import React from 'react';

interface RecitationStudentColumnLevelProps {
  levels: number[];
}
const LELEL_LIST = Array.from({ length: 12 }, (_, i) => i + 1);
const RecitationStudentColumnLevel = ({
  levels,
}: RecitationStudentColumnLevelProps) => {
  return (
    <div className="grid grid-cols-6 bg-gray2 gap-[1px] p-[1px]">
      {LELEL_LIST.map((level, index) => {
        const isCompleted = levels.includes(level);
        return (
          <div
            key={index}
            className={`p-[1px] ${
              isCompleted
                ? 'bg-purple5 text-white font-bold'
                : 'bg-white text-gray5'
            }`}
          >
            {level}
          </div>
        );
      })}
    </div>
  );
};

export default RecitationStudentColumnLevel;
