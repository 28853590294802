import HeaderContents from './dashboard/headercontents';
import BoostUp from './dashboard/boostup/boostup';
import { useAppSelector } from '../../store/hook';
import { selectLmsLists } from '../../store/features/lmsSlice';
import { useParams } from 'react-router-dom';

const LmsDashboard = () => {
  const lmsLists = useAppSelector(selectLmsLists);
  const { classId } = useParams();

  return (
    <section className="flex flex-col gap-5 p-3 xl:p-5">
      {lmsLists && classId === lmsLists.id && (
        <>
          <HeaderContents classId={classId} lmsLists={lmsLists} />
          <BoostUp classId={classId} lmsLists={lmsLists} />
        </>
      )}
    </section>
  );
};

export default LmsDashboard;
