import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MypageBadge from '../../pages/mypage/badge/Badge';
import MypageCertification from '../../pages/mypage/certification/Certification';
import MypageCoupon from '../../pages/mypage/coupon/Coupon';
import MypageCustomer from '../../pages/mypage/customer/Customer';
import MypageEvent from '../../pages/mypage/event/Event';
import MypageEventDetail from '../../pages/mypage/event/EventDetail';
import MypageModify from '../../pages/mypage/modify/Modify';
import MypageNotice from '../../pages/mypage/notice/Notice';
import MypageNoticeDetail from '../../pages/mypage/notice/NoticeDetail';
import MypagePoint from '../../pages/mypage/point/Point';
import MypagePurchase from '../../pages/mypage/purchase/Purchase';
import MypagePurchaseDetail from '../../pages/mypage/purchase/PurchaseDetail';
import MypageRating from '../../pages/mypage/rating/Rating';
import MypageSetting from '../../pages/mypage/setting/Setting';
import MypageAnnounce from '../../pages/mypage/Announce';
import LayoutMypage from './LayoutMypage';
import MypageMembership from './membership/MypageMembership';
import MypageMembershipChurch from './membership/MypageMembershipChurch';
import MypageMembershipMember from './membership/MypageMembershipMember';
import { User } from '../../constants/types';
import { MypageContext } from './membership/MypageContext';
import ModifyBasic from './modify/ModifyBasic';
import ModifyPw from './modify/ModifyPw';

const RouteMypage = () => {
  const [userInfo, setUserInfo] = useState<User>();
  return (
    <MypageContext.Provider
      value={{
        userInfo: userInfo,
        setMemberInfo: setUserInfo,
      }}
    >
      <Routes>
        {/* base "/mypage" */}
        <Route element={<LayoutMypage />}>
          {/* 메인 [ 나의 뱃지 ] */}
          <Route
            path="/"
            element={<Navigate replace to="/mypage/customer" />}
          />

          {/* 나의 뱃지 */}
          <Route path="/badge" element={<MypageBadge />} />

          {/* 회원등급 */}
          <Route path="/rating" element={<MypageRating />} />

          {/* 수료증 */}
          <Route path="/certification" element={<MypageCertification />} />

          {/* 보유 포인트 */}
          <Route path="/point" element={<MypagePoint />} />

          {/* 구매내역 */}
          <Route path="/purchase" element={<MypagePurchase />} />

          {/* 구매내역 상세페이지 */}
          <Route
            path="/purchase/detail/:detailId"
            element={<MypagePurchaseDetail />}
          />

          {/* 교인인증 */}
          <Route path="/membership" element={<MypageMembership />} />

          <Route
            path="/membership/church"
            element={<MypageMembershipChurch />}
          />
          <Route
            path="/membership/member"
            element={<MypageMembershipMember />}
          />

          {/* 쿠폰 [ 미사용 ] - 기능 개발후 사용예정 */}
          {/* <Route path="/coupon" element={<MypageCoupon />} /> */}

          {/* 공지사항 */}
          <Route path="/notice" element={<MypageNotice />} />
          {/* 공지사항 상세페이지 */}
          <Route
            path="/notice/detail/:detailId"
            element={<MypageNoticeDetail />}
          />

          {/* 이벤트 */}
          <Route path="/event/" element={<MypageEvent />} />
          {/* 이벤트 상세페이지 */}
          <Route
            path="/event/detail/:detailId"
            element={<MypageEventDetail />}
          />

          {/* 고객센터 */}
          <Route path="/customer" element={<MypageCustomer />} />

          {/* 회원 정보 수정 */}
          <Route path="/modify" element={<MypageModify />} />
          <Route path="/modify/basic" element={<ModifyBasic />} />
          <Route path="/modify/pw" element={<ModifyPw />} />
          <Route path="/modify" element={<MypageModify />} />

          {/* 설정 */}
          <Route path="/setting" element={<MypageSetting />} />

          {/* 알수 없음 미사용 항목. 체크 필요 */}
          {/* <Route path="/announce" element={<MypageAnnounce />} /> */}
        </Route>
      </Routes>
    </MypageContext.Provider>
  );
};

export default RouteMypage;
