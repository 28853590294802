import { Tab, Tabs, TabsHeader } from '@material-tailwind/react';
import React from 'react';
import { LmsMigrationStatus } from '../../../constants/types/lms-type';
import TextWinds from '../../../components/common/TextWinds';
interface LmsMigrationStateProps {
  value?: LmsMigrationStatus;
  onChange?: (value: LmsMigrationStatus) => void;
}
const LmsMigrationState = ({
  value = LmsMigrationStatus.SUCCESS,
  onChange,
}: LmsMigrationStateProps) => {
  const data = [
    {
      value: LmsMigrationStatus.SUCCESS,
      label: '성공',
    },
    {
      value: LmsMigrationStatus.FAIL,
      label: '실패',
    },
  ];

  const tapHandler = (value: LmsMigrationStatus) => {
    onChange(value);
  };
  return (
    <div className="w-full max-w-[520px]">
      <Tabs value={value}>
        <TabsHeader>
          {data.map((item) => (
            <Tab
              key={item.value}
              value={item.value}
              onClick={() => tapHandler(item.value)}
            >
              {item.label}
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
      <TextWinds type="content_body2" color="gray7" className="mt-3">
        {value === LmsMigrationStatus.SUCCESS
          ? '가져오기에 성공한 학생은 [등록 관리] - [대기생] 목록에서 확인하실 수 있습니다.'
          : '가져오기에 실패한 학생은 [등록] 버튼을 눌러 아이디를 변경해주셔야 합니다.'}
      </TextWinds>
      <TextWinds type="content_body2" color="purple5">
        가져오기한 학생의 초기 비밀번호는 1111로 일괄 설정됩니다.
      </TextWinds>
    </div>
  );
};

export default LmsMigrationState;
