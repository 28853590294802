import { Tabs as MaterialTabs, TabsHeader, Tab } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import Shops from "../../../components/shop/shops";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

import "../../../styles/shop/shop.css";
import FullWidthPage from "../../../components/layout/FullWidthPage";
import TextWinds from "../../../components/common/TextWinds";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { getShopLists, resetShopInfo, resetShopLists, selectShopLists } from "../../../store/features/shopSlice";
import { CommonResProps, ShopInfoProps } from "../../../constants/types";
import { AppProductListOrderBy, ProductSubType, ProductType } from "../../../enum";
import { Link } from "react-router-dom";
import useUserHook from "../../../controllers/useUserHook";

const TABS_MENU = [
    {
        label: "전체",
        value: "ALL",
    },
    {
        label: "일반 도서",
        value: ProductSubType.NORMAL_BOOK,
    },
    {
        label: "굿즈(교구)",
        value: ProductSubType.GOODS,
    },
    // {
    //     label: "최근 본 상품",
    //     value: "HISTORY",
    // },
];

const Shop = () => {
    const {checkAndPageMove} = useUserHook();
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState("ALL");
    
    useEffect(() => {
        dispatch(resetShopLists())
        // dispatch(resetShopInfo())
        tabClickHandler(activeTab);
    }, []);

    const shopLists = useAppSelector(selectShopLists);

    const params = {
        product_type: ProductType.STANDARD,
        product_sub_type: ProductSubType.ALL,
        order_by: AppProductListOrderBy.RECOMMENDED,
    };

    const tabClickHandler = (value: string) => {
        setActiveTab(value);
        dispatch(getShopLists({ url: "/v1/product", params: { ...params, product_sub_type: value } }));
    };

    return (
        <FullWidthPage>
            <section>
                <MaterialTabs value={activeTab} className="relative border-b">
                    <TabsHeader
                        className="inline-flex p-0 bg-transparent bg-white rounded-none"
                        indicatorProps={{
                            className: "bg-transparent border-b-2 border-purple5 shadow-none rounded-none  ",
                        }}
                    >
                        {TABS_MENU.map(({ label, value }) => (
                            <Tab
                                key={value}
                                value={value}
                                onClick={() => tabClickHandler(value)}
                                className={`text-content_bold h-[48px] ml-[24px] whitespace-nowrap  ${
                                    activeTab === value ? "text-purple5" : ""
                                }`}
                            >
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                </MaterialTabs>
            </section>

            <section className="px-0 pt-[24px]">
                <div className="grid grid-cols-2 gap-x-[15px] gap-y-[24px] mx-4 my-2 lg:grid-cols-4">
                    {shopLists&&
                        <Shops
                            activeTab={activeTab}
                            label={activeTab}
                            datas={shopLists as CommonResProps<ShopInfoProps[]>}
                        />
                    } 
                </div>
            </section>

            <section className="pb-6 mt-[80px]">
                <div className="relative flex items-center justify-between w-full px-[20px] py-[26px] rounded-none bg-gradient-to-r from-gradient_f2 to-gradient_t2 xl:bg-spotLilac xl:from-transparent xl:to-transparent xl:py[50px] xl:px-[72px] xl:mb-[100px] xl:justify-between">
                    <div className="relative inline-flex flex-col items-start flex-auto hidden xl:block">
                        <TextWinds type="title_h1" color="white">
                            젭스의 전체 코스
                        </TextWinds>
                        <TextWinds type="content_body1" color="white" className="pt-[10px]">
                            홈스쿨부터 주일학교, NIV학습까지!
                            <br />
                            나에게 필요한 젭스 코스가 무엇인지 확인해 보세요.
                        </TextWinds>
                    </div>
                    <div className="relative inline-flex flex-col items-end justify-end w-full gap-[20px] xl:w-[390px]">
                        <div className="relative flex items-start self-stretch justify-between flex-auto  py-[14px] px-[12px] bg-white rounded-md xl:p-[24px] ">
                            <div className="relative ">
                                <Link to={"/courses"}>
                                    <TextWinds type="content_bold">학습 코스를 수강하기 원하시나요?</TextWinds>
                                    <div className="inline-flex flex-auto items-center gap-[2px]]">
                                        <TextWinds type="content_bold" color="purple5">
                                            코스 찾기 이동
                                        </TextWinds>
                                        <ArrowRightIcon className="w-[20px] h-[20px]" color="purple" />
                                    </div>
                                </Link>
                            </div>
                            <Link to={"/courses"}>
                                <img
                                    className="relative w-[52px] h-[52px]"
                                    alt=""
                                    src={process.env.PUBLIC_URL + "images/shop/boosterup.png"}
                                />
                            </Link>
                        </div>
                        <div className="relative flex items-start self-stretch justify-between flex-auto  py-[14px] px-[12px] bg-white rounded-md xl:p-[24px] cursor-pointer"
                            onClick={()=>{checkAndPageMove("/ClassManagement")}}
                        >
                            <div className="relative ">
                                <TextWinds type="content_bold">내 클래스와 관련된 상품을 구매하시나요?</TextWinds>
                                <div className="inline-flex flex-auto items-center gap-[2px]]">
                                    <TextWinds type="content_bold" color="purple5">
                                        부스트업 이동
                                    </TextWinds>
                                    <ArrowRightIcon className="w-[20px] h-[20px]" color="purple" />
                                </div>                                
                            </div>
                            <img
                                className="!relative !w-[52px] !h-[52px]"
                                alt=""
                                src={process.env.PUBLIC_URL + "images/shop/findshop.png"}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </FullWidthPage>
    );
};

export default Shop;
