import { ColumnsType } from 'antd/es/table';
import {
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import { PaginationProps } from '../../../constants/types';
import StudentConroller from './StudentController';
import dayjs from 'dayjs';
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  InformationCircleIcon,
  KeyIcon,
  TrashIcon,
  UserGroupIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

const columns = (
  pageInfo: PaginationProps,
  status: studentStatusType,
  controller: StudentConroller
): ColumnsType<studentType> => [
  {
    title: 'No.',
    key: 'index',
    render: (_, __, index) =>
      (pageInfo.page - 1) * pageInfo.pageSize + index + 1,
    align: 'center',
  },
  {
    title: '학생명',
    key: 'name',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '아이디',
    key: 'uid',
    dataIndex: 'uid',
    align: 'center',
  },
  {
    title: '반',
    key: 'classGroup',
    render: (_, row) =>
      row.classInfos.map((classInfo) => classInfo.classGroup?.name).join(', '),
    align: 'center',
  },
  {
    title: '등록일',
    key: 'createdAt',
    render: (_, row) => dayjs(row.createdAt).format('YYYY-MM-DD'),
    align: 'center',
  },
  {
    title: '상세',
    key: 'detail',
    render: (_, row) => (
      <Link to={`./${row.id}`} className="text-purple5 text-sm font-bold">
        상세보기
      </Link>
    ),
    align: 'center',
  },
  {
    title: (
      <Tooltip
        color="white"
        title={
          <div className="flex flex-col gap-1 w-25 text-black">
            {status === studentStatusType.ACTIVE && (
              <>
                <div className="flex justify-start gap-2">
                  <UserGroupIcon className="w-5 text-red-500" />반 배정하기
                </div>
                <div className="flex gap-2">
                  <UserMinusIcon className="w-5 text-orange4" />
                  수강 정지
                </div>
              </>
            )}
            {(status === studentStatusType.INACTIVE ||
              status === studentStatusType.PENDING) && (
              <>
                <div className="flex gap-2">
                  <CheckBadgeIcon className="w-5 text-purple5" />
                  수강 등록
                </div>
                <div className="flex gap-2">
                  <TrashIcon className="w-5 text-red-500" />
                  학생 삭제
                </div>
              </>
            )}
            <div className="flex gap-2">
              <KeyIcon className="w-5 text-purple5" />
              비밀번호 초기화
            </div>
          </div>
        }
        className="w-full justify-center flex items-center gap-1"
      >
        관리
        <InformationCircleIcon className="h-5 w-5 text-gray6" />
      </Tooltip>
    ),
    key: 'reset',
    render: (_, row) => (
      <div className="flex gap-3 items-center justify-center">
        {status === studentStatusType.ACTIVE && (
          // 반 배정하기
          <Tooltip title="반 배정하기">
            <UserGroupIcon
              className="h-5 w-5 text-red-500 cursor-pointer hover:opacity-50"
              onClick={() => controller.assignHandler([row])}
            />
          </Tooltip>
        )}
        {status === studentStatusType.ACTIVE && (
          // 정지하기
          <Tooltip title="수강 정지">
            <UserMinusIcon
              className="h-5 w-5 text-orange4 cursor-pointer hover:opacity-50"
              onClick={() => controller.stopHandler([row])}
            />
          </Tooltip>
        )}
        {(status === studentStatusType.INACTIVE ||
          status === studentStatusType.PENDING) && (
          // 수강등록

          <Tooltip title="수강 등록">
            <CheckBadgeIcon
              className="h-5 w-5 text-purple5 cursor-pointer hover:opacity-50"
              onClick={() => controller.registHandler([row])}
            />
          </Tooltip>
        )}
        {(status === studentStatusType.INACTIVE ||
          status === studentStatusType.PENDING) && (
          // 삭제
          <Tooltip title="학생 삭제">
            <TrashIcon
              className="h-5 w-5 text-red-500 cursor-pointer hover:opacity-50"
              onClick={() => controller.deleteHandler([row])}
            />
          </Tooltip>
        )}
        <Tooltip title="비밀번호 초기화">
          <KeyIcon
            className="h-5 w-5 text-purple5 cursor-pointer hover:opacity-50"
            onClick={() => controller.resetHandler(row.id)}
          />
        </Tooltip>
      </div>
    ),
    align: 'center',
    width: 120,
  },
];

export default columns;
