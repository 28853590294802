import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import { RootState } from "..";
import instance from "../../api/axios/utils.ts/instance";
import { AxiosParamType } from ".";
import { LmsInfoTypes } from "../../constants/types";

interface stateObj {
    status: string,
    lmsLists: LmsInfoTypes
}
const initialState: stateObj = {
    status: '',
    lmsLists: undefined,
}
export const lmsSlice = createSlice({
  name: 'lms',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLmsLists.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getLmsLists.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.lmsLists = action.payload
      })
      .addCase(getLmsLists.rejected, (state) => {
        state.status = 'rejected'
      })
  }
})
export const getLmsLists = createAsyncThunk(
    'asyncThunk/getLmsLists',
    async ( { url, params }: AxiosParamType ) => {
    try {
        const response = await instance.get(url, {params});
        return response.data;
    } catch(error) {
        console.error('[axios] getLmsLists : ', error)
        return isRejectedWithValue(error)
    }
});
export const selectLmsLists = (state: RootState) => state.lms.lmsLists
export default lmsSlice.reducer