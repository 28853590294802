import React from "react";
import Shape from "./images/shape-common-orange.png";
import ImageA from "./images/review6-a.png";
import ImageB from "./images/review6-b.png";
import Shape1 from "./images/shape1-orange.png";
import Shape2 from "./images/shape2-orange.png";
import Shape3 from "./images/shape3-orange.png";
import { Link } from "react-router-dom";

export const Review6 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-16 pt-12 pb-20 text-lg leading-7 text-center text-black bg-white max-md:px-5 relative overflow-hidden">
      <img
        src={Shape1}
        width={300}
        className="absolute -left-24 top-[780px] hidden md:visible"
      />
      <img
        src={Shape2}
        width={300}
        className="absolute -right-12 top-[1900px] hidden md:visible"
      />
      <img
        src={Shape3}
        width={300}
        className="absolute -left-12 top-[2900px] hidden md:visible"
      />
      <img
        loading="lazy"
        src={Shape}
        className="max-w-full aspect-square w-[106px]"
      />
      <div className="mt-3.5 text-3xl font-bold leading-9 max-md:max-w-full">
        기도의 삼박자로 은혜가 배가 되는 젭스홈스쿨!
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-16 tracking-normal text-black max-md:mt-10 max-md:max-w-full">
        젭스영어성경홈스쿨을 운영하며
        <br />
        영어와 성경을 재미있게 배우는 아이들과
        <br />
        그런 아이들을 무한 응원하는 부모님들과
        <br />
        매일매일 아이들을 위해 고민하고 있는 제 자신을 볼 때 마다
        <br />
        느끼는 것이 있습니다.
        <br />
        <br />
        <span className="text-[#ff6d2c]">
          바로, 기도하는 선생님, 기도하는 부모님, 기도하는 아이들{" "}
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          이 삼박자가 맞으면 은혜가 배가 된다는 것입니다.
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          이 은혜는 젭스영어성경홈스쿨만의 특권이라고 생각합니다.
        </span>
        <br />
        <br />
        교습소 학생 중 알파벳도 어려워하고 암송도 쉽게 까먹어 <br />
        암송인증제에서 불합격한 친구가 있습니다.
        <br />
        불합격할 때 마다 꾹 참고 집에가서 눈물을 터뜨리곤 했습니다.
        <br />
        <br />
        하루는 인증제를 준비하며 엄마와 집에서 열심히 암송하고 연습했는데도{" "}
        <br />
        너무 긴장이 되어 30분 일찍 인증제를 도전하러 왔습니다.
        <br />
        학생의 어머님은 인증제에 대한 마음이 큰 아이를 끌어안고 이렇게
        기도해주시더라고요.
        <br />
        <br />
        <span className="text-[#ff6d2c]">
          “하나님의 지혜를 구합니다. 이 모든 것이 하나님의 기쁨이 되어 주세요!”
        </span>
      </div>
      <img
        loading="lazy"
        src={ImageA}
        className="mt-20 max-w-full aspect-[1.43] w-[649px] max-md:mt-10 relative"
      />
      <div className="mt-20 tracking-normal text-black max-md:mt-10 max-md:max-w-full">
        어떻게 되었을까요?
        <br />
        <br />
        엄마의 기도를 받은 아이는 결국 인증제에 합격하고, 짜릿한 기쁨을
        맛보았습니다.
        <br />
        모두의 응원과 기도를 받아 인증제에 합격하고 나니,
        <br /> 그 이후로 수업시간마다 아이의 눈빛이 달라지고 <br />
        영어 말씀 암송 또한 거뜬히 성공하고 있습니다.
        <br />
        <br />
        <span className="text-[#ff6d2c]">
          기도하는 아이, 기도하는 부모님, 기도하는 선생님
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          이 기도의 삼박자만 맞으면 은혜가 배가 되고,
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          형통함이 이루어지고, 함께함의 기쁨이 생겨남을
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          젭스를 통해 다시 한 번 느끼는 하루였습니다.
        </span>
        <br />
      </div>
      <img
        loading="lazy"
        src={ImageB}
        className="mt-20 max-w-full  lg:w-[647px] aspect-[1.32] max-md:mt-10 relative"
      />
      <div className="mt-14 tracking-normal text-black max-md:mt-10 max-md:max-w-full">
        다시한번 쉐마의 말씀을 마음에 새깁니다!
        <br />
        <br />
        부모인 우리가 먼저 마음을 다하고 뜻을 다하고 힘을다하여 주님을 사랑하고!
        <br />이 말씀을 우리들의 마음에 먼저 새긴 후에 <br />
        우리 자녀들에게 부지런히 가르쳐야 됨을 다시 한번 깨닫습니다.
        <br />
        <br />
        <span className="text-[#ff6d2c]">
          기도의 힘과 함께함의 능력을 경험하게 하는 젭스!
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          매일매일 새로운 힘과 소망이 우리 가운데 넘치길 소망합니다
        </span>
        🧡
      </div>
      <div className="mt-24 text-3xl font-bold leading-9 text-slate-900 max-md:mt-10">
        - JEMM 169기 성** 선생님의 후기 -
      </div>
      <div className="shrink-0 mt-24 max-w-full h-0.5 bg-black border-2 border-black border-solid max-md:mt-10" />
      <div className="mt-24 tracking-normal max-md:mt-10">
        기도와 응원을 받는 아이들은 결국 해내고야 말죠.
        <br />
        그리고 그 과정을 통해 더 강한 믿음과 실력을 얻습니다.
        <br />
        <br />한 아이가 성령이 충만하고 영어가 능통하길
        <br />
        바라는 마음만으로 부모님과 선생님이 기도하고 응원할 때<br />
        아이는 믿음과 실력이 함께 자라날 수 있습니다.
        <br />
      </div>
      <div className="justify-center px-7 py-10 mt-20 font-medium tracking-normal text-black bg-[#FAEEE4] leading-[133%] max-md:pr-5 max-md:mt-10">
        “기도의 삼박자만 맞으면 은혜가 배가 됩니다.”
      </div>
      <div className="mt-14 tracking-normal max-md:mt-10 max-md:max-w-full">
        젭스영어성경홈스쿨은 다른 것 보다도
        <br />한 아이가 복음을 알고 말씀을 영어로 배워
        <br />더 좋은 사람, 하나님과 동행하는 글로벌 리더가 될 수 있도록
        돕습니다.
        <br />
        <br />
        그렇기에 많은 아이들이 인내하고 다시 도전하고 승리하며
        <br />
        진짜 믿음의 길을 경험하게 됩니다.
      </div>
      <Link to="/courses/homeschool">
        <div className="justify-center items-center px-2.5 py-4 mt-32 max-w-full text-base font-bold tracking-normal leading-6 text-purple5 bg-white rounded border border-purple5 border-solid w-[376px] max-md:px-5 max-md:mt-10">
          홈스쿨 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
