import React from 'react';
import { useParams } from 'react-router-dom';
import { ProductSubType } from '../../../enum';
import CourseWidget from '../../../components/course/CourseWidget';

const CourseCategory = () => {
  const productSubType = useParams().productSubType;

  const getProductSubType = () => {
    switch (productSubType) {
      case 'homeschool':
        return ProductSubType.HOMESCHOOL;
      case 'churchschool':
        return ProductSubType.WORSHIP;
      case 'englishconversation':
        return ProductSubType.LEARN_ON_PHONE;
      case 'smallgroup':
        return ProductSubType.SMALL_GROUP;
    }
  };

  return <CourseWidget product_sub_type={getProductSubType()} />;
};

export default CourseCategory;
