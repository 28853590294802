import React from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { Link } from 'react-router-dom';
import { orderDetailType } from '../../../constants/types/mypage-type';
import { OrderItemVariant, OrderTemplate } from '../../../constants/types';
import { numberWithCommas } from '../../../api/utils/format/numbers';

interface purchaseItemProps {
  props: orderDetailType;
  lastItemRef?: (node: HTMLDivElement) => void;
}
const PurchaseItem = ({ props, lastItemRef }: purchaseItemProps) => {
  return (
    <div
      className="flex justify-between items-center flex-col gap-y-3 shadow-card rounded-lg p-3"
      ref={lastItemRef}
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex flex-col lg:flex-row gap-2">
          <TextWinds type="caption1" color="gray9">
            {new Date(props.created_at).toLocaleString()}
          </TextWinds>
          <TextWinds type="caption1" color="gray9">
            {`주문번호 : ${props.id}`}
          </TextWinds>
        </div>
        <Link
          to={`/mypage/purchase/detail/${props.id}`}
          state={props}
          className="w-[60px] text-right"
        >
          <TextWinds type="button2" color="purple5">
            상세보기
          </TextWinds>
        </Link>
      </div>
      {(props.template as OrderTemplate).order_items.map((option, key) => (
        <div key={key} className="w-full pb-2">
          <div className="flex justify-between">
            <div className="flex flex-col gap-y-2">
              {/* 물품인 경우 */}
              {option.chosen_variants.length > 0 && (
                <div>
                  <TextWinds type="content_body2">
                    {option.product.name}
                  </TextWinds>
                  <OptionList
                    list={option.chosen_variants}
                    originPrice={option.product.current_price}
                  />
                </div>
              )}

              {/* 코스인 경우 */}
              {option.enroll_options.length > 0 && (
                <div>
                  <TextWinds type="content_body2">
                    {option.product.name}
                  </TextWinds>
                  <div className="flex items-center gap-x-1">
                    <TextWinds type="title_h3">
                      {numberWithCommas(option.product.current_price)}
                    </TextWinds>
                    <TextWinds type="content_body1">원</TextWinds>
                  </div>
                  {option.chosen_options.map((desc, key) => (
                    <OptionList
                      key={key}
                      list={desc.chosen_variants}
                      originPrice={desc.product.current_price}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* 물품 썸네일 */}
            <div className="max-w-[72px]">
              <img src={option.product.thumbnail.access_url} />
            </div>
          </div>

          {/* 배송비가 있을 경우 */}
          {props.template.shipping_fee > 0 && (
            <div className="flex flex-col items-left gap-x-1 text-left w-full">
              <TextWinds type="caption1">배송비</TextWinds>
              <div className="flex items-center gap-x-1">
                <TextWinds type="title_h3">
                  {numberWithCommas(props.template.shipping_fee)}
                </TextWinds>
                <TextWinds type="content_body1">원</TextWinds>
              </div>
            </div>
          )}
        </div>
      ))}

      {/* {props.status} */}
      {/*     
                "PENDING",
                "PAY_RECIEVED",
                "REFUND_COMPLETE",
                "COMPLETE"
            */}
      <div className="border-t border-gray2 w-full text-center pt-3">
        {' '}
        총 합계 : {numberWithCommas(props.template.total_price)} 원
      </div>
      {/* <PurchaseShippingStatus status={props.status} /> */}
      {/* <div className="flex flex-col gap-y-1 w-full">
            <TextWinds type="sub_title01" color="orange">
                입금 대기중
            </TextWinds>
            <TextWinds type="content_body2">받을분 {props.deposit}</TextWinds>
            <TextWinds type="content_body2">입금계좌 {props.account}</TextWinds>
            <TextWinds type="content_body2">입금기한 {props.deadline}까지</TextWinds>
        </div> */}
    </div>
  );
};

interface OptionListProps {
  list: OrderItemVariant[];
  originPrice: number;
}
const OptionList = ({ list, originPrice }: OptionListProps) => {
  const detail_total_price = list.reduce((acc, cur) => {
    return acc + originPrice * cur.quantity;
  }, 0);
  return (
    <>
      {list.map((option, key) => (
        <OptionItem
          key={key}
          name={option.variant.name}
          optionPrice={originPrice + option.variant.additional_price}
          quantity={option.quantity}
        />
      ))}
      <div className="flex items-center gap-x-1">
        <TextWinds type="title_h3">
          {numberWithCommas(detail_total_price)}
        </TextWinds>
        <TextWinds type="content_body1">원</TextWinds>
      </div>
    </>
  );
};

interface OptionItemProps {
  name: string;
  optionPrice: number;
  quantity: number;
}

const OptionItem = ({ name, optionPrice, quantity }: OptionItemProps) => {
  return (
    <div className="flex flex-col gap-x-[4px] lg:flex-row">
      <TextWinds type="caption1">{name}</TextWinds>
      <TextWinds type="caption1">
        {`(${numberWithCommas(
          optionPrice
        )}원 * ${quantity} 개 = ${numberWithCommas(
          optionPrice * quantity
        )}원 )`}
      </TextWinds>
    </div>
  );
};

export default PurchaseItem;
