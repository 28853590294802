import { Input, Button } from "@material-tailwind/react";
import Buttons from "./Buttons2";
import TextWinds from "./TextWinds";

interface ComInputButtonProps {
    type: "text" | "number";
    placeholder?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    buttonText: string;
    variant: "filled" | "outlined";
    action?:() => void;
}

export default function ComInputButton({
    type,
    placeholder,
    value,
    onChange,
    className,
    buttonText,
    variant,
    action,
}: ComInputButtonProps) {
    return (
        <div className={`inline-flex items-center w-full border rounded border-gray-500 ${className}`}>
            <Input
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e)}
                className="px-[10px] py-[14px] !border-0 bg-white !text-black placeholder:text-gray-600 placeholder:opacity-100 focus:!border-purple-500 focus:!border-t-purple-500"
                labelProps={{
                    className: "hidden",
                }}
                containerProps={{
                    className: "min-w-min h-[50px]",
                }}
            />
            {variant === "filled" ? (
                <div className="mx-2">
                    <Buttons variant="filled" color="purple5" type="secondary" className="" onClick={action}>
                        <TextWinds type="button2" color="white" className="whitespace-nowrap">
                            {buttonText}
                        </TextWinds>
                    </Buttons>
                </div>
            ) : (
                <div className="mx-2">
                    <Buttons variant="outlined" color="purple5" type="secondary" className="">
                        <TextWinds type="button2" color="purple5" className="whitespace-nowrap">
                            {buttonText}
                        </TextWinds>
                    </Buttons>
                </div>
            )}
        </div>
    );
}
