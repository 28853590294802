import { StoryCourse } from "./StoryCourse";
import { StoryIntro } from "./StoryIntro";
import { StoryTimeLine } from "./StoryTimeLine";
import { StoryVideo } from "./StoryVideo";
import "../../../styles/story/story.css";
import { StoryReview } from "./StoryReview";
import { StoryDownladApp } from "./StoryDownloadApp";

const VideoComp = ({ url }: { url: string }) => (
  <video className="object-cover rounded-lg" controls>
    <source src={url} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
);
const StoryMain = () => {
  return (
    <>
      <StoryIntro />
      <StoryTimeLine />
      <StoryCourse />
      <StoryVideo />
      <StoryReview />
      {/* <StoryDownladApp /> */}
    </>
  );
};
export default StoryMain;
