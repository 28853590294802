import React, { useState } from 'react';
import { classGroupType } from '../../../../constants/types/student-type';
import { useParams } from 'react-router-dom';
import Buttons from '../../../../components/common/Buttons';
import TextWinds from '../../../../components/common/TextWinds';
import {
  axiosDeleteClass,
  axiosPutClass,
} from '../../../../api/utils/lms/lmsApi';

interface ModifyListItemProps {
  classItem: classGroupType;
  reloadClassList: () => void;
}
const ModifyListItem = ({
  classItem,
  reloadClassList,
}: ModifyListItemProps) => {
  const { classId } = useParams();
  const [item, setItem] = useState(classItem);

  const deleteClass = (itemId: number) => {
    window.confirm('정말 삭제하시겠습니까?') &&
      axiosDeleteClass(classId, itemId).then((result) => {
        reloadClassList();
        alert('반을 삭제하였습니다.');
      });
  };

  const updateClass = (item: classGroupType) => {
    axiosPutClass(classId, item.id, {
      name: item.name,
      description: item.description,
    }).then((result) => {
      reloadClassList();
      alert('반을 수정하였습니다.');
    });
  };

  return (
    <div key={item.id} className="xl:w-full flex flex-row items-center gap-2.5">
      <input
        placeholder="한글/영문/숫자 10자 이내"
        value={item.name}
        onChange={(e) => setItem({ ...item, name: e.target.value })}
        className="border border-gray4 rounded-[3px] w-full h-[40px] px-2.5"
      />

      <div className="min-w-[131px] max-w-max flex flex-row gap-2.5">
        <Buttons
          type={'text'}
          color="purple5"
          isHeight="titlebutton"
          className="py-3 hover:bg-gray2"
          onClick={() => updateClass(item)}
        >
          <TextWinds type={'button2'} color="purple5">
            수정하기
          </TextWinds>
        </Buttons>
        <Buttons
          type={'text'}
          color="red5"
          isHeight="titlebutton"
          className="py-3 hover:bg-gray2"
          onClick={() => deleteClass(item.id)}
        >
          <TextWinds type={'button2'} color="red5">
            삭제하기
          </TextWinds>
        </Buttons>
      </div>
    </div>
  );
};

export default ModifyListItem;
