import { deleteStudent, putStudentStatus } from '../../../api/utils/lms/lmsApi';
import {
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import { AppDispatch } from '../../../store';
import {
  openAlert,
  openConfirm,
  openModal,
} from '../../../store/features/modalSlice';
import { putStudentPassword } from './func';
import NewPasswordModal from './popup/NewPasswordModal';
import ClassAssignment from './extend/classAssignment';

class StudentConroller {
  private dispatch: AppDispatch;
  private classId: string;
  private reloadTable: () => void;

  constructor(dispatch: AppDispatch, classId: string, reloadTable: () => void) {
    this.dispatch = dispatch;
    this.classId = classId;
    this.reloadTable = reloadTable;
  }

  /**
   * 타입에 따른 메시지 생성
   * @param studentList
   * @param type
   * @returns
   */
  private makeMsg = (
    studentList: studentType[],
    type: '정지' | '삭제' | '수강등록'
  ) => {
    const count = studentList.length;

    if (count === 1) {
      return `${studentList[0].name} 학생을 ${type} 하시겠습니까?`;
    } else {
      return `총 ${count}명의 학생을 ${type} 하시겠습니까?`;
    }
  };

  /**
   * 학생 상태 변경
   * @param studentList
   * @param status
   */
  private changeStatus = (
    studentList: studentType[],
    status: studentStatusType
  ) => {
    putStudentStatus(
      this.classId,
      studentList.map((item) => item.id),
      status
    ).then(() => {
      this.reloadTable();
    });
  };

  /**
   * 체크된 학생 수 확인
   * @param studentList
   * @returns
   */
  private confirmCheckedCount = (studentList: studentType[]): boolean => {
    if (studentList.length === 0) {
      this.dispatch(openAlert({ message: '학생을 선택해주세요.' }));
      return false;
    }
    return true;
  };

  /**
   * 확인창
   * @param msg
   * @param onConfirm
   */
  private confirm(msg: string, onConfirm: () => void) {
    this.dispatch(
      openConfirm({
        message: msg,
        onConfirm: onConfirm,
      })
    );
  }

  /**
   * 학생 배정
   * @param studentList
   * @returns
   */
  public assignHandler = (studentList: studentType[]) => {
    if (!this.confirmCheckedCount(studentList)) return;

    this.dispatch(
      openModal({
        body: (
          <ClassAssignment
            classId={this.classId}
            studentList={studentList}
            reloadTable={this.reloadTable}
          />
        ),
      })
    );
  };

  /**
   * 학생 정지
   * @param studentList
   * @returns
   */
  public stopHandler = (studentList: studentType[]) => {
    if (!this.confirmCheckedCount(studentList)) return;

    const msg = this.makeMsg(studentList, '정지');
    const onConfirm = () =>
      this.changeStatus(studentList, studentStatusType.INACTIVE);
    this.confirm(msg, onConfirm);
  };

  /**
   * 학생 수강등록
   * @param studentList
   * @returns
   */
  public registHandler = (studentList: studentType[]) => {
    if (!this.confirmCheckedCount(studentList)) return;

    const msg = this.makeMsg(studentList, '수강등록');
    const onConfirm = () =>
      this.changeStatus(studentList, studentStatusType.ACTIVE);
    this.confirm(msg, onConfirm);
  };

  /**
   * 비밀번호 초기화
   * @param id
   */
  public resetHandler = (id: number) => {
    const msg = '비밀번호를 초기화 하시겠습니까?';
    const onConfirm = () => {
      putStudentPassword(this.classId, id).then(({ uid, password }) => {
        this.dispatch(
          openModal({
            body: <NewPasswordModal type="RESET" uid={uid} pw={password} />,
          })
        );
      });
    };
    this.confirm(msg, onConfirm);
  };

  /**
   * 학생 삭제
   * @param studentList
   * @returns
   */
  public deleteHandler = (studentList: studentType[]) => {
    if (!this.confirmCheckedCount(studentList)) return;

    // 1차 삭제 메시지
    const msg = this.makeMsg(studentList, '삭제');

    // 최종 삭제
    const deleteAction = () => {
      deleteStudent(
        this.classId,
        studentList.map((item) => item.id)
      );
    };

    // 2차 삭제 확인
    const onConfirm = () => {
      // 2차 삭제 메시지
      const tmpMsg =
        '학생 삭제 시 학생의 모든 학습 이력 및 데이터가 삭제되며, 추후 복구가 불가합니다.\nㅤ\n정말 삭제하시겠습니까?';
      this.confirm(tmpMsg, deleteAction);
    };

    // 1차 삭제 확인
    this.confirm(msg, onConfirm);
  };
}

export default StudentConroller;
