import React, { ReactNode } from 'react';
import {
  Arrcodian,
  CheckIcon,
  DiamondIcon,
  TextCheckList,
} from './HomeschoolComp';
import { motion } from 'framer-motion';

const HomeschoolSection9 = () => {
  return (
    <>
      <div className="relative flex flex-col items-center bg-[#eb5d1a]">
        <div className="w-full max-w-[480px]">
          <section className="flex flex-col items-center p-5 pt-[80px] drop-shadow-[0px_0px_2px_rgba(255,255,255,0.6)]">
            <DiamondIcon color="white" width={24} />
            <div className="text-3xl md:text-4xl text-center mt-3 text-white">
              <div className="font-bold">회원에게만 주어지는</div>
              풍성한 혜택!
            </div>
          </section>
          <section className="flex flex-col gap-5 my-10 px-3">
            <Arrcodian
              body={
                <div className="flex text-base md:text-xl font-bold gap-2">
                  <CheckIcon width={24} />
                  교사 라이센스 발급
                </div>
              }
            >
              <TextCheckList
                textArr={[
                  '최초 수료 시 홈스쿨 교사 자격 부여!',
                  <>
                    '라이센스를 통해 홈스쿨, 공부방, 교습소 모두 운영 가능!
                    <br />
                    (학원은 불가. 학원 운영 희망 시 고객센터에 별도 문의)'
                  </>,
                ]}
              />
            </Arrcodian>
            <Arrcodian
              body={
                <div className="flex text-base md:text-xl font-bold gap-2">
                  <CheckIcon width={24} />
                  교재 단독 구매 가능
                </div>
              }
            >
              <TextCheckList
                textArr={[
                  'JEMM 회원만이 수료한 코스의 교재 구매 가능',
                  '구매 교재 금액의 3~5% 포인트 적립 가능',
                ]}
              />
            </Arrcodian>
            <Arrcodian
              body={
                <div className="flex text-base md:text-xl font-bold gap-2">
                  <CheckIcon width={24} />
                  커뮤니티, 컨퍼런스 등 공동체 참여 자격
                </div>
              }
            >
              <TextCheckList
                textArr={[
                  'JEMM 회원 전용 커뮤니티 참여 가능 (카페)',
                  '멤버십 한정 커뮤니티 혜택 제공',
                  '교수법 점검, 비전 나눔 등 JEMM 회원만의 특별한 컨퍼런스 모임 참여 가능',
                  '젭스영어성경홈스쿨 온라인 홍보단 ‘젬브릿지’ 지원 자격 부여',
                ]}
              />
            </Arrcodian>
          </section>
        </div>
      </div>
      <div className="relative flex flex-col items-center">
        <div className="flex flex-col gap-5 w-full max-w-[480px] py-20 px-3">
          <Arrcodian
            body={
              <div className="flex justify-center gap-3">
                <div className="text-4xl h-fit m-auto">🙋‍♀️</div>
                <div className="text-base font-bold">
                  알파벳, 파닉스도 모르는 우리 아이
                  <br />
                  <Green>KIDS vs BASIC</Green> 어떤 걸 해야할까요?
                </div>
              </div>
            }
          >
            <TextCheckList
              textArr={[
                '영어 첫 노출을 젭스홈스쿨로 시작한다면 무엇이든 상관없습니다. 언어 습득 과정에 최적화 되어 있기에 아이의 언어 수준에 맞는 코스로 진행하시면 됩니다.',
                '알파벳, 파닉스를 모르더라도 우리 말을 잘 구사하고 의사소통 능력이 뛰어나다면 바로 연령에 맞게 BASIC 시작이 가능합니다.',
              ]}
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="flex justify-center gap-3">
                <div className="text-4xl h-fit m-auto">🙋‍♀️</div>
                <div className="text-base font-bold h-fit self-center">
                  이미 <Green>초등학교 고학년</Green>인데, BASIC 시작해도
                  될까요?
                </div>
              </div>
            }
          >
            <TextCheckList
              textArr={[
                <>
                  당연하죠! BASIC BOOK 1부터 시작하면 차근차근 확실한 영어
                  실력을 쌓을 수 있습니다. <br />
                  물론, 중학생/고등학생도 아직 영어에 대한 자신감이 없다면 먼저
                  영어에 대한 재미를 붙이기 위해 BOOK 1부터 시작하는 것이
                  장기적인 학습에 유리합니다.
                </>,
              ]}
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="flex justify-center gap-3">
                <div className="text-4xl h-fit m-auto">🙋‍♀️</div>
                <div className="text-base font-bold h-fit self-center">
                  <Green>다자녀 가정</Green>은 어떻게 수업을 하면 좋을까요?
                </div>
              </div>
            }
          >
            <TextCheckList
              textArr={[
                '나이 터울이 크면 다른 레벨로 각각 진행하는 것을 추천 드립니다. 하지만, 7세와 9세 같이 나이 터울이 크지 않다면, 동일 레벨을 진행하는 것을 추천 드립니다.',
              ]}
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="flex justify-center gap-3">
                <div className="text-4xl h-fit m-auto">🙋‍♀️</div>
                <div className="text-base font-bold h-fit self-center">
                  <Green>홈스쿨 교사 교육</Green>을 반드시 받아야 하는 이유가
                  있나요?
                </div>
              </div>
            }
          >
            <TextCheckList
              textArr={[
                '하나님께선 말씀을 통해 ‘네 자녀에게 부지런히 가르치라’고 말씀하십니다. 말씀을 통해 바라본 교육의 원리를 기반으로, A.D.영어학습법의 원리와 교수법을 반드시 이해하고 아이들을 가르쳐야 하기 때문입니다.',
              ]}
            />
          </Arrcodian>
        </div>
      </div>
    </>
  );
};

const Green = ({ children }: { children: ReactNode }) => (
  <span className="text-[#129c20]">{children}</span>
);

export default HomeschoolSection9;
