import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit"
import { OrderMaster, OrderTemplate } from "../../constants/types"
import { AxiosParamType } from "."
import instance from "../../api/axios/utils.ts/instance"
import { RootState } from ".."
import { Device, PaymentMethod, ProductInstallmentType } from "../../enum"
import { LegacyOrderMasterType } from "../../constants/legacyTypes"

export type PurchaseRequestResolve = {
  authPageUrl: string
  order_master: OrderMaster
}
export interface PurchaseRequestDto {
  template_id: string
  address_1: string
  address_2: string
  contact_no: string
  message: string
  point_used: number
  post_code: string
  receiver: string
  payment_method: PaymentMethod
  device: Device
  installment_type: ProductInstallmentType | string
}

interface ItemType {
  status: string,
  purchaseInfo: OrderTemplate,
  orderMasterInfo: OrderMaster,
}
const initialState: ItemType = {
  status: '',
  purchaseInfo: null,
  orderMasterInfo: null,
}
export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    resetPurchaseInfo(state) {
      state.status = ''
      state.purchaseInfo = null
      state.orderMasterInfo = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPurchaseInfo.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getPurchaseInfo.fulfilled, (state, action: PayloadAction<OrderTemplate>) => {
        state.status = 'fulfilled';
        state.purchaseInfo = action.payload;
      })
      .addCase(getPurchaseInfo.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(reqPurchaseRequest.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(reqPurchaseRequest.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        // console.log('PG사 응답 결과 : ', action)
      })
      .addCase(reqPurchaseRequest.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(getOrderMasterComplete.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getOrderMasterComplete.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        // console.log('PG사 응답 결과 : ', action.payload)
        state.orderMasterInfo = action.payload
      })
      .addCase(getOrderMasterComplete.rejected, (state) => {
        state.status = 'rejected';
      })
  }
})
export const getPurchaseInfo = createAsyncThunk(
  'asyncThunk/getPurchaseInfo',
  async ({ url, params }: AxiosParamType) => {
    try {
      const response = await instance.get(url, { params });
      return response.data;
    } catch (error) {
      console.error('[axios] getPurchaseInfo : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const reqPurchaseRequest = createAsyncThunk(
  'asyncThunk/reqPurchaseRequest',
  async ({ url, data }: { url: string, data: PurchaseRequestDto }) => {
    try {
      const response = await instance.post(url, data);
      return response.data;
    } catch (error) {
      console.error('[axios] getPurchaseInfo : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const getOrderMasterComplete = createAsyncThunk(
  'asyncThunk/getOrderMasterComplete',
  async ({ url, orderMasterId }: { url: string, orderMasterId: string }) => {
    try {
      const path = url.concat('/').concat(orderMasterId)
      const response = await instance.get(path);
      response.data = DataParser(response.data)
      return response.data;
    } catch (error) {
      console.error('[axios] getPurchaseInfo : ', error)
      return isRejectedWithValue(error)
    }
  }
)

const DataParser = (data: LegacyOrderMasterType): OrderMaster => {

  const rtn: OrderMaster = {
    payment_method: data.payment_method,
    post_code: data.post_code,
    address_1: data.address_1,
    address_2: data.address_2,
    contact_no: data.contact_no,
    balance: data.balance,
    user: data.user,
    template: data.template,
    transactions: data.transactions,
  }

  return rtn
}

export const { resetPurchaseInfo } = purchaseSlice.actions
export const selectPurchaseInfo = (state: RootState) => state.purchase.purchaseInfo
export const selectOrderMasterInfo = (state: RootState) => state.purchase.orderMasterInfo
export default purchaseSlice.reducer