import TextWinds from '../../../components/common/TextWinds';
import '../../../styles/mypage/mypage.css';
import { orderDetailType } from '../../../constants/types/mypage-type';
import { numberWithCommas } from '../../../api/utils/format/numbers';

const PaymentDetail = ({ orderDetail }: { orderDetail: orderDetailType }) => {
  // const location = useLocation();
  // const orderDetail = location.state;
  const getPaytype = () => {
    switch (orderDetail.payment_method) {
      case 'CREDIT_CARD':
        return '신용카드 결제';
      case 'DEPOSIT':
        return '무통장 입금';
      default:
        console.error(orderDetail.payment_method);
        return '결제수단 확인 불가';
    }
  };
  return (
    <div className="flex flex-col gap-y-4 px-3 py-[14px] bg-gray0">
      <TextWinds type="sub_title01">결제 상세</TextWinds>
      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          수단
        </TextWinds>
        <TextWinds type="content_body4">{getPaytype()}</TextWinds>
      </div>
      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          결제 금액
        </TextWinds>
        {numberWithCommas(
          orderDetail.template.total_price -
            (orderDetail.point_used ? orderDetail.point_used : 0)
        )}{' '}
        원
      </div>
      {/* <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    처리 상태
                </TextWinds>
                <TextWinds type="content_body4">승인 대기중</TextWinds>
            </div> */}
      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          포인트 사용
        </TextWinds>
        <TextWinds type="content_body4">
          {numberWithCommas(
            orderDetail.point_used ? orderDetail.point_used : 0
          )}{' '}
          포인트
        </TextWinds>
      </div>
    </div>
  );
};

export default PaymentDetail;
