import React, { Dispatch, SetStateAction } from 'react';
import { DeliveryProps } from '../../constants/types';
import TextWinds from '../common/TextWinds';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Chip } from '@material-tailwind/react';
import {
  deleteDeliveryInfo,
  getDeliveryInfoList,
  setEditDeliveryInfo,
} from '../../store/features/orderInfoSlice';
import { useAppDispatch } from '../../store/hook';
import Buttons from '../common/Buttons';
import { phoneFormat } from '../../api/utils/format/numbers';

interface DeliveryInfoCardProps {
  deliveryInfo: DeliveryProps;
  onClick?: () => void;
  editable?: boolean;
  setState?: Dispatch<SetStateAction<'form' | 'none' | 'list'>>;
}
const DeliveryInfoCard = ({
  deliveryInfo,
  onClick,
  editable,
  setState,
}: DeliveryInfoCardProps) => {
  const dispatch = useAppDispatch();
  const editClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    deliveryInfo: DeliveryProps
  ) => {
    setState && setState('form');
    dispatch(setEditDeliveryInfo(deliveryInfo));
    e.stopPropagation();
  };

  const deleteClick = (deliveryInfo: DeliveryProps) => {
    if (deliveryInfo.isDefault) {
      window.alert('기본 배송지는 삭제할 수 없습니다.');
      return;
    }

    const result: boolean = window.confirm(
      `[${deliveryInfo.name}] 배송지를 삭제하시겠습니까?`
    );
    if (result) {
      dispatch(
        deleteDeliveryInfo({ url: '', params: { id: deliveryInfo.id } })
      ).then(() => {
        dispatch(getDeliveryInfoList({ url: '', params: {} }));
      });
      // 삭제 호출 후 목록 새로 조회
    }
  };

  if (!deliveryInfo) {
    return (
      <Buttons
        className="flex items-center px-[14px] py-[12px] border-[1px] border-gray5 mt-[16px] bg-white w-full rounded-md hover:bg-gray1"
        onClick={onClick}
        type={'text'}
        color={'gray7'}
      >
        <div className="flex flex-col gap-[6px] text-left w-full">
          <div className="flex justify-between">
            <div className="flex items-center">
              <TextWinds type="caption1">배송지를 추가해주세요.</TextWinds>
            </div>
            <div>
              <ChevronRightIcon
                strokeWidth={2.5}
                className="w-10 h-10"
                fill="gray"
              />
            </div>
          </div>
        </div>
      </Buttons>
    );
  }

  return (
    <Buttons
      className="flex items-center px-[14px] py-[12px] border-[1px] border-gray5 mt-[16px] bg-white w-full rounded-md hover:bg-gray1"
      onClick={onClick}
      type={'text'}
      color={'gray7'}
    >
      <div className="flex flex-col gap-[6px] text-left w-full">
        <div className="flex justify-between">
          <div className="flex flex-col justify-center shrink-1">
            <div>
              {deliveryInfo.isDefault && (
                <Chip
                  variant="filled"
                  className="bg-purple1 inline-flex h-[20px] px-[4px] flex-shrink-0 text-purple5 mb-2"
                  value="기본 배송지"
                />
              )}
            </div>
            <TextWinds type="content_rel">{deliveryInfo.name}</TextWinds>
            <TextWinds type="caption1" color="gray7">
              {deliveryInfo.address}
            </TextWinds>
            <TextWinds type="caption1" color="gray7">
              {deliveryInfo.detailAddress}
            </TextWinds>
            <TextWinds type="caption1">
              {phoneFormat(deliveryInfo.phone)}
            </TextWinds>
          </div>

          <div className="flex justify-center items-center shrink-0">
            {editable ? (
              <div className="flex flex-col gap-4">
                <div
                  onClick={(e) => {
                    editClick(e, deliveryInfo);
                  }}
                >
                  <TextWinds type="caption1">수정하기</TextWinds>
                </div>
                {!deliveryInfo.isDefault && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteClick(deliveryInfo);
                    }}
                  >
                    <TextWinds type="caption1" className="text-red-500">
                      삭제하기
                    </TextWinds>
                  </div>
                )}
              </div>
            ) : (
              <ChevronRightIcon
                strokeWidth={2.5}
                className="w-10 h-10"
                fill="gray"
              />
            )}
          </div>
        </div>
      </div>
    </Buttons>
  );
};

export default DeliveryInfoCard;
