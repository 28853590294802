import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../api/storage/localStorage';
import TextWinds from '../../components/common/TextWinds';
import instance from '../../api/axios/utils.ts/instance';
import { useAppDispatch } from '../../store/hook';
import { getMyAuth } from '../../store/features/mySlice';
import HFinput from '../../components/common/input/HFinput';
import HFbutton from '../../components/common/input/HFbutton';
import { openAlert } from '../../store/features/modalSlice';

export interface LoginFormValue {
  userId: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValue>();

  const onSubmit: SubmitHandler<LoginFormValue> = async (loginData) => {
    await instance
      .post('/v1/auth/sign-in', {
        uid: loginData.userId,
        password: loginData.password,
      })
      .then((res) => {
        dispatch(getMyAuth({ url: '' }));
        const getToken = res.data.data;
        const setStorageItems = {
          userId: loginData.userId,
          accessToken: getToken.accessToken,
          userInfo: getToken.userInfo,
        };
        login(setStorageItems);
        if (getToken.userInfo.type === 'STUDENT') {
          navigate('/student');
          return;
        }

        // 로컬스토리지에 redirect url이 있을 경우 해당 페이지로 이동
        const redirectUrl = localStorage.getItem('redirect');

        if (redirectUrl) {
          window.location.href = redirectUrl;
          localStorage.removeItem('redirect');
          return;
        }

        // 로그인 후 홈으로 이동
        navigate('/');
      })
      .catch((err) => {
        dispatch(
          openAlert({
            message:
              '로그인에 실패하였습니다.\n아이디와 비밀번호를 확인해주세요.',
          })
        );
        console.error(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2">
          <TextWinds type="content_rel">아이디</TextWinds>
          <HFinput
            register={register('userId', {
              required: { value: true, message: '아이디를 입력해주세요.' },
            })}
            error={errors.userId?.message}
            id="userId"
            placeholder="아이디"
            autoFocus
          />
        </div>
        <div className="flex flex-col gap-2">
          <TextWinds type="content_rel">비밀번호</TextWinds>
          <HFinput
            register={register('password', {
              required: { value: true, message: '비밀번호를 입력하세요.' },
            })}
            error={errors.password?.message}
            id="password"
            type="password"
            placeholder="비밀번호"
          />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex gap-4">
          <Link to={'/signin/id'} className="hover:font-bold">
            아이디 찾기
          </Link>
          <Link to={'/signin/password'} className="hover:font-bold">
            비밀번호 초기화
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-6">
        <HFbutton
          type="Filled"
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          로그인
        </HFbutton>
        <HFbutton type="Outlined" onClick={() => navigate('/signup')}>
          회원가입
        </HFbutton>
      </div>
    </form>
  );
};

export default Login;
