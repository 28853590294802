import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";

interface CardProps {
  img: string;
  tag?: string;
  title: string;
  desc?: string;
  author?: { name: string; img: string };
  date?: string;
}

export const ImageCards = ({ img, tag, title, desc, author, date }: CardProps) => {
  return (
    <Card className="h-[250px] hover:bg-gray1 cursor-pointer group">
      <CardHeader floated={false} shadow={false} color="transparent">
        <img src={img} alt={title} className="h-auto scale-100 group-hover:scale-105 transition-transform duration-500 ease-in-out object-cover aspect-4/3" />
      </CardHeader>
      <CardBody>
        <Typography as="a" href="#" variant="small" color="blue-gray" className="ml-1 font-bold">
          {title}
        </Typography>
      </CardBody>
    </Card>
  );
};
