import { isAxiosError } from 'axios';
import { ErrorReturnType, OrderItem } from '../../constants/types';
import { cartCheckedProps } from '../../store/features/cartSlice';
import { shutdownChannelTalk } from '../../components/ChannelTalk';
import { TodoMgmtSubTypeName } from '../../enum';
import html2canvas from 'html2canvas';

type OriginalObject = {
  [key: string]: any;
};

type TransformedObject = {
  [key: string]: any;
};

export function transformKeys(obj: OriginalObject, value: string) {
  const result: TransformedObject = {};

  Object.keys(obj).forEach((key) => {
    const newKey = obj[key] || key;
    if (typeof newKey === 'string' && key === 'key') {
      // result[newKey] = obj[key];
      result[newKey] = value;
    }
  });

  return result;
}
export function groupBy<T>(
  iterable: Iterable<T>,
  fn: (item: T) => string | number
) {
  return [...iterable].reduce<Record<string, T[]>>((groups, curr) => {
    const key = fn(curr);
    const group = groups[key] ?? [];
    group.push(curr);
    return { ...groups, [key]: group };
  }, {});
}

export function updateTarget(
  arr: cartCheckedProps[],
  id: string,
  updatedData: Partial<cartCheckedProps>
): cartCheckedProps[] {
  const result = arr.map((item) =>
    item.id === id ? { ...item, ...updatedData } : item
  );
  return result;
}

export function calcDateClone(
  date: Date,
  calc: { day?: number; month?: number; year?: number; sec?: number }
) {
  const result = new Date(date);
  calcDate(result, calc);
  return result;
}

export function calcDate(
  date: Date,
  calc: { day?: number; month?: number; year?: number; sec?: number }
) {
  if (calc.day) date.setDate(date.getDate() + calc.day);
  if (calc.month) date.setMonth(date.getMonth() + calc.month);
  if (calc.year) date.setFullYear(date.getFullYear() + calc.year);
  if (calc.sec) date.setSeconds(date.getSeconds() + calc.sec);
}

export function toDay(date: Date = new Date()) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
}

export const handleCopyClipBoard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export function dateFormattingToYYYYMMdd(date: Date) {
  const regStr = date
    .toLocaleDateString()
    .replace(/\s/gi, '')
    .replace(/\.$/g, '');
  return regStr.replaceAll('.', '-').toString();
}

export function changeTimestamp(date: any) {
  return date ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$1.$2.$3') : '';
}

export function calcRemainingPeriod(endDate: Date) {
  let remainingPeriod = Math.abs(endDate.getTime() - new Date().getTime());
  remainingPeriod = Math.ceil(remainingPeriod / (1000 * 60 * 60 * 24));

  return remainingPeriod;
}

export const getRandom = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min) + min);

export const calcPercent = (startDate: Date, endDate: Date) => {
  // 전체 기간 중 오늘부터 종료일까지 남은 기간
  const remainPeriod = endDate.getTime() - new Date().getTime();
  // 만약 종료일이 이미 지난 경우 0%를 반환합니다.
  if (remainPeriod <= 0) {
    return 0;
  }

  // 전체 기간 (종료일 - 오늘의 시작일)
  const totalPeriod = endDate.getTime() - startDate.getTime();

  const percent = Math.ceil((remainPeriod / totalPeriod) * 100);
  return percent;
};

export const totalDay = (startDate: Date, endDate: Date) => {
  const totalPeriod = Math.abs(startDate.getTime() - endDate.getTime());
  const totalDay = Math.ceil(totalPeriod / (1000 * 60 * 60 * 24));
  return totalDay;
};

export const remainDay = (endDate: Date) => {
  const remainPeriod = Math.abs(endDate.getTime() - new Date().getTime());
  const remainDay = Math.ceil(remainPeriod / (1000 * 60 * 60 * 24));
  return remainDay;
};

/**
 * YYYY년 MM월 DD일
 * @param date
 */
export const dateToKorean = (date: Date) => {
  return date.toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
/**
 * YYYY/MM/DD
 */
export const dateToSlash = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat('ja-JP', options).format(date); // 'ja-JP' 형식을 사용하여 'year/month/day'로 지정
  return formattedDate.replace(/\s/g, ''); // 공백 제거
};

export const openNewWindow = (url: string) => {
  window.open(url, '_blank');
};

export function deleteCookie(
  name: string,
  path: string = '/',
  domain?: string
): void {
  let cookieStr = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;

  if (domain) {
    cookieStr += ` domain=${domain};`;
  }

  document.cookie = cookieStr;
}

export const logout = (gotoHome?: boolean | undefined) => {
  const loginInfo = [
    'persist:root',
    'userId',
    'accessToken',
    'type',
    'isActive',
  ];
  loginInfo.map((item) => localStorage.removeItem(item));
  if (gotoHome === undefined || gotoHome) {
    window.location.href = '/';
  }
  shutdownChannelTalk();
};

export function itemNameFormatter<
  T extends { [key: string]: any },
  K extends keyof T
>(items: T[], key: K) {
  let rtn = '';
  if (!items) return rtn;

  if (items.length === 0) return rtn;

  rtn = items[0][key];

  if (items.length > 1) {
    rtn += ` 외 ${items.length - 1}건`;
  }

  return rtn;
}

export const getItemName = (orderItems: OrderItem[]) => {
  if (orderItems.length < 1) return '';

  if (orderItems.length === 1) return orderItems[0].product.name;

  return `${orderItems[0].product.name} 외 ${orderItems.length - 1}건`;
};

export function filterAndUpperCase(input: string): string {
  const filtered = input.replace(/[^a-zA-Z ]/g, '');
  const upperCased = filtered.toUpperCase();
  return upperCased;
}

export const getErrorMessages = (error: any) => {
  if (isAxiosError(error) && error.response) {
    try {
      const data = error.response?.data.error as ErrorReturnType;

      const msg = data.message.reduce(
        (acc, cur) =>
          acc + `[${data.statusCode}] ` + cur.constraints.join(',') + '\n',
        ''
      );
      return msg;
    } catch (e) {
      return error.response.data.error;
    }
  } else {
    return '알 수 없는 오류가 발생했습니다.';
  }
};

export const check14UnderAge = (birth: Date): boolean => {
  const today = new Date();
  const birthDate = new Date(birth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age < 14;
};

// 콘텐츠 플레이어 창 오폰 및 데이터 전달. 새 창이 열리면 기존 창은 닫힘.
export const openContentsView = (
  data: {
    type: string;
    contents: any;
  },
  setWindow?: (value: React.SetStateAction<Window>) => void
) => {
  const openWindow = () => {
    // 새 창 열기
    const newWindow = window.open(
      '/contents',
      'ContentsView',
      'location=no, menubar=no,'
    );

    if (newWindow) {
      newWindow.name = 'popup';
      newWindow.onload = () => {
        newWindow.postMessage(data, '*');
      };
    }
    return newWindow;
  };

  if (setWindow) {
    setWindow((prev) => {
      if (prev !== undefined) {
        prev.close();
      }
      return openWindow();
    });
  } else {
    openWindow();
  }
};

export const getSubTypeName = (subType: string) => {
  return TodoMgmtSubTypeName[subType as keyof typeof TodoMgmtSubTypeName];
};

export const uidRegExp =
  /^[a-zA-Z][a-zA-Z0-9_]{4,19}$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const downloadDiv = (id: string, filename: string) => {
  const style = document.createElement('style');
  document.head.appendChild(style);
  style.sheet?.insertRule(
    'body > div:last-child img { display: inline-block; }'
  );

  const imageDiv = document.getElementById(id);
  imageDiv!.style.display = 'block';

  const img = html2canvas(imageDiv, { scale: 10 }).then((canvas) => {
    style.remove();

    const imgData = canvas.toDataURL('image/png');
    // **다운 받지 않고 브라우저 확인용**
    // sample.current!.src = imgData;
    const link = document.createElement('a');
    link.href = imgData;
    link.download = `${filename}.png`;
    link.click();
  });
  return img;
};
