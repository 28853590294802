import { DatePicker, Input, Radio } from 'antd';
import { Control, Controller, Path, RegisterOptions } from 'react-hook-form';
import HFbutton from './HFbutton';
import { useState } from 'react';
import { phoneFormat } from '../../../api/utils/format/numbers';
import { timeLeftCheck, timeLeftToString } from '../../../controllers/useTimer';
import useSmsHook from '../../../controllers/useSmsHook';

type FormRowType =
  | 'text'
  | 'password'
  | 'text-button'
  | 'date'
  | 'gender'
  | 'phone'
  | 'phone-confirm';
export interface FormRowProps<T> {
  type: FormRowType;
  name: Path<T>;
  control: Control<T>;
  className?: string;
  placeholder?: string;
  rules?: RegisterOptions;
  error?: boolean;
  disabled?: boolean;
  textButtonProp?: {
    text: string;
    action: () => void;
    disabled?: boolean;
  };
  autoComplete?: 'on' | 'off';
}

const FormRow = <T,>({
  type,
  name,
  rules,
  control,
  className,
  placeholder,
  error,
  disabled,
  textButtonProp,
  autoComplete = 'on',
}: FormRowProps<T>) => {
  switch (type) {
    case 'text':
    case 'password':
    default:
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Input
              name={name}
              type={type}
              size="large"
              placeholder={placeholder}
              status={!error ? undefined : 'error'}
              value={field.value as string}
              onChange={(e) => field.onChange(e.target.value)}
              className={`p-3 rounded ${className}`}
              disabled={disabled}
              autoComplete={autoComplete}
            />
          )}
        />
      );
    case 'text-button':
      if (!textButtonProp) {
        throw new Error('textButton data is required');
      }
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <div className="relative flex w-full">
              <Input
                type="text"
                size="large"
                placeholder={placeholder}
                status={!error ? undefined : 'error'}
                value={field.value as string}
                onChange={(e) => field.onChange(e.target.value)}
                className={`p-3 rounded ${className}`}
                disabled={disabled}
              />
              <div className="!absolute right-2 top-1.5 rounded text-sm">
                <HFbutton
                  height={40}
                  onClick={() => textButtonProp.action()}
                  disabled={textButtonProp.disabled}
                >
                  {textButtonProp.text}
                </HFbutton>
              </div>
            </div>
          )}
        />
      );
    case 'date':
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <DatePicker
              size="large"
              placeholder={placeholder}
              value={field.value}
              onChange={(date) => field.onChange(date)}
              className={`p-3 rounded ${className}`}
            />
          )}
        />
      );
    case 'gender':
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <Radio.Group
              size="large"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              className="w-full grid grid-cols-2"
              disabled={disabled}
            >
              <Radio.Button
                value="MALE"
                className="text-center"
                style={{
                  borderRadius: '0.25rem 0 0 0.25rem',
                  height: '50px',
                  lineHeight: '50px',
                }}
              >
                남
              </Radio.Button>
              <Radio.Button
                value="FEMALE"
                className="text-center"
                style={{
                  borderRadius: '0 0.25rem 0.25rem 0',
                  height: '50px',
                  lineHeight: '50px',
                }}
              >
                여
              </Radio.Button>
            </Radio.Group>
          )}
        />
      );
    case 'phone':
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <FormRowPhone
              value={field.value as string}
              onChange={field.onChange}
              className={className}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
        />
      );
    case 'phone-confirm':
      return (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <FormRowPhoneConfirm
              value={field.value as string}
              onChange={field.onChange}
              className={className}
            />
          )}
        />
      );
  }
};

const FormRowPhoneConfirm = ({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: (phone: string) => void;
  className?: string;
}) => {
  const [phone, setPhone] = useState<string>(value);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const { sendVerifyNumber, checkVerifyNumber, timeLeft } = useSmsHook();

  // 인증번호 전송
  const postSms = async () => {
    if (!phone) {
      alert('전화번호를 입력해주세요.');
      return;
    }
    sendVerifyNumber(phone).then((msg) => {
      alert(msg);
    });
  };

  // 인증코드 검증
  const putSms = async () => {
    if (!verificationCode) {
      alert('인증번호를 입력해주세요.');
      return;
    }
    checkVerifyNumber(phone, verificationCode).then((res) => {
      if (res.confirm) {
        setIsConfirm(true);
        onChange(phone);
      }
      alert(res.msg);
    });
  };

  // 전화번호 입력시 '-' 제거 및 인증 초기화
  const handlePhoneChange = (value: string) => {
    setPhone(value);
    setIsConfirm(false);
    setVerificationCode('');
  };

  return (
    <>
      <div className="relative flex w-full">
        <FormRowPhone
          placeholder="전화번호"
          value={phone}
          onChange={handlePhoneChange}
          disabled={isConfirm}
        />
        <div className="!absolute right-2 top-1.5 rounded text-sm w-20">
          <HFbutton
            height={40}
            onClick={() => postSms()}
            disabled={isConfirm || !phone}
          >
            {timeLeftCheck(timeLeft) ? timeLeftToString(timeLeft) : '인증번호'}
          </HFbutton>
        </div>
      </div>
      <div className="relative w-full">
        <Input
          type="number"
          size="large"
          placeholder="인증번호"
          className={`p-3 rounded ${className}`}
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          disabled={isConfirm}
        />
        <div className="!absolute right-2 top-1.5 rounded text-sm w-20">
          <HFbutton
            height={40}
            onClick={() => putSms()}
            disabled={isConfirm || !verificationCode}
          >
            {isConfirm ? '인증완료' : '인증확인'}
          </HFbutton>
        </div>
      </div>
    </>
  );
};

const FormRowPhone = ({
  value,
  onChange,
  className,
  placeholder,
  disabled,
}: {
  value: string;
  onChange: (phone: string) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const handlePhoneChange = (value: string) => {
    // 숫자 제외 모든 문자 제거
    onChange(value.replace(/[^0-9]/g, ''));
  };
  return (
    <Input
      type="text"
      size="large"
      placeholder={placeholder}
      className={`p-3 rounded ${className}`}
      value={phoneFormat(value)}
      onChange={(e) => handlePhoneChange(e.target.value)}
      disabled={disabled}
      autoComplete="on"
    />
  );
};

export default FormRow;
