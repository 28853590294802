import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import Confetti, { ConfettiRef } from './WmwConfetti';
import medalImg from '../img/sec01-medal.png';
import ribbon01Img from '../img/sec01-ribbon01.png';
import ribbon02Img from '../img/sec01-ribbon02.png';
import wmwbook from './img/wmwbook.png';
import jsbook01 from './img/jsbook01.png';
import jsbook02 from './img/jsbook02.png';

const WmwSection1 = () => {
  const confettiRef = useRef<ConfettiRef>(null);

  const triggerConfetti = () => {
    confettiRef.current?.startFire();
  };
  return (
    <div className="w-full">
      <div className="relative w-full pb-20 max-h-screen z-1">
        <Background />

        <motion.img
          src={ribbon01Img}
          className="absolute left-0 bottom-0 w-1/4 m-auto max-w-[400px] -translate-y-1/3"
          initial={{ width: 0 }}
          whileInView={{ width: '25%' }}
          transition={{
            delay: 0.1,
          }}
        />
        <motion.img
          src={ribbon02Img}
          className="absolute right-0 bottom-0 w-1/3 m-auto max-w-[400px]"
          initial={{ width: 0 }}
          whileInView={{ width: '33%' }}
          transition={{ delay: 0.1 }}
        />
        <div className="absolute left-0 top-0 w-full h-full">
          <div className="text-2xl md:text-3xl text-white text-center mt-10 h-full">
            <span className="font-bold">초등학생 전도</span>에 최적화된
            <br />
            <span className="font-bold">주일학교 프로그램이 있다?</span>
          </div>
          <div className="w-full absolute left-0 bottom-1/2 transform translate-y-3/4 md:translate-y-1/2">
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: -10 }}
              transition={{
                repeatType: 'reverse',
                duration: 2,
              }}
            >
              <motion.img
                src={medalImg}
                className="w-[40%] max-w-[400px] m-auto"
                animate={{ scale: 1.05 }} // Y축을 기준으로 360도 회전
                transition={{
                  repeatType: 'reverse',
                  repeat: Infinity,
                  duration: 2,
                  // ease: 'linear',
                }}
                onAnimationStart={triggerConfetti}
              />
            </motion.div>
            <div className="text-center mt-3 text-xl md:text-3xl">
              정철영어성경학교의
              <br />
              <span className="font-bold">15년 스테디셀러 프로그램</span>
            </div>
          </div>
        </div>
        <div className="absolute left-0 top-0 w-full aspect-square md:aspect-[4/3] z-2">
          <Confetti ref={confettiRef} />
        </div>
      </div>
      <div className="flex flex-col gap-3 w-full max-w-[520px] p-3 m-auto">
        <motion.div
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          onAnimationStart={triggerConfetti}
          className="relative bg-[#e6ebff] p-5 rounded-lg"
        >
          <div className="absolute px-10 py-2 text-white text-lg font-semibold top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#94abff] rounded-full shadow-lg">
            초등부
          </div>
          <div className="text-center text-2xl md:text-4xl font-extrabold p-5">
            <span className="text-[#0d0bea]">W</span>
            ho
            <span className="text-[#0d0bea]"> M</span>
            ade the
            <span className="text-[#0d0bea]"> W</span>
            orld? Series
          </div>
        </motion.div>
        <div className="grid grid-cols-2 gap-3">
          <div className="bg-[#e6ebff] p-3 rounded-lg flex flex-col gap-3">
            <img src={wmwbook} className="aspect-[4/3] object-contain" />
            <div className="w-fit m-auto text-xs md:text-base">
              <span className="font-extrabold">W</span>
              ho
              <span className="font-extrabold"> M</span>
              ade the
              <span className="font-extrabold"> W</span>
              orld?
            </div>
          </div>
          <div className="bg-[#e6ebff] p-3 rounded-lg flex flex-col gap-3">
            <div className="relative aspect-[4/3] w-full">
              <img
                src={jsbook01}
                alt="jsbook01"
                className="absolute top-0 left-0 w-[58%]"
              />
              <img
                src={jsbook02}
                alt="jsbook02"
                className="absolute bottom-0 right-0 w-[58%]"
              />
            </div>
            <div className="w-fit m-auto text-xs md:text-base">
              <span className="font-extrabold">J</span>
              esus
              <span className="font-extrabold"> S</span>
              tory
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Background = () => (
  <svg viewBox="0 0 100 70">
    <defs>
      <rect id="shape" width="100%" height="100%" />
      <filter id="noise">
        <feTurbulence
          type="fractalNoise"
          baseFrequency="19.5"
          numOctaves="10"
          result="turbulence"
        />
        <feComposite
          operator="in"
          in="turbulence"
          in2="SourceAlpha"
          result="composite"
        />
        <feColorMatrix in="composite" type="luminanceToAlpha" />
        <feBlend in="SourceGraphic" in2="composite" mode="color-burn" />
      </filter>
      <mask id="gradient">
        <linearGradient id="fade" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor="black" stopOpacity="0.6" />
          <stop offset="65%" stopColor="white" stopOpacity="0.9" />
          <stop offset="75%" stopColor="white" stopOpacity="1" />
        </linearGradient>
        <use href="#shape" fill="url('#fade')" />
      </mask>
    </defs>
    <use
      href="#shape"
      fill="#508aff"
      mask="url(#gradient)"
      filter="url('#noise')"
    />
  </svg>
);

export default WmwSection1;
