import React, { useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import Datepicker from '../../../components/mypage/Datepicker';
import PurchaseList from './PurchaseList';
import { calcDateClone, toDay } from '../../../api/utils/util-func';

const MypagePurchase = () => {
  const [startDate, setStartDate] = useState(
    calcDateClone(toDay(), { month: -1 })
  );
  const [endDate, setEndDate] = useState(
    calcDateClone(toDay(), { day: +1, sec: -1 })
  );

  const calcEndDate = (date: Date) => {
    setEndDate(calcDateClone(toDay(date), { day: +1, sec: -1 }));
  };

  const TABS_MENU = [
    {
      label: '주문완료',
      value: 'ALL',
    },
    {
      label: '배송중',
      value: 'SHIPPING',
    },
    {
      label: '구매완료',
      value: 'COMPLETED',
    },
  ];

  const [activeTab, setActiveTab] = useState(TABS_MENU[0].value);

  const tabClickHandler = (value: string) => {
    setActiveTab(value);
  };

  return (
    <>
      <section>
        <div className="hidden lg:block lg:mb-6">
          <TextWinds type="title_h2">구매 내역</TextWinds>
        </div>
        <div className="flex flex-col gap-y-[18px] mb-4">
          <Datepicker
            startDate={startDate}
            endDate={endDate}
            starDateHandle={setStartDate}
            endDateHandle={calcEndDate}
          />
        </div>
        <PurchaseList
          activeTab={activeTab}
          startDate={startDate}
          endDate={endDate}
        />
      </section>
    </>
  );
};

export default MypagePurchase;
