import React, { useContext, useEffect, useState } from 'react';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { studentProfileType } from '../../../constants/types/student-type';
import StudentProfileComp from './StudentProfileComp';
import { StudentProfileContext } from './StudentProfileContext';
import StudentClassComp from './StudentClassComp';
import StudentGuardianComp from './StudentGuardianComp';
import StudentPwComp from './StudentPwComp';

const StudentProfilePage = () => {
  const { studentProfile } = useContext(StudentProfileContext);

  return (
    <div className="w-full bg-gray0 py-10">
      {studentProfile && (
        <div className="max-w-[600px] m-auto p-10 shadow-card bg-white rounded-lg flex flex-col gap-10">
          <StudentProfileComp />
          <hr />
          <StudentPwComp />
          <hr />
          <StudentClassComp />
          <hr />
          <StudentGuardianComp />
        </div>
      )}
    </div>
  );
};

export default StudentProfilePage;
