import { PhotoIcon } from '@heroicons/react/24/outline';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
} from '@material-tailwind/react';
import React from 'react';
import { numberWithCommas } from '../../../../../api/utils/format/numbers';
import TextWinds from '../../../../../components/common/TextWinds';
import { Link } from 'react-router-dom';
import { BoostUpTypes } from '../../../../../constants/types';
import { current } from '@reduxjs/toolkit';

interface BoostupCardProps {
  data: BoostUpTypes;
  classId: string;
}
const BoostupCard = ({
  data: { id, name, origin_price, current_price, thumbnail },
  classId,
}: BoostupCardProps) => {
  const handleNewTabs = (path: string) => {
    window.open(path, '_blank');
  };
  return (
    <>
      <Card className="shadow-card ">
        <Button
          className="bg-white border border-gray2 hover:border-purple2 rounded-xl shadow-none h-full p-0"
          onClick={() => handleNewTabs(`./boostup/detail/${id}`)}
        >
          <CardHeader className="aspect-square m-0 p-0 shadow-none rounded-none rounded-t-xl">
            <img
              className="w-full h-full object-cover"
              src={thumbnail.access_url}
            />
          </CardHeader>
          <CardBody className="p-2">
            <div className="content-start">
              <TextWinds
                type="content_body3"
                color="gray8"
                className="w-full max-h-12 line-clamp-2 text-left"
              >
                {name}
              </TextWinds>
            </div>
            <div className="">
              <div className="">
                <div className="flex flex-row items-center justify-end">
                  <TextWinds type="content_body3">
                    {numberWithCommas(current_price)}
                  </TextWinds>
                  <TextWinds type="content_body4">원</TextWinds>
                </div>
                <div className="flex flex-row items-center justify-end">
                  <TextWinds type="caption2" className="line-through">
                    {numberWithCommas(origin_price)}
                  </TextWinds>
                  <TextWinds type="caption2">원</TextWinds>
                </div>
              </div>
            </div>
          </CardBody>
        </Button>
      </Card>
    </>
  );
};

export default BoostupCard;
