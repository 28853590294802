import React from 'react';
import html2canvas from 'html2canvas';
import TextWinds from '../common/TextWinds';
import Buttons from '../common/Buttons2';
import { CertificateImgEnum } from '../../constants/types/mypage-type';

interface CertificateImgType {
  name: string;
  type: CertificateImgEnum;
  date: string;
  readonly?: boolean;
}
const CertificateImg = ({
  name,
  type,
  date,
  readonly = false,
}: CertificateImgType) => {
  const printImg = (id: string) => {
    const imageDiv = document.getElementById(id);
    imageDiv!.style.display = 'block';

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }'
    );

    const img = html2canvas(imageDiv, { scale: 10 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // **다운 받지 않고 브라우저 확인용**
      // sample.current!.src = imgData;
      const link = document.createElement('a');
      link.href = imgData;
      link.download = '수료증';
      link.click();
    });
  };
  // **다운 받지 않고 브라우저 확인용**
  // const sample = useRef<HTMLImageElement>(null);

  const getImgURL = (type: CertificateImgEnum) => {
    return process.env.PUBLIC_URL + `/images/mypage/certification/${type}.png`;
  };
  return (
    <>
      {/* // **다운 받지 않고 브라우저 확인용** */}
      {/* <img ref={sample} src="" width={300} height={100}/> */}
      <div className="border border-gray-400 p-1 rounded-md">
        <div id={`${name}${date}`}>
          <div className="relative">
            <img
              src={getImgURL(type)}
              alt={name}
              className="max-w-[250px] w-[250px]"
            />
            <h1 className="title absolute top-[156px] left-0 text-[30px] text-center w-full">
              {name}
            </h1>
            <h2 className="date absolute top-[278px] left-[48px] text-[9px] w-full">
              {date}
            </h2>
          </div>
        </div>
        {!readonly && (
          <div className="flex justify-between">
            <div>
              <TextWinds type="content_body3" xlType="sub_title01">
                {name}
              </TextWinds>
              <TextWinds type="caption1" xlType="content_body4" color="gray8">
                발급일 {date}
              </TextWinds>
            </div>
            <Buttons
              variant="text"
              color="purple5"
              type="text"
              onClick={() => printImg(`${name}${date}`)}
            >
              <TextWinds type="button2" color="purple5">
                다운로드
              </TextWinds>
            </Buttons>
          </div>
        )}
      </div>
    </>
  );
};

export default CertificateImg;
