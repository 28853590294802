import { PlayIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hook';
import { selectContentInfo } from '../../../../store/features/takeclassSice';

const VimeoContentView = () => {
  const contentId = useParams().contentId;
  // 2. video 데이터 바인딩 처리
  const contentInfo = useAppSelector(selectContentInfo);

  useEffect(() => {
    // 1. [axios call] 토큰체크
  }, []);
  const [playing, setPlaying] = useState(true);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);

  const fetchedRef = useRef(false);
  const handleVideoStart = () => {
    if (fetchedRef.current) return;
    fetchedRef.current = true;
  };

  const isLoading = (): boolean => {
    if (!contentInfo) {
      return true;
    } else {
      return false;
    }
  };

  return isLoading() ? (
    <></>
  ) : (
    <div className="flex justify-center items-center h-screen w-screen bg-black p-4 box-border">
      <div className="relative w-full h-full max-w-screen-lg max-h-screen-lg">
        <ReactPlayer
          className="react-player aspect-video"
          url={
            contentInfo.youtube_url ||
            contentInfo.vimeoKey ||
            contentInfo.exe_file?.access_url
          }
          width="100%"
          height="auto"
          controls={true}
        />
      </div>
    </div>
  );
};

export default VimeoContentView;
