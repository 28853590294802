import { CardItemProps } from '../components/card/cards';
import {
  CartProps,
  OptionProps,
  PlanProps,
  CouponDataType,
  ExtendProps,
  CourseSummaryType,
  OrderProps,
  MypageNoticeProps,
  MypageEventProps,
  MypageCouponProps,
} from '../constants/types';
import { studentStatusType } from '../constants/types/student-type';

// 무이자 할부 정보
export const INTEREST_FREE_INSTALLMENT = `11월 무이자 할부
\nㅤ\n
농협/비씨/우리 2~6개월\n
신한 2~5개월\n
국민/삼성/롯데/하나/현대 2~3개월`;

export const ICON_ARRAY_LIST = [
  {
    img: 'svg/home/findacourse.svg',
    alt: 'findacourse',
    text: '코스 찾기',
    link: '/courses',
    auth: 'ALL',
  },
  {
    img: 'svg/home/shop.svg',
    alt: 'shop',
    text: 'SHOP',
    link: '/shop',
  },
  // {
  //     img: "svg/home/recommendedcourse.svg",
  //     alt: "recommendedcourse",
  //     text: "추천 코스",
  //     link: undefined,
  //     auth:"USER"
  // },
  {
    img: 'svg/home/mylearning.svg',
    alt: 'mylearning',
    text: '내 학습',
    link: '/learning',
    auth: 'USER',
  },
  {
    img: 'svg/home/classmanagement.svg',
    alt: 'classmanagement',
    text: '클래스 관리',
    link: '/ClassManagement',
    auth: 'USER',
  },
  {
    img: 'svg/home/churchmembercerti.svg',
    alt: 'churchmembercerti',
    text: '교인인증',
    link: '/mypage/membership',
    auth: 'USER',
  },
  {
    img: 'svg/home/mybadge.svg',
    alt: 'mybadge',
    text: '나의 뱃지',
    link: '/mypage/badge',
    auth: 'USER',
  },
  {
    img: 'svg/home/music.svg',
    alt: 'music',
    text: '교재 음원',
    link: 'https://jebs-notice.oopy.io/07c226c9-0104-4699-9427-f7d4c6a06134',
    type: 'external',
  },
  {
    img: 'svg/home/classarroundme.svg',
    alt: 'classarroundme',
    text: '주변 클래스',
    link: undefined,
  },
];

export const POSTS = [
  { img: 'svg/empty.svg', title: 'empty image' },
  { img: 'svg/empty.svg', title: 'empty image' },
  { img: 'svg/empty.svg', title: 'empty image' },
  { img: 'svg/empty.svg', title: 'empty image' },
];

export const TAGS = [{ title_1st: '수준 +14세', title_2nd: '채움태그' }];

export const TAG_POSTS = [
  {
    img: 'svg/empty.svg',
    title: 'empty image',
    description: 'empty image',
    route: '#',
  },
  {
    img: 'svg/empty.svg',
    title: 'empty image',
    description: 'empty image',
    route: '#',
  },
  {
    img: 'svg/empty.svg',
    title: 'empty image',
    description: 'empty image',
    route: '#',
  },
  {
    img: 'svg/empty.svg',
    title: 'empty image',
    description: 'empty image',
    route: '#',
  },
];

export const SHOPS = [
  {
    id: 1,
    name: '가격은 9자리까지, 그 이상은 별도 문의가 맞겠죠?',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '일반 도서',
      value: 'BOOK',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '가격은 9자리까지, 그 이상은 별도 문의 표기를 통한 안내 제공',
      route: '#',
    },
    isSales: true,
    isHot: false,
    chips: {
      text: 'SALE',
      co: 'purple',
    },
    prices: {
      origin: 24444000,
      sale: 12222000,
      unit: '원',
    },
  },
  {
    id: 2,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '굿즈(교구)',
      value: 'goods',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    chips: {
      text: 'HOT',
      co: 'orange',
    },
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
  {
    id: 3,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '굿즈(교구)',
      value: 'goods',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    chips: {
      text: 'HOT',
      co: 'orange',
    },
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
  {
    id: 4,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '일반 도서',
      value: 'BOOK',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    chips: {
      text: 'HOT',
      co: 'orange',
    },
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
  {
    id: 5,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '굿즈(교구)',
      value: 'goods',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
  {
    id: 6,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '굿즈(교구)',
      value: 'goods',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
  {
    id: 7,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '일반 도서',
      value: 'BOOK',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
  {
    id: 8,
    name: '상품명은 두 줄까지 가능합니다.',
    title: '홈스쿨 어드벤스드 코드',
    desc: '#교회 새가족 #복음을 우리말 #영어로 정리하고 싶은 분들',
    tabs: {
      label: '일반 도서',
      value: 'BOOK',
    },
    images: {
      src: 'svg/empty.svg',
      title: 'empty image',
      text: '상품명은 두 줄까지 가능합니다.',
      route: '#',
    },
    isSales: false,
    prices: {
      origin: 22000,
      unit: '원',
    },
  },
];

// export const HOME_SCHOOLS: HomeSchoolProps[] = [
//     {
//         id: "section1",
//         gubunStr: "가정, 공부방, 교습소 모두 홈스쿨 코스",
//         items: [
//             {
//                 id: 1,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//             {
//                 id: 2,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//             {
//                 id: 3,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//             {
//                 id: 4,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//         ],
//     },
//     {
//         id: "section2",
//         gubunStr: "온라인 가이드에 따라 학습하는 홈이",
//         items: [
//             {
//                 id: 1,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//             {
//                 id: 2,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//             {
//                 id: 3,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//             {
//                 id: 4,
//                 name: "누구나, 그림 전도!",
//                 description: "",
//                 FootTxt: "#교회 새가족 #복음을 우리마 #영어로 정리하고 싶은 분들",
//             },
//         ],
//     },
// ];

export const OPTIONS_DATA: OptionProps[] = [
  // {
  //     optionId: 1,
  //     optionName: "옵션1",
  //     value: "option1",
  //     isRequire: true,
  //     cnt: 1,
  //     price: 10000,
  //     totalPrice: 10000,
  // },
  // {
  //     optionId: 2,
  //     optionName: "옵션2",
  //     value: "option2",
  //     cnt: 1,
  //     price: 20000,
  //     totalPrice: 20000,
  // },
  // {
  //     optionId: 3,
  //     optionName: "옵션3",
  //     value: "option3",
  //     cnt: 1,
  //     price: 30000,
  //     totalPrice: 30000,
  // },
];

export const PLANS_DATA: PlanProps[] = [
  {
    planId: 1,
    planName: 'datalist-plan01',
    value: 'plan1',
    title: '1년 구매',
    desc: '디스크립션 영역',
    price: 10000,
  },
  {
    planId: 2,
    planName: 'datalist-plan02',
    value: 'plan2',
    title: '2년 구매',
    desc: '디스크립션 영역',
    price: 20000,
  },
  {
    planId: 3,
    planName: 'datalist-plan03',
    value: 'plan3',
    title: '옵션 플랜 01',
    desc: '디스크립션 영역',
    price: 500,
  },
];

export const EXTEND_DATA: ExtendProps[] = [
  {
    extendId: 1,
    extendName: '60일 연장',
    value: 'extend1',
    title: '60일 연장',
    desc: '디스크립션 영역',
    price: 10000,
    unit: '원',
  },
  {
    extendId: 2,
    extendName: '120일 연장',
    value: 'extend2',
    title: '120일 연장',
    desc: '디스크립션 영역',
    price: 20000,
    unit: '원',
  },
  {
    extendId: 3,
    extendName: '180일 연장',
    value: 'extend3',
    title: '180일 연장',
    desc: '디스크립션 영역',
    price: 30000,
    unit: '원',
  },
];

export const productState: CartProps[] = [
  {
    id: 1,
    name: '상품명01',
    desc: '부가적 설명은 괄호 안에',
    price: 20000,
    saleInfo: '[50%] 10,000원 (패키지 구매 할인)',
    discountPercent: 50,
    options: [
      // {
      //     optionId: 1,
      //     optionName: "optionName1",
      //     value: "option Value1",
      //     isRequire: true,
      //     cnt: 2,
      //     price: 10000,
      //     totalPrice: 20000,
      // },
      // {
      //     optionId: 2,
      //     optionName: "optionName2",
      //     value: "option Value2",
      //     isRequire: true,
      //     cnt: 2,
      //     price: 10000,
      //     totalPrice: 20000,
      // },
    ],
    totalPrice: 40000,
  },
  {
    id: 2,
    name: '상품명02',
    desc: '부가적 설명은 괄호 안에',
    price: 10000,
    saleInfo: '[50%] 5,000원 (패키지 구매 할인)',
    discountPercent: 50,
    plans: {
      planId: 1,
      planName: 'datalist-plan01',
      value: 'plan1',
      title: '1년 구매',
      desc: '디스크립션 영역',
      price: 100000,
    },
    options: [
      // {
      //     optionId: 1,
      //     optionName: "optionName1",
      //     value: "option Value1",
      //     isRequire: true,
      //     cnt: 5,
      //     price: 10000,
      //     totalPrice: 50000,
      // },
      // {
      //     optionId: 2,
      //     optionName: "optionName3",
      //     value: "option Value3",
      //     isRequire: true,
      //     cnt: 2,
      //     price: 10000,
      //     totalPrice: 20000,
      // },
    ],
    totalPrice: 170000,
  },
];

export const COUPON_DATA: CouponDataType[] = [
  {
    id: 1,
    name: '쿠폰1',
    value: 'coupon1',
    isAvailable: true,
  },
  {
    id: 2,
    name: '쿠폰2',
    value: 'coupon2',
    isAvailable: true,
  },
  {
    id: 3,
    name: '쿠폰3',
    value: 'coupon3',
    isAvailable: true,
  },
  {
    id: 4,
    name: '쿠폰4',
    value: 'coupon4',
    isAvailable: false,
  },
  {
    id: 5,
    name: '쿠폰5',
    value: 'coupon5',
    isAvailable: false,
  },
];

// export const DELIVERY_DATA: DeliveryProps[] = [
//     {
//         id: 0,
//         name: "기본배송지",
//         label: "기본배송지",
//         isDefault: true,
//         datas: {
//             name: "김젭스",
//             address: "서울시 서울구 서울대로 1000길 2",
//             subAdd: "1층 101호",
//             phone: "010-1234-5697",
//         },
//     },
//     {
//         id: 1,
//         name: "배송지-02",
//         label: "배송지-02",
//         isDefault: false,
//         datas: {
//             name: "테스터",
//             address: "서울시 서울구 서울대로 1000길 2",
//             subAdd: "1층 101호",
//             phone: "010-1234-5697",
//         },
//     },
// ];

export const COURSE_SUMMERY_DATA: CourseSummaryType[] = [
  {
    to: '/courses/homeschool',
    src: process.env.PUBLIC_URL + 'images/course/homeschool.png',
    text1: '자녀와 함께하는',
    text2: '홈스쿨 코스',
  },
  {
    to: '/courses/churchschool',
    src: process.env.PUBLIC_URL + 'images/course/churchschool.png',
    text1: '우리 교회를 위한',
    text2: '교회학교 코스',
  },
  // {
  //     to: "/courses/selfstudy",
  //     src: process.env.PUBLIC_URL + "images/course/selfstudy.png",
  //     text1: "바로 시작하는",
  //     text2: "자율학습 코스",
  // },
  {
    to: '/courses/smallgroup',
    src: process.env.PUBLIC_URL + 'images/course/selfstudy.png',
    text1: '소그룹 추천',
    text2: 'NIV,전도용 코스',
  },
  {
    to: '/courses/englishconversation',
    src: process.env.PUBLIC_URL + 'images/course/englishconversation.png',
    text1: '원어민 선생님과',
    text2: '1:1 영어회화 코스',
  },
];

export const BADGE_ARRAY_LIST = [
  {
    img: '/svg/badge/ready_badge.svg',
    alt: '준비된 교사',
    text1: '신입 교사',
    text2: '교사대학 최초 수료',
  },
  {
    img: '/svg/badge/ready_badge.svg',
    alt: '숙련된 교사',
    text1: '뱃지 명',
    text2: '뱃지 설명',
  },
  {
    img: '/svg/badge/ready_badge.svg',
    alt: '배테랑 교사',
    text1: '뱃지 명',
    text2: '뱃지 설명',
  },
  {
    img: '/svg/badge/ready_badge.svg',
    alt: '숙련된 교사',
    text1: '뱃지 명',
    text2: '뱃지 설명',
  },
  {
    img: '/svg/badge/ready_badge.svg',
    alt: '숙련된 교사',
    text1: '뱃지 명',
    text2: '뱃지 설명',
  },
  {
    img: '/svg/badge/ready_badge.svg',
    alt: '숙련된 교사',
    text1: '뱃지 명',
    text2: '뱃지 설명',
  },
];

export const RATING_ARRAY_LIST = [
  // {
  //     title: "FRIEND",
  //     desc: "회원가입 시",
  //     text: "결제 시 0.5% 적립",
  //     color: "gray9",
  // },
  {
    title: 'FELLOW',
    desc: '최근 1년 구매 점수 999점 이하',
    text: '결제 시 0.5% 적립',
    color: 'gray6',
  },
  // {
  //     title: "FELLOW+",
  //     desc: "최근 1년 구매 누적 점수 50점 이상",
  //     text: "결제 시 1.5% 적립",
  //     color: "purple5",
  // },
  {
    title: 'PARTNER',
    desc: '주일학교 코스 운영기간 활성화,\n 최근 1년 구매 점수 119점 이하',
    text: '결제 시 2% 적립',
    color: 'purple5',
  },
  {
    title: 'PARTNER+',
    desc: '주일학교 코스 운영기간 활성화,\n 최근 1년 구매 점수 120점 이상',
    text: '결제 시 2.5% 적립',
    color: 'purple5',
  },
  {
    title: 'FAMILY',
    desc: '홈스쿨 코스 운영기간 활성화,\n 최근 1년 구매 점수 119점 이하',
    text: '결제 시 3% 적립',
    color: 'tangerine5',
  },
  {
    title: 'FAMLILY+',
    desc: '홈스쿨 코스 운영기간 활성화,\n 최근 1년 구매 점수 120점 이상 499점 이하',
    text: '결제 시 4% 적립',
    color: 'tangerine5',
  },
  {
    title: 'VIP',
    desc: '홈스쿨 코스 운영기간 활성화,\n 최근 1년 구매 점수 500점 이상',
    text: '결제 시 5% 적립',
    color: 'orange',
  },
  // {
  //     title: "VIP+",
  //     desc: "JJEMM 멤버십 2년차,\n 최근 1년 구매 누적 점수 120점 이상",
  //     text: "결제 시 5.5% 적립",
  //     color: "orange",
  // },
  // {
  //     title: "PREMIUM",
  //     desc: "JEMM 멤버십 3년차 이상,\n 최근 1년 구매 누적 점수 120점 이상",
  //     text: "결제 시 6% 적립",
  //     color: "black",
  // },
  // {
  //     title: "PREMUIM+",
  //     desc: "JEMM 멤버십 3년차 이상,\n 최근 1년 구매 누적 점수 300점 이상",
  //     text: "결제 시 6.5% 적립",
  //     color: "black",
  // },
];

export const Certification_ARRAY_LIST = [
  {
    img: '/images/mypage/certification/sample.png',
    title: 'Lucy Lee',
    date: '2020.05.34',
  },
  {
    img: '/images/mypage/certification/basic.png',
    title: 'Ian',
    date: '2021.05.34',
  },
  {
    img: '/images/mypage/certification/basic.png',
    title: 'Angelo',
    date: '2021.05.34',
  },
];

export const POINT_ARRAY_LIST = [
  {
    point: '+150',
    desc: '20220214689828937 주문의 적립금 지급',
    date: '2020.03.03',
  },
  {
    point: '+3,000',
    desc: '컨퍼런스 이벤트 참여 감사',
    date: '2020.03.03',
  },
  {
    point: '-3,000',
    desc: '상품주문시 적립금 사용(20220214689828937)',
    date: '2020.03.03',
  },
];

export const MYPAGE_ORDER_DATA: OrderProps[] = [
  {
    id: 1,
    status: '0',
    date: '2020.03.03',
    ordernum: '102030405060',
    options: [
      {
        name: '상품명1',
        desc: [
          {
            text1: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
            text2: '',
          },
        ],
        img: '/images/dummy_img.png',
        alt: '상품명1',
        price: '90000',
      },
    ],
    deposit: '(주) 젭스',
    account: '00은행 234242-93-132131',
    deadline: '2022.10.15 24:00',
    invoice: '',
  },
  {
    id: 2,
    status: '1',
    date: '2020.03.03',
    ordernum: '102030405060',
    options: [
      {
        name: '상품명1',
        desc: [
          {
            text1: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
            text2: '',
          },
        ],
        img: '/images/dummy_img.png',
        alt: '상품명1',
        price: '90000',
      },
    ],
    deposit: '',
    account: '',
    deadline: '',
    invoice: '한진택배 202021102',
  },
  {
    id: 3,
    status: '2',
    date: '2020.03.03',
    ordernum: '102030405060',
    options: [
      {
        name: '상품명1',
        desc: [
          {
            text1: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
            text2: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
          },
        ],
        img: '/images/dummy_img.png',
        alt: '상품명1',
        price: '90000',
      },
      {
        name: '상품명1',
        desc: [
          {
            text1: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
            text2: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
          },
        ],
        img: '/images/dummy_img.png',
        alt: '상품명1',
        price: '90000',
      },
    ],
    deposit: '',
    account: '',
    deadline: '',
    invoice: '한진택배 202021102',
  },
  {
    id: 4,
    status: '3',
    date: '2020.03.03',
    ordernum: '102030405060',
    options: [
      {
        name: '상품명1',
        desc: [
          {
            text1: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
            text2: '',
          },
        ],
        img: '/images/dummy_img.png',
        alt: '상품명1',
        price: '90000',
      },
    ],
    deposit: '',
    account: '',
    deadline: '',
    invoice: '한진택배 202021102',
  },
  {
    id: 5,
    status: '0',
    date: '2020.03.03',
    ordernum: '102030405060',
    options: [
      {
        name: '상품명1',
        desc: [
          {
            text1: '옵션: [옵션선택1:옵션선택2] 교재:초등1학년',
            text2: '',
          },
        ],
        img: '/images/dummy_img.png',
        alt: '상품명1',
        price: '90000',
      },
    ],
    deposit: '(주) 젭스',
    account: '00은행 234242-93-132131',
    deadline: '2022.10.15 24:00',
    invoice: '',
  },
];

export const Announce_DATA = [
  {
    status: '',
    type: '0',
    title: '오늘은 ‘클래스명’ 수업이 있는 날이에요.',
    date: '오늘',
  },
  {
    status: 'new',
    type: '0',
    title: '오늘은 ‘클래스명’ 수업이 있는 날이에요.',
    date: '4일전',
  },
  {
    status: 'new',
    type: '1',
    title:
      '새로운 공지사항이 올라왔어요! ‘공지사항 제목...’ 내용을 확인해 주세요.',
    date: '4일전',
  },
  {
    status: '',
    type: '1',
    title:
      '새로운 공지사항이 올라왔어요! ‘공지사항 제목...’ 내용을 확인해 주세요.',
    date: '4일전',
  },
];

export const MYPAGE_NOTICE_DATA: MypageNoticeProps[] = [
  {
    id: 1,
    title: '업데이트 공지사항',
    date: '2020.03.03',
    text: '서비스를 이용해 주시는 이용자 여러분께 감사드리며, 새로운 개인정보 처리방침 적용에 관한 안내 말씀 드립니다. 회사는 이용자 여러분의 개인정보를 무엇보다 소중하게 처리하고 있으며, 어떤 사안보다도 우선하여 안전하게 관리하고 있습니다. 새롭게 변경될 개인정보 처리방침 내용을 확인하시고 서비스 이용에 참고하시기 바랍니다.',
  },
  {
    id: 2,
    title: '정기점검 안내로 인한 서비스 중지 안내',
    date: '2022.04.30',
    text: '변경될 개인정보 처리방침 내용을 확인하시고 서비스 이용에 참고하시기 바랍니다.',
  },
  {
    id: 3,
    title: '업데이트 공지사항',
    date: '2022.12.30',
    text: '변경될 개인정보 처리방침 내용을 확인하시고 서비스 이용에 참고하시기 바랍니다.',
  },
];

export const MYPAGE_EVENT_DATA: MypageEventProps[] = [
  {
    id: 1,
    img: '/images/dummy_img.png',
    title: '이벤트 제목',
    date: '2022.01.01 ~ 2022.05.34',
    text: '변경될 개인정보 처리방침 내용을 확인하시고 서비스 이용에 참고하시기 바랍니다.',
  },
  {
    id: 2,
    img: '/images/dummy_img.png',
    title: '이벤트 제목2',
    date: '2022.01.01 ~ 2022.05.34',
    text: '반가워요',
  },
  {
    id: 3,
    img: '/images/dummy_img.png',
    title: '이벤트 제목3',
    date: '2022.01.01 ~ 2022.05.34',
    text: '반가워요',
  },
];

export const MYPAGE_COUPON_DATA: MypageCouponProps[] = [
  {
    id: 1,
    date: '2022.09.10까지 (132일 남음)',
    discount: '3,000원',
    title: '★JEMM 교사대학 첫 수료 축하 쿠폰 [8월]',
    desc: {
      text1: '홈스쿨 교재 제외 전 상품 사용 가능',
      text2: '20,000원 이상 구매 시 ',
      text3: '2022.03.01~2022.09.10',
    },
  },
  {
    id: 2,
    date: '제한 없음',
    discount: '1,000원',
    title: '회원가입 축하 할인 쿠폰',
    desc: {
      text1: '홈스쿨 교재 제외 전 상품 사용 가능',
      text2: '',
      text3: '',
    },
  },
  {
    id: 3,
    date: '2022.09.10까지 (132일 남음)',
    discount: '10%',
    title: '★JEMM 교사대학 첫 수료 축하 쿠폰 [8월]',
    desc: {
      text1: '홈스쿨 교재 제외 전 상품 사용 가능',
      text2: '20,000원 이상 구매 시',
      text3: '2021.08.01~2021.08.31',
    },
  },
];

export const AGREEMENT_DATA = [
  {
    title: '이용약관',
    text: '이용약관',
  },
  {
    title: '개인정보 수집 및 이용 동의',
    text: '개인정보 수집 및 이용 동의 내용',
  },
  {
    title: '프로모션 정보 수신 동의',
    text: '개인정보 제3자 제공 동의 내용',
  },
];

export const HOME_REVIEW_DATA: CardItemProps[] = [
  {
    img: 'images/home/review/review1.png',
    title: '수요일에도 교회에 모여 영어로 말씀을 묵상해요!',
    link: '/review/details/2',
    type: 'internal',
  },
  {
    img: 'images/home/review/review2.png',
    title: '하나님과 동행하는 지도자 되기 프로젝트는 계속 될 것입니다. ',
    link: '/review/details/1',
    type: 'internal',
  },
  {
    img: 'images/home/review/review3.png',
    title: '엄마 손 잡고 첫 수업하던 아이의 확실한 비포&애프터',
    link: '/review/details/8',
    type: 'internal',
  },
  {
    img: 'images/home/review/review4.png',
    title: '기도의 삼박자로 은혜가 배가 되는 젭스홈스쿨!',
    link: '/review/details/6',
    type: 'internal',
  },
];

export const CITY_DATA = [
  '서울특별시',
  '인천광역시',
  '대전광역시',
  '부산광역시',
  '울산광역시',
  '대구광역시',
  '광주광역시',
  '제주특별자치도',
  '경기도',
  '강원도',
  '충청남도',
  '충청북도',
  '경상북도',
  '경상남도',
  '전라남도',
  '전라북도',
  '세종특별자치시',
];

export enum TODO_TYPE {
  WMW = 'WMW',
  JS1 = 'JS1',
  JS2 = 'JS2',
  HF = 'HF',
  HJ = 'HJ',
  RECITATION = 'RECITATION',
  JEBSON = 'JEBSON',
}

export const TODO_SUBTYPE = [
  {
    courseId: 'VY6Yl4kxQsb3sYJZ',
    subType: 'JEBSPL',
    name: '젭스플',
    types: [
      { key: TODO_TYPE.WMW, name: 'Who made the world' },
      { key: TODO_TYPE.JS1, name: 'Jesus Story1' },
      { key: TODO_TYPE.JS2, name: 'Jesus Story2' },
    ],
  },
  {
    courseId: '',
    subType: 'JEBSPL',
    name: '젭스플',
    types: [
      { key: TODO_TYPE.HF, name: 'Hello, Father' },
      { key: TODO_TYPE.HJ, name: 'Hello, Jesus' },
    ],
  },
  {
    courseId: '',
    subType: 'RECITATION',
    name: '인증제',
    types: [{ key: TODO_TYPE.RECITATION, name: '인증제' }],
  },
  {
    courseId: '',
    subType: 'JEBSON',
    name: '젭스온',
    types: [{ key: TODO_TYPE.JEBSON, name: '젭스온' }],
  },
];

export const STUDENT_STATUS: { [key in studentStatusType]: string } = {
  [studentStatusType.ACTIVE]: '수강생',
  [studentStatusType.INACTIVE]: '정지',
  [studentStatusType.PENDING]: '대기생',
};
