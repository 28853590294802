import { CartItem, OrderItem } from "../../constants/types"

export const getOrderItemTotalAmount = (orderItem: OrderItem) => {
  /* TODO : 코스 Enroll 금액 더하기 */
  const { chosen_options, chosen_variants, product, enroll_options } = orderItem

  let amount = 0

  chosen_variants &&
    chosen_variants.forEach(({ variant, quantity }) => {
      const { origin_price } = product
      amount += (origin_price + variant.additional_price) * quantity
    })

  chosen_options &&
    chosen_options.forEach(({ product, chosen_variants }) => {
      const { origin_price } = product
      chosen_variants.forEach(({ variant, quantity }) => {
        amount += (origin_price + variant.additional_price) * quantity
      })
    })

  enroll_options &&
    enroll_options.forEach(({ enroll_option: { price } }) => {
      amount += price
    })

  return amount
}
  
// For Template
export const getOrderItemsTotal = (orderItems: OrderItem[]) => {
  let amount = 0
  if(orderItems) {
      orderItems.forEach((item) => {
        amount += getOrderItemTotalAmount(item)
      })
  }
  return amount
}
  
// export const getOrderItemShippingFee = (orderItem: OrderItem) => {
export const getOrderItemShippingFee = (ids: string[], cartLists: CartItem[]) => {
  let shipping_fee = 0
  ids.map(id => {
    cartLists.filter(x => x.id === id).map(cartItem => {
      shipping_fee += cartItem.order_item.product.shipping_fee
    })
  })
  if(shipping_fee > 3000) {
    shipping_fee = 3000
  }
  return shipping_fee
}

export const getChosenVariantsAmounts = (orderItem: OrderItem) => {
  const { chosen_options, chosen_variants, product, enroll_options } = orderItem

  let amount = 0;
  chosen_variants && chosen_variants.forEach(({quantity, variant}) => {
    const { current_price } = product
    amount += (current_price + variant.additional_price) * quantity
  })

  chosen_options && chosen_options.forEach(({product, chosen_variants}) => {
    const { current_price } = product
    chosen_variants.forEach(({ variant, quantity }) => {
      amount += (current_price + variant.additional_price) * quantity
    })
  })

  enroll_options && enroll_options.forEach(({enroll_option: { price }}) => {
    amount += price
  })

  return amount
}

export const getDiscountAmount = (gubun: string, orderItems: OrderItem | OrderItem[]) => {
  let discountAmout = 0;
  if( 'cart' === gubun || 'purchase' === gubun ) {
    let saleAmout = (orderItems as OrderItem).product.origin_price - (orderItems as OrderItem).product.current_price;
    (orderItems as OrderItem).chosen_variants.map((variant) => {
      discountAmout += saleAmout * variant.quantity
    })
  } else {
    if(orderItems) {
      (orderItems as OrderItem[]).map((item: OrderItem) => {
        discountAmout += item.product.origin_price - item.product.current_price;
      })
  }
  }
  return discountAmout
}