import { Textarea } from "@material-tailwind/react";

export default function ComTextarea() {
    return (
        <Textarea
            rows={1}
            placeholder="클래스 소개 2,000자 이내 입력"
            className="!px-2.5 !py-[14px] !border-0 focus:border-transparent min-h-min	"
            containerProps={{
                className: "grid h-full border border-gray5 rounded",
            }}
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
    );
}
