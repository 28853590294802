import React, { useImperativeHandle } from 'react';
import { CreateTypes } from 'canvas-confetti';
import { forwardRef } from 'react';
import ReactCanvasConfetti from '../common/ReactCanvasConfetti';

interface ConfettiFireworkProps {}

export interface ConfettiFireworkRef {
  startFire: () => void;
}

export const ConfettiFirework = forwardRef<
  ConfettiFireworkRef,
  ConfettiFireworkProps
>((props, ref) => {
  const animationInstance = React.useRef<CreateTypes | null>(null);
  const isAnimationEnabled = React.useRef(false);
  const makeShot = (particleRatio: number, opts: object) => {
    animationInstance.current &&
      animationInstance.current({
        ...opts,
        // origin: { x: 0.5, y: 1 },
        particleCount: Math.floor(200 * particleRatio),
      });
  };
  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
      angle: -45,
      origin: { x: 0, y: 0 },
    });
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
      angle: 180 + 45,
      origin: { x: 1, y: 0 },
    });
  };
  const handlerFire = () => {
    if (!isAnimationEnabled.current) {
      isAnimationEnabled.current = true;
    }
    requestAnimationFrame(fire);
    fire();
  };
  useImperativeHandle(ref, () => ({
    startFire: handlerFire,
  }));
  const getInstance = (instance: CreateTypes | null) => {
    animationInstance.current = instance;
  };
  return (
    <ReactCanvasConfetti
      refConfetti={getInstance}
      className="canvas h-full w-full"
    />
  );
});
