import React from 'react'
import { pointType } from '../../../constants/types/mypage-type'
import TextWinds from '../../../components/common/TextWinds'
import { numberWithCommas } from '../../../api/utils/format/numbers'
export enum PointTransactionType {
    PURCHASE_USE = 'PURCHASE_USE', // 쇼핑몰 사용
    PURCHASE_EARN = 'PURCHASE_EARN', // { 쇼핑몰 구매 / 관리자 적립 }
    PURCHASE_GRADE = 'PURCHASE_GRADE', // 쇼핑몰 구매
    REGISTRATION = 'REGISTRATION',
    REFUND = 'REFUND', // 환불
    OFFICE_GRANT = 'OFFICE_GRANT', // 관리자 차감
  }
  export   enum TransactionSource{
    MANUAL = 'MANUAL', // 관리자
    SHOP = 'SHOP', // 쇼핑몰
  }

interface PointItemProps {
    data:pointType
}
const PointItem = ({data}:PointItemProps) => {
    const getTransactionName = (type:PointTransactionType,source:TransactionSource)=>{
        switch(type){
            case PointTransactionType.PURCHASE_USE:
                return '쇼핑몰 사용'
            case PointTransactionType.PURCHASE_EARN:
                return source===TransactionSource.SHOP?'쇼핑몰 구매':'관리자 적립'
            case PointTransactionType.PURCHASE_GRADE:
                return '쇼핑몰 구매'
            case PointTransactionType.REGISTRATION:
                return ''
            case PointTransactionType.REFUND:
                return '환불'
            case PointTransactionType.OFFICE_GRANT:
                return '관리자 차감'
        }
    }
  return (
    <div
        className={`flex justify-between items-center py-4 border-b`}
    >
        <div className="w-[90px]">
            <TextWinds type="content_body1" color="purple5">
                {numberWithCommas(data.amount)}
            </TextWinds>
        </div>
        <div className="flex-1 text-right">
            <TextWinds type="content_body4" color="gray9">
                {getTransactionName(data.transactionType as PointTransactionType,data.transactionSource as TransactionSource)}
            </TextWinds>
            <input hidden value={data.transactionType} />
            <input hidden value={data.transactionSource} />
            <TextWinds type="content_body4" color="gray9">
                {data.createdAt.toString().slice(0,10)}
            </TextWinds>
        </div>
    </div>
  )
    {/* {POINT_ARRAY_LIST.map((props, key) => (
        <div
            key={key}
            className={`flex justify-between items-center py-4 border-b ${
                key === 0 ? "border-t" : ""
            }`}
        >
            <div className="w-[90px]">
                <TextWinds type="content_body1" color="purple5">
                    {props.point}
                </TextWinds>
            </div>
            <div className="flex-1 text-right">
                <TextWinds type="content_body4" color="gray9">
                    {props.desc}
                </TextWinds>
                <TextWinds type="content_body4" color="gray9">
                    {props.date}
                </TextWinds>
            </div>
        </div>
    ))} */}
}



export default PointItem