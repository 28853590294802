import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import {
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/utils/util-func';

const profileMenuItemsByStudent = [
  {
    id: 'student-profile',
    label: '회원 정보',
    icon: UserCircleIcon,
  },
  {
    id: 'signout',
    label: '로그아웃',
    icon: ArrowRightStartOnRectangleIcon,
  },
];

const profileMenuItems = [
  {
    id: 'profile',
    label: '내정보',
    icon: UserCircleIcon,
  },
  {
    id: 'signout',
    label: '로그아웃',
    icon: ArrowRightStartOnRectangleIcon,
  },
];
interface ProfileProps {
  isStudent?: boolean;
}
const Profile = ({ isStudent }: ProfileProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const handleActionEvent = (id: string) => {
    setIsMenuOpen(false);
    switch (id) {
      case 'student-profile':
        navigate('/student/profile');
        break;
      case 'signout':
        logout();
        break;
      case 'profile':
        navigate('/mypage');
        break;
    }
  };
  const navigate = useNavigate();

  const menuItems = isStudent ? profileMenuItemsByStudent : profileMenuItems;

  return (
    <Fragment>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <ListItem
            className="flex items-center gap-2 py-2 pr-0 font-medium text-gray-900"
            selected={isMenuOpen || isMobileMenuOpen}
            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
          >
            {/* <Avatar
              variant="circular"
              size="sm"
              className="border border-gray-900 p-0.5"
              src={process.env.PUBLIC_URL + "/svg/user.svg"}
            /> */}
            <img
              src={process.env.PUBLIC_URL + '/svg/icon/profile.svg'}
              alt="user"
              className="w-[35px] h-[35px]"
            />
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${
                isMenuOpen ? 'rotate-180' : ''
              }`}
            />
          </ListItem>
        </MenuHandler>
        <MenuList className="p-1">
          {menuItems.map(({ label, icon, id }, key) => {
            const isLastItem = key === menuItems.length - 1;
            return (
              <MenuItem
                key={label}
                onClick={() => handleActionEvent(id)}
                className={`flex items-center gap-2 rounded ${
                  isLastItem
                    ? 'hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10'
                    : ''
                }`}
              >
                {React.createElement(icon, {
                  className: `h-4 w-4 ${isLastItem ? 'text-red-500' : ''}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal"
                  color={isLastItem ? 'red' : 'inherit'}
                >
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Fragment>
  );
};

export default Profile;
