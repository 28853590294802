import React from "react";

export default function PageHead({ children }: { children: React.ReactNode }) {
    return (
        <>
            <div className="xl:flex xl:items-center xl:w-[1176px] xl:h-[90px] mx-auto">{children}</div>
            {/* <div className="xl:flex xl:items-center xl:h-[90px] mx-auto px-10">{children}</div> */}
        </>
    );
}
