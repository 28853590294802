import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import { AxiosParamType } from ".";
import instance from "../../api/axios/utils.ts/instance";

const cors = require('cors')
const initialState: any = {
    status: '',
}
export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(addTemplateFromProduct.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(addTemplateFromProduct.fulfilled, (state, action) => {
                state.status = 'fulfilled';
            })
            .addCase(addTemplateFromProduct.rejected, (state) => {
                state.status = 'rejected';
            })
    }
})

export const addTemplateFromProduct = createAsyncThunk(
    'asyncThunk/addTemplateFromProduct',
    async ({ url, params }: AxiosParamType) => {
        try {
            const response = await cors(instance.post(url, params));
            return response.data;
        } catch(error) {
            return isRejectedWithValue(error)
        }
    }
)

export default orderSlice.reducer