import React from 'react';
import {
  LmsMigrationStatus,
  LmsMigrationStudent,
} from '../../../constants/types/lms-type';
import { StudentCol } from '../studentmgmt/StudentCol';
import { Button } from '@material-tailwind/react';
import { useAppDispatch } from '../../../store/hook';
import {
  openAlert,
  openConfirm,
  openModal,
} from '../../../store/features/modalSlice';
import LmsMigrationModifyModal from './LmsMigrationModifyModal';

interface LmsMigrationItemProps {
  student: LmsMigrationStudent;
  classId: string;
  status: LmsMigrationStatus;
  setList: React.Dispatch<React.SetStateAction<LmsMigrationStudent[]>>;
}
const LmsMigrationItem = ({
  student,
  classId,
  status,
  setList,
}: LmsMigrationItemProps) => {
  const dispatch = useAppDispatch();

  const modifyCompleted = (flag: boolean) => {
    if (flag) {
      dispatch(openAlert({ message: '등록되었습니다.' }));
      setList((prev) => prev.filter((item) => item.u_id !== student.u_id));
    }
  };
  const modifyHandler = () => {
    // dispatch(openAlert({ message: '관리자에게 문의하세요' }));
    dispatch(
      openModal({
        body: (
          <LmsMigrationModifyModal
            student={student}
            classId={classId}
            onCompleted={modifyCompleted}
          />
        ),
      })
    );
  };
  const deleteHandler = () => {
    // dispatch(openAlert({ message: '관리자에게 문의하세요' }));
    dispatch(
      openConfirm({
        message: '정말로 삭제하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.',
        onConfirm: () => {
          setList((prev) => prev.filter((item) => item.u_id !== student.u_id));
          dispatch(openAlert({ message: '삭제되었습니다.' }));
        },
        confirmText: '삭제',
      })
    );
  };
  return (
    <div className="flex h-[50px] border-b border-gray4 items-center">
      <StudentCol>{student.u_id}</StudentCol>
      <StudentCol>{student.u_name}</StudentCol>
      <StudentCol>{student.u_name_en}</StudentCol>
      <StudentCol>{new Date(student.u_birth).toLocaleDateString()}</StudentCol>
      {status === LmsMigrationStatus.FAIL && (
        <>
          <StudentCol width="20" shrink="0">
            <Button color="blue" size="sm" onClick={modifyHandler}>
              등록
            </Button>
          </StudentCol>
          {/* <StudentCol width="20" shrink="0">
            <Button color="red" size="sm" onClick={deleteHandler}>
              삭제
            </Button>
          </StudentCol> */}
        </>
      )}
    </div>
  );
};

export default LmsMigrationItem;
