import { Checkbox } from '@material-tailwind/react';

export interface IsCheckedProps {
  id: number;
  key?: string;
  name?: string;
  filter?: string;
  nested?: any;
  isChecked: boolean;
}
interface CheckBoxProps {
  type?: 'full' | 'border' | 'rounded';
  id?: string;
  index?: number;
  value?: string;
  checked?: boolean;
  color?: 'purple5' | 'skyblue' | 'orange' | 'orange4' | 'gray9' | 'red5';
  labelValue?: React.ReactNode;
  children?: React.ReactNode;
  borderWidth?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
}

export default function CheckBoxes({
  type,
  id,
  index,
  value,
  checked,
  color = 'purple5',
  labelValue,
  children,
  borderWidth,
  onChange,
  className,
  disabled,
}: CheckBoxProps) {
  let colorClassName = '';
  switch (type) {
    case 'full':
      colorClassName = `border border-[${borderWidth}px] border-${color} rounded-[3px] before:w-5 before:h-5 checked:bg-${color} checked:border-${color}`;
      break;
    case 'border':
      colorClassName = `border border-[${borderWidth}px] rounded-[3px] border-${color} checked:bg-white checked:border-${color}`;
      break;
    case 'rounded':
      colorClassName = `border-solid border-[${borderWidth}px] rounded-[25px] border-${color} checked:bg-${color} checked:border-${color} w-[25px] h-[25px]`;
      break;
  }

  return (
    <>
      {
        <Checkbox
          id={id}
          value={value}
          className={`${colorClassName} ${className}`}
          onChange={(e) => onChange(e)}
          checked={checked}
          label={
            <div className="text-[14px] leading-[22px] tracking-[-0em] font-semibold text-black whitespace-wrap">
              {labelValue}
            </div>
          }
          iconProps={{
            className: `${type === 'border' ? 'text-purple5' : 'text-white'} `,
          }}
          containerProps={{
            className: 'p-[2px] rounded-[3px]',
          }}
          labelProps={{
            className: 'm-0 ml-[8px]',
          }}
          disabled={disabled}
        >
          {children}
        </Checkbox>
      }
    </>
  );
}
