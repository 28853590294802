import { createContext } from "react";

type SignupContextType = {
    step: number;
    setStep: (step: number) => void;
    phone: string;
    setPhone: (phone: string) => void;
    // handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // verificationCode: string;
    // handleVerification: () => void;
    // handleVerificationCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // handleSignup: () => void;
    // handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // email: string;
    // handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // password: string;
    // handlePasswordConfirmChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // passwordConfirm: string;
    // handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // name: string;
    // handleBirthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // birth: string;
    // handleGenderChange: (e: React.ChangeEvent<HTMLInputElement
}

export const SignupContext = createContext<SignupContextType>({
    phone:"",
    setPhone: () => {},
    step: 0,
    setStep: () => {},
});