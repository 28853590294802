import React from 'react';
import image1 from './img/section4-a.png';
import image2 from './img/section4-b.png';
import image3 from './img/section4-c.png';
import { DiamondIcon } from './HomeschoolComp';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import HomeschoolKIDS from './HomeschoolKIDS';
import { useAppDispatch } from '../../../store/hook';
import { closeModal, openModal } from '../../../store/features/modalSlice';
import HomeschoolBASIC from './HomeschoolBASIC';
import HomeschoolADVANCED from './HomeschoolADVANCED';

const HomeschoolSection4 = () => {
  return (
    <div className="flex flex-col items-center my-20 w-full max-w-[480px] m-auto">
      <DiamondIcon color="#ff4f00" width={24} />
      <div className="text-3xl md:text-4xl text-center mt-3">
        홈스쿨 연령별 맞춤코스,
        <div className="font-bold">이렇게 구성되어 있어요!</div>
      </div>
      <div className="bg-black text-white font-bold rounded-full px-4 py-1 mt-10">
        Line up
      </div>
      <CourseButton
        img={image1}
        text={'KIDS 자세히 보기'}
        color={'#ff4f00'}
        Inner={HomeschoolKIDS}
      />
      <CourseButton
        img={image2}
        text={'BASIC 자세히 보기'}
        color={'#ff9b00'}
        Inner={HomeschoolBASIC}
      />

      <CourseButton
        img={image3}
        text={'ADVANCED 자세히 보기'}
        color={'#309c5b'}
        // Inner={undefined}
        Inner={HomeschoolADVANCED}
      />
    </div>
  );
};

const CourseButton = ({
  img,
  text,
  color,
  Inner,
}: {
  img: string;
  text: string;
  color: string;
  Inner: () => React.JSX.Element;
}) => {
  const dispatch = useAppDispatch();
  const openPopup = () => {
    if (!Inner) {
      alert('준비중입니다.');
      return;
    }
    dispatch(openModal({ body: <Popup Inner={Inner} /> }));
  };
  return (
    <div className="w-full bg-white rounded-lg p-5 mt-5" style={{ color }}>
      <div
        className="group flex flex-col items-center cursor-pointer"
        onClick={openPopup}
      >
        <img
          src={img}
          alt="icon"
          className="w-[200px] mb-[-16px] transition-transform duration-100 transform group-hover:scale-110"
        />
        <div
          className="w-full flex text-lg font-bold ml-3 border bg-white items-center justify-center rounded p-3 shadow-lg transition-transform duration-100 transform group-hover:scale-100 group-hover:bg-gray1"
          style={{ borderColor: color }}
        >
          {text}
          <ChevronDownIcon
            className={`w-6 h-6 transform  ${'rotate-[-90deg]'}`}
          />
        </div>
      </div>
      {/* <motion.div
        style={{ overflow: 'hidden', transformOrigin: 'top' }}
        initial={{ height: 0 }}
        animate={isOpen ? { height: 'auto' } : {}}
      >
        {children}
      </motion.div> */}
    </div>
  );
};

const Popup = ({ Inner }: { Inner: () => React.JSX.Element }) => {
  const dispatch = useAppDispatch();
  const closePopup = () => {
    dispatch(closeModal());
  };
  return (
    <div className="relative h-screen w-screen max-w-[520px] pt-10">
      <div className="absolute top-0 left-0 w-full bg-black flex justify-end">
        <XMarkIcon
          className="w-10 h-10 cursor-pointer text-white hover:text-gray5"
          onClick={closePopup}
        />
      </div>
      {/* <div
        className="absolute w-[calc(100%-24px)]  m-auto rounded-full text-center bottom-4 cursor-pointer"
        onClick={closePopup}
      >
        <HFbutton onClick={closePopup}>닫기</HFbutton>
      </div> */}
      <div className="h-full  overflow-y-scroll bg-white ">
        <Inner />
      </div>
      {/* <div
        className="absolute top-5 right-7 cursor-pointer"
        onClick={closePopup}
      >
        <XCircleIcon className="w-10 h-10" />
      </div> */}
    </div>
  );
};

export default HomeschoolSection4;
