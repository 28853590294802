import React from 'react';
import HFHJSection1 from './HFHJSection1';
import HFHJSection2 from './HFHJSection2';
import HFHJSection3 from './HFHJSection3';
import HFHJSection4 from './HFHJSection4';
import HFHJSection5 from './HFHJSection5';
import HFHJSection6 from './HFHJSection6';
import HFHJSection7 from './HFHJSection7';
import HFHJSection8a from './HFHJSection8a';
import HFHJSection8b from './HFHJSection8b';
import HFHJSection9 from './HFHJSection9';
import HFHJSection10 from './HFHJSection10';
import HFHJSection11 from './HFHJSection11';
import HFHJSection12 from './HFHJSection12';
import HFHJSection13 from './HFHJSection13';
import HFHJSection14 from './HFHJSection14';
const HFHJMain = () => {
  return (
    <div>
      <HFHJSection1 />
      <HFHJSection2 />
      <HFHJSection3 />
      <HFHJSection4 />
      <HFHJSection5 />
      <HFHJSection6 />
      <HFHJSection7 />
      <HFHJSection8a />
      <HFHJSection8b />
      <HFHJSection9 />
      <HFHJSection10 />
      <HFHJSection11 />
      <HFHJSection12 />
      <HFHJSection13 />
      <HFHJSection14 />
    </div>
  );
};

export default HFHJMain;
