import React, { ReactNode } from 'react';

interface CompleteRowProps {
  children: ReactNode;
  className?: string;
}
const CompleteRow = ({ children, className }: CompleteRowProps) => {
  return (
    <div className={`flex w-full justify-between ${className}`}>{children}</div>
  );
};

export default CompleteRow;
