import React, { useEffect, useState } from 'react';
import TextWinds from '../common/TextWinds';
import SimpleDialog from '../layout/SimpleDialog';
import OrderinfoChange from './orderinfoChange';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  getOrdererInfo,
  selectOrderInfo,
} from '../../store/features/orderInfoSlice';
import { phoneFormat } from '../../api/utils/format/numbers';

const OrdererInfo = () => {
  const dispatch = useAppDispatch();
  const orderer = useAppSelector(selectOrderInfo);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getOrdererInfo({ url: '' }));
  }, []);

  return (
    <div className="flex flex-col gap-2 p-6 rounded-md bg-white">
      <TextWinds type="title_h4">주문자 정보</TextWinds>
      <div className="flex items-center">
        <div className="mr-auto">
          <TextWinds type="content">
            {orderer ? (
              `${orderer?.name} ( ${phoneFormat(orderer?.phone)} )`
            ) : (
              <>주문자 정보가 없습니다.</>
            )}
          </TextWinds>
        </div>
        {/* <Button variant="text" color="purple" onClick={() => setOpen(true)}>
          <TextWinds type="content_bold" color="purple5">
            변경하기
          </TextWinds>
        </Button> */}
      </div>
      <section>
        {/* 팝업 섹션 */}
        {orderer && (
          <SimpleDialog
            open={open}
            title="주문자 정보 변경"
            handleClose={() => setOpen(false)}
            onConfirm={() => setOpen(false)}
          >
            <OrderinfoChange
              name={orderer.name}
              phone={orderer.phone}
              setOpen={setOpen}
            />
          </SimpleDialog>
        )}
      </section>
    </div>
  );
};

export default OrdererInfo;
