import { Progress } from '@material-tailwind/react'
import React from 'react'

interface ProgressBarProps {
  percentage: number
}
export default function ProgressDefault({
  percentage
}: ProgressBarProps) {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full text-xs font-medium h-1.5 rounded-none">
        <div style={{width: `${percentage}%`}} className={`flex justify-center items-center h-full overflow-hidden break-all bg-skyblue`} />
      </div>
    </div>
  )
}