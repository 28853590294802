import React from "react";
import { Button as MaterialButton } from "@material-tailwind/react";
import { className } from "@material-tailwind/react/types/components/button";

interface ButtonProps {
  variant: "filled" | "gradient" | "outlined" | "text";
  type?: "primary" | "secondary" | "text" | "icon" | "bg";
  color?: "purple1" | "purple5" | "orange4" | "orange5" | "gray2" | "gray9" | "gray8" | "red5";
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: className;
}

export default function Buttons({ variant, type, color, children, onClick, disabled, className }: ButtonProps) {
  const getVariantClassName = (variant: string, color: string) => {
    switch (variant) {
      case "filled":
        let hoverColor = color === "purple5" ? "purple6" : color;
        let hoverBorderColor = color === "purple5" ? "purple6" : color;
        if (color === "orange4") {
          hoverColor = "orange5";
          hoverBorderColor = "orange5";
        }
        return `border border-${color} text-white bg-${color} hover:bg-${hoverColor} hover:border-${hoverBorderColor} focus:opacity-[1] active:opacity-[1] active:border-${color === "purple5" ? "black" : color
          }`;
      case "gradient":
        return `bg-gradient-to-r from-${color} to-${color}`;
      case "outlined":
        return `border border-${color} text-${color} bg-white hover:bg-${color === "purple5" ? "purple0" : color
          } focus:opacity-[1] active:opacity-[1] active:border-${color === "purple5" ? "purple1" : color}`;
      case "text":
        return `bg-white focus:opacity-[1] active:opacity-[1]`;
      default:
        return "";
    }
  };

  const getButtonClassName = (type?: string) => {
    let buttonClassName =
      "flex items-center justify-center w-full h-[54px] px-[10px] py-[15px] rounded shadow-none hover:shadow-none";

    if (type) {
      switch (type) {
        case "secondary":
          buttonClassName =
            "flex items-center justify-center w-full h-[38px] px-[10px] py-2 rounded shadow-none hover:shadow-none";
          break;
        case "text":
          buttonClassName =
            "flex items-center justify-center px-[6px] py-1 rounded shadow-none hover:shadow-none";
          break;
        case "icon":
          buttonClassName =
            "flex items-center justify-center gap-x-2 px-2 py-1 pr-0 rounded shadow-none hover:shadow-none";
          break;
        case "bg":
          buttonClassName =
            "flex items-center justify-center w-full p-0 opacity-80 shadow-none hover:shadow-none";
          break;
      }
    }

    return buttonClassName;
  };

  const variantClassName = getVariantClassName(variant, color);
  const buttonClassName = getButtonClassName(type);

  return (
    <MaterialButton
      className={`text-button ${variantClassName} ${buttonClassName} ${className}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MaterialButton>
  );
}
