import React from "react";
import Shape from "./images/shape-common-purple.png";
import ImageA from "./images/review3-a.png";
import ImageB from "./images/review3-b.png";
import ImageC from "./images/review3-c.png";
import Shape1 from "./images/shape1-purple.png";
import Shape2 from "./images/shape2-purple.png";
import { Link } from "react-router-dom";

export const Review3 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-32 py-16 text-lg leading-7 text-center text-black bg-white max-md:px-5 relative overflow-hidden">
      <img
        src={Shape1}
        width={600}
        className="absolute -left-52 top-[500px] hidden md:visible"
      />
      <img
        src={Shape2}
        width={600}
        className="absolute -right-52 top-[1900px] hidden md:visible"
      />

      <img loading="lazy" src={Shape} className="aspect-square w-[88px]" />
      <div className="mt-5 text-3xl font-bold leading-9 max-md:max-w-full">
        인도에서도 울려 퍼지는 아이들의 말씀 암송!
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-16 tracking-normal text-black max-md:mt-10 max-md:max-w-full">
        저는 인도에서선교를 위해 작은 교회를 운영하고 있습니다.
        <br />이 먼 인도에서도 하나님의 말씀이 울려 퍼질 수 있는 것은
        <br />
        모두 젭스영어주일학교 덕분입니다.
        <br />
        <br />
        <span className="text-purple5">젭스영어주일학교를 만나</span>
        <br />
        <span className="text-purple5">인도에서 주일학교를 운영하게 되고</span>
        <br />
        <span className="text-purple5">
          젭스에서 매번 올려주시는 이벤트 덕분에
        </span>
        <br />
        <span className="text-purple5">
          현지 아이들과도 더욱 깊은 말씀 암송의 훈련을 하고 있습니다.
        </span>
        <br />
        <br />
        어떻게 하면 아이들과 이 좋은 기독교의 절기를
        <br />더 풍성히 보낼 수 있을까고민하다가
        <br />
        얼마 전, 부활절을 기다리는 마음으로
        <br />
        영아부 아이들에게 부활절 그림을 그리게 하고
        <br />그 그림들로 실내 데코레이션도 해보았습니다.
      </div>
      <img
        loading="lazy"
        src={ImageC}
        className="mt-20 max-w-full aspect-[1.92] w-[561px] max-md:mt-10 relative"
      />
      <div className="mt-16 tracking-normal max-md:mt-10 max-md:max-w-full">
        또한 얼마 전 부활절을 맞이하여
        <br />한 달 내내 현지 아이들과 함께
        <br />
        말씀 암송을 준비했습니다.
        <br />
        부활절에 대해, 그리고 예수님에 대해
        <br />
        많이 알아가는 좋은 시간이었습니다.
        <br />
        <br />
        부활절 뿐만 아닙니다.
        <br />
        젭스영어주일학교의 다양한 프로그램 덕분에
        <br />
        지난 성탄절에는 젭스에서 제공해주는 찬양에 맞춰 춤도 추고
        <br />
        스피치 데이도 하며 예수님의 탄생에 기쁨으로 동참할 수 있었습니다.
        <br />
        <br />
        게다가, 영어로 모든 행사를 진행하니
        <br />
        현지 아이들의 부모님들도 놀라하고 뿌듯해 하셨습니다.
        <br />
        <br />
        젭스의 동역해주심으로 준비한 모든 절기의 행사를 통해
        <br />
        저희 교회에는 더 끈끈한 결속력이 생겼고,
        <br />더 나아가 공부방에 새로 등록하는 아이들도 생겨났습니다!
      </div>
      <img
        loading="lazy"
        src={ImageA}
        className="mt-16 max-w-full aspect-[1.79] w-[569px] max-md:mt-10"
      />
      <img
        loading="lazy"
        src={ImageB}
        className="mt-6 max-w-full aspect-[1.43] w-[569px] relative"
      />
      <div className="mt-12 tracking-normal max-md:mt-10">
        <span className="text-purple5">젭스영어주일학교를 통해</span>
        <br />
        <span className="text-purple5">
          말씀 앞에 선교지라는 한계는 의미가 없다는 것을 느낍니다.
        </span>
        <br />
        <br />그 곳이 어디든 하나님의 말씀만 있다면
        <br />
        그리고 그 말씀을 재미있게 암송하고 묵상할 수만 있다면
        <br />그 어디나 행복한 곳임을 고백합니다.
        <br />
        <br />
        앞으로도 젭스와 함께
        <br />
        인도에서 더 크게 말씀 암송이 울려 퍼질 수 있길
        <br />
        바라고, 원하고, 소망합니다!
      </div>
      <div className="mt-20 text-3xl font-bold leading-9 text-slate-900 max-md:mt-10">
        - 인도시온교회 유** 선생님의 후기 -
      </div>
      <div className="shrink-0 self-stretch mt-20 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-20 tracking-normal max-md:mt-10">
        하나님의 말씀은, 예수님의 복음은
        <br />그 곳이 어디든 상관 없습니다.
        <br />
        <br />
        어디서나 생명을 살리고, 기쁨을 주며
        <br />
        함께할 이유를 선물해주기 때문입니다.
        <br />
      </div>
      <div className="justify-center items-center px-16 py-4 mt-14 max-w-full font-medium tracking-normal leading-6 text-black bg-[#EFE4FA] w-[376px] max-md:px-5 max-md:mt-10">
        “젭스영어주일학교를 통해
        <br />
        말씀 앞에 선교지라는 한계는
        <br />
        의미가 없다는 것을 느낍니다.”
      </div>
      <div className="mt-16 tracking-normal max-md:mt-10">
        젭스영어주일학교의 말씀 중심, 복음 중심의 프로그램은
        <br />
        국내 주일학교 뿐 아니라 선교지 복음화 프로그램으로도
        <br />
        활용할 수 있습니다!
      </div>
      <Link to="/courses/churchschool">
        <div className="justify-center items-center px-2.5 py-4 mt-20 max-w-full text-base font-bold tracking-normal leading-6 text-purple5 bg-white rounded border border-purple5 border-solid w-[376px] max-md:px-5 max-md:mt-10">
          주일학교 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
