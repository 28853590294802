import React from 'react';
import Image1 from './img/book-ad.png';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { GradientButton } from './HomeschoolComp';
const HomeschoolSection3DropAD = () => {
  return (
    <div className="flex flex-col p-4 text-center text-black bg-white max-md:px-5 relative text-xs md:text-base">
      <div className="justify-center self-center px-5 py-1 text-2xl bg-[#F2F2F2] rounded-full font-bold ">
        <span className="text-[#59B067]">A.D. 영어학습법</span>이란?
      </div>
      <div className="mt-4">
        알파벳, 파닉스, 영단어, 문법 위주의 옛날식 영어학습방식을
        <br />
        B.C.학습법이라 칭하고, 하나님이 가르쳐 주신 영어학습의 <br />
        새로운 방식을 A.D.학습법이라 칭합니다. <br />
        <br />
        성경에 예비된 대로, <br />
        <span className="font-bold text-[#59B067]">
          언어를 주관하시는 하나님의 언어 원리에 따라
        </span>{' '}
        <br />
        영어에 접근하고 배우는 방법으로,
        <br />
        <br />
        A.D.영어학습법은
        <span className="font-bold">
          {' '}
          ‘단어의 묶음 덩어리’가 ‘궁금한 순서로
        </span>
        <br />
        <span className="font-bold">흐르는 것’ </span>이라는{' '}
        <span className="font-bold text-[#59B067]">청크(chunk)원리</span>를 쉽고
        재미있게
        <br /> 배울 수 있는 학습법입니다. <br />
        <br />
        <span className="font-bold">
          어렵게 영어를 분석하는 수고 없이, 아이들도 쉽게 문장을
        </span>
        <br />
        <span className="font-bold">
          그대로 머리에 옮겨심으며 영어를 내뱉을 수 있습니다.{' '}
        </span>
      </div>

      <motion.img
        loading="lazy"
        src={Image1}
        className="self-center mt-5 w-[100px]"
        animate={{ y: 10 }}
      />
      <a href="http://aladin.kr/p/FzQW9" target="_blank" className="mt-11">
        <GradientButton text="정철의 A.D.영어학습법 교재 보기" />
      </a>
    </div>
  );
};

export default HomeschoolSection3DropAD;
