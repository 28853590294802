import React from "react";

interface IconProps {
    width: number;
    height: number;
    fileName: string;
}

const Icon: React.FC<IconProps> = ({ width, height, fileName }) => {
    return (
        <img
            src={process.env.PUBLIC_URL + "/images/common/" + fileName + ".svg"}
            alt={fileName}
            width={width}
            height={height}
            style={{ display: "inline-block" }}
        />
    );
};

export default Icon;
