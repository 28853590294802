import React from 'react';

const WarningSection = () => {
  return (
    <div className="bg-[#FFD01E] p-3 py-8 md:p-10">
      <div
        className="max-w-[520px] w-full m-auto
                flex flex-col justify-center items-center gap-5 text-center font-bold"
      >
        {/* 타이틀 */}
        <div className=" text-center text-[#6463E3] text-2xl md:text-4xl">
          성공하는 교회와{' '}
          <span className="text-red-500 text-shadow-sm">아닌 교회</span>의 차이!
        </div>

        <div className="flex flex-col gap-5 text-base md:text-xl">
          <div>
            <span className="underline underline-offset-4">
              학생 수대로 학생용 교재를 구매하여 <br />
              프로그램 운영안대로 수업을 진행하는 것! <br />
            </span>
            <span className="font-medium">
              (교재 없이 운영 시 프로그램 효과가 떨어지며, <br />
              교재 비용은 학부모가 부담하는 것이 좋습니다.)
            </span>
          </div>

          <span>
            *교재 불법 복제 근절에 동참해주세요.
            <br />
            교회학교의 정직한 교재 사용을 부탁드립니다.
          </span>
        </div>
      </div>
    </div>
  );
};

export default WarningSection;
