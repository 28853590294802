import { Link } from "react-router-dom";

interface FooterLinkProps {
  to: string;
  view: string;
}
export const FooterLinkIcon = ({ to, view }: FooterLinkProps) => {
  return (
    <Link to={to} target="_blank">
      <img
        alt="Frame"
        src={process.env.PUBLIC_URL + view}
        className="w-10 lg:w-[60PX]"
      />
    </Link>
  );
};

export const FooterLinkString = ({ to, view }: FooterLinkProps) => {
  return (
    <Link
      to={to}
      className="block font-sans text-base antialiased font-bold leading-relaxed transition-colors text-blue-gray-900 hover:text-blue-500 focus:text-blue-500"
    >
      {view}
    </Link>
  );
};
