import React from 'react';
import StudentBadgeItem from './StudentBadgeItem';
import { studentBadgeType } from '../../../constants/types/student-type';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';

const StudentBadgeList = () => {
  const [badgeList, setBadgeList] = React.useState<studentBadgeType[]>([]);

  const getBadgeList = async () => {
    try {
      const result = await instance.get<ApiResponse<studentBadgeType[]>>(
        '/v1/student/badge'
      );
      return result.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getBadgeList().then((data) => {
      setBadgeList(data);
    });
  }, []);

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4 mt-8 justify-between gap-y-10">
        {badgeList
          .sort((a, b) => a.sort - b.sort)
          .map((item, index) => (
            <StudentBadgeItem key={index} badge={item} />
          ))}
      </div>
      {badgeList.length === 0 && (
        <div className="text-center w-full font-lg text-gray7 text-bold">
          획득한 뱃지가 없습니다.
        </div>
      )}
    </>
  );
};

export default StudentBadgeList;
