import { Progress } from '@material-tailwind/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import { myLearningInfoType } from '../../../constants/types/student-type';
import instance from '../../../api/axios/utils.ts/instance';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
interface StudentClassItemProps {
  data: myLearningInfoType;
}
const StudentClassItem = ({ data }: StudentClassItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const percentage = 0;
  const pageMoveHander = async () => {
    try {
      const result = await instance.get(
        '/v1/student/class/' + data.myLearning.id
      );
      if (result.data.statusCode === 200) {
        navigate(`/student/class/${data.myLearning.id}`);
      }
    } catch (e) {
      dispatch(openAlert({ message: '접근 권한이 없습니다.' }));
    }
  };
  return (
    <div
      className="w-full h-auto bg-clip-border rounded-lg shadow-card cursor-pointer"
      onClick={() => {
        pageMoveHander();
      }}
    >
      <img
        src={data.myLearning.thumbnail}
        className="w-full h-[150px] object-cover rounded-t-lg"
      />
      <div className="h-auto px-3.5 py-3">
        <div className="flex gap-2">
          <img src="/images/common/icon_pencil.svg" />
          <TextWinds type={'title_h4'} children={data.myLearning.name} />
        </div>
        <Progress value={percentage} color="blue" size="md" className="mt-2" />
        {/* <TextWinds
          type={'caption1'}
          children={`나의 할 일 완료율 ${percentage}%`}
        /> */}
        {/* <div className="border-t border-[#ccc] mt-2 pt-2"> */}
        <div className="mt-2 pt-2">
          <TextWinds
            type={'caption1'}
            children={`담당 선생님 : ${data.myLearning.teacherName}`}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentClassItem;
