import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import MegaMenu from '../../components/common/layouts/megamenu';
import ScrollToTop from '../../components/layout/ScrollToTop';
import useUserHook from '../../controllers/useUserHook';

const LayoutStudent = () => {
  const navigate = useNavigate();
  const isActive = localStorage.getItem('isActive');
  useEffect(() => {
    if (isActive === 'false') {
      navigate('/student/firstLogin');
    }
  }, []);
  return (
    <>
      <MegaMenu />
      <main className="mt-[58px]">
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default LayoutStudent;
