import React, { Fragment } from "react";
import Buttons from "../../../components/common/Buttons2";
import ComInput from "../../../components/common/ComInput";
import TextWinds from "../../../components/common/TextWinds";

const LoginIdEmail = () => {
    return (
        <Fragment>
            <div className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-y-2">
                    <TextWinds type="content_body3">이름</TextWinds>
                    <ComInput type="text" placeholder="이름 입력" onChange={(e) => {}} />
                </div>
                <div className="flex flex-col gap-y-2">
                    <TextWinds type="content_body3">이메일 주소</TextWinds>
                    <ComInput type="text" placeholder="이메일 입력" onChange={(e) => {}} />
                </div>
            </div>
            <div className="mt-[52px]">
                <Buttons variant="filled" color="purple5">
                    <TextWinds type="button" color="white">
                        확인
                    </TextWinds>
                </Buttons>
            </div>
        </Fragment>
    );
};

export default LoginIdEmail;
