import React from 'react';
import HFbutton from '../../common/input/HFbutton';
import TextWinds from '../../common/TextWinds';
interface TakeclassLinkButtonProps {
  id: string;
  openAction?: (id: string) => void;
}
const TakeclassLinkButton = ({ id, openAction }: TakeclassLinkButtonProps) => {
  return (
    <div className="w-[80px] m-auto">
      <HFbutton type="Outlined" onClick={() => openAction(id)} height={36}>
        <TextWinds type="button_text1" color="purple5">
          수업하기
        </TextWinds>
      </HFbutton>
    </div>
  );
};

export default TakeclassLinkButton;
