import React from 'react';
import ReactPlayer from 'react-player';
import { openNewWindow } from '../../../../api/utils/util-func';
import {
  NeumophismButton,
  CircleRightArrowIcon,
  NeumophismAccordion,
} from '../wmw/WmwComp';
import { motion } from 'framer-motion';
import ADbook from '../img/sec05-ADbook.png';
const videoUrl = 'https://youtu.be/Cqt7cDnZVNc?si=GOAeC-6Yq90tAvvO';
const formUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLScMRotEaPs4nzwkv0S9qOUadyqnhD59FY8h2VfzDaFNTHzBNA/viewform';
const bookUrl = 'https://search.shopping.naver.com/book/catalog/40460513623';

const HFHJSection5 = () => {
  return (
    <div className="bg-gradient-to-b from-[#fff] to-[#f5f5fa] p-3 py-10 md:p-10">
      <div className="flex flex-col gap-10 w-full max-w-[520px] m-auto">
        <div className="overflow-hidden rounded-2xl aspect-video">
          <ReactPlayer url={videoUrl} width="100%" height="100%" controls />
        </div>
        <NeumophismButton
          onClick={() => openNewWindow(formUrl)}
          className="flex gap-2 items-center justify-center p-5 bg-[#f5f5fa] rounded-2xl cursor-pointer
          text-center text-[#FF5C00] font-bold text-base md:text-xl"
        >
          지금 바로 도입 문의하기
          <CircleRightArrowIcon color="#FF5C00" />
        </NeumophismButton>
        <NeumophismAccordion
          title={
            <div className="flex-1 text-base md:text-xl">
              <span className="font-bold">A.D.영어학습법</span>이 무엇일까요?
            </div>
          }
        >
          <ADbookInfo />
        </NeumophismAccordion>
      </div>
    </div>
  );
};

const ADbookInfo = () => {
  return (
    <div className="bg-[#FCAF3C] text-black p-5 rounded-lg text-center text-sm md:text-base">
      <div>
        알파벳, 파닉스, 영단어, 문법 위주의
        <br />
        <span className="font-bold">옛날식 영어학습방식을 B.C.학습법</span>이라
        칭하고,{' '}
        <span className="text-white font-bold">
          하나님이 가르쳐 주신 영어학습의 새로운 방식을 A.D.학습법
        </span>
        이라 칭합니다.
        <br /> A.D.영어학습법은 ‘단어의 묶음 덩어리’가 ‘궁금한 순서로 흐르는 것’
        이라는 청크(chunk)원리를 쉽고 재미있게 배울 수 있는 학습법입니다.
      </div>
      <img src={ADbook} alt="ADbook" className="px-5 sm:px-10" />

      <motion.div
        className="flex items-center justify-center gap-2 bg-[#ffdfaf] p-3 rounded text-black cursor-pointer"
        whileHover={{ opacity: 0.9, scale: 1.01 }}
        onClick={() => openNewWindow(bookUrl)}
      >
        <div>
          정철의{' '}
          <span className="text-[#FF5C00] font-bold">
            A.D.영어학습법 도서 보기
          </span>
        </div>
        <CircleRightArrowIcon width={16} color="#FF5C00" />
      </motion.div>
    </div>
  );
};

export default HFHJSection5;
