import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import { ThemeProvider } from '@material-tailwind/react';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ko_KR from 'antd/es/locale/ko_KR';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // 개발모드인 경우,,,
  // <React.StrictMode>
  //     <ThemeProvider>
  //         <Provider store={store}>
  //             <PersistGate loading={null} persistor={persistor}>
  //                 <App />
  //             </PersistGate>
  //         </Provider>
  //     </ThemeProvider>
  // </React.StrictMode>

  // 개발모드 아닐경우,,,
  // <ThemeProvider>
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Pretendard',
        colorPrimary: '#6032db',
      },
    }}
    locale={ko_KR}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ConfigProvider>
  // </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
