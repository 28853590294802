import { className } from "@material-tailwind/react/types/components/button";
import { PaymentMethod } from "../../enum";

interface CustomRadiosProps {
  id: string;
  color: "purple2" | "purple5" | "skyblue" | "orange" | "orange4" | "gray9" | "gray6" | "red5";
  target: string;
  children: React.ReactNode;
  onChange?: (id: string) => void;
  className?: className
}
export default function CustomRadios ({
  id,
  color,
  target,
  children,
  onChange,
  className,
}: CustomRadiosProps) {
  const getLabelClassName = () => {
    let labelClassName = `
      flex
      items-center
      justify-center
      w-full
      h-[55px]
      border
      border-solid
      rounded-[4px]
      shadow-none
      hover:shadow-none
      hover:bg-gray-100
    `
    if(target) {
      labelClassName += `
        peer-checked:border-blue-600
        peer-checked:bg-purple5
        text-white
        border-${color}
      `;
    } else {
      labelClassName += `
        peer-checked:border-blue-600
        peer-checked:bg-gray-100
        text-${color}
        border-${color}
      `;
    }
    return labelClassName;
  }
  const labelClassName = getLabelClassName()
  return (
    <li>
      <input type='radio' id={id} name={'payments'} value={id} className='hidden peer' required onChange={(e) => onChange(e.target.id)} />
      <label htmlFor={id} className={`cursor-pointer ${labelClassName}`} >
        <div className={`${className}`}>
          {children}
        </div>
      </label>
    </li>
  )
}