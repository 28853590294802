import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  ChosenVariant,
  CommonResProps,
  CourseInfoType,
  EnrollOption,
  OptionProduct,
  ProductContent,
  ProductCourse,
} from '../../constants/types';
import instance from '../../api/axios/utils.ts/instance';
import { RootState } from '..';
import { AxiosParamType } from '.';
import {
  legacyCourseListType,
  legacyCourseType,
} from '../../constants/legacyTypes';
import { CourseType } from '../../enum';

interface stateObj {
  status: string;
  corseLists: CommonResProps<CourseInfoType | CourseInfoType[]>;
  courseInfo: CourseInfoType;
}
const initialState: stateObj = {
  status: '',
  corseLists: {
    page: null,
    result: null,
  },
  courseInfo: null,
};

const DataParser = (data: legacyCourseType): CourseInfoType => {
  const courseType =
    data?.courses?.length > 1
      ? CourseType.TRAINING
      : data.courses[0].course.type;
  const contents: ProductContent[] = data.contents.map((v) => ({
    id: v.id,
    order_idx: v.order_idx,
    type: v.type,
    file_id: v.file_id,
    video_url: v.video_url,
    url_link: v.url_link,
    url_label: v.url_label,
    product_id: data.id,
    file: {
      access_url: v.file?.access_url,
    },
  }));

  const courses: ProductCourse[] = data.courses
    .filter((v1) => v1.course.type === courseType)
    .map((v2) => ({
      course_model_id: v2.course_model_id,
      training_course_id: v2.training_course_id,
      course_id: v2.course_id,
      course: {
        id: v2.course.id,
        name: v2.course.name,
        type: v2.course.type,
        required_completion_percent: v2.course.required_completion_percent,
      },
    }));

  const optionProduct: OptionProduct[] = data.option_products.filter(
    (v1) =>
      v1.option_constraints[0].constraint_course_id === courses[0].course.id
  );
  const filtered = data.option_products.filter(
    (v1) =>
      v1.option_constraints[0].constraint_course_id === courses[0]?.course.id
  );
  const options: ChosenVariant[] = filtered.map((v2) => {
    const v3 = v2.option_product.option_variants[0];
    return {
      id: v3.id,
      product_id: v3.product_id,
      name: v3.name,
      additional_price: v3.additional_price,
      inventory_code: v3.inventory_code,
      is_active: v3.is_active,
      jebspl_subscription_period: v3.jebspl_subscription_period,
    };
  });

  const enrolls: EnrollOption[] = data.courses
    .find((v1) => v1.course.type === courseType)
    .enroll_options.map((v2) => ({
      id: v2.id,
      product_course_id: v2.product_course_id,
      period: v2.period,
      price: v2.price,
      is_active: v2.is_active,
    }));

  const rtn: CourseInfoType = {
    id: data.id,
    name: data.name,
    description: data.description,
    thumbnail_url: data.thumbnail.access_url,
    current_price: data.current_price,
    origin_price: data.origin_price,
    base_quantity: data.base_quantity,
    courses: data.courses,
    contents: contents,
    option_products: optionProduct,
    option: options,
    detail_imgs: data.contents.map((v) => v.file?.access_url),
    enrolls: enrolls,
    product_type: data.product_type,
    product_sub_type: data.product_sub_type,
  };
  return rtn;
};

const DataListParser = (list: legacyCourseListType[]): CourseInfoType[] => {
  return list.map((data) => {
    const rtn: CourseInfoType = {
      id: data.id,
      name: data.name,
      description: data.description,
      thumbnail_url: data.thumbnail.access_url,
      current_price: data.current_price,
      origin_price: data.origin_price,
      base_quantity: 0,
      contents: [],
      option: [],
      detail_imgs: [],
      courses: [],
      enrolls: [],
      product_type: data.product_type,
      product_sub_type: data.product_sub_type,
    };
    return rtn;
  });
};

export const getCourseLists = createAsyncThunk<
  CommonResProps<CourseInfoType[]>,
  AxiosParamType
>('asyncThunk/getCourseLists', async ({ url, params }) => {
  try {
    const response = await instance.get(url, { params });
    response.data.result = DataListParser(response.data.result);
    return response.data;
  } catch (error) {
    console.error('[axios] getCourseLists : ', error);
    return isRejectedWithValue(error);
  }
});
export const getCourseInfo = createAsyncThunk<CourseInfoType, AxiosParamType>(
  'asyncThunk/getCourseInfo',
  async ({ url }: AxiosParamType) => {
    try {
      const response = await instance.get(url);
      response.data = DataParser(response.data);
      return response.data;
    } catch (error) {
      console.error('[axios] getCourseInfo : ', error);
      return isRejectedWithValue(error);
    }
  }
);

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    reset(state) {
      state.corseLists = {
        page: null,
        result: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // 코스 목록 조회
      .addCase(getCourseLists.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(
        getCourseLists.fulfilled,
        (state, action: PayloadAction<CommonResProps<CourseInfoType[]>>) => {
          state.status = 'fullfilled';
          state.corseLists = action.payload;
        }
      )
      .addCase(getCourseLists.rejected, (state) => {
        state.status = 'rejected';
      })
      // 코스 상세 조회
      .addCase(getCourseInfo.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(
        getCourseInfo.fulfilled,
        (state, action: PayloadAction<CourseInfoType>) => {
          state.status = 'fullfilled';
          state.courseInfo = action.payload;
        }
      )
      .addCase(getCourseInfo.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const { reset } = courseSlice.actions;
export const selectCourseLists = (state: RootState) => state.course.corseLists;
export const selectCourseInfo = (state: RootState) => state.course.courseInfo;
export default courseSlice.reducer;
