import React, { useEffect } from 'react';
import NormalPage from '../../../../components/layout/NormalPage';
import LearningTabs from '../../../jebs/learning/component/LearningTabs';
import TextWinds from '../../../../components/common/TextWinds';
// import SortingButton from '../../../jebs/learning/component/SortingButton ';
import StudentHomeworkList from './StudentHomeworkList';
import { ToDoStatusEnum } from '../../../../enum';
import instance from '../../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../../constants/types';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import { useParams } from 'react-router-dom';

const StudentHomeworkPage = () => {
  // const [sortingState, setSortingState] = useState('default');
  const { classId } = useParams();
  const [status, setStatus] = React.useState<ToDoStatusEnum | ''>('');
  const [count, setCount] = React.useState<{
    total: number;
    completed: number;
  }>({ total: 0, completed: 0 });

  const headData = [
    {
      label: '전체',
      value: '',
    },
    {
      label: '진행 중',
      value: 'IN_PROGRESS',
    },
    {
      label: '완료',
      value: 'COMPLETED',
    },
  ];

  const statusLabel = headData.find((item) => item.value === status)?.label;

  useEffect(() => {
    getHomework();
  }, []);

  // const handleSortChange = (sortState: string) => {
  //   // Handle the sorting state change here
  //   setSortingState(sortState);
  // };
  const setStatusHandler = (status: string) => {
    switch (status) {
      case 'IN_PROGRESS':
        setStatus(ToDoStatusEnum.IN_PROGRESS);
        break;
      case 'COMPLETED':
        setStatus(ToDoStatusEnum.COMPLETED);
        break;
      case 'NOT_STARTED':
        setStatus(ToDoStatusEnum.NOT_STARTED);
        break;
      case 'CLOSED':
        setStatus(ToDoStatusEnum.CLOSED);
        break;
      default:
        setStatus('');
        break;
    }
  };

  const getHomework = async () => {
    //params: HomeworkFilterParam) => {
    const params = {
      page: 1,
      pageSize: 10,
    };
    const result = await instance.get<
      ApiResponse<{
        todos: myLearningTodoType[];
        completedCount: number;
        totalCount: number;
      }>
    >('/v1/student/class/' + classId + '/todo', { params });
    if (result.data.statusCode === 200) {
      setCount({
        total: result.data.data.totalCount,
        completed: result.data.data.completedCount,
      });
    } else {
      console.error(result.data.message);
    }
  };

  return (
    <>
      <NormalPage xlBgColor="gray1" bgColor="gray1" paddingY={0} paddingX={0}>
        <LearningTabs
          data={headData}
          onChange={(tabValue) => setStatusHandler(tabValue)}
        >
          <div className="flex justify-between">
            <div className="flex gap-[6px]">
              <TextWinds type="title_h4">{statusLabel}</TextWinds>
              <TextWinds type="content" color="gray6">
                ({count.total}개 중 {count.total - count.completed}개 미완료)
              </TextWinds>
            </div>
            {/* <SortingButton onSortChange={handleSortChange} /> */}
          </div>
          <div className=" w-full  mx-auto px-[20px] py-[16px] xl:px-[2px]">
            <StudentHomeworkList status={status} />
          </div>
        </LearningTabs>
      </NormalPage>
    </>
  );
};

export default StudentHomeworkPage;
