import { Typography } from '@material-tailwind/react';
import React, { Fragment } from 'react';
import { COURSE_SUMMERY_DATA } from '../../../data/statics-datas';
import { IconNavLink } from '../../../components/IconNavLink';
import NormalPage from '../../../components/layout/NormalPage';
import TextWinds from '../../../components/common/TextWinds';
import Box from '../../../components/layout/Box';
import FullWidthPage from '../../../components/layout/FullWidthPage';

const Courses = () => {
  return (
    <>
      <FullWidthPage>
        <div className="px-[20px] xl:px-0">
          <div className="pt-[30px]">
            <TextWinds type="title_h2" xlType="title_h1">
              코스 찾기
            </TextWinds>
            <TextWinds type={'sub_title01'} className="pt-[20px]">
              어떤 코스를 찾고 계신가요?
            </TextWinds>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-5 lg:grid-cols-4 lg:mt-9">
            {COURSE_SUMMERY_DATA.map((item, index) => (
              <IconNavLink
                key={index}
                to={item.to}
                src={item.src}
                text1={item.text1}
                text2={item.text2}
              />
            ))}
          </div>
        </div>
      </FullWidthPage>
    </>
  );
};

export default Courses;
