import React from 'react'
import { pointType } from '../../../constants/types/mypage-type'
import PointItem from './PointItem'
interface PointListProps {
    list:pointType[]
}
const PointListComp = ({list}:PointListProps) => {
  return (
    list===undefined || list.length===0
    ?<section>
      <div className="flex justify-center items-center h-[200px]">
        <p className="text-[14px] text-gray5">내역이 없습니다.</p>
      </div>        
    </section>
    :<section>
      {
        list.map(
          (point, key) => 
            (<PointItem key={key} data={point} />)
        )
      }
    </section>
  )
}

export default PointListComp
