import React, { useEffect } from 'react';
import StudentHomeworkItem from './StudentHomeworkItem';
import { getRandom } from '../../../../api/utils/util-func';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import { useParams } from 'react-router-dom';
import instance from '../../../../api/axios/utils.ts/instance';
import { ToDoStatusEnum } from '../../../../enum';
import { ApiResponse } from '../../../../constants/types';

type HomeworkFilterParam = {
  page: number;
  pageSize: number;
  status?: string | string[];
};
interface StudentHomeworkListProps {
  max?: number;
  status?: ToDoStatusEnum | '';
}

const StudentHomeworkList = ({ max, status }: StudentHomeworkListProps) => {
  const { classId } = useParams();
  const PAGESIZE = 10;
  const [page, setPage] = React.useState<number>(1);
  const [data, setData] = React.useState<myLearningTodoType[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const observer = React.useRef<IntersectionObserver>();
  const setTodoView = React.useState<Window>(undefined)[1];

  const getHomework = async (page: number) => {
    //params: HomeworkFilterParam) => {
    const params = getParams(page, status);
    const result = await instance.get<
      ApiResponse<{
        todos: myLearningTodoType[];
        completedCount: number;
        totalCount: number;
      }>
    >('/v1/student/class/' + classId + '/todo', { params });
    if (result.data.statusCode === 200) {
      return result.data.data;
    } else {
      console.error(result.data.message);
    }
  };

  const getParams = (page: number, status: ToDoStatusEnum | '') => {
    const params: HomeworkFilterParam = {
      page: page,
      pageSize: PAGESIZE,
    };
    if (status) {
      switch (status) {
        case ToDoStatusEnum.IN_PROGRESS:
          params.status = 'IN_PROGRESS';
          break;
        case ToDoStatusEnum.COMPLETED:
        case ToDoStatusEnum.CLOSED:
          params.status = ['COMPLETED', 'CLOSED'];
          break;
        default:
          break;
      }
    }
    return params;
  };

  useEffect(() => {
    setData([]);
    setHasMore(true);
    loadMoreItems(1); // Initial load
  }, [status]);

  const loadMoreItems = React.useCallback(
    async (page: number) => {
      const newItems = await getHomework(page);
      setData((prev) => [...prev, ...newItems.todos]);
      setPage(page + 1);

      if (newItems.todos.length === 0) {
        setHasMore(false);
      }
    },
    [status]
  );

  const lastItemRef = React.useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreItems(page);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, page, loadMoreItems]
  );

  return (
    <div className="mt-4 grid grid-cols-1 gap-4">
      {data && data.length > 0 ? (
        data
          .slice(0, max)
          .map((item, index) => (
            <StudentHomeworkItem
              data={item}
              key={item.id}
              setTodoView={setTodoView}
              lastItemRef={
                max !== undefined
                  ? undefined
                  : index === data.slice(0, max).length - 1
                  ? lastItemRef
                  : undefined
              }
            />
          ))
      ) : (
        <div>할 일이 없습니다.</div>
      )}
    </div>
  );
};

export default StudentHomeworkList;
