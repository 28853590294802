import React from 'react'
import { BoostUpTypes } from '../../constants/types'
import BoostupCard from '../../pages/lms/dashboard/boostup/component/Card'

const BoostupWidget = ({ datas, classId }: { datas: BoostUpTypes[], classId: string }) => {
  return (
    <>
      {
        datas !== null &&
        datas?.map((data, idx) => 
          <BoostupCard
            key={idx}
            classId={classId}
            data={data}
            // id={data.id}
            // name={data.name}
            // product_type={data.product_type}
            // product_sub_type={data.product_sub_type}
            // origin_price={data.origin_price}
            // current_price={data.current_price}
            // base_quantity={data.base_quantity}
            // shipping_fee={data.shipping_fee}
            // thumbnail={data.thumbnail}
          />
        )
      }
    </>
  )
}

export default BoostupWidget