import { useLocation, useNavigate } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import Buttons from "../../../components/common/Buttons2";

const MypageEventDetail = () => {
    const location = useLocation();
    const eventDetail = location.state;
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate(-1);
    };

    return (
        <>
            <section>
                <div className="hidden lg:block lg:mb-6">
                    <TextWinds type="title_h2">이벤트 상세</TextWinds>
                </div>
                <div className="flex flex-col gap-y-8 px-5 pt-4 pb-6 lg:p-0">
                    <div className="flex flex-col gap-y-2 pb-2 border-b">
                        <TextWinds type="sub_title01">{eventDetail.title}</TextWinds>
                        <div className="flex justify-between items-center ">
                            <TextWinds type="content_body4">{eventDetail.date}</TextWinds>
                            <TextWinds type="content_body4">관리자</TextWinds>
                        </div>
                    </div>
                    <div className="pb-8 border-b">
                        <div className="mb-6">
                            <img src={process.env.PUBLIC_URL + eventDetail.img} alt="사람" />
                        </div>
                        <TextWinds type="content_body2">{eventDetail.text}</TextWinds>
                    </div>
                    <div className="flex gap-x-2">
                        <div className="w-full">
                            <Buttons variant="outlined" color="purple5" onClick={() => clickHandler()}>
                                <TextWinds type="button" color="purple5">
                                    목록으로 돌아가기
                                </TextWinds>
                            </Buttons>
                        </div>
                        <div className="hidden lg:block lg:w-full">
                            <Buttons variant="filled" color="purple5">
                                <TextWinds type="button" color="white">
                                    이벤트 참여
                                </TextWinds>
                            </Buttons>
                        </div>
                    </div>
                </div>
                <div className="p-4 shadow-[0_0_4px_0_rgba(0,0,0,0.25)] lg:hidden">
                    <Buttons variant="filled" color="purple5">
                        <TextWinds type="button" color="white">
                            이벤트 참여
                        </TextWinds>
                    </Buttons>
                </div>
            </section>
        </>
    );
};

export default MypageEventDetail;
