import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { phoneFormat } from '../../../api/utils/format/numbers';
import HFinput from './HFinput';

interface HFinputPhoneProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  register?: UseFormRegisterReturn;
  error?: string;
  height?: number;
  defaultValue?: string;
  formatToPhoneNumber?: (value: string) => string;
  unformatPhoneNumber?: (value: string) => string;
}

const defaultFormatToPhoneNumber = phoneFormat;
const defaultUnformatPhoneNumber = (value: string) => value.replace(/-/g, '');

const HFinputPhone = ({
  className,
  label,
  register,
  error,
  height = 50,
  defaultValue = '',
  formatToPhoneNumber = phoneFormat,
  unformatPhoneNumber = defaultUnformatPhoneNumber,
  ...rest
}: HFinputPhoneProps) => {
  const [rawValue, setRawValue] = useState(defaultValue);

  const py = (height - 26) / 2;
  const size = `w-full h-auto px-[10px] py-[${py}px] text-md mb-6`;
  const design = 'bg-white rounded transition-colors';
  const text = 'overflow-hidden text-ellipsis';
  const border = 'border border-gray5 focus:outline-none focus:border-purple5';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = unformatPhoneNumber(e.target.value); // Remove dashes for raw value
    setRawValue(inputValue);

    if (register) {
      register.onChange({
        ...e,
        target: {
          ...e.target,
          value: inputValue, // Set the raw value without dashes
        },
      });
    }
  };

  return (
    <div className="relative">
      <div className={`text-sm mb-2 leading-snug ${!label && 'hidden'}`}>
        {label}
      </div>
      <input
        type="text"
        className={`${size} ${design} ${border} ${text} ${
          error ? 'border-red-500' : ''
        }
        ${className}`}
        {...rest}
        {...register}
        value={defaultFormatToPhoneNumber(rawValue)} // Display formatted value
        onChange={handleChange}
      />

      {error && (
        <div className="absolute left-0 bottom-1 text-red-500 text-xs">
          {error}
        </div>
      )}
    </div>
  );
};

export default HFinputPhone;
