import React from 'react';
import { SnowFlower } from './HomeschoolComp';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';

const HomeschoolSection7 = () => {
  return (
    <div className="relative flex flex-col items-center overflow-hidden bg-[#eb5d1a]">
      <div className="w-full max-w-[480px]">
        <section className="flex flex-col items-center p-5 pt-[80px] ">
          <SnowFlower color="white" opacity={1} width={24} />
          <div className="text-3xl md:text-4xl text-center mt-3 text-white">
            젭스영어성경홈스쿨,
            <div className="font-bold">이렇게 시작하세요!</div>
          </div>
        </section>
        <section className="flex w-full py-20 pr-10">
          <div className="relative bg-[#ffc1a4] w-[1px] mx-5">
            <div className="absolute bottom-[-2px] left-1 w-[1px] bg-[#ffc1a4] h-3 rotate-45"></div>
          </div>
          <div className="flex-1 mt-[-12px] text-white text-sm">
            <Card
              head="Case 01"
              title="가정에서 자녀와 함께 홈스쿨로 운영"
              url="https://youtu.be/quUKGugiVvs?si=q_CZ-szNrnkAt7bP"
            />
            <Dash />
            <Card
              head="Case 02"
              title="공부방, 교습소로 학생을 모집하여 운영"
              url="https://youtu.be/YyKOUcq8BGQ?si=vE-OCr5HGQUgl8nQ"
            />
            <Dash />
            <Card
              head="Case 03"
              title="교회 주중 공부방, 소모임 등으로 활용"
              url="https://youtu.be/UD1P3GtEnVo?si=2Wi0T-4w3nn3N2Lg"
            />
            <Dash />
            <Card
              head="Case 04"
              title="대안학교에서 영어교과로 활용"
              url="https://youtu.be/75jgHQDdwp8?si=hgR43vR6MAH_sQeY"
            />
            <div className="h-20"></div>
          </div>
        </section>
      </div>
    </div>
  );
};

const Card = ({
  head,
  title,
  url,
}: {
  head: string;
  title: string;
  url: string;
}) => (
  <div className="relative text-base md:text-lg font-bold">
    {/* historyLine의 점 */}
    <div className="absolute left-[-24px] top-3 h-2 w-2 bg-[#ffc1a4] rounded-full"></div>

    {/* case */}
    <motion.div
      className="border-[1px] border-white rounded-full w-fit px-3 py-0.5"
      initial={{ x: -20 }}
      whileInView={{ x: 0 }}
    >
      {head}
    </motion.div>
    {/* title */}
    <motion.div
      className="mt-2"
      initial={{ y: -20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
    >
      {title}
    </motion.div>

    <div className="mt-5 rounded-xl overflow-hidden w-full aspect-video">
      <ReactPlayer width={'100%'} height={'100%'} url={url} controls />
    </div>
  </div>
);
const Dash = () => (
  <div className="my-8 border-t border-[#ff7d40] border-dashed"></div>
);

export default HomeschoolSection7;
