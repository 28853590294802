import React from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { useAppDispatch } from '../../../store/hook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import instance from '../../../api/axios/utils.ts/instance';
import {
  closeModal,
  openAlert,
  openModal,
} from '../../../store/features/modalSlice';
import HFinput from '../../../components/common/input/HFinput';
import HFinputPhone from '../../../components/common/input/HFinputPhone';
import HFbutton from '../../../components/common/input/HFbutton';
import {
  getErrorMessages,
  handleCopyClipBoard,
} from '../../../api/utils/util-func';
import { timeLeftCheck, timeLeftToString } from '../../../controllers/useTimer';
import useSmsHook from '../../../controllers/useSmsHook';

const LoginPasswordPhone = () => {
  type resetPwByPhoneDTO = {
    uid: string;
    name: string;
    verifySmsId: string;
    phone: string;
    verifyType: 'PHONE';
  };

  const dispatch = useAppDispatch();
  const navigete = useNavigate();
  const [searchParam] = useSearchParams();
  const uid = searchParam.get('uid');
  const [verifyNumber, setVerifyNumber] = React.useState<string>('');
  const [isConfirm, setIsConfirm] = React.useState<boolean>(false);

  const { sendVerifyNumberWithName, checkVerifyNumber, timeLeft } =
    useSmsHook();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<resetPwByPhoneDTO>({ defaultValues: { uid: uid } });

  const name = watch('name');

  const onSubmit = (data: resetPwByPhoneDTO) => {
    data.verifyType = 'PHONE';

    if (data.verifySmsId === undefined) {
      dispatch(openAlert({ message: '전화번호 인증을 받아주세요.' }));
      return;
    }

    const params: resetPwByPhoneDTO = {
      uid: data.uid,
      name: data.name,
      phone: data.phone.replace(/-/g, ''),
      verifyType: 'PHONE',
      verifySmsId: data.verifySmsId,
    };

    getUserId(params);
  };

  const Alert = (msg: string) => {
    dispatch(openAlert({ message: msg }));
  };

  // 문자 발송
  const sendSmsHandler = () => {
    sendVerifyNumberWithName(watch('phone'), name).then((msg) => {
      Alert(msg);
    });
  };

  // 문자 확인
  const confirmSmsHandler = () => {
    checkVerifyNumber(watch('phone'), verifyNumber).then((res) => {
      if (res.confirm) {
        setIsConfirm(true);
        setValue('verifySmsId', res.confirm.toString());
      }
      Alert(res.msg);
    });
  };

  const getUserId = async (params: resetPwByPhoneDTO) => {
    try {
      const res = await instance.patch(
        '/v1/mypage/user/reset-password',
        params
      );

      if (res === undefined) {
        dispatch(openAlert({ message: '회원정보가 일치하지 않습니다.' }));
        return;
      }

      dispatch(
        openModal({
          body: (
            <ViewPwModal
              pw={res.data.data.password}
              linkTo={(link) => navigete(link)}
            />
          ),
        })
      );
    } catch (err) {
      dispatch(openAlert({ message: getErrorMessages(err) }));
    }
  };

  return (
    <form>
      <TextWinds
        type="caption1"
        className="mb-5 text-center bg-gray2 p-2 rounded"
      >
        학생 계정의 비밀번호 초기화는 클래스 선생님의 LMS를 통해 초기화 할 수
        있습니다.
      </TextWinds>
      <HFinput
        label="아이디"
        name="uid"
        register={register('uid', {
          required: { value: true, message: '아이디를 입력해주세요.' },
        })}
        placeholder="아이디를 입력해주세요."
        error={errors.uid?.message}
      />
      <HFinput
        label="이름"
        name="name"
        register={register('name', {
          required: { value: true, message: '이름을 입력해주세요.' },
        })}
        placeholder="이름을 입력해주세요."
        error={errors.name?.message}
      />
      <div className="relative">
        <HFinputPhone
          label="휴대폰 번호"
          placeholder="휴대폰 번호를 입력해주세요."
          name="phone"
          register={register('phone', {
            required: { value: true, message: '휴대폰 번호를 입력해주세요.' },
          })}
          disabled={isConfirm}
        />
        <div className="absolute top-8 right-1">
          <HFbutton height={40} onClick={sendSmsHandler} disabled={isConfirm}>
            {timeLeftCheck(timeLeft) ? timeLeftToString(timeLeft) : '인증번호'}
          </HFbutton>
        </div>
      </div>
      <div className="relative">
        <HFinput
          label="인증번호"
          type="text"
          placeholder="인증번호를 입력해주세요."
          value={verifyNumber}
          onChange={(e) => setVerifyNumber(e.target.value)}
          disabled={isConfirm}
        />
        <div className="absolute top-8 right-1">
          <HFbutton
            height={40}
            onClick={confirmSmsHandler}
            disabled={isConfirm}
          >
            {isConfirm ? '인증완료' : '인증확인'}
          </HFbutton>
        </div>
      </div>

      <div className="flex gap-3">
        <HFbutton type="Outlined" onClick={() => navigete('/signin')}>
          취소
        </HFbutton>
        <HFbutton onClick={handleSubmit(onSubmit)}>확인</HFbutton>
      </div>
    </form>
  );
};

const ViewPwModal = ({
  pw,
  linkTo,
}: {
  pw: string;
  linkTo: (link: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(closeModal());
    linkTo('/signin');
  };

  const copyHandler = () => {
    handleCopyClipBoard(pw).then(() => {
      alert('복사되었습니다.');
    });
  };
  return (
    <div className="max-w-[480px] w-full bg-white rounded p-5">
      <TextWinds type="title_h1" className="text-center">
        비밀번호 초기화 완료
      </TextWinds>

      <div className="text-center text-sm  pb-5 mt-5">
        임시 비밀번호는 <span className="font-semibold">{pw}</span> 입니다.
        <br />
        임시 비밀번호로 로그인 후,{' '}
        <span className="font-semibold">[내 정보]</span>에서 비밀번호를 변경하실
        수 있습니다.
        {/* 비밀번호는 <span className="font-semibold">{pw}</span> 입니다. */}
      </div>
      <div className="flex gap-3">
        <HFbutton onClick={copyHandler}>복사하기</HFbutton>
        <HFbutton type="Outlined" onClick={closeHandler}>
          닫기
        </HFbutton>
      </div>
    </div>
  );
};

export default LoginPasswordPhone;
