import React, { useEffect, useState } from 'react';
import TextWinds from '../common/TextWinds';
import SimpleDialog from '../layout/SimpleDialog';
import DeliveryInfoList from './DeliveryInfoList';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { getDeliveryInfoDefault, getDeliveryInfoList, selectDeliveryInfo } from '../../store/features/orderInfoSlice';
import DeliveryInfoCard from './DeliveryInfoCard';
import DeliveryInfoChange from './DeliveryInfoChange';
import { DeliveryProps } from '../../constants/types';

const DeliveryInfo = () => {
    const dispatch = useAppDispatch()
    const deliveryInfo = useAppSelector(selectDeliveryInfo)

    const [state, setState] = useState<'none'|'list'|'form'>('none');    
    useEffect(()=>{
        dispatch(getDeliveryInfoList({url:'',params:{}}))
        dispatch(getDeliveryInfoDefault({url:'',params:{}}))
    },[])

    const openDialog = ()=>{
        setState('list')
    }
    return (
        <div className="mt-4 py-[14px] px-[20px] bg-white rounded-md">
            <TextWinds type="title_h4">배송지 정보</TextWinds>
    
            {/* 선택된 배송지. 없다면 기본배송지 */}
            <DeliveryInfoCard deliveryInfo={deliveryInfo} onClick={openDialog} setState={setState}/>

            <SimpleDialog
                open={state!=='none'}
                title="배송지 정보"
                handleClose={() => setState('none')}
                onConfirm={() => setState('none')}
            >
                {
                    state==='form'
                        ?<DeliveryInfoChange setState={setState}/>
                        :<DeliveryInfoList setState={setState}/>
                }
            </SimpleDialog>
        </div>
    );
};

export default DeliveryInfo;
