import React, { useEffect, useState } from "react";
import TextWinds from "./TextWinds";
import { Button, Input, Select, Option } from "@material-tailwind/react";
import { phoneFormat } from "../../api/utils/format/numbers";

interface InputProps {
  type: "text" | "password" | "text-button" | "phone" | "birthday";
  title?: string;
  value?: string;
  value2?: string;
  className?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onChange2?: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  idDbCheck?: boolean;
  phone?: string;
  buttonText?: string;
  onClick?: () => void;
  maxValue?: number;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
}

export default function Inputs({
  type,
  className,
  placeholder,
  title,
  value,
  value2,
  onChange,
  onChange2,
  onBlur, // onBlur 이벤트 핸들러 추가
  idDbCheck,
  phone,
  buttonText,
  onClick,
  maxValue,
  error,
  errorText,
  disabled,
}: InputProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  const handleInputChange2 = (value: string) => {
    if (onChange2) {
      onChange2(value);
    }
  };

  const [phoneValue, setPhoneValue] = useState<string>("");
  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value.replace(/[^0-9]/g, "");

    setPhoneValue(phone);
    if (onChange) {
      onChange(phone);
    }
  };

  useEffect(() => {
    setPhoneValue(phoneFormat(value) || "");
  }, [value]);

  return (
    <>
      {
        type === "text" || type === "password" && (
          <div>
            <TextWinds type={"title_h5"}>{title}</TextWinds>
            <div className={`relative w-full mt-[8px] ${className}`}>
              <Input
                placeholder={placeholder}
                type={type === "password" ? "password" : "text"}
                className={`!border-gray5 focus:!border-gray5 ${
                  error ? "!border-red5 focus:!border-red5" : ""
                } h-[50px] border border-solid border-gray6 rounded-[4px]`}
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                containerProps={{
                  className: "min-w-full h-[50px]",
                }}
                defaultValue={value}
                onChange={handleInputChange}
                onBlur={onBlur} // onBlur 이벤트 핸들러 적용
              />
            </div>
            {error && errorText && (
              <p className="pt-[8px] text-badge text-red5">{errorText}</p>
            )}
          </div>
        )
      }
      {
        type === "text-button" && (
          <div>
            <TextWinds type="title_h5">{title}</TextWinds>
            <div className={`relative w-full mt-[8px] ${className}`}>
              <Input
                error={error} // 에러 상태에 따라 에러 표시를 활성화
                placeholder={placeholder}
                className={`!border-gray5 focus:!border-gray5 ${
                  error ? "!border-red5 focus:!border-red5" : ""
                } h-[50px] border border-solid border-gray6 rounded-[4px]`}
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                containerProps={{
                  className: "min-w-full h-[50px]",
                }}
                onChange={handleInputChange}
                onBlur={onBlur}
                max={maxValue}
                value={value}
              />
              <Button
                disabled={!idDbCheck} // idDbCheck 상태에 따라 disabled 속성 변경
                className={`!absolute right-[6px] top-[6px] rounded w-[70px] h-[38px] flex p-0 items-center justify-center ${
                  idDbCheck ? "bg-purple5" : "bg-purple3"
                }`}
                onClick={onClick}
                defaultValue={value}
              >
                {buttonText ? buttonText : "중복확인"}
              </Button>
            </div>
            {error && errorText && (
              <p className="pt-[8px] text-badge text-red5">{errorText}</p>
            )}
          </div>
        )
      }
      {
        type === "phone" && (
          <div>
            <TextWinds type="title_h5">{title}</TextWinds>
            <div className={`w-full flex gap-[10px] mt-[8px] ${className}`}>
                        {/* <div className="flex-initial w-1/3">
                            <Select
                                className="w-full min-w-full h-[50px] border border-solid border-gray6 rounded-[4px]"
                                containerProps={{
                                    className: "min-w-full",
                                }}
                                value={value2}
                                disabled={disabled}
                            >
                                <Option>+82</Option>
                            </Select>
                        </div> */}
              <div className="relative flex-initial w-full">
                <Input
                  value={value}
                  type="text"
                  placeholder={placeholder}
                  className={`!border-gray5 focus:!border-gray5 ${
                    error ? "!border-red5 focus:!border-red5" : ""
                  } h-[50px] border border-solid border-gray6 rounded-[4px]`}
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  containerProps={{
                    className: "min-w-full h-[50px]",
                  }}
                  onChange={handleInputChange}
                  onBlur={onBlur} // onBlur 이벤트 핸들러 적용
                  disabled={disabled}
                />
                {!disabled && (
                  <Button
                    disabled={!phone || value !== null}
                    className={`!absolute right-1 top-1 rounded w-[70px] h-[38px] flex p-0 items-center justify-center ${
                      phone ? "bg-purple5" : "bg-purple3"
                    }`}
                  >
                    인증번호
                  </Button>
                )}
              </div>
            </div>
            {error && errorText && (
              <p className="pt-[8px] text-badge text-red5">{errorText}</p>
            )}
          </div>
        )
      }
      {
        type === "birthday" && (
          <div>
            <div>
              <TextWinds type="title_h5">{title}</TextWinds>
              <div className={`w-full flex gap-[10px] mt-[8px] ${className}`}>
                <div className="relative flex-initial w-2/3">
                  <Input
                    type="date"
                    placeholder={placeholder}
                    className={`!border-gray5 focus:!border-gray5 ${
                      error ? "!border-red5 focus:!border-red5" : ""
                    } h-[50px] border border-solid border-gray6 rounded-[4px]`}
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    containerProps={{
                      className: "min-w-full h-[50px]",
                    }}
                    onChange={handleInputChange}
                    onBlur={onBlur} // onBlur 이벤트 핸들러 적용
                  />
                </div>
                <div className="flex-initial w-1/3">
                  <Select
                    className="w-full min-w-full h-[50px] border border-solid border-gray6 rounded-[4px]"
                    containerProps={{
                      className: "min-w-full",
                    }}
                    placeholder="성별"
                    onChange={(val) => handleInputChange2(val)}
                  >
                    <Option value="MALE">남</Option>
                    <Option value="FEMALE">여</Option>
                  </Select>
                </div>
              </div>
            </div>
            {error && errorText && (
              <p className="pt-[8px] text-badge text-red5">{errorText}</p>
            )}
          </div>
        )
      }
        </>
    );
}
