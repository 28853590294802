import React from 'react';
import { neumophismDown } from '../../CourseStyle';
import { Card } from './WmwComp';
import atIcon from '../img/sec02-a-atIcon.png';
import keyIcon from '../img/sec02-b-keyIcon.png';
import section2Img1 from '../img/sec02-c-file.png';
import section2Img2 from '../img/sec02-c-map.png';
import section2Img3 from '../img/sec02-c-tick.png';
import section2Img4 from '../img/sec02-c-pencil.png';

const WmwSection2 = () => {
  return (
    <div className="bg-gradient-to-b from-[#3E70F9] to-[#865BFF] flex justify-center p-3 py-10 md:p-12 md:py-20">
      <div className="flex flex-col gap-10 w-full max-w-[520px] text-white">
        {/* 섹션 1 */}
        <section className="flex text-base md:text-lg pr-2 md:pr-0 md:gap-2 ">
          <img src={atIcon} alt="atIcon" className="w-1/4 m-auto" />
          <div>
            정철영어성경학교의{' '}
            <span className="font-semibold">
              스테디셀러 프로그램 WMW Series
            </span>
            는 50년 영어교육의 대가 정철 선생님이 예수님을 만난 후 새롭게 정립한
            A.D.영어학습법을 토대로{' '}
            <span className="font-semibold">
              아이들이 영어로 성경을 배우며 복음의 핵심을 깨닫게 하는
              젭스영어주일학교의 대표 프로그램
            </span>
            입니다.
          </div>
        </section>

        {/* 섹션 2 */}
        <section className="flex flex-col gap-3">
          <div className="text-center text-xs md:text-base">
            <div className="text-[#ffda18] font-bold">
              젭스영어주일학교의 목표는
            </div>
            <div>
              다음 세대 교회 부흥의 주역인 우리 아이들을 영어로 복음을 외치는
            </div>
          </div>
          <div
            style={neumophismDown}
            className="flex gap-2 items-center bg-white rounded-2xl py-3 px-5 w-fit m-auto"
          >
            <img src={keyIcon} alt="keyIcon" className="w-20 m-auto" />
            <div className="w-fit h-fit text-black text-sm whitespace-nowrap">
              <div className="text-[#0075ff] text-lg font-bold">
                성령 충만! 영어 능통!
              </div>
              한 세계적인 지도자로 길러내는 것입니다.
            </div>
          </div>
          <div className="text-center text-xs md:text-sm font-light">
            *<span className="font-medium"> WMW(후메월)</span>은 Who Made the
            World World의 줄임말 입니다.
          </div>
        </section>

        {/* 섹션 3 */}
        <section className="text-3xl md:text-4xl text-center">
          <div className="text-[#ffda18] font-bold">WMW Series는</div>
          이래서 믿을 수 있어요!
        </section>
        <section>
          <div className="text-center text-2xl">
            <span className="text-[#ffda18] font-bold">“이미 검증된”</span>
            주일학교 프로그램
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-5">
            <Card img={section2Img1}>
              <>
                특허받은
                <br />
                <span className="text-[#5F32C9] font-bold">
                  {' '}
                  삽화기억술
                  <br /> 교수법{' '}
                </span>
                적용
              </>
            </Card>
            <Card img={section2Img2}>
              <>
                <br />
                <span className="text-[#5F32C9] font-bold">
                  전국 7천여
                  <br /> 교회
                </span>
                의 선택
              </>
            </Card>
            <Card img={section2Img3}>
              <>
                <br />
                <span className="text-[#5F32C9] font-bold">
                  1만여 명의
                  <br /> 교사{' '}
                </span>
                배출료
              </>
            </Card>
            <Card img={section2Img4}>
              <>
                <br />
                <span className="text-[#5F32C9] font-bold">
                  10만여 명
                  <br /> 학생
                </span>
                의 수료
              </>
            </Card>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WmwSection2;
