import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  getDeliveryInfoList,
  selectDeliveryList,
  setDeliveryInfo,
  setEditDeliveryInfo,
} from '../../store/features/orderInfoSlice';
import DeliveryInfoCard from './DeliveryInfoCard';
import Buttons from '../common/Buttons';
import { DeliveryProps } from '../../constants/types';
interface DeliveryInfoListProps {
  setState: Dispatch<SetStateAction<'form' | 'none' | 'list'>>;
}
const DeliveryInfoList = ({ setState }: DeliveryInfoListProps) => {
  const dispatch = useAppDispatch();
  const deliveryList = useAppSelector(selectDeliveryList);

  useEffect(() => {
    dispatch(getDeliveryInfoList({ url: 'v1/shipping', params: {} }));
  }, []);

  const selectItem = (deliveryInfo: DeliveryProps) => {
    dispatch(setDeliveryInfo(deliveryInfo));
    setState('none');
  };

  const addDeliveryInfo = () => {
    dispatch(setEditDeliveryInfo(undefined));
    setState('form');
  };

  return (
    <div>
      <div className="max-h-[70vh] overflow-auto mt-[-16px]">
        {deliveryList.length > 0 ? (
          deliveryList.map((delivery, index) => (
            <DeliveryInfoCard
              key={index}
              deliveryInfo={delivery}
              onClick={() => selectItem(delivery)}
              editable={true}
              setState={setState}
            />
          ))
        ) : (
          <DeliveryInfoCard
            deliveryInfo={undefined}
            onClick={() => setState('form')}
            editable={true}
            setState={setState}
          />
        )}
      </div>
      <Buttons
        type={'filled'}
        color={'purple5'}
        onClick={() => addDeliveryInfo()}
        className="mt-4"
      >
        <span>배송지 등록</span>
      </Buttons>
    </div>
  );
};
export default DeliveryInfoList;
