import React, { Fragment, useState } from "react";
import { Textarea } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import Buttons from "../../../components/common/Buttons2";
import TextWinds from "../../../components/common/TextWinds";
import MoLayout from "../../../components/lms/MoLayout";
import PcLayout from "../../../components/lms/PcLayout";

const NoticeEditor = () => {
    const params = useLocation().state;
    const [isCheckMode, setIsCheckMode] = useState(params.mode);

    return (
        <Fragment>
            <MoLayout title="게시물 작성" isButtonVisible={true} btnTitle="저장하기" />
            <PcLayout
                secClassName="pt-4 px-5"
                topClassName="hidden xl:flex justify-between items-center"
                top={
                    <>
                        <TextWinds type="title_h3">게시물 작성</TextWinds>
                        <div>
                            <Buttons variant="filled" color="purple5" type="secondary">
                                <TextWinds type="button2" color="white">
                                    저장하기
                                </TextWinds>
                            </Buttons>
                        </div>
                    </>
                }
                bottom={<Textarea rows={5} defaultValue={params.data.title} />}
            />
        </Fragment>
    );
};

export default NoticeEditor;
