import { Outlet } from 'react-router-dom'
import MegaMenu from './common/layouts/megamenu'

const OnlyBodyLayout = () => {
    return (
        <>
            <main>
                <Outlet />
            </main>
        </>
    )
}

export default OnlyBodyLayout