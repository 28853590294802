import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { AxiosParamType } from '.';
import instance from '../../api/axios/utils.ts/instance';
import { RootState } from '..';
import {
  orderDetailType,
  orderList,
  pointList,
  pointType,
} from '../../constants/types/mypage-type';
import { User } from '../../constants/types';

const initialState: {
  status: string;
  myInfo: User;
  orderList: orderList;
  orderDetail: orderDetailType;
  pointList: pointList;
} = {
  status: '',
  myInfo: undefined,
  orderList: undefined,
  orderDetail: undefined,
  pointList: undefined,
};
export const mySlice = createSlice({
  name: 'my',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyAuth.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getMyAuth.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.myInfo = action.payload;
      })
      .addCase(getMyAuth.rejected, (state) => {
        state.status = 'rejected';
      })

      .addCase(getMyOrders.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getMyOrders.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.orderList = action.payload;
      })
      .addCase(getMyOrders.rejected, (state) => {
        state.status = 'rejected';
      })

      .addCase(getMyOrderDetail.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getMyOrderDetail.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.orderDetail = action.payload;
      })
      .addCase(getMyOrderDetail.rejected, (state) => {
        state.status = 'rejected';
      })

      .addCase(getMyPoint.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getMyPoint.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.pointList = action.payload;
      })
      .addCase(getMyPoint.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getMyOrders = createAsyncThunk<orderList, AxiosParamType>(
  'mypage/getMyOrders',
  async ({ url, params }) => {
    try {
      const url = '/v1/mypage/orders';
      const response = await instance.get(url, { params });
      return response.data.data;
    } catch (error) {
      console.error('[axios] getOrdererInfo : ', error);
      return isRejectedWithValue(error);
    }
  }
);

export const getMyAuth = createAsyncThunk<User, AxiosParamType>(
  'mypage/getMyAuth',
  async () => {
    try {
      const url = '/v1/auth/user-authorization';
      const response = await instance.get(url);
      if (response.data.statusCode === 200) {
        return response.data.data;
      }
      return undefined;
    } catch (error) {
      console.error('[axios] getMyAuth : ', error);
      return isRejectedWithValue(error);
    }
  }
);

export const getMyOrderDetail = createAsyncThunk<
  orderDetailType,
  AxiosParamType
>('mypage/getMyOrderDetail', async ({ url, params }) => {
  try {
    const url = '/v1/mypage/orders/' + params;
    const response = await instance.get(url);
    return response.data.data;
  } catch (error) {
    console.error('[axios] getMyOrderDetail : ', error);
    return isRejectedWithValue(error);
  }
});

export const getMyPoint = createAsyncThunk<pointList, AxiosParamType>(
  'mypage/getMyPoint',
  async ({ url, params }) => {
    try {
      const url = '/v1/mypage/points';
      const response = await instance.get(url, { params });
      return response.data.data;
    } catch (error) {
      console.error('[axios] getMyPoint : ', error);
      return isRejectedWithValue(error);
    }
  }
);

export const {} = mySlice.actions;

export const selectMyInfo = (state: RootState) =>
  state.my.status !== 'pending' ? state.my.myInfo : undefined;

export const selectMyOrders = (state: RootState) =>
  state.my.status !== 'pending' ? state.my.orderList : undefined;

export const selectMyOrderDetail = (state: RootState) =>
  state.my.status !== 'pending' ? state.my.orderDetail : undefined;

export const selectMyPoints = (state: RootState) =>
  state.my.status !== 'pending' ? state.my.pointList : undefined;

export default mySlice.reducer;
