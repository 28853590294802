import React from 'react';

interface LmsContainerProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}
export const LmsContainer = ({ children, header: head }: LmsContainerProps) => {
  return (
    <div>
      {head && <div className="p-5 pb-0">{head}</div>}
      <div className="flex flex-col gap-x-1 bg-white rounded-lg shadow-none md:shadow-md p-5 m-0 md:m-5 ">
        {children}
      </div>
    </div>
  );
};
