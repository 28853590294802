import { useState, useEffect, useCallback } from 'react';

interface TimeLeft {
  minutes: number;
  seconds: number;
}

function useTimer() {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    minutes: 0,
    seconds: 0,
  });
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [onEndCallback, setOnEndCallback] = useState<() => void>(
    () => () => {}
  );

  // 타이머 시작
  const start = useCallback((endTime: Date, onEnd: () => void) => {
    setEndTime(endTime);
    setOnEndCallback(() => onEnd);
  }, []);

  // 타이머 종료
  const stop = () => {
    setEndTime(null);
    setTimeLeft({ minutes: 0, seconds: 0 });
  };

  // 타이머 진행
  useEffect(() => {
    if (!endTime) return;

    const tick = () => {
      const now = new Date();
      const remainingTime = endTime.getTime() - now.getTime();

      if (remainingTime <= 0) {
        setTimeLeft({ minutes: 0, seconds: 0 });
        onEndCallback();
        setEndTime(null);
      } else {
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        setTimeLeft({ minutes, seconds });
      }
    };

    const intervalId = setInterval(tick, 1000);

    // Initialize the timer immediately
    tick();

    return () => clearInterval(intervalId);
  }, [endTime, onEndCallback]);

  return { timeLeft, start, stop };
}
export default useTimer;

/**
 * 남은 시간을 문자열로 변환
 * @param timeLeft
 * @returns
 */
export const timeLeftToString = (timeLeft: TimeLeft) => {
  return `${timeLeft.minutes.toString().padStart(2, '0')}:${timeLeft.seconds
    .toString()
    .padStart(2, '0')}`;
};

/**
 * 남은 시간이 있는지 확인
 * @param timeLeft
 * @returns
 */
export const timeLeftCheck = (timeLeft: TimeLeft) => {
  return timeLeft.minutes + timeLeft.seconds > 0;
};
