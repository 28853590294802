import React from 'react';
import { useAppDispatch } from '../../../store/hook';
import { closeModal } from '../../../store/features/modalSlice';
import HFbutton from '../../../components/common/input/HFbutton';
import HFinput from '../../../components/common/input/HFinput';
import { useForm } from 'react-hook-form';
import TextWinds from '../../../components/common/TextWinds';
import { LmsMigrationStudent } from '../../../constants/types/lms-type';
import instance from '../../../api/axios/utils.ts/instance';
import ErrorInput from '../../student/components/ErrorInput';
import { AxiosError, isAxiosError } from 'axios';

interface LmsMigrationModifyModalProps {
  student: LmsMigrationStudent;
  classId: string;
  onCompleted: (flag: boolean) => void;
}
const LmsMigrationModifyModal = ({
  student,
  classId,
  onCompleted,
}: LmsMigrationModifyModalProps) => {
  type FormValues = {
    id: string;
  };
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const putLegacyStudent = async (id: number, newUid: string) => {
    try {
      const res = await instance.put(
        `/v1/lms/${classId}/migration/legacy-student/${id}`,
        {
          newUid,
        }
      );
      if (200 === res.status) {
        return true;
      } else {
        console.error('res:', res.data);
        return false;
      }
    } catch (error) {
      //axioserror라면
      if (isAxiosError(error)) {
        setError(error.response?.data.error);
      } else {
        console.error(error);
      }
      return false;
    }
  };

  const submitHandler = (data: FormValues) => {
    putLegacyStudent(student.id, data.id).then((flag) => {
      onCompleted(flag);
    });
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };

  return (
    <form className="bg-white rounded-lg p-5 w-full max-w-[480px]">
      <TextWinds type="title_h2">아이디 변경</TextWinds>
      <TextWinds type="content_body3" className="mt-2">
        중복된 계정이 확인되기 때문에 아이디를 변경해 주셔야 합니다.
      </TextWinds>
      <div className="relative mt-5">
        <HFinput
          name="id"
          defaultValue={student.u_id}
          register={register('id', {
            required: { value: true, message: '변경할 아이디를 입력해주세요' },
          })}
          error={errors.id?.message}
        />
        {error && (
          <div className="text-red5 w-full text-center pb-3">{error}</div>
        )}
      </div>
      <div className="flex gap-3">
        <HFbutton type="Outlined" onClick={closeHandler}>
          닫기
        </HFbutton>
        <HFbutton onClick={handleSubmit(submitHandler)}>저장</HFbutton>
      </div>
    </form>
  );
};

export default LmsMigrationModifyModal;
