import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoginFormValue, login } from '../../../api/storage/localStorage';

const OauthSuccess = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginValue, setLoginValue] = useState<LoginFormValue>();
  const navigate = useNavigate();

  useEffect(() => {
    const uid = searchParams.get('uid');
    const accessToken = searchParams.get('accessToken');
    const type = searchParams.get('type');
    const isActive = searchParams.get('isActive');
    if (searchParams) {
      setLoginValue({
        userId: uid,
        accessToken: accessToken,
        userInfo: { type: type, isActive: isActive === 'true' },
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (loginValue) {
      login(loginValue);
      navigate('/');
    }
  }, [loginValue]);

  return <div>로딩중...</div>;
};

export default OauthSuccess;
