import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  CircleIconBoxWithTitle,
  DiscountedPrice,
  CircleIcon,
  CircleCheckIcon,
} from '../wmw/WmwComp';
import monitor from '../img/circle-monitor.png';
import shield from '../img/circle-shield.png';
import notebook from '../img/circle-notebook.png';

const HFHJSection10 = () => {
  return (
    <>
      <section className="bg-[#F9F9F9] p-3 py-10 md:pt-20">
        <div className="max-w-[520px] w-full m-auto flex flex-col justify-center items-center gap-5 md:gap-10">
          <div className="text-3xl md:text-4xl font-extrabold text-center text-[#FE7020] font-['Pretendard'] mb-5 ">
            프로그램 구성&가격
          </div>

          <div
            className="flex flex-col justify-center items-center gap-5 md:gap-10
                bg-white w-full p-5 rounded-lg relative"
            style={{
              boxShadow: '0px 3px 7px 1px rgba(150,150,150,0.3 )',
            }}
          >
            <div
              className=" bg-white border-2 border-[#FE7020] rounded-xl px-5 py-2 md:px-7 md:py-3 w-fit
                text-[#FE7020] text-xl md:text-2xl font-extrabold shadow
                  absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              교사 메인 코스
            </div>

            <div
              className=" bg-white border border-[#FE7020] rounded-full px-4 py-1 md:px-6 md:py-2 w-fit mt-5 md:mt-10 
                  text-base md:text-xl text-[#FE7020]"
            >
              <span className="font-bold">교사 코스</span> (메인-2년)
            </div>
            <div className="flex w-full justify-center items-center gap-5 md:gap-7">
              {/* <div className="grid grid-cols-3 w-full place-items-center gap-5 md:gap-7"> */}
              <CircleIconBoxWithTitle
                icon={monitor}
                circleColor="bg-[#FFDA19]"
                circleSize="h-20 w-20 md:h-28 md:w-28"
                title={
                  <div className="text-sm md:text-xl whitespace-nowrap">
                    온라인 교육과정
                  </div>
                }
                delay={0.1}
              />
              <CircleIconBoxWithTitle
                icon={shield}
                circleColor="bg-[#FFDA19]"
                circleSize="h-20 w-20 md:h-28 md:w-28"
                title={
                  <div className="text-sm md:text-xl whitespace-nowrap">
                    온라인 자료{' '}
                    <span className="text-[#FE7020] font-bold">2년</span>
                  </div>
                }
                delay={0.3}
              />
              <CircleIconBoxWithTitle
                icon={notebook}
                circleColor="bg-[#FFDA19]"
                circleSize="h-20 w-20 md:h-28 md:w-28"
                title={
                  <div className="text-sm md:text-xl whitespace-nowrap">
                    교재 <span className="text-[#FE7020] font-bold">2년치</span>
                  </div>
                }
                delay={0.5}
              />
            </div>

            <DiscountedPrice
              price="220,000"
              discountedPrice="189,000"
              discountColor="text-[#FE7020]"
            />
          </div>

          <CircleIcon color="#FE7020" text="+" />
          <div
            className="  bg-white border-2 border-[#FE7020] rounded-2xl px-5 py-2 md:px-8 md:py-3 w-fit m-auto
                shadow-md
              text-[#FE7020] text-2xl md:text-3xl font-extrabold"
          >
            학생용 교재 구성
          </div>
          <Accordion title="[교재] 구성" color="#FE7020">
            <BookDetail />
          </Accordion>
        </div>
      </section>
    </>
  );
};

const Accordion = ({
  color,
  title,
  children,
}: {
  color: string;
  title: string;
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className="rounded-2xl cursor-pointer p-3"
      onClick={() => setIsOpen((v) => !v)}
      style={{ backgroundColor: color }}
    >
      <div className="flex items-center w-full text-[#FFDA19]">
        <ChevronDownIcon
          className={`w-6 transform 
                transition-transform duration-300 ease-in-out
                ${isOpen ? 'rotate-180' : 'rotate-0'}`}
        />
        {/* <ChevronDownIcon className="w-6 flex-shrink-0" /> */}
        {!isOpen && (
          <div className="flex-1 justify-center items-center w-full font-bold text-center md:text-lg">
            {title}
          </div>
        )}
      </div>
      <motion.div
        className="overflow-hidden"
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

const BookDetail = () => {
  return (
    <div className="flex flex-col items-center gap-5 px-5 py-10 m-auto cursor-auto">
      <div
        className="border-[#FFDA19] rounded-full border-[1px] text-[#FFDA19] px-5 py-1 
        text-lg md:text-xl font-bold"
      >
        Hello, Father! Hello, Jesus!
      </div>
      <div className="text-xl md:text-2xl text-white font-extrabold">
        커리큘럼 구성
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex gap-2 w-full text-white font-bold text-base md:text-lg">
          <CircleCheckIcon width="22" />
          본교재 2권(Part 1,2)
        </div>
        <div className="flex gap-2 w-full text-white font-bold text-base md:text-lg ">
          <CircleCheckIcon width="22" /> 워크북 1권
        </div>
        <div className="flex gap-2 w-full text-white font-bold text-base md:text-lg ">
          <CircleCheckIcon width="22" /> QR 스티커(찬양 및 워크북)
        </div>
        <div className="flex gap-2 w-full text-white font-bold text-base md:text-lg ">
          <CircleCheckIcon width="22" /> 스토리북 1권
        </div>
        <div className="flex gap-2 w-full text-white font-bold text-base md:text-lg ">
          <CircleCheckIcon width="22" /> 젭스플 제공
        </div>
      </div>
      <div className="w-full sm:w-[80%] bg-white text-black text-center py-3 rounded-lg font-bold text-xl md:text-2xl">
        <div className="text-sm">HF/HJ 각각</div>
        판매가 75,000원 / 1년
      </div>
      <div className="md:px-10 text-sm text-white">
        * 학생용 교재 20세트 이상 구매 시 10% 교재 할인이 적용됩니다.
        <br />* 최초 교사 수료 후 수료 후기 작성 시 학생용 교재 5세트 이상 구매
        시 20% 할인 혜택이 적용됩니다.
      </div>
    </div>
  );
};

export default HFHJSection10;
