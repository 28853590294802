import React from "react";
import Shape from "./images/shape-common-purple.png";
import ImageB from "./images/review1-b.png";
import ImageC from "./images/review1-c.png";
import ImageD from "./images/review1-d.png";
import ImageE from "./images/review1-e.png";
import Shape1 from "./images/shape1-purple.png";
import Shape2 from "./images/shape2-purple.png";
import Shape3 from "./images/shape3-purple.png";
import { Link } from "react-router-dom";

export const Review1 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-14 py-16 bg-white max-md:px-5 relative overflow-hidden">
      <img src={Shape1} width={600} className="-left-52 top-[600px] hidden md:visible absolute" />
      <img src={Shape2} width={600} className="-right-52 top-[2000px] hidden md:visible absolute" />
      <img src={Shape3} width={600} className="-left-52 top-[2600px] hidden md:visible absolute" />
      <img loading="lazy" src={Shape} className="aspect-square w-[88px]" />
      <div className="mt-5 text-3xl font-bold leading-9 text-center text-black max-md:max-w-full relative">
        하나님과 동행하는 지도자 되기 프로젝트는 계속 될 것입니다.{" "}
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-20 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10">
        우연히 듣게 된 젭스영어주일학교 설명회는
        <br />
        <span className="text-[#6032DB]">
          저에게 설레고 기대하는 마음을 심어 주었습니다.
        </span>
        <br />
        <br />그 마음으로 시작한 젭스영어주일학교
        <br />총 15명으로 시작해 어느새 저희 교회 <br />첫 번째 Speech day를
        맞이하게 되었습니다.
        <br />
        <br />
        <span className="text-xs leading-5">
          *Speech day : 학생들이 공부한 것을 실제 스피치하며
        </span>
        <br />
        <span className="text-xs leading-5">
          학생들의 성취감을 향상 시키는 축제의 날
        </span>
        <br />
        <br />
        <br />
        아이들은 그동안 배우고 외운
        <br />
        <span className="text-[#6032DB]">
          영어 말씀을 자신 있게 암송하였습니다.
        </span>
        <br />
        그리고 아이들의 모습을 본 많은 성도분들은
        <br />
        감동을 받았다며 은혜로운 말씀을 전해주시기도 했습니다.
        <br />
        모두가 한 마음으로 은혜 받는 얼굴을 생각하면
        <br />
        저는 지금도 가슴이 설레고 울컥합니다.
      </div>
      <div className="flex flex-col justify-center px-7 py-9 mt-16 max-w-full bg-black rounded-xl max-md:px-5 max-md:mt-10 relative">
        <img
          loading="lazy"
          src={ImageB}
          className="w-full aspect-[1.92] max-md:max-w-full  lg:w-[647px] "
        />
      </div>
      <div className="mt-16 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10">
        <span className="font-medium tracking-normal leading-6 text-black">
          어느새 젭스영어주일학교는 저의 사명이 되었습니다.
        </span>
        <br />
        <br />
        이제 더 나아가
        <br />
        <span className="text-[#6032DB]">
          영어복음선교사로 하나님 나라를 위해
        </span>
        <br />
        <span className="text-[#6032DB]">
          평생을 사역하고 싶은 마음이 듭니다.{" "}
        </span>
        <br />
        <br />
        <span className="text-[#6032DB]">젭스영어주일학교는</span>
        <br />
        <span className="text-[#6032DB]">
          하나님과 동행하는 세계적인 지도자 되기 프로젝트입니다.
        </span>
        <br />
        <br />
        다음세대를 향한 이 귀하고 귀한 프로젝트는
        <br />
        앞으로도 쭉 계속 될 것입니다.
      </div>
      <img
        loading="lazy"
        src={ImageC}
        className="mt-16 max-w-full aspect-[1.64] w-[593px] max-md:mt-10"
      />
      <img
        loading="lazy"
        src={ImageD}
        className="mt-10 max-w-full aspect-[1.28] w-[593px] relative"
      />
      <img
        loading="lazy"
        src={ImageE}
        className="mt-12 max-w-full aspect-[0.83] w-[485px] max-md:mt-10 relative"
      />
      <div className="mt-24 text-3xl font-bold leading-9 text-center text-slate-900 max-md:mt-10 max-md:max-w-full">
        - 대전디딤돌교회 이** 선생님의 후기 -
      </div>
      <div className="mt-56 text-lg tracking-normal leading-7 text-center text-[#6032DB] max-md:mt-10 max-md:max-w-full">
        '아이들에게 어떻게 성경을 가르쳐야 하나요?'
        <br />
        '어떻게 하면 아이들을 믿음의 사람으로 기를 수 있을까요?'
        <br />​<br />
        많은 주일학교 교사들이 하는 고민입니다.
        <br />
        아이들에게 복음을 전하겠다는 작은 사명으로 시작했지만
        <br />
        정말 아이들에게 필요한 게 무엇인지 길을 헤맬 때가 있습니다.
        <br />​<br />
        <span className="text-[#6032DB]">
          그래서 교회에는 확실한 사명이 필요합니다.
        </span>
        <br />
      </div>
      <div className="justify-center px-9 py-7 mt-14 max-w-full text-lg font-medium tracking-normal leading-6 text-center text-black bg-[#EFE4FA] w-[338px] max-md:px-5 max-md:mt-10">
        “영어복음선교사로 하나님 나라를 위해
        <br />
        평생 사역하고 싶은 마음이 듭니다.”
      </div>
      <div className="mt-16 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10">
        한 영혼이 말씀을 통해 하나님과 동행하게 되는
        <br />
        모습을 볼 때 가슴이 설레고 울컥합니다.
        <br />​<br />
        <span className="text-[#6032DB]">
          영어로 복음을 전하는 사명을 감당함이
        </span>
        <br />
        <span className="text-[#6032DB]">우리의 기쁨이요, 자랑입니다✨</span>
        <br />
        <span className="text-[#6032DB]">​</span>
        <br />
        주일학교의 부흥과
        <br />
        다음 세대 전도에 사명이 있다면,
        <br />
        주저말고 지금 바로
        <br />
        젭스영어주일학교와 동행해 보세요!
      </div>
      <Link to="/courses/churchschool">
        <div className="justify-center items-center px-2.5 py-4 mt-24 max-w-full text-base font-bold tracking-normal leading-6 text-center text-[#6032DB] bg-white rounded border border-[#6032DB] border-solid w-[376px] max-md:px-5 max-md:mt-10">
          주일학교 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
