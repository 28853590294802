import React, { useEffect } from 'react';
import LmsMigrationState from './LmsMigrationState';
import LmsMigrationList from './LmsMigrationList';
import TextWinds from '../../../components/common/TextWinds';
import { LmsMigrationStatus } from '../../../constants/types/lms-type';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Buttons from '../../../components/common/Buttons';
import StudentMigrationModal from '../studentmgmt/StudentMigrationModal';
import { openModal } from '../../../store/features/modalSlice';
import { useAppDispatch } from '../../../store/hook';
import MigrationGuideModal from './MigrationGuideModal';

const LmsMigrationPage = () => {
  const [params, setParams] = useSearchParams({
    status: LmsMigrationStatus.SUCCESS,
  });
  const status = params.get('status') as LmsMigrationStatus;

  const { classId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setState = (status: LmsMigrationStatus) => {
    params.set('status', status);
    setParams(params);
  };

  // --- 안내 모달 ---
  // TODO: api 연결로 바꿔야함
  const hasIntegrated: boolean = false;

  useEffect(() => {
    let migrationGuideModalShown = localStorage.getItem(
      'migrationGuideModalShown'
    );
    if (migrationGuideModalShown === null) {
      migrationGuideModalShown = 'true';
      localStorage.setItem('migrationGuideModalShown', 'true');
    }

    // 1st. 다시보지않기 체크
    if (migrationGuideModalShown !== 'false') {
      // 2nd. 연동 여부 체크
      if (!hasIntegrated) {
        openGuideModal();
      }
    }
  }, [hasIntegrated]);

  const openGuideModal = () => {
    dispatch(
      openModal({
        body: <MigrationGuideModal />,
      })
    );
  };

  const openMigrationModal = () => {
    dispatch(
      openModal({
        body: (
          <StudentMigrationModal
            classId={classId}
            onComplete={() => navigate('../migration')}
          />
        ),
      })
    );
  };

  return (
    <div className="p-0 lg:px-10 lg:py-8">
      <div className="flex flex-col gap-4 p-5 lg:p-8 bg-white rounded-lg shadow-card">
        <section className="flex flex-row justify-between items-center md:items-left ">
          <TextWinds type="title_h3">구버전 학생 가져오기</TextWinds>
          <div>
            <Buttons
              type="filled"
              color="red5"
              isHeight="titlebutton"
              onClick={openMigrationModal}
              className="h-10"
            >
              구 버전 연동하기
            </Buttons>
          </div>
        </section>
        {/* <LmsMigrationLoading /> */}
        <LmsMigrationState value={status} onChange={setState} />
        <LmsMigrationList status={status} />
      </div>
    </div>
  );
};

export default LmsMigrationPage;
