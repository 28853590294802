import { Button, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const RecitationStudentSearch = () => {
  const [params, setParams] = useSearchParams();
  const [searchOption, setSearchOption] = React.useState<string>('name');
  const [searchValue, setSearchValue] = React.useState<string>();

  useEffect(() => {
    if (searchValue === '') {
      params.delete('searchOption');
      params.delete('searchValue');
      setParams(params);
    }
  }, [searchValue]);

  const searchHandler = () => {
    params.set('searchOption', searchOption);
    params.set('searchValue', searchValue);
    setParams(params);
  };

  return (
    <div className="flex gap-3 mb-5">
      <Select
        className="w-[100px]"
        defaultValue={'이름'}
        onChange={(v) => setSearchOption(v)}
      >
        <Select.Option value="name">이름</Select.Option>
        <Select.Option value="uid">아이디</Select.Option>
      </Select>
      <Input
        type="search"
        className="w-[200px] b-5"
        placeholder="검색어를 입력하세요"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onPressEnter={searchHandler}
        allowClear
      />
      <Button onClick={searchHandler}>검색</Button>
    </div>
  );
};

export default RecitationStudentSearch;
