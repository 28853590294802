import React from 'react';
import HFbutton from './HFbutton';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface HFLinkProps {
  to: string;
  body: React.ReactNode;
  getParams?: { key: string; value: string }[];
  height?: number;
}
const HFLink = ({ to, body, getParams, height }: HFLinkProps) => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <HFbutton
      height={height}
      onClick={() => {
        getParams.forEach((item) => {
          searchParams.set(item.key, item.value);
        });
        setSearchParams(searchParams);
      }}
    >
      {body}
    </HFbutton>
  );
};

export default HFLink;
