import { Input, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import HFbutton from '../../common/input/HFbutton';
import { useSearchParams } from 'react-router-dom';

export type TodoStatusSearchObjType = {
  searchOption: string;
  searchValue: string;
  orderBy: string;
};
interface TodoStatusSearchProps {
  category: string;
  subType: string;
  tab: string;
}
const TodoStatusSearch = ({
  category,
  subType,
  tab,
}: TodoStatusSearchProps) => {
  const [params, setParams] = useSearchParams();

  const { searchOption, searchValue } = useMemo(() => {
    const searchOption = params.get('searchOption');
    const searchValue = params.get('searchValue');
    return { searchOption, searchValue };
  }, [params]);

  const searchOptions = useMemo((): {
    label: string;
    value: string;
  }[] => {
    if (category === 'BY_TODO') {
      switch (subType.toUpperCase()) {
        case 'RECITATION':
          return [
            { label: '학생명', value: 'name' },
            { label: '학생 ID', value: 'uid' },
            { label: '레벨', value: 'book' },
            { label: '북', value: 'title' },
          ];
        case 'JEBSON':
          return [
            { label: '학생명', value: 'name' },
            { label: '학생 ID', value: 'uid' },
            { label: '북/챕터', value: 'book' },
            { label: '레슨', value: 'lesson' },
            { label: '제목', value: 'title' },
          ];
        case 'JEBSPL':
          return [
            { label: '학생명', value: 'name' },
            { label: '학생 ID', value: 'uid' },
            { label: '북/챕터', value: 'book' },
            { label: '제목', value: 'title' },
          ];

        default:
          return [];
      }
    } else {
      return [
        { label: '학생명', value: 'name' },
        { label: '학생 ID', value: 'uid' },
      ];
    }
  }, [category, subType]);

  const [searchObj, setSearchObj] = React.useState<TodoStatusSearchObjType>({
    searchOption: searchOption || searchOptions[0].value,
    searchValue: searchValue || undefined,
    orderBy: 'ASC',
  });

  useEffect(() => {
    params.delete('searchOption');
    params.delete('searchValue');
    setSearchObj({
      searchOption: searchOptions[0].value,
      searchValue: undefined,
      orderBy: 'ASC',
    });
    setParams(params);
  }, [tab, category]);

  const submithandler = (searchObj: TodoStatusSearchObjType) => {
    params.set('searchOption', searchObj.searchOption);
    params.set('searchValue', searchObj.searchValue);
    setParams(params);
  };

  return (
    <div className="flex flex-col gap-5 py-3 px-5 md:flex-row md:px-0">
      <div className="flex gap-2 w-full max-w-[600px] h-8">
        <Select
          placeholder="검색조건"
          value={searchObj.searchOption}
          onChange={(value) =>
            setSearchObj({ ...searchObj, searchOption: value as string })
          }
          className="w-[120px]"
        >
          {searchOptions.map((item) => (
            <Select.Option value={item.value} key={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
        <Input
          value={searchObj.searchValue}
          onChange={(e) =>
            setSearchObj({ ...searchObj, searchValue: e.target.value })
          }
          placeholder="검색어를 입력하세요"
          onPressEnter={() => submithandler(searchObj)}
        />
        <div className="w-[140px]">
          <HFbutton height={34} onClick={() => submithandler(searchObj)}>
            SEARCH
          </HFbutton>
        </div>
      </div>

      {/* <Select
        placeholder="정렬순서"
        onChange={(value) => {
          const search = { ...searchObj, orderBy: value as string };
          onChange(search);
          onSubmit(search);
        }}
        value={searchObj?.orderBy}
      >
        <Select.Option value="ASC">오름차순</Select.Option>
        <Select.Option value="DESC">내림차순</Select.Option>
      </Select> */}
    </div>
  );
};

export default TodoStatusSearch;
