import { Input } from "@material-tailwind/react";

interface ComInputProps {
    type: "text" | "number" | "password";
    placeholder?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    maxLength?: number;
}

export default function ComInput({ type, placeholder, value, onChange, className, maxLength }: ComInputProps) {
    return (
        <div className={`inline-flex items-center w-full border rounded border-gray-500 ${className}`}>
            <Input
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e)}
                maxLength={maxLength}
                className="px-[10px] py-[14px] !border-0 bg-white !text-black shadow-none placeholder:text-gray-600 placeholder:opacity-100 focus:!border-purple-500 focus:!border-t-purple-500"
                labelProps={{
                    className: "hidden",
                }}
                containerProps={{
                    className: "h-[50px]",
                }}
            />
        </div>
    );
}
