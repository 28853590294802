import React from 'react';
import { LmsContainer } from '../../../components/layout/LmsLayouts';
import { Link } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import RecitationStudentList from '../../../components/lms/recitation/RecitationStudentList';

const RecitationHistory = () => {
  return (
    <LmsContainer header={<TextWinds type="title_h3">인증제 내역</TextWinds>}>
      <RecitationStudentList />
    </LmsContainer>
  );
};

export default RecitationHistory;
