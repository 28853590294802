import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CommonResProps, ShopInfoProps } from "../../constants/types";
import TextWinds from "../common/TextWinds";
import { numberWithCommas } from "../../api/utils/format/numbers";

interface ShopsProps {
    activeTab: string;
    label: string;
    datas: CommonResProps<ShopInfoProps[]>;
}
const Shops = ({ activeTab, label, datas }: ShopsProps) => {
    return (
        <Fragment>
            {datas.result &&
                datas.result.map((props: ShopInfoProps, key: React.Key) => (
                    <Link to={`details/${props.id}`} key={key}
                    className="flex flex-col items-center justify-between w-full bg-white rounded-xl overflow-hidden shadow-card border box border-white hover:border-purple2">
                        <div className="m-0 rounded-none xl:bloock">
                            <img
                                width={768}
                                height={768}
                                src={process.env.PUBLIC_URL + props.thumbnail_url}
                                alt={props.name}
                                className="object-cover w-full h-full"
                            />
                        </div>
                        <div className="p-3 border-t border-gray2 w-full">
                            <div className="flex h-12">
                                <TextWinds type="content_body2" className="line-clamp-2">
                                    {props.name}
                                </TextWinds>                                
                            </div>
                            <div className="mt-0 text-end">
                                <TextWinds type="content_body3" className="font-semibold">
                                    {numberWithCommas(props.current_price)} 원
                                </TextWinds>
                                <TextWinds type="content_body5" className="font-normal line-through">
                                    {numberWithCommas(props.origin_price)} 원
                                </TextWinds>
                            </div>                            
                        </div>
                    </Link>
                ))}
        </Fragment>
    );
};

export default Shops;
