import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import TextWinds from '../common/TextWinds';
import { Button, Input, Option, Select } from '@material-tailwind/react';
import instance from '../../api/axios/utils.ts/instance';
import {
  churchDataType,
  denominationType,
} from '../../constants/types/mypage-type';
import { ApiResponse, PaginationResponse } from '../../constants/types';
import { phoneFormat } from '../../api/utils/format/numbers';
import { CITY_DATA } from '../../data/statics-datas';

type searchDataType = {
  city?: string;
  denominationId?: string;
  churchName?: string;
  pastorName?: string;
};
interface ModalChurchProps {
  submitHandler: (data: churchDataType) => void;
}
const ModalChurch = ({ submitHandler }: ModalChurchProps) => {
  const dispatch = useAppDispatch();
  const [denominationList, setDenominationList] = React.useState<
    denominationType[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver>();
  const [churchList, setChurchList] = React.useState<churchDataType[]>([]);
  const [searchData, setSearchData] = React.useState<searchDataType>({});
  const [debouncedSearchData, setDebouncedSearchData] =
    useState<searchDataType>({});

  const loadMoreItems = useCallback(
    async (page: number) => {
      const newItems = await getChurch(page, debouncedSearchData);
      setChurchList((prevChurchList) => [...prevChurchList, ...newItems]);
      setPage((prevPage) => prevPage + 1);

      if (newItems.length === 0) {
        setHasMore(false);
      }
    },
    [debouncedSearchData]
  );

  useEffect(() => {
    getDenomination();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchData(searchData);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchData]);

  useEffect(() => {
    if (Object.keys(debouncedSearchData).length > 0) {
      setPage(1);
      setChurchList([]);
      loadMoreItems(1);
    }
  }, [debouncedSearchData]);

  useEffect(() => {
    loadMoreItems(1); // Initial load
  }, []); // Empty dependency array ensures this runs only once on mount

  const lastItemRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreItems(page);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, page, loadMoreItems]
  );

  const getDenomination = async () => {
    try {
      const result = await instance.get<ApiResponse<denominationType[]>>(
        '/v1/church/denomination'
      );
      setDenominationList(result.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getChurch = async (page: number, searchData: searchDataType) => {
    try {
      const params = {
        page: page,
        pageSize: 10,
        ...searchData,
      };
      const result = await instance.get<
        ApiResponse<PaginationResponse<churchDataType[]>>
      >('v1/church', { params });
      return result.data.data.result;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const InputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const searchBtnHandler = () => {
    setPage(1);
    setChurchList([]);
    loadMoreItems(1);
  };

  const onSubmit = (data: churchDataType) => {
    dispatch(closeModal());
    submitHandler(data);
  };

  return (
    <div className="max-w-[600px] min-h-[60vh] max-h-[90vh] w-full bg-white p-4 rounded-lg m-4 flex flex-col gap-4 overflow-y-hidden overflow-h-hidden overflow-x-hidden">
      <section className="flex justify-between">
        <TextWinds type="title_h2">교회 정보 검색</TextWinds>
        <div
          className="cursor-pointer hover:bg-gray2 p-2 w-8 h-8 flex justify-center items-center rounded border-gray7 border"
          onClick={() => dispatch(closeModal())}
        >
          X
        </div>
      </section>

      <section className="border-b border-gray2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
          <Select
            label="특별.광역시/도"
            onChange={(v) => setSearchData({ ...searchData, city: v })}
          >
            {CITY_DATA.map((item, index) => (
              <Option value={item} key={index}>
                {item}
              </Option>
            ))}
          </Select>
          <Select
            label="--교단--"
            onChange={(v) =>
              setSearchData({ ...searchData, denominationId: v })
            }
          >
            {denominationList?.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.denominationName}
              </Option>
            ))}
          </Select>
          <Input
            label="교회명"
            name="churchName"
            type="text"
            onChange={InputHandler}
          />
          <Input
            label="목사님 성함"
            name="pastorName"
            type="text"
            onChange={InputHandler}
          />
        </div>
        <Button
          className="w-full mt-2 bg-purple5 rounded"
          onClick={searchBtnHandler}
        >
          검색
        </Button>
      </section>

      <section ref={scrollRef} className="h-full overflow-y-auto ">
        {churchList && churchList.length > 0 ? (
          churchList.map((item, index) => (
            <ChurchItem
              key={index}
              item={item}
              onClick={onSubmit}
              lastItemRef={
                index === churchList.length - 1 ? lastItemRef : undefined
              }
            />
          ))
        ) : (
          <>검색 정보가 없습니다.</>
        )}
      </section>
    </div>
  );
};

const ChurchItem = ({
  item,
  lastItemRef,
  onClick,
}: {
  item: churchDataType;
  lastItemRef?: (node: HTMLDivElement) => void;
  onClick: (data: churchDataType) => void;
}) => {
  return (
    <div
      className="flex flex-col p-2 border-b border-gray2 hover:bg-gray2 cursor-pointer"
      onClick={() => onClick(item)}
      ref={lastItemRef}
    >
      <TextWinds type="title_h3">
        {item.churchName} {item.pastorName && `-${item.pastorName}`}
      </TextWinds>
      <TextWinds type="caption1">
        {item.denomination.denominationName} {phoneFormat(item.tel)}
      </TextWinds>
      <TextWinds type="caption1">{item.address}</TextWinds>
    </div>
  );
};

export default ModalChurch;
