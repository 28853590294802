import { Button, Typography } from "@material-tailwind/react";
import { NavLink } from "react-router-dom";
import TextWinds from "./common/TextWinds";

interface IconNavLinkProps {
    to: string;
    src: string;
    text1: string;
    text2: string;
}
export const IconNavLink = ({ to, src, text1, text2 }: IconNavLinkProps) => (
    <NavLink to={to}>
        <div className="relative flex flex-col items-center flex-1 border-2 rounded grow">
            <Button
                className="box-border w-full text-center md:text-lg"
                color="white"
                placeholder={undefined}
            >
                <img className="w-full max-w-[100px] ml-auto mr-auto" src={src} />

                <TextWinds type="button" color="purple5" className="text-sm lg:text-lg">
                    {text1}
                </TextWinds>
                <br />
                <TextWinds type="button" color="purple5" className="text-sm lg:text-lg">
                    {text2}
                </TextWinds>
            </Button>
        </div>
    </NavLink>
);
