import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Typography,
} from "@material-tailwind/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import Buttons from "../../../components/common/Buttons";

const Step4 = () => {
    const { pathname } = useLocation();
    return (
        <>
            <div className="py-[34px]">
                <div>
                    <img
                        src={process.env.PUBLIC_URL + "images/logo.png"}
                        alt="JEBS logo"
                        width={95}
                        height={95}
                        className="m-auto"
                    />
                </div>
                <div className="py-[32px] text-center">
                    <TextWinds type="title_h2" xlType="title_h1">
                        회원가입 완료!
                    </TextWinds>
                    <div className="pt-[28px]">
                        <TextWinds type="title_h4">
                            회원님을 위한 <br className="xl:hidden" />
                            맞춤형 <span className="text-purple5">교육 콘텐츠</span>를 준비했어요 😀
                            <br /> 지금 시작해 보세요.
                        </TextWinds>
                    </div>
                    <div className="flex justify-center mt-[32px]">
                        <Link to={"/"} className="w-full">
                            <Buttons type="filled" color={"purple5"}>
                                메인으로 이동
                            </Buttons>
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                {/* <Card className='px-10'>
                <CardHeader className='py-12' shadow={false}>
                    <img src={process.env.PUBLIC_URL+"images/logo.png"} alt="JEBS logo" width={170} height={170} className='m-auto' />
                </CardHeader>
                <CardBody className='pt-12'>
                    <Typography variant='h2' className='font-extrabold'>회원가입 완료!</Typography>
                    <Typography variant='h4'>회원님을 위한 맞춤형 교육 콘텐츠를 준비했어요</Typography>
                    <Typography variant='h4'>지금 시작해 보세요.</Typography>
                </CardBody>
                <CardFooter className='pt-20'>
                    <div className="flex justify-center">
                        <Link to={'/'}>
                            <Button>
                                메인으로 이동
                            </Button>
                        </Link>
                    </div>
                </CardFooter>
            </Card> */}
            </div>
        </>
    );
};

export default Step4;
