import { CertificateImgEnum } from './mypage-type';

export type myLearnigDetailType = {
  course: {
    id: string;
    name: string;
    thumbnail: string;
    type: string;
    licenseType: CertificateImgEnum;
  };
  user: {
    id: string;
    name: string;
    englishName: string;
    teacherStatus: string;
  };
  myLearning: {
    id: string;
    name: string;
    thumbnail: string;
    agree: boolean;
    enrolment: {
      id: string;
      remainDays: string;
      startDate: Date;
      endDate: Date;
    };
    openId: string;
    isRegistered: boolean;
    learningProgress: {
      recentlyLearningContent: {
        id: number;
        userId: string;
        classId: string;
        courseId: string;
        contentId: string;
        thumbnail: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date;
      };
      learningCount: {
        totalCount: number;
        completedCount: number;
      };
    };
    tasks: myLearningTodoType[];
    isGraduationTarget: boolean;
    hasCertificatedChurchInfo: boolean;
    isGraduated: boolean;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type myLearningTodoType = {
  id: number;
  title: string;
  description: string;
  type: string;
  status: string;
  taskAssignmentId: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};

export type myLearningChapterType = {
  id: number;
  name: string;
  lessons: myLearningLessonShortInfoType[];
  lessonCount: number;
  totalRunningTime: string;
  thumbnail: string;
};

export type myLearningLessonShortInfoType = {
  id: string;
  sequence: number;
  title: string;
};

export enum LearningStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type myLearningLessonInfoType = {
  id: string;
  name: string;
  type: string;
  chapterName: string;
  lessonName: string;
  thumbnail: string;
  status: LearningStatusEnum;
};
