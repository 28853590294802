import { ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Avatar,
} from '@material-tailwind/react';
import { Link, useLocation } from 'react-router-dom';
import TextWinds from '../../components/common/TextWinds';
import '../../styles/mypage/mypage.css';
import useUserHook from '../../controllers/useUserHook';
import { useState } from 'react';
import { logout } from '../../api/utils/util-func';

const MypageMain = () => {
  const location = useLocation();
  const { user } = useUserHook();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems1: menuItemType[] = [
    // { title: '회원등급', link: '/mypage/rating', color: 'purple5' },
    { title: '수료증', link: '/mypage/certification', color: 'gray6' },
    { title: '보유 포인트', link: '/mypage/point', color: 'purple5' },
    { title: '구매내역', link: '/mypage/purchase', color: 'purple5' },
    // {title:"쿠폰", link:"/mypage/coupon", detail:"10 장", color:"purple5"},
  ];
  const menuItems2: menuItemType[] = [
    { title: '교인인증', link: '/mypage/membership' },
    { title: '뱃지 보기', link: '/mypage/badge' },
    { title: '공지사항', link: '/mypage/notice' },
    // {title:"공지사항", link:"https://jebs-notice.oopy.io/55e90fc4-9893-4189-9c9d-759c138d4e98", type:"_blank"},
    { title: '이벤트', link: '/mypage/event' },
    // {title:"이벤트", link:"https://jebs-notice.oopy.io/b68dae28-f925-44c7-aaeb-ba5e2d12e279", type:"_blank"},
    { title: '고객센터', link: '/mypage/customer' },
    { title: '회원 정보 수정', link: '/mypage/modify' },
    { title: '설정', link: '/mypage/setting' },
  ];

  return (
    <section className="flex flex-col gap-y-4 pt-5 lg:px-5 lg:pt-[58px] lg:pb-[54px] lg:gap-y-[30px] lg:pt-[10px] lg:px-0">
      <div className="flex flex-col gap-y-[22px] mb-[10px] lg:gap-y-6 lg:mb-0">
        {/* <TextWinds type="title_h2" xlType="title_h1">
                    내 정보
                </TextWinds> */}
        <div className="flex items-center gap-4 pl-4">
          <img
            src={process.env.PUBLIC_URL + '/svg/icon/manageStudent.svg'}
            alt="manageStudent"
            className="w-[48px] h-[48px]"
          />
          {/* <Avatar
                        variant="circular"
                        src={process.env.PUBLIC_URL + "/svg/icon-profile.svg"}
                        className="w-18 h-18 bg-gray4"
                        alt="myname"
                    /> */}
          <div>
            <TextWinds type="sub_title01">{user?.name}</TextWinds>
            <TextWinds type="content_body2" color="gray7">
              {user?.uid}
            </TextWinds>
          </div>
        </div>
      </div>
      {/* {location.pathname !== '/mypage/badge' && (<div className="lg:hidden">
                <Card className="items-center gap-y-5 pt-4 pr-[54px] pb-3 pl-[55px] bg-gray1 shadow-none">
                    <List className="justify-center	flex-row gap-x-[53px] p-0">
                        <ListItem className="flex-col gap-y-[10px] max-w-10 p-0">
                            <ListItemPrefix className="m-0">
                                <Avatar
                                    variant="square"
                                    alt="green_badge"
                                    src={process.env.PUBLIC_URL + "/svg/badge/green_badge.svg"}
                                />
                            </ListItemPrefix>
                            <div className="w-full h-[10px] rounded-[5px] bg-gray10"></div>
                        </ListItem>
                        <ListItem className="flex-col gap-y-[10px] max-w-10 p-0">
                            <ListItemPrefix className="m-0">
                                <Avatar
                                    variant="square"
                                    alt="blue_badge"
                                    src={process.env.PUBLIC_URL + "/svg/badge/blue_badge.svg"}
                                />
                            </ListItemPrefix>
                            <div className="w-full h-[10px] rounded-[5px] bg-gray10"></div>
                        </ListItem>
                        <ListItem className="flex-col gap-y-[10px] max-w-10 p-0">
                            <ListItemPrefix className="m-0">
                                <Avatar
                                    variant="square"
                                    alt="orange_badge"
                                    src={process.env.PUBLIC_URL + "/svg/badge/orange_badge.svg"}
                                />
                            </ListItemPrefix>
                            <div className="w-full h-[10px] rounded-[5px] bg-gray10"></div>
                        </ListItem>
                    </List>
                    <Link to={"/mypage/badge"}>
                        <div className="flex">
                            <TextWinds type="button2" color="purple5">
                                뱃지 모두 보기
                            </TextWinds>
                        </div>
                    </Link>
                </Card>
            </div>)} */}
      <List className="p-0 hidden lg:block">
        {menuItems1.map((item, index) => (
          <MenuItemComp
            key={index}
            item={item}
            isActive={location.pathname === item.link}
          />
        ))}
        <hr className="my-2 border-blue-gray-50" />
        {menuItems2.map((item, index) => (
          <MenuItemComp
            key={index}
            item={item}
            isActive={location.pathname === item.link}
          />
        ))}
        <div onClick={() => logout()}>
          <ListItem className="justify-between py-4 px-0 text-purple5">
            로그아웃
          </ListItem>
        </div>
      </List>
      {/* <div className="flex flex-wrap gap-1 lg:hidden"> */}
      {isMenuOpen && (
        <div className="grid grid-cols-3 gap-2 lg:hidden mountAnimation">
          {menuItems1.map((item, index) => (
            <MenuItemMiniComp
              key={index}
              item={item}
              isActive={location.pathname === item.link}
            />
          ))}
          {menuItems2.map((item, index) => (
            <MenuItemMiniComp
              key={index}
              item={item}
              isActive={location.pathname === item.link}
            />
          ))}
        </div>
      )}
      <div
        className="text-center text-xs border p-2 block lg:hidden cursor-pointer hover:bg-gray2"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? '메뉴 닫기' : '메뉴 더보기'}
      </div>
      <div
        className="text-center text-xs border p-2 block lg:hidden cursor-pointer hover:bg-gray2"
        onClick={() => logout()}
      >
        로그아웃
      </div>
      <hr className="my-2 border-blue-gray-50 lg:hidden" />
    </section>
  );
};

type menuItemType = {
  title: string;
  link: string;
  detail?: string;
  color?: string;
  type?: '_blank' | undefined;
};

const MenuItemComp = ({
  item,
  isActive,
}: {
  item: menuItemType;
  isActive: boolean;
}) => {
  return (
    <Link to={item.link} target={item.type}>
      <ListItem
        className={`justify-between py-4 px-0 text-black rounded lg:pl-3 lg:pr-6 lg:hover:bg-purple0 ${
          isActive ? 'mypage-on' : ''
        }`}
      >
        {item.title}
        <div className="flex gap-2">
          <TextWinds type="content_body2" color={item.color}>
            {item.detail}
          </TextWinds>
          <ChevronRightIcon className="w-[22px] h-[22px]" />
        </div>
      </ListItem>
    </Link>
  );
};

const MenuItemMiniComp = ({
  item,
  isActive,
}: {
  item: menuItemType;
  isActive: boolean;
}) => {
  return (
    <Link
      to={item.link}
      className={`w-full text-center box-border p-3 inline rounded-lg hover:bg-gray-100 text-xs ${
        isActive ? 'mypage-on' : ''
      }`}
    >
      {item.title}
    </Link>
  );
};

export default MypageMain;
