import instance from '../../axios/utils.ts/instance';

export const putRecentlyLearning = async (
  learningId: string,
  lessonId: string
) => {
  try {
    const response = await instance.put(
      `/v1/learning/${learningId}/recently-learning-content/${lessonId}`
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};
type LearningLessonContentType = {
  id: string;
  name: string;
  division: string;
  type: 'Vimeo' | string;
  vimeo_key: string;
  running_time: string;
  created_at: Date;
  updated_at: Date;
  path: string;
  exe_file: { resource_path: string };
};

export const getContentView = async (learningId: string, lessonId: string) => {
  try {
    const response = await instance.get<{
      content: LearningLessonContentType;
    }>(`/v1/contents-app/view/${learningId}/${lessonId}`);
    return response.data.content;
  } catch (error) {}
};
