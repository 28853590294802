import React, { useContext, useState } from 'react';
import Buttons from '../../../components/common/Buttons';
import TextWinds from '../../../components/common/TextWinds';
import Icon from '../../../components/common/Icon';
import { Button, Input } from '@material-tailwind/react';
import { SignupContext } from './SignupContext';
import { phoneFormat } from '../../../api/utils/format/numbers';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse, SmsReturnType } from '../../../constants/types';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import { useNavigate } from 'react-router-dom';

const LoginCheck = () => {
  // 전화 번호를 입력했을 때 '인증번호' 버튼 활성
  // '인증번호' 클릭 시 문자 발송
  // 문자 발송 되었을 때 '인증하기' 버튼 활성
  // '인증하기' 클릭 시 인증 확인 후 '다음' 버튼 활성
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { phone, setPhone, step, setStep } = useContext(SignupContext);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifyId, setVerifyId] = useState<number>();
  const [isVerified, setIsVerified] = useState<boolean>(false);

  // 인증코드 문자 발송
  const postSms = async () => {
    if (!phone) {
      dispatch(openAlert({ message: '전화번호를 입력해주세요.' }));
      return;
    }
    try {
      const response = await instance.post<ApiResponse<SmsReturnType>>(
        '/v1/sms',
        {
          phone: phone,
        }
      );
      const data = response.data;
      if (data.statusCode === 200) {
        setVerifyId(data.data.id);
        dispatch(
          openAlert({
            message: '인증번호가 발송되었습니다. 3분안에 인증해주세요.',
          })
        );
      } else {
      }
    } catch (error) {}
  };

  // 인증코드 검증
  const putSms = async () => {
    if (!verificationCode) {
      dispatch(openAlert({ message: '인증번호를 입력해주세요.' }));
      return;
    }
    try {
      const response = await instance.put<ApiResponse<SmsReturnType>>(
        `/v1/sms`,
        {
          phone: phone,
          code: verificationCode,
          id: verifyId,
        }
      );
      const data = response.data;
      if (data.statusCode === 200) {
        // 인증 성공
        dispatch(openAlert({ message: '인증되었습니다.' }));
        setIsVerified(true);
      } else {
        // 인증 실패
      }
    } catch (error) {}
  };

  // 전화번호 입력시 '-' 제거 및 인증 초기화
  const handlePhoneChange = (value: string) => {
    setPhone(value.replace(/-/g, ''));
    setIsVerified(false);
    setVerificationCode('');
  };

  return (
    <div className="flex flex-col gap-4">
      <TextInfoSection />
      <section className="flex flex-col gap-2">
        <div className="relative flex w-full">
          <Input
            type="text"
            label="전화번호"
            className="pr-20"
            containerProps={{
              className: 'min-w-0',
            }}
            value={phoneFormat(phone)}
            onChange={(e) => handlePhoneChange(e.target.value)}
          />
          <Button
            size="sm"
            className="!absolute right-1 top-1 rounded bg-purple5"
            onClick={() => postSms()}
            disabled={!phone}
          >
            인증번호
          </Button>
        </div>
        <div className="relative flex w-full">
          <Input
            type="text"
            label="인증번호"
            className="pr-20"
            containerProps={{
              className: 'min-w-0',
            }}
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <Button
            size="sm"
            className="!absolute right-1 top-1 rounded bg-purple5"
            onClick={() => putSms()}
            disabled={!phone || !verificationCode}
          >
            인증하기
          </Button>
        </div>
      </section>
      <section className="flex flex-col gap-2">
        <Buttons
          type="filled"
          color={'purple5'}
          disabled={!isVerified}
          onClick={() => setStep(step + 1)}
        >
          다음
        </Buttons>
        <Buttons
          type="outlined"
          color={'purple5'}
          onClick={() => navigate('/signin')}
        >
          취소
        </Buttons>
      </section>
    </div>
  );
};

const TextInfoSection = () => (
  <section>
    <div>
      <TextWinds type="title_h2">회원가입 확인</TextWinds>
      <TextWinds type="caption1">
        학생 계정 가입은 클래스 선생님에게 요청해 주세요.
      </TextWinds>
    </div>
    <div className="pt-[35px] pb-[25px] text-center">
      <div className="flex justify-center">
        <TextWinds type="title_h3" xlType="title_h2" color="gray9">
          안녕하세요~
        </TextWinds>
        <Icon width={28} height={28} fileName="icon_face" />
      </div>
      <div className="pt-[9px]">
        <TextWinds type="title_h4" color="purple5">
          먼저 휴대폰 번호를 확인해 주세요.
        </TextWinds>
      </div>
    </div>
  </section>
);

export default LoginCheck;
