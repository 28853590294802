import { LmsInfoTypes } from '../../../constants/types';

export function setExtendBtnLink(id: string) {
  let checkType = '';
  let purchaseLink: Record<string, any> = {};
  switch (id) {
    case 'QJhUDePfAn__Y6na':
      checkType = 'double';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/P9hcqg1j_bJDTtVA`;
      purchaseLink.packLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/r7rC4K6hoPycJ0Gz`;
      break;
    case 'Irmk2AAW2RWV-iHn':
      checkType = 'double';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/qVNHW5pY8HZjbHKM`;
      purchaseLink.packLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/Tvj1HF6vcvSgXPo-`;
      break;
    case 'XoaJAz_Vg6fJ1QxQ':
      checkType = 'double';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/7g6LZsi0qBwLP5K1`;
      purchaseLink.packLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/homeschool/details/VVpX9YkX7hcxsMdE`;
      break;
    case 'GXHrE6RAfYafHfgs':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/nO8efugcVGoFZWQb`;
      break;
    case 'VY6Yl4kxQsb3sYJZ':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/i3OS02dLW4eb_jj1`;
      break;
    case 'UFRScZaSalYhG8sw':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/x7X38suGrP9dcQiU`;
      break;
    case 'L8dZVMTvkzcHkSGm':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/DR4RZkbr_ZNsoltq`;
      break;
    case 'tRJLemvzaElleQ5m':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/lQP6eIqBBbi6G_1Q`;
      break;
    case 'C8qyk2J3H068tONs':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/Nh8o32qxIXXqXR_O`;
      break;
    case 'paQMscIKGGisFATO':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/wprZm-glvDkO8enM`;
      break;
    case 'jkfpzV9bk4sEmcEA':
      checkType = 'single';
      purchaseLink.onlineLink = `${process.env.REACT_APP_JEBS_APP_URL}/courses/churchschool/details/iB9i8IjMv519AEb3`;
      break;
  }
  return { checkType, purchaseLink };
}

export function courseIdDetermination(classId: string, lmsLists: LmsInfoTypes) {
  let divisionStr = '';
  if (classId === lmsLists?.id) {
    switch (lmsLists.course?.id) {
      case 'Irmk2AAW2RWV-iHn': // 홈스쿨 베이직
        // case "XoaJAz_Vg6fJ1QxQ": // 홈스쿨 어드벤스드
        divisionStr = 'home';
        break;
      case 'GXHrE6RAfYafHfgs': // HFHJ
      case 'VY6Yl4kxQsb3sYJZ': // WMW
        divisionStr = 'church';
        break;
      default:
        divisionStr = '';
        break;
    }
  }
  return divisionStr;
}

export function setPurchaseTicketLink(type: string) {
  let targetUrl = '';
  switch (type) {
    // 젭스플
    case 'WMW':
      targetUrl = `${process.env.REACT_APP_JEBS_APP_URL}/shop/details/4Sil93OMkV2Gdo0r`;
      break;
    case 'JS1':
      targetUrl = `${process.env.REACT_APP_JEBS_APP_URL}/shop/details/hyRj7dUQ_JbEXyJ-`;
      break;
    case 'JS2':
      targetUrl = `${process.env.REACT_APP_JEBS_APP_URL}/shop/details/rPvEY01DDZF3UcB7`;
      break;
    case 'HF':
      targetUrl = `${process.env.REACT_APP_JEBS_APP_URL}/shop/details/Tm5hMspVafbUV_UQ`;
      break;
    case 'HJ':
      targetUrl = `${process.env.REACT_APP_JEBS_APP_URL}/shop/details/Z0c__5c22-qZf1zt`;
      break;
    // 인증제
    case 'RECITATION':
      targetUrl = `${process.env.REACT_APP_JEBS_APP_URL}/shop/details/VYUEujY1-EdTuwxh`;
      break;
  }
  return targetUrl;
}

export function extentionCategory(id: string): boolean {
  let resultBool = true;
  switch (id) {
    case 'P9hcqg1j_bJDTtVA':
    case 'r7rC4K6hoPycJ0Gz':
    case 'qVNHW5pY8HZjbHKM':
    case 'Tvj1HF6vcvSgXPo-':
    case '7g6LZsi0qBwLP5K1':
    case 'VVpX9YkX7hcxsMdE':
    case 'nO8efugcVGoFZWQb':
    case 'i3OS02dLW4eb_jj1':
    case 'x7X38suGrP9dcQiU':
    case 'DR4RZkbr_ZNsoltq':
    case 'lQP6eIqBBbi6G_1Q':
    case 'Nh8o32qxIXXqXR_O':
    case 'wprZm-glvDkO8enM':
    case 'iB9i8IjMv519AEb3':
    case '18Mm87u5V0hU6B57':
    case 'WvA7mOd2zVCpgjSC':
    case 'o11lpOrtYZvft2kS':
    case 'YCs71D_HpHuzaIn-':
    case 'vKPAt48Z82J--OQW':
    case 'yn77N1jwEoadGi9k':
    case 'dyU762lu6RonOLhT':
    case 'Cb1rlUxvKLutuFyT':
    case 'C1GN6X0nwhF9y4m-':
    case 'rH76pcPI7w89wgFB':
    case '9x2g5R-9DMQMAHSX':
      resultBool = false;
      break;
    default:
      resultBool = true;
      break;
  }
  return resultBool;
}
