import { configureStore } from "@reduxjs/toolkit";
import persistReducer from "./features";
import { persistStore } from "redux-persist";
// import shopSlice from "./features/shopSlice";

export const store = configureStore({
    reducer: persistReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
