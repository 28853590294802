import { Drawer } from '@material-tailwind/react';
import React, { Fragment, useState } from 'react';
import CustomDialog from '../../../../components/layout/CustomDialog';
import Extend from '../../../../components/drawer/extend';
import MoLayout from '../../../../components/lms/MoLayout';
import PcLayout from '../../../../components/lms/PcLayout';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import LeftContents from './childpages/LeftContents';
import { setExtendBtnLink } from '../../../../api/utils/lms/ExtendSetBtnLink';
import { openAlert, openModal } from '../../../../store/features/modalSlice';
import ModalExtendLink from '../../../../components/modal/ModalExtendLink';
import TextWinds from '../../../../components/common/TextWinds';
import {
  changeTimestamp,
  handleCopyClipBoard,
} from '../../../../api/utils/util-func';
import Buttons from '../../../../components/common/Buttons2';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const dialogText =
  '‘클래스 코드’란 선생님이 개설한 클래스의 고유 코드로, 선생님의 클래스에 속하기 위해서는 클래스 코드가 학생 계정에 반드시 기재되어야 합니다. \n소속 클래스가 변경 되었다면 담당 선생님에게 클래스 코드 문의 후 학생 계정에 입력/수정해 주세요. \n클래스 코드는 총 3개까지 입력 가능합니다.';
const CourseMgmt = () => {
  const lmsLists = useAppSelector(selectLmsLists);
  const handleOpenDialog = () => {
    dispatch(openAlert({ message: dialogText }));
  };

  const dispatch = useAppDispatch();
  const extendButtonHandler = ({
    id,
    name,
    type,
  }: {
    id: string;
    name: string;
    type: string;
  }) => {
    const { checkType, purchaseLink } = setExtendBtnLink(id);
    dispatch(
      openModal({
        body: (
          <ModalExtendLink
            checkType={checkType}
            type={type}
            message="클래스 연장하기"
            purchaseLInk={purchaseLink}
          />
        ),
      })
    );
  };

  const copyHandler = (msg: string) => {
    handleCopyClipBoard(msg).then(() => {
      dispatch(openAlert({ message: '클립보드에 복사되었습니다.' }));
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full mt-3 p-5 xl:mt-0">
      <div className="lg:px-8 lg:pt-6 lg:pb-9 lg:shadow-card lg:bg-white lg:rounded-lg ">
        <div className="mb-3">
          <TextWinds type="title_h4">연장하기</TextWinds>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-3 p-3 shadow-card rounded-lg bg-white">
          <TextWinds type="title_h3" color="gray8">
            {lmsLists.course?.name}
          </TextWinds>

          <div>
            <TextWinds type="sub_title01" color="gray7">
              {lmsLists.remain_days}일 남음
            </TextWinds>
            <TextWinds type="content_body3" color="gray7">
              {changeTimestamp(lmsLists.end_date)} 까지
            </TextWinds>
          </div>
          <Buttons
            variant="filled"
            color="orange4"
            type="secondary"
            onClick={() => extendButtonHandler(lmsLists.course)}
            className="lg:w-[143px] lg:h-[54px]"
          >
            <TextWinds type="button2" xlType="button" color="white">
              연장하기
            </TextWinds>
          </Buttons>
        </div>
      </div>
      <div className="lg:px-8 lg:pt-6 lg:pb-9 lg:shadow-card lg:bg-white lg:rounded-lg">
        <div className="flex justify-between py-3">
          <TextWinds type="title_h4">클래스 코드명</TextWinds>

          <div
            className="flex flex-row items-center  justify-end gap-x-2 cursor-pointer"
            onClick={handleOpenDialog}
          >
            <InformationCircleIcon className="w-5 h-5 text-skyblue" />
            <div className="text-[14px] leading-[22px] tracking-[-0em] font-medium text-skyblue whitespace-nowrap">
              클래스 코드란?
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between gap-3 p-3 shadow-card rounded-lg bg-white">
          <TextWinds type="title_h3" color="gray8">
            {lmsLists.id}
          </TextWinds>

          <Buttons
            variant="filled"
            color="orange4"
            type="secondary"
            onClick={() => copyHandler(lmsLists.id)}
            className="lg:w-[143px] lg:h-[54px]"
          >
            <TextWinds type="button" color="white">
              코드 복사하기
            </TextWinds>
          </Buttons>
        </div>
      </div>
    </div>
  );
};

export default CourseMgmt;
