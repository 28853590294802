import React, { ReactNode, useState } from 'react';
import Star from '../img/sec04-star.png';
import {
  neumophismUp,
  neumophismDown,
  neumophismOver,
} from '../../CourseStyle';
import { motion } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

/*
props로 tailwind문법 받아오는 컴포넌트
- CircleIconBox
- CircleIconBoxWithTitle
- DiscountedPrice
 */

export const Card = ({
  img,
  children,
}: {
  img: string;
  children: ReactNode;
}) => (
  <div
    style={neumophismDown}
    className="flex flex-col bg-white rounded-2xl p-3 text-black"
  >
    <img src={img} className="w-24 m-auto md:w-full" />
    <div className="text-center ">{children}</div>
  </div>
);

export const RoundedBox = ({
  number,
  children,
}: {
  number: string;
  children: ReactNode;
}) => (
  <div className="flex items-center rounded-2xl gap-3 p-4 md:p-5 md:px-8 bg-white shadow-xl">
    <div className="text-xl md:text-4xl text-[#6463e31a] font-extrabold font-['Pretendard']">
      {number}
    </div>
    <div className="w-full items-center text-center text-base sm:text-lg md:text-xl font-medium font-['Pretendard']">
      {children}
    </div>
  </div>
);

export const ReviewBox = ({
  img,
  star,
  children,
  isSmall = false,
}: {
  img: string;
  star: number;
  children: ReactNode;
  isSmall?: boolean;
}) => (
  <motion.div
    className="rounded-lg bg-white shadow-lg flex items-center p-2 md:p-3 "
    initial={{ y: 100, opacity: 0, scale: isSmall ? 0.9 : 1 }}
    whileInView={{
      y: 0,
      opacity: 1,
      scale: isSmall ? 0.9 : 1,
    }}
    transition={{
      duration: 0.4,
      ease: 'easeOut',
    }}
  >
    <div className="flex items-center gap-1 md:gap-3">
      <div>
        <img src={img} className="w-16 md:w-24" />
      </div>

      <div className="flex-1 flex flex-col gap-1">
        <div className="flex">
          {[...Array(5)].map((_, index) => (
            <img
              className="w-5 md:w-6"
              src={index < star ? Star : ''}
              key={index}
            />
          ))}
        </div>
        {children}
      </div>
    </div>
  </motion.div>
);

export const NeumophismButton = ({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const [style, setStyle] = React.useState(neumophismUp);

  return (
    <div
      style={style}
      className={className}
      onClick={onClick}
      onMouseDown={() => setStyle(neumophismDown)}
      onMouseUp={() => setStyle(neumophismOver)}
      onMouseOver={() => setStyle(neumophismOver)}
      onMouseLeave={() => setStyle(neumophismUp)}
    >
      {children}
    </div>
  );
};

export const NeumophismAccordion = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div style={neumophismDown} className="p-5 rounded-2xl">
      <div className="flex items-center" onClick={() => setIsOpen((v) => !v)}>
        {title}
        <NeumophismButton className="w-fit p-3 rounded-2xl cursor-pointer">
          <ChevronDownIcon
            className={`w-6 h-6 text-gray7 transform transition-transform duration-300 ease-in-out ${
              isOpen ? '-rotate-180' : 'rotate-0'
            }`}
          />
        </NeumophismButton>
      </div>
      <motion.div
        style={{ overflow: 'hidden', transformOrigin: 'top' }}
        initial={{ height: 0 }}
        animate={{
          height: isOpen ? 'auto' : 0,
          paddingTop: isOpen ? 20 : 0,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export const WmwIconBox = ({
  children,
  icon,
  title,
}: {
  children: ReactNode;
  icon: string;
  title: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div
        className="border-2 border-[#dddddd] rounded-lg p-1 md:p-5 "
        onClick={() => setIsOpen((v) => !v)}
      >
        <div className="flex items-center flex-col gap-3 md:gap-5">
          <div className="relative w-full">
            <img
              src={icon}
              className="bg-white shadow-xl rounded-xl w-full aspect-square object-contain px-5 sm:px-10"
            />
            {isOpen ? (
              <div className="absolute w-full h-full top-0 left-0">
                <div className="bg-white rounded-xl w-full h-full flex items-center justify-center text-xs sm:text-base md:text-base text-center">
                  {children}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="rounded-full border border-[#5f32c9] text-[#5f32c9] text-center text-xs sm:text-base md:text-base font-medium font-['Pretendard'] px-2">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CircleRightArrowIcon = ({
  width = 22,
  color = '#855AFF',
}: {
  width?: number;
  color?: string;
}) => (
  <svg
    width={width}
    viewBox="0 0 38 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="19"
      cy="19.2466"
      r="17.8125"
      stroke={color}
      strokeWidth="2.375"
    />
    <path
      d="M16.0312 10.9341L24.3438 19.2466L16.0312 27.5591"
      stroke={color}
      strokeWidth="2.77083"
    />
  </svg>
);

export const CircleIcon = ({
  width = 51,
  height = 52,
  fontSize = 52,
  text = '+',
  color = '#875CFF',
}: {
  width?: number;
  height?: number;
  fontSize?: number;
  text?: string;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 51 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Ellipse 26"
      cx="25.3877"
      cy="26.1084"
      r="24.95"
      stroke={color}
      strokeWidth="0.875439"
    />
    <text
      x="25.3877"
      y="26.1084"
      textAnchor="middle"
      dominantBaseline="middle"
      fill={color}
      fontSize={fontSize}
      fontWeight="bold"
      fontFamily="Pretendard"
    >
      {text}
    </text>
  </svg>
);

export const CircleCheckIcon = ({
  width,
  circleColor = '#FFDA19',
  checkColor = 'white',
}: {
  width: string;
  circleColor?: string;
  checkColor?: string;
}) => (
  <svg
    width={width}
    viewBox="0 0 43 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.427 19.1559C36.427 28.851 28.7718 36.6425 19.4114 36.6425C10.0511 36.6425 2.39583 28.851 2.39583 19.1559C2.39583 9.46072 10.0511 1.66927 19.4114 1.66927C28.7718 1.66927 36.427 9.46072 36.427 19.1559Z"
      stroke={circleColor}
      strokeWidth="3.16667"
    />
    <path
      d="M12.5918 17.8848L19.4114 24.8772L41.1101 2.62891"
      stroke={checkColor}
      strokeWidth="3.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ListItem = ({ text }: { text: string }) => (
  <li className="pl-2 md:pl-4 relative before:content-['-'] before:absolute before:left-0">
    {text}
  </li>
);

export const QuoteIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 375 336"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Vector" filter="url(#filter0_d_610_1078)">
      <path
        d="M267.97 162.831C263.241 156.607 257.566 151.755 250.946 148.244C240.542 142.818 235.498 136.274 235.813 128.614C236.066 122.102 240.542 115.016 249.243 107.387C251.923 105.025 252.112 100.876 249.748 98.1626C247.667 95.8006 244.199 95.3218 241.52 96.9816C223.14 108.409 209.584 121.272 200.851 135.54C192.686 148.914 188.052 162.097 186.948 175.12C185.782 170.364 183.922 166.278 181.305 162.831C176.576 156.607 170.902 151.755 164.281 148.244C153.878 142.818 148.834 136.274 149.149 128.614C149.401 122.102 153.878 115.016 162.579 107.387C165.258 105.025 165.448 100.876 163.083 98.1626C161.002 95.8006 157.535 95.3218 154.855 96.9816C136.475 108.409 122.919 121.272 114.187 135.54C104.729 151.021 100 166.246 100 181.248C100 198.166 104.256 210.838 112.768 219.296C121.28 227.755 131.841 231.968 144.42 231.968C150.725 231.968 156.557 230.851 161.917 228.617C167.276 226.382 171.91 223.286 175.851 219.296C179.792 215.306 182.85 210.614 185.057 205.188C186.349 201.996 187.264 198.708 187.799 195.293C189.596 205.379 193.443 213.391 199.401 219.328C207.913 227.787 218.474 232 231.053 232C237.358 232 243.19 230.883 248.55 228.648C253.909 226.414 258.543 223.318 262.484 219.328C266.425 215.338 269.483 210.646 271.69 205.22C273.897 199.793 275 194.048 275 187.983C275 177.45 272.636 169.087 267.907 162.863L267.97 162.831Z"
        fill="#FFFFFF33"
      />
    </g>
  </svg>
);

// --- props로 tailwind문법 받아옴 ---
export const CircleIconBox = ({
  circleColor = 'bg-[#ede9f5]',
  circleSize = 'h-[60px] w-[60px] md:h-24 md:w-24',
  icon,
  delay = 0,
}: {
  circleColor?: string;
  circleSize?: string;
  icon: string;
  delay?: number;
}) => (
  <div
    className={`flex items-center justify-center rounded-full ${circleSize} ${circleColor}`}
  >
    <img src={icon} alt="icon" className={`h-[70%] w-[70%] `} />
  </div>
);

// --- props로 tailwind문법 받아옴 ---
export const CircleIconBoxWithTitle = ({
  icon,
  circleColor = 'bg-[#d9d9d9]',
  circleSize = 'h-24 w-24 md:h-32 md:w-32',
  title,
  delay,
}: {
  icon: string;
  circleColor?: string;
  circleSize?: string;
  title: ReactNode;
  delay: number;
}) => (
  <motion.div
    className="flex flex-col justify-center items-center gap-2"
    initial={{ y: -15, opacity: 1 }}
    whileInView={{ y: 0, opacity: 1 }}
    transition={{ delay: delay || 0 }}
  >
    <CircleIconBox
      icon={icon}
      delay={delay}
      circleColor={circleColor}
      circleSize={circleSize}
    />
    {title}
  </motion.div>
);

export const ArrowIcon = ({
  style,
  color = '#6730FF',
}: {
  style?: React.CSSProperties;
  color?: string;
}) => (
  <svg
    width="25"
    height="18"
    viewBox="0 0 47 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      id="Line 25"
      d="M47 8.75391L32 0.0936518V17.4142L47 8.75391ZM0 10.2539H33.5V7.25391H0V10.2539Z"
      fill={color}
    />
  </svg>
);

// --- props로 tailwind문법 받아옴 ---
export const DiscountedPrice = ({
  price,
  discountedPrice,
  discountColor = 'text-[#5600E8]',
  delay = 0,
}: {
  price: string;
  discountedPrice: string;
  discountColor?: string;
  delay?: number;
}) => {
  return (
    <motion.div
      className="flex justify-center items-center gap-5"
      initial={{ x: -20, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.4, ease: 'easeOut', delay: delay || 0 }}
    >
      <div className="flex justify-center items-center">
        <div className="relative text-lg md:text-xl text-[#969696] font-medium">
          {price}원
          <div className="absolute top-1/2 left-[-5px] right-[-20px] h-[1px] -translate-y-1/2 bg-[#B0B0B0]" />
        </div>
        <div
          className="w-0 h-0  
           border-t-[4px] border-t-transparent 
           border-b-[4px] border-b-transparent
           border-l-[8px] border-l-[#B0B0B0]
           ml-4"
        />
      </div>

      <div className={`text-3xl md:text-4xl ${discountColor} font-extrabold`}>
        {discountedPrice}
        <span>원</span>
      </div>
    </motion.div>
  );
};

export const CircleDownArrowIcon = ({
  size = 45,
  color = '#FFD600',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 1410118460">
      <circle
        id="Ellipse 24"
        cx="22.7503"
        cy="22.1667"
        r="20.7812"
        transform="rotate(90 22.7503 22.1667)"
        fill="#FFDA19"
        stroke={color}
        strokeWidth="2.77083"
      />
      <path
        id="Vector 1"
        d="M32.4482 18.7031L22.7503 28.401L13.0524 18.7031"
        stroke={color}
        strokeWidth="3.23264"
      />
    </g>
  </svg>
);
