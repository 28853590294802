import React from "react";

export default function Box({ children }: { children: React.ReactNode }) {
    return (
        <>
            <section className="xl:bg-skyblue_5">
                <div className="flex flex-wrap gap-[22px] w-full pt-3.5 pb-[64px] xl:w-[1176px] xl:mx-auto">
                {/* <div className="flex flex-wrap gap-[22px] w-full pt-3.5 pb-[64px] xl:mx-auto"> */}
                    {children}
                </div>
            </section>
        </>
    );
}
