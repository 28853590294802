import React, { ReactNode } from 'react';
import { neumophismDown } from '../../CourseStyle';
import atIcon from '../img/sec02-a-atIcon.png';
import keyIcon from '../img/sec02-b-keyIcon.png';
import section2Img1 from '../img/sec02-c-file.png';
import section2Img2 from '../img/sec02-c-map.png';
import section2Img3 from '../img/sec02-c-tick.png';
import section2Img4 from '../img/sec02-c-pencil.png';

const HFHJSection2 = () => {
  return (
    <div className="bg-gradient-to-b from-[#FCAF3C] to-[#FFA319] flex justify-center p-3 py-10 md:p-12 md:py-20">
      <div className="flex flex-col gap-10 w-full max-w-[520px] text-black">
        <section className="flex text-base md:text-lg pr-2 md:pr-0 md:gap-2 ">
          <img src={atIcon} alt="atIcon" className="w-1/4 m-auto" />
          <div>
            젭스영어주일학교의{' '}
            <span className="font-semibold">유치부 프로그램 HFHJ</span>는
            어린이들이 구약과 신약의 큰 사건들을 영어로 배우며{' '}
            <span className="font-semibold">
              하나님과 예수님에 대해 알아가는 동시에 일상의 유용한 표현을 영어로
              말할 수 있는 기반을 다지는 유치부 프로그램
            </span>
            입니다.
          </div>
        </section>
        <section className="flex flex-col gap-3">
          <div className="text-center text-xs md:text-base">
            <div className="text-white text-base md:text-lg font-bold">
              젭스영어주일학교의 목표는
            </div>
            <div>
              다음 세대 교회 부흥의 주역인 우리 아이들을 영어로 복음을 외치는
            </div>
          </div>
          <div
            style={neumophismDown}
            className="flex gap-2 items-center bg-white rounded-2xl py-3 px-5 w-fit m-auto"
          >
            <img src={keyIcon} alt="keyIcon" className="w-16 md:w-20 m-auto" />
            <div className="w-fit h-fit text-black text-sm whitespace-nowrap">
              <div className="text-[#FF5C00] text-lg md:text-xl font-bold">
                성령 충만! 영어 능통!
              </div>
              한 세계적인 지도자로 길러내는 것입니다.
            </div>
          </div>
          <div className="text-center text-xs md:text-sm font-normal text-white">
            *<span className="font-medium"> HFHJ(헬파헬지)</span>는 Hello,
            Father! Hello, Jesus!의 줄임말 입니다.
          </div>
        </section>
        <section className="text-2xl md:text-4xl text-center">
          <span className="text-white font-bold">HFHJ</span>는 이래서 믿을 수
          있어요!
        </section>
        <section>
          <div className="text-center text-2xl md:text-3xl">
            <span className="text-white font-bold">“이미 검증된”</span> 주일학교
            프로그램
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-5">
            <Card img={section2Img1}>
              <>
                <br />
                <span className="text-[#FF5C00] font-bold">
                  정철연구소의
                  <br /> 교육노하우
                </span>{' '}
                집약
              </>
            </Card>
            <Card img={section2Img2}>
              <>
                <br />
                <span className="text-[#FF5C00] font-bold">
                  전국 7천여
                  <br /> 교회
                </span>
                의 선택
              </>
            </Card>
            <Card img={section2Img3}>
              <>
                <br />
                <span className="text-[#FF5C00] font-bold">
                  1만여 명의
                  <br /> 교사{' '}
                </span>
                배출
              </>
            </Card>
            <Card img={section2Img4}>
              <>
                <br />
                <span className="text-[#FF5C00] font-bold">
                  10만여 명
                  <br /> 학생
                </span>
                의 수료
              </>
            </Card>
          </div>
        </section>
      </div>
    </div>
  );
};
const Card = ({ img, children }: { img: string; children: ReactNode }) => (
  <div
    style={neumophismDown}
    className="flex flex-col bg-white rounded-2xl p-3 text-black"
  >
    <img src={img} className="w-24 m-auto md:w-full" />
    <div className="text-center md:text-sm">{children}</div>
  </div>
);
export default HFHJSection2;
