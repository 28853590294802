import React, { useEffect } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { useNavigate, useParams } from 'react-router-dom';
import instance from '../../../api/axios/utils.ts/instance';
import { myLearingInfoDetailType } from '../../../constants/types/student-type';
import StudentHomeworkItem from './homework/StudentHomeworkItem';
import HFbutton from '../../../components/common/input/HFbutton';

const StudentClassDetail = () => {
  const navigate = useNavigate();
  const { classId } = useParams();
  const [data, setData] = React.useState<myLearingInfoDetailType>();
  const setTodoView = React.useState<Window>(undefined)[1];

  const getClassDetail = async () => {
    try {
      const result = await instance.get('/v1/student/class/' + classId);
      if (result.data.statusCode === 200) {
        const data = result.data.data;
        setData(data);
      } else {
        console.error(result.data.message);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getClassDetail();
  }, []);

  return (
    <div className="bg-white py-16 p-4 xl:p-0 xl:pt-8">
      <div className="max-w-[1176px] m-auto flex flex-col gap-8">
        <section>
          <header>
            <TextWinds type={'title_h1'} children={data?.course.name} />
            <TextWinds
              type={'content'}
              children={`${data?.teacherName} 선생님`}
            />
            <div></div>
          </header>
        </section>
        <section>
          <img
            src={data?.course.thumbnail}
            alt=""
            className="w-full h-[264px] object-cover rounded-lg"
          />
        </section>
        <section className="rounded-lg bg-gray-100 p-5">
          <header className="flex justify-between">
            <TextWinds
              type={'title_h3'}
              children={'할일'}
              className="w-10 shrink-0"
            />
            <div className="w-auto">
              <HFbutton
                height={36}
                onClick={() => navigate(`/student/homework/${classId}`)}
              >
                전체보기
              </HFbutton>
            </div>
          </header>
          <div className="flex flex-col gap-5 mt-3 ">
            {data && data.todos?.length > 0 ? (
              data.todos.map((item) => (
                <StudentHomeworkItem
                  data={item}
                  key={item.id}
                  setTodoView={setTodoView}
                />
              ))
            ) : (
              <TextWinds
                type={'content'}
                children={'할 일이 없습니다.'}
                className="text-center text-gray5"
              />
            )}
          </div>
          {/* <StudentHomeworkList max={2} /> */}
        </section>
        {/* <section>
          <header className="flex justify-between">
            <TextWinds type={'title_h3'} children={'게시판'} />
            <TextWinds type={'button2'} children={'목록보기'} />
          </header>
          <div className="border-t border-gray5 mt-2">
            {data && data.notices.length > 0 ? (
              data.notices.map((item) => (
                <StudentNoticeItem key={item.id} data={item} />
              ))
            ) : (
              <div className="p-8 border-b border-gray5 ">
                <TextWinds
                  type={'content'}
                  children={'게시글이 없습니다.'}
                  className="text-center"
                />
              </div>
            )}
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default StudentClassDetail;
