import React from 'react'
import { EnrollOption, OrderItem, OrderItemVariant } from '../../../constants/types'
import TextWinds from '../../common/TextWinds';
import { numberWithCommas } from '../../../api/utils/format/numbers';
import CounterNumber from '../../common/CounterNumber';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Buttons from '../../common/Buttons';
import { getChosenVariantsAmounts } from '../../../api/utils/order-item-total-amount';

interface PurchaseConditionsProps {
  order_item: OrderItem;
  enroll_options: Array<{enroll_option: EnrollOption}>;
  chosen_options: Array<Omit<OrderItem, 'chosen_options' | 'enroll_options'>>;
  chosen_variants: OrderItemVariant[];
  handleIncrementCount: (type: string, variantId: string, orderItemId: string, value: number) => void;
  handleDecrementCount: (type: string, variantId: string, orderItemId: string, value: number) => void;
  handleBuySingleItem: (item: OrderItem) => void;
}
const Purchase_Conditions = ({
  order_item,
  enroll_options,
  chosen_options,
  chosen_variants,
  handleDecrementCount,
  handleIncrementCount,
  handleBuySingleItem,
}: PurchaseConditionsProps) => {
  // const chosen_options: Omit<OrderItem, 'chosen_options' | 'enroll_options'> = order_item.chosen_options
  return (
    <>
      <div className='flex flex-row gap-[6px]'>
        {/* 상품명 */}
        <div className='w-full flex flex-col items-start justify-start'>
          <TextWinds type="content" className="pt-[5px]">
            {order_item.product.name}
          </TextWinds>
          <TextWinds type="content_rel">
            ({order_item.product.description})
          </TextWinds>

          <TextWinds type="content_rel" className="pt-[8px]">
            {numberWithCommas(order_item.product.current_price)} 원
          </TextWinds>

          <div className='w-full flex flex-col'>
            {/* COURSE 인 경우 */}
            {
              enroll_options && (
                enroll_options.map((enrolls, idx) => (
                  <div key={enrolls.enroll_option.product_course_id} className="pt-[8px] flex flex-row items-center justify-between">
                    <div className=''>
                      <TextWinds type="content_rel">
                        [플랜] : {enrolls.enroll_option.product_course.course.name} - {enrolls.enroll_option.period}
                      </TextWinds>
                    </div>
                    <div className=''>
                      <TextWinds type="content_rel">
                        {numberWithCommas(enrolls.enroll_option.price)} 원
                      </TextWinds>
                    </div>
                  </div>
                ))
              )
            }

            {/* COURSE 인 경우 */}
            {
              chosen_options && (
                chosen_options.map((options: Omit<OrderItem, "chosen_options" | "enroll_options">, idx) => (
                  options.chosen_variants.map((variants: OrderItemVariant, idx) => (
                    <div key={variants.variant_id} className="pt-[8px]">
                      <div className='flex flex-row items-center justify-between'>
                        <div className=''>
                          <TextWinds type="content_rel">
                            옵션 : {variants.variant.name}
                          </TextWinds>
                        </div>
                        <div className=''>
                          {/* <TextWinds type="content_rel">
                            개수 : {variants.quantity}
                          </TextWinds> */}
                          <TextWinds type="content_rel">
                            {numberWithCommas((options.product.current_price + variants.variant.additional_price)* variants.quantity)}
                          </TextWinds>
                        </div>
                      </div>
                      <CounterNumber
                        selCnt={variants.quantity}
                        maxValue={99}
                        minValue={1}
                        increment={() => handleIncrementCount('option', variants.variant_id, variants.order_item_id, variants.quantity+1)}
                        decrement={() => handleDecrementCount('option', variants.variant_id, variants.order_item_id, variants.quantity-1)}
                      />
                    </div>
                  ))
                ))
              )
            }

            {/* SHOP / BOOSTUP 인 경우 */}
            {
              chosen_variants && (
                chosen_variants.map((variants: OrderItemVariant) => (
                  <div key={variants.variant_id} className="pt-[8px]">
                    <div className='flex flex-row items-center justify-between'>
                      <div className=''>
                        <TextWinds type="content_rel">
                          옵션 : {variants.variant.name}
                        </TextWinds>
                      </div>
                      <div className=''>
                        {/* <TextWinds type="content_rel">
                          개수 : {variants.quantity}
                        </TextWinds> */}
                        <TextWinds type="content_rel">
                          {numberWithCommas((order_item.product.current_price + variants.variant.additional_price)* variants.quantity)} 원
                        </TextWinds>
                      </div>
                    </div>
                    <CounterNumber
                      selCnt={variants.quantity}
                      maxValue={99}
                      minValue={1}
                      increment={() => handleIncrementCount('shop', variants.variant_id, variants.order_item_id, variants.quantity+1)}
                      decrement={() => handleDecrementCount('shop', variants.variant_id, variants.order_item_id, variants.quantity-1)}
                    />
                  </div>
                ))
              )
            }
          </div>
        </div>
        <div className="flex items-center justify-center w-[15vw] h-[15vh] aspect-square shrink-0 bg-gray4 rounded-[4px] ml-[24px]">
          <img
            width={768}
            height={768}
            src={order_item.product.thumbnail.access_url}
            alt={order_item.product.thumbnail.origin_file_name}
            className='object-cover w-full h-full'
          />
        </div>
      </div>
      <div className="flex flex-row justify-between mt-[12px]">
        <div className='flex items-center'>
          <TextWinds type="title_h3" className="flex gap-[5px] items-center">
            {numberWithCommas(getChosenVariantsAmounts(order_item))}
          </TextWinds>
          <TextWinds type="content">원</TextWinds>
        </div>
        <div className="flex gap-[10px]">
          {/* <Buttons type="filled" color="purple5" className="h-[38px]">
            수정하기
          </Buttons> */}
          <Buttons
            type="filled"
            color="purple5"
            className="h-[38px]"
            onClick={() => handleBuySingleItem(order_item)}
          >
            구매하기
          </Buttons>
        </div>
      </div>
    </>
  )
}

export default Purchase_Conditions