import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import Buttons from '../../../components/common/Buttons2';
import { useAppDispatch } from '../../../store/hook';
import {
  closeModal,
  openAlert,
  openModal,
} from '../../../store/features/modalSlice';
import { Button, Input, Option, Select } from '@material-tailwind/react';
import ModalChurch from '../../../components/modal/ModalChurch';
import { churchDataType } from '../../../constants/types/mypage-type';
import instance from '../../../api/axios/utils.ts/instance';
import { MypageContext } from './MypageContext';
import { ChurchPosition } from '../../../enum';

const MypageMembershipChurch = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useContext(MypageContext);
  const [church, setChurch] = useState<churchDataType>(userInfo?.churchInfo);
  const [job, setJob] = useState<ChurchPosition>(userInfo?.churchPosition);
  const jobList = ['담임목회자', '부교역자', '사모', '선교사', '성도'];

  const submitHandler = (data: churchDataType) => {
    setChurch(data);
  };

  const openSearchChurchModal = () => {
    dispatch(
      openModal({ body: <ModalChurch submitHandler={submitHandler} /> })
    );
  };

  const openRegistChurchInfoModal = () => {
    dispatch(openModal({ body: <RegistChurchInfoModal /> }));
  };

  const jobSelectHandler = (job: string) => {
    setJob(job as ChurchPosition);
  };

  const putChurchInfo = async (churchId: string, churchPosition: string) => {
    try {
      const params = {
        churchId: churchId,
        churchPosition: churchPosition,
      };
      const result = await instance.put('/v1/mypage/church-info', params);
      return result.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  const registHandler = () => {
    if (!church) {
      dispatch(openAlert({ message: '교회를 선택해주세요.' }));
      return;
    }
    if (!job) {
      dispatch(openAlert({ message: '직분을 선택해주세요.' }));
      return;
    }
    putChurchInfo(church.id, job)
      .then(() => {
        dispatch(openAlert({ message: '등록이 완료 되었습니다.' }));
        navigate('/mypage/membership/');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="flex flex-col gap-4 shadow-card p-4 rounded-lg max-w-[500px] m-auto">
      <TextWinds type="title_h2">교회 정보 등록</TextWinds>
      <section className="flex flex-col gap-4">
        <div className="relative flex w-full">
          <Input
            type="text"
            label={
              church ? `${church.churchName} ${church.pastorName}` : '교회명'
            }
            readOnly
            onClick={openSearchChurchModal}
            containerProps={{
              className: 'min-w-0',
            }}
          />
          <Button
            size="sm"
            className="!absolute right-1 top-1 rounded bg-purple5"
            onClick={openSearchChurchModal}
          >
            교회 찾기
          </Button>
        </div>
        <div
          className="flex gap-1 items-center cursor-pointer"
          onClick={openRegistChurchInfoModal}
        >
          <img
            src={process.env.PUBLIC_URL + '/svg/icon/info.svg'}
            alt="info_button"
            className="w-4 h-4"
          />
          <TextWinds type="caption1">
            교회찾기에 교회가 나오지 않는 경우
          </TextWinds>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <Select label="직분" onChange={jobSelectHandler} value={job}>
            {jobList.map((job, index) => (
              <Option key={index} value={job}>
                {job}
              </Option>
            ))}
          </Select>
          {/* {job === '기타' && (
            <Input type="text" label={'직분 정보 직접 입력'} />
          )} */}
        </div>
      </section>
      <section>
        <Buttons
          variant="filled"
          color="purple5"
          className="mt-6"
          onClick={registHandler}
        >
          등록하기
        </Buttons>
        <Link to={'/mypage/membership/'}>
          <Buttons variant="outlined" color="purple5" className="mt-6">
            취소
          </Buttons>
        </Link>
      </section>
    </div>
  );
};

const RegistChurchInfoModal = () => {
  const dispatch = useAppDispatch();
  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  const openChurch114 = () => {
    window.open('https://ch114.kr/', '_blank');
  };
  return (
    <div className="flex flex-col gap-4 max-w-[400px] m-auto w-full shadow-card rounded-lg bg-white p-4">
      <TextWinds type="title_h2">교회 정보 등록 안내</TextWinds>
      <TextWinds type="content">
        JEBS에서 제공하는 모든 Teacher 코스는 개신교 보편적 교단에 속한 교회의
        교인만 신청이 가능하며 이를 위해 '교회114' 및 '현대종교'와 협력하고
        있습니다. 교회찾기에서 교회가 검색되지 않는 경우 ‘교회114' 홈페이지에서
        먼저 교회신청을 부탁드립니다. 교회114 승인이 완료된 다음 날 젭스
        교회찾기에 반영됩니다.
      </TextWinds>
      <Buttons
        variant="outlined"
        color="purple5"
        className="text-md"
        onClick={openChurch114}
      >
        교회 114 홈페이지 이동
      </Buttons>
      <Buttons
        variant="filled"
        color="purple5"
        onClick={closeModalHandler}
        className="text-md"
      >
        확인
      </Buttons>
    </div>
  );
};

export default MypageMembershipChurch;
