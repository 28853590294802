import React, { useEffect, useState } from 'react';
import NormalPage from '../../../components/layout/NormalPage';
import TextWinds from '../../../components/common/TextWinds';
import Buttons from '../../../components/common/Buttons';
import IconInfo from './IconInfo';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import instance from '../../../api/axios/utils.ts/instance';
import { useNavigate } from 'react-router-dom';
import { AgreementType } from '../../../components/common/Agreement';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import GuardianInput from './GuardianInput';
import { studentGuardianType } from '../../../constants/types/student-type';
import {
  AgreementBox,
  AgreementObjType,
} from '../../../components/common/AgreementBox';
import { check14UnderAge, logout } from '../../../api/utils/util-func';
import FormRow from '../../../components/common/input/FormRow';
import ErrorInput from '../components/ErrorInput';

export interface studentInfo {
  password: string;
  password2: string;
  birthday: Date;
  englishName: string;
  gender: string;
  agreementServiceUsage: boolean;
  agreementPersonalInfo: boolean;
  guardianInfo?: Omit<studentGuardianType, 'id'>;
}
const StudentFirstLogin = () => {
  const dispatch = useAppDispatch();

  const methods = useForm<studentInfo>();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const password = watch('password');
  const birthday = watch('birthday');
  const [agreement, setAgreement] = useState<boolean>();
  const [isMinor, setIsMinor] = useState<boolean>(false);

  const agreementList: AgreementObjType[] = [
    { agree: AgreementType.TERAMS_CONDITION, essential: true },
    { agree: AgreementType.PERSONAL_COLLECTION, essential: true },
    { agree: AgreementType.PROMOTION, essential: false },
  ];

  useEffect(() => {
    if (birthday) {
      setIsMinor(check14UnderAge(birthday));
    }
  }, [birthday]);

  const putStudentFirstSetup = async (data: studentInfo) => {
    try {
      const res = await instance.put('/v1/user/student/first-login', data);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit: SubmitHandler<studentInfo> = (data) => {
    if (!agreement) {
      dispatch(openAlert({ message: '필수 약관에 동의해주세요' }));
      return;
    }

    const params: studentInfo = {
      ...data,
      englishName: data.englishName.toUpperCase(),
      agreementPersonalInfo: agreement,
      agreementServiceUsage: agreement,
    };

    if (isMinor) {
      let msg = '';
      if (!data.guardianInfo.name) {
        msg = '보호자 이름을 입력해주세요.';
      } else if (!data.guardianInfo.birthday) {
        msg = '보호자 생년월일을 입력해주세요.';
      } else if (!data.guardianInfo.gender) {
        msg = '보호자 성별을 입력해주세요.';
      } else if (!data.guardianInfo.phone) {
        msg = '보호자 연락처를 인증해주세요.';
      } else if (!data.guardianInfo.agreementPersonalInfo) {
        msg = '보호자 약관에 동의해주세요.';
      }

      if (msg) {
        dispatch(openAlert({ message: msg }));
        return;
      }

      params.guardianInfo.countryCode = '82';
    } else {
      delete params.guardianInfo;
    }

    putStudentFirstSetup(params)
      .then((res) => {
        if (res.status === 200) {
          alert('입력이 완료 되었습니다. 다시 로그인 해주세요.');
          logout();
        } else {
        }
      })
      .catch(() => {
        dispatch(
          openAlert({ message: '입력이 실패했습니다. 다시 시도해주세요' })
        );
      });
  };

  return (
    <NormalPage bgColor="white" xlBgColor="gray0">
      <div className="place-items-center pb-5 xl:w-[600px] xl:mx-auto xl:pt-8">
        <div className="bg-white xl:p-10">
          <FormProvider {...methods}>
            <form>
              <section>
                <TextWinds type="title_h1">학생 정보 입력 (최초 1회)</TextWinds>
                <TextWinds type="content" className="mt-4">
                  학생 정보는 이후 내 정보에서 수정 가능합니다.
                </TextWinds>
              </section>

              <section className="flex flex-col gap-4 mt-8">
                <div>
                  <TextWinds type={'content_body2'}>새 비밀번호 설정</TextWinds>
                  <FormRow
                    control={control}
                    name="password"
                    type="password"
                    placeholder="영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다."
                    rules={{
                      minLength: {
                        value: 8,
                        message: '8글자 이상 입력해주세요.',
                      },
                      required: '새 비밀번호를 입력해주세요',
                      pattern: {
                        value:
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
                        message:
                          '영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
                      },
                    }}
                  />
                  <div className="h-2">
                    {errors['password'] && (
                      <ErrorInput text={errors['password'].message} />
                    )}
                  </div>
                </div>

                <div>
                  <TextWinds type={'content_body2'}>새 비밀번호 확인</TextWinds>
                  <FormRow
                    control={control}
                    name="password2"
                    type="password"
                    placeholder="영문 대소문자, 숫자, 특수문자를 포함 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다."
                    rules={{
                      required: '새 비밀번호 확인을 입력해주세요',
                      pattern: {
                        value:
                          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
                        message:
                          '영문 대소문자, 숫자, 특수문자를 포함 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
                      },
                      minLength: {
                        value: 8,
                        message: '8글자 이상 입력해주세요.',
                      },
                      validate: (value) => {
                        return (
                          value === password || '비밀번호가 일치하지 않습니다.'
                        );
                      },
                    }}
                  />
                  <div className="h-2">
                    {errors.password2 && (
                      <ErrorInput text={errors.password2.message} />
                    )}
                  </div>
                </div>

                <div>
                  <TextWinds type={'content_body2'}>영문명</TextWinds>
                  <FormRow
                    control={control}
                    name="englishName"
                    type="text"
                    className="uppercase"
                    placeholder="영문명을 입력해주세요"
                    rules={{
                      required: '영문명을 입력해주세요',
                      pattern: {
                        value: /^[a-zA-Z ]+$/,
                        message: '영문 대문자만 입력해주세요',
                      },
                    }}
                  />
                  <div className="h-2">
                    {errors.englishName && (
                      <ErrorInput text={errors.englishName.message} />
                    )}
                  </div>
                </div>

                <div>
                  <div>
                    <TextWinds type="title_h5">생년월일 및 성별</TextWinds>
                    <div className="grid grid-cols-2 gap-2">
                      <FormRow
                        control={control}
                        name="birthday"
                        type="date"
                        placeholder="생년월일을 입력해주세요"
                        rules={{
                          required: '생년월일을 입력해주세요',
                        }}
                      />
                      <FormRow
                        control={control}
                        name="gender"
                        type="gender"
                        placeholder="성별"
                        rules={{
                          required: '성별을 선택해주세요',
                        }}
                      />
                    </div>
                  </div>
                  {errors.birthday && (
                    <ErrorInput text={errors.birthday.message} />
                  )}
                  {errors.gender && <ErrorInput text={errors.gender.message} />}
                </div>

                <div className="w-[326px] h-6 justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative">
                    <IconInfo />
                  </div>
                  <div className="text-blue-500 text-sm font-medium font-['Pretendard'] leading-snug">
                    만 14세 미만의 어린이는 보호자의 동의가 필요합니다.
                  </div>
                </div>
              </section>

              <section className="flex flex-col gap-2 py-5">
                <AgreementBox
                  agreementList={agreementList}
                  essentialChecked={setAgreement}
                />
              </section>

              {isMinor && (
                <section>
                  <GuardianInput
                    control={control}
                    setAgree={(agree) =>
                      setValue('guardianInfo.agreementPersonalInfo', agree)
                    }
                  />
                </section>
              )}

              <section className="flex flex-col gap-[8px] mt-8">
                <div className="">
                  <Buttons
                    onClick={handleSubmit(onSubmit)}
                    type={'filled'}
                    color={'purple5'}
                  >
                    저장
                  </Buttons>
                </div>
                <div>
                  <Buttons
                    type={'outlined'}
                    color={'purple5'}
                    onClick={() => logout()}
                  >
                    취소
                  </Buttons>
                </div>
              </section>
            </form>
          </FormProvider>
        </div>
      </div>
    </NormalPage>
  );
};

export default StudentFirstLogin;
