import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface HFinputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  register?: UseFormRegisterReturn;
  error?: string;
  height?:number;
}

const HFinput = ({
  className,
  register,
  label,
  error,
  height=50,
  ...rest
}: HFinputProps) => {
  const py = (height-26)/2;
  const size = `w-full h-auto px-[10px] py-[${py}px] text-md mb-6`;
  const design = 'bg-white rounded transition-colors';
  const text = 'overflow-hidden text-ellipsis';
  const border = 'border border-gray5 focus:outline-none focus:border-purple5';
  return (
    <div className="relative w-full">
      <div className={`text-sm mb-2 leading-snug ${!label && 'hidden'}`}>
        {label}
      </div>
      <input
        type="text"
        className={`${size} ${design} ${border} ${text} ${
          error ? 'border-red-500' : ''
        }
        ${className}`}
        {...rest}
        {...register}
      />

      {error && (
        <div className="absolute left-0 bottom-1 text-red-500 text-xs">
          {error}
        </div>
      )}
    </div>
  );
};

export default HFinput;
