import React, { useEffect, useMemo } from 'react';
import HFbutton from '../../../components/common/input/HFbutton';
import { useAppDispatch } from '../../../store/hook';
import { closeModal } from '../../../store/features/modalSlice';
import TextWinds from '../../../components/common/TextWinds';

const MigrationGuideModal = () => {
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const doNotShowAgainHandler = () => {
    localStorage.setItem('migrationGuideModalShown', 'false');
    closeHandler();
  };

  const keyHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeHandler();
    }
  };

  useEffect(() => {
    // esc 키로 모달 닫기
    window.addEventListener('keydown', keyHandler);
  }, []);

  return (
    <div className="flex flex-col gap-1 p-1 max-w-[480px] w-full bg-white  rounded mt-20 xl:mt-0 mx-2">
      <section className="flex flex-col gap-3 p-2">
        <TextWinds type="title_h2">구버전 학생 가져오기 안내</TextWinds>
        <ol className="flex flex-col gap-1 list-decimal pl-5">
          <li>
            <span className="bg-red5 text-white text-sm p-1.5 rounded font-bold">
              구 버전 연동하기
            </span>{' '}
            기능을 사용하여, 구사이트(jebs2.kr)의 학생을 가져올 수 있습니다.
          </li>
          <li>
            연동하지 않고 학생을 새로 등록하면, 구사이트의 인증제 내역을 가져올
            수 없습니다.
          </li>
          <li>
            구 사이트(jebs2.kr)를 이용하지 않으셨다면 학생 연동이 불필요하며
            적용되지 않습니다.
          </li>
        </ol>

        <TextWinds
          type="content_body2"
          className="text-center text-purple5 font-bold hover:text-purple7"
        >
          <a
            href="https://jebs-notice.oopy.io/45f2e7dd-6fd7-47d8-9f13-f2dbf25aa25f"
            target="_blank"
          >
            {`<학생 연동 자세히 보기>`}
          </a>
        </TextWinds>
      </section>
      <section className="flex gap-3 p-2">
        <HFbutton type="Outlined" onClick={doNotShowAgainHandler}>
          다시 보지 않기
        </HFbutton>
        <HFbutton onClick={closeHandler}>닫기</HFbutton>
      </section>
    </div>
  );
};

export default MigrationGuideModal;
