import Buttons from "../../components/common/Buttons2";
import TextWinds from "../../components/common/TextWinds";

interface MoLayoutProps {
    type?: "todo";
    title: string;
    text1?: string;
    text2?: string;
    isButtonVisible?: boolean;
    isButtonVisible2?: boolean;
    handleButtonClick?: () => void;
    btnTitle?: string;
    btnClassName?: string;
    handleButtonClick2?: () => void;
    btnTitle2?: string;
    btnClassName2?: string;
}

const MoLayout: React.FC<MoLayoutProps> = ({
    type,
    title,
    text1,
    text2,
    isButtonVisible,
    handleButtonClick,
    btnTitle,
    btnClassName,
    isButtonVisible2,
    handleButtonClick2,
    btnTitle2,
    btnClassName2,
}) => {
    if (type === "todo") {
        return (
            <section className="xl:hidden">
                <div className="flex flex-col gap-y-[2px] px-5 py-3 bg-gray1 shadow-none">
                    <TextWinds type="title_h3">{title}</TextWinds>
                    <TextWinds type="caption1">
                        {text1}
                        <br />
                        {text2}
                    </TextWinds>
                </div>
            </section>
        );
    }

    return (
        <section className="xl:hidden">
            <div className="flex justify-between items-center px-5 py-3 bg-gray1 shadow-card">
                <TextWinds type="title_h3">{title}</TextWinds>
                {isButtonVisible ? (
                    <div className="flex gap-x-3">
                        <Buttons
                            variant="filled"
                            color="purple5"
                            onClick={handleButtonClick}
                            type="secondary"
                            className={btnClassName}
                        >
                            <TextWinds type="button2" color="white">
                                {btnTitle}
                            </TextWinds>
                        </Buttons>
                        {isButtonVisible2 ? (
                            <Buttons
                                variant="outlined"
                                color="purple5"
                                onClick={handleButtonClick2}
                                type="secondary"
                                className={btnClassName2}
                            >
                                <TextWinds type="button2" color="purple5" className="whitespace-nowrap">
                                    {btnTitle2}
                                </TextWinds>
                            </Buttons>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default MoLayout;
