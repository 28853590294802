import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    Collapse,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Typography,
} from "@material-tailwind/react";
import React, { Fragment, useState } from "react";

interface SubMenuProps {
    title: string;
    url: string;
    blank?: boolean;
}
interface NavListProps {
    text: string;
    obj: SubMenuProps[];
}
function NavListMenu({ text, obj }: NavListProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const renderItems = obj.map(({ title, url, blank }: SubMenuProps, key) => (
        <a href={url} key={key} target={blank&&'_blank'}>
            <MenuItem className="flex items-center gap-3 rounded-lg">
                <div>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="flex items-center text-sm font-medium"
                    >
                        {title}
                    </Typography>
                </div>
            </MenuItem>
        </a>
    ));

    return (
        <Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            {text}
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className={`grid grid-cols-[${obj.length}] gap-y-2 outline-none outline-0`}>
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </Fragment>
    );
}

const StudentNavList = () => {    
    return (
        <List className="flex flex-col gap-2 mt-2 mb-2 lg:mt-0 lg:mb-0 lg:flex-row lg:items-center">
            <Typography
                as="a"
                href="/student/class"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">내 학습</ListItem>
            </Typography>
            <Typography
                as="a"
                href="/student/badge"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">획득 뱃지</ListItem>
            </Typography>
            <Typography
                as="a"
                href="/student/certification"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">인증제 현황</ListItem>
            </Typography>
        </List>
    );
};

export default StudentNavList;
