import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  orderList,
  orderDetailType,
} from '../../../constants/types/mypage-type';
import PurchaseItem from './PurchaseItem';
import { useAppDispatch } from '../../../store/hook';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';

interface PurchaseListProps {
  activeTab: string;
  startDate: Date;
  endDate: Date;
}
const PurchaseList = ({ activeTab, startDate, endDate }: PurchaseListProps) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [orderList, setOrderList] = useState<orderDetailType[]>([]);
  const observer = useRef<IntersectionObserver>();
  const [hasMore, setHasMore] = useState(true);

  const loadMoreItems = useCallback(
    async (page: number) => {
      const newItems = await getMyOrders(page);
      setOrderList((prev) => [...prev, ...newItems]);
      setPage(page + 1);

      if (newItems.length === 0) {
        setHasMore(false);
      }
    },
    [startDate, endDate, activeTab]
  );

  const lastItemRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreItems(page);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, page, loadMoreItems]
  );

  const getMyOrders = async (page: number) => {
    try {
      const params = {
        page: page,
        pageSize: 10,
        status: activeTab,
        startDate,
        endDate,
      };
      const url = '/v1/mypage/orders';
      const response = await instance.get<ApiResponse<orderList>>(url, {
        params,
      });
      return response.data.data.result;
    } catch (error) {
      console.error('[axios] getOrdererInfo : ', error);
    }
  };

  useEffect(() => {
    setOrderList([]);
    setHasMore(true);
    loadMoreItems(1);
  }, [activeTab, startDate, endDate]);

  return orderList === undefined || orderList.length === 0 ? (
    <section>
      <div className="flex justify-center items-center h-[200px]">
        <p className="text-[14px] text-gray5">구매 내역이 없습니다.</p>
      </div>
    </section>
  ) : (
    <section className="flex flex-col gap-3">
      {orderList.map((order: orderDetailType, index) => (
        <PurchaseItem
          key={order.id}
          props={order}
          lastItemRef={index === orderList.length - 1 ? lastItemRef : undefined}
        />
      ))}
    </section>
  );
};

export default PurchaseList;
