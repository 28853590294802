import React from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { LmsVoucherProps } from '../../../constants/types';
import Buttons from '../../../components/common/Buttons2';
import { useAppDispatch } from '../../../store/hook';

interface TodoTicketStatusProps {
  voucherLists: LmsVoucherProps[];
  purchaseTicket: (data: any) => void;
}
const TodoTicketStatus: React.FC<TodoTicketStatusProps> = ({
  voucherLists,
  purchaseTicket,
}) => {
  const getTicketName = (type: string) => {
    switch (type) {
      case 'RECITATION':
        return '인증제 이용권';
      case 'HJ':
        return '젭스플 HJ 이용권';
      case 'HF':
        return '젭스플 HF 이용권';
      case 'WMW':
        return '젭스플 WMW 이용권';
      case 'JS1':
        return '젭스플 JS1 이용권';
      case 'JS2':
        return '젭스플 JS2 이용권';
      default:
        return type;
    }
  };

  return (
    <div className="flex flex-col border-t border-b border-gray5 w-full min-w-fit border-none">
      {voucherLists &&
        voucherLists.map((row: any) => (
          <div
            key={row.id}
            className="flex items-center justify-start border-t border-gray4 first:border-t-0 p-2 mx-2"
          >
            <div className="flex flex-row gap-2">
              <img
                src={process.env.PUBLIC_URL + '/svg/icon/sidebar-gray.svg'}
                alt="ticket"
              />
              <span className="text-black">
                {getTicketName(row.name)}
                <span className="text-blue-500 px-2">{row.voucherCount}</span>개
              </span>
            </div>
            <div className="ml-auto">
              <Buttons
                variant="filled"
                type="text"
                color="purple5"
                className="h-[38px] w-[69px]"
                onClick={() => purchaseTicket(row)}
              >
                <TextWinds
                  type="button_text1"
                  color="white"
                  className="font-bold"
                >
                  구매하기
                </TextWinds>
              </Buttons>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TodoTicketStatus;
