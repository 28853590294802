import { className } from "@material-tailwind/react/types/components/typography";
import React from "react";

interface TextProps {
    type:
        | "title_h1"
        | "title_h2"
        | "title_h3"
        | "title_h4"
        | "title_h5"
        | "title_h6"
        | "content"
        | "content_bold"
        | "content_body1"
        | "content_body2"
        | "content_body3"
        | "content_body4"
        | "content_body5"
        | "content_rel"
        | "sub_title01"
        | "chips"
        | "caption1"
        | "caption2"
        | "caption3"
        | "caption4"
        | "button"
        | "button2"
        | "button_text1";
    xlType?:
        | "title_h1"
        | "title_h2"
        | "title_h3"
        | "title_h4"
        | "title_h5"
        | "title_h6"
        | "content"
        | "content_bold"
        | "content_body1"
        | "content_body2"
        | "content_body3"
        | "content_body4"
        | "content_body5"
        | "content_rel"
        | "sub_title01"
        | "chips"
        | "caption1"
        | "caption2"
        | "caption3"
        | "caption4"
        | "button"
        | "button2"
        | "button_text1";
    color?: string;
    onClick?: () => void;
    children?: React.ReactNode;
    className?: className;
}

export default function TextWinds({
    type,
    xlType,
    children,
    color = "black",
    onClick,
    className,
}: TextProps) {
    const Tag = {
        title_h1: "p",
        title_h2: "p",
        title_h3: "p",
        title_h4: "p",
        title_h5: "p",
        title_h6: "p",
        content: "p",
        content_bold: "p",
        content_body1: "p",
        content_body2: "p",
        content_body3: "p",
        content_body4: "p",
        content_body5: "p",
        content_rel: "p",
        sub_title01: "p",
        button: "span",
        button2: "span",
        button_text1: "span",
        chips: "span",
        caption1: "p",
        caption2: "span",
        caption3: "span",
        caption4: "span",
    }[type] as keyof JSX.IntrinsicElements;

    const classNames = `text-${type} text-${color} ${
        xlType ? "xl:text-" + xlType : ""
    } ${className}`;

    return (
        <Tag className={classNames} onClick={onClick}>
            {children}
        </Tag>
    );
}
