import { CreateTypes } from 'canvas-confetti';
import React, { Component, forwardRef, useImperativeHandle } from 'react';
import ReactCanvasConfetti from '../../../common/ReactCanvasConfetti';

interface ConfettiProps {}

export interface ConfettiRef {
  startFire: () => void;
}

const Confetti = forwardRef<ConfettiRef, ConfettiProps>((props, ref) => {
  const animationInstance = React.useRef<CreateTypes | null>(null);
  const isAnimationEnabled = React.useRef(false);

  const makeShot = (particleRatio: number, opts: object) => {
    animationInstance.current &&
      animationInstance.current({
        ...opts,
        origin: { x: 0.5, y: 1 },
        particleCount: Math.floor(200 * particleRatio),
      });
  };

  const fire = () => {
    const w = window.screen.width;
    const h = window.screen.height;
    const power = (w * h) / 8000;
    makeShot(0.25, {
      spread: 70,
      startVelocity: power,
      colors: ['#ffd254', '#fff', '#d5a444'],
    });
  };

  const handlerFire = () => {
    if (!isAnimationEnabled.current) {
      isAnimationEnabled.current = true;
    }
    requestAnimationFrame(fire);
    fire();
  };

  useImperativeHandle(ref, () => ({
    startFire: handlerFire,
  }));

  const getInstance = (instance: CreateTypes | null) => {
    animationInstance.current = instance;
  };

  return (
    <ReactCanvasConfetti
      refConfetti={getInstance}
      className="canvas h-full w-full pointer-events-none"
    />
  );
});

export default Confetti;
