import React from 'react';
import ResponseTypo from '../../../components/ResponseTypo';
import { HalfLayout } from '../../../components/layout/HalfLayout';
import ReactPlayer from 'react-player';

export const StoryIntro = () => {
  return (
    <section className="bg-gradient-to-b from-spotLilac/[0.1] to-white p-5 lg:py-14">
      <div className="lg:m-auto lg:max-w-[1024px]">
        <ResponseTypo variant_lg="h4" variant_sm="h5" className="font-bold">
          젭스 Story
        </ResponseTypo>
        <HalfLayout
          className="lg:m-auto lg:max-w-[1024px]"
          left={
            <>
              <ResponseTypo
                variant_lg="h1"
                variant_sm="h4"
                className="gradient-purple mt-6 font-bold"
              >
                Speak n Spread
              </ResponseTypo>

              <ResponseTypo
                variant_lg="h5"
                variant_sm="h5"
                className="text-spotViolet mt-4 font-bold"
              >
                영어로 말씀을 암송한다는 것,
                <br />
                복음을 전한다는 것!
              </ResponseTypo>

              <ResponseTypo
                variant_lg="small"
                variant_sm="small"
                className="mt-4"
              >
                우리가 ‘영어’를 배우는 진짜 이유
                <br />
                하나님이 우리에게 주신 지상명령을 실현하기 위해
                <br />
                어디서든 복음을 전할 수 있도록 필수 언어인 영어를 배웁니다.
              </ResponseTypo>
            </>
          }
          right={
            <ReactPlayer
            style={{aspectRatio: '16/9'}}
            width={'100%'}
            height={'auto'}
              url={'https://youtu.be/TkvW6vOr_fU?si=nhIL0GG0C246lsgK'}
            />
          }
        />
      </div>
    </section>
  );
};
