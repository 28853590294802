import React, { useContext, useState } from 'react';
import { SignupContext } from './SignupContext';
import { phoneFormat } from '../../../api/utils/format/numbers';
import TextWinds from '../../../components/common/TextWinds';
import { useAppDispatch } from '../../../store/hook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { User } from '../../../constants/types';
import { UserSnsType } from '../../../enum';
import { studentGuardianType } from '../../../constants/types/student-type';
import { useForm } from 'react-hook-form';
import ErrorInput from '../../student/components/ErrorInput';
import instance from '../../../api/axios/utils.ts/instance';
import { openAlert } from '../../../store/features/modalSlice';
import { check14UnderAge, uidRegExp } from '../../../api/utils/util-func';
import GuadianForm from './GuadianForm';
import { Input } from 'antd';
import HFbutton from '../../../components/common/input/HFbutton';
import FormRow from '../../../components/common/input/FormRow';
export type UserDTO = Pick<
  User,
  'uid' | 'email' | 'password' | 'name' | 'phone' | 'birthday' | 'gender'
> & {
  password2: string;
  snsType: UserSnsType;
  snsCode?: string;
  countryCode: string;
  agreementServiceUsage: boolean;
  agreementPersonalInfo: boolean;
  agreementLocationalInfo: boolean;
  guardianInfo: studentGuardianType;
};
const InfoForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { phone, step, setStep } = useContext(SignupContext);
  const [checkDuplicate, setCheckDuplicate] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserDTO>();
  const password = watch('password');
  const uid = watch('uid');

  const sns_code = searchParams.get('sns_code');
  const sns_type = searchParams.get('sns_type');

  const onSubmit = (data: UserDTO) => {
    if (!checkDuplicate) {
      dispatch(openAlert({ message: '아이디 중복확인을 해주세요.' }));
      return;
    }

    if (check14UnderAge(data.birthday)) {
      if (!data.guardianInfo) {
        dispatch(openAlert({ message: '보호자 정보를 입력해주세요.' }));

        return;
      } else if (
        !data.guardianInfo.name ||
        !data.guardianInfo.gender ||
        !data.guardianInfo.birthday
      ) {
        dispatch(openAlert({ message: '보호자 정보를 입력해주세요.' }));
        return;
      } else if (!data.guardianInfo.phone) {
        dispatch(openAlert({ message: '보호자 연락처를 인증받아 주세요' }));
        return;
      }
    }

    const packet: UserDTO = {
      ...data,
      countryCode: '82',
      phone: phone,
      agreementLocationalInfo: true,
      agreementPersonalInfo: true,
      agreementServiceUsage: true,
      guardianInfo: check14UnderAge(data.birthday)
        ? data.guardianInfo
        : undefined,
    };

    if (sns_code && sns_type) {
      packet.snsCode = sns_code;
      packet.snsType = sns_type as UserSnsType;
    } else {
      packet.snsType = UserSnsType.DEFAULT;
    }

    postSignUp(packet);
  };

  const postSignUp = async (data: UserDTO) => {
    try {
      const response = await instance.post('/v1/auth/sign-up', data);
      const result = response.data;
      if (result.statusCode === 200) {
        setStep(step + 1);
      } else {
        dispatch(openAlert({ message: result.message }));
      }
    } catch (error) {}
  };

  // 아이디 중복확인
  const getIdCheck = async () => {
    try {
      const response = await instance.get('/v1/user/uid-check', {
        params: {
          uid: uid,
        },
      });
      const data = response.data;
      if (data.statusCode === 200) {
        if (data.data.existUser === false) {
          setCheckDuplicate(true);
          dispatch(openAlert({ message: '사용 가능한 아이디입니다.' }));
        } else {
          setCheckDuplicate(false);
          dispatch(openAlert({ message: '이미 사용중인 아이디입니다.' }));
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <form className="flex flex-col gap-4">
      <section>
        <TextWinds type="title_h2">가입 정보 입력</TextWinds>
      </section>
      <section className="flex flex-col gap-2">
        <TextWinds type="caption1" children="전화번호" />
        <Input
          size="large"
          placeholder="전화번호"
          value={phoneFormat(phone)}
          disabled
        />

        <FormRow
          type="text-button"
          name="uid"
          className="p-3"
          rules={{
            required: '아이디를 입력해주세요.',
            minLength: {
              value: 5,
              message: '아이디는 5자 이상으로 입력해주세요.',
            },
            maxLength: {
              value: 20,
              message: '아이디는 20자 이하로 입력해주세요.',
            },
            pattern: {
              value: uidRegExp,
              message:
                '아이디는 영문자로 시작해야 하며, 영문자와 숫자, _로 구성될 수 있습니다. 이외 특수문자는 사용할 수 없습니다. 또는 이메일 형식이어야 합니다',
            },
          }}
          placeholder="아이디"
          control={control}
          error={!!errors.uid}
          textButtonProp={{ text: '중복확인', action: getIdCheck }}
        />
        {errors.uid && <ErrorInput text={errors.uid.message} />}

        <FormRow
          type="text"
          name="email"
          rules={{
            required: '이메일을 입력해주세요.',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: '이메일 형식이 아닙니다.',
            },
          }}
          placeholder="email"
          control={control}
          error={!!errors.email}
        />
        {errors.email && <ErrorInput text={errors.email.message} />}

        <FormRow
          type="password"
          name="password"
          rules={{
            required: '비밀번호를 입력해주세요.',
            pattern: {
              value:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
              message:
                '영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
            },
          }}
          placeholder="비밀번호"
          control={control}
          error={!!errors.password}
        />
        {errors.password && <ErrorInput text={errors.password.message} />}

        <FormRow
          type="password"
          name="password2"
          rules={{
            required: '비밀번호 확인을 입력해주세요.',
            validate: (value) =>
              value === password || '비밀번호가 일치하지 않습니다.',
          }}
          placeholder="비밀번호"
          control={control}
          error={!!errors.password2}
        />
        {errors.password2 && <ErrorInput text={errors.password2.message} />}

        <FormRow
          type="text"
          name="name"
          rules={{
            required: '이름을 입력해주세요.',
            pattern: {
              value: /^[가-힣]{2,20}$/,
              message: '한글 2 ~ 20 글자로 입력해주세요.',
            },
          }}
          placeholder="이름"
          control={control}
          error={!!errors.name}
        />
        {errors.name && <ErrorInput text={errors.name.message} />}

        <FormRow
          name={'birthday'}
          type={'date'}
          rules={{ required: '생년월일을 입력해주세요.' }}
          control={control}
          placeholder={'생년월일'}
          error={!!errors.birthday}
        />
        {errors.birthday && <ErrorInput text={errors.birthday.message} />}

        <FormRow
          name="gender"
          type={'gender'}
          control={control}
          rules={{ required: '성별을 선택해주세요.' }}
        />
        {errors.gender && <ErrorInput text={errors.gender.message} />}
      </section>
      {check14UnderAge(watch('birthday')) && (
        <section>
          <GuadianForm control={control} />
        </section>
      )}
      <section className="flex flex-col gap-2">
        <HFbutton onClick={() => handleSubmit(onSubmit)()}>가입하기</HFbutton>
        <HFbutton type="Outlined" onClick={() => navigate('/signin')}>
          취소
        </HFbutton>
      </section>
    </form>
  );
};

export default InfoForm;
