import React from 'react';
import TextWinds from '../common/TextWinds';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const CourseNaviBar = () => {
  return (
    <div className="relative flex items-center justify-between w-full px-[20px] py-[26px] rounded-none bg-gradient-to-r from-gradient_f to-gradient_t xl:bg-spotLilac xl:from-transparent xl:to-transparent xl:py[50px] xl:px-[72px] xl:mb-[100px] xl:justify-between">
      <div className="relative inline-flex flex-col items-start flex-auto hidden xl:block">
        <TextWinds type="title_h1" color="white">
          젭스의 전체 코스
        </TextWinds>
        <TextWinds type="content_body4" color="white" className="pt-[10px]">
          홈스쿨부터 주일학교, NIV학습까지!
          <br />
          나에게 필요한 젭스 코스가 무엇인지 확인해 보세요.
        </TextWinds>
      </div>
      <div className="relative inline-flex flex-col items-end justify-end w-full xl:w-[280px] cursor-pointer">
        <div className="relative flex items-start self-stretch justify-between flex-auto  py-[14px] px-[12px] bg-white rounded-md xl:p-[24px] ">
          <div className="relative ">
            <Link to={'/courses'}>
              <TextWinds type="content_bold">
                젭스의 다른 코스를 보고 싶다면?
              </TextWinds>
              <div className="inline-flex flex-auto items-center gap-[2px]]">
                <TextWinds type="content_bold" color="purple5">
                  코스 찾기 이동
                </TextWinds>
                <ArrowRightIcon className="w-[20px] h-[20px]" color="purple" />
              </div>
            </Link>
          </div>
          <Link to={'/courses'}>
            <img
              className="w-[52px] h-[52px]"
              src={process.env.PUBLIC_URL + '/images/course/findcourse.png'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseNaviBar;
