import { useAppDispatch, useAppSelector } from '../store/hook';
import { getMyAuth, selectMyInfo } from '../store/features/mySlice';
import { useNavigate } from 'react-router';

const useUserHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectMyInfo);

  const refresh = async (): Promise<boolean> => {
    const data = await dispatch(getMyAuth({ url: '' }));
    if (!data.payload) return false;
    return true;
  };

  const checkAndPageMove = (url: string) => {
    localStorage.setItem('redirect', url);
    refresh().then((isLogin) => {
      if (isLogin) {
        localStorage.removeItem('redirect');
        navigate(url);
        return;
      }
    });
  };

  const checkWithCallback = (callback: Function) => {
    refresh().then((isLogin) => {
      if (isLogin) {
        callback();
        return;
      }
    });
  };

  return {
    user,
    checkAndPageMove,
    checkWithCallback,
  };
};

export default useUserHook;
