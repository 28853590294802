import React from 'react';
import { useParams } from 'react-router-dom';
import HomeschoolMain from './homeschool/HomeschoolMain';
import WmwMain from './churchschool/wmw/WmwMain';
import HFHJMain from './churchschool/hfhj/HFHJMain';

const CourseDetail = () => {
  const productSubType = useParams().productSubType;

  switch (productSubType) {
    case '4b38faf7-3929-4356-b394-ca48f6b0b67c':
      return <HomeschoolMain />;
    case 'bd74a9c7-516b-4356-b8da-ab9412c7fe0e':
      return <WmwMain />;
    case 'fb4d113c-72b0-482a-aadf-4f0155c460cf':
      return <HFHJMain />;
    default:
      return <>잘못된 접속입니다.</>;
  }
};

export default CourseDetail;
