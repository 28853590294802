import React from 'react';
import WarningSection from '../WarningSection';
import JepsplSection from '../JepsplSection';

const HFHJSection11 = () => {
  return (
    <>
      <WarningSection />
      <JepsplSection />
    </>
  );
};

export default HFHJSection11;
