import React from 'react';
import { motion } from 'framer-motion';
import { openNewWindow } from '../../../../api/utils/util-func';
import {
  NeumophismAccordion,
  CircleRightArrowIcon,
  CircleCheckIcon,
  ListItem,
} from '../wmw/WmwComp';
import hjbook from './img/sec01-hjbook.png';
import HJ1_1 from './img/sec08-HJ-1-1.png';
import HJ1_2 from './img/sec08-HJ-1-2.png';
import HJ2 from './img/sec08-HJ-2.png';
import HJ3_1 from './img/sec08-HJ-3-1.png';
import HJ3_2 from './img/sec08-HJ-3-2.png';

const HJ_BOOK_URL = 'https://teach.jebs2.kr/media/PPTX/ASPN/hj/';
const HJ_TEACHER_MANUAL_URL =
  'https://drive.google.com/file/d/1nNPWPMjZuHXq449fp2SWRlt4I76uFud4/view';

const HFHJSection8b = () => {
  return (
    <div className="bg-[#F9F9F9] flex flex-col justify-center items-center p-3 pb-10 md:p-12 gap-5 md:gap-10 ">
      <div className="w-full flex flex-col justify-center items-center gap-5 md:gap-12">
        {/* 주황박스 컨텐츠 */}
        <div className="flex justify-center rounded-2xl bg-[#FFA319] p-3 w-full">
          <div className="flex flex-col justify-center items-center gap-5 md:gap-10 max-w-[520px]">
            {/* 타이틀 */}
            <div
              className="rounded-full border border-black w-fit px-5 py-2 
            text-xl md:text-3xl font-bold text-black mt-10"
            >
              Hello, Jesus!
            </div>

            <div className="text-white text-xl md:text-3xl font-bold font-['Pretendard']">
              영어로 배우는{' '}
              <span className="text-black">신약의 8가지 사건!</span>
            </div>

            <div className="text-white text-sm md:text-lg font-normal font-['Pretendard'] text-center">
              탄생하신 예수님, 사탄을 이기시는 예수님,
              <br className="md:hidden" />
              만물의 왕이신 예수님,
              <br className="hidden md:block" />
              병자를 고치시는 예수님,
              <br className="md:hidden" />
              기적을 베푸신 예수님, 생명을 살리신 예수님,
              <br />
              구원자 되신 예수님, 부활하신{' '}
              <span className="text-black font-bold">
                예수님을 알아가며 <br />
                일상의 영어 표현을 말하는 기반
              </span>
              을 닦습니다.
            </div>

            {/* 그리드 영역 */}
            <section className="grid grid-cols-2 gap-3 md:gap-5 mb-5 md:mb-10">
              {/* 박스 1 */}
              <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                <div className="relative aspect-[4/3] w-full">
                  <img
                    src={HJ1_1}
                    alt="hj1_1"
                    className="absolute top-0 left-0 w-[70%] z-10"
                  />
                  <img
                    src={HJ1_2}
                    alt="hj1_2"
                    className="absolute bottom-0 right-0 w-[70%] z-0"
                  />
                </div>
                <div className="flex-1 flex flex-col justify-center items-center">
                  <div className="text-center text-xs md:text-lg font-medium font-['Pretendard']">
                    <span className="text-[#FE7020] font-semibold">
                      재미있는 3박자 문장의
                      <br />
                      애니메이션과 노래
                    </span>
                    로
                    <br />
                    아이들의 시선 집중
                  </div>
                </div>
              </div>

              {/* 박스 2 */}
              <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                <img src={HJ2} alt="hj2" className="w-[65%] mx-auto" />
                <div className="flex-1 flex flex-col justify-center items-center">
                  <div className="text-center text-xs md:text-lg font-medium font-['Pretendard']">
                    <span className="text-[#FE7020] font-semibold">
                      단어와 문장 쓰기
                    </span>
                    로<br />
                    기초에서 심화로!
                  </div>
                </div>
              </div>

              {/* 박스 3 */}
              <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                <div className="relative aspect-[4/3] w-full mb-5">
                  <img
                    src={HJ3_1}
                    alt="hj3_1"
                    className="absolute top-0 left-0 w-[70%]"
                  />
                  <img
                    src={HJ3_2}
                    alt="hj3_2"
                    className="absolute -bottom-5 right-0 w-[70%]"
                  />
                </div>
                <div
                  className="flex-1 flex flex-col justify-center items-center
                    text-center text-xs md:text-lg font-medium font-['Pretendard'] whitespace-nowrap"
                >
                  <div>
                    <span className="text-[#FE7020] font-semibold">
                      그림 퍼즐을 맞추기와
                      <br /> 게임과 그리기
                    </span>
                    등의
                    <br />
                    추가 활동을 통해
                    <br />
                    자연스럽게 문장 습득
                  </div>
                </div>
              </div>

              {/* 박스 4 */}
              <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                <div className="w-full overflow-hidden">
                  <img src={hjbook} alt="hjbook" className="scale-125" />
                </div>
                <div className="flex flex-col justify-center gap-3 md:gap-5">
                  <div className="text-center text-xs md:text-base font-medium font-['Pretendard'] text-black">
                    <span className="text-[#FE7020] font-semibold">
                      본교재 2권 + 워크북 1권
                    </span>
                    <br />
                    + 스토리북 1권
                    <br className="md:hidden" />
                    + QR스티커
                    <br />
                    (찬양 및 워크북)
                    <br className="md:hidden" />+ 달란트 통장
                  </div>
                  <div className="text-center text-[10px] md:text-sm font-normal font-['Pretendard'] whitespace-nowrap">
                    재미있는 2권의 교재 구성과
                    <br />
                    주일학교에서 바로 활용할 수 있는
                    <br />
                    영어 찬양과 달란트 통장 제공!
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* 주황박스 하단 버튼 */}
        <div className="flex flex-col md:gap-10 gap-5 max-w-[520px] w-full">
          {/* 교사용 매뉴얼 */}
          <section>
            <NeumophismAccordion
              title={
                <div className="flex-1 text-base md:text-xl font-bold">
                  교사용 매뉴얼
                </div>
              }
            >
              <HJTeacherManual />
            </NeumophismAccordion>
          </section>

          {/* 교재 상세히 보기 */}
          <section>
            <motion.div
              className="flex gap-2 items-center justify-center p-3 bg-[#FFA319] rounded-2xl shadow-md cursor-pointer 
              text-center text-white font-bold text-base md:text-xl"
              whileHover={{ opacity: 0.8 }}
              onClick={() => openNewWindow(HJ_BOOK_URL)}
            >
              <div className="flex flex-col items-center">
                <span className="text-[#ffda18]">Hello, Jesus!</span>
                <div>교재 상세히 보기</div>
              </div>
              <CircleRightArrowIcon width={20} color="white" />
            </motion.div>
          </section>
        </div>
      </div>
    </div>
  );
};

const HJTeacherManual = () => {
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#FFA319] rounded-xl p-3 md:p-5 gap-5
   text-black text-center font-normal font-['Pretendard'] text-[13px] md:text-lg "
    >
      <div
        className="rounded-full border border-black w-fit px-5 py-2 
      text-xl md:text-2xl font-bold mt-10"
      >
        Hello, Jesus!
      </div>

      <div className="text-xl md:text-2xl font-bold">
        연간 학사 일정 & 수업 절차
      </div>

      <div>
        어떻게 가르쳐야 할까? 걱정하지 마세요.
        <br />
        선생님들을 위한 상세한 매뉴얼을 제공해 드립니다.
      </div>

      <div>
        교사용 매뉴얼에는
        <br />
        Hello, Jesus! 콘텐츠 안내,
        <br />
        수업 절차 안내, 수업 준비 및 운영에 필요한 자료가
        <br />
        자세히 정리되어 있습니다.
      </div>

      <div className="font-bold">
        교사용 매뉴얼만 있으면 누구든 쉽게
        <br />
        영어로 성경을 가르칠 수 있습니다.
      </div>

      <div className="flex flex-col md:w-full">
        <div className="flex flex-col place-content-center gap-5 rounded-t-lg bg-white p-5 md:p-7 md:w-full text-black ">
          <div className="flex flex-col gap-3">
            <div className="flex justify-start items-center gap-2 font-bold">
              <CircleCheckIcon
                width="18"
                circleColor="#FFA319"
                checkColor="#FFA319"
              />
              <div className="text-xs md:text-lg">
                <span className="text-[#FFA319]">주 1회 60분씩, 41주 완성</span>{' '}
                과정 추천
              </div>
            </div>

            <div className="flex justify-start items-center gap-2 font-bold">
              <CircleCheckIcon
                width="18"
                circleColor="#FFA319"
                checkColor="#FFA319"
              />
              <div className="text-xs md:text-lg">
                <span className="text-[#FFA319]">기초 &gt; 학습 &gt; 반복</span>
                의 절차로 수업을 진행
              </div>
            </div>
          </div>

          <div className="w-full text-left text-[11px] md:text-base">
            <ul className="list-none pl-0">
              <ListItem text="Shouting & Greetings (힘찬 함성과 인사)" />
              <ListItem text="Let’s sing along (노래와 율동)" />
              <ListItem text="Vision Statement (비전 외치기)" />
              <ListItem text="The Lord’s Prayer (영어 주기도문 노래 합창)" />
              <ListItem text="Lesson Review (처음부터 배운 부분 복습)" />
              <ListItem text="Mini Sermon (짧은 설교)" />
              <ListItem text="Today’s Bible Story (본 수업 애니메이션 시청)" />
              <ListItem text="Puzzle (퍼즐을 통해 대사 습득)" />
              <ListItem text="Words & Act Out (단어를 몸으로 표현하기)" />
              <ListItem text="Play Time (노래 및 단체 활동으로 복습)" />
              <ListItem text="Dot Dot Dot & Words writing (알파벳, 단어쓰기)" />
              <ListItem text="Fun Task (그리기, 만들기, 색칠하기 등 추가 활동)" />
              <ListItem text="What’s next (다음 시간 예습)" />
              <ListItem text="Prayer & Shouting (영어로 기도, 환호성)" />
              <ListItem text="End-Class Meeting (반 별 종례)" />
              <ListItem text="Snack Time (간식 시간)" />
            </ul>
          </div>
        </div>

        <motion.div
          className="bg-[#FFDA19] rounded-b-xl w-full flex items-center justify-center gap-2 p-5 cursor-pointer"
          whileHover={{ opacity: 0.9 }}
          onClick={() => openNewWindow(HJ_TEACHER_MANUAL_URL)}
        >
          <div className="text-sm md:text-lg text-black font-bold ">
            교사용 매뉴얼 교안 샘플 보기
          </div>
          <CircleRightArrowIcon width={18} color="black" />
        </motion.div>
      </div>
    </div>
  );
};

export default HFHJSection8b;
