import React, { ReactNode } from 'react';
import { Arrcodian, TextCheckList } from './HomeschoolComp';
import ChannelService from '../../../api/ChannelService';

const HomeschoolSection11 = () => {
  return (
    <div className="bg-[#f5f5f5] w-full p-5 py-20">
      <div className="flex flex-col gap-5 max-w-[480px] m-auto">
        <div className="self-center text-3xl font-extrabold">FAQ</div>
        <Arrcodian
          body={<FAQ text="코스 이용기간 종료 후 연장은 어떻게 하나요?" />}
        >
          <TextCheckList
            textArr={[
              <>
                각 코스 별 연장 결제를 통해 연장이 가능하며, 연장은 1년 단위로
                진행됩니다.
                <br />
                연장을 하시면 JEMM 멤버십 혜택을 그대로 유지하실 수 있습니다.
              </>,
            ]}
          />
        </Arrcodian>
        <Arrcodian
          body={
            <FAQ
              text={
                <>
                  연장하지 않고 멤버십 기간이 <br />
                  종료되는 경우 어떻게 되나요?
                </>
              }
            />
          }
        >
          <TextCheckList
            textArr={[
              <>
                연장하지 않고 멤버십 기간이 종료되는 경우, 휴면 상태가 되며 휴면
                후 3년 이내에 연장 시에는 멤버십 자격을 회복하실 수 있습니다.
              </>,
            ]}
          />
        </Arrcodian>
        <Arrcodian body={<FAQ text={'연장 시 할인 혜택은 없나요?'} />}>
          <TextCheckList
            textArr={[
              <>
                BASIC 코스의 경우, [77개근상] 조건을 충족하시면 6만점이 적립되며
                연장 시 사용가능합니다. 또한 교재팩 연장으로 교재 12세트 구매 시
                온라인 비용이 할인됩니다.
                <br />
                KIDS 코스의 경우, 교재팩 연장으로 교재 12세트 구매 시 온라인
                비용이 면제 됩니다.
              </>,
            ]}
          />
        </Arrcodian>
        <Arrcodian
          body={<FAQ text={'코스를 결제하면 바로 학습이 시작되나요?'} />}
        >
          <TextCheckList
            textArr={[
              <>
                결제 후 학습페이지에 처음 진입하면 수강이 시작됩니다. 강의는
                60일 기간 안에만 수료하시면 됩니다. (평균 수료 기간 3일)
              </>,
            ]}
          />
        </Arrcodian>
        <Arrcodian body={<FAQ text={'영어를 못하는데 교사가 될 수 있나요?'} />}>
          <TextCheckList
            textArr={[
              <>
                교사대학에서는 프로그램 이해를 위한 강의를 제공하므로 개인의
                영어실력에 관계없이 누구나 가능합니다.
              </>,
            ]}
          />
        </Arrcodian>
        <Arrcodian body={<FAQ text={'목회자 할인 혜택이 있나요?'} />}>
          <TextCheckList
            textArr={[
              <>
                목사님, 사모님, 선교사님, 전임 전도사님을 위한 홈스쿨 등록 할인
                혜택을 제공하고 있습니다. 목회자 할인 혜택은 1:1 채팅 상담을
                통해 도와드리고 있습니다. 상담 문의 주세요!
                <br />
                <br />
                <div onClick={() => ChannelService.openChat()}>
                  👉 1:1 상담하기
                </div>
              </>,
            ]}
          />
        </Arrcodian>
        <Arrcodian
          body={<FAQ text={'각 코스 별 수업 권장 시간이 있을까요?'} />}
        >
          <TextCheckList
            textArr={[
              <>
                KIDS 코스의 경우, 한 레슨 당 키즈바 30분/ 키즈톡 20분의 수업
                시간을 권장합니다. BASIC 코스의 경우, 한 레슨 당 잉스바 50분/
                잉포 15분/ 잉크 35분의 수업 시간을 권장합니다. ADVANCED 코스의
                경우, 한 레슨 당 90분의 수업 시간을 권장합니다.
              </>,
            ]}
          />
        </Arrcodian>
      </div>
    </div>
  );
};

const FAQ = ({ text }: { text: ReactNode }) => {
  return (
    <div className="flex gap-4 items-center">
      <div className="bg-[#eb5d1a] text-center rounded-full w-6 h-6 mt-0 text-lg text-white font-extrabold leading-[24px]">
        Q
      </div>{' '}
      <div className="flex-1 font-bold">{text}</div>
    </div>
  );
};

export default HomeschoolSection11;
