import React from 'react';

const WmwSection14 = () => {
  return (
    <div className="bg-[#7441FF] flex justify-center p-5 py-10 md:p-12 md:py-20">
      <div className="w-full max-w-[700px] flex flex-col justify-center items-center gap-10 md:gap-12">
        <div className="text-[#FFDA19] text-3xl md:text-4xl font-bold">
          환불 규정
        </div>
        <div className="w-full text-white flex flex-col gap-5 md:gap-8">
          <div className="text-sm md:text-base">
            <div className="text-[#FFDA19] text-xl md:text-2xl font-bold mb-2">
              온라인 수강
            </div>
            <span className="text-lg md:text-xl font-bold">
              1. 수강 시작 전 온라인 교육 비용 환불
            </span>
            <div>
              1) 결제 후 3주 이내 전액 환불 가능합니다.
              <br />
              2) 결제 후 3주 이후 교육비 환불 불가합니다. (단, 교재 및 교부재 등
              미개봉한 상품은 환불 가능)
              <br /> 3) 결제 후 60일 이후 전액 환불 불가합니다.
            </div>
          </div>
          <div className="text-sm md:text-base">
            <span className="text-lg md:text-xl font-bold">
              2. 수강 시작 후 온라인 교육 비용 환불
            </span>
            <div>
              1) 7일 이내, 1강 이하 수강 시 전액 환불 가능합니다. (단, 결제 후
              3주 이내)
              <br />
              2) 7일 이내, 2강 이상 수강 시 교육비 환불 불가합니다. (단, 교재 및
              교부재 등 미개봉한 상품은 환불 가능)
            </div>
          </div>
          <div className="text-sm md:text-base">
            <span className="text-lg md:text-xl font-bold">
              3. 수료 후 온라인 서비스 이용 환불
            </span>
            <div>
              1) 수료 후 환불 요청 시, 결제 후 60일 이내일 경우 온라인 서비스
              비용 환불 가능합니다.
              <br />
              2) 환불 시 수료 취소되며, 탈퇴 후 3년간 해당 코스 재가입
              불가합니다.
            </div>
          </div>
        </div>
        <div className="text-white flex flex-col gap-5 md:gap-8">
          <div className="text-sm md:text-base">
            <div className="text-[#FFDA19] text-xl md:text-2xl font-bold mb-2">
              교재 및 교부재
            </div>
            <div>
              <span className="text-lg md:text-xl font-bold">
                1. 교재 및 교부재 환불/반품/교환
              </span>
              <br />
              1) 교재 및 교부재는 미개봉 시 3주 이내에 환불 및 교환 가능합니다.
              <br />
              2) 교육 환불 시, 받은 미개봉 교재 및 교부재 등은 모두 정상
              반품해야 하며, 개봉하여 재판매가 어려울 경우 제외 후 환불됩니다.
              <br />
              3) 교재가 불량(파본, 오인쇄)일 경우 60일 이내 교환 가능합니다.
              <br />
              4) 환불, 반품 및 교환 시 왕복 배송비는 귀책사유가 있는 쪽에서
              배송비를 부담합니다.
              <br />
              5) 이벤트 기간에 할인된 금액으로 교재 및 교부재 구매 후 부분 환불
              시 정가에서 차감되어 환불 및 교환됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WmwSection14;
