import { className } from "@material-tailwind/react/types/components/accordion";

interface NormalPageProps {
    children: React.ReactNode;
    bgColor?: string;
    xlBgColor?: string;
    paddingY?: number;
    paddingX?: number;
    className?: className;
}
const NormalPage: React.FC<NormalPageProps> = ({
    children,
    bgColor = "white",
    xlBgColor = "white",
    paddingX = 5,
    paddingY = 6,
    className,
}) => {
    return (
        <>
            <div
                className={`relative px-${paddingX} pt-${paddingY} xl:p-0 bg-${bgColor} xl:bg-${xlBgColor} min-h-normal xl:min-height-normal_pc ${className}`}
            >
                {children}
            </div>
        </>
    );
};
export default NormalPage;
