import React from 'react';
import ReferenceButton from './ReferenceButton';
import TextWinds from '../../../../components/common/TextWinds';
import { openNewWindow } from '../../../../api/utils/util-func';

const ReferenceCafe = ({ type }: { type: 'home' | 'church' }) => {
  const [title, link] =
    type === 'home'
      ? ['홈스쿨', 'https://cafe.naver.com/jemm']
      : ['주일학교', 'https://cafe.naver.com/jcebs'];

  return (
    <>
      <TextWinds type="title_h3">{title} 수료생 카페</TextWinds>
      <ReferenceButton type="violet" child="홈스쿨 수료생 카페" link={link} />
      <TextWinds type="content">
        {title} 코스의 다양한 부가 자료와 이벤트를 수료생 카페에서 확인하세요.
        <br />
        수료생 카페 별명을 ‘기수+이름'으로 설정한 후 등업 신청을 하여야 등업
        완료됩니다.
      </TextWinds>
    </>
  );
};

export default ReferenceCafe;
