import React from 'react';
import { RoundedBox } from './WmwComp';

const WmwSection3 = () => {
  return (
    <>
      <div className="bg-[#f5f5fa] flex justify-center p-3 py-10 md:p-12 md:py-20">
        <div className="w-full max-w-[520px] ">
          <div className="flex flex-col gap-8 md:gap-12">
            {/* 타이틀 */}
            <section>
              <div className="flex flex-col gap-1 md:gap-3 text-3xl md:text-4xl text-center font-['Pretendard']">
                <div>이런 분들이라면,</div>
                <div className="font-bold">반드시 도입하세요!</div>
              </div>
            </section>

            {/* 컨텐츠 */}
            <section className="flex flex-col gap-5 font-['Pretendard']">
              <RoundedBox number="01">
                <div>
                  <div>특별한 주일학교 프로그램을</div>
                  <div>
                    고민하는{' '}
                    <span className="text-[#5f32c9] font-bold">사역자</span>
                  </div>
                </div>
              </RoundedBox>

              <RoundedBox number="02">
                <div>
                  <div>주일학교 아이들의 부흥을 위해</div>
                  <div>
                    기도하고 있는{' '}
                    <span className="text-[#5f32c9] font-bold">
                      사역자, 교사
                    </span>
                  </div>
                </div>
              </RoundedBox>

              <RoundedBox number="03">
                <div>
                  <div>교회 아이들의 영어 교육에</div>
                  <div>
                    도움을 주고 싶은{' '}
                    <span className="text-[#5f32c9] font-bold">학부모</span>
                  </div>
                </div>
              </RoundedBox>

              <RoundedBox number="04">
                <div>
                  <div>자체 주일학교 프로그램을 운영할 여건이</div>
                  <div>
                    되지 않은{' '}
                    <span className="text-[#5f32c9] font-bold">
                      개척교회, 미자립교회
                    </span>
                  </div>
                </div>
              </RoundedBox>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default WmwSection3;
