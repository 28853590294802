import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit";
import { BoostUpTypes, CommonResProps } from "../../constants/types";
import { AxiosParamType } from ".";
import instance from "../../api/axios/utils.ts/instance";
import { RootState } from "..";

interface stateObj {
    status: string,
    boostupLists: CommonResProps<BoostUpTypes[]>,
    boostupInfo: BoostUpTypes
}
const initialState: stateObj = {
    status: '',
    boostupLists: {
        page: null,
        result: null,
    },
    boostupInfo: null
}
export const boostupSlice = createSlice({
    name: 'boostup',
    initialState,
    reducers: {
        resetBoostupLists(state) {
            state.boostupLists = {
                page: null,
                result: null,
            }
        },
        resetBoostupInfo(state) {
            state.boostupInfo = null
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getBoostupLists.pending, (state) => {
                state.status = 'pending'
            })
            .addCase(getBoostupLists.fulfilled, (state, action) => {
                state.status = 'fulfilled'
                state.boostupLists = action.payload
            })
            .addCase(getBoostupLists.rejected, (state) => {
                state.status = 'rejected'
            })

            .addCase(getBoostupInfo.pending, (state) => {
                state.status = 'pending'
            })
            .addCase(getBoostupInfo.fulfilled, (state, action: PayloadAction<BoostUpTypes>) => {
                state.status = 'fulfilled'
                state.boostupInfo = action.payload
            })
            .addCase(getBoostupInfo.rejected, (state) => {
                state.status = 'rejected'
            })
    }
})

export const getBoostupLists = createAsyncThunk(
    'asyncThunk/getBoostupLists',
    async ({ url, params }: AxiosParamType) => {
        try {
            const response = await instance.get(url, {params});
            return response.data;
        } catch(error) {
            console.error('[axios] getBoostupLists : ', error)
            return isRejectedWithValue(error)
        }
    }
)

export const getBoostupInfo = createAsyncThunk(
    'asyncThunk/getBoostupInfo',
    async ({ url, params }: AxiosParamType) => {
        try {
            const response = await instance.get(url);
            return response.data;
        } catch(error) {
            console.error('[axios] getBoostupInfo : ', error)
            return isRejectedWithValue(error)
        }
    }
)

export const selectBoostupLists = (state: RootState) => state.boostup.boostupLists
export const selectBoostupInfo = (state: RootState) => state.boostup.boostupInfo
export default boostupSlice.reducer