import { useLocation } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import "../../../styles/mypage/mypage.css";
import { orderDetailType } from "../../../constants/types/mypage-type";
import { phoneFormat } from "../../../api/utils/format/numbers";

const DeliveryInfo = ({orderDetail}:{orderDetail:orderDetailType}) => {
    // const location = useLocation();
    // const orderDetail = location.state;

    return (
        <div className="flex flex-col gap-y-4 px-3 py-[14px] bg-gray0">
            <TextWinds type="sub_title01">배송지 정보</TextWinds>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    수령인
                </TextWinds>
                <TextWinds type="content_body4">{orderDetail.receiver}</TextWinds>
            </div>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    전화번호
                </TextWinds>
                <TextWinds type="content_body4">{phoneFormat(orderDetail.contact_no)}</TextWinds>
            </div>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    주소
                </TextWinds>
                <div className="max-w-[180px] text-right">
                    <TextWinds type="content_body4">{orderDetail.address_1} {orderDetail.address_2}</TextWinds>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    배송 메시지
                </TextWinds>
                <TextWinds type="content_body4">{orderDetail.message}</TextWinds>
            </div>
        </div>
    );
};

export default DeliveryInfo;
