import React, { useEffect, useState } from 'react';
import Inputs from '../common/Inputs';
import Buttons from '../common/Buttons';
import { useAppDispatch } from '../../store/hook';
import { setOrdererInfo } from '../../store/features/orderInfoSlice';
import { phoneFormat, regPhone } from '../../api/utils/format/numbers';
import HFinput from '../common/input/HFinput';
import HFinputPhone from '../common/input/HFinputPhone';
import { useForm } from 'react-hook-form';

interface orderinfoChangeProps {
  name?: string;
  phone?: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderinfoChange = ({ name, phone, setOpen }: orderinfoChangeProps) => {
  type orderer = {
    name: string;
    phone: string;
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<orderer>({ values: { name: name, phone: phoneFormat(phone) } });

  const dispatch = useAppDispatch();
  const onSubmmit = (data: orderer) => {
    dispatch(setOrdererInfo(data));
    setOpen(false);
  };

  return (
    <form>
      <HFinput
        label="주문자명"
        name="name"
        placeholder="주문자명을 입력해주세요."
        register={register('name', {
          required: '주문자 정보를 입력해주세요.',
        })}
        error={errors.name?.message}
      />
      <HFinputPhone
        label="주문자 휴대전화번호"
        name="phone"
        placeholder="휴대전화번호를 입력해주세요."
        register={register('phone', {
          required: '휴대전화번호를 입력해주세요.',
        })}
        defaultValue={watch('phone')}
        error={errors.phone?.message}
      />
      <div className="flex gap-4">
        <Buttons
          type={'filled'}
          color={'purple5'}
          onClick={handleSubmit((data) => onSubmmit(data))}
        >
          확인
        </Buttons>
      </div>
    </form>
  );
};

export default OrderinfoChange;
