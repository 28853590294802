import React from 'react';
import IconInfo from '../../student/firstLogin/IconInfo';
import { Input } from '@material-tailwind/react';
import HFbutton from '../../../components/common/input/HFbutton';
import { timeLeftCheck, timeLeftToString } from '../../../controllers/useTimer';
import useSmsHook from '../../../controllers/useSmsHook';
import { phoneFormat } from '../../../api/utils/format/numbers';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import { Control } from 'react-hook-form';
import FormRow from '../../../components/common/input/FormRow';
import { UserDTO } from './InfoForm';

interface GuadianFormProps {
  control: Control<UserDTO>;
}
const GuadianForm = ({ control }: GuadianFormProps) => {
  return (
    <div>
      <div className="w-full h-6 justify-start items-center gap-2 inline-flex">
        <div className="w-6 h-6 relative">
          <IconInfo />
        </div>
        <div className="text-blue-500 text-xs font-medium">
          만 14세 미만의 어린이는 보호자의 동의가 필요합니다.
        </div>
      </div>
      {/* 보호자 이름/생년월일/성별/전화번호/약관동의 */}
      <div className="flex flex-col gap-3">
        <FormRow
          type="text"
          name="guardianInfo.name"
          control={control}
          placeholder="보호자 이름"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <FormRow
            type="date"
            name="guardianInfo.birthday"
            control={control}
            placeholder="보호자 생년월일"
          />
          <FormRow type="gender" name="guardianInfo.gender" control={control} />
        </div>

        <PhoneInput
          onSubmit={(phone) => {
            control.register('guardianInfo.phone', { value: phone });
            control.register('guardianInfo.countryCode', { value: '82' });
            control.register('guardianInfo.agreementPersonalInfo', {
              value: true,
            });
          }}
        />
      </div>
    </div>
  );
};

interface PhoneInputProps {
  onSubmit: (phone: string) => void;
}
const PhoneInput = ({ onSubmit }: PhoneInputProps) => {
  // 전화번호 입력 상태, 인증번호 발송 상태, 인증번호 확인 상태
  type phoneState = 'input' | 'send' | 'confirm';
  const dispatch = useAppDispatch();
  const [state, setState] = React.useState<phoneState>('input');
  const [phone, setPhone] = React.useState<string>('');
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const { sendVerifyNumber, checkVerifyNumber, timeLeft } = useSmsHook();

  const handlePhoneChange = (value: string) => {
    setState('input');
    // 숫자 제외 모두 제거
    setPhone(value.replace(/[^0-9]/g, ''));
  };

  const confirmHandler = () => {
    checkVerifyNumber(phone, verificationCode).then((res) => {
      if (res.confirm) {
        setState('confirm');
        onSubmit(phone);
      }
      dispatch(openAlert({ message: res.msg }));
    });
  };
  return (
    <>
      <div className="relative flex w-full">
        <Input
          type="text"
          label="전화번호"
          className="pr-20"
          containerProps={{
            className: 'min-w-0',
          }}
          value={phoneFormat(phone)}
          onChange={(e) => handlePhoneChange(e.target.value)}
          disabled={state === 'confirm'}
        />
        <div className="!absolute w-[70px] right-[3px] top-[3px]">
          <HFbutton
            height={34}
            onClick={() => {
              sendVerifyNumber(phone);
              setState('send');
            }}
            disabled={state !== 'input'}
          >
            {timeLeftCheck(timeLeft) ? (
              timeLeftToString(timeLeft)
            ) : (
              <span className="text-xs">인증번호</span>
            )}
          </HFbutton>
        </div>
      </div>
      {state === 'send' && (
        <div className="relative flex w-full">
          <Input
            type="text"
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <div className="!absolute w-[70px] right-[3px] top-[3px]">
            <HFbutton height={34} onClick={confirmHandler}>
              <span className="text-xs">인증확인</span>
            </HFbutton>
          </div>
        </div>
      )}
    </>
  );
};

export default GuadianForm;
