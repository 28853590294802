import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LayoutStudent from './LayoutStudent';
import StudentFirstLogin from './firstLogin/StudentFirstLogin';
import StudentClassPage from './class/StudentClassPage';
import StudentClassDetail from './class/StudentClassDetail';
import StudentBadgePage from './badge/StudentBadgePage';
import StudentCertificatioinPage from './certification/StudentCertificatioinPage';
import StudentHomeworkPage from './class/homework/StudentHomeworkPage';
import StudentProfilePage from './profile/StudentProfilePage';
import StudentProfileEditPage from './profile/StudentProfileEditPage';
import { studentProfileType } from '../../constants/types/student-type';
import { StudentProfileContext } from './profile/StudentProfileContext';
import instance from '../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../constants/types';

const RouteStudent = () => {
  const [studentProfile, setStudentProfile] = useState<studentProfileType>();
  const { pathname } = useLocation();

  const getStudentProfile = async () => {
    try {
      const response = await instance.get<ApiResponse<studentProfileType>>(
        '/v1/user/student'
      );
      const data = response.data;
      if (data.statusCode === 200) {
        setStudentProfile(data.data);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStudentProfile();
  }, [pathname]);

  return (
    <StudentProfileContext.Provider
      value={{
        studentProfile: studentProfile,
        setStudentProfile: setStudentProfile,
      }}
    >
      <Routes>
        {/* base "/student" */}
        <Route index path="/firstLogin" element={<StudentFirstLogin />} />
        <Route element={<LayoutStudent />}>
          {/* 내 학습 */}
          <Route
            index
            path="/"
            element={<Navigate replace to="/student/class" />}
          />
          <Route index path="/class" element={<StudentClassPage />} />

          {/* 내 학습 상세페이지 */}
          <Route
            index
            path="/class/:classId"
            element={<StudentClassDetail />}
          />

          {/* 할일 목록  */}
          <Route
            index
            path="/homework/:classId"
            element={<StudentHomeworkPage />}
          />

          {/* 획득 뱃지 */}
          <Route index path="/badge" element={<StudentBadgePage />} />

          {/* 인증제 현황 */}
          <Route
            index
            path="/certification"
            element={<StudentCertificatioinPage />}
          />

          {/* 개인 정보 확인 */}
          <Route index path="/profile" element={<StudentProfilePage />} />

          {/* 개인 정보 수정 */}
          <Route
            index
            path="/profile/edit/:type"
            element={<StudentProfileEditPage />}
          />
        </Route>
      </Routes>
    </StudentProfileContext.Provider>
  );
};

export default RouteStudent;
