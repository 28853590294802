import React, { ReactNode } from 'react';
import { DoubleQuote, GradientButton, PackmanIcon } from './HomeschoolComp';

const HomeschoolSection10 = () => {
  return (
    <div className="flex flex-col gap-10 bg-[#ffd973] py-10 px-3">
      <Card>
        <PackmanIcon color="#ffd973" width={80} />
        <div className="text-2xl text-center">
          젭스홈스쿨에 대한
          <br />
          <span className="font-bold">더욱 자세한 설명이 필요하다면?</span>
        </div>
        {/* <div className="w-full">
          <a
            href="https://jebs2.kr/new_seminar/2023_jebs_home_school.pdf"
            target="_blank"
          >
            <GradientButton text={'도입제안서 확인하기'} />
          </a>
        </div> */}
        <div className="w-full">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdN9AqcS5o0bfkZN6ij7e512bi09ynt2o7jPRdmN-2rLAAvHA/viewform"
            target="_blank"
          >
            <GradientButton text={'1:1 문의하기'} />
          </a>
        </div>
      </Card>
      <Card>
        <DoubleQuote color="#ffd973" width={80} />
        <div className="text-2xl text-center">
          젭스홈스쿨의
          <br />
          <span className="font-bold">더 많은 후기가 궁금하다면?</span>
        </div>
        <div className="w-full">
          <a href="https://cafe.naver.com/jemm" target="_blank">
            <GradientButton text={'젭스홈스쿨 네이버 카페 보러가기'} />
          </a>
        </div>
      </Card>
    </div>
  );
};

const Card = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col items-center gap-3 w-full max-w-[480px] rounded-lg bg-[#fff6e5] m-auto py-10 px-5">
    {children}
  </div>
);

export default HomeschoolSection10;
