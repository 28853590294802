import { AxiosError, InternalAxiosRequestConfig } from 'axios';

export const onRequest = (config: InternalAxiosRequestConfig) => {
  const { method, url } = config;

  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const onReqError = (error: AxiosError | Error) => {
  return Promise.reject(error);
};
