const _neumophismUp = '-5px -5px 10px 2px white, 2px 2px 10px #aaaacc';

const _neumophismDown = `
-2px -2px 5px 2px #FFFFFF inset,
2px 2px 5px 0px #AAAACC80 inset,
2px 2px 4px 0px #AAAACC40 inset,
-2px -2px 4px 0px #FFFFFF80 inset
`;

export const neumophismOver = {
  background: 'linear-gradient(145deg, #ffffff, #AAAACC80)',
  WebkitBoxShadow: _neumophismUp,
  boxShadow: _neumophismUp,
};

export const neumophismUp = {
  WebkitBoxShadow: _neumophismUp,
  boxShadow: _neumophismUp,
};
export const neumophismDown = {
  WebkitBoxShadow: _neumophismDown,
  boxShadow: _neumophismDown,
};
