import React, { ReactNode } from 'react';
import JepsplSection from '../JepsplSection';
import WarningSection from '../WarningSection';
// import { motion } from 'framer-motion';
// import { CheckIcon } from '../../homeschool/HomeschoolComp';
// import wmwLecture from './img/wmw-lecture.jpg';
// import siteScreenshot from './img/site-screenshot.png';

const WmwSection11 = () => {
  return (
    <>
      <WarningSection />
      <JepsplSection />
    </>
  );
};

// const WmwSection11 = () => {
//   return (
//     <>
//       <div className="bg-[#FFD01E] p-3 py-8 md:p-10">
//         <div
//           className="max-w-[520px] w-full m-auto
//           flex flex-col justify-center items-center gap-5 text-center font-bold"
//         >
//           {/* 타이틀 */}
//           <div className=" text-center text-[#6463E3] text-2xl md:text-4xl">
//             성공하는 교회와{' '}
//             <span className="text-red-500 text-shadow-sm">아닌 교회</span>의
//             차이!
//           </div>

//           <div className="flex flex-col gap-5 text-base md:text-xl">
//             <div>
//               <span className="underline underline-offset-4">
//                 학생 수대로 학생용 교재를 구매하여 <br />
//                 프로그램 운영안대로 수업을 진행하는 것! <br />
//               </span>
//               <span className="font-medium">
//                 (교재 없이 운영 시 프로그램 효과가 떨어지며, <br />
//                 교재 비용은 학부모가 부담하는 것이 좋습니다.)
//               </span>
//             </div>

//             <span>
//               *교재 불법 복제 근절에 동참해주세요.
//               <br />
//               교회학교의 정직한 교재 사용을 부탁드립니다.
//             </span>
//           </div>
//         </div>
//       </div>

//       <div className="bg-[#6730FF]">
//         <div className="max-w-[520px] m-auto flex flex-col gap-5 md:gap-10 items-center text-center py-10  text-white">
//           <div>
//             <div className="text-3xl md:text-4xl font-bold">
//               <span className="text-[#ffda18]">젭스플</span>
//               이란?
//             </div>

//             <div className="text-lg md:text-xl">
//               젭스영어주일학교 온라인 콘텐츠
//             </div>
//           </div>

//           <div>
//             <span className="text-[#ffda18] text-3xl md:text-4xl font-bold gap-3 md:gap-5">
//               젭스플 (JEBS PLAY)
//             </span>
//             <div className="text-base md:text-lg">
//               젭스플(JEBS Play)은 젭스영어주일학교 프로그램을
//               <br />
//               PC/모바일로 자유롭게 이용할 수 있는 콘텐츠로
//               <br />
//               더욱 풍성한 학습 활동을 지원해줍니다.
//             </div>
//           </div>

//           <div>
//             <div className="grid grid-cols-2 max-w-[520px] gap-5 p-5 ">
//               <div className="flex flex-col bg-gray1 rounded-2xl">
//                 <img
//                   src={wmwLecture}
//                   alt="wmwLecture"
//                   className="h-full object-cover rounded-t-2xl"
//                 />
//                 <div className="w-fit text-xs font-bold rounded-full px-2 my-1 self-center text-[#6132da]">
//                   수업/율동 영상 시청
//                 </div>
//               </div>
//               <div className="flex flex-col bg-gray1 rounded-2xl">
//                 <img
//                   src={siteScreenshot}
//                   alt="site-screenshot"
//                   className="h-full object-cover rounded-t-2xl"
//                 />
//                 <div className="w-fit text-xs font-bold rounded-full px-2 my-1 self-center text-[#6132da]">
//                   한 눈에 보는 학습현황
//                 </div>
//               </div>
//             </div>

//             <div className="text-sm md:text-base">
//               *구매한 학생 교재 1세트 당 젭스플 이용권 1개 사용가능
//             </div>
//           </div>

//           <div className="px-5 w-full">
//             <TabView />
//           </div>
//         </div>
//       </div>
//     </>

//   );
// };

// const TabView = () => {
//   const [tab, setTab] = React.useState(0);

//   const content = {
//     0: [
//       '복습 수업 영상 및 액티비티로 집에서도 학생들의 복습 가능',
//       '학생들의 학습 현황 한 눈에 확인',
//     ],
//     1: [
//       '주중 가정 복습용 (가정학습 가이드 제공)',
//       '본 수업용 (젭스플+선생님 지도의 콜라보)',
//       '진도 따라잡기용',
//     ],
//   };

//   return (
//     <div className="max-w-[520px] w-full bg-[#30009c] rounded-xl mt-5">
//       <div className="grid grid-cols-2 text-center w-full">
//         <div
//           className={`cursor-pointer py-2 rounded-tl-xl w-full ${
//             tab === 0 ? 'text-[#ffda18] font-bold' : 'bg-[#451ca2] text-white'
//           }`}
//           onClick={() => setTab(0)}
//         >
//           젭스플 주요기능
//         </div>
//         <div
//           className={`cursor-pointer py-2 rounded-tr-xl w-full ${
//             tab === 1 ? 'text-[#ffda18] font-bold' : 'bg-[#451ca2] text-white'
//           }`}
//           onClick={() => setTab(1)}
//         >
//           젭스플 활용방안
//         </div>
//       </div>
//       <div className="p-5 w-full">
//         <TextCheckListBox
//           textArr={content[tab as keyof typeof content]}
//           textSize="text-sm md:text-base"
//         />
//       </div>
//     </div>
//   );
// };

// export const TextCheckListBox = ({
//   textArr,
//   textSize,
// }: {
//   textArr: ReactNode[];
//   textSize?: string;
// }) => (
//   <div className="w-full flex flex-col gap-3 p-5 bg-gray2 rounded-lg text-black text-left">
//     {textArr.map((text, index) => (
//       <motion.div
//         key={index}
//         className={`w-full flex gap-2 items-start ${textSize || 'text-base'}`}
//         initial={{ opacity: 0, x: -20 }}
//         whileInView={{ opacity: 1, x: 0 }}
//         transition={{ delay: index * 0.2 }}
//       >
//         <CheckIcon width={18} color="#9c9c9c" />
//         <div className="flex-1 w-full md:text-base">{text}</div>
//       </motion.div>
//     ))}
//   </div>
// );

export default WmwSection11;
