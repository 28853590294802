import React from 'react';
import { BreadIcon, DownCircleArrow, SnowFlower } from './HomeschoolComp';
import { motion } from 'framer-motion';
import backgroundImg from './img/section8-a.png';

const HomeschoolSection8 = () => {
  return (
    <div className=" bg-[#fff6e5]">
      <div className="flex flex-col items-center  w-full max-w-[480px] m-auto overflow-hidden pt-20">
        <SnowFlower color="#eb5d1a" width={24} opacity={1} />
        <div className="text-3xl md:text-4xl text-center mt-3 text-[#eb5d1a]">
          홈스쿨 운영 절차
        </div>
        <section className="flex flex-col gap-10 w-full mt-10 p-4">
          <Card arrow>
            <div className="text-center py-8 text-lg md:text-2xl">
              원하는<span className="font-bold"> 코스 </span> 등록
            </div>
          </Card>
          <Card arrow>
            <div className="text-center py-8 text-lg md:text-2xl">
              온라인{' '}
              <span className="font-bold">교사대학 수료 / 교인 인증</span>
            </div>
          </Card>
          <Card>
            <div className="text-center py-8 text-lg md:text-2xl">
              <span className="font-bold">License</span> 취득 +{' '}
              <span className="font-bold">JEMM 멤버십</span> 자동 등록
            </div>
          </Card>
          <div className="text-[#564c39] text-xs md:text-sm text-center font-bold">
            * 교인 인증의 경우, 개신교 보편적인 교단에 속한 교회의 교인만
            가능하며
            <br />
            교회114 사이트와 연동하여 교회인증을 진행합니다.
          </div>
        </section>
        <section className="flex flex-col gap-10 w-full p-5">
          <Card>
            <div className="relative pt-10 pb-12 overflow-hidden text-sm md:text-base">
              <div className="absolute drop-shadow-[-10px_-10px_20px_rgba(255,231,221)] right-0 bottom-0 translate-x-1/3 translate-y-1/3">
                <SnowFlower color="white" width={300} opacity={1} />
              </div>
              <div className="relative w-fit m-auto border border-[#eb5d1a] text-[#eb5d1a] rounded-full py-2 px-5 font-bold text-2xl">
                JEMM 멤버십이란?
              </div>
              <div className="relative text-center mt-10">
                JEBS English Missionary Membership의 약자로
                <br />
                영어교육선교사의 비전을 가지고
                <br />
                <span className="font-bold">
                  젭스 교사대학 교육을 수료한 후,
                </span>
                <br />
                각자의 현장(집, 교회 등)에서 젭스영어성경홈스쿨 콘텐츠로
                <br />
                학생을 가르칠 수 있는 자격이 주어진 멤버십 회원!
              </div>
            </div>
          </Card>
          <Card>
            <div className="relative pt-10 pb-12 overflow-hidden">
              <div className="absolute drop-shadow-[-30px_-10px_20px_rgba(255,231,221)] right-0 bottom-0 translate-y-1/3 rotate-[-90deg]">
                <BreadIcon color="white" width={200} opacity={1} />
              </div>
              <div className="relative w-fit m-auto border border-[#eb5d1a] text-[#eb5d1a] rounded-full py-2 px-5 font-bold text-2xl">
                교사대학이란?
              </div>
              <div className="relative text-center mt-5">
                <span className="font-bold">
                  수업 진행 절차를 배우는 과정으로 <br />
                  개인의 영어실력과 관계 없이 누구나 진행 가능
                </span>
              </div>
              <div className="relative flex w-fit m-auto gap-1 md:gap-3 mt-10 text-xs md:text-base">
                <div className="flex items-center bg-[#ffecde] rounded-lg text-center font-bold px-4 py-4">
                  온라인
                  <br />
                  강의 수강
                </div>
                <NextArrow width={24} />
                <div className="flex items-center bg-[#ffecde] rounded-lg text-center font-bold px-4">
                  교인 인증
                </div>
                <NextArrow width={24} />
                <div className="flex items-center bg-[#ffecde] rounded-lg text-center font-bold px-4">
                  수료 &
                  <br />
                  멤버십 시작
                </div>
              </div>
            </div>
          </Card>
          <div className="text-[#564c39] text-xs md:text-sm text-center font-bold">
            * 강의 수강 기간은 총 60일 부여
            <br />
            *평균 수료 기간은 3일
            <br />
            *평균 코스별 강의 수강 시간 4시간
          </div>
        </section>
      </div>
      <img
        src={backgroundImg}
        alt="background"
        className="w-full filter grayscale mt-[-110px] aspect-video object-cover"
      />
    </div>
  );
};

const Card = ({
  children,
  arrow,
}: {
  children: React.ReactNode;
  arrow?: boolean;
}) => (
  <div className="relative border border-[#f28b3d] bg-white w-full rounded-lg">
    {children}
    {arrow && (
      <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <DownCircleArrow width={40} bgColor="#f28b3d" />
      </div>
    )}
  </div>
);

const NextArrow = ({ width }: { width: number }) => (
  <svg
    width={width}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_610_494)">
      <path
        d="M46.9862 23.5069C46.9862 10.532 36.468 0.0137924 23.4931 0.0137929C10.5182 0.0137935 -1.59407e-06 10.532 -1.02692e-06 23.5069C-4.59766e-07 36.4818 10.5182 47 23.4931 47C36.468 47 46.9862 36.4818 46.9862 23.5069Z"
        fill="#F28B3D"
      />
      <path
        d="M32.3082 23.4933C32.3082 24.1693 32.0875 24.8453 31.646 25.4109L22.3895 37.2471C21.3273 38.599 19.3822 38.8335 18.0303 37.7851C16.6783 36.7367 16.4438 34.7778 17.4922 33.4258L25.2451 23.5071L17.4922 13.5884C16.4438 12.2365 16.6783 10.2914 18.0302 9.22919C19.3822 8.16696 21.3273 8.41528 22.3895 9.7672L31.646 21.6034C32.0875 22.169 32.3082 22.845 32.3082 23.5209L32.3082 23.4933Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_610_494">
        <rect
          width="47"
          height="47"
          fill="white"
          transform="translate(0 47) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default HomeschoolSection8;
