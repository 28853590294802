import React from 'react';
import Image1 from './img/patentExam.png';
const HomeschoolSection3DropPatent = () => {
  return (
    <div className="flex flex-col p-4 text-center bg-white relative text-xs md:text-base">
      <div className="justify-center self-center px-5 py-1 text-2xl bg-[#fafafa] rounded-full font-bold">
        <span className="text-[#36A1EE]">삽화기억술</span>이란?
      </div>
      <div className="self-stretch mt-12 text-black max-md:mt-10 max-md:max-w-full">
        삽화기억술은{' '}
        <span className="font-bold">정철 선생님의 특허 받은 교수법</span>으로,{' '}
        <br />
        <span className="font-bold text-[#36A1EE]">
          청크 단위로 궁금한 순서를 표현한 이미지와{' '}
        </span>
        <br />
        <span className="font-bold text-[#36A1EE]">
          이미지의 위치를 통해 영어 문장의 구조를 이해하고{' '}
        </span>
        <br />
        <span className="font-bold text-[#36A1EE]">스토리를 이미지화</span>하여
        자연스럽게 기억하게 하는 <br />
        영어학습 방법입니다.
        <br />
        <br />
        젭스홈스쿨의 대표 코스인 베이직 코스의 교재 <br />
        English Story Bible(잉스바)은 <br />
        신·구약 바이블 스토리의 약 천 여개의 문장을 암송합니다. <br />
        아이들이 그림만 보고도 영어 문장을 술술술 말할 수 있는 비밀! <br />
        바로 이 <span className="font-bold text-[#36A1EE]">
          '삽화기억술'
        </span>{' '}
        때문입니다.
      </div>
      <img loading="lazy" src={Image1} className="self-center mt-5 w-[200px]" />
    </div>
  );
};

export default HomeschoolSection3DropPatent;
