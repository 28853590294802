import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageReducer, { storageSlice } from './storageSlice';
import productReducer, { productSlice } from './productSlice';
import shopReducer, { shopSlice } from './shopSlice';
import storage from 'redux-persist/lib/storage';
import optionReducer, { optionSlice } from './optionSlice';
import purchaseReducer, { purchaseSlice } from './purchaseSlice';
import orderReducer, { orderSlice } from './orderSlice';
import pointReducer, { pointSlice } from './pointSlice';
import courseReducer, { courseSlice } from './courseSlice';
import cartReducer, { cartSlice } from './cartSlice';
import orderInfoReducer, { orderInfoSlice } from './orderInfoSlice';
import lmsReducer, { lmsSlice } from './lmsSlice';
import boostupReducer, { boostupSlice } from './boostupSlice';
import takeClassReducer, { takeclassSlice } from './takeclassSice';
import myReducer, { mySlice } from './mySlice';
import modalReducer, { modalSlice } from './modalSlice';

export interface AxiosParamType {
  url: string;
  params?: any;
}

const persistConfig = {
  key: 'root',
  // storage: storageSession,
  storage,
};
const rootReducer = combineReducers({
  [storageSlice.name]: storageReducer,
  [productSlice.name]: productReducer,
  [cartSlice.name]: cartReducer,
  [shopSlice.name]: shopReducer,
  [optionSlice.name]: optionReducer,
  [purchaseSlice.name]: purchaseReducer,
  [orderSlice.name]: orderReducer,
  [orderInfoSlice.name]: orderInfoReducer,
  [pointSlice.name]: pointReducer,
  [courseSlice.name]: courseReducer,
  [lmsSlice.name]: lmsReducer,
  [boostupSlice.name]: boostupReducer,
  [takeclassSlice.name]: takeClassReducer,
  [mySlice.name]: myReducer,
  [modalSlice.name]: modalReducer,
});

export default persistReducer(persistConfig, rootReducer);
