import React from "react";
import { Button as MaterialButton } from "@material-tailwind/react";
import { className } from "@material-tailwind/react/types/components/button";

interface ButtonProps {
    type: "filled" | "gradient" | "outlined" | "text" | "text-bg";
    color:
        | "purple1"
        | "purple2"
        | "purple5"
        | "skyblue"
        | "orange"
        | "orange4"
        | "gray2"
        | "gray6"
        | "gray7"
        | "gray9"
        | "red5"
        | "white";
    align?: "left" | "center" | "right";
    children: React.ReactNode;
    onClick?: () => void;
    isHeight?: string;
    disabled?: boolean;
    className?: className;
}

export default function Buttons({ type, color, children, onClick, isHeight, align, disabled, className }: ButtonProps) {
    const getColorClassName = (type: string, color: string) => {
        switch (type) {
            case "filled":
                return `bg-${color} border-${color} text-white`;
            case "gradient":
                return `bg-gradient-to-r from-${color} to-${color}`;
            case "outlined":
                return `border text-${color} border-${color} bg-white`;
            case "text":
                return `w-auto h-auto px-[6px] py-1 border-0 bg-white text-${color} hover:bg-purple2`;
            case "text-bg":
                return `bg-${color} h-auto w-auto p-0 border-none opacity-80`;
            default:
                return "";
        }
    };

    const getButtonClassName = (isHeight?: string, align?: string) => {
        let buttonClassName =
            "border border-solid h-[54px] flex items-center justify-center w-full rounded-[4px] shadow-none hover:shadow-none";

        if (isHeight) {
            switch (isHeight) {
                case "titlebutton":
                    buttonClassName =
                        "flex flex-row items-center justify-center px-2.5 py-2 rounded shadow-none hover:shadow-none";
                    break;
                case "intable":
                    buttonClassName =
                        "px-0 py-2 border border-solid flex items-center justify-center w-full font-semibold whitespace-nowrap";
                    break;
                case "full":
                    buttonClassName += " h-full";
                    break;
                default:
                    buttonClassName =
                        "border border-solid h-[54px] flex items-center justify-center w-full rounded-[4px]";
                    break;
            }
        }

        if (align) {
            switch (align) {
                case "left":
                    buttonClassName += " justify-start";
                    break;
                case "right":
                    buttonClassName += " justify-end";
                    break;
                default:
                    buttonClassName += "";
                    break;
            }
        }

        return buttonClassName;
    };

    const colorClassName = getColorClassName(type, color);
    const buttonClassName = getButtonClassName(isHeight, align);

    return (
        <MaterialButton
            className={`text-button ${buttonClassName} ${colorClassName} ${className}`}
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </MaterialButton>
    );
}
