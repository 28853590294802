import React from 'react';

const IconInfo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="icon/notice">
        <path
          id="Ellipse 203"
          d="M19.75 12C19.75 7.44365 16.5563 4.25 12 4.25C7.44365 4.25 4.25 7.44365 4.25 12C4.25 16.5563 7.44365 19.75 12 19.75C16.5563 19.75 19.75 16.5563 19.75 12Z"
          stroke="#2686FF"
          // stroke-width="1.5"
        />
        <rect
          id="Rectangle 1031"
          width="1.5"
          height="6.5"
          transform="matrix(1 0 0 -1 11.25 17)"
          fill="#2686FF"
        />
        <circle
          id="Ellipse 204"
          cx="0.9"
          cy="0.9"
          r="0.9"
          transform="matrix(1 0 0 -1 11.0996 9)"
          fill="#2686FF"
        />
      </g>
    </svg>
  );
};

export default IconInfo;
