import React from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import TextWinds from '../common/TextWinds';
import { IconButton } from '@material-tailwind/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface ModalExtendLinkProps {
  checkType: string;
  type?: string;
  message: string;
  purchaseLInk: Record<string, any>;
}
const ModalExtendLink = ({
  checkType,
  type,
  message,
  purchaseLInk,
}: ModalExtendLinkProps) => {
  const dispatch = useAppDispatch();
  const closeBtnhandler = () => {
    dispatch(closeModal());
  };
  const navigateBtnHandler = (loc: string, targetUrl: string) => {
    window.open(targetUrl, '_blank');
    dispatch(closeModal());
  };
  return (
    <div className="flex flex-col gap-4 w-[335px] p-4 bg-white shadow-card mb-40 rounded-md mountAnimation">
      <div className="flex flex-row items-center w-full">
        <div>{message}</div>
        <div className="flex items-center ml-auto">
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeBtnhandler}
          >
            <XMarkIcon className="!w-[30px] !h-[30px]" />
          </IconButton>
        </div>
      </div>
      <div className="grid grid-flow-col-dense gap-2">
        <TextWinds
          type="button2"
          color="purple5"
          className="cursor-pointer hover:bg-gray2 p-2 rounded-md border text-center"
          onClick={() =>
            navigateBtnHandler(
              'online',
              purchaseLInk.onlineLink + '?option=false'
            )
          }
        >
          온라인 연장
        </TextWinds>
        {'double' === checkType && (
          <TextWinds
            type="button2"
            color="purple5"
            className="cursor-pointer hover:bg-gray2 p-2 rounded-md border text-center"
            onClick={() =>
              navigateBtnHandler('pack', purchaseLInk.packLink + '?option=true')
            }
          >
            교재팩 연장
          </TextWinds>
        )}
      </div>
    </div>
  );
};

export default ModalExtendLink;
