import { myLearningLessonInfoType } from '../../../../constants/types/learning-type';
import { PaginationProps } from '../../../../constants/types';
import { ColumnsType } from 'antd/es/table';
import TakeclassLinkButton from '../../../../components/lms/takeclass/TakeclassLinkButton';

export const resourceTypeBase = [
  { key: 'Vimeo', name: '동영상' },
  { key: 'HTML', name: '수업 자료' },
];
export const resourceTypeForAdvanced = [
  ...resourceTypeBase,
  { key: 'Jebspl', name: 'Acts수업자료' },
];

export const columns = (
  pageInfo: PaginationProps,
  isAdvanced: boolean,
  openAction?: (id: string) => void
): ColumnsType<myLearningLessonInfoType> => [
  {
    title: 'No.',
    key: 'index',
    render: (_, row, index) =>
      index + (pageInfo.page - 1) * pageInfo.pageSize + 1,
    align: 'center',
  },
  {
    title: '북(챕터)/레슨',
    key: 'book',
    render: (_, row) => `${row.chapterName}/${row.lessonName}`,
    align: 'center',
  },
  {
    title: '제목',
    key: 'name',
    render: (_, row) => row.name,
    align: 'center',
  },
  {
    title: '자료유형',
    key: 'type',
    render: (_, row) =>
      (isAdvanced ? resourceTypeForAdvanced : resourceTypeBase).find(
        (x) => x.key === row.type
      )?.name,
    align: 'center',
  },
  {
    title: '수업하기',
    key: 'lesson',
    render: (_, row) => (
      <TakeclassLinkButton id={row.id} openAction={openAction} />
    ),
    align: 'center',
  },
];
