import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hook';
import { AgreementModal, AgreementType } from './Agreement';
import { openModal } from '../../store/features/modalSlice';
import CheckBoxes from './CheckBoxes';
import TextWinds from './TextWinds';

export type AgreementObjType = {
  agree: AgreementType; // 약관 타입
  essential: boolean; // 필수 동의 여부
  checked?: boolean; // 동의 체크 여부
};

/**
 * 약관 동의 리스트
 * @param agreementList 약관 목록
 * @param essentialChecked 필수 체크 완료시 실행할 함수 ( 필수 동의 체크 여부, 체크 완료된 목록 )
 * @returns 
 */
export const AgreementBox = ({
  agreementList,
  essentialChecked,
}: {
  agreementList: AgreementObjType[];
  essentialChecked?: (
    checked: boolean,
    checkedList?: AgreementObjType[]
  ) => void;
}) => {
  const dispatch = useAppDispatch();

  // 기본 체크 안함으로 목록 초기화
  const [agreement, setAgreement] = useState<AgreementObjType[]>(
    agreementList.map((item) => ({ ...item, checked: false }))
  );

  // 전체 선택 상태관리
  const [allCheck, setAllCheck] = useState(false);

  // 개별 동의 체크박스 클릭
  const handleAgreement = (index: number) => {
    setAgreement((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      )
    );
  };

  // 전체 동의 클릭시 개별 체크박스 전체 체크/해제
  const handleAllChecked = (checked: boolean) => {
    setAgreement(agreement.map((item) => ({ ...item, checked })));
  };

  // 약관 상세보기 다이얼로그 열기
  const handleDialog = (idx: number) => {
    dispatch(
      openModal({
        body: <AgreementModal agreement={agreementList[idx].agree} />,
      })
    );
  };

  useEffect(() => {
    // 개별 체크에 의한 전체 체크 여부 판단
    agreement.filter((item) => item.checked === false).length > 0
      ? setAllCheck(false)
      : setAllCheck(true);

    // 필수 동의 체크 여부 판단
    essentialChecked &&
      essentialChecked(
        agreement
          .filter((item) => item.essential === true)
          .filter((item) => item.checked === false).length > 0
          ? false
          : true,
        agreement.filter((item) => item.essential === true)
      );
  }, [agreement]);

  return (
    <>
      <div
        className="flex items-center gap-3"
        onClick={() => handleAllChecked(!allCheck)}
      >
        <CheckBoxes
          className="w-6 h-6"
          type="full"
          color="purple5"
          labelValue="전체 약관 동의"
          onChange={(e) => {}}
          checked={allCheck}
        />
      </div>
      {agreementList.map((data, index) => (
        <div className="flex overflow-wrap items-center gap-3" key={index}>
          <CheckBoxes
            className="w-6 h-6"
            color="purple5"
            type="rounded"
            labelValue={`${data.agree} (${data.essential ? '필수' : '선택'})`}
            checked={agreement[index]?.checked}
            onChange={() => handleAgreement(index)}
          />
          <TextWinds
            type="content_body3"
            color="purple5"
            className="cursor-pointer hover:underline"
            onClick={() => handleDialog(index)}
          >
            자세히 보기
          </TextWinds>
        </div>
      ))}
    </>
  );
};
