import React from 'react';
import TextWinds from '../common/TextWinds';
import { numberWithCommas } from '../../api/utils/format/numbers';
export type PurchaseSummaryType = {
  productAmount: number;
  deliveryCharge: number;
  discountAmount: number;
  pointAmount: number;
};
const PurchaseSummary = ({
  productAmount,
  deliveryCharge,
  discountAmount,
  pointAmount,
}: PurchaseSummaryType) => {
  const salePrice = discountAmount + pointAmount;
  const orderPrice = productAmount + deliveryCharge;
  const totalPrice = orderPrice - salePrice;

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <TextWinds type="content_rel">주문금액</TextWinds>
        <TextWinds type="content_rel" className="flex items-center gap-[5px]">
          {numberWithCommas(orderPrice)} 원
        </TextWinds>
      </div>
      <ul className="list-disc">
        <li className="flex flex-row items-center justify-between pt-[10px]">
          <TextWinds type="content_rel" color="gray7">
            &middot; 상품금액
          </TextWinds>
          <TextWinds type="content_rel" className="flex items-center gap-[5px]">
            {numberWithCommas(productAmount)} 원
          </TextWinds>
        </li>
        <li className="flex flex-row items-center justify-between pt-[10px]">
          <TextWinds type="content_rel" color="gray7">
            &middot; 배송비
          </TextWinds>
          <TextWinds type="content_rel" className="flex items-center gap-[5px]">
            {numberWithCommas(deliveryCharge)} 원
          </TextWinds>
        </li>
      </ul>

      <div className="my-[16px] border border-t-[1px] border-gray4" />

      <div className="flex flex-row items-center justify-between">
        <TextWinds type="content_rel">할인금액</TextWinds>
        <TextWinds type="content_rel" className="flex items-center gap-[5px]">
          {numberWithCommas(salePrice)} 원
        </TextWinds>
      </div>
      <ul className="list-disc">
        <li className="flex flex-row items-center justify-between pt-[10px]">
          <TextWinds type="content_rel" color="gray7">
            &middot; 제품 할인
          </TextWinds>
          <TextWinds type="content_rel" className="flex items-center gap-[5px]">
            {numberWithCommas(discountAmount)} 원
          </TextWinds>{' '}
        </li>
        <li className="flex flex-row items-center justify-between pt-[10px]">
          <TextWinds type="content_rel" color="gray7">
            &middot; 포인트 사용
          </TextWinds>
          <TextWinds type="content_rel" className="flex items-center gap-[5px]">
            {numberWithCommas(pointAmount)} 원
          </TextWinds>{' '}
        </li>
      </ul>

      <div className="my-[16px] border border-t-[1px] border-gray4" />

      <div className="flex flex-row items-center justify-between ">
        <TextWinds type="content_rel">총 결제 금액</TextWinds>
        <TextWinds
          type="content_rel"
          className="flex items-center gap-[5px] font-bold"
          color="purple5"
        >
          {numberWithCommas(totalPrice)} 원
        </TextWinds>
      </div>
    </div>
  );
};

export default PurchaseSummary;
