import React, { useMemo, useState } from 'react';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import TextWinds from '../../../../components/common/TextWinds';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  CourseChapter,
  CourseLesson,
  PaginationProps,
} from '../../../../constants/types';
import LmsSelect, {
  LmsMultiSelect,
  LmsSelectOption,
  toLmsSelectOptions,
} from '../../../../components/lms/LmsSelect';
import { Pagination, Table } from 'antd';
import { myLearningLessonInfoType } from '../../../../constants/types/learning-type';
import { columns, resourceTypeBase, resourceTypeForAdvanced } from './columns';
import { openContentsView } from '../../../../api/utils/util-func';
import {
  getBookChapterList_Light,
  getTakeClassList_Light,
} from '../../../../api/utils/api/lightApi';

const TakeclassLight = () => {
  // const classId = 'zBHuODhRge6wiqJ0';
  const { subtype } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // 북/챕터 리스트
  const [chapterList, setChapterList] = useState<CourseChapter[]>([]);

  // 레슨 SelectBox option 리스트
  const [lessonList, setLessonList] = useState<LmsSelectOption[]>([]);

  // 페이징 정보
  const [pageInfo, setPageInfo] = useState<PaginationProps>({
    page: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  });

  //userSearchParams
  const { chapter, division, page, pageSize } = useMemo(() => {
    return {
      chapter: searchParams.get('chapter') || undefined,
      division: searchParams.get('division')?.split(',') || undefined,
      page: +searchParams.get('page') || 1,
      pageSize: +searchParams.get('pageSize') || 10,
    };
  }, [searchParams]);

  // 수업하기 리스트. 실제 화면에 출력되는 데이터
  const [tableDatas, setTableDatas] = useState<myLearningLessonInfoType[]>([]);

  const courseId = useMemo(() => {
    switch (subtype) {
      case 'wmw':
        return 'VY6Yl4kxQsb3sYJZ';
      case 'basic':
        return 'Irmk2AAW2RWV-iHn';
      default:
        alert('잘못된 접근입니다.');
        return '';
    }
  }, [subtype]);

  // classId 바뀔때마다 북/챕터 SelectBox option 리스트 변경
  useMemo(() => {
    getBookChapterList_Light(courseId).then((res) => {
      setChapterList(res.data);
    });
  }, [subtype]);

  // chapter(북/챕터 선택) 바뀔때마다 레슨 SelectBox option 리스트 변경
  useMemo(() => {
    if (!chapter || chapterList.length === 0) {
      setLessonList([]);
      return;
    }

    setLessonList(
      chapterList
        .find((v) => v.id === +chapter)
        .lessons.map((v) => {
          return { key: v.id, value: v.id, label: v.title };
        })
    );
  }, [chapter]);

  const lesson = useMemo(
    () => searchParams.get('lesson') || undefined,
    [chapter, searchParams]
  );

  //query string 바뀔 때마다 데이터 갈아치우는 부분
  useMemo(() => {
    const params = {
      page: page,
      pageSize: pageSize,
      chapterId: chapter,
      lessonId: lesson,
      type: division,
    };
    getTakeClassList_Light(courseId, params).then((res) => {
      if (page > 1 && res.result.length === 0) {
        searchParams.set('page', res.page.lastPage.toString());
        setSearchParams(searchParams);
        return;
      }
      setPageInfo({
        page: page,
        pageSize: pageSize,
        total: res.page.total,
        lastPage: res.page.lastPage,
      });
      setTableDatas(res.result);
    });
  }, [page, pageSize, chapter, lesson, division]);

  const contentsOpenHandler = (id: string) => {
    const dataToSend = {
      type: 'contentsView',
      contents: {
        classId: courseId,
        contentId: id,
        isLight: true,
      },
    };
    openContentsView(dataToSend);
  };

  // return !chapterList ? null : (
  return (
    <div className="flex flex-col gap-5 m-0 md:m-5 p-5 bg-white rounded-lg">
      <div className="flex gap-2 items-center">
        <ListBulletIcon className="w-6 h-6 text-purple5" />
        <TextWinds type="title_h2" xlType="title_h3" color="purple5">
          수업하기
        </TextWinds>
      </div>

      <div className="p-4 bg-gray1 rounded">
        <TextWinds type="caption1" color="gray8">
          수업에 필요한 자료를 선택한 후 ‘수업하기’ 버튼을 클릭하여 새창에서
          자료를 이용하세요.
        </TextWinds>
      </div>
      <div className="flex flex-col lg:flex-row gap-2 justify-start">
        <LmsSelect
          label="북/챕터"
          options={chapterList.map((v, idx) => ({
            key: idx.toString(),
            value: v.id.toString(),
            label: v.name,
          }))}
          value={chapter}
          onChange={(v) => {
            //이미 선택된 하위 셀렉트 제거
            searchParams.delete('lesson');
            if (v === undefined) {
              searchParams.delete('chapter');
            } else {
              searchParams.set('chapter', v);
            }
            setSearchParams(searchParams);
          }}
          className="w-60"
        />
        <LmsSelect
          label="레슨"
          options={lessonList}
          // value={lessonList.length > 0 ? lesson : undefined}
          value={lesson}
          onChange={(v) => {
            if (v === undefined) {
              searchParams.delete('lesson');
            } else {
              searchParams.set('lesson', v);
            }
            setSearchParams(searchParams);
          }}
          notFoundContent="북/챕터를 먼저 선택해주세요."
          className="w-60"
        />
        <LmsMultiSelect
          label="자료유형"
          options={toLmsSelectOptions(resourceTypeBase, 'key', 'key', 'name')}
          value={division}
          onChange={(v) => {
            if (v.length === 0) {
              searchParams.delete('division');
            } else {
              searchParams.set('division', v.join(','));
            }
            setSearchParams(searchParams);
          }}
          className="w-60"
        />
      </div>
      <div className="flex flex-col gap-5">
        <Table
          dataSource={tableDatas}
          columns={columns(pageInfo, false, contentsOpenHandler)}
          rowKey={(row) => row.id}
          scroll={{ x: 'max-content' }}
          size="small"
          pagination={false}
        />
        <Pagination
          current={page}
          pageSize={pageSize}
          total={pageInfo.total}
          showSizeChanger
          onChange={(page, pageSize) => {
            searchParams.set('page', page.toString());
            searchParams.set('pageSize', pageSize.toString());
            setSearchParams(searchParams);
          }}
          className="justify-end"
        />
      </div>
    </div>
  );
};

export default TakeclassLight;
