import React from 'react';
import TextWinds from '../common/TextWinds';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Buttons from '../common/Buttons';
import { CourseInfoType } from '../../constants/types';
import { useAppDispatch } from '../../store/hook';
import { openAlert } from '../../store/features/modalSlice';
import { INTEREST_FREE_INSTALLMENT } from '../../data/statics-datas';

interface CourseLeftContentsProps {
  hasCourse: boolean;
  courseInfo: CourseInfoType;
  redirectToJebsphoneLink: () => void;
  openDrawerBottom: () => void;
}
const LeftContents: React.FC<CourseLeftContentsProps> = ({
  hasCourse,
  courseInfo,
  redirectToJebsphoneLink,
  openDrawerBottom,
}) => {
  const dispatch = useAppDispatch();
  const infoBtnClick = () => {
    dispatch(openAlert({ message: INTEREST_FREE_INSTALLMENT }));
  };
  return (
    <div className="bg-gray0 p-[10px] sticky top-20 h-fit w-full">
      <div className="relative w-full ">
        <TextWinds type="title_h6" color="gray7">
          설명
        </TextWinds>
        <TextWinds type="content_rel" className="pt-[4px]">
          {courseInfo.description}
        </TextWinds>
      </div>
      <div className="bg-white mt-[16px] p-[10px]">
        {courseInfo.product_sub_type !== 'LEARN_ON_PHONE' && (
          <div
            className="flex items-center gap-[10px] p-[10px] cursor-pointer"
            onClick={infoBtnClick}
          >
            <InformationCircleIcon className="relative w-[24px] h-[24px]" />
            <TextWinds type="content_rel">무이자 할부 안내</TextWinds>
          </div>
        )}
        <div className="flex items-center flex-col justify-center w-full pt-[13px] gap-[13px]">
          {hasCourse ? (
            courseInfo.product_sub_type !== 'LEARN_ON_PHONE' && (
              <Buttons type="filled" disabled color={'purple5'}>
                이용 중
              </Buttons>
            )
          ) : courseInfo.product_sub_type === 'LEARN_ON_PHONE' ? (
            <Buttons
              type="filled"
              onClick={redirectToJebsphoneLink}
              color={'purple5'}
            >
              구매링크 이동
            </Buttons>
          ) : (
            <Buttons type="filled" onClick={openDrawerBottom} color={'purple5'}>
              구매하기
            </Buttons>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftContents;
