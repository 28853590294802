import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { useParams } from 'react-router-dom';
import {
  getOrderMasterComplete,
  selectOrderMasterInfo,
} from '../../../../store/features/purchaseSlice';
import { PaymentMethod } from '../../../../enum';
import CreditCard from './creditcard';
import Deposit from './deposit';
import BankTransfer from './banktransfer';
import ChannelService from '../../../../api/ChannelService';

const CompleteBranch = () => {
  const orderMasterId = useParams().orderMasterId;

  const dispatch = useAppDispatch();
  const orderMasterInfo = useAppSelector(selectOrderMasterInfo);
  useEffect(() => {
    dispatch(
      getOrderMasterComplete({
        url: '/v1/order/master',
        orderMasterId: orderMasterId,
      })
    );
    ChannelService.hideChannelButton();

    return () => {
      ChannelService.showChannelButton;
    };
  }, []);
  return (
    <>
      {/*
      [차후] 케이스 구분 처리 필요
      1. 예외 오류 케이스인 경우
    */}
      {orderMasterInfo &&
        orderMasterInfo.payment_method === PaymentMethod.CREDIT_CARD && (
          <CreditCard data={orderMasterInfo} />
        )}
      {orderMasterInfo &&
        orderMasterInfo.payment_method === PaymentMethod.DEPOSIT && (
          <Deposit data={orderMasterInfo} />
        )}
      {orderMasterInfo &&
        orderMasterInfo.payment_method === PaymentMethod.BANK_TRANSFER && (
          <BankTransfer data={orderMasterInfo} />
        )}
    </>
  );
};

export default CompleteBranch;
