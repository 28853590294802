import React from 'react';
import TextWinds from '../components/common/TextWinds';
import { Spinner } from '@material-tailwind/react';

const WindowClose = () => {
  React.useEffect(() => {
    window.parent.postMessage({ type: 'closeEvent' }, '*');
  }, []);

  return (
    <div className="flex flex-col gap-2 items-center mt-3 mx-auto w-fit ">
      <TextWinds type="title_h3">젭스온 종료중 입니다.</TextWinds>
      <Spinner />
    </div>
  );
};

export default WindowClose;
