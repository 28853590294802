import React, { useContext } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import Buttons from '../../../components/common/Buttons2';
import { useNavigate } from 'react-router-dom';
import { MypageContext } from './MypageContext';
import { TeacherStatus } from '../../../enum';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse, User } from '../../../constants/types';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';

const MypageMembership = () => {
  const { userInfo, setMemberInfo } = useContext(MypageContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const teacherStatus = userInfo?.teacherStatus;

  /**
   * 인증 버튼 활성 조건
   * 1. 교회 정보, 직분 입력 완료
   * 2. 교인확인서 업로드 완료
   * 3. 본인 사진 업로드 완료
   * 4. 인증 요청 전인 경우만 버튼 활성화
   */
  const isActiveCertificateBtn = () => {
    const rtn =
      userInfo?.churchInfo &&
      userInfo?.churchPosition &&
      userInfo?.certificates &&
      userInfo?.headShot &&
      (teacherStatus === TeacherStatus.NONE ||
        teacherStatus === TeacherStatus.PENDING);

    if (rtn) return true;

    return false;
  };

  const putRequestChurchAuth = async () => {
    try {
      const result = await instance.put<ApiResponse<User>>(
        '/v1/mypage/church-info/request-church-authentication'
      );
      return result.data.data;
    } catch (e: any) {
      dispatch(openAlert({ message: e.response.data.error }));
    }
  };

  const handleCertificateBtn = () => {
    putRequestChurchAuth().then((data) => {
      dispatch(openAlert({ message: '인증 요청이 완료되었습니다.' }));
      setMemberInfo(data);
    });
  };

  const getTeacherStatusName = () => {
    if (!teacherStatus) return '인증 요청 하기';
    switch (teacherStatus) {
      case TeacherStatus.NONE:
        return '인증 요청 하기';
      case TeacherStatus.REQUESTED:
        return '인증 요청 중';
      case TeacherStatus.CERTIFIED:
        return '인증 완료';
      case TeacherStatus.PENDING:
        return '인증 실패. 정보를 다시 입력해주세요';
      // default:
      //   return '인증 요청 하기';
    }
  };

  const getStateColor = (teacherStatus: TeacherStatus) => {
    switch (teacherStatus) {
      case TeacherStatus.NONE:
        return 'gray1';
      case TeacherStatus.REQUESTED:
        return 'blue-500';
      case TeacherStatus.CERTIFIED:
        return 'green-500';
      case TeacherStatus.PENDING:
        return 'red-500';
    }
  };
  return (
    <div className="flex flex-col gap-4 w-full">
      <section className="shadow-card p-4 rounded-lg">
        <TextWinds type="title_h2">1. 교회 정보</TextWinds>
        <TextWinds type="title_h4" className="mt-6">
          학습 시작 전, 나의 교회정보 입력하기
        </TextWinds>
        <TextWinds type="content" className="mt-2">
          <br />
          나의 출석 교회 정보를 먼저 등록해 주세요.
          <br />
          교회명, 직분만 등록되어도 학습이 가능합니다 😊
        </TextWinds>

        <Buttons
          variant="outlined"
          color="purple5"
          className="mt-6"
          disabled={
            teacherStatus === TeacherStatus.REQUESTED ||
            teacherStatus === TeacherStatus.CERTIFIED
          }
          onClick={() => navigate('/mypage/membership/church')}
        >
          등록하기
        </Buttons>
      </section>
      <section className="shadow-card p-4 rounded-lg">
        <TextWinds type="title_h2">2. 교인 정보</TextWinds>
        <TextWinds type="title_h4" className="mt-2">
          교인확인서와 사진이 준비되어 있으신가요?
        </TextWinds>
        <TextWinds type="content" className="text-purple5">
          <br />
          혹시 준비 안되셨더라도 걱정마세요!
        </TextWinds>
        <TextWinds type="content">
          다음에 준비해도 교사 코스는 정상적으로 학습 가능합니다.
          <br />
          교사대학 수료 전까지 교인확인서와 사진을 등록해주세요!
        </TextWinds>
        <Buttons
          variant="outlined"
          color="purple5"
          className="mt-6"
          disabled={
            teacherStatus === TeacherStatus.REQUESTED ||
            teacherStatus === TeacherStatus.CERTIFIED
          }
          onClick={() => navigate('/mypage/membership/member')}
        >
          등록하기
        </Buttons>
      </section>
      <section
        className={`shadow-card p-4 rounded-lg border-${getStateColor(
          teacherStatus
        )} border-2`}
      >
        <TextWinds type="title_h2">3. 인증 요청</TextWinds>
        <TextWinds type="title_h4" className="mt-6">
          모든 정보 입력이 완료된 후 인증 요청을 진행해 주세요.
        </TextWinds>
        <TextWinds type="content" className="mt-2">
          서류 제출 후 인증이 완료되면 클래스를 이용할 수 있습니다.
          <br />
          서류 승인은 영업일 기준 1~2일 소요됩니다.
        </TextWinds>
        <Buttons
          variant="filled"
          color="purple5"
          className="mt-6"
          disabled={!isActiveCertificateBtn()}
          onClick={handleCertificateBtn}
        >
          {getTeacherStatusName()}
        </Buttons>
      </section>
    </div>
  );
};

export default MypageMembership;
