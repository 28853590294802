import { SetStateAction, useState } from "react";

import NormalPage from "../../../components/layout/NormalPage";
import LearningTabs from "./component/LearningTabs";
import LearningLecturePage from "./Detail/LearningLecturePage";
import { useParams } from "react-router-dom";

const LearningList = () => {
    const {learningId} = useParams();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(1);
    const [sortingState, setSortingState] = useState("default");

    const handleOpen = (value: SetStateAction<number>) => setOpen(open === value ? 0 : value);
    const headData = [
        {
            label: "전체",
            value: "tab_1",
        },
        // {
        //     label: "진행 중",
        //     value: "tab_2",
        // },
        // {
        //     label: "완료",
        //     value: "tab_3",
        // },
    ];
    const handleChangeTab = (tabValue: string) => {
        
    };

    const handleSortChange = (sortState: string) => {
        // Handle the sorting state change here
        setSortingState(sortState);
    };

    return (
        <>
            <NormalPage xlBgColor="gray1" bgColor="gray1" paddingY={0} paddingX={0}>
                <LearningTabs data={headData} onChange={(tabValue) => handleChangeTab(tabValue)}>
                    {/* <div className="flex justify-between">
                        <div className="flex gap-[6px]">
                            <TextWinds type="title_h4">진행 중</TextWinds>
                            <TextWinds type="content" color="gray6">
                                (25개 중 2개 미완료)
                            </TextWinds>
                        </div>
                        <SortingButton onSortChange={handleSortChange} />
                    </div> */}
                    <div className="flex flex-col gap-4 w-full  mx-auto px-[20px] py-[16px] xl:px-[2px]">
                        {/* <LearningAccordion data={data} /> */}
                        <LearningLecturePage learningId={learningId}/>
                    </div>
                </LearningTabs>
            </NormalPage>
        </>
    );
};

export default LearningList;
