import React from "react";
import TextWinds from "../common/TextWinds";

interface InfoProps {
    title: string;
    text: string[];
    className?: string;
}

const InfoText = ({ title, text, className }: InfoProps) => (
    <div className={`p-3 bg-gray1 rounded ${className}`}>
        <div className="mb-1">
            <TextWinds type="content_body3">{title}</TextWinds>
        </div>
        <div className="text-gray9">
            <ul className="text-[12px] leading-[20px]">
                {text.map((item, key) => (
                    <li key={key} className="list-dot">
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default InfoText;
