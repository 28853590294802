import React from 'react';
import TextWinds from '../common/TextWinds';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';

export interface ModalTicketConfirmProps {
  title: string;
  message: string;
  cancelText?: string;
  confirmText?: string;
  confirmColor?: string;
  confirmHoverColor?: string;
  onConfirm: () => void;
}
const ModalTicketConfirm = ({
  title,
  message,
  cancelText = '취소',
  confirmText = '확인',
  confirmColor = 'orange5',
  confirmHoverColor = 'orange4',
  onConfirm: onclick,
}: ModalTicketConfirmProps) => {
  const dispatch = useAppDispatch();
  const closeBtnhandler = () => {
    dispatch(closeModal());
  };
  const confirmBtnhandler = () => {
    dispatch(closeModal());
    onclick();
  };
  return (
    <div className="flex flex-col gap-4 w-[335px] p-4 bg-white shadow-card mb-40 rounded-md mountAnimation">
      <div>
        <TextWinds type='title_h4'>{title}</TextWinds>
      </div>
      <div className='flex flex-col'>
        {
          message.split('\n').map((line, index) => (
            <div key={index} className='text-center'>{line}</div>
          ))
        }
      </div>

      <div className="grid grid-cols-2 gap-2">
        <TextWinds
          type="button2"
          color="purple5"
          className="cursor-pointer hover:bg-gray2 p-2 rounded-md border text-center"
          onClick={closeBtnhandler}
        >
          {cancelText}
        </TextWinds>
        <TextWinds
          type="button2"
          color="orange5"
          className={`cursor-pointer hover:bg-gray2 p-2 rounded-md border text-center bg-${confirmColor} text-white hover:bg-${confirmHoverColor}`}
          onClick={confirmBtnhandler}
        >
          {confirmText}
        </TextWinds>
      </div>
    </div>
  );
};

export default ModalTicketConfirm;
