import { ImageCards } from './cardimages';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
export type CardItemProps = {
  img: string;
  title: string;
  link: string;
  type: 'external' | 'internal';
};
interface CardsProps {
  items: CardItemProps[];
}
export const Cards = ({ items }: CardsProps) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="grid items-center grid-cols-1 md:grid-cols-2 my-6 gap-2 lg:gap-x-8 lg:gap-y-6 lg:grid-cols-4">
        {items.map((props, key) => (
          <div key={key} onClick={()=>navigate(props.link)}>
            <ImageCards
            key={key}
              img={process.env.PUBLIC_URL + props.img}
              title={props.title}
            />
          </div>
        ))}
      </div>
      {/* <div className="grid px-4 py-4 place-items-center">
                <Button variant="text" size="lg" color="gray" className="flex items-center gap-2">
                    VIEW MORE
                    <ChevronDownIcon className="w-5 h-5 font-bold text-gray-900" />
                </Button>
            </div> */}
    </Fragment>
  );
};
