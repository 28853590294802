import { useState } from 'react';
import instance from '../../../../api/axios/utils.ts/instance';
import {
  dateToSlash,
  filterAndUpperCase,
} from '../../../../api/utils/util-func';
import HFbutton from '../../../../components/common/input/HFbutton';
import HFinput from '../../../../components/common/input/HFinput';
import CertificateImg from '../../../../components/mypage/CertificateImg';
import { ApiResponse } from '../../../../constants/types';
import {
  CertificateImgEnum,
  TeacherLicenseType,
} from '../../../../constants/types/mypage-type';
import { closeModal, openAlert } from '../../../../store/features/modalSlice';
import { useAppDispatch } from '../../../../store/hook';
import TextWinds from '../../../../components/common/TextWinds';
import { myLearnigDetailType } from '../../../../constants/types/learning-type';

const CertificateModal = ({
  coursedId,
  courseName,
  learningId,
  userEnglishName,
  type,
  readonly = false,
  reloadData,
}: {
  coursedId: string;
  courseName: string;
  learningId: string;
  userEnglishName: string;
  type: CertificateImgEnum;
  readonly?: boolean;
  reloadData?: () => Promise<myLearnigDetailType>;
}) => {
  const dispatch = useAppDispatch();
  const [ename, setEname] = useState<string>(userEnglishName);
  const getLicense = async () => {
    const getLicenseAction = async () => {
      try {
        const param = {
          coursedId: coursedId,
          courseName: courseName,
          learningId: learningId,
          userEnglishName: ename,
          graduationAt: new Date(),
        };
        const response = await instance.post<ApiResponse<TeacherLicenseType[]>>(
          '/v1/mypage/teacher-license',
          param
        );
        const res = response.data;
      } catch (e) {
        console.error(e);
      }
    };

    const putUserEnglishName = async () => {
      try {
        const response = await instance.put('v1/mypage/user', {
          englishName: ename,
        });
        return response.data;
      } catch (e) {
        console.error(e);
      }
    };

    await putUserEnglishName();
    await getLicenseAction();
    if (reloadData) await reloadData();

    dispatch(openAlert({ message: '수료증이 발급되었습니다.' }));
  };

  const englishNameHandler = (ename: string) => {
    if (!ename) {
      setEname('');
      return;
    }
    setEname(filterAndUpperCase(ename));
  };
  return (
    <div className="bg-white p-5 rounded">
      <CertificateImg
        name={ename}
        type={type}
        date={dateToSlash(new Date())}
        readonly
      />
      <div className=" mt-5">
        {!readonly && (
          <>
            <TextWinds type="caption1">
              수료증 발급을 위해 영문 이름을 확인해주세요.
            </TextWinds>
            <HFinput
              height={34}
              onChange={(e) => englishNameHandler(e.target.value)}
              value={ename}
              placeholder="영문 대문자로 입력해주세요."
            />
          </>
        )}
        <div className="flex flex-col gap-3">
          {!readonly && (
            <HFbutton onClick={() => getLicense()}>수료증 발급</HFbutton>
          )}
          <HFbutton onClick={() => dispatch(closeModal())}>닫기</HFbutton>
        </div>
      </div>
    </div>
  );
};

export default CertificateModal;
