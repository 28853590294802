import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer';
import MegaMenu from './common/layouts/megamenu';
import ScrollToTop from './layout/ScrollToTop';

const RootLayout = () => {
  return (
    <>
      <MegaMenu />
      <main className="mt-[58px]">
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </main>
      <Footer />
    </>
  );
};

export default RootLayout;
