import { motion } from 'framer-motion';
import React from 'react';
import { ReviewBox, CircleRightArrowIcon } from '../wmw/WmwComp';
import Memoji01 from '../img/sec04-memoji01.png';
import Memoji02 from '../img/sec04-memoji02.png';
import Memoji03 from '../img/sec04-memoji03.png';
const HFHJSection4 = () => {
  const blogUrl =
    'https://blog.naver.com/PostList.naver?blogId=jebs_jemm&from=postList&categoryNo=36&parentCategoryNo=36';
  return (
    <>
      <div className="bg-gradient-to-b from-[#FFA319] to-[#FFFFFF] from-10% to-80% flex justify-center p-3 py-10 md:p-12 md:py-20">
        <div className="w-full max-w-[520px]">
          <div className="flex flex-col gap-8 md:gap-12">
            {/* 타이틀 */}
            <section>
              <div className="text-3xl md:text-4xl text-center font-['Pretendard'] text-white">
                왜 <span className="font-bold">HFHJ</span>인지 알 수 있는
                <div className="font-bold text-[28px] md:text-4xl">
                  동역자들의 수 많은 실제후기!
                </div>
              </div>
            </section>

            {/* 리뷰 컨텐츠 */}
            <section className="flex flex-col gap-3 md:gap-5">
              <ReviewBox img={Memoji01} star={5}>
                <div className="text-xs sm:text-sm md:text-base font-['Pretendard'] pr-3">
                  유치부 어린이들을 위한 쉽고 재미있는 성경 공부예요!
                </div>
              </ReviewBox>

              <ReviewBox img={Memoji02} star={5}>
                <div className="text-xs sm:text-sm md:text-base font-['Pretendard'] pr-5">
                  어려워하던 성경 이야기를 영어로, 그림으로 재미있게 공부하니
                  아이들이 재미있어해요!
                </div>
              </ReviewBox>

              <ReviewBox img={Memoji03} star={5}>
                <div className="text-xs sm:text-sm md:text-base font-['Pretendard'] pr-3">
                  아이들과 구약과 신약을 어느새 마스터했어요!
                </div>
              </ReviewBox>

              <div className="blur-sm">
                <ReviewBox img={Memoji02} star={5} isSmall={true}>
                  <div className="text-xs sm:text-sm md:text-base font-['Pretendard'] pr-3">
                    어려워하던 성경 이야기를 영어로, 그림으로 재미있게 공부하니
                    아이들이 재미있어해요!
                  </div>
                </ReviewBox>
              </div>
            </section>

            {/* 버튼 */}
            <section>
              <motion.div
                className="max-w-[280px] max-h-[32px] w-full mx-auto flex justify-center gap-3 items-center rounded-full bg-[#FCAF3C] p-3 md:p-5 cursor-pointer"
                whileHover={{ scale: 1.05, opacity: 0.9 }}
                onClick={() => window.open(blogUrl, '_blank')}
              >
                <div className="text-white text-sm md:text-base font-bold font-['Pretendard']">
                  더 많은 리뷰 보러가기
                </div>
                <CircleRightArrowIcon width={18} color="white" />
              </motion.div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default HFHJSection4;
