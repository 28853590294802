import { Button, Card, CardBody, CardFooter } from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';
import Login from './login';
import NormalPage from '../../components/layout/NormalPage';
import TextWinds from '../../components/common/TextWinds';
import Icon from '../../components/common/Icon';

const SignIn = () => {
  const navigate = useNavigate();
  /*
    const {values, errors, isLoading, handleChange, handleSubmit } = useForm({
        initialValues: { userId: '', password: '' },
        onSubmit: (values: any) => {
            // axios.post('http://43.200.7.84:3000/auth/sign-in', {userId: values.userId, password: values.password})
            // .then( (response) => {
            // })
            // .catch(function(error) {
            // })
            fetch('http://43.200.7.84:3000/auth/sign-in', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(values)
            })
            .then( (response: any) => {
            })
            .catch( (error: any) => {
            })
            .then( (data: any) => {
            })
            // alert(JSON.stringify(values, null, 2))
        },
        validate
    })
    */

  const handelr = () => {
    navigate('https://dev.jebs.kr:3000/v1/oauth/kakao');
  };

  return (
    <>
      <NormalPage xlBgColor="gray0">
        <div className="w-full xl:w-[580px] xl:mx-auto xl:pt-[45px] xl:px-0">
          <div className="text-left xl:text-center">
            <TextWinds type="title_h1">일반 회원 로그인</TextWinds>
          </div>
          <Card
            color="white"
            className="shadow-none mt-[36px] mb-[27px] xl:shadow-card rounded-none "
          >
            <CardBody className="px-0 xl:py-[40px] xl:px-[32px]">
              <Login />
            </CardBody>

            <CardFooter className="px-0 pt-0 xl:px-[32px]">
              <div className="mb-[16px]">
                <TextWinds type="title_h4">간편 로그인</TextWinds>
              </div>
              <div>
                {/* <Button
                  size="lg"
                  color="white"
                  className="flex items-center justify-center gap-[10px] border border-gray9 border-solid"
                  fullWidth
                >
                  <Icon width={24} height={24} fileName={'login_apple'}></Icon>
                  <TextWinds type="title_h4">Apple 아이디로 로그인</TextWinds>
                </Button> */}
                <Link
                  to={process.env.REACT_APP_JEBS_API_URL + '/v1/oauth/kakao'}
                >
                  <Button
                    size="lg"
                    color="white"
                    className="flex items-center justify-center gap-[10px] shadow-md mt-[8px] bg-[#FEE500]"
                    fullWidth
                  >
                    <Icon
                      width={24}
                      height={24}
                      fileName={'login_kakao'}
                    ></Icon>
                    <TextWinds type="title_h4">
                      카카오 아이디로 로그인
                    </TextWinds>
                  </Button>
                </Link>
              </div>
            </CardFooter>
            {/* <Typography variant="paragraph" className="mt-4 font-medium text-center text-blue-gray-500">
                            Not registered?
                            <Link to="sign-up" className="ml-1 text-gray-900">Create account</Link>
                        </Typography> */}
          </Card>
        </div>
      </NormalPage>
    </>
  );
};

export default SignIn;
