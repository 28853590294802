import React, { useEffect, useRef } from 'react';
import { closeModal } from '../../store/features/modalSlice';
import { useAppDispatch } from '../../store/hook';
import HFbutton from '../common/input/HFbutton';
import { downloadDiv } from '../../api/utils/util-func';
import dayjs from 'dayjs';
import {
  ConfettiFirework,
  ConfettiFireworkRef,
} from '../confetti/ConfettiFirework';

interface ModalRecitationCertificateProps {
  englishName: string;
  subject: string;
  date: Date;
}
const ModalRecitationCertificate = ({
  englishName,
  subject,
  date,
}: ModalRecitationCertificateProps) => {
  const dispatch = useAppDispatch();
  const confettiRef = useRef<ConfettiFireworkRef>(null);
  const downloadHandler = () => {
    downloadDiv('certificate', `${englishName} ${subject}`);
  };
  const closeHandler = () => {
    dispatch(closeModal());
  };

  const triggerConfetti = () => {
    confettiRef.current?.startFire();
  };

  useEffect(() => {
    triggerConfetti();
  }, []);
  return (
    <div className="relative flex flex-col gap-2 mt-[140px] xl:mt-0 bg-white p-5 rounded-lg w-full max-w-[240px]">
      <div className="relative" id="certificate" onClick={triggerConfetti}>
        <div className="absolute top-0 left-0 w-full">
          <span className="absolute top-[126px] w-full text-center text-[16px]">
            {englishName}
          </span>
          <span className="absolute top-[170px] w-full left-[42px] text-center block text-[8px]">
            {subject}
          </span>
          <span className="absolute top-[190px] w-full left-[15px] text-center block text-[8px]">
            {dayjs(date).format('YYYY-MM-DD')}
          </span>
        </div>
        <img
          src={process.env.PUBLIC_URL + '/images/certification/recitation.jpg'}
        />
      </div>
      <HFbutton height={36} onClick={downloadHandler}>
        다운로드
      </HFbutton>
      <HFbutton height={36} type="Outlined" onClick={closeHandler}>
        닫기
      </HFbutton>
      <div className="fixed w-full h-screen left-0 top-0 pointer-events-none">
        <ConfettiFirework ref={confettiRef} />
      </div>
    </div>
  );
};

export default ModalRecitationCertificate;
