import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit"
import { RootState } from ".."
import instance from "../../api/axios/utils.ts/instance"
import { AxiosParamType } from "."
import { CartItem } from "../../constants/types"
import { OrderItemDto } from "../../constants/types/product-type"

export interface cartCheckedProps {
  id: string
  isChecked: boolean
}

interface UpdateCartItemQuantityDto {
  order_item_id: string
  variant_id: string
  quantity: number
}
interface AddToCartDto {
  order_item: OrderItemDto
}

interface CartState {
  status: string;
  cartLists: CartItem[];
  selectItems: cartCheckedProps[];
}
const initialState: CartState = {
  status: '',
  cartLists: [],
  selectItems: [],
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(getCartLists.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(getCartLists.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.cartLists = action.payload;
    })
    .addCase(getCartLists.rejected, (state) => {
      state.status = 'rejected'
    })
    .addCase(addCart.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(addCart.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    })
    .addCase(addCart.rejected, (state) => {
      state.status = 'rejected';
    })
    .addCase(updateCartItemQuantity.pending, (state) => {
      state.status = 'pending'
    })
    .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
      state.status = 'fulfilled'
    })
    .addCase(updateCartItemQuantity.rejected, (state) => {
      state.status = 'rejected'
    })
    .addCase(addTemplateFromCart.pending, (state) => {
      state.status = 'pending'
    })
    .addCase(addTemplateFromCart.fulfilled, (state, action) => {
      state.status = 'fulfilled'
    })
    .addCase(addTemplateFromCart.rejected, (state) => {
      state.status = 'rejected'
    })
  }
})
export const getCartLists = createAsyncThunk<CartItem[], AxiosParamType>(
  'asyncThunk/getCartLists',
  async ({url, params}) => {
    try {
      const response = await instance.get(url, {params});
      return response.data;
    } catch(error) {
      console.error('[axios] getShopLists : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const addCart = createAsyncThunk(
  'asyncThunk/addCart',
  async ({ url, data }: {url: string, data: AddToCartDto}) => {
    try {
      const response = await instance.post(url, data);
      return response.data;
    } catch(error) {
      console.error('[axios] getShopLists : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const singleRemoveItem = createAsyncThunk(
  'asyncThunk/singleRemoveItem',
  async ({ url }: {url: string}) => {
    try {
      const response = await instance.delete(url);
      return response.data;
    } catch(error) {
      console.error('[axios] getShopLists : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const updateCartItemQuantity = createAsyncThunk(
  'asyncThunk/updateCartItemQuantity',
  async ({url, data}: {url: string, data: UpdateCartItemQuantityDto}) => {
    try {
      const response = await instance.put(url, data);
      return response.data;
    } catch(error) {
      console.error('[axios] getShopLists : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const addTemplateFromCart = createAsyncThunk(
  'asyncThunk/addTemplateFromCart',
  async ({ url, params }: AxiosParamType) => {
    try {
      const response = await instance.post(url, params);
      return response.data;
    } catch(error) {
      return isRejectedWithValue(error)
    }
  }
)

export const selectItemsLists = (state: RootState) => state.cart.selectItems;
export const selectCartLists = (state: RootState) => state.cart.cartLists;
export default cartSlice.reducer