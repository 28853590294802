import ChannelService from '../../../api/ChannelService';
import { openNewWindow } from '../../../api/utils/util-func';
import Buttons from '../../../components/common/Buttons2';
import TextWinds from '../../../components/common/TextWinds';

const MypageCustomer = () => {
  return (
    <>
      <section className="px-5 py-6 lg:p-0">
        <div className="hidden lg:block lg:mb-6">
          <TextWinds type="title_h2">고객센터</TextWinds>
        </div>
        <div className="mb-2">
          <TextWinds type="sub_title01">자주 묻는 질문 (FAQ)</TextWinds>
        </div>
        <div className="mb-4 lg:mb-[38px]">
          <TextWinds type="content_body4">
            문의 전 자주 묻는 질문 리스트를 통해 답변을 빠르게 확인하실 수
            있어요.
          </TextWinds>
        </div>
        <div className="mb-7 lg:w-[476px] lg:mx-auto lg:mb-10">
          <Buttons
            variant="filled"
            color="purple5"
            onClick={() =>
              openNewWindow(
                'https://jebs-notice.oopy.io/3445e325-9c88-40d5-a77f-a862a8e46af5'
              )
            }
          >
            <TextWinds type="button" color="white">
              이용가이드
            </TextWinds>
          </Buttons>
        </div>
        <div className="mb-[15px]">
          <TextWinds type="sub_title01">
            고객센터 10:00 ~ 17:00
            <br />
            점심시간 13:00 ~ 14:00
          </TextWinds>
        </div>
        <div className="mb-4 customer-dot lg:mb-[38px]">
          <TextWinds type="content_body4">
            평일 문의가 가능합니다. (주말 및 공휴일 제외)
          </TextWinds>
          <TextWinds type="content_body4">
            주말 및 공휴일의 경우 1:1 채팅 상담에 문의 남겨주시면 운영시간 내
            순차적으로 답변드려요.
          </TextWinds>
        </div>
        <div className="flex flex-col gap-y-2 lg:items-center">
          <div className="lg:w-[476px]">
            <Buttons
              variant="filled"
              color="purple5"
              onClick={() => {
                ChannelService.openChat();
              }}
            >
              <TextWinds type="button" color="white">
                1:1 채팅 문의하기 (추천)
              </TextWinds>
            </Buttons>
          </div>
          <div className="lg:w-[476px]">
            <Buttons variant="outlined" color="purple5" onClick={()=>document.location.href='tel:1800-0588'}>
              <TextWinds type="button" color="purple5">
                1800-0588 전화 문의하기
              </TextWinds>
            </Buttons>
          </div>
          {/* <div className="lg:w-[476px]">
                        <Buttons variant="outlined" color="purple5">
                            <TextWinds type="button" color="purple5">
                                이메일 문의하기
                            </TextWinds>
                        </Buttons>
                    </div> */}
        </div>
      </section>
    </>
  );
};

export default MypageCustomer;
