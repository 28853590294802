import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowDownTrayIcon, PhotoIcon } from "@heroicons/react/24/outline";
import Buttons from "../../../components/common/Buttons2";
import TextWinds from "../../../components/common/TextWinds";
import MoLayout from "../../../components/lms/MoLayout";
import PcLayout from "../../../components/lms/PcLayout";

const NoticeDetail = () => {
    const detailId = useParams().detailId;
    const params = useLocation().state;
    const navigate = useNavigate();
    const clickHandler = (type: string) => {
        switch (type) {
            case "list":
                callNoticeLists();
                break;
            case "edit":
                callNoticeEditor();
                break;
            case "delete":
                callNoticeDelete();
                break;
        }
    };
    const callNoticeLists = () => {
        navigate(-1);
    };
    const callNoticeEditor = () => {

        navigate(`/lms/notice/detail/${detailId}`);
        navigate({ pathname: "/lms/notice/editor" }, { state: { mode: "edit", data: params } });
    };
    const callNoticeDelete = () => {
    };
    return (
        <Fragment>
            <MoLayout title="공지 관리" />
            <PcLayout
                secClassName="pt-4 px-5"
                divClassName="xl:gap-y-4"
                topClassName="hidden xl:flex justify-between items-center"
                top={<TextWinds type="title_h3">공지 관리</TextWinds>}
                bottom={
                    <div className="flex flex-col gap-y-8 xl:gap-y-6">
                        <div className="flex flex-col gap-y-2 pb-2 border-b border-gray5 xl:pb-3">
                            <div className="flex flex-col gap-y-2 xl:flex-row xl:justify-between xl:items-end">
                                <TextWinds type="sub_title01" xlType="title_h3">
                                    {params.title}
                                </TextWinds>
                                <div className="flex justify-between xl:gap-x-5">
                                    <TextWinds type="content_body4" xlType="content_body3">
                                        {params.createdAt}
                                    </TextWinds>
                                    <TextWinds type="content_body4" xlType="content_body3">
                                        조회수: {params.viewsCnt}
                                    </TextWinds>
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-[14px] xl:flex-row xl:items-center xl:gap-x-[14px]">
                                <TextWinds type="content_body3">첨부 파일</TextWinds>
                                <div className="flex flex-col gap-y-2.5 xl:flex-row xl:gap-x-2.5">
                                    <Buttons
                                        variant="filled"
                                        className="gap-x-1 max-w-max h-max px-2.5 py-[6px] shadow-none rounded-[20px]"
                                        color="gray2"
                                    >
                                        <TextWinds type="chips" color="gray9">
                                            220823_홈이 1년차_lesson 01 추가자료.docx
                                        </TextWinds>
                                        <ArrowDownTrayIcon className="w-5 h-5 text-gray7" />
                                    </Buttons>
                                    <Buttons
                                        variant="filled"
                                        className="gap-x-1 max-w-max h-max px-2.5 py-[6px] shadow-none rounded-[20px] border-0"
                                        color="gray2"
                                    >
                                        <TextWinds type="chips" color="gray9">
                                            220823_홈이 1년차_lesson 01 추가자료.docx
                                        </TextWinds>
                                        <ArrowDownTrayIcon className="w-5 h-5 text-gray7" />
                                    </Buttons>
                                </div>
                            </div>
                        </div>
                        <div className="pb-8 border-b border-gray5">
                            <TextWinds type="content_body2">본문 내용 기본 16pt</TextWinds>
                        </div>
                        <div className="xl:flex xl:justify-center">
                            <div className="flex flex-col gap-y-2 xl:flex-row xl:gap-x-2 xl:w-[380px]">
                                <Buttons variant="filled" color="purple5" onClick={() => clickHandler("list")}>
                                    <TextWinds type="button" color="white">
                                        목록으로
                                    </TextWinds>
                                </Buttons>
                                <Buttons variant="outlined" color="purple5" onClick={() => clickHandler("edit")}>
                                    <TextWinds type="button" color="purple5">
                                        수정하기
                                    </TextWinds>
                                </Buttons>
                                <Buttons variant="outlined" color="red5" onClick={() => clickHandler("delete")}>
                                    <TextWinds type="button" color="red5">
                                        삭제하기
                                    </TextWinds>
                                </Buttons>
                            </div>
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
};

export default NoticeDetail;
