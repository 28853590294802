import React from 'react';
import {
  DoubleQuote,
  IconCard,
  OrangeCloud,
  SpeechBubble,
} from './HomeschoolComp';
import { motion } from 'framer-motion';
import CircleWoman1 from './img/CircleWoman1.png';
import CircleWoman2 from './img/CircleWoman2.png';
import CircleWoman3 from './img/CircleWoman3.png';
import icon1 from './img/icon-doubleQuote.png';
import icon2 from './img/icon-packman.png';
import icon3 from './img/icon-star.png';
import ReactPlayer from 'react-player';

const HomeschoolSection2 = () => {
  return (
    <>
      <motion.div
        className="relative mt-[-260px]"
        initial={{ y: -40 }}
        whileInView={{ y: -55 }}
        transition={{ duration: 1 }}
      >
        <OrangeCloud width={'full'} />
      </motion.div>
      <div className="relative mt-[-60px] w-full h-[40px] bg-[#EB5D1A]"></div>
      <div className="relative bg-[#EB5D1A] pb-10" id="2">
        <div className="w-fit m-auto">
          <DoubleQuote width={20} />
        </div>
        <div className="text-[#fff0c9] text-center text-2xl md:text-4xl font-bold">
          우리 아이 영어교육,
        </div>
        <div className="text-white text-center text-2xl md:text-4xl font-bold">
          이렇게 걱정하고 계신가요?
        </div>
        {/* 말풍선 리스트 */}
        <div className="flex flex-col gap-10 text-sm md:text-xl p-5 max-w-[480px] mt-10 m-auto">
          <SpeechBubble
            textArea={
              <div className="pl-2 xl:pl-5">
                <div className="font-bold">영어교재 속 비성경적인 가치관이</div>
                아이에게 심겨지면 어떡하지?
              </div>
            }
            src={CircleWoman1}
            direction="left"
          />

          <SpeechBubble
            textArea={
              <div className="pl-2 xl:pl-5">
                <span className="font-bold">
                  꾸준히 해도 영어로 말 한마디
                  <br /> 못하는{' '}
                </span>
                우리아이... 어떡하지?
              </div>
            }
            src={CircleWoman2}
            direction="right"
          />
          <SpeechBubble
            textArea={
              <div className="pl-2 xl:pl-5">
                <span className="font-bold">교육비, 가성비, 콘텐츠 </span>
                <br /> 모두 갖춘 프로그램 없을까?
              </div>
            }
            src={CircleWoman3}
            direction="left"
          />
        </div>
        <div className="flex flex-col gap-5 my-10">
          <motion.div
            className="w-4 h-4 bg-white rounded-full m-auto"
            initial={{ opacity: 0.2 }}
            animate={{ opacity: 1 }}
            transition={{
              repeatType: 'reverse',
              repeat: Infinity,
              duration: 1,
              delay: 0.2,
            }}
          ></motion.div>
          <motion.div
            className="w-4 h-4 bg-white rounded-full m-auto opacity-60"
            initial={{ opacity: 0.2 }}
            animate={{ opacity: 1 }}
            transition={{
              repeatType: 'reverse',
              repeat: Infinity,
              duration: 1,
              delay: 0.7,
            }}
          ></motion.div>
          <motion.div
            className="w-4 h-4 bg-white rounded-full m-auto opacity-30"
            initial={{ opacity: 1 }}
            animate={{ opacity: 0.2 }}
            transition={{
              repeatType: 'reverse',
              repeat: Infinity,
              duration: 1,
              delay: 0.2,
            }}
          ></motion.div>
        </div>
        <div className="text-3xl text-[#ffcbb3] text-center">
          이제 ‘마음’만 먹으세요!
        </div>
        <motion.div
          className="text-white font-bold text-center mt-3"
          initial={{ transform: 'scale(0)' }}
          whileInView={{ transform: 'scale(1)' }}
        >
          좋은 가격부터 좋은 내용의 프로그램까지,
          <br />
          수업에 필요한 모든 것은 젭스가 제공합니다.
        </motion.div>
        <div className="text-center text-white text-2xl font-bold mt-5">
          ✨11월 홈스쿨 온라인 설명회✨
        </div>
        <div className="flex flex-col gap-5 p-5">
          <div className="w-full max-w-[480px] m-auto aspect-video">
            <ReactPlayer
              url="https://youtu.be/2enuFrPyPRg"
              width="100%"
              height="100%"
            />
          </div>
          <div className="grid grid-cols-3 gap-5 w-full max-w-[480px] m-auto text-sm md:text-base">
            <IconCard
              src={icon1}
              textArea={
                <>
                  <div className="text-gray6 text-xs">데이빗 정 대표의</div>
                  젭스 비전
                </>
              }
            />
            <IconCard
              src={icon2}
              textArea={
                <>
                  <div className="text-gray6">젭스학습법</div>
                  수업 시연
                </>
              }
            />

            <IconCard
              src={icon3}
              textArea={
                <>
                  <div className="text-gray6">운영방법, 비용</div>
                  도입 방법
                </>
              }
            />
          </div>
          <div className="text-center text-white font-bold mt-5">
            크리스천 자녀교육에 유익한 내용
            <br />
            설명회에서 모두 얻어가세요!
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeschoolSection2;
