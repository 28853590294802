import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Buttons from '../common/Buttons';
import { Button, Checkbox, Input } from '@material-tailwind/react';
import TextWinds from '../common/TextWinds';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  postDeliveryInfo,
  putDeliveryInfo,
  selectEditDeliveryInfo,
} from '../../store/features/orderInfoSlice';
import { DeliveryProps } from '../../constants/types';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { phoneFormat, regPhone } from '../../api/utils/format/numbers';

type DeliveryDTO = Omit<
  DeliveryProps,
  'id' | 'userId' | 'createdAt' | 'updatedAt' | 'deletedAt'
>;
interface DeliveryInfoChangeProps {
  setState: Dispatch<SetStateAction<'form' | 'none' | 'list'>>;
}
const DeliveryInfoChange = ({ setState }: DeliveryInfoChangeProps) => {
  const dispatch = useAppDispatch();
  const delivery = useAppSelector(selectEditDeliveryInfo);
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryDTO>(delivery);

  const daumPostHook = useDaumPostcodePopup();

  /**
   * 배송지 상태 변경 핸들러
   * @param key
   * @param value
   */
  const inputChangeHandle = (key: string, value: string | boolean | number) => {
    setDeliveryInfo({ ...deliveryInfo, [key]: value });
  };

  const checkValidation = (deliveryInfo: DeliveryDTO) => {
    if (!deliveryInfo || !deliveryInfo.name) {
      window.alert('배송지 정보를 입력해주세요.');
      return false;
    }
    if (!deliveryInfo.phone || !regPhone.test(deliveryInfo.phone)) {
      window.alert('올바른 전화번호를 입력해주세요.');
      return false;
    }
    if (!deliveryInfo.zipCode || !deliveryInfo.address) {
      window.alert('주소를 입력해주세요.');
      return false;
    }
    return true;
  };

  /**
   * 배송지 정보 추가
   */
  const onSubmitPost = () => {
    if (checkValidation(deliveryInfo) === false) return;

    const params = {
      ...deliveryInfo,
      detailAddress: deliveryInfo?.detailAddress
        ? deliveryInfo?.detailAddress
        : '',
      isDefault: deliveryInfo?.isDefault ? true : false,
    };

    dispatch(postDeliveryInfo({ url: '', params: params })).then((res) => {
      // 통신 후 페이지 배송지 목록으로 이동
      setState('list');
    });
  };

  /**
   * 배송지 정보 수정
   */
  const onSubmitPut = () => {
    if (checkValidation(deliveryInfo) === false) return;

    const params: DeliveryDTO & { id: number } = {
      id: delivery?.id,
      name: deliveryInfo?.name,
      phone: deliveryInfo?.phone,
      zipCode: deliveryInfo?.zipCode,
      address: deliveryInfo?.address,
      detailAddress: deliveryInfo?.detailAddress,
      isDefault: deliveryInfo?.isDefault,
    };
    dispatch(putDeliveryInfo({ url: '', params: params })).then((res) => {
      setState('list');
    });
  };

  const daumPostHandleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setDeliveryInfo({
      ...deliveryInfo,
      zipCode: data.zonecode,
      address: fullAddress,
    });
  };

  const daumPostHandleClick = () => {
    daumPostHook({ onComplete: daumPostHandleComplete });
  };

  const phoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setDeliveryInfo({ ...deliveryInfo, phone: value });
  };

  return (
    <div className="flex flex-col gap-4">
      <Input
        type="text"
        label="배송지명"
        size="lg"
        value={deliveryInfo?.name}
        onChange={(e) => inputChangeHandle('name', e.target.value)}
      />
      <Input
        type="text"
        label="연락처"
        size="lg"
        value={phoneFormat(deliveryInfo?.phone)}
        onChange={phoneHandler}
      />
      <div className="relative flex w-full">
        <Input
          type="text"
          label="우편번호"
          value={deliveryInfo?.zipCode}
          disabled
          onChange={(e) => inputChangeHandle('zipCode', e.target.value)}
          onClick={daumPostHandleClick}
          containerProps={{
            className: 'min-w-0',
          }}
        />
        <Button
          size="sm"
          className="!absolute right-1 top-1 rounded bg-purple5"
          onClick={daumPostHandleClick}
        >
          주소 찾기
        </Button>
      </div>
      <Input
        type="text"
        label="주소"
        className="pr-20"
        value={deliveryInfo?.address}
        disabled
        // onChange={(e) => inputChangeHandle('address', e.target.value)}
        containerProps={{
          className: 'min-w-0',
        }}
        onClick={daumPostHandleClick}
      />
      <Input
        type="text"
        label="상세주소 입력"
        className="pr-20"
        value={deliveryInfo?.detailAddress}
        onChange={(e) => inputChangeHandle('detailAddress', e.target.value)}
        containerProps={{
          className: 'min-w-0',
        }}
      />
      <div
        className="flex items-center"
        onClick={(e) =>
          delivery?.isDefault && alert('하나 이상의 기본 배송지가 필요합니다.')
        }
      >
        <Checkbox
          id="checkBox"
          color="purple"
          className="pl-0"
          defaultChecked={deliveryInfo?.isDefault}
          disabled={delivery?.isDefault}
          onChange={(e) => {
            inputChangeHandle('isDefault', e.target.checked);
          }}
        />
        <label htmlFor="checkBox">
          <TextWinds type={'content'}>기본 배송지로 지정하기</TextWinds>
        </label>
      </div>
      <div className="flex gap-4">
        {delivery ? (
          <Buttons type={'filled'} color={'purple5'} onClick={onSubmitPut}>
            <span>수정</span>
          </Buttons>
        ) : (
          <Buttons type={'filled'} color={'purple5'} onClick={onSubmitPost}>
            <span>추가</span>
          </Buttons>
        )}
        <Buttons
          type={'outlined'}
          color={'purple5'}
          onClick={() => {
            setState('list');
          }}
        >
          <span>취소</span>
        </Buttons>
      </div>
    </div>
  );
};

export default DeliveryInfoChange;
