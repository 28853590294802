import { Tabs, TabsHeader, Tab } from '@material-tailwind/react';
import { Drawer } from '@material-tailwind/react';
import { Fragment, useEffect, useState } from 'react';
import Buttons from '../../../components/common/Buttons';
import TextWinds from '../../../components/common/TextWinds';
import RegistDetail from './popup/RegistDetail';
import Modify from './popup/Modify';
import StudentList from './StudentList';
import {
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import { useParams, useSearchParams } from 'react-router-dom';
import { PaginationProps } from '../../../constants/types';
import { getStudentList } from '../../../api/utils/lms/lmsApi';
import { PAGE_SIZE } from '../../../constants/constants';

const TABS_MENU = [
  {
    label: '수강생',
    value: studentStatusType.ACTIVE,
  },
  {
    label: '정지',
    value: studentStatusType.INACTIVE,
  },
  {
    label: '대기생',
    value: studentStatusType.PENDING,
  },
];

const Signupmgmt = () => {
  const { classId } = useParams();

  // --- SearchParams ---
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab =
    (searchParams.get('status') as studentStatusType) ||
    studentStatusType.PENDING;
  const page = searchParams.get('page') ? +searchParams.get('page') : 1;
  const pageSize = searchParams.get('pageSize')
    ? +searchParams.get('pageSize')
    : PAGE_SIZE;

  // --- 드로어 관리 ---
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState<boolean>(false);

  const [studentList, setStudentList] = useState<studentType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pageInfo, setPageInfo] = useState<PaginationProps>({
    page,
    pageSize,
    lastPage: 0,
    total: 0,
  });

  useEffect(() => {
    if (signupModalOpen || modifyModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [signupModalOpen, modifyModalOpen]);

  useEffect(() => {
    reloadStudentList();
  }, [activeTab, page, pageSize]);

  // 페이지 정보 변경에 따른 학생 리스트 가져오기
  const reloadStudentList = () => {
    setIsLoading(true);

    getStudentList(classId, activeTab, page, pageSize)
      .then((res) => {
        if (page > 1 && res.result.length === 0) {
          searchParams.set('page', res.page.lastPage.toString());
          setSearchParams(searchParams);
          return;
        }

        setStudentList(res.result);
        setPageInfo({
          page,
          pageSize,
          total: res.page.total,
          lastPage: res.page.lastPage,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <div className="flex flex-col gap-x-1 bg-white rounded-lg shadow-md p-5 m-0 md:m-5 ">
        {/* 헤드 */}
        <section className="flex flex-col gap-2 md:flex-row md:justify-between items-center md:items-left border-b border-gray5 pb-5">
          <TextWinds type="title_h2" xlType="title_h3">
            학생 관리
          </TextWinds>
          <div className="grid grid-cols-2 gap-3">
            <Buttons
              type="filled"
              color="purple5"
              isHeight="titlebutton"
              onClick={() => setSignupModalOpen(true)}
              className="h-10"
            >
              학생 추가
            </Buttons>
            <Buttons
              type="outlined"
              color="purple5"
              isHeight="titlebutton"
              onClick={() => setModifyModalOpen(true)}
              className="h-10"
            >
              반 추기/수정
            </Buttons>
          </div>
        </section>

        {/* 탭 */}
        <Tabs value={activeTab} className="w-full">
          <TabsHeader
            className="rounded-none border-b border-gray6 bg-transparent p-0"
            indicatorProps={{
              className:
                'bg-transparent border-b-0 border-purple5 shadow-none rounded-none',
            }}
          >
            {TABS_MENU.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setSearchParams({ status: value })}
                className={`w-[142px] h-[48px] border-b-${
                  activeTab === value ? '2' : '0'
                } border-purple5`}
              >
                <div className="flex">
                  <TextWinds
                    type={`${
                      activeTab === value ? 'button2' : 'content_body3'
                    }`}
                    color={`${activeTab === value ? 'purple5' : 'gray7'}`}
                    xlType={`${
                      activeTab === value ? 'button' : 'content_body2'
                    }`}
                  >
                    {label}
                  </TextWinds>
                </div>
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>

        {/* 중앙 테이블  */}
        <StudentList
          status={activeTab}
          data={studentList}
          pageInfo={pageInfo}
          reloadStudentList={reloadStudentList}
          isLoading={isLoading}
        />

        {/* 학생 등록 모달 */}
        <Drawer
          placement="bottom"
          open={signupModalOpen}
          onClose={() => {
            setSignupModalOpen(false);
          }}
          overlay={true}
          size={608}
          className="w-full lg:w-1/2 items-center left-0 lg:left-1/4 right-1/4 px-5 py-2.5 rounded-t-xl overflow-y-auto max-h-[100%]"
        >
          <RegistDetail
            title="학생 등록"
            open={signupModalOpen}
            closeDrawerBottom={() => {
              setSignupModalOpen(false);
            }}
            reloadStudentList={reloadStudentList}
          />
        </Drawer>

        {/* 반 등록 모달 */}
        <Drawer
          placement="bottom"
          open={modifyModalOpen}
          onClose={() => {
            setModifyModalOpen(false);
          }}
          overlay={true}
          size={485}
          className="w-full lg:w-1/2 items-center left-0 lg:left-1/4 right-1/4 px-5 py-4 rounded-t-xl overflow-y-auto max-h-[100%]"
        >
          <Modify
            title="반 등록하기"
            closeDrawerBottom={() => {
              setModifyModalOpen(false);
            }}
            open={modifyModalOpen}
          />
        </Drawer>
      </div>
    </Fragment>
  );
};

export default Signupmgmt;
