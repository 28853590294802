import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LNB from '../../pages/mypage/Main';
import useUserHook from '../../controllers/useUserHook';
import { ApiResponse, User } from '../../constants/types';
import instance from '../../api/axios/utils.ts/instance';
import { MypageContext } from './membership/MypageContext';

const LayoutMypage = () => {
  const { setMemberInfo } = useContext(MypageContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const type = localStorage.getItem('type');
    if (type === 'STUDENT') {
      navigate('/student/mypage');
    }
  }, []);

  const getUserInfo = async () => {
    try {
      const result = await instance.get<ApiResponse<User>>('/v1/mypage/user');
      return result.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setMemberInfo(res);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [pathname]);

  return (
    <div className="max-w-screen-xl flex m-auto lg:gap-x-6 p-4 flex-col lg:flex-row ">
      <div className="lg:max-w-screen-sm">
        <LNB />
      </div>
      <div className="w-full lg:pt-[92px]">
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutMypage;
