import { Card, CardBody, CardHeader } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import { MYPAGE_EVENT_DATA } from "../../../data/statics-datas";

const MypageEvent = () => {
    return (
        <>
            <section className="px-6 pt-6 lg:p-0">
                <div className="hidden lg:block lg:mb-6">
                    <TextWinds type="title_h2">이벤트</TextWinds>
                </div>
                <iframe
                    src='https://jebs-notice.oopy.io/b68dae28-f925-44c7-aaeb-ba5e2d12e279'
                    className="w-full h-[70vh] border-0"
                    title="notice"/>
                    
                {/* <div className="grid grid-cols-1 gap-y-6 lg:grid-cols-2 lg:gap-x-6 lg:gap-y-8">
                    {MYPAGE_EVENT_DATA.map((props, key) => (
                        <Link key={key} to={`/mypage/event/detail/${props.id}`} state={props}>
                            <Card className="gap-y-[9px] shadow-none rounded-none lg:gap-y-2">
                                <CardHeader
                                    floated={false}
                                    shadow={false}
                                    color="transparent"
                                    className="h-[120px] m-0 rounded-none"
                                >
                                    <img
                                        width={327}
                                        height={120}
                                        src={process.env.PUBLIC_URL + props.img}
                                        alt="사람"
                                    />
                                </CardHeader>
                                <CardBody className="p-0">
                                    <div className="flex flex-col">
                                        <TextWinds type="content_body3" xlType="sub_title01">
                                            {props.title}
                                        </TextWinds>
                                        <TextWinds type="caption1" xlType="content_body4" color="gray8">
                                            {props.date}
                                        </TextWinds>
                                    </div>
                                </CardBody>
                            </Card>
                        </Link>
                    ))}
                </div> */}
            </section>
        </>
    );
};

export default MypageEvent;
