import React from 'react';
import { LmsContainer } from '../../../components/layout/LmsLayouts';
import TextWinds from '../../../components/common/TextWinds';

const RecitationDashbaord = () => {
  return (
    <LmsContainer header={<TextWinds type="title_h3">인증제 내역</TextWinds>}>
      RecitationDashbaord
    </LmsContainer>
  );
};

export default RecitationDashbaord;
