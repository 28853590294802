import React, { Fragment, useEffect, useState } from 'react';
import {
  Collapse,
  IconButton,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react';
import {
  UserCircleIcon,
  XMarkIcon,
  Bars3Icon,
  ChevronDownIcon,
  PowerIcon,
  HomeIcon,
  ArrowRightEndOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TextWinds from '../components/common/TextWinds';
import Sidebar from './lms/Sidebar';
import '../styles/lms/lms.css';
import { persistor } from '../store';
import { getDatas } from '../api/storage/localStorage';
import LmsBottomNavigation from './lmsBottomNavigation';
import { logout } from '../api/utils/util-func';

const menuItems = [
  {
    id: 'home',
    label: '젭스메인',
    icon: HomeIcon,
  },
  {
    id: 'profile',
    label: '내정보',
    icon: UserCircleIcon,
  },
  {
    id: 'signout',
    label: '로그아웃',
    icon: PowerIcon,
  },
];

export function LmsHeader({ classId }: { classId: string }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openNav, setOpenNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setOpenNav(false);
  }, [pathname]);

  const handleActionEvent = (id: string) => {
    setIsMenuOpen(false);
    if (id === 'signout') {
      purge();
    } else if (id === 'profile') {
      navigate('/mypage');
    } else {
      navigate('/');
    }
  };

  const purge = () => {
    logout();
    persistor.purge().then(() => navigate('/'));
  };

  return (
    <header className="px-5 bg-purple7 shadow-card z-[1]">
      <div className="p-0 xl:mx-auto">
        <div className="flex justify-between items-center h-[58px] xl:h-[58px]">
          <Typography
            as={'a'}
            href={`/ClassManagement`}
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
            <TextWinds type="title_h2" color="white">
              JEBS LMS
            </TextWinds>
          </Typography>

          {/* <div className="hidden xl:block bg-white xl:bg-gray1 w-[516px]">
            <div className="w-full flex flex-row items-center justify-start gap-[10px] py-[5px] px-[10px] bg-white border-[1px] border-solid border-gray5 rounded-[4px]">
              <MagnifyingGlassIcon className="w-3.5 h-3.5" />
              <div className="text-[14px] leading-[22px] tracking-[-0em] text-gray6 whitespace-nowrap">
                통합 검색
              </div>
            </div>
          </div> */}

          <IconButton
            size="sm"
            color="white"
            variant="text"
            className="ml-auto mr-2 xl:hidden"
            onClick={() => setOpenNav((cur) => !cur)}
          >
            {openNav ? (
              <XMarkIcon className="w-6 h-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="w-6 h-6" strokeWidth={2} />
            )}
          </IconButton>
          <div className="xl:flex xl:items-center xl:gap-x-1">
            <div className="flex items-center gap-x-1">
              <Link to={'/cart'}>
                <div className="h-10 w-10">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/svg/icon/shopping-cart-white.svg'
                    }
                    alt="장바구니"
                    className="w-10 h-auto"
                  />
                  {/* <ShoppingCartIcon strokeWidth={1.5} className={"h-10 w-10"} color="white" /> */}
                </div>
              </Link>

              <Fragment>
                <Menu
                  open={isMenuOpen}
                  handler={setIsMenuOpen}
                  placement="bottom-end"
                >
                  <MenuHandler>
                    <ListItem
                      className="flex items-center gap-2 py-2 pr-0 font-medium disabled:bg-none"
                      selected={isMenuOpen}
                      onClick={() => setIsMenuOpen((cur) => !cur)}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + '/svg/icon/profile-white.svg'
                        }
                        alt="장바구니"
                        className="w-10 h-auto"
                      />
                      {/* <Avatar
                        size="sm"
                        className="border-2 border-white p-0.5 fill-white"
                        src={process.env.PUBLIC_URL + "/svg/icon-profile-white.svg"}
                      /> */}
                      <ChevronDownIcon
                        strokeWidth={2.5}
                        color="white"
                        className={`
                          h-5 w-5 transition-transform 
                          ${isMenuOpen ? 'rotate-180' : ''}
                        `}
                      />
                    </ListItem>
                  </MenuHandler>
                  <MenuList className="p-1 z-[10001]">
                    {getDatas() ? (
                      menuItems.map(({ id, label, icon }, key) => {
                        const isLastItem = key === menuItems.length - 1;
                        return (
                          <MenuItem
                            key={label}
                            onClick={() => handleActionEvent(id)}
                            className={`flex items-center gap-2 rounded ${
                              isLastItem
                                ? 'hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10'
                                : ''
                            }`}
                          >
                            {React.createElement(icon, {
                              className: `h-5 w-5 ${
                                isLastItem ? 'text-red-500' : ''
                              }`,
                              strokeWidth: 2,
                            })}
                            <Typography
                              as="span"
                              variant="small"
                              className="font-normal"
                              color={isLastItem ? 'red' : 'inherit'}
                            >
                              {label}
                            </Typography>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        onClick={() => navigate('/signin')}
                        className={`flex items-center gap-2 rounded`}
                      >
                        {React.createElement(ArrowRightEndOnRectangleIcon, {
                          className: `h-5 w-5`,
                          strokeWidth: 2,
                        })}
                        <Typography>로그인</Typography>
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Fragment>
            </div>
          </div>
        </div>
        <div className={`${openNav ? 'block' : 'hidden'}`}>
          <div className="h-[calc(100vh-58px)] overflow-y-scroll">
            <Sidebar classId={classId} />
          </div>
        </div>
        <div className="block xl:hidden">
          <LmsBottomNavigation classId={classId} />
        </div>
      </div>
    </header>
  );
}

export default LmsHeader;
