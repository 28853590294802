import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OnlyHeadersLayout from '../../../components/onlyheaderslayout';
import LearningMain from './LearningMain';
import LearningDetail from './LearningDetail';
import LearningList from './LearningList';

const RouteLearning = () => {
  return (
    <Routes>
      {/* 내 학습 / 클래스관리 */}
      <Route element={<OnlyHeadersLayout />}>
        {/* 내학습 목록 */}
        <Route index path="/" element={<LearningMain />} />

        {/* 내학습 상세페이지 */}
        <Route index path="/detail/:detailId" element={<LearningDetail />} />

        {/* 내학습 학습 전체보기 */}
        <Route index path="/list/:learningId" element={<LearningList />} />

        {/* 시험 목록 - 미사용 */}
        {/* <Route index path="/todo" element={<LearningTodo />} /> */}
      </Route>
    </Routes>
  );
};

export default RouteLearning;
