import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import Confetti, { ConfettiRef } from '../wmw/WmwConfetti';
import medalImg from '../img/sec01-medal.png';
import ribbon01Img from '../img/sec01-ribbon01.png';
import ribbon02Img from '../img/sec01-ribbon02.png';
import hfbookImg from './img/sec01-hfbook.png';
import hjbookImg from './img/sec01-hjbook.png';

const HFHJSection1 = () => {
  const confettiRef = useRef<ConfettiRef>(null);

  const triggerConfetti = () => {
    confettiRef.current?.startFire();
  };
  return (
    <div className="w-full">
      <div className="relative w-full pb-20 max-h-screen z-1">
        <Background />

        <motion.img
          src={ribbon01Img}
          className="absolute left-0 bottom-0 w-1/4 m-auto max-w-[400px] -translate-y-1/3"
          initial={{ width: 0 }}
          whileInView={{ width: '25%' }}
          transition={{ delay: 0.1 }}
        />
        <motion.img
          src={ribbon02Img}
          className="absolute right-0 bottom-0 w-1/3 m-auto max-w-[400px]"
          initial={{ width: 0 }}
          whileInView={{ width: '33%' }}
          transition={{ delay: 0.1 }}
        />
        <div className="absolute left-0 top-0 w-full h-full">
          <div className="text-2xl md:text-3xl text-black text-center mt-10 h-full">
            <span className="font-bold">어린이 전도</span>에 최적화된
            <br />
            <span className="font-bold">주일학교 프로그램이 있다?</span>
          </div>
          <div className="w-full absolute left-0 bottom-1/2 transform translate-y-3/4 md:translate-y-1/2">
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: -10 }}
              transition={{
                repeatType: 'reverse',
                duration: 2,
              }}
            >
              <motion.img
                src={medalImg}
                className="w-[40%] max-w-[400px] m-auto"
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                onAnimationStart={triggerConfetti}
              />
            </motion.div>
            <div className="text-center mt-3 text-xl md:text-3xl">
              어린이 눈높이에 최적화한
              <br />
              <span className="font-bold">구약, 신약 마스터 프로그램</span>
            </div>
          </div>
        </div>
        <div className="absolute left-0 top-0 w-full aspect-square md:aspect-[4/3] z-2">
          <Confetti ref={confettiRef} />
        </div>
      </div>
      <div className="flex flex-col gap-3 w-full max-w-[520px] p-3 m-auto">
        <motion.div
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          onAnimationStart={triggerConfetti}
          className="relative bg-[#ffdfaf] p-5 rounded-lg"
        >
          <div className="absolute px-10 py-2 text-lg font-semibold top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#FFDA19] rounded-full shadow-lg">
            유치부
          </div>
          <div className="text-center text-2xl md:text-4xl font-extrabold pt-3">
            Hello, Father!
            <br />
            Hello, Jesus!
          </div>
        </motion.div>
        <div className="grid grid-cols-2 gap-3">
          <div className="bg-[#ffdfaf] p-3 rounded-lg">
            <img src={hfbookImg} />
            <div className="w-fit m-auto text-base md:text-lg">
              <span className="font-extrabold">H</span>
              ello
              <span className="font-extrabold"> F</span>
              ather
            </div>
          </div>
          <div className="bg-[#ffdfaf] p-3 rounded-lg">
            <img src={hjbookImg} />
            <div className="w-fit m-auto text-base md:text-lg">
              <span className="font-extrabold">H</span>
              ello
              <span className="font-extrabold"> J</span>
              esus
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Background = () => (
  <svg viewBox="0 0 100 70">
    <defs>
      <rect id="shape" width="100%" height="100%" />
      <filter id="noise">
        <feTurbulence
          type="fractalNoise"
          baseFrequency="3"
          numOctaves="10"
          result="turbulence"
        />
        <feColorMatrix
          in="turbulence"
          type="matrix"
          values="
          1 0 0 0 0
          1 1 0 0 0
          0 0 0 1 0
          0 0 0 1 0"
          result="yellowNoise"
        />
        <feBlend mode="screen" in="yellowNoise" in2="SourceGraphic" />
      </filter>
      <mask id="gradient">
        <linearGradient id="fade" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor="black" stopOpacity="0.6" />
          <stop offset="65%" stopColor="white" stopOpacity="0.9" />
          <stop offset="75%" stopColor="white" stopOpacity="1" />
        </linearGradient>
        <use href="#shape" fill="url('#fade')" />
      </mask>
    </defs>
    <use
      href="#shape"
      fill="#fe7e2b"
      mask="url(#gradient)"
      filter="url('#noise')"
    />
  </svg>
);

export default HFHJSection1;
