import React from "react";

export default function FullWidthPage({
    children,
    bgColor = "white",
}: {
    children: React.ReactNode;
    bgColor?: string;
}) {
    return (
        <>
            <div
                className={`relative xl:p-0 xl:bg-${bgColor} xl:min-h-lvh max-w-[1176px] m-auto relative`}
            >
                {children}
            </div>
        </>
    );
}
