import instance from '../../axios/utils.ts/instance';

export const checkToken = async () => {
  try {
    if (!localStorage.getItem('accessToken')) return false;
    const res = await instance.get('/v1/auth/user-authorization?check=true');
    if (res.data.statusCode === 200) return res.data;
    return false;
  } catch (error) {
    return false;
  }
};
