import React from 'react';
import TextWinds from '../../common/TextWinds';
import {
  SnowFlower,
  NumberingText,
  KIDS,
  GrayNumList,
  AccordionColor,
  PurchaseBox,
  HeadBox,
  PurchaseButton,
  AsteriskLi,
  GradientButton,
  ADVANCED,
} from './HomeschoolComp';
import AdvancedMark from './img/section4c1-a.png';
import AdvancedActs from './img/section4c1-b.png';

const HomeschoolADVANCED = () => {
  return (
    <div>
      {/* section1 */}
      <div className="w-full bg-[#fff8dd]">
        <div className="flex flex-col gap-10 items-center text-black py-20 px-5 max-w-[520px] m-auto">
          <SnowFlower color="#2686ff" width={24} opacity={1} />
          <div className="text-3xl md:text-4xl text-center mt-3">
            젭스홈스쿨의 마지막 라인업
            <div className="font-bold">수준높은 심화 과정 코스</div>
          </div>
          <div className="bg-[#2686ff] text-white py-3 w-full rounded-lg text-center font-bold text-xl">
            ADVANCED | 2년
          </div>

          <div className="flex flex-col gap-5 w-full rounded-lg bg-white p-3 py-5">
            <img src={AdvancedMark} alt="kids-bible" className="w-full px-3" />
            <div>
              <TextWinds type="title_h3">
                마가복음 (Master English Bible with Mark 10권)
              </TextWinds>
              <div className="text-gray6">
                10개월 기간의 프로그램으로 중학교 2학년 수준의 난이도로 진행
              </div>
            </div>
            <a
              href="https://teach.jebs2.kr/media/PPTX/advanced/samplebook/"
              target="_blank"
            >
              <GradientButton text="[마가복음 교재] 미리보기" />
            </a>
          </div>
          <div className="flex flex-col gap-5 w-full rounded-lg bg-white p-3 py-5">
            <img src={AdvancedActs} alt="kids-bible" className="w-full px-3" />
            <div>
              <TextWinds type="title_h3">
                사도행전 (Master English Bible with Acts 12권)
              </TextWinds>
              <div className="text-gray6">
                12개월 기간의 프로그램으로 중학교 3학년 수준의 난이도로 진행
              </div>
            </div>
            <a
              href="https://teach.jebs2.kr/media/PPTX/ASPN/act_preview/"
              target="_blank"
            >
              <GradientButton text="[사도행전 교재] 미리보기" />
            </a>
          </div>

          {/* 권장 수업 시간 */}
          <div className="w-full">
            <div className="font-bold w-fit m-auto text-lg">
              <span className="text-[#2686ff]">ADVANCED</span> 권장 수업 시간
            </div>
            <div className="w-full mt-3 drop-shadow-lg">
              <div className="w-full">
                <div className="flex text-white text-center w-full font-bold text-sm">
                  <div className="w-[230px] bg-[#2686ff] py-2 rounded-tl-lg border-r border-dotted border-[#fff1dc]">
                    교재
                  </div>
                  <div className="flex-1 bg-[#2686ff] py-2 rounded-tr-lg">
                    권장 수업 시간
                  </div>
                </div>
                <div className="flex text-xs text-[#583b3b] text-center font-semibold">
                  <div className="w-[230px] bg-[#e0edff] px-2  rounded-bl-lg">
                    <div className="py-3 border-b border-[#2686ff30]">
                      마가복음
                      <br /> (Master English Bible with Mark)
                    </div>
                    <div className="py-3">
                      사도행전
                      <br /> (Master English Bible with Acts)
                    </div>
                  </div>
                  <div className="flex-1 bg-white px-3 rounded-br-lg">
                    <div className="py-3 border-b border-gray3 h-14 leading-[32px]">
                      레슨 당{' '}
                      <span className="text-[#2686ff] font-bold">90분</span>
                    </div>
                    <div className="py-3 leading-[32px]">
                      레슨 당{' '}
                      <span className="text-[#2686ff] font-bold">90분</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section 2 - KIDS코스가 좋은 이유 7가지 */}
      <div className="flex flex-col gap-5 w-full max-w-[520px] py-10 px-5">
        <div className="text-2xl text-center font-semibold">
          <span className="text-[#2686ff]">ADVANCED</span>
          코스가
          <div className="font-extrabold">좋은 이유 4가지</div>
        </div>
        {/* <List /> */}
        <GrayNumList
          textArr={[
            'NIV 성경 본문으로 원어민 수준의 독해 연습',
            '학교시험 대비 문제 연습을 통해 중고등 영어 마스터',
            '단어부터 듣기,읽기 학습으로 전체 내용을 이해, 구성, 전달하며 종합적인 영어능력 향상',
            '스스로 질문 만들기, 설명하기 등의 학습으로 능동적 토론 수업 가능',
          ]}
        />
      </div>
      <div className="flex flex-col gap-5 bg-[#f4f4f4] py-10 px-5">
        <div className="text-2xl text-center font-semibold">
          <span className="text-[#2686ff]">ADVANCED</span>
          <span className="font-extrabold"> 멤버십 비용</span>
        </div>

        <PurchaseBox
          head={'1년'}
          textArr={[
            <>
              온라인 교사 교육
              <div className="text-xs text-gray6 font-normal">
                (최초 수료 강의 + 연 2회 강화 교육)
              </div>
            </>,
            'ADVANCED 교재 총 12세트',
            '온라인 서비스 1년 & 교사용 메뉴얼',
          ]}
          button={
            <>
              <div className="text-[#d6d0ff]">멤버십 1년 </div>
              800,000원 <span className="text-base">(월 33,333원)</span>
            </>
          }
          link="/courses/homeschool/details/URxr_HxtwXy5H-u_?palnItems=true"
        />
        <HeadBox
          head={
            <div className="w-fit px-9 py-1 font-bold bg-[#ff373f] text-white rounded-full">
              연장
            </div>
          }
        >
          <div className="p-5">
            <div className="grid grid-cols-2 text-center font-semibold text-xl">
              <div className="pr-5">
                <div className="h-24 leading-[6rem]">연장 패키지</div>
                <PurchaseButton>
                  <div className="relative w-full text-center py-2 text-white font-extrabold h-[60px] leading-[44px] ">
                    <div className="w-fit bg-[#ff6363] absolute top-[-4px] px-2 py-1 left-1/2 transform -translate-y-full -translate-x-1/2 text-xs rounded text-nowrap">
                      교재 10권 포함
                      <div className="absolute bottom-0 left-1/2 transform translate-y-full -translate-x-1/2 w-0 h-0 border-t-[6px] border-r-transparent border-r-[6px] border-l-transparent border-l-[6px] border-t-[#ff6363]"></div>
                    </div>
                    195,000원
                  </div>
                </PurchaseButton>
              </div>
              <div className="flex flex-col justify-between border-l pl-5">
                <div className="mt-6">
                  온라인 서비스
                  <br />
                  연장(1년)
                </div>
                <PurchaseButton>
                  <div className="w-full text-center py-2 text-white font-extrabold leading-4 pt-3">
                    120,000원
                    <br />
                    <span className="text-[#d6d0ff] text-base font-semibold">
                      (월 10,000원)
                    </span>
                  </div>
                </PurchaseButton>
              </div>
            </div>
            <div className="pt-5 text-gray6 text-center text-xs md:text-sm">
              * 교재 12세트 구매 시, 온라인 서비스는 무료 연장됩니다.{' '}
            </div>
          </div>
        </HeadBox>
        <HeadBox head={<></>}>
          <ul className="p-5 list-none text-gray6 text-sm space-y-1 text-[#868686] font-medium">
            <AsteriskLi>
              해당 멤버십은 ADVANCED 과정만 이용할 수 있습니다.
            </AsteriskLi>
            <AsteriskLi>
              목사님, 사모님, 선교사님은 교역자 할인이 적용되오니,
              <br /> 젭스 본사로 별도 문의를 부탁 드립니다.
            </AsteriskLi>
          </ul>
        </HeadBox>
      </div>
    </div>
  );
};

export default HomeschoolADVANCED;
