import React, { Fragment } from "react";
import TextWinds from "../../../components/common/TextWinds";
import InfoText from "../../../components/mypage/Info";
import "../../../styles/mypage/mypage.css";
import { RATING_ARRAY_LIST } from "../../../data/statics-datas";

const INFO_TEXT = [
    {
        title: "회원등급 안내",
        text: [
            "구매 점수는 만 원 당 1점씩 부여됩니다. (천 원 단위 이하 제외)",
            "코스 가입 상품은 구매 점수에 반영되지 않습니다.",
            "포인트 적립은 적립 가능 상품에 한해 적용됩니다.",
            "포인트 사용은 상품 구매 시 1포인트=1원으로 사용가능합니다.",
            "적립 포인트 유효기간은 적립 일로부터 3년입니다.",
        ],
    },
];

const MypageRating = () => {
    return (
        <>
            <section className="px-5 pb-[65px] lg:px-0">
                <div className="mb-6 lg:mb-[26px]">
                    <TextWinds type="title_h2">회원등급</TextWinds>
                </div>
                <div className="mb-8">
                    {RATING_ARRAY_LIST.map((props, key) => (
                        <div
                            key={key}
                            className={`flex justify-between items-center py-4 border-b ${
                                key === 0 ? "border-t" : ""
                            }`}
                        >
                            <div className="flex flex-col gap-1">
                                <TextWinds type="title_h4" color={`${props.color}`}>
                                    {props.title}
                                </TextWinds>
                                <TextWinds type="content_body4" color="text-gray9">
                                    {props.desc.split("\n").map((line, key) => (
                                        <Fragment key={key}>
                                            {line}
                                            <br />
                                        </Fragment>
                                    ))}
                                </TextWinds>
                            </div>
                            <TextWinds type="content_body4" color="skyblue">
                                {props.text}
                            </TextWinds>
                        </div>
                    ))}
                </div>
                {INFO_TEXT.map((info, key) => (
                    <InfoText key={key} title={info.title} text={info.text} />
                ))}
            </section>
        </>
    );
};

export default MypageRating;
