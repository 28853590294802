import { useLocation } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import "../../../styles/mypage/mypage.css";
import { orderDetailType } from "../../../constants/types/mypage-type";

const RefundCancelInfo = ({orderDetail}:{orderDetail:orderDetailType}) => {
    // const location = useLocation();
    // const orderDetail = location.state;

    return (
        <div className="flex flex-col gap-y-4 px-3 py-[14px] bg-gray0">
            <TextWinds type="sub_title01">환불/취소 정보</TextWinds>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    처리 상태
                </TextWinds>
                <TextWinds type="content_body4">환불 요청 완료</TextWinds>
            </div>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    취소 금액
                </TextWinds>
                <TextWinds type="content_body4">18,000 원</TextWinds>
            </div>
            <div className="flex justify-between items-center">
                <TextWinds type="content_body4" color="gray7">
                    환불 정보
                </TextWinds>
                <TextWinds type="content_body4">김젭스 00은행 231-1921-2312233</TextWinds>
            </div>
        </div>
    );
};

export default RefundCancelInfo;
