import React from 'react';
import { ApiResponse } from '../constants/types';
import instance from '../api/axios/utils.ts/instance';
import useTimer from './useTimer';
import { getErrorMessages } from '../api/utils/util-func';

const useSmsHook = () => {
  type verifyInfoType = {
    code: string;
    createdAt: Date;
    id: number;
    expiresAt: Date;
  };

  const [verifyInfo, setVerifyInfo] = React.useState<verifyInfoType>();
  const { start, stop, timeLeft } = useTimer();

  //인증번호 발송
  const sendVerifyNumber = async (phone: string): Promise<string> => {
    try {
      const res = await instance.post<ApiResponse<verifyInfoType>>('/v1/sms', {
        phone,
      });
      setVerifyInfo(res.data.data);
      start(new Date(res.data.data.expiresAt), () => {});
      return '인증번호가 발송되었습니다.';
    } catch (err) {
      return getErrorMessages(err);
    }
  };

  //인증번호 발송
  const sendVerifyNumberWithName = async (
    phone: string,
    name: string
  ): Promise<string> => {
    try {
      const res = await instance.post<ApiResponse<verifyInfoType>>(
        '/v1/sms/with-name',
        { phone, name }
      );
      setVerifyInfo(res.data.data);
      start(new Date(res.data.data.expiresAt), () => {});
      return '인증번호가 발송되었습니다.';
    } catch (err) {
      return getErrorMessages(err);
    }
  };

  //인증번호 확인
  const checkVerifyNumber = async (
    phone: string,
    verifyNumber: string
  ): Promise<{ confirm: false | number; msg: string }> => {
    try {
      if (!verifyInfo) {
        return { confirm: false, msg: '인증번호를 먼저 요청해주세요.' };
      }
      const res = await instance.put('/v1/sms', {
        phone: phone,
        code: verifyNumber,
        id: verifyInfo.id,
      });
      stop();
      return { confirm: verifyInfo.id, msg: '인증되었습니다.' };
    } catch (err) {
      return { confirm: false, msg: getErrorMessages(err) };
    }
  };

  return {
    sendVerifyNumber,
    sendVerifyNumberWithName,
    checkVerifyNumber,
    timeLeft,
  };
};

export default useSmsHook;
