import React from 'react';
import WmwSection1 from './WmwSection1';
import WmwSection2 from './WmwSection2';
import WmwSection3 from './WmwSection3';
import WmwSection4 from './WmwSection4';
import WmwSection5 from './WmwSection5';
import WmwSection6 from './WmwSection6';
import WmwSection7 from './WmwSection7';
import WmwSection8a from './WmwSection8a';
import WmwSection8b from './WmwSection8b';
import WmwSection9 from './WmwSection9';
import WmwSection10 from './WmwSection10';
import WmwSection11 from './WmwSection11';
import WmwSection12 from './WmwSection12';
import WmwSection13 from './WmwSection13';
import WmwSection14 from './WmwSection14';
const WmwMain = () => {
  return (
    <div>
      <WmwSection1 />
      <WmwSection2 />
      <WmwSection3 />
      <WmwSection4 />
      <WmwSection5 />
      <WmwSection6 />
      <WmwSection7 />
      <WmwSection8a />
      <WmwSection8b />
      <WmwSection9 />
      <WmwSection10 />
      <WmwSection11 />
      <WmwSection12 />
      <WmwSection13 />
      <WmwSection14 />
    </div>
  );
};

export default WmwMain;
