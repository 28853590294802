import { useNavigate, useParams } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import { useState, useEffect } from 'react';
import instance from '../../../api/axios/utils.ts/instance';
import {
  LearningStatusEnum,
  myLearnigDetailType,
  myLearningLessonInfoType,
} from '../../../constants/types/learning-type';
import { ApiResponse } from '../../../constants/types';
import { useAppDispatch } from '../../../store/hook';
import { openConfirm, openModal } from '../../../store/features/modalSlice';
import LearningLessonItem from './Detail/LearningLessonItem';
import HFbutton from '../../../components/common/input/HFbutton';
import CertificateModal from './Detail/CertificateModal';
import CourseInfoBox from './Detail/CourseInfoBox';

const LearningDetail = () => {
  const { detailId } = useParams();
  const navigate = useNavigate();
  const [learningData, setLearningData] = useState<myLearnigDetailType>();
  const [lessonInfo, setLessonInfo] = useState<myLearningLessonInfoType>();

  // 내 학습 디테일 정보 가져오기
  const getLearning = async () => {
    try {
      const response = await instance.get<ApiResponse<myLearnigDetailType>>(
        '/v1/learning/' + detailId
      );
      const res = response.data;
      setLearningData(res.data);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  // 학습 콘텐츠 가져오기
  const getLearningContent = async () => {
    try {
      const params = { page: 1, pageSize: 100 };
      const response = await instance.get<
        ApiResponse<{ result: myLearningLessonInfoType[] }>
      >('/v1/learning/' + detailId + '/content', { params });
      const res = response.data;
      return res.data.result;
    } catch (error) {}
  };

  useEffect(() => {
    // 최근 학습이 없는 경우
    if (!learningData?.myLearning.learningProgress?.recentlyLearningContent)
      return;

    getLearningContent().then((data) => {
      const lesson = data.find(
        (item) =>
          item.id ===
          learningData?.myLearning.learningProgress.recentlyLearningContent
            .contentId
      );
      setLessonInfo(lesson);
    });
  }, [detailId, learningData]);

  useEffect(() => {
    getLearning();
  }, []); // 컴포넌트가 처음 렌더링될 때만 실행되도록 []

  return learningData ? (
    learningData && (
      <div className="flex flex-col lg:flex-row gap-4 p-4 justify-center lg:py-10">
        {/* 좌측 코스 정보 박스 */}
        <CourseInfoBox
          courseName={learningData.course.name}
          startDate={learningData.myLearning.enrolment.startDate}
          endDate={learningData.myLearning.enrolment.endDate}
          remainDays={Number(learningData.myLearning.enrolment.remainDays)}
          CourseId={learningData.course.id}
          CourseType={learningData.course.type}
        />

        <div className="flex flex-col gap-10">
          <div>
            <img
              src={learningData.course.thumbnail}
              alt={learningData.course.name}
              className="w-full h-[264px] object-cover"
            />
          </div>

          {/* 수료완료시 -> 수료증 확인 화면 */}
          {/* 수료대상자 -> 수료 확인 화면 */}
          {learningData.myLearning.isGraduated ? (
            <CourseGraduation learningData={learningData} />
          ) : learningData.myLearning.isGraduationTarget ? (
            <CourseComplete
              learningData={learningData}
              reloadData={() => getLearning()}
            />
          ) : null}

          {/* 학습하기 */}
          <section className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-end gap-2">
                <TextWinds type="title_h4">{`학습하기 `}</TextWinds>
                <TextWinds type="caption1">
                  {`
                    ${learningData?.myLearning.learningProgress.learningCount.totalCount}강 중
                    ${learningData?.myLearning.learningProgress.learningCount.completedCount}강 수강
                  `}
                </TextWinds>
              </div>
              <div className="w-auto">
                <HFbutton
                  height={36}
                  onClick={() =>
                    navigate('/learning/list/' + learningData.myLearning.id)
                  }
                >
                  전체 보기
                </HFbutton>
              </div>
            </div>

            {learningData?.myLearning.learningProgress
              .recentlyLearningContent ? (
              <LearningLessonItem
                learningId={
                  learningData?.myLearning.learningProgress
                    .recentlyLearningContent.classId
                }
                lesson={lessonInfo}
                onComplete={() =>
                  setLessonInfo({
                    ...lessonInfo,
                    status: LearningStatusEnum.COMPLETED,
                  })
                }
              />
            ) : (
              <div className="flex justify-center items-center h-[200px] bg-gray1 rounded-md">
                <TextWinds type="content_body3" color="gray6">
                  최근 학습한 콘텐츠가 없습니다.
                </TextWinds>
              </div>
            )}
          </section>

          {/* 시험 */}
          {/* <div className="p-5 bg-gray1 rounded">
            <div className="flex items-center justify-between">
              <TextWinds type="title_h4">시험</TextWinds>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              {learningData?.myLearning.tasks.map((todo) => (
                <ToDoList
                  todo={todo}
                  key={todo.id}
                  classId={learningData?.myLearning.id}
                  taskAssignmentId={todo.taskAssignmentId}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    )
  ) : (
    <></>
  );
};

const CourseComplete = ({
  learningData,
  reloadData,
}: {
  learningData: myLearnigDetailType;
  reloadData: () => Promise<myLearnigDetailType>;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleComplete = () => {
    reloadData().then((data) => {
      if (data.user.teacherStatus === 'CERTIFIED') {
        // 교인인증 완료의 경우 수료 정보 확인 창 띄우기
        checkCertificateModal({ onCompleted: reloadData });
      } else {
        // 교인 정보가 없을 경우 교인인증 페이지로 이동 확인
        confirmMemberCheck();
      }
    });
  };

  const confirmMemberCheck = () => {
    dispatch(
      openConfirm({
        message:
          '교인인증이 완료되지 않았습니다.\n교인인증 페이지로 이동하시겠습니까?',
        onConfirm: () => {
          navigate('/mypage/membership');
        },
        confirmText: '이동',
      })
    );
  };

  const checkCertificateModal = ({
    onCompleted,
  }: {
    onCompleted: () => void;
  }) => {
    dispatch(
      openModal({
        body: (
          <CertificateModal
            userEnglishName={learningData.user.englishName}
            type={learningData.course.licenseType}
            coursedId={learningData.course.id}
            courseName={learningData.course.name}
            learningId={learningData.myLearning.id}
            reloadData={reloadData}
          />
        ),
      })
    );
  };

  return (
    <div className="flex-col">
      <div className="pt-5 pb-[20px] text-center">
        <p className="">😀</p>
        <TextWinds type="title_h4" color="purple5">
          학습 수강을 완료하였어요!
        </TextWinds>
        <TextWinds type="content_bold" color="gray7" className="pt-[8px]">
          교인 인증까지 모두 마쳤다면 수료 최종 확인을 누르세요.
          <br />
          클래스가 열리며 운영 기간이 시작됩니다.
        </TextWinds>
      </div>

      <div className="min-w-[200px] m-auto">
        <HFbutton
          className="bg-skyblue text-white hover:bg-[#1c6dd3] active:text-black"
          onClick={() => handleComplete()}
        >
          수료 최종 확인
        </HFbutton>
      </div>
    </div>
  );
};

const CourseGraduation = ({
  learningData,
}: {
  learningData: myLearnigDetailType;
}) => {
  const dispatch = useAppDispatch();

  const handleCertificate = () => {
    dispatch(
      openModal({
        body: (
          <CertificateModal
            userEnglishName={learningData.user.englishName}
            type={learningData.course.licenseType}
            coursedId={learningData.course.id}
            courseName={learningData.course.name}
            learningId={learningData.myLearning.id}
            readonly={true}
          />
        ),
      })
    );
  };
  return (
    <div className="flex flex-col gap-5">
      <div className="pt-[14px] text-center">
        <p className="">🖐</p>
        <TextWinds type="title_h4" color="purple5">
          수료를 축하합니다!
        </TextWinds>
      </div>
      <div className="min-w-[200px] m-auto">
        <HFbutton onClick={() => handleCertificate()}>수료증 보기</HFbutton>
      </div>
    </div>
  );
};

export default LearningDetail;
