import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    Collapse,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Typography,
} from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserHook from "../../../controllers/useUserHook";

interface SubMenuProps {
    title: string;
    url: string;
    blank?: boolean;
}
interface NavListProps {
    text: string;
    obj: SubMenuProps[];
}
const resourceSubMenu = [
    // {
    //   title: "내 학습",
    //   url: "/learning",
    // },
    {
        title: "홈스쿨",
        url: "/courses/homeschool",
    },
    {
        title: "주일학교",
        url: "/courses/churchschool",
    },
    {
        title: "소그룹",
        url: "/courses/smallgroup",
    },
    {
        title: "화상영어",
        url: "/courses/englishconversation",
    },
];
const storySubMenu = [
    {
        title: "회원 후기",
        url: "/review",
    },
    {
        title: "젭스 소개",
        url: "/story",
    },
];
const communitySubMenu:SubMenuProps[] = [
    {
        title:"홈스쿨 교사 카페",
        url:"https://cafe.naver.com/jemm",
        blank:true
    },
    {
        title:"주일학교 교사 카페",
        url:"https://cafe.naver.com/jcebs",
        blank:true
    }
]
function NavListMenu({ text, obj }: NavListProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const renderItems = obj.map(({ title, url, blank }: SubMenuProps, key) => (
        <a href={url} key={key} target={blank&&'_blank'}>
            <MenuItem className="flex items-center gap-3 rounded-lg">
                <div>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="flex items-center text-sm font-medium"
                    >
                        {title}
                    </Typography>
                </div>
            </MenuItem>
        </a>
    ));

    return (
        <Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
                            selected={false}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            {text}
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className={`grid grid-cols-[${obj.length}] gap-y-2 outline-none outline-0`}>
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </Fragment>
    );
}

const NavList = () => {
    const {checkAndPageMove} = useUserHook();
    
    // TODO : 반드시 삭제
    const tmpId: number = 1;
    return (
        <List className="flex flex-col gap-2 mt-2 mb-2 lg:mt-0 lg:mb-0 lg:flex-row lg:items-center">
            {/* <Typography
        as="a"
        href="/courses"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          코스 찾기
        </ListItem>
      </Typography>
      <NavListMenu text="내 학습" obj={resourceSubMenu} /> */}
            <NavListMenu text="코스 찾기" obj={resourceSubMenu} />
            <Typography
                as="a"
                // href="/learning"
                variant="small"
                color="blue-gray"
                className="font-medium"
                onClick={() => {
                    checkAndPageMove("/learning");
                }}
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">내 학습</ListItem>
            </Typography>
            {/* <Typography
        as="a"
        href="/ClassManagement"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          클래스 관리
        </ListItem>
      </Typography> */}
            <Typography
                as="a"
                // href={`/ClassManagement`}
                variant="small"
                color="blue-gray"
                className="font-medium"
                onClick={() => {
                    checkAndPageMove("/ClassManagement");
                }}
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">클래스 관리</ListItem>
            </Typography>
            {/* <Typography as="a" href="#" variant="small" color="blue-gray" className="font-medium">
                <ListItem className="flex items-center gap-2 py-2 pr-4">커뮤니티</ListItem>
            </Typography> */}
            <NavListMenu text="커뮤니티" obj={communitySubMenu} />
            <NavListMenu text="스토리" obj={storySubMenu} />
            {/* <Typography
                as="a"
                href="/shop"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    STORY
                </ListItem>
            </Typography> */}
            <Typography
                as="a"
                href="/shop"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">SHOP</ListItem>
            </Typography>
            {/* <Typography
                as="a"
                variant="small"
                color="blue-gray"
                className="font-medium"
                onClick={() => {
                    checkAndPageMove("/mypage");
                }}
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">마이페이지</ListItem>
            </Typography> */}
        </List>
    );
};

export default NavList;
