import dayjs, { Dayjs } from 'dayjs';

export type carouselItem = {
  img: string;
  mobile: string;
  title: string;
  link: string;
  type?: '_blank';
  date?: {
    start: Dayjs;
    end: Dayjs;
  };
};

export const IMAGE_LIST: carouselItem[] = [
  {
    img: 'images/home/banner/bannerD-24.11.04.jpg',
    mobile: 'images/home/banner/bannerM-24.11.04.jpg',
    title: '리뉴얼 홈페이지 배너- 11월 홈스쿨 설명회',
    link: 'courses/homeschool/details/DAvFDd_N0nbFgISL',
    date: {
      start: dayjs('2024-11-04'),
      end: dayjs('2024-11-30'),
    },
  },
  {
    img: 'images/home/banner/bannerD-24.10.11-1.jpg',
    mobile: 'images/home/banner/bannerM-24.10.11-1.jpg',
    title: '리뉴얼 홈페이지 배너- 영어로 땡스기빙',
    link: 'shop/details/x9lD5uumPhg1MkoV',
    // date: {
    //   start: dayjs('2024-10-16'),
    //   end: dayjs('2024-11-15'),
    // },
  },
  {
    img: 'images/home/banner/bannerD-24.09.12-2.jpg',
    mobile: 'images/home/banner/bannerM-24.09.12-2.jpg',
    title: '리뉴얼 홈페이지 배너- 김PD가간다-광명교회',
    link: 'https://youtu.be/Cqt7cDnZVNc?si=i9ontV0V1sNtOM3V',
    type: '_blank',
    // date: {
    //   start: dayjs('2024-09-19'),
    //   end: dayjs('2024-10-31'),
    // },
  },
  {
    img: 'images/home/banner/bannerD-24.08.19.jpg',
    mobile: 'images/home/banner/bannerM-24.08.19.jpg',
    title: '리뉴얼 홈페이지 배너- 영어로 그림전도 출간',
    link: '/shop/details/U9NHpfT92l_A4zTE',
  },
];
