import { useLocation, useNavigate } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import Buttons from "../../../components/common/Buttons2";

const MypageNoticeDetail = () => {
    const location = useLocation();
    const noticeDetail = location.state;
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate(-1);
    };

    return (
        <>
            <section className="px-5 pt-4 lg:p-0">
                <div className="hidden lg:block lg:mb-6">
                    <TextWinds type="title_h2">공지사항 상세</TextWinds>
                </div>
                <div className="flex flex-col gap-y-8">
                    <div className="flex flex-col gap-y-2 pb-2 border-b">
                        <TextWinds type="sub_title01">{noticeDetail.title}</TextWinds>
                        <div className="flex justify-between items-center ">
                            <TextWinds type="content_body4">{noticeDetail.date}</TextWinds>
                            <TextWinds type="content_body4">관리자</TextWinds>
                        </div>
                    </div>
                    <div className="pb-8 border-b">
                        <TextWinds type="content_body2">{noticeDetail.text}</TextWinds>
                    </div>
                    <div className="lg:w-[476px] lg:mx-auto">
                        <Buttons variant="outlined" color="purple5" onClick={() => clickHandler()}>
                            <TextWinds type="button" color="purple5">
                                목록으로 돌아가기
                            </TextWinds>
                        </Buttons>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MypageNoticeDetail;
