import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit"
import { RootState } from ".."
import { AxiosParamType } from "."
import instance from "../../api/axios/utils.ts/instance"
import { Class, CourseChapter, CourseLesson } from "../../constants/types"

interface stateObj {
  status: string,
  chapterLists: Class
  lessonsLists: CourseLesson[]
  tableDatas: CourseChapter,
  contentInfo: any,
}
const initialState: stateObj = {
  status: '',
  chapterLists: undefined,
  lessonsLists: undefined,
  tableDatas: undefined,
  contentInfo: undefined,
}
export const takeclassSlice = createSlice({
  name: 'takeclass',
  initialState,
  reducers: {
    setContentInfo: (state, action) => {
      state.contentInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getChapterLists.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getChapterLists.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.chapterLists = action.payload
      })
      .addCase(getChapterLists.rejected, (state) => {
        state.status = 'rejected'
      })

      .addCase(getTableDatas.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getTableDatas.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.tableDatas = action.payload
      })
      .addCase(getTableDatas.rejected, (state) => {
        state.status = 'rejected'
      })
      .addCase(getLessonLists.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getLessonLists.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.lessonsLists = action.payload
      })
      .addCase(getLessonLists.rejected, (state) => {
        state.status = 'rejected'
      })
  }
})

export const getChapterLists = createAsyncThunk(
  'asyncThunk/getChapterLists',
  async ({ url, params }: AxiosParamType) => {
    try {
      const response = await instance.get(url, {params});
      return response.data;
    } catch(error) {
      console.error('[axios] getChapterLists : ', error)
      return isRejectedWithValue(error)
    }
  }
)
export const getLessonLists = createAsyncThunk(
  'asyncThunk/getLessonLists',
  async ({ url, params }: AxiosParamType) => {
    try {
      const response = await instance.get(url, {params});
      return response.data;
    } catch(error) {
      console.error('[axios] getLessonLists : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const getTableDatas = createAsyncThunk(
  'asyncThunk/getTableDatas',
  async ({ url }: {url: string}) => {
    try {
      const response = await instance.get(url);
      return response.data;
    } catch(error) {
      console.error('[axios] getTableDatas : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const { setContentInfo } = takeclassSlice.actions
export const selectTableDatas = (state: RootState) => state.takeclass.tableDatas
export const selectChapterLists = (state: RootState) => state.takeclass.chapterLists
export const selectLessonsLists = (state: RootState) => state.takeclass.lessonsLists
export const selectContentInfo = (state: RootState) => state.takeclass.contentInfo
export default takeclassSlice.reducer