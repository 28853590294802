import {
  ApiResponse,
  Class,
  CourseChapter,
  CourseLesson,
  PaginationResponse,
} from '../../../constants/types';
import { myLearningLessonInfoType } from '../../../constants/types/learning-type';
import instance from '../../axios/utils.ts/instance';
import { getErrorMessages } from '../util-func';

const classId = 'zBHuODhRge6wiqJ0';

/**
 * lms 수업하기 북/챕터, 레슨 리스트 가져오기
 * @returns
 */
export const getBookChapterList_Light = async (courseId: string) => {
  const response = await instance.get<ApiResponse<CourseChapter[]>>(
    `/v1/teaching-only/${courseId}/chapter-list`
  );
  return response.data;
};

/**
 * lms 수업하기 콘텐츠 목록 가져오기
 * @param params 타입 지정 필요. 필터링을 위한 값
 * @returns
 */
export const getTakeClassList_Light = async (courseId: string, params: any) => {
  try {
    type ResponseType = ApiResponse<
      PaginationResponse<myLearningLessonInfoType[]>
    >;
    const response = await instance.get<ResponseType>(
      `/v1/teaching-only/${courseId}/contents`,
      {
        params,
      }
    );

    return response.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 수업하기 콘텐츠 링크 가져오기
 * @param contentId
 * @returns
 */
export const getContentsDatas_light = async (
  courseId: string,
  contentId: string
) => {
  try {
    const path = `/v1/teaching-only/${courseId}/contents/${contentId}`;
    const rtn = await instance.get(path);
    return rtn.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};
