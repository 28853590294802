import React, { useContext, useEffect, useState } from 'react';
import HFinput from '../../../components/common/input/HFinput';
import { useForm } from 'react-hook-form';
import HFbutton from '../../../components/common/input/HFbutton';
import { MypageContext } from '../membership/MypageContext';
import { phoneFormat } from '../../../api/utils/format/numbers';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import instance from '../../../api/axios/utils.ts/instance';
import TextWinds from '../../../components/common/TextWinds';
import { Link, useNavigate } from 'react-router-dom';
import { filterAndUpperCase } from '../../../api/utils/util-func';
import useSmsHook from '../../../controllers/useSmsHook';
import { timeLeftCheck, timeLeftToString } from '../../../controllers/useTimer';
import FormRow from '../../../components/common/input/FormRow';
import ErrorInput from '../../student/components/ErrorInput';

type UserBasicDTO = {
  email: string;
  countryCode: string;
  phone: string;
  englishName: string;
};

interface ModifyBasicProps {
  isEdit?: boolean;
}
const ModifyBasic = ({ isEdit = true }: ModifyBasicProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useContext(MypageContext);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [isConfirm, setIsConfirm] = React.useState<boolean>(false);
  const [verifyId, setVerifyId] = useState<number>();
  const { sendVerifyNumber, checkVerifyNumber, timeLeft } = useSmsHook();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<UserBasicDTO>({
    values: {
      phone: userInfo?.phone,
      email: userInfo?.email,
      countryCode: userInfo?.countryCode,
      englishName: userInfo?.englishName,
    },
  });
  const phone = watch('phone');

  // 전화번호 변경 감지
  const isPhoneChanged = phone !== userInfo?.phone;

  const Alert = (msg: string) => {
    dispatch(openAlert({ message: msg }));
  };

  const onSubmit = (data: UserBasicDTO) => {
    // 전화번호가 변경되었는데, 인증을 안받은 경우
    if (isPhoneChanged && !isConfirm) {
      Alert('전화번호가 변경 되었습니다.\n인증을 다시 받아주세요');
      return;
    }

    putUserInfo(data);
  };

  const handlePhoneChange = (value: string) => {
    const phone = value.replace(/\D/g, '');
    setValue('phone', phone);
  };

  // 인증 코드 전송
  const postSms = async () => {
    if (!isPhoneChanged) {
      // 전화번호 변경없이 요청의 경우
      // 플로우상 존재할 수 없지만 예외처리
      return;
    }

    sendVerifyNumber(phone).then((msg) => {
      Alert(msg);
    });
  };

  // 인증코드 검증
  const putSms = async () => {
    if (!verificationCode) {
      Alert('인증번호를 입력해주세요.');
      return;
    }
    checkVerifyNumber(phone, verificationCode).then((res) => {
      if (res.confirm) {
        setIsConfirm(true);
        setVerifyId(res.confirm);
      }
      Alert(res.msg);
    });
  };

  const putUserInfo = async (data: UserBasicDTO) => {
    try {
      const response = await instance.put('/v1/mypage/user', data);
      if (response.status === 200) {
        Alert('수정되었습니다.');
        navigate('/mypage/modify');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form>
      <section className="flex justify-between">
        <TextWinds type="title_h4">기본 정보</TextWinds>
        {!isEdit && (
          <Link to="/mypage/modify/basic">
            <div className="flex items-center hover:bg-purple2 px-2 border border-purple5 rounded cursor-pointer">
              <div className="text-purple5">수정하기</div>
              <img src="/svg/icon/write.svg" alt="modify" />
            </div>
          </Link>
        )}
      </section>
      <section className="flex flex-col gap-5 mt-5">
        <TextWinds type={'content'}>이름 : {userInfo?.name}</TextWinds>

        <div>
          <TextWinds type={'content'}>영어 이름 (수료증 기재용)</TextWinds>
          <FormRow
            name="englishName"
            type="text"
            control={control}
            disabled={!isEdit}
            rules={{
              required: { value: true, message: '영문명을 입력해주세요.' },
              pattern: {
                value: /^[A-Z ]+$/,
                message: '영문명은 영문대문자만 입력 가능합니다.',
              },
            }}
          />
          {isEdit && <ErrorInput text={errors.englishName?.message} />}
        </div>
        <div>
          <TextWinds type={'content'}>이메일 주소</TextWinds>
          <FormRow
            type="text"
            name="email"
            control={control}
            rules={{
              required: { value: true, message: '이메일을 입력해주세요.' },
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: '이메일 형식이 아닙니다.',
              },
            }}
            disabled={!isEdit}
          />
        </div>

        <div className="flex flex-col gap-2">
          <div className="relative flex w-full">
            <HFinput
              label="전화번호"
              value={phoneFormat(watch('phone'))}
              onChange={(e) => handlePhoneChange(e.target.value)}
              disabled={!isEdit}
              type="tel"
              className={`${isEdit ? '' : 'text-gray5'}`}
            />
            {isPhoneChanged && (
              <div className="absolute top-8 right-1">
                <HFbutton height={40} onClick={postSms} disabled={isConfirm}>
                  {timeLeftCheck(timeLeft)
                    ? timeLeftToString(timeLeft)
                    : '인증번호'}
                </HFbutton>
              </div>
            )}
          </div>
          {isPhoneChanged && (
            <div className="relative flex w-full">
              <HFinput
                label="인증번호"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                disabled={!isEdit}
              />
              <div className="absolute top-8 right-1">
                <HFbutton height={40} onClick={putSms} disabled={isConfirm}>
                  {isConfirm ? '인증완료' : '인증확인'}
                </HFbutton>
              </div>
            </div>
          )}
        </div>
      </section>
      {isEdit && (
        <div className="flex flex-col gap-5 mt-5 ">
          <HFbutton onClick={handleSubmit(onSubmit)}>저장</HFbutton>
          <HFbutton type="Outlined" onClick={() => navigate('/mypage/modify')}>
            취소
          </HFbutton>
        </div>
      )}
    </form>
  );
};

export default ModifyBasic;
