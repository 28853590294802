import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@material-tailwind/react';
import Buttons from '../../../../components/common/Buttons';
import ComInput from '../../../../components/common/ComInput';
import TextWinds from '../../../../components/common/TextWinds';
import { useParams } from 'react-router-dom';
import ModifyListItem from './ModifyListItem';
import { LmsClassGroupType } from '../../../../constants/types/lms-type';
import {
  axiosPostClass,
  getClassGroupList,
} from '../../../../api/utils/lms/lmsApi';

interface ModuleProps {
  title: string;
  open: boolean;
  closeDrawerBottom?: () => void;
}
const Modify = ({ title, open, closeDrawerBottom }: ModuleProps) => {
  const { classId } = useParams();

  const [detailTab, setDetailTab] = useState(true);
  const [newClassName, setNewClassName] = useState('');

  const [classLists, setClassLists] = useState<LmsClassGroupType[]>();

  useEffect(() => {
    // 모달창이 열릴 때만 호출
    if (!open) return;

    setNewClassName('');
    reloadClassList();
  }, [open]);

  // 반 리스트 가져오기
  const reloadClassList = () => {
    getClassGroupList(classId).then((res) => {
      setClassLists(res.result);
    });
  };

  // 반 추가
  const addClass = () => {
    if (!newClassName) {
      alert('반 이름을 입력해주세요.');
      return;
    }
    axiosPostClass(classId, { name: newClassName, description: '' }).then(
      (result) => {
        reloadClassList();
        alert('반을 추가하였습니다.');
      }
    );
    setNewClassName('');
  };

  return (
    classLists && (
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-y-2.5"
      >
        <div className="flex justify-between items-center pt-2.5 pb-5 border-b border-gray4">
          <TextWinds type="title_h3">{title}</TextWinds>
          <IconButton
            variant="text"
            onClick={closeDrawerBottom}
            className="w-[30px] max-w-[30px] h-[30px] max-h-[30px]"
          >
            <XMarkIcon className="w-[30px] h-[30px]" />
          </IconButton>
        </div>
        <div className="flex flex-col gap-y-2.5">
          <div className="flex flex-col gap-y-2 w-full">
            <TextWinds type="content_body3" color="purple5">
              *새로운 반 이름
            </TextWinds>
            <div className="flex gap-x-2">
              <ComInput
                type="text"
                placeholder="한글/영문/숫자 10자 이내"
                value={newClassName}
                onChange={(e) => setNewClassName(e.target.value)}
              />
              <Buttons
                type="outlined"
                color="purple5"
                isHeight="titlebutton"
                onClick={addClass}
                className="whitespace-nowrap"
              >
                <TextWinds type={'button'} color="purple5">
                  등록
                </TextWinds>
              </Buttons>
            </div>
          </div>
          <div className="border-y border-gray4">
            <div
              className="flex items-center justify-between py-2.5"
              onClick={() => {
                setDetailTab(!detailTab);
              }}
            >
              <TextWinds type="sub_title01">등록된 반 정보</TextWinds>
              <IconButton
                variant="text"
                className="w-6 max-w-6 h-6 max-h-6 text-gray8 hover:bg-transparent"
              >
                <ChevronDownIcon
                  className={`w-6 h-6 transition-transform ${
                    detailTab ? 'rotate-180' : ''
                  }`}
                  strokeWidth={2}
                />
              </IconButton>
            </div>
            {detailTab && (
              <div className="flex flex-col gap-y-2.5 py-2.5 border-t border-gray4 h-[120px] overflow-auto">
                {classLists.map((item) => (
                  <ModifyListItem
                    key={item.id}
                    classItem={item}
                    reloadClassList={reloadClassList}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="p-3 bg-purple0">
          <TextWinds type="content_body3" color="purple5" className="mb-1">
            반 기능 안내
          </TextWinds>
          <ul className="list-disc space-y-2 text-purple5 ml-4">
            <li className="text-xs font-normal">
              등록된 학생들을 특정 반에 배정할 수 있습니다.
            </li>
            <li className="text-xs font-normal">
              할 일(과제) 등록 및 관리 시 개별 학생이 아닌, 반 단위로 편하게
              관리 가능합니다.
            </li>
            <li className="text-xs font-normal">
              반 기능은 수업 운영을 위한 필수 기능은 아닙니다.
            </li>
          </ul>
        </div>
        {/* <Buttons type="filled" color="purple5" onClick={closeDrawerBottom}>
          <TextWinds type="button" color="white">
            완료
          </TextWinds>
        </Buttons> */}
      </div>
    )
  );
};

export default Modify;
