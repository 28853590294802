import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardBody, Button } from '@material-tailwind/react';
import TextWinds from '../../../../components/common/TextWinds';
import ProgressDefault from './ProgressDefault';
import { calcPercent, changeTimestamp } from '../../../../api/utils/util-func';

interface MyLearningType {
  id: string; // 학습 ID
  name: string; // 학습 이름
  thumbnail: string; // 썸네일
  isRegistered: boolean; // 학습 동의(시작) 여부
  enrolment: {
    id: string | null; // 수강 ID
    remainDays: string | null; // 수강 잔여일
    endDate: string | null; // 수강 종료일
    startDate: Date | null; // 수강 시작일
  };
  createdAt: string; // 학습 생성일
  updatedAt: string; // 학습 수정일
}
export interface CardProps {
  content?: string;
  openStart?: (id?: string) => void;
  openExtend?: (id?: string) => void;
  linkTo?: string;
  data?: MyLearningType;
  type?: string;
}

const LearningCard: React.FC<CardProps> = ({
  openStart,
  openExtend,
  linkTo,
  data,
  type,
}) => {
  // 내학습 강의 상태 [ 수강전, 수강중, 연장대기, 기간종료 ]
  // 수강전 : isRegistered = false, remainDays = null, endDate = null
  // 수강중 : isRegistered = true, remainDays > 0, endDate = Date
  // 기간종료 : isRegistered = true, remainDays = null, endDate = null

  // 클래스 관리 강의 상태
  // 강의중 : remainDays > 0
  // 기간종료 : remainDays = null

  // 학습 시작은 [ 수강전 ] 상태에서만 가능
  // 학습 연장은 [ 수강중, 연장대기 ] 상태에서만 가능
  // 학습 진행은 [ 수강중, 연장대기 ] 상태에서만 가능

  const navigate = useNavigate();

  // 학습 상태 확인
  const getLearningStatus = () => {
    if (type === 'learning') {
      if (data.enrolment?.remainDays === null) {
        if (data.isRegistered) {
          return '기간종료';
        }
        return '수강전';
      } else {
        if (Number(data.enrolment?.remainDays) <= 0) {
          return '기간종료';
        }
      }
      return '이용가능';
    } else {
      if (data.enrolment?.remainDays === null) {
        return '기간종료';
      } else {
        if (Number(data.enrolment?.remainDays) <= 0) {
          return '기간종료';
        }
      }
      return '이용가능';
    }
  };

  // 학습 기간 연장하기 버튼 활성 여부
  const getExtensionStatus = () => {
    if (type === 'learning') {
      // 등록 했음
      if (data.isRegistered) {
        // 남은 날이 있음
        if (data.enrolment?.remainDays) {
          // 날이 30일 이하일 경우 연장 활성
          if (Number(data.enrolment.remainDays) <= 30) {
            return true;
          }
          // 날이 30일 초과일 경우 연장 비활성
          return false;
        }
        // 남은 날이 없으면 연장 활성
        return true;
      }
      // 등록 안했으면 연장 비활성
      return false;
    } else {
      // 남은 날이 있음
      if (data.enrolment?.remainDays) {
        // 날이 30일 이하일 경우 연장 활성
        if (Number(data.enrolment.remainDays) <= 30) {
          return true;
        }
        // 날이 30일 초과일 경우 연장 비활성
        return false;
      }
      // 남은 날이 없으면 연장 활성
      return true;
    }
  };

  // [ 학습 진행 ] 카드, 시작하기를 클릭하여 학습 상세 페이지로 이동
  const startClick = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    // 권한 확인 후 페이지 이동
    e.stopPropagation();

    if (type === 'learning') {
      switch (getLearningStatus()) {
        case '수강전':
          openStart(data.id);
          break;
        case '이용가능':
          navigate(`${linkTo}`);
          break;
        case '기간종료':
          openExtend(data.id);
          break;
      }
    } else {
      if (getLearningStatus() === '이용가능') {
        navigate(`${linkTo}`);
      } else {
        openExtend(data.id);
      }
    }
  };

  return (
    <Card
      shadow={false}
      className="w-full bg-white rounded-lg overflow-hidden shadow-card xl:w-[376px] cursor-pointer group"
      onClick={startClick}
    >
      {data.thumbnail && (
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="hidden m-0 rounded-none xl:block h-[150px]"
        >
          <img
            src={data.thumbnail}
            alt={data.name}
            className="h-full w-full object-cover group-hover:scale-105 transition-transform duration-200 ease-in-out"
          />
        </CardHeader>
      )}
      {!data.thumbnail && (
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="hidden m-0 rounded-none xl:block h-[150px]"
        >
          <img
            src={process.env.PUBLIC_URL + '/images/default_img.jpeg'}
            alt={'empty_img'}
          />
        </CardHeader>
      )}

      <CardBody className={`p-0 px-3.5 py-3`}>
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center gap-x-2">
            <img
              src={process.env.PUBLIC_URL + 'images/common/icon_pencil.svg'}
              alt="pencil"
            />
            <TextWinds type="title_h4">{data.name}</TextWinds>
          </div>
          {data.enrolment?.endDate && data.enrolment?.remainDays !== null ? (
            <div className="flex flex-col gap-y-2">
              <ProgressDefault
                percentage={calcPercent(
                  new Date(data.enrolment.startDate),
                  new Date(data.enrolment.endDate)
                )}
              />
              {'learning' === type && (
                <div className="flex justify-between">
                  <TextWinds type="content_body4" color="gray7">
                    {Number(data.enrolment.remainDays) > 0
                      ? `${data.enrolment.remainDays}일 남음`
                      : '학습기간종료'}
                    ({changeTimestamp(data.enrolment.endDate)}까지)
                  </TextWinds>
                  {getExtensionStatus() && (
                    <TextWinds type="content_body4" color="red5">
                      학습기간 연장하기
                    </TextWinds>
                  )}
                </div>
              )}
              {'classmgmt' === type && (
                <div className="flex justify-between">
                  <TextWinds type="content_body4" color="gray7">
                    {Number(data.enrolment.remainDays) > 0
                      ? `${data.enrolment.remainDays}일 남음`
                      : '클래스기간종료'}
                    ({changeTimestamp(data.enrolment.endDate)}까지)
                  </TextWinds>
                  {getExtensionStatus() && (
                    <TextWinds type="content_body4" color="red5">
                      클래스기간 연장하기
                    </TextWinds>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-y-2">
              <ProgressDefault percentage={0} />
              {'learning' === type &&
                (getExtensionStatus() ? (
                  <div className="flex justify-between">
                    <TextWinds type="content_body4" color="gray7">
                      학습기간종료
                    </TextWinds>
                    <TextWinds type="content_body4" color="red5">
                      학습기간 연장하기
                    </TextWinds>
                  </div>
                ) : (
                  <TextWinds type="content_body4" color="gray7">
                    학습 시작 전
                  </TextWinds>
                ))}
              {'classmgmt' === type && getExtensionStatus() && (
                <div className="flex justify-between">
                  <TextWinds type="content_body4" color="gray7">
                    클래스기간종료
                  </TextWinds>
                  <TextWinds type="content_body4" color="red5">
                    클래스기간 연장하기
                  </TextWinds>
                </div>
              )}
            </div>
          )}
          {'learning' === type && (
            <Button
              className={`p-2 bg-purple5 shadow-none hover:bg-purple6 hover:shadow-none rounded`}
              disabled={
                data.isRegistered
                  ? Number(data.enrolment.remainDays) > 0
                    ? false
                    : true
                  : false
              }
              onClick={startClick}
            >
              <TextWinds type="button" color="white">
                학습 시작하기
              </TextWinds>
            </Button>
          )}
          {/* {date && <Progress value={date} color="blue" />} */}
        </div>
      </CardBody>
      {/* {(extension || complete) && (
                <CardFooter className="px-[14px] pb-[16px] pt-0">
                    <div className="border-t border-gray-400 border-solid mt-[8px] pt-[8px]">
                        {extension && (
                            <TextWinds type="content_body5" color="red5" onClick={dayOnClick}>
                                학습기간 연장하기
                            </TextWinds>
                        )}
                        {complete && (
                            <TextWinds type="content_body5" color="gray7">
                                모든 수강을 완료하였습니다.
                            </TextWinds>
                        )}
                    </div>
                </CardFooter>
            )} */}
    </Card>
  );
};

export default LearningCard;
