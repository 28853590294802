import React, { ReactNode } from 'react';
import { openNewWindow } from '../../../../api/utils/util-func';

const ReferenceButton = ({
  type,
  child,
  link,
}: {
  type: 'orange' | 'violet';
  child: ReactNode;
  link: string;
}) => (
  <>
    <div
      className={`'w-full bg-white rounded font-bold text-center border p-3 max-w-[300px] hover:bg-gray2 cursor-pointer' ${
        type === 'orange'
          ? 'border-orange5 text-orange5'
          : 'border-spotViolet text-spotViolet'
      }`}
      onClick={() => openNewWindow(link)}
    >
      {child}
    </div>
  </>
);

export default ReferenceButton;
