import { Typography } from "@material-tailwind/react";
import { variant } from "@material-tailwind/react/types/components/typography";
import React from "react";
import MediaQuery from "react-responsive";

interface ResponseTypoProps {
  variant_sm: variant;
  variant_lg: variant;
  className?: string;
  children: React.ReactNode;
}
/**
 * 반응형 텍스트 lg,sm 사이즈에 따라 variant를 변경해줌
 * @variant_sm sm 사이즈일 때 variant
 * @variant_lg lg 사이즈일 때 variant
 * @className className
 */
export default function ResponseTypo({
  variant_sm,
  variant_lg,
  className,
  children,
}: ResponseTypoProps) {
  return (
    <>
      <MediaQuery minWidth={960}>
        <Typography variant={variant_lg} className={className}>
          {children}
        </Typography>
      </MediaQuery>
      <MediaQuery maxWidth={960}>
        <Typography variant={variant_sm} className={className}>
          {children}
        </Typography>
      </MediaQuery>
    </>
  );
}
