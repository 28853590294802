import React from "react";
import Shape from "./images/shape-common-purple.png";
import ImageB from "./images/review2-b.png";
import ImageC from "./images/review2-c.png";
import ImageD from "./images/review2-d.png";
import Shape1 from "./images/shape1-purple.png";
import Shape2 from "./images/shape2-purple.png";
import Shape3 from "./images/shape3-purple.png";
import { Link } from "react-router-dom";

export const Review2 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-14 py-16 text-lg leading-7 text-center text-black bg-white max-md:px-5 relative overflow-hidden">
      <img
        src={Shape1}
        width={600}
        className="-left-52 top-[660px] hidden md:visible absolute"
      />
      <img
        src={Shape2}
        width={600}
        className="-right-52 top-[1900px] hidden md:visible absolute"
      />
      <img
        src={Shape3}
        width={600}
        className="-left-52 top-[2200px] hidden md:visible absolute"
      />
      <img loading="lazy" src={Shape} className="aspect-square w-[88px]" />
      <div className="mt-5 text-3xl font-bold leading-9 max-md:max-w-full">
        수요일에도 교회에 모여 영어로 말씀을 묵상해요!
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-20 tracking-normal text-black max-md:mt-10 max-md:max-w-full">
        <span className="font-medium tracking-normal leading-6">
          저희 교회는 특별히 주말이 아닌 수요일에 모임을 합니다.
        </span>
        <br />
        <br />
        굳이 수요일에 모여 젭스 프로그램으로 영어와 말씀을 가르치는 이유는
        <br />
        아이들이 일주일 동안의 삶 속에서 한 번 더 하나님을 기억하고
        <br />
        나누는 시간이 있길 바라는 마음으로,
        <br />
        그리고 부모님들 또한 아이들과 함께 자연스럽게
        <br />
        수요예배로 섬길 수 있길 바라는 마음으로 시작했습니다.
        <br />
        <br />
        <span className="text-purple5">결과는 대 성공!</span>
        <br />
        <span className="text-purple5">
          아이들의 교회 훈련, 부모님들의 예배 훈련
        </span>
        <br />
        <span className="text-purple5">
          이 두 마리 토끼를 잡은 것 같습니다.{" "}
        </span>
        <br />
        <br />
        저희는 달란트 시장, 연극발표 등을 하며 아이들과 즐겁게
        <br />
        <span className="text-purple5">
          영어로 하나님을 묵상하고 배우고 있습니다.
        </span>
        <br />
        <span className="text-purple5">
          그리고 자연스럽게 영어 실력 또한 쑥쑥 자라고 있습니다.
        </span>
        <br />
        <br />
        처음에 알파벳도 잘 모르는 친구들이 있었는데,
        <br />
        수요일 수업과 주중 줌 모임을 통해 리뷰와 숙제를 반복하며
        <br />
        지금은 읽기도 말하기도 곧잘 하는 수준까지 이르렀습니다.
      </div>
      <img
        loading="lazy"
        src={ImageB}
        className="mt-14 max-w-full aspect-[2.08] w-[554px] max-md:mt-10 relative"
      />
      <div className="mt-14 tracking-normal max-md:mt-10 max-md:max-w-full">
        사실, 젭스영어주일학교를 시작하며
        <br />
        <span className="font-medium tracking-normal leading-6">
          ‘과연 이게 될까?’
        </span>
        <br />
        라고 의심하기도 했었습니다. <br />
        이제는 저의 부족한 믿음을 회개하며 감사만이 넘치는 시간을 보내고
        있습니다.
        <br />
        <br />
        아이들은 영어를 통하여 성경을 접하고,
        <br />
        Q&A 시간을 통해 하나님을 깊이 묵상하며
        <br />
        아이들도 선생님도 함께 조금씩 성장하고 있습니다.
        <br />
        <br />
        교회에 더 힘써 모일 수 있고,
        <br />
        예배에 더 열심히 참여할 수 있도록
        <br />
        이렇게 좋은 교재와 커리큘럼, 다음세대 전도를 위한 좋은 프로그램을
        <br />
        제공해주심에 깊은 감사를 드립니다. 감사합니다!
        <br />
      </div>
      <img
        loading="lazy"
        src={ImageC}
        className="mt-24 max-w-full aspect-[1.52] w-[585px] max-md:mt-10 relative"
      />
      <img
        loading="lazy"
        src={ImageD}
        className="mt-12 max-w-full aspect-[1.06] w-[585px] max-md:mt-10 relative"
      />
      <div className="mt-24 text-3xl font-bold leading-9 text-slate-900 max-md:mt-10">
        - 수원명성교회 유** 선생님의 후기 -
      </div>
      <div className="shrink-0 self-stretch mt-24 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-28 tracking-normal max-md:mt-10">
        모두가 교회를 걱정하고 의심할 때<br />
        그럼에도 교회는 모여야 힘이 납니다.
        <br />
        <br />
        어려울수록 모이는 교회,
        <br />
        오히려 예배를 힘쓰는 교회에 더 큰 부흥이 일어납니다.
        <br />
      </div>
      <div className="justify-center px-8 py-7 mt-14 max-w-full font-medium tracking-normal leading-6 text-black bg-[#efe4fa] w-[376px] max-md:px-5 max-md:mt-10">
        “수요일에 모여 아이들과 함께 일주일의 삶 <br />
        속에서 한 번 더 하나님을 기억하고 있습니다.”
      </div>
      <div className="mt-16 tracking-normal text-purple5 max-md:mt-10 max-md:max-w-full">
        주일뿐 아니라 주중에도 교회에 모이는 아이들,
        <br />그 아이들과 함께 자연스럽게 예배 훈련을 받는 부모님들
        <br />
        젭스영어주일학교를 통해 일석이조, 두 마리 토끼를 잡을 수 있습니다!
      </div>
      <Link to="/courses/churchschool">
        <div className="justify-center items-center px-2.5 py-4 mt-20 max-w-full text-base font-bold tracking-normal leading-6 text-purple5 bg-white rounded border border-purple5 border-solid w-[376px] max-md:px-5 max-md:mt-10">
          주일학교 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
