interface PcLayoutProps {
    type?: "half";
    top?: React.ReactNode;
    bottom?: React.ReactNode;
    secClassName?: string;
    divClassName?: string;
    topClassName?: string;
    bottomClassName?: string;
}

const PcLayout: React.FC<PcLayoutProps> = ({ type, top, bottom, secClassName, divClassName, topClassName }) => {
    if (type === "half") {
        return <section className={`${secClassName} pt-[26px] px-5 xl:px-10 xl:pt-8`}>{bottom}</section>;
    }

    return (
        <section className={`${secClassName} xl:px-10 xl:pt-8`}>
            <div
                className={`flex flex-col gap-y-4 bg-white xl:px-8 xl:pt-6 xl:pb-8 xl:shadow-card xl:rounded-lg ${divClassName}`}
            >
                <div className={`${topClassName} pb-[18px] border-b border-gray4`}>{top}</div>
                {bottom}
            </div>
        </section>
    );
};

export default PcLayout;
