import { Tabs as MaterialTabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { useState } from "react";
import TextWinds from "../../../../components/common/TextWinds";

interface TabData {
    label: string;
    value: string;
    labelNum?: number;
    totalNum?: number;
    list?: Array<{
        value: string;
        title: string;
        date?: string;
        completeData?: string;
        result?: number;
    }>;
}

interface TabsProps {
    data: TabData[];
    children: React.ReactNode;
    onChange: (tabValue: string) => void; // onChange 콜백 함수 정의
}

const LearningTabs: React.FC<TabsProps> = ({ data, children, onChange }) => {
    const [activeTab, setActiveTab] = useState(data[0].value);

    const handleChangeTab = (tabValue: string) => {
        setActiveTab(tabValue);
        onChange(tabValue); // 탭 변경 시 onChange 콜백 호출
    };

    return (
        <MaterialTabs
            value={activeTab}
            className="relative w-full min-h-screen xl:flex xl:w-[976px] mx-auto xl:pt-[30px] xl:gap-[24px]"
        >
            <TabsHeader
                className="p-0 bg-transparent bg-white border-b rounded-none border-gray6 xl:flex-col xl:w-[276px] xl:p-[14px] xl:rounded-[8px] xl:shrink-0 xl:border-0"
                indicatorProps={{
                    className: "bg-transparent border-b-2 border-purple5 shadow-none rounded-none xl:border-0 ",
                }}
            >
                {data.map(({ label, value }) => (
                    <Tab
                        key={value}
                        value={value}
                        onClick={() => handleChangeTab(value)} // onClick 이벤트 핸들러 변경
                        className={`text-content_bold h-[48px] xl:h-[56px] xl:justify-start xl:rounded-[4px] xl:font-bold ${
                            activeTab === value ? "text-purple5 xl:bg-purple0 " : ""
                        }`}
                    >
                        {label}
                    </Tab>
                ))}
            </TabsHeader>
            <TabsBody className="xl:w-[676px] xl:shrink-0">{children}</TabsBody>
        </MaterialTabs>
    );
};

export default LearningTabs;
