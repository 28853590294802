import React, { useEffect, useState } from 'react';
import { classGroupType } from '../../../../../constants/types/student-type';
import { LmsClassGroupType } from '../../../../../constants/types/lms-type';
import { getClassGroupList } from '../../../../../api/utils/lms/lmsApi';
import { Radio, Skeleton } from 'antd';

interface ClassAssignListProps {
  classId: string;
  selected: classGroupType | undefined;
  setSelected: React.Dispatch<React.SetStateAction<classGroupType | undefined>>;
}
const ClassAssignList = ({
  classId,
  selected,
  setSelected,
}: ClassAssignListProps) => {
  const [classList, setClassList] = useState<LmsClassGroupType[]>();
  const [selectRadio, setSelectRadio] = useState<number>(selected?.id);

  useEffect(() => {
    getClassGroupList(classId).then((res) => {
      setClassList(res.result);
    });
  }, []);

  const radioHandler = (e: any) => {
    const value = e.target.value;
    setSelectRadio(value);
    setSelected(classList?.find((item) => item.id === value));
  };

  return classList ? (
    <Radio.Group
      onChange={radioHandler}
      value={selectRadio}
      className="flex flex-col gap-1"
    >
      {classList?.map((classItem) => (
        <Radio
          key={classItem.id}
          value={classItem.id}
          className={`p-1 px-3 rounded ${
            classItem.id === selectRadio ? 'bg-purple1' : 'bg-gray1'
          } hover:bg-purple2`}
        >
          {classItem.name}
        </Radio>
      ))}
    </Radio.Group>
  ) : (
    <Skeleton />
  );
};

export default ClassAssignList;
