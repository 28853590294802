import { createContext } from 'react';
import { studentProfileType } from '../../../constants/types/student-type';
type StudentProfileContextType = {
  studentProfile: studentProfileType;
  setStudentProfile: (studentProfile: studentProfileType) => void;
};

export const StudentProfileContext = createContext<StudentProfileContextType>({
  studentProfile: undefined,
  setStudentProfile: () => {},
});
