import React, { useContext } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import Buttons from '../../../components/common/Buttons';
import { SignupContext } from './SignupContext';
import { useNavigate } from 'react-router-dom';
import { AGREEMENT_DATA } from '../../../data/statics-datas';
import { AgreementType } from '../../../components/common/Agreement';
import {
  AgreementBox,
  AgreementObjType,
} from '../../../components/common/AgreementBox';

const AgreementCheck = () => {
  const agreementList: AgreementObjType[] = [
    { agree: AgreementType.TERAMS_CONDITION, essential: true },
    { agree: AgreementType.PERSONAL_COLLECTION, essential: true },
    { agree: AgreementType.PROMOTION, essential: false },
  ];
  const { step, setStep } = useContext(SignupContext);
  const [allCheck, setAllCheck] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-8">
      <section>
        <TextWinds type="title_h2">회원가입 약관동의</TextWinds>
      </section>
      <section className="flex flex-col gap-2">
        <AgreementBox agreementList={agreementList} essentialChecked={setAllCheck} />
      </section>
      <section className="flex flex-col gap-2">
        <Buttons
          type="filled"
          color={'purple5'}
          disabled={!allCheck}
          onClick={() => setStep(step + 1)}
        >
          다음
        </Buttons>
        <Buttons
          type="outlined"
          color={'purple5'}
          onClick={() => navigate('/signin')}
        >
          취소
        </Buttons>
      </section>
    </div>
  );
};

export default AgreementCheck;
