/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';

const PurchaseStaging = () => {
  const navigate = useNavigate();
  // const { query } = useRouter()
  const query = new URLSearchParams(useLocation().search);
  const res = query.get('res') as string;
  const mst_id = query.get('mst_id') as string;

  useEffect(() => {
    // 결제 결과
    if (!res) {
      handleTimeoutCount();
      return;
    }

    // 결제 결과 전달
    const success = res === 'y' ? true : false;
    if (success) {
      navigate(`/purchase/complete/${mst_id}`);
    } else {
      const templateId = localStorage.getItem('templateId');
      navigate('/purchase', { state: { purchaseId: templateId } });
      localStorage.removeItem('templateId');
    }
  }, [res]);

  const handleTimeoutCount = () => {
    // handle timeout
  };

  return (
    <div className="w-full h-full flex items-center">
      <LoadingSpinner />
    </div>
  );
};

export default PurchaseStaging;
