import { Typography } from '@material-tailwind/react';
import { numberWithCommas } from '../../api/utils/format/numbers';
import TextWinds from '../common/TextWinds';
import Buttons from '../common/Buttons';

const CartRight = ({
  selectedProductsCnt,
  allOrderPrice,
  allProdPrice,
  allSalePrice,
  deliveryPrice,
  allPurchasePrice,
}: {
  selectedProductsCnt: number;
  allOrderPrice: number;
  allProdPrice: number;
  allSalePrice: number;
  deliveryPrice: number;
  allPurchasePrice: number;
}) => {
  return (
    <>
      <div className="relative w-full py-[14px] px-[20px] bg-gray0 mt-[30px] rounded-md shadow-none h-fit xl:mt-0 xl:bg-white xl:rounded-[8px]">
        <div>
          <div className="flex flex-row items-center justify-between pt-[10px]">
            <TextWinds type="content">선택 상품 수</TextWinds>
            <div className="flex gap-1 items-center">
              <TextWinds
                type="title_h3"
                className="flex items-center gap-[5px]"
              >
                {selectedProductsCnt}
              </TextWinds>
              <TextWinds type="content">개</TextWinds>
            </div>
          </div>

          <div className="my-[16px] border border-t-[1px] border-gray4" />

          <div className="flex flex-row items-center justify-between pt-[10px]">
            <TextWinds type="content">주문금액</TextWinds>
            <div className="flex gap-1 items-center">
              <TextWinds
                type="title_h3"
                className="flex items-center gap-[5px]"
              >
                {numberWithCommas(allOrderPrice)}
              </TextWinds>
              <TextWinds type="content">원</TextWinds>
            </div>
          </div>
          <ul className="list-disc">
            <li className="flex flex-row items-center justify-between pt-[10px]">
              <TextWinds type="content_rel" color="gray7">
                &middot; 상품금액
              </TextWinds>
              <TextWinds
                type="content_rel"
                className="flex items-center gap-[5px]"
              >
                {numberWithCommas(allProdPrice)} 원
              </TextWinds>
            </li>
            <li className="flex flex-row items-center justify-between pt-[10px]">
              <TextWinds type="content_rel" color="gray7">
                &middot; 배송비
              </TextWinds>
              <TextWinds
                type="content_rel"
                className="flex items-center gap-[5px]"
              >
                {numberWithCommas(deliveryPrice)} 원
              </TextWinds>
            </li>
          </ul>
          {/* <div className="flex flex-row items-center justify-between pt-[10px]">
            <TextWinds type="content">상품금액</TextWinds>
            <TextWinds type="title_h3" className="flex items-center gap-[5px]">
              {numberWithCommas(allProdPrice)}
              <TextWinds type="content">원</TextWinds>
            </TextWinds>
          </div>
          <div className="flex flex-row items-center justify-between pt-[10px]">
            <TextWinds type="content">배송비</TextWinds>
            <TextWinds type="title_h3" className="flex items-center gap-[5px]">
              {numberWithCommas(deliveryPrice)}
              <TextWinds type="content">원</TextWinds>
            </TextWinds>
          </div> */}

          <div className="my-[16px] border border-t-[1px] border-gray4" />

          <div className="flex flex-row items-center justify-between pt-[10px]">
            <TextWinds type="content">할인금액</TextWinds>
            <div className="flex gap-1 items-center">
              <TextWinds
                type="title_h3"
                className="flex items-center gap-[5px]"
              >
                {numberWithCommas(allSalePrice)}
              </TextWinds>
              <TextWinds type="content">원</TextWinds>
            </div>
          </div>

          <div className="my-[16px] border border-t-[1px] border-gray4" />

          <div className="flex flex-row items-center justify-between pt-[10px]">
            <TextWinds type="content">총 결제금액</TextWinds>
            <div className="flex gap-1 items-center">
              <TextWinds
                type="title_h3"
                color="purple5"
                className="flex items-center gap-[5px]"
              >
                {numberWithCommas(allPurchasePrice)}
              </TextWinds>
              <TextWinds type="content" color="purple5">
                원
              </TextWinds>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartRight;
