import React, { Fragment } from "react";
import Buttons from "../../../components/common/Buttons2";
import ComInput from "../../../components/common/ComInput";
import ComInputButton from "../../../components/common/ComInputButton";
import TextWinds from "../../../components/common/TextWinds";

const LoginPasswordEmail = () => {
    return (
        <Fragment>
            <div className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-y-2">
                    <TextWinds type="content_body3">이름</TextWinds>
                    <ComInput type="text" placeholder="이름 입력" onChange={(e) =>{}} />
                </div>
                <div className="flex flex-col gap-y-2">
                    <TextWinds type="content_body3">아이디</TextWinds>
                    <ComInput type="text" placeholder="아이디 입력" onChange={(e) => {}} />
                </div>
                <div className="flex flex-col gap-y-2">
                    <TextWinds type="content_body3">이메일 주소</TextWinds>
                    <ComInputButton
                        type="number"
                        placeholder="이메일 입력"
                        onChange={(e) => {}}
                        buttonText="인증확인"
                        variant="filled"
                    />
                </div>
                <div className="flex flex-col gap-y-2">
                    <TextWinds type="content_body3">인증번호</TextWinds>
                    <ComInputButton
                        type="number"
                        placeholder="인증번호 입력"
                        onChange={(e) => {}}
                        buttonText="인증확인"
                        variant="filled"
                    />
                </div>
            </div>
            <div className="flex flex-col gap-y-2 mt-[52px]">
                <Buttons variant="filled" color="purple5">
                    <TextWinds type="button" color="white">
                        다음
                    </TextWinds>
                </Buttons>
                <Buttons variant="outlined" color="purple5">
                    <TextWinds type="button" color="purple5">
                        이전
                    </TextWinds>
                </Buttons>
            </div>
        </Fragment>
    );
};

export default LoginPasswordEmail;
