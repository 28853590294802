import React from 'react';
import { ArrowIcon, CircleCheckIcon, CircleIconBox } from './WmwComp';
import monitor from '../img/circle-monitor.png';
import file from '../img/sec12-file-text-front-gradient.png';
import headphone from '../img/sec12-headphone-front-gradient.png';
import medal from '../img/sec12-medal-front-gradient.png';

const WmwSection12 = () => {
  return (
    <div className="bg-[#ffffff] flex justify-center p-3 py-10 md:p-12 md:py-20">
      <div className="w-full max-w-[520px] flex flex-col justify-center items-center gap-10 md:gap-12">
        <div className="text-3xl md:text-4xl  text-center font-bold font-['Pretendard'] leading-tight md:leading-snug">
          <span className="text-[#6730ff]">WMW </span>Series는
          <br />
          [교사대학] 수료 후
          <br />
          도입 가능해요!
        </div>

        <div className="bg-[#F5F4FF] w-full rounded-xl p-5 shadow-md flex flex-col justify-center items-center gap-5 md:gap-10">
          <div
            className=" border-[0.5px] border-[#0d0bea] rounded-full px-5 py-1 w-fit
              text-lg md:text-xl text-black font-bold"
          >
            수료 과정
          </div>

          <section className="flex flex-col justify-center items-center gap-5">
            {/* 첫번째줄 */}
            <div className="flex justify-center items-center gap-5">
              <CircleIconBoxWithTitleBox icon={monitor} title="코스 신청" />
              <ArrowIcon />
              <CircleIconBoxWithTitleBox
                icon={headphone}
                title="온라인 강의 수강"
              />
            </div>
            {/* 두번째줄 */}
            <ArrowIcon
              style={{ marginLeft: '-10px', transform: 'rotate(135deg)' }}
            />
            {/* 세번째줄 */}
            <div className="flex justify-center items-center gap-5 md:gap-8">
              <CircleIconBoxWithTitleBox icon={file} title="교인 인증" />
              <ArrowIcon />
              <CircleIconBoxWithTitleBox icon={medal} title="수료&교회 도입" />
            </div>
          </section>
          <div className="flex flex-col gap-2 text-left text-[15px] md:text-lg font-light">
            <div className="flex gap-2">
              <CircleCheckIcon
                width="20"
                circleColor="#0D0CEA"
                checkColor="#0D0CEA"
              />
              <span>
                <span className="font-bold">교육대상</span> 도입 예정 담당
                부서의 목회자 or 교사
              </span>
            </div>
            <div className="flex gap-2">
              <CircleCheckIcon
                width="20"
                circleColor="#0D0CEA"
                checkColor="#0D0CEA"
              />
              <span className="flex items-center gap-2 whitespace-nowrap">
                <span className="font-bold">교육기간</span>
                온라인 학습 약 1주 (수강기간 60일 제공)
              </span>
            </div>
          </div>

          <div className="text-sm md:text-base text-center">
            * 본 교육은 개신교 보편적인 교단에 속한 교회의 본인이
            <br />본 교회에서 진행할 경우에만 신청 가능 (교회114 기준으로 확인)
          </div>
        </div>
      </div>
    </div>
  );
};

const CircleIconBoxWithTitleBox = ({
  icon,
  title,
}: {
  icon: string;
  title: string;
}) => (
  <div className="flex flex-col justify-center items-center gap-2">
    <CircleIconBox
      icon={icon}
      circleColor="bg-[#d9d9d9]"
      circleSize="h-24 w-24 md:h-32 md:w-32"
    />
    <div className="flex-1 bg-[#6730FF] text-white rounded-lg px-3 md:px-5 py-2 text-sm md:text-base font-medium whitespace-nowrap shadow">
      {title}
    </div>
  </div>
);

export default WmwSection12;
