import React, { useMemo } from 'react';
import { LmsContainer } from '../../../components/layout/LmsLayouts';
import { Link, useParams } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import { useAppDispatch } from '../../../store/hook';
import { openModal } from '../../../store/features/modalSlice';
import RecitationResultDetailModal from './RecitationResultDetailModal';
import {
  recitationBookType,
  recitationLevelType,
} from '../../../constants/types/lms-type';
import { getRecitationResultDetail } from '../../../api/utils/lms/lmsApi';
import dayjs from 'dayjs';
import IconAward from '../../student/certification/IconAward';
import ModalRecitationCertificate from '../../../components/modal/ModalRecitationCertificate';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';

const RecitationHistoryDetail = () => {
  const { classId, studentId } = useParams();
  const [levelList, setLevelList] = React.useState<recitationLevelType[]>([]);

  React.useEffect(() => {
    // fetch data
    getRecitationResultDetail(classId, studentId).then((data) => {
      setLevelList(data);
    });
  }, []);

  return (
    <LmsContainer
      header={<TextWinds type="title_h3">인증제 내역 상세보기</TextWinds>}
    >
      <div className="grid grid-cols-1  gap-5">
        {levelList?.map((level) => (
          <Level data={level} key={level.level} />
        ))}
      </div>
    </LmsContainer>
  );
};

const Level = ({ data }: { data: recitationLevelType }) => {
  const dispatch = useAppDispatch();
  const checkBadge = !data.results
    ?.map((book) => (book.passDate ? true : false))
    .includes(false);

  const openCertificateModal = () => {
    dispatch(
      openModal({
        body: (
          <ModalRecitationCertificate
            englishName={'ENGLISH NAME'}
            subject={'LEVEL 1' + data.level}
            date={new Date('2024-10-7')}
          />
        ),
      })
    );
  };

  return (
    <div className="flex flex-col lg:flex-row gap-3 mt-4 items-center">
      {/* <TextWinds type={'title_h3'} children={`Level ${level.groupLevel}`} /> */}
      <img
        src={
          process.env.PUBLIC_URL +
          `/images/student/badge/recitation/LV${data.level}.png`
        }
        className={`h-[100px] ${checkBadge ? '' : 'filter grayscale'}`}
      />
      <div className="w-full">
        <div className="flex gap-2 items-center">
          <TextWinds type="title_h3">LEVEL {data.level}</TextWinds>
          {/* {checkBadge && (
            <button
              onClick={openCertificateModal}
              className="text-sm text-skyblue border border-skyblue rounded-lg px-1 p-[2px] hover:bg-gray2"
            >
              인증서 <DocumentArrowDownIcon className="w-4 h-4 inline" />
            </button>
          )} */}
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-2">
          {data?.results.map((book) => (
            <Book key={book.title} book={book} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Book = ({ book }: { book: recitationBookType }) => {
  const dispatch = useAppDispatch();
  const onoff = book.passDate ? true : false;

  const openDetailView = () => {
    dispatch(openModal({ body: <RecitationResultDetailModal /> }));
  };

  return (
    <div className="flex flex-col">
      <div
        className={`w-full text-center shadow-card rounded-lg py-3 text-lg font-bold ${
          onoff ? 'bg-spotLilac text-white' : 'bg-gray-100 text-gray-500'
        }`}
        // onClick={() => {
        //   alert('TODO : 버튼인지 보는 용도인지 확인 필요');
        // }}
      >
        <div
          className={`flex flex-row sm:flex-row items-center justify-center gap-2`}
        >
          {onoff && <IconAward />}
          {book.title}
        </div>
      </div>
      <TextWinds
        type="content_body3"
        color="gray7"
        className="text-center mt-1"
      >
        {book.passDate
          ? '합격 : ' + dayjs(book.passDate).format('YYYY-MM-DD')
          : book.failDate
          ? '불합격 : ' + dayjs(book.failDate).format('YYYY-MM-DD')
          : book.testDate
          ? '연습 : ' + dayjs(book.testDate).format('YYYY-MM-DD')
          : ''}
      </TextWinds>
    </div>
  );
};

export default RecitationHistoryDetail;
