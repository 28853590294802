import TextWinds from '../../../components/common/TextWinds';
import { AgreementType } from '../../../components/common/Agreement';
import { AgreementBox } from '../../../components/common/AgreementBox';
import FormRow from '../../../components/common/input/FormRow';
import { Control } from 'react-hook-form';
import { studentInfo } from './StudentFirstLogin';
import React from 'react';

export type ParentInfo = {
  name: string;
  birth: Date;
  gender: string;
  phone: string;
};

interface GuardianInputProps {
  control: Control<studentInfo>;
  setAgree: (value: boolean) => void;
}
const GuardianInput = ({ control, setAgree }: GuardianInputProps) => {
  const agreement = [
    { agree: AgreementType.PERSONAL_POLICY, essential: true },
    { agree: AgreementType.PERSONAL_COLLECTION, essential: true },
  ];

  return (
    <div className="flex flex-col gap-2">
      <TextWinds type={'title_h5'}>보호자 성함</TextWinds>
      <FormRow
        control={control}
        type="text"
        name="guardianInfo.name"
        placeholder="보호자 성함"
      />

      <TextWinds type={'title_h5'}>보호자 생년월일 및 성별</TextWinds>
      <div className="grid grid-cols-2 gap-2">
        <FormRow
          control={control}
          type="date"
          name="guardianInfo.birthday"
          placeholder="보호자 생년월일"
        />
        <FormRow
          control={control}
          type="gender"
          name="guardianInfo.gender"
          placeholder="보호자 성별"
        />
      </div>

      <section className="flex flex-col gap-5 mt-3">
        <div className="flex flex-col gap-2">
          <TextWinds type={'title_h5'}>보호자 연락처</TextWinds>
          <FormRow type="phone" name="guardianInfo.phone" control={control} />
        </div>
        <div className="flex flex-col gap-2">
          <AgreementBox agreementList={agreement} essentialChecked={setAgree} />
        </div>
      </section>
    </div>
  );
};

export default GuardianInput;
