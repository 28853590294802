import React from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import TextWinds from './TextWinds';
import HFbutton from './input/HFbutton';

export enum AgreementType {
  TERAMS_CONDITION = '이용약관',
  PERSONAL_POLICY = '개인정보처리방침',
  PERSONAL_COLLECTION = '개인정보 수집/이용동의',
  REFUND = '환불규정',
  PROMOTION = '프로모션 정보 수신 동의',
  LEARNING_HOMESCHOOL = 'JEMM회원약관(회원용)',
  LEARNING_TEACHING = '내학습(홈스쿨 외) 약관',
}
export const getAgreement = (type: AgreementType): string => {
  switch (type) {
    case AgreementType.TERAMS_CONDITION:
      return TERAMS_CONDITION;
    case AgreementType.PERSONAL_POLICY:
      return PERSONAL_POLICY;
    case AgreementType.PERSONAL_COLLECTION:
      return PERSONAL_COLLECTION;
    case AgreementType.REFUND:
      return REFUND;
    case AgreementType.PROMOTION:
      return PROMOTION;
    case AgreementType.LEARNING_HOMESCHOOL:
      return LEARNING_HOMESCHOOL;
    case AgreementType.LEARNING_TEACHING:
      return LEARNING_TEACHING;
    default:
      return `약관이 없습니다.`;
  }
};

export default getAgreement;

export const AgreementModal = ({ agreement }: { agreement: AgreementType }) => {
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(closeModal());
  };
  return (
    <div className="flex flex-col gap-4 bg-white rounded p-5 w-[80vh] h-auto max-h-[80vh] mountAnimation">
      <TextWinds type="title_h3">{agreement}</TextWinds>
      <div className="h-full overflow-y-auto">
        <pre className="whitespace-pre-wrap">{getAgreement(agreement)}</pre>
      </div>
      <HFbutton onClick={closeHandler}>닫기</HFbutton>
    </div>
  );
};

const TERAMS_CONDITION = `*이용약관
[제 1 장 총칙]
제 1 조 목적
이 서비스 약관은 주식회사 젭스(이하 “회사”)가 제공하는 서비스를 이용함에 있어 필요한 회사와 회원간의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 그 목적으로 합니다.

제 2 조 용어의 정의
1.이용자: 서비스 이용을 신청하고 회사가 이를 승낙하여 회원ID를 발급 받은 자
2.회원ID: 이용자 식별과 서비스 이용을 위하여 이용자가 선정하고 회사가 부여하는 영문자와 숫자의 조합
3.비밀번호: 이용자와 회원ID가 일치하는지를 확인하고 통신상의 자신의 비밀보호를 위하여 이용 자신이 선정한 영문자와 숫자, 특수문자의 조합
4.요금납입책임자: 회사에 대하여 부담하는 서비스 요금 등 약관에 따른 모든 채무를 이행할 의무가 있는자
5.해지: 회사 또는 이용자가 서비스 개통 후에 그 이용계약을 종료시키는 의사표시

제 3 조 약관의 효력 및 변경
1. 이 약관은 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력을 발생합니다.
2. 본 약관의 내용은 서비스 홈페이지(URL,이하 "홈페이지"라 함) 내에 게시합니다.
3. 회사는 필요하다고 인정되는 경우 이 약관을 개정할 수 있으며, 약관이 개정된 경우에는 지체 없이 제2항과 같은 방법으로 공시합니다. 약관을 개정할 경우에는 현행약관과 함께 개정 적용일자 7일 이전부터, 이용자에게 개정 내용이 불리한 경우에는 그 적용일자 30일전부터 각각 이를 사이트에 고지합니다.
4. 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴할 수 있습니다. 다만, 이용자가 제3항의 방법 등으로 고지한 바에 따라 변경된 약관의 적용일로부터 15일 이내에 회사에 거부 의사를 표시하지 않는 경우에는 변경된 약관에 동의한 것으로 간주합니다.
5. 회사와 회원은 필요에 따라 별도의 계약(서면합의)을 할 수 있으며 해당 내용이 본 약관과 상충할 경우에는 별도의 계약서에 명시된 내용이 우선하여 적용됩니다.

제 4 조 약관 외 준칙
이 약관에 명시되지 않은 사항에 대해서는 전기통신기본법, 전기통신사업법, 독점 규제 및 공정거래에 관한 법률, 컴퓨터 프로그램 보호법, 저작권법, 전자상거래 등에 관한 소비자 보호에 관한 법률 등 관계법령 및 회사가 정한 서비스의 세부이용지침 등의 규정에 따릅니다.

제 5 조 회원가입
1. 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
2. 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
1) 등록내용에 허위, 기재누락, 오기가 있는 경우
2) 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우
3) 회사가 본인확인 절차를 실시할 경우에 본인 확인이 되지 않거나 본인이 아님이 확인된 경우
3. 회원은 회원정보에 변경이 있는 경우, 회원정보 수정 등의 방법으로 그 변경사항을 반영하여야 합니다. 회원정보 수정을 하지 않음으로써 발생하는 회원의 손해에 대하여 회사는 아무런 책임을 지지 않습니다.

제 6 조 회원 탈퇴 및 자격 상실 등
1. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 자격을 제한 또는 정지, 상실시킬 수 있습니다.
1) 회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우
2) 서비스를 이용하여 구입한 재화 등의 대금, 기타 이용과 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
3) 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위반하는 경우
4) 주소, 이메일 등 회원정보를 잘못 입력하거나 변경하지 않는 등 회원의 귀책사유로 인하여 회사가 상당한 노력을 기울였음에도 불구하고 회원에게 통지 또는 연락을 할 수 없게 된 경우
5) 이 약관의 규정 또는 회사의 이용정책 등을 위반하거나 법령 기타 공서양속에 반하는 행위를 하는 경우
6) 기타 다음과 같은 행위 등으로 회사의 건전한 운영을 해하거나 회사의 업무를 방해하는 경우
-회사의 운영과 관련하여 근거 없는 사실 또는 허위의 사실을 적시하거나 유포하여 회사의 명예를 실추시키거나 회사의 신뢰성을 해하는 경우
-서비스의 이용과정에서 직원에게 폭언, 협박 도는 음란한 언행, 이에 준하는 행동 등으로 회사의 운영을 방해하는 경우
-서비스를 통해 구입한 상품 또는 용역에 특별한 하자가 없는데도 불구하고 일부 사용 후 상습적인 취소 및 전부 또는 일부 반품, 이의 제기 등으로 회사의 업무를 방해하는 경우
-이미 가입된 회사의 회원의 개인정보(이름, 전화번호, 이메일 주소 등)와 동일한 경우
-부정한 용도를 목적으로 서비스를 이용하는 경우
-동일/유사한 아이디, 전화번호, 이메일 주소 등의 회원정보를 통하여 부정한 사용을 하는 것으로 의심되는 경우
-무단으로 회사의 재화, 용역, 정보를 수집하거나 외부에 제출, 게시, 이용하여 회사의 저작권, 상표권 등의 지식재산권을 침해하는 경우
2. 회사가 회원자격을 상실시키는 경우, 회원에게 이를 통지하고 회원 정보 말소 30일 전 기간동안 소명할 기회를 부여합니다.

제 7 조 회원에 대한 통지
1. 회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 이메일, 문자, 전화 등의 방법으로 할 수 있습니다.
2. 회사는 불특정다수의 회원에 대한 통지의 경우 1주일 이상 서비스 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다

[제 2 장 서비스 이용 계약]
제 1 조 서비스 이용계약의 성립, 이용신청 및 승낙
1. 이용계약은 회원이 되고자 하는 자가 본 약관의 내용 및 개인정보 처리방침에 대하여 동의를 한 후 이용신청을 할 경우 체결됩니다.
2. 회원이 되고자 하는 자는 사이트에 가입 시 회사에서 제공하는 회원가입 신청양식에 따라 필요한 사항을 기재하여야 합니다. 회원가입 신청 시 선택기재 항목 중 일부 항목은 서비스 신청 시 반드시 입력해야 합니다.
3. 회사는 회원이 되고자 하는 자에게 이메일, SMS 인증 또는 기타 본인을 확인할 수 있는 인증절차를 요구할 수 있습니다. 이 경우 회원이 되고자 하는 자는 해당 인증을 수행하는 등 가입절차를 완료하여야 합니다.
4. 회사는 다음의 각 호에 해당하는 신청에 대하여는 이용계약을 해지할 수 있습니다.
1) 실명이 아니거나, 다른 사람의 명의사용 등 이용자 등록 시 허위로 신청하는 경우
2) 이용자의 서비스 이용 목적이 명백하게 사회 질서 및 미풍양속에 반하는 경우
3) 서비스 이용목적 또는 이용행위가 법령에 위반되거나 제3자의 권리를 침해할 우려가 있는 경우
4) 악성 프로그램 및 버그 이용 또는 시스템 취약점을 악용하는 등 부정한 방법을 서비스에 사용한 경우
5) 이전에 회원 자격을 상실한 적이 있는 경우. 다만, 회사가 승낙한 경우는 예외로 함
6) 기타 회사가 정한 이용신청 요건이 만족되지 않았을 경우

제 2 조 서비스 이용 제한 및 자격상실
1. 회원이 다음 각 호의 사유에 해당하거나 본 약관상의 의무를 이행하지 않은 경우, 회사는 회원 자격을 제한 또는 정지시킬 수 있으며, 회원은 회사에게 제한 또는 정지에 따른 보상을 청구할 수 없습니다.
1) 전기통신기본법, 전기통신사업법, 정보통신윤리위원회심의규정, 정보통신윤리강령, 프로그램보호법, 산업안전보건법 및 기타 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하는 경우
2) 방송통신위원회 또는 한국인터넷진흥원 등이 불법스팸 전송사실을 확인하여 이용정지를 요청하는 경우
3) 제13조 이용자의 의무 중 하나 또는 그 이상을 위반하는 경우
2. 회사가 다음 각 호의 사유에 해당하거나 회원의 자격을 제한정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우에 회사는 해당 회원의 자격을 상실 시킬 수 있으며, 회원은 회사에게 자격 상실에 따른 보상을 청구할 수 없습니다.
1) 회원의 계정, 명의를 도용하거나 결제 도용한 경우
2) 해킹 및 악성프로그램을 배포하는 경우
3. 회원은 본 조의 회사 조치에 대하여 회사가 정한 절차에 따라 이의신청을 할 수 있으며, 회원의 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.

[제 3 장 서비스 이용]
제 1 조 서비스의 이용시간
1. 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다.
2. 다만, 회사의 업무상이나 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영 상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전에 이를 공지하며, 부득이한 경우 사후 통보할 수 있습니다.
3. 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다.

제 2 조 서비스의 변경 및 중지
1. 회사는 변경될 서비스의 내용 및 제공일자를 회원에게 통지하고 서비스를 변경하여 제공할 수 있습니다.
2. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
1) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
2) 이용자가 회사의 영업활동을 방해하는 경우
3) 정전, 제반 설비의 장애 또는 이용양의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
4) 상품 공급 사와의 계약 종료 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우
5) 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
6) 기타 서비스를 유지할 수 없는 중대한 사유가 발생한 경우
3. 회사가 통제할 수 없는 사유로 인한 서비스의 중단(회사의 고의, 과실이 없는 디스크 장애, 시스템다운 등)으로 인하여 사전 통지가 불가능한 경우에는 사후통지 하거나 통지가 이루어지지 않을 수 있습니다.

제 3 조 회원정보의 변경
1. 회원은 회원 정보관리화면을 통하여 언제든지 회원정보를 열람하고 수정할 수 있습니다. 다만 서비스 운영을 위하여 회사가 정한 일부 사항은 수정이 제한될 수 있습니다.
2. 회원은 이용 신청 시 기재한 사항이 변경되었을 경우 즉시 온라인으로 수정을 하거나 회사가 정한 별도의 정해진 양식 및 이용방법으로 변경사항을 알려야 하며, 변경사항을 알리지 아니하여 발생한 손해에 대하여 회사는 책임지지 않습니다.

제 4 조 아이디와 비밀번호 관리에 대한 의무와 책임
1. 회원은 자신의 아이디와 비밀번호가 제3자에게 노출 또는 이용되지 않도록 철저히 관리하여야 합니다.
2. 회원이 전 항의 의무를 위반하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정사용 등에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며, 회사는 그에 대한 책임을 지지 않습니다.
3. 회사는 회원의 아이디로 인하여 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인 우려가 있는 경우 해당 계정의 이용을 제한할 수 있습니다.
4. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
5. 전 항의 경우에 회원이 회사에 그 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제 5조 포인트 쿠폰
1. 회사는 회사의 정책에 따라 서비스 이용에 따른 혜택 또는 서비스 이용상 편의를 위해 회원에게 회사가 제공하는 서비스를 이용하는 데 있어 서비스 내에서 결제 및 각종 활용 가능한 포인트 또는 쿠폰(이하 "포인트/쿠폰")을 회사가 정한 기준에 따라 무상으로 부여할 수 있습니다.
2. 포인트/쿠폰 적립기준, 사용방법, 사용 가능 시점, 사용 한도, 유효기간 및 제한 등 구체적 조건에 관한 사항은 서비스 내에서 개별적으로 고지합니다.
3. 포인트/쿠폰의 유효기간은 적립일로부터 회사가 정하여 고지한 기간으로 설정되며, 유효기한 동안 사용되지 않거나 회원 탈퇴 또는 자격상실 사유가 발생한 경우 자동으로 소멸됩니다. 회원 탈퇴 내지 자격상실로 포인트/쿠폰이 소멸된 경우 재가입하더라도 소멸된 포인트/쿠폰은 복구되지 않습니다.
4. 포인트/쿠폰은 현금으로 환전 또는 환불되지 않습니다. 회원은 포인트/쿠폰을 제3자에게 또는 다른 아이디로 양도할 수 없으며, 유상으로 거래하거나 현금으로 전환할 수 없습니다.
5. 만일 회원이 회사가 승인하지 않은 방법으로 포인트/쿠폰을 획득/이용한 사실이 확인될 경우 회사는 회원에게 이를 통지하고 소명의 기회를 부여한 뒤, 소명의 결과를 확인하여 회원의 포인트/쿠폰 회수, ID삭제, 포인트/쿠폰 사용 제한 등 필요한 조치를 취할 수 있습니다. 단, 통지 시부터 소명 결과 확인 시까지 포인트/쿠폰의 사용을 임시로 중지할 수 있습니다.
6. 포인트/쿠폰의 적립기준, 사용방법, 사용 가능 시점, 사용한도, 유효기간 및 제한 등 본 조에서 구체적으로 규정하지 아니한 포인트/쿠폰 관련 회사의 정책은 회사의 영업정책에 따라 변동될 수 있습니다. 회원에게 불리한 변경인 경우에는 규정에 따라 공지 또는 통지하며 서비스 계속 이용시 동의한 것으로 간주됩니다.
7. 회사가 영업양도, 분할, 합병 등에 따라 영업을 폐지하거나 서비스의 전체 또는 일부의 제공을 종국적으로 종료하는 경우, 또는 포인트/쿠폰 제도의 운영을 종료하는 경우 사전에 고지된 영업 폐지일, 서비스 또는 포인트.쿠폰 제도 운영 종료일까지 사용되지 않은 포인트/ 쿠폰은 소멸합니다.

[제 4 장 계약당사자의 의무]
제 1 조 회사의 의무
1. 회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.
2. 회사는 서비스와 관련한 이용자의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 SMS 등을 통하여 이용자에게 통지합니다.
3. 회사가 제공하는 서비스로 인하여 이용자에게 손해가 발생한 경우, 해당 손해가 회사의 고의나 중과실에 기해 발생한 경우에 한하여 회사가 책임을 부담합니다.
4. 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 개인정보보호법률 등 서비스의 운영 및 유지와 관련 있는 법규를 준수합니다.

제 2 조 이용자의 의무
1. 이용자는 서비스 이용의 대가로 회사가 정한 서비스 이용요금을 지정된 일자를 준수하여 납입하여야 합니다.
2. 이용자는 다음 각 호의 행위를 하여서는 안됩니다
1) 서비스를 제공받기 위해 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 이용자의 정보를 도용, 부정하게 사용하는 행위
2) 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위
3) 서비스를 통해 구입한 재화 등의 대금, 기타 서비스 이용에 관련하여 이용자가 부담하는 채무를 기일에 지급하지 않는 경우
4) 본 사이트를 통해 구매한 상품을 제 3자에게 판매, 임대 등을 하여 이득을 취득하는 일체의 제공 행위
5) 타인의 명예를 손상시키거나 불이익을 주는 행위, 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
6) 회사의 저작권, 제 3자의 저작권 등 기타 권리를 침해하는 행위
7) 공공질서 및 미풍양속에 위반되는 내용 등을 타인에게 유포하는 행위
8) 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보를 전송하는 행위
9) 서비스에 게시된 정보를 변경하는 행위
10) 관련 법령에 의하여 전송 또는 게시가 금지되는 정보 (컴퓨터 프로그램 포함)의 전송 또는 게시 행위
11) 회사의 직원이나 서비스 운영자를 가장하거나 사칭하여 SMS, MMS 발송 또는 연락을 취하는 행위
12) 스토킹(Stalking) 등 다른 이용자를 괴롭히는 행위 등 기타 불법적이거나 부당한 행위
13) 기타 불법적이거나 부당한 행위

제 3 조 계약해지
1. 회원이 서비스 이용계약을 해지하고자 할 경우에는 사이트 상에서 또는 회사가 정한 별도의 이용방법으로 회사에 해지 신청을 하여야 합니다.
2. 회사는 회원의 해지 신청 시 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다. 단, 별도의 채권, 채무 관계가 있을 경우에는 그러하지 아니합니다.
3. 회원이 계약을 해지하는 경우, 회원의 아이디는 삭제되며 재사용 및 복구가 불가합니다.
4. 회원이 계약을 해지하는 경우, 회원의 주문 정보 등 회원이 생성한 데이터 일체는 별도로 5년간 분리 보관된 후 삭제됩니다.
5. 회사는 회원이 본 약관 상 의무를 위반한 경우 계약을 해지할 수 있습니다.

[제 5 장 개인정보의 보호]
제 1 조 이용자의 개인정보보호
회사는 관련법령이 정하는 바에 따라서 이용자의 개인정보를 보호하기 위하여 노력합니다. 이용자의 개인정보보호에 관해서는 관련법령 및 회사가 정하는 『개인정보 처리방침』에 정한 바에 의합니다.

제 2 조 개인정보의 보호 및 처리 위탁
1. 회사는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률/시행령/시행규칙” 등 관계 법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호 및 사용에 대해서는 관계 법령 및 회사의 개인정보처리방침이 적용됩니다. 단, 회사의 공식 사이트 이외의 웹에서 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 또한 회사는 회원의 귀책사유로 인해 유출된 정보에 대해서 일체의 책임을 지지 않습니다.
2. 회사는 수집된 개인정보의 처리 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 다음 각 호의 경우에는 업무의 일부 또는 전부를 회사가 선정한 제3자에에 위탁할 수 있습니다. 회원의 개인정보 처리 등의 위탁에 대해서는 회사의 개인정보처리방침이 적용됩니다.
1) 서비스 제공을 위해 이용자가 가입한 연계 서비스 제공자에게 제공하는 경우
2) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
3) 이동통신 부가서비스 가입 또는 데이터 충전을 위해 전송자가 입력한 수신자의 휴대폰 번호를 이동통신사에 제공하는 경우
4) 기타 법률의 규정에 따라 제공하는 경우
3. 회원의 개인정보보호에 관해서는 관련법령 및 회사가 정하는 『개인정보 처리방침』에 정한 바에 의합니다.

[제 6 장 손해배상 등]
제 1 조 손해 배상
1. 회사가 고의 또는 과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.
2. 회사는 그 손해가 천재지변 등 불가항력이거나 회원의 고의 또는 과실로 인하여 발생된 때에는 손해배상을 하지 않습니다.
3. 회원이 본 약관의 규정을 위반함으로 인하여 발생하는 모든 문제는 회원에게 책임이 있습니다. 이로 인해 발생한 회사, 수신자 또는 제 3자의 손해에 대하여 회원은 손해액 전액을 배상하여야 합니다.
4. 손해 배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 이메일, 전화 등으로 신청하여야 합니다.

제 2 조 면책사항
1. 회사는 국가의 비상사태, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임을 지지 않습니다.
2. 회사는 회원의 귀책사유로 인한 서비스의 이용 장애에 대하여 책임을 지지 않습니다.
3. 회사는 불법 또는 부정한 방법으로 서비스를 이용하여 이용자 상호간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
1) 타인의 휴대전화(분실 휴대전화 포함)를 이용하거나 번호를 도용하여 서비스를 이용한 경우
2) 회원이 불법 할인의 목적으로 구매하여 제 3자 또는 수신자에게 전송(판매)하는 경우
4. 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대한 책임을 지지 않으며, 그밖에 서비스를 통해 얻은 자료로 인한 손해 등에 대하여 책임을 지지 않습니다.
5. 회사는 회원이 생성한 데이터 내용을 확인할 의무가 없으며 데이터 내용으로 인하여 발생하는 불법 사항이나 개인간의 분쟁 등에 대하여 책임을 지지 않습니다.

제 3 조 관할법원
1. 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.
2. 본 조 제 1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
3. 서비스 이용 및 본 약관과 관련하여 발생하는 분쟁에 대해서는 대한민국의 법률을 적용합니다.

부칙
[시행일] 본 이용약관은 2024년 06월 05일부터 적용됩니다.`;

const PERSONAL_POLICY = `
*개인정보처리방침
1. 개인정보 수집
주식회사 젭스(이하 “회사”)는 개인정보의 수집 시 관련 법규에 따라 그 수집 항목 및 이용 목적을 사전에 고지합니다.
회사는 이용자가 제공한 개인정보를 이용자의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다.
회사가 수집하는 이용자의 개인정보 수집 및 이용목적, 보유기간은 다음과 같습니다.
-회원가입 및 이용자별 주문정보 식별
-법인에 대한 정보(사업자 등록번호, 회사(단체)명, 대표자명, 담당자명, 전화번호, 주소, 계좌번호 등)
-개인에 대한 정보(담당자 이름, 수신자 정보, 생년월일, 주소, 전화번호, 이메일, 비밀번호, 기념일 등)
회원탈퇴 후 5일 이내 또는 법령에 따른 보존기간
회사는 개인정보를 수집함에 있어 서비스 제공에 필요한 최소한의 개인정보를 필수동의 항목으로, 그 외 개인정보는 선택 동의 항목으로 구분하고 이에 대해 개별적으로 동의할 수 있는 절차를 마련합니다.

회사는 회원의 개인정보를 서비스 가입기간 또는 고충 처리기간 동안 이용하며, 요금 미납 등의 고충 처리를 위해 가입 해지 후 일정 기간 동안 개인정보를 보유합니다. 자세한 내용에 대해서는 개인정보 처리방침을 참고하시기 바랍니다.

회원의 유료 서비스를 이용하는 과정에서 결제등을 위하여 은행계좌정보, 신용카드 정보, 통신사 정보, 세금계산서 발행을 위한 고유번호 등의 정보들이 수집될 수 있습니다.

서비스 이용 기록, 접속로그, 접속IP정보, 제재 및 이용정지 기록, 장애 관련 정보 등이 생성되어 수집 및 보관될 수 있습니다. 이러한 개인정보는 서비스 프로그램의 실행 또는 사용 과정에서 수집되거나 협력회사로부터의 제공 및 생성정보 수집 툴을 통해 수집됩니다.

2. 개인정보 이용
회원관리, 서비스 제공 및 개선, 신규 서비스 개발 등을 위해 이용합니다.

회원가입 시 또는 서비스 이용 과정에서 홈페이지 또는 개별 애플리케이션이나 프로그램 등을 통해 아래와 같이 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.
-회원 식별 및 가입의사 확인, 본인 확인, 부정 이용 방지
-신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달
-서비스 이용요금 정산
-서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재
서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 맞춤형 서비스 제공, 서비스 개선에 활용회사는 이용자가 제공한 개인정보를 당해 이용자의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다.

3. 개인정보 파기
개인정보는 수집 및 이용목적이 달성되거나 이용자의 탈퇴 요청이 있는 경우 지체없이 파기하며, 절차 및 방법은 다음과 같습니다.

전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.

이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 아래와 같습니다.

전자상거래 등에서 소비자 보호에 관한 법률
-계약 또는 청약철회 등에 관한 기록: 5년 보관
-대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
-소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
-표시/광고에 관한 기록: 6개월

국세기본법
-세법이 규정하는 모든 거래에 관한 장부 및 증빙서류: 5년

전자금융거래법
-전자금융에 관한 기록: 5년 보관
통신비밀보호법
-로그인 기록: 3개월

4. 개인정보처리 위탁
회사는 귀하가 선택한 특정 서비스의 원활한 제공을 위해 아래와 같이 귀하의 개인 정보를 제공하고 있으며, 서비스 이행을 위한 개인정보 처리 업무를 외부 전문 업체에 위탁하여 운영하고 있습니다.
가. 한국정보통신㈜ : 결제처리
나. 인포뱅크㈜ : 대량 문자서비스 전송 시스템 운영
다. 롯데글로벌로지스티스㈜ : 제품 배송 등의 물류업무
라. 비즈톡㈜ : 카카오톡 알림톡 전송 시스템 운영

회사는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.

위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

5. 개인정보의 안전성 확보 조치
회사는 이용자의 개인정보를 가장 소중한 가치로 여기고 개인정보를 취급함에 있어서 다음과 같은 노력을 다하고 있습니다.
이용자의 개인정보를 암호화하고 있습니다.
회사는 이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중요정보는 암호화하여 보관하고 있습니다.
해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.
회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다.
해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다.
또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고 있습니다.
소중한 개인정보에 접근할 수 있는 사람을 최소화하고 있습니다.
회사는 개인정보를 취급하는 직원을 최소화합니다.
또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
임직원에게 이용자의 개인정보 보호에 대해 정기적인 교육을 실시하고 있습니다.
개인정보를 취급하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육을 실시하고 있습니다.
※ 고객님께서는 개인정보 수집 및 이용 동의를 거부하실 수 있습니다. 다만, 동의 거부 시 회사의 서비스 이용이 불가능합니다.

6. 개인정보 보호책임자
가. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자
성명 : 방세환
연락처 : 1800-0588, cs@jebs.kr

정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

7. 개인정보 처리방침 변경
이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.

본 방침은 2024년 6월 5일부터 시행됩니다.
`;

const PERSONAL_COLLECTION = `
*개인정보 수집/이용동의
1. 수집 및 이용 항목
아이디, 비밀번호, 이름, 생년월일, 휴대전화번호, 이메일 주소, 성별, 만 14세미만 법정대리인 정보(이름, 휴대폰번호), 카카오 이메일과 회원 번호(서비스 로그인 연동 회원에 한함), 교인정보, 결제정보(신용카드정보, 은행계좌정보, 기타 결제에 필요한 정보), 서비스 이용(정지)기록, 접속로그, IP정보, 쿠키

2. 수집 및 이용 목적
회원 가입 및 관리, 재화 및 서비스 제공, 대금 수취(유료 서비스 결제 및 제공), 웹페이지를 사용한 서비스 제공, 민원 사무 처리 및 공지사항 전달

3. 보유 및 이용 기간
회원탈퇴 후 7일 까지. 단, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 
- 관계 법령 위반에 따른 수사, 조사 등이 진행중인 경우에는 해당 수사, 조사 종료시까지
- 서비스 이용으로 인한 채권, 채무 관계 잔존시에는 해당 채권, 채무 관계 정산시까지
`;

const REFUND = `
*환불규정
1. 관계법령상 환불 정책
1) 회사의 본 약관상 환불규정 등은 "약관의 규제에 관한 법률", "콘텐츠산업진흥법","전자상거래 등에서의 소비자보호에 관한 법률", "방문판매 등에 관한 법률"등을 바탕으로 작성되었습니다.
2) 회원은 다음의 경우 위의 환불을 할 수 없습니다.
- 회원에게 책임 있는 사유 또는 천재지변 사유로 "제품 및 서비스 등"이 멸실 또는 훼손된 경우
- 회원의 사용 또는 일부 소비로 "제품 및 서비스 등"의 가치가 현저히 감소한 경우
- 시간의 경과에 의하여 재판매가 곤란할 정도로 "제품 및 서비스 등"의 가치가 현저히 감소한 경우
- 복제가 가능한 "제품 및 서비스, 교재 등"의 포장을 훼손한 경우
- 회원의 결제 및 이용조건에 따른 환불 가능 금액이 0원 이하인 경우

2. 회사의 환불 규정
[온라인 수강]
1. 수강 시작 전 온라인 교육 비용 환불
1) 결제 후 3주 이내 전액 환불 가능합니다.
2) 결제 후 3주 이후 교육비 환불 불가합니다. (단, 교재 및 교부재 등 미개봉한 상품은 환불 가능)
3) 결제 후 60일 이후 전액 환불 불가합니다.

2. 수강 시작 후 온라인 교육 비용 환불
1) 7일 이내, 1강 이하 수강 시 전액 환불 가능합니다. (단, 결제 후 3주 이내)
2) 7일 이내, 2강 이상 수강 시 교육비 환불 불가합니다. (단, 교재 및 교부재 등 미개봉한 상품은 환불 가능)

3. 수료 후 온라인 서비스 이용 환불
1) 수료 후 환불 요청 시, 결제 후 60일 이내일 경우 온라인 서비스 비용 환불 가능합니다.
2) 환불 시 수료 취소되며, 탈퇴 후 3년간 해당 코스 재가입 불가합니다.

[교재 및 교부재]
1. 교재 및 교부재 환불/반품/교환
1) 교재 및 교부재는 미개봉 시 3주 이내에 환불 및 교환 가능합니다.
2) 교육 환불 시, 받은 미개봉 교재 및 교부재 등은 모두 정상 반품해야 하며, 개봉하여 재판매가 어려울 경우 제외 후 환불됩니다.
3) 교재가 불량(파본, 오인쇄)일 경우 60일 이내 교환 가능합니다.
4) 환불, 반품 및 교환 시 왕복 배송비는 귀책사유가 있는 쪽에서 배송비를 부담합니다.
5) 이벤트 기간에 할인된 금액으로 교재 및 교부재 구매 후 부분 환불 시 정가에서 차감되어 환불 및 교환됩니다.
`;

const PROMOTION = `
*프로모션 정보 수신 동의
프로모션 정보 수신에 동의하시면 주식회사 젭스가 제공하는 신제품 및 신규서비스, 할인 및 쿠폰, 이벤트 관련 각종 정보를 SMS, 이메일, 앱푸시, 전화 등의 방법으로 받으실 수 있으며, 결제안내 등 의무적으로 안내되어야 하는 정보는 수신동의 여부와 무관하게 전송됩니다.
수신동의 이후에라도 의사에 따라 동의를 철회할 수 있습니다. 수신동의 철회 시, 마이페이지에서 직접 상태 변경 또는 고객센터로 연락주시기 바랍니다.

[고객상담센터]
- 대표번호 : 1800-0588
- 이메일 : cs@jebs.kr
`

const LEARNING_HOMESCHOOL = `
JEMM 회원 가입 약관 

(주)젭스(이하 ‘본사’라 함)와 본사로부터 젭스영어성경홈스쿨 교육 콘텐츠 서비스를 제공받기 위하여 등록한 회원 간의 상호 권리와 의무를 규정하기 위하여 다음과 같이 약정합니다. 
 
제 1 조【 회원의 자격 】 

1. 회원은 본인이어야 하며 정식 교단에 등록된 개신교인이어야 합니다.  
2. 제 1조 1항의 내용확인을 위해 교육 수료 전까지 사진과 교인확인서를 본사에 제출하여야 합니다.  
3. 회원은 운영함에 있어 회원이 속한 각 지역 관할(시도교육청, 세무서)의 방침을 따라야 합니다. 

제 2 조【 회원 가입 절차 및 등록비 규정 】 

1. 본사의 회원이 되기 위해서는 코스에 등록하고, 이에 필요한 등록비를 완납 후, 해당 코스의 교육을 수료하여야 합니다. 
2. 등록비는 교육비와 교재비 및 온라인서비스 1년 사용료로 구성되어 있습니다. 

제 3 조【회원 자격 유지】 

1. 회원 자격은 최초 교육 수료 시점으로부터 1년간 지속됩니다. 
2. 회원 자격을 유지하게 위해서는 제5조 회원의 의무를 성실히 지켜야 합니다. 
3. 회원 자격이 정지된 경우 3년 이내에 자격회복이 가능하며, 이후에는 신규로 재가입 하여야 합니다.  
4. 환불 등의 회원의 이유로 회원자격이 종료되었을 경우, 이후 3년간 재가입이 불가합니다. 

제 4 조【 회원의 권리 및 본사의 의무 】 

1. 회원은 수료한 콘텐츠의 교재 및 온라인서비스를 이용할 수 있습니다.  
2. 회원은 본사에서 제공하는 포인트 적립 서비스를 받을 수 있습니다. 
3. 회원은 젭스영어성경홈스쿨 로고와 JEBS(젭스, 정철영어성경학교) 명칭을 규정 안에서 홍보/판촉물에 사용할 수 있습니다. 
4. 본사는 지속적인 콘텐츠 개발과 교육을 제공합니다.  
5. 본사는 교육 인증 받은 회원의 멤버십 보호와 확인을 위해 필요한 경우, 회원의 Teacher’s license인증번호와 사진 및 운영장소에 대한 정보를 홈페이지에 게시합니다. 
6. 본사는 명칭 및 로고를 변경할 경우, 회원에게 공지하도록 합니다.  

제 5 조【 회원의 의무 】 

1. 회원은 가입정보를 사실대로 정확하게 기재하여야 합니다. 
2. 회원은 변동사항(출석교회 및 교단, 주소, 연락처 및 교육청 등록, 운영방식, 장소 등 기타 운영 변동사항)이 발생할 경우 5일 내에 지체 없이 본사에 통보하여야 합니다 
3. 회원은 본사에서 수료한 교육내용을 따라 성실히 가르쳐야 합니다. 
4. 회원자격은 1년 단위로 연장하며, 연장 시 VIP 온라인서비스 비용을 납부해야 합니다. 
5. 회원은 기존의 정철 브랜드 사용자들 과의 혼선을 없이 하기 위해 [젭스영어성경홈스쿨] 공식명칭을 사용하고, ‘정철’ 단독문구는 사용 불가하며 ‘정철영어성경학교’로 표기해야 합니다.  
6. 회원은 구매한 콘텐츠를 외부로 유출하거나 재판매할 수 없습니다. 
7. 저작권법에 의거, 콘텐츠 및 교재의 무단 복제, 복사 및 2차 저작물 제작, 배포 등은 불가합니다. 
8. 회원은 본사가 규정하는 교육의 형태에서만 본 서비스의 운영이 가능합니다. 

제 6 조【 회원의 규약 위반 시 제재사항 】 

1. 아래와 같은 경우 회원 자격을 제한, 정지 또는 박탈할 수 있습니다.  
  1) 제1조의 회원 자격에 부합하지 않음이 확인되는 경우 
  2) 제3조 회원자격 유지 및 제5조 회원의 의무를 성실히 이행하지 않을 경우 
  3) 산업안전보건법 제 41조 및 젭스이용약관 제 1장 총칙의 제 6조의 의무를 성실히 이행하지 않을 경우  

제 7 조【 포인트 적립/사용/소멸 】 

1. 포인트는 교재 구매 및 다양한 이벤트를 통하여 적립 및 사용할 수 있습니다. 
2. 적립률은 회원 등급에 따라 적용됩니다. 
3. 회원 자격 종료 시 적립된 포인트는 자동 소멸됩니다.  
4. 포인트는 타인에게 양도할 수 없습니다. 
5. 적립된 포인트는 3년간 유효하며, 3년이 경과된 포인트는 순차적으로 소멸됩니다. 
6. 포인트로 구매한 건은 적립되지 않으며, 교환 및 환불이 불가합니다. 

제 8 조【 환불, 반품 및 교환 】 

1. 교육 및 온라인 서비스 비용 환불 
  1) 온라인 교육 개강 전까지 100% 취소 및 환불이 가능합니다. 
  2) 온라인 교육 개강 후에는 아래의 환불 규정이 적용되며, 등록 시 받은 교재 및 물품은 모두 정상 반품해야 합니다. 

  교육 개강 후 ~ 교육 수료 전 
  등록비 70% 환불 

  교육 수료 후 6개월 이내 
  등록비 30% 환불 

  교육 수료 후 6개월 이후 
  환불 불가 

  3) 교육은 정해진 기간 내에 (개강 후 6개월) 이수해야 하며, 이수하지 않을 경우 환불은 불가합니다.

2. 교재 환불, 반품 및 교환 
  1) 교재가 불량(파본, 오인쇄)일 경우 결제 후 3주 이내에 가능합니다.  
  2) 왕복배송비 및 기타 필요한 비용은 귀책사유가 있는 쪽에서 부담합니다. 
  3) 할인된 금액으로 구매 시 환불이 불가합니다. 

제 9 조【 신의·성실의 자세 】 

본사와 회원은 서로 신의와 성실의 정신으로 본 약관의 이행을 위하여 노력해야 합니다. 

제 10 조【 분쟁의 해결·조정 】 

이 약관에 명시되지 아니한 사항 또는 약관 해석상의 차이로 다툼이 있을 경우에는 본사와 회원이 협의하여 해결하되 합의가 이루어지지 않을 경우에는 관계 법령에 따릅니다. 분쟁의 관할법원은 본사 사업자의 주소지를 관할하는 법원으로 합니다. 

제11 조【 계약의 발효 】 

이 약관은 제2조 회원가입절차에 따라 회원 가입에 필요한 제반 절차가 완료된 시점부터 발효됩니다. 본사의 사정이나 관계기관의 권고에 따라 약관의 전부 또는 일부가 변경될 수 있으며, 이 경우 본사는 인터넷 홈페이지를 통하여 회원에게 공지하도록 합니다. 가입 회원은 상기 내용에 대해 충분히 정보를 제공받고, 내용을 인지했음을 확인합니다.
`;

const LEARNING_TEACHING = `
수강 약관

제 1 조【 회원의 자격 】
	1. 수강하는 회원은 본인이어야 하며 정식 교단에 등록된 개신교인이어야 합니다. 
  2. 제 1조 1항의 내용확인을 위해 교사대학 학습자의 경우 교육 수료 전까지 사진과 교인확인서를 본사에 제출하여야 합니다. 

제 2 조【 회원의 의무 】
	1. 회원은 가입정보를 사실대로 정확하게 기재하여야 합니다. 
  2. 회원은 구매한 콘텐츠를 외부로 유출하거나 재판매할 수 없습니다.
  3. 저작권법에 의거, 콘텐츠 및 교재의 무단 복제, 복사 및 2차 저작물 제작, 배포 등은 불가합니다.

제 3 조【 회원의 규약 위반 시 제재사항 】
  1. 아래와 같은 경우 회원 자격을 제한, 정지 또는 박탈할 수 있습니다. 
    1) 제1조의 회원 자격에 부합하지 않음이 확인되는 경우
    2) 산업안전보건법 제 41조 및 젭스이용약관 제 1장 총칙의 제 6조의 의무를 성실히 이행하지 않을 경우 
       
제 4 조【 포인트 적립/사용/소멸 】
  1. 포인트는 교재 구매 및 다양한 이벤트를 통하여 적립 및 사용할 수 있습니다.
	2. 적립률은 회원 등급에 따라 적용됩니다.
	3. 회원 자격 종료 시 적립된 포인트는 자동 소멸됩니다. 
  4. 포인트는 타인에게 양도할 수 없습니다.
  5. 적립된 포인트는 3년간 유효하며, 3년이 경과된 포인트는 순차적으로 소멸됩니다.
  6. 포인트로 구매한 건은 적립되지 않으며, 교환 및 환불이 불가합니다.

제 5 조【 환불, 반품 및 교환 】
	1. 수강 시작 전 온라인 교육 비용 환불
    1) 결제 후 3주 이내 전액 환불 가능합니다. 
    2) 결제 후 3주 이후 교육비 환불 불가합니다. (단, 교재 및 교부재 등 미개봉한 상품은 환불 가능) 
    3) 결제 후 60일 이후 전액 환불 불가합니다. 
 
  2. 수강 시작 후 온라인 교육 비용 환불 
    1) 7일 이내, 1강 이하 수강 시 전액 환불 가능합니다. (단, 결제 후 3주 이내) 
    2) 7일 이내, 2강 이상 수강 시 교육비 환불 불가합니다. (단, 교재 및 교부재 등 미개봉한 상품은 환불 가능) 
 
  3. 수료 후 온라인 서비스 이용 환불 
    1) 수료 후 환불 요청 시, 결제 후 60일 이내일 경우 온라인 서비스 비용 환불 가능합니다. 
    2) 환불 시 수료 취소되며, 탈퇴 후 3년간 해당 코스 재가입 불가합니다. 
 
  4. 교재 환불, 반품 및 교환
    1) 교재가 불량(파본, 오인쇄)일 경우 결제 후 3주 이내에 가능합니다. 
    2) 왕복배송비 및 기타 필요한 비용은 귀책사유가 있는 쪽에서 부담합니다.
    3) 할인된 금액으로 구매 시 환불이 불가합니다.

회원은 상기 내용에 대해 충분히 정보를 제공받고, 내용을 인지했음을 확인합니다.
`