import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BoostUpDetail from './dashboard/boostup/boostupdetail';
import LmsLayout from '../../components/lmslayout';
import LmsDashboard from './lmsdashboard';
import CourseMgmt from './classmgmt/coursemgmt/CourseMgmt';
import TakeClass from './classmgmt/takeclass/takeclass';
import TodoMgmt from './classmgmt/todomgmt/todomgmt';
import ReferenceRoom from './classmgmt/referenceroom/referenceroom';
import NoticeDetail from './noticemgmt/noticedetail';
import NoticeEditor from './noticemgmt/noticeeditor';
import Signupmgmt from './studentmgmt/signupmgmt';
import LmsMigrationPage from './migration/LmsMigrationPage';
import StudentDetail from './studentmgmt/StudentDetail';
import ClassSetting from './classsetting/ClassSetting';
import ChannelService from '../../api/ChannelService';
import RecitationHistory from './recitation/RecitationHistory';
import RecitationHistoryDetail from './recitation/RecitationHistoryDetail';
import RecitationDashbaord from './recitation/RecitationDashbaord';

const RouteLms = () => {
  useEffect(() => {
    ChannelService.hideChannelButton();
    return () => {
      ChannelService.showChannelButton();
    };
  }, []);

  return (
    <Routes>
      {/* base "/lms/:classId" */}

      {/* 부스트업 상세페이지 */}
      <Route path="/boostup/detail/:boostupId" element={<BoostUpDetail />} />

      {/* LMS */}
      <Route element={<LmsLayout />}>
        {/* 대쉬보드 */}
        <Route index path="/dashboard/" element={<LmsDashboard />} />

        {/* 코스관리 */}
        <Route path="/course-management" element={<CourseMgmt />} />

        {/* 수업하기 */}
        <Route path="/take-class" element={<TakeClass />} />

        {/* 할일 관리 */}
        <Route path="/todo-management/:subType" element={<TodoMgmt />} />

        {/* 학생별 인증제 내역 대쉬보드 */}
        <Route path="/recitation/dashboard" element={<RecitationDashbaord />} />

        {/* 학생별 인증제 내역 조회 */}
        <Route path="/recitation/list" element={<RecitationHistory />} />

        {/* 학생별 인증제 내역 조회 상세 */}
        <Route
          path="/recitation/list/:studentId"
          element={<RecitationHistoryDetail />}
        />

        {/* 자료실 */}
        <Route path="/reference-room" element={<ReferenceRoom />} />

        {/* 공지 상세 */}
        <Route path="/notice/detail/:detailId" element={<NoticeDetail />} />

        {/* 게시물 작성 */}
        <Route path="/notice/editor" element={<NoticeEditor />} />

        {/* 학생 관리 */}
        <Route path="/signup-management" element={<Signupmgmt />} />

        {/* 학생 상세 */}
        <Route
          path="/signup-management/:studentId"
          element={<StudentDetail />}
        />

        {/* 학생 가져오기 [ 구젭스 ] */}
        <Route path="/migration" element={<LmsMigrationPage />} />

        {/* 클래스 설정 */}
        <Route path="/class-setting" element={<ClassSetting />} />

        {/* 비정상 접근시 대쉬보드로 */}
        {/* <Route path="/*" element={<InvalidRouteRedirect />} /> */}
      </Route>
    </Routes>
  );
};

const InvalidRouteRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 대쉬보드로 replace 리다이렉트
    navigate('./dashboard', { replace: true });
  }, [navigate]);
  return <></>;
};

export default RouteLms;
