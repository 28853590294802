import React from "react";
import ResponseTypo from "../../../components/ResponseTypo";
import { IconNavLink } from "../../../components/IconNavLink";
import { COURSE_SUMMERY_DATA } from "../../../data/statics-datas";

export const StoryCourse = () => {
  return (
    <section className="p-5 lg:py-14">
      <div className="lg:m-auto lg:max-w-[1024px]">
        <ResponseTypo variant_lg="h3" variant_sm="h5" className="color-red">
          바이블 스토리 암송은 물론
          <br />
          회화, A.D 문법까지 아우르는 <br className="lg:hidden" />전 연령
          커리큘럼을 제공합니다.
        </ResponseTypo>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-5 lg:mt-9">
          {COURSE_SUMMERY_DATA.map((item, index) => (
            <IconNavLink
              key={index}
              to={item.to}
              src={item.src}
              text1={item.text1}
              text2={item.text2}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
