import React from 'react';
import jsbook01 from './img/section8b_js_book_01.png';
import jsbook02 from './img/section8b_js_book_02.png';
import jsbook03 from './img/section8b_js_book_03.png';
import jsbook04 from './img/jsbook01.png';
import jsbook05 from './img/jsbook02.png';

import { motion } from 'framer-motion';
import {
  NeumophismAccordion,
  CircleRightArrowIcon,
  CircleCheckIcon,
  ListItem,
} from './WmwComp';

const WmwSection9 = () => {
  return (
    <div className="bg-[#efefef] flex flex-col justify-center items-center p-3 pb-10 md:p-12 gap-5 md:gap-10 ">
      <div className="w-full flex flex-col justify-center items-center gap-5 md:gap-12">
        <div className="flex justify-center rounded-2xl bg-[#5344FF] p-3 w-full">
          <div className="flex flex-col justify-center items-center gap-5 md:gap-10 max-w-[520px]">
            {/* 타이틀 */}
            <div className="rounded-full border border-white w-fit px-5 py-2 text-xl md:text-3xl font-bold text-[#ffda19] mt-10">
              Jesus Story
            </div>

            <div className="text-white text-xl md:text-3xl font-bold font-['Pretendard']">
              영어로 익히는 <span className="text-[#ffda19]">복음의 핵심!</span>
            </div>

            <div className="text-white text-sm md:text-lg font-normal font-['Pretendard'] text-center">
              Jesus Story는
              <br />
              <span className="text-[#ffda19] font-bold">
                4복음서를 기반으로 한 예수님의 삶과 가르침, 기적
              </span>
              등을
              <br /> 영어로 배우며 아이들이 인격적인 예수님을 만나고
              <br /> 좋은 예배자와 세계 복음화의 주역으로 자라나게 합니다.
            </div>

            <div className="grid grid-cols-2 gap-3 md:gap-5 mb-5 md:mb-10">
              {/* 박스 1 */}
              <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
                <img src={jsbook01} alt="jsbook01" />
                <div
                  className="flex-1 flex flex-col justify-center items-center 
                text-center text-xs md:text-lg font-medium font-['Pretendard'] "
                >
                  <div>
                    <span className="text-[#5344fe] font-semibold">
                      성경 이야기
                    </span>
                    를 말씀으로
                  </div>
                  <div>배우고 영어 문장을</div>
                  <div>따라쓰며 암송</div>
                </div>
              </div>

              {/* 박스 2 */}
              <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
                <img src={jsbook02} alt="jsbook02" />
                <div
                  className="flex-1 flex flex-col justify-center items-center 
                text-center text-xs md:text-lg font-medium font-['Pretendard'] "
                >
                  <div>배운 성경 이야기를</div>
                  <div>토대로 구성한</div>
                  <span className="text-[#5344fe] font-semibold">
                    묵상 질문과 기도 연습
                  </span>
                </div>
              </div>

              {/* 박스 3 */}
              <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
                <img src={jsbook03} alt="jsbook03" />
                <div
                  className="flex-1 flex flex-col justify-center items-center 
                text-center text-xs md:text-lg font-medium font-['Pretendard'] "
                >
                  <div>요일별 숙제와</div>
                  <div>에피소드 별 그림</div>
                  <div>
                    구성으로
                    <span className="text-[#5344fe] font-semibold">
                      {' '}
                      배운 이야기를
                    </span>
                  </div>
                  <div className="text-[#5344fe] font-semibold">
                    한번 더 연습
                  </div>
                </div>
              </div>

              {/* 박스 4 */}
              <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
                <div className="relative aspect-[4/3] w-full">
                  <img
                    src={jsbook04}
                    alt="jsbook05"
                    className="absolute top-0 left-0 w-[60%]"
                  />
                  <img
                    src={jsbook05}
                    alt="jsbook06"
                    className="absolute bottom-0 right-0 w-[60%]"
                    style={{
                      filter: 'drop-shadow(-3px -3px 0 rgba(255, 255, 255))',
                    }}
                  />
                </div>
                <div className="flex-1 flex flex-col justify-center gap-3">
                  <div
                    className="flex-1 flex flex-col justify-center items-center 
                text-center text-xs md:text-lg font-medium font-['Pretendard'] "
                  >
                    <div>
                      <span className="text-[#5344fe] font-semibold">
                        Main Book 4권
                      </span>
                    </div>
                    <div>+ Mini Book 4권</div>
                    <div>+ 보드게임 4개</div>
                  </div>
                  <div className="flex-1 flex items-center text-center text-[9px] md:text-xs font-normal font-['Pretendard'] whitespace-nowrap">
                    체계적으로 구성된 4권의 교재 구성과
                    <br />
                    추가 학습을 위한 미니북 구성,
                    <br />
                    그리고 재미있게 활용할 수 있는
                    <br />
                    보드게임까지 제공!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:gap-10 gap-5 max-w-[520px] w-full">
          {/* 교사용 매뉴얼 */}
          <section>
            <NeumophismAccordion
              title={
                <div className="flex-1 text-base md:text-xl font-bold">
                  교사용 매뉴얼
                </div>
              }
            >
              <JsTeacherManual />
            </NeumophismAccordion>
          </section>

          {/* 교재 상세히 보기 */}
          <section>
            <motion.div
              className="flex gap-2 items-center justify-center p-3 bg-[#5344ff] rounded-2xl shadow-md text-center text-white font-bold text-xl cursor-pointer"
              whileHover={{ opacity: 0.8 }}
              onClick={() =>
                window.open(
                  'https://jebs2.kr/teach/media/PPTX/book/js/',
                  '_blank'
                )
              }
            >
              <div className="flex flex-col items-center">
                <span className="text-[#ffda18]">Jesus Story</span>
                <div>교재 상세히 보기</div>
              </div>
              <CircleRightArrowIcon color="white" />
            </motion.div>
          </section>
        </div>
      </div>
    </div>
  );
};

const JsTeacherManual = () => {
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#5344ff] rounded-lg p-3 md:p-5 gap-5
     text-white text-center font-normal font-['Pretendard'] text-[13px] md:text-lg"
    >
      <div
        className="rounded-full border border-[#ffda18] w-fit px-5 py-2 
        text-xl md:text-2xl text-[#ffda18] font-bold mt-10"
      >
        Jesus Story
      </div>

      <div className="text-xl md:text-2xl font-bold">
        연간 학사 일정 & 수업 절차
      </div>

      <div>
        어떻게 가르쳐야 할까? 걱정하지 마세요.
        <br />
        선생님들을 위한 상세한 매뉴얼을 제공해 드립니다.
      </div>

      <div>
        Jesus Story 콘텐츠 안내, 수업 절차 안내,
        <br />
        수업 준비 및 운영에 필요한 자료가
        <br /> 자세히 정리되어 있습니다.
      </div>

      <div className="text-[#ffda18] font-bold">
        교사용 매뉴얼만 있으면 누구든 쉽게
        <br />
        영어로 성경을 가르칠 수 있습니다.
      </div>

      <div className="flex flex-col md:w-full">
        <div className="flex flex-col items-center justify-center gap-5 rounded-t-xl bg-white p-3 md:p-5 text-black md:w-full">
          <div className="flex flex-col gap-2 w-full justify-start">
            <div className="flex items-center gap-2 font-bold">
              <CircleCheckIcon
                width="20"
                circleColor="#5344ff"
                checkColor="#5344ff"
              />
              <div className="text-sm md:text-lg text-left">
                <span className="text-[#5344ff]">Jesus Story는 Ⅰ, Ⅱ 2개</span>의
                과정으로 진행
              </div>
            </div>

            <div className="flex items-start gap-2 font-bold">
              <div className="mt-1">
                <CircleCheckIcon
                  width="20"
                  circleColor="#5344ff"
                  checkColor="#5344ff"
                />
              </div>
              <div className="text-sm md:text-lg text-left">
                <div>
                  각각{' '}
                  <span className="text-[#5344ff]">
                    주 1회 60분씩, 47주 완성, 총 2년 과정{' '}
                  </span>
                  추천
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 font-bold">
              <CircleCheckIcon
                width="20"
                circleColor="#5344ff"
                checkColor="#5344ff"
              />
              <div className="text-sm md:text-lg text-left">
                <span className="text-[#5344ff]">기초+심화에 최적화된</span>{' '}
                절차로 수업을 진행
              </div>
            </div>
          </div>

          <div className="text-left text-[11px] md:text-base">
            <ul className="list-none pl-0">
              <ListItem text="Shouting (힘찬 함성)" />
              <ListItem text="Praise the Lord (신나는 찬양)" />
              <ListItem text="Teacher’s Prayer (교사 기도)" />
              <ListItem text="Vision Statement (비전 외치기)" />
              <ListItem text="The Ten Commandments & The Two Greatest Commandments (십계명 & 가장 큰 두 계명 영어 암송)" />
              <ListItem text="Review (복습)" />
              <ListItem text="Today’s Lesson (본 수업)" />
              <ListItem text="Episode Review (총 복습)" />
              <ListItem text="Think and Talk / Activity (생각하고 나누기 / 신나는 활동)" />
              <ListItem text="Praise the Lord (신나는 찬양)" />
              <ListItem text="Offering (헌금)" />
              <ListItem text="Let’s Pray (다 같이 기도)" />
              <ListItem text="The Lord’s Prayer (주기도문)" />
              <ListItem text="Homework (숙제)" />
              <ListItem text="Today’s Winning Team (오늘 승리한 팀 달란트 지급)" />
              <ListItem text="Shouting & Clean-Up (힘찬 함성과 청소)" />
              <ListItem text="End-Class Meeting (반 별 종례)" />
            </ul>
          </div>
        </div>

        <motion.div
          className="bg-[#FFDA19] rounded-b-xl w-full flex items-center justify-center gap-2 p-5 cursor-pointer"
          whileHover={{ opacity: 0.9 }}
          onClick={() =>
            window.open(
              'https://drive.google.com/file/d/1JmvroL-GA9w7EOi6TCJdWkpJdWhG2eb1/view',
              '_blank'
            )
          }
        >
          <div className="text-sm md:text-lg text-[#875CFF] font-bold ">
            교사용 매뉴얼 교안 샘플 보기
          </div>
          <CircleRightArrowIcon color="#875CFF" />
        </motion.div>
      </div>
    </div>
  );
};

export default WmwSection9;
