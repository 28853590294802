import { ChosenVariant, ChosenVariantDto, EnrollOption, OrderItem } from "../../constants/types";
import { OrderItemDto } from "../../constants/types/product-type";
import { ChosenOptionsDto } from "../../store/features/shopSlice";
import { groupBy } from "./util-func";

export const getOrderItem = ({
    product_id,
    variants,
    options,
    enrolls
  }: {
    product_id: string,
    variants: ChosenVariantDto[],
    options: ChosenOptionsDto[],
    enrolls: EnrollOption[]
  }): OrderItemDto => {
    const chosen_variants = variants.map(({ quantity, variant }) => ({
      quantity,
      variant_id: variant.id,
    }))
    const optionGrouped = groupBy(options, (op) => op.optionProduct.option_product_id)
  
    const chosen_options = Object.keys(optionGrouped).map((prodId) => {
      const list = optionGrouped[prodId]
      return {
        product_id: prodId,
        chosen_variants: list.map((x) => ({
          quantity: x.quantity,
          variant_id: x.chosenVariant.id,
        })),
      }
    })
    const enroll_options = enrolls.map((x) => ({
        enroll_option_id: x.id
    }))
    return {
        chosen_variants,
        chosen_options,
        enroll_options,
        product_id: product_id,
    }
}

export const calcPrice = (item: OrderItem) => {
  let sum = 0
  item.chosen_variants &&
  item.chosen_variants.map(variants => (
    sum += (item.product.current_price + variants.variant.additional_price) * variants.quantity
  ))
  
  item.enroll_options &&
  item.enroll_options.map(enrolls => (
    sum += enrolls.enroll_option.price
  ))

  item.chosen_options.map(options => (
    options.chosen_variants.map(x => (
      sum += (options.product.current_price + x.variant.additional_price) * x.quantity
    ))
  ))

  return sum;
}