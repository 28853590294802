import React from 'react';
import ReactPlayer from 'react-player';
import { motion } from 'framer-motion';
import { openNewWindow } from '../../../../api/utils/util-func';
import {
  QuoteIcon,
  CircleRightArrowIcon,
  CircleDownArrowIcon,
} from '../wmw/WmwComp';
import questionBox from '../img/sec09-questionbox.png';

const videoUrl = 'https://youtu.be/zD4tMb6l3DM?si=3JdcYWqXl0EGnxOe';
const pdfUrl =
  'https://drive.google.com/file/d/1rcSIvVjuaY48gZEFpxKzfvzxb-xvkwgj/view';

const HFHJSection9 = () => {
  return (
    <div className="bg-[#FE7020] p-3 py-10 md:py-20">
      <div className="w-full max-w-[520px] m-auto">
        <div className="flex flex-col gap-20">
          {/* 어떻게 도입할까요? */}
          <section className="flex flex-col gap-10">
            <div className="text-white text-3xl md:text-4xl font-['Pretendard'] text-center">
              <span className="text-[#FFD600] font-bold">HFHJ, </span>
              어떻게 도입할까요?
            </div>

            <div className="text-white text-base md:text-lg font-['Pretendard'] text-center">
              젭스영어주일학교의 Hello, Father! Hello, Jesus! 는
              <br />
              영어로 복음을 전하는 어떤 곳이든
              <br />
              자체 프로그램과 클래스로 활용할 수 있습니다.
            </div>

            <motion.div
              className="flex flex-col gap-8 md:gap-10"
              animate={{ x: 0 }}
            >
              <motion.div
                className="text-base border-1 border-white rounded-lg relative"
                initial={{ y: -20, opacity: 1 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
              >
                <div
                  className="bg-[#FFD600] rounded-lg w-fit px-3 m-auto text-black font-bold text-base md:text-lg shadow
                       absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  Case 1
                </div>
                <div className="bg-white rounded-lg p-5 md:p-6 m-auto text-center text-sm md:text-lg font-medium">
                  주일학교, 영어예배 등 주일예배 프로그램으로 도입
                </div>
              </motion.div>

              <motion.div
                className="text-base border-1 border-white rounded-lg relative"
                initial={{ y: -20, opacity: 1 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <div
                  className="bg-[#FFD600] rounded-lg w-fit px-3 m-auto text-black font-bold text-base md:text-lg shadow
                      absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  Case 2
                </div>
                <div className="bg-white rounded-lg p-5 md:p-6 m-auto text-center text-sm md:text-lg font-medium">
                  토요일 특별 프로그램으로 도입
                </div>
              </motion.div>

              <motion.div
                className="text-base border-1 border-white rounded-lg relative"
                initial={{ y: -20, opacity: 1 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <div
                  className="bg-[#FFD600] rounded-lg w-fit px-3 m-auto text-black font-bold text-base md:text-lg shadow
                     absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  Case 3
                </div>
                <div className="bg-white rounded-lg p-5 md:p-6 m-auto text-center text-sm md:text-lg font-medium">
                  선교지 영어 선교 클래스 프로그램으로 도입
                </div>
              </motion.div>

              <motion.div
                className="text-base border-1 border-white rounded-lg relative"
                initial={{ y: -20, opacity: 1 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <div
                  className="bg-[#FFD600] rounded-lg w-fit px-3 m-auto text-black font-bold text-base md:text-lg shadow
                     absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                  Case 4
                </div>
                <div className="bg-white rounded-lg px-3 py-5 md:p-6 m-auto text-center text-sm md:text-lg font-medium">
                  어린이집, 선교학교 등 교회 부속기관 프로그램으로 도입
                </div>
              </motion.div>
            </motion.div>
          </section>

          <section className="flex flex-col justify-center items-center gap-10 md:gap-10  ">
            <div className="flex flex-col">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  repeatType: 'reverse',
                  repeat: Infinity,
                  duration: 2,
                }}
              >
                <img src={questionBox} className="w-full max-w-20 m-auto" />
              </motion.div>

              <div className="relative p-5">
                <div className="absolute -top-4 -left-8">
                  <QuoteIcon />
                </div>
                <div className="text-white text-3xl md:text-4xl font-['Pretendard'] font-bold text-center">
                  젭스영어주일학교가
                  <br /> 궁금하다면?
                </div>
                <div className="rotate-180 absolute bottom-0 -right-8">
                  <QuoteIcon />
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col gap-5">
              <div>
                <div className="text-white text-base md:text-xl font-['Pretendard'] text-center">
                  젭스영어주일학교가 궁금하신가요?
                </div>
                <div className="flex justify-center items-center gap-2">
                  <CircleDownArrowIcon size={20} color="#FE7020" />
                  <span className="text-base md:text-xl text-[#FFD600] font-bold">
                    이 영상부터 보세요!
                  </span>
                  <CircleDownArrowIcon size={20} color="#FE7020" />
                </div>
              </div>

              <div className="overflow-hidden w-full aspect-video rounded-lg">
                <ReactPlayer
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
            </div>

            <motion.div
              className="flex items-center justify-center gap-2 bg-[#FFDA19] w-full p-3 rounded-lg text-black cursor-pointer"
              whileHover={{ opacity: 0.9, scale: 1.01 }}
              onClick={() => openNewWindow(pdfUrl)}
            >
              <div className="text-black font-bold text-base md:text-lg">
                젭스영어주일학교 [도입제안서]
              </div>
              <CircleRightArrowIcon width={16} color="#000000" />
            </motion.div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HFHJSection9;
