import React, { ReactNode } from 'react';
import TextWinds from '../../../components/common/TextWinds';

interface DashboardCardProps {
  title: string;
  children: ReactNode;
}
const DashboardCard = ({ title, children }: DashboardCardProps) => (
  <div className="shadow-card rounded-lg bg-white p-3 xl:px-8 xl:py-4">
    <div className="flex justify-center border-b border-gray4 pb-4">
      <TextWinds type="title_h3">{title}</TextWinds>
    </div>
    <>{children}</>
  </div>
);

export default DashboardCard;

interface DashboardCardItemProps {
  title: string;
  children: ReactNode;
}
export const DashboardCardItem = ({
  title,
  children,
}: DashboardCardItemProps) => (
  <div className="flex py-3 text-sm">
    <div className="text-gray6 font-semibold w-[120px]">{title}</div>
    <div>{children}</div>
  </div>
);
