import React, { useState } from "react";

interface CounterNumberProps {
    selCnt: number;
    minValue: number;
    maxValue: number;
    increment: (value: number) => void;
    decrement: (value: number) => void;
}

const CounterNumber = ({ selCnt, minValue, maxValue, increment, decrement }: CounterNumberProps) => {

  const handleCountDown = () => {
    const newValue = Math.max(selCnt - 1, minValue);
    // setValue(newValue);
    // onChange(newValue);
    decrement(newValue)
  };

  const handleCountUp = () => {
    const newValue = Math.min(selCnt + 1, maxValue);
    // setValue(newValue);
    // onChange(newValue);
    increment(newValue)
  };

  return (
    <div className="flex items-center justify-start h-[32px]">
      <div className="h-full flex flex-row box-border bg-transparent rounded-0 border-[1px] border-solid border-gray5">
        <button
          data-action="decrement"
          className="flex items-center justify-center w-[32px] h-full shrink-0 text-gray6 bg-gray1 cursor-pointer m-0"
          onClick={handleCountDown}
          disabled={selCnt === minValue}
        >
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <div className="flex w-8 h-full items-center justify-center border-x border-gray5">
          {selCnt}
        </div>
        {/* <input
          type="number"
          className="w-[32px] h-full shrink-0 text-black bg-white cursor-pointer text-center border-x-[1px] text-description border-solid border-gray5"
          name="custom-input-number"
          value={selCnt}
          readOnly
        /> */}
        <button
          data-action="increment"
          className="flex items-center justify-center w-[32px] h-full shrink-0 text-gray6 bg-gray1 cursor-pointer m-0"
          onClick={handleCountUp}
          disabled={selCnt === maxValue}
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
};

export default CounterNumber;
