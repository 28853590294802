import { Typography } from '@material-tailwind/react';
import React from 'react';
import { ICON_ARRAY_LIST } from '../data/statics-datas';
import { useNavigate } from 'react-router-dom';
import useUserHook from '../controllers/useUserHook';
import { useAppDispatch } from '../store/hook';
import ModalAlert from './modal/ModalAlert';
import { openModal } from '../store/features/modalSlice';
import TextWinds from './common/TextWinds';

const IconArrayList = () => {
  const {checkAndPageMove} = useUserHook();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clickHandler = (data: any) => {
    if (data.link === undefined) {
      dispatch(openModal({ body: <ModalAlert message="준비중인 기능입니다." /> }));
      return;
    }

    if(data.type === 'external'){
      window.open(data.link);
      return;
    }

    if(data.auth==="USER"){
      checkAndPageMove(data.link);
    }else{
      navigate(data.link);
    }


  };
  return (
    <div className="grid grid-cols-4 lg:grid-cols-8 gap-[19px] lg:mx-0">
      {ICON_ARRAY_LIST.map((props, key) => (
        <div
          key={key}
          className="flex flex-col gap-0.5 lg:gap-[15px] relative cursor-pointer group"
          onClick={() => {
            clickHandler(props);
          }}
        >
          <div className="w-full m-auto bg-gray-00 rounded-[8px] p-2">
            <img src={process.env.PUBLIC_URL + props.img} alt={props.alt} className=' group-hover:scale-110 transition-transform duration-100 ease-in-out object-cover'/>
          </div>
          <TextWinds type='title_h4' children={props.text} className='text-center !text-xs md:!text-lg' />
          {/* <Typography
            variant="h6"
            className="font-normal text-center [font-family:'Pretendard-Medium',Helvetica] text-gray-10 tex-[18px] tracking-[-0.02px] leading-[24px] whitespace-nowrap"
          >
            {props.text}{' '}
          </Typography> */}
        </div>
      ))}
    </div>
  );
};

export default IconArrayList;
