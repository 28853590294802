import React, { MouseEventHandler, useEffect } from 'react';
import TextWinds from '../common/TextWinds';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import HFbutton from '../common/input/HFbutton';
export interface ModalAlertProps {
  message: string;
}
const ModalAlert = ({ message }: ModalAlertProps) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler);
    return () => {
      document.removeEventListener('keydown', keyboardHandler);
    };
  }, []);

  const keyboardHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div className="w-[335px] p-4 bg-white shadow-card mb-40 rounded-md mountAnimation">
      <div>
        {/* {message} */}
        {message.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
      <div className="flex justify-end mt-2">
        <div className="w-[50px]">
          <HFbutton type="Outlined" onClick={onClose} height={34} isFocus>
            확인
          </HFbutton>
        </div>
      </div>
    </div>
  );
};

export default ModalAlert;
