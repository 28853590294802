import { RootState } from '..';
import { DeliveryProps } from '../../constants/types';
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { AxiosParamType } from '.';
import instance from '../../api/axios/utils.ts/instance';
// import { DELIVERY_DATA } from '../../data/statics-datas';

interface OrderState {
  status: string;
  ordererInfo?: {
    name: string;
    phone: string;
  };
  deleveryInfoId?: number;
  editDeliveryInfo?: DeliveryProps;
  deliveryList: DeliveryProps[];
}
const initialState: OrderState = {
  status: '',
  ordererInfo: undefined,
  deleveryInfoId: 0,
  deliveryList: [],
};

export const orderInfoSlice = createSlice({
  name: 'orderInfo',
  initialState,
  reducers: {
    setOrdererInfo: (
      state,
      action: PayloadAction<{ name: string; phone: string }>
    ) => {
      state.ordererInfo = action.payload;
    },
    setDeliveryInfo: (state, action: PayloadAction<DeliveryProps>) => {
      state.deleveryInfoId = action.payload.id;
    },
    setEditDeliveryInfo: (state, action: PayloadAction<DeliveryProps>) => {
      state.editDeliveryInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrdererInfo.pending, (state) => {
        state.status = 'pending';
        state.ordererInfo = { name: '', phone: '' };
      })
      .addCase(getOrdererInfo.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.ordererInfo = action.payload;
      })
      .addCase(getOrdererInfo.rejected, (state) => {
        state.status = 'rejected';
      })

      .addCase(getDeliveryInfoList.pending, (state) => {
        state.status = 'pending';
        state.deliveryList = [];
      })
      .addCase(getDeliveryInfoList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.deliveryList = action.payload;
      })
      .addCase(getDeliveryInfoList.rejected, (state) => {
        state.status = 'rejected';
      })

      .addCase(getDeliveryInfoDefault.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getDeliveryInfoDefault.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        if(action.payload) {
          state.deleveryInfoId = action.payload.id;
        } else {
          state.deleveryInfoId = initialState.deleveryInfoId
        }
      })
      .addCase(getDeliveryInfoDefault.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getOrdererInfo = createAsyncThunk<
  { name: string; phone: string },
  AxiosParamType
>('orderInfo/getOrdererInfo', async ({ url, params }) => {
  try {
    const url = '/v1/auth/user-authorization';
    const response = await instance.get(url, { params });
    return response.data.data;
  } catch (error) {
    console.error('[axios] getOrdererInfo : ', error);
    return isRejectedWithValue(error);
  }
});

export const getDeliveryInfoList = createAsyncThunk<
  DeliveryProps[],
  AxiosParamType
>('orderInfo/getDeliveryInfoList', async ({ url, params }) => {
  try {
    const url = '/v1/shipping';
    const response = await instance.get(url, { params });
    return response.data.data;
  } catch (error) {
    console.error('[axios] getDeliveryInfoList : ', error);
    return isRejectedWithValue(error);
  }
});

export const getDeliveryInfoDefault = createAsyncThunk<DeliveryProps,AxiosParamType>(
  'orderInfo/getDeliveryInfoDefault', async ({ params }) => {
    try {
      const url = '/v1/shipping/default';
      const response = await instance.get(url, { params });
      return response.data.data;
    } catch (error) {
      console.error('[axios] getDeliveryInfoDefault : ', error);
      return isRejectedWithValue(error);
    }
  }
);

/**
 * 배송지 추가
 * @params DeliveryProps
 */
export const postDeliveryInfo = createAsyncThunk<
  DeliveryProps[],
  AxiosParamType
>('orderInfo/postDeliveryInfo', async ({ url, params }) => {
  try {
    const url = '/v1/shipping';
    const response = await instance.post(url, params);
    return response.data;
  } catch (error) {
    console.error('[axios] postDeliveryInfo : ', error);
    return isRejectedWithValue(error);
  }
});
/**
 * 배송지 수정
 * @params DeliveryProps
 */
export const putDeliveryInfo = createAsyncThunk<
  DeliveryProps[],
  AxiosParamType
>('orderInfo/putDeliveryInfo', async ({ url, params }) => {
  try {
    const url = '/v1/shipping';
    const response = await instance.put(url, params);
    return response.data;
  } catch (error) {
    console.error('[axios] putDeliveryInfo : ', error);
    return isRejectedWithValue(error);
  }
});
/**
 * 배송지 삭제
 * @params id
 */
export const deleteDeliveryInfo = createAsyncThunk(
  'orderInfo/deleteDeliveryInfo',
  async ({ url, params }: AxiosParamType) => {
    try {
      const url = '/v1/shipping';
      const response = await instance.delete(url, { data: params });
      return response.data;
    } catch (error) {
      console.error('[axios] deleteDeliveryInfo : ', error);
      return isRejectedWithValue(error);
    }
  }
);

export const { setOrdererInfo, setDeliveryInfo, setEditDeliveryInfo } =
  orderInfoSlice.actions;
/**
 * 주문자 정보
 */
export const selectOrderInfo = (state: RootState) =>
  state.orderInfo.ordererInfo;
/**
 * 배송지 목록
 */
export const selectDeliveryList = (state: RootState) =>
  state.orderInfo.deliveryList;
/**
 * 선택된 배송지
 */
export const selectDeliveryInfo = (state: RootState) =>
  state.orderInfo.deliveryList?.find((i)=>i.id===state.orderInfo.deleveryInfoId);
/**
 * 배송지 수정 정보
 */
export const selectEditDeliveryInfo = (state: RootState) =>
  state.orderInfo.editDeliveryInfo;
export default orderInfoSlice.reducer;
