import React, { useState } from 'react';
import {
  Tabs,
  TabsBody,
  TabsHeader,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import LoginIdPhone from './LoginIdPhone';
import LoginIdEmail from './LoginIdEmail';

const LoginId = () => {
  const [activeTab, setActiveTab] = useState('phone');

  const TABS_MENU = [
    {
      label: '아이디 찾기',
      value: 'phone',
    },
    // {
    //     label: "이메일로 찾기",
    //     value: "email",
    // },
  ];

  const tabClickHandler = (value: string) => {
    setActiveTab(value);
  };

  return (
    <div className="w-full shadow-none xl:w-[480px] xl:mx-auto xl:mt-[120px] xl:px-8 xl:py-10 xl:shadow-card xl:rounded-lg">
      <Tabs value={activeTab} className="w-full">
        <TabsHeader
          className="rounded-none border-b border-gray6 bg-transparent p-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-purple5 shadow-none rounded-none',
          }}
        >
          {TABS_MENU.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => tabClickHandler(value)}
              className={`pt-[12px] pb-[13px] leading-[22px] ${
                activeTab === value
                  ? 'font-semibold text-purple5'
                  : 'text-gray7'
              }`}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value={activeTab} className="px-5 py-2 xl:px-0">
            {activeTab === 'phone' && <LoginIdPhone />}
            {activeTab === 'email' && <LoginIdEmail />}
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default LoginId;
