import React from 'react';
import { motion } from 'framer-motion';
import {
  CircleCheckIcon,
  CircleRightArrowIcon,
  ListItem,
  NeumophismAccordion,
} from './WmwComp';
import wmwpage01 from './img/section8_wmwpage_1.png';
import wmwpage02 from './img/section8_wmwpage_2.png';
import wmwpage03 from './img/section8_wmwpage_3.png';
import wmwbook from './img/wmwbook.png';

const WmwSection8a = () => {
  return (
    <div className="bg-[#efefef] flex justify-center items-center p-3 py-10 md:p-12 md:pt-20">
      <div className="w-full flex flex-col place-content-center gap-5 md:gap-12">
        {/* 타이틀 */}
        <div className="text-center text-3xl md:text-4xl font-bold font-['Pretendard']">
          <div className="text-[#875CFF]">WMW Series</div>
          <div>교재 구성</div>
        </div>

        {/* 보라박스 컨텐츠 */}
        <div className="flex justify-center rounded-2xl bg-[#875cff] p-3">
          <div className="flex flex-col justify-center items-center gap-5 md:gap-10 max-w-[520px]">
            {/* 타이틀 */}
            <div className="rounded-full border border-white w-fit px-5 py-2 text-xl md:text-3xl font-bold text-white mt-10">
              Who Made The World?
            </div>

            <div className="text-white text-xl md:text-3xl font-bold font-['Pretendard']">
              영어로 익히는{' '}
              <span className="text-[#ffda19]">하나님의 구원 계획!</span>
            </div>

            <div className="text-white text-sm md:text-lg font-normal font-['Pretendard'] text-center">
              Who Made the World?는
              <br />
              하나님의 십자가 사랑과 천지 창조의 놀라움을
              <br />
              쉽고 재미있는 영어로 배우며 아이들의 성경 지식과
              <br />
              영어실력이 쑥쑥 자라납니다.
            </div>

            {/* 그리드 영역 */}
            <div className="flex flex-col gap-5">
              <CardSection01 />
              <div className="text-white text-center text-xs md:text-sm font-normal font-['Pretendard'] mb-5 md:mb-10">
                * 교재와 함께 선생님들을 위한 교사용 매뉴얼 함께 제공
              </div>
            </div>
          </div>
        </div>

        {/* 보라박스 하단 버튼 */}
        <div className="flex flex-col md:gap-10 gap-5 max-w-[520px] w-full m-auto">
          {/* 교사용 매뉴얼 */}
          <section>
            <NeumophismAccordion
              title={
                <div className="flex-1 text-base md:text-xl font-bold">
                  교사용 매뉴얼
                </div>
              }
            >
              <WmwTeacherManual />
            </NeumophismAccordion>
          </section>

          {/* 교재 상세히 보기 */}
          <section>
            <motion.div
              className="flex gap-2 items-center justify-center p-3 bg-[#875cff] rounded-2xl shadow-md cursor-pointer
              text-center text-white font-bold text-base md:text-xl "
              whileHover={{ opacity: 0.8 }}
              onClick={() =>
                window.open(
                  'https://teach.jebs2.kr/media/PPTX/ASPN/wmw/',
                  '_blank'
                )
              }
            >
              <div className="flex flex-col items-center">
                <span className="text-[#ffda18]">Who Made the World?</span>
                <div>교재 상세히 보기</div>
              </div>
              <CircleRightArrowIcon color="white" />
            </motion.div>
          </section>
        </div>
      </div>
    </div>
  );
};

const WmwTeacherManual = () => {
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#875cff] rounded-xl p-3 md:p-5 gap-5
     text-white text-center font-normal font-['Pretendard'] text-[13px] md:text-lg "
    >
      <div
        className="rounded-full border border-[#ffda18] w-fit px-5 py-2 
        text-xl md:text-2xl text-[#ffda18] font-bold mt-10"
      >
        Who Made The World?
      </div>

      <div className="text-xl md:text-2xl font-bold">
        연간 학사 일정 & 수업 절차
      </div>

      <div>
        어떻게 가르쳐야 할까? 걱정하지 마세요.
        <br />
        선생님들을 위한 상세한 매뉴얼을 제공해 드립니다.
      </div>

      <div>
        교사용 매뉴얼에는
        <br />
        Who Made the World? 콘텐츠 안내,
        <br />
        수업 절차 안내, 수업 준비 및 운영에 필요한 자료가
        <br />
        자세히 정리되어 있습니다.
      </div>

      <div className="text-[#ffda18] font-bold">
        교사용 매뉴얼만 있으면 누구든 쉽게
        <br />
        영어로 성경을 가르칠 수 있습니다.
      </div>

      <div className="flex flex-col md:w-full">
        <div className="flex flex-col place-content-center gap-5 rounded-t-lg bg-white p-7 md:w-full text-black ">
          <div className="flex flex-col gap-3">
            <div className="flex gap-2 font-bold">
              <CircleCheckIcon
                width="20"
                circleColor="#875CFF"
                checkColor="#875CFF"
              />
              <div className="text-sm md:text-lg">
                <span className="text-[#875CFF]">주 1회 60분씩, 47주 완성</span>{' '}
                과정 추천
              </div>
            </div>

            <div className="flex gap-2 font-bold">
              <CircleCheckIcon
                width="20"
                circleColor="#875CFF"
                checkColor="#875CFF"
              />
              <div>
                <span className="text-[#875CFF]">기초에 최적화</span>된 절차로
                수업을 진행
              </div>
            </div>
          </div>

          <div className="w-full text-left text-[11px] md:text-base">
            <ul className="list-none pl-0">
              <ListItem text="Shouting (힘찬 함성)" />
              <ListItem text="Praise the Lord (신나는 찬양)" />
              <ListItem text="Teacher’s Prayer (교사 기도)" />
              <ListItem text="Vision Statement (비전 외치기)" />
              <ListItem text="The Lord’s Prayer (영어 주기도문)" />
              <ListItem text="Total Review (복습)" />
              <ListItem text="Today’s Lesson (본 수업)" />
              <ListItem text="Book Review / Q.T (총 복습/Q.T)" />
              <ListItem text="Activity (추가 자료 활동)" />
              <ListItem text="Homework (숙제 공지)" />
              <ListItem text="Offering (헌금)" />
              <ListItem text="Today’s Winning Team (오늘 승리한 팀 달란트 지급)" />
              <ListItem text="Let’s Pray (다 같이 기도)" />
              <ListItem text="Shouting & Clean-Up (힘찬 함성과 청소)" />
              <ListItem text="End-Class Meeting (반 별 종례)" />
            </ul>
          </div>
        </div>

        <motion.div
          className="bg-[#FFDA19] rounded-b-xl w-full flex items-center justify-center gap-2 p-5 cursor-pointer"
          whileHover={{ opacity: 0.9 }}
          onClick={() => window.open('https://vo.la/eQZGM', '_blank')}
        >
          <div className="text-sm md:text-lg text-[#875CFF] font-bold ">
            교사용 매뉴얼 교안 샘플 보기
          </div>
          <CircleRightArrowIcon color="#875CFF" />
        </motion.div>
      </div>
    </div>
  );
};

const CardSection01 = () => {
  return (
    <div className="grid grid-cols-2 gap-3 md:gap-5">
      {/* 박스 1 */}
      <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
        <img src={wmwpage01} alt="wmwpage01" />
        <div
          className="flex-1 flex flex-col justify-center items-center
          text-center text-sm md:text-lg font-medium font-['Pretendard']"
        >
          <div>
            <span className="text-[#875cff] font-semibold">재미있는 삽화</span>
            로
          </div>
          <div>아이들의 시선 집중</div>
        </div>
      </div>

      {/* 박스 2 */}
      <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
        <img src={wmwpage02} alt="wmwpage02" />
        <div
          className="flex-1 flex flex-col justify-center items-center
          text-center text-sm md:text-lg font-medium font-['Pretendard']"
        >
          <div>
            <span className="text-[#875cff] font-semibold">삽화기억술</span>을
            통해
          </div>
          <div>그림만 보고도</div>
          <div>영어 암송이 술술!</div>
        </div>
      </div>

      {/* 박스 3 */}
      <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
        <img src={wmwpage03} alt="wmwpage03" />
        <div
          className="flex-1 flex flex-col justify-center items-center
          text-center text-sm md:text-lg font-medium font-['Pretendard'] whitespace-nowrap"
        >
          <div>요일별 숙제,</div>
          <div>그림 그리기, 암송카드 등</div>
          <div>
            <span className="text-[#875cff] font-semibold">추가 활동</span>
            으로
          </div>
          <div>완벽한 영어 학습</div>
        </div>
      </div>

      {/* 박스 4 */}
      <div className="flex flex-col items-center rounded-xl bg-white w-full p-5 md:p-8 gap-3 md:gap-5">
        <img src={wmwbook} alt="wmwbook" />
        <div className="flex flex-col justify-center gap-3 md:gap-5">
          <div className="text-center text-sm md:text-lg font-medium font-['Pretendard']">
            <div>
              <span className="text-[#875cff] font-semibold">
                Main Book 8권
              </span>
            </div>
            <div>찬양&교재 음원</div>
            <div>MP3 파일 제공</div>
          </div>
          <div className="text-center text-[10px] md:text-sm font-normal font-['Pretendard'] whitespace-nowrap">
            재미있는 8권의 교재 구성과
            <br />
            주일학교에서 바로 활용할 수 있는
            <br />
            영어 찬양과 음원 제공!
          </div>
        </div>
      </div>
    </div>
  );
};

export default WmwSection8a;
