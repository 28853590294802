import React from "react";
import { HalfLayout } from "../../../components/layout/HalfLayout";
import ResponseTypo from "../../../components/ResponseTypo";
import ReactPlayer from "react-player";

export const StoryVideo = () => {
  return (
    <section className="bg-gradient-to-r from-spotLilac/[0.1] to-white p-5 lg:pt-20 lg:pb-14">
      <div className="lg:m-auto lg:max-w-[1024px]">
        <HalfLayout
          left={
            <div className="flex flex-col text-center items-center md:text-left md:items-start">
              <img src="./svg/story/icon-award.svg" />
              <ResponseTypo variant_sm="h4" variant_lg="h4" className="mt-3">
                최고의 영어교재인 영어성경과
                <br />
                정철 선생의 50년 영어교육 노하우가 만났습니다. 누구도 따라할 수
                없는 발명특허 ‘삽화기억술’
              </ResponseTypo>
            </div>
          }
          right={
            <ReactPlayer
            style={{aspectRatio: '16/9'}}
            width={'100%'}
            height={'auto'}
              url={'https://youtu.be/3ZMRsurB9jA'}
            />
          }
        />
      </div>
    </section>
  );
};
