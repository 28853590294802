import React, { Fragment } from 'react';
import { ButtonGroup, Button, Input } from '@material-tailwind/react';
import TextWinds from '../common/TextWinds';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { calcDateClone, toDay } from '../../api/utils/util-func';

interface DatepickerProps {
  startDate: Date;
  endDate: Date;
  starDateHandle: (date: Date) => void;
  endDateHandle: (date: Date) => void;
}
const Datepicker = ({
  startDate,
  endDate,
  starDateHandle,
  endDateHandle,
}: DatepickerProps) => {
  const onMonthChange = (month: number) => {
    const today = toDay();
    starDateHandle(calcDateClone(today, { month: month }));
    endDateHandle(today);
  };
  return (
    <Fragment>
      <div className="flex flex-col justify-between items-center px-5 bg-gray0 rounded lg:justify-center lg:gap-x-[60px] lg:flex-row">
        <div className="flex flex-col gap-y-[10px] px-2 py-[10px]">
          <div className="flex gap-x-[6px]">
            <img
              src={process.env.PUBLIC_URL + '/svg/icon-calendar.svg'}
              alt="Calendar"
            />
            <TextWinds type="content_body3">조회 시작일</TextWinds>
          </div>
          <Input
            style={{ border: 'none', padding: '0', width: 'auto' }}
            labelProps={{ className: 'hidden' }}
            type="date"
            value={startDate.toISOString().split('T')[0]}
            onChange={(e) => starDateHandle(new Date(e.target.value))}
            className="bg-gray0 text-purple5"
          />
          {/* <DatePicker selected={startDate} onChange={starDateHandle} className="bg-gray0 text-purple5" dateFormat={"YYYY-MM-dd"}/> */}
          {/* <TextWinds type="content_body2" color="purple5">
                    2022.02.02{startDate.toDateString()}
                </TextWinds> */}
        </div>
        <div className="flex flex-col gap-y-[10px] px-2 py-[10px]">
          <div className="flex gap-x-[6px]">
            <img
              src={process.env.PUBLIC_URL + '/svg/icon-calendar.svg'}
              alt="Calendar"
            />
            <TextWinds type="content_body3">조회 종료일</TextWinds>
          </div>
          <Input
            style={{ border: 'none', padding: '0', width: 'auto' }}
            labelProps={{ className: 'hidden' }}
            type="date"
            value={endDate.toISOString().split('T')[0]}
            onChange={(e) => endDateHandle(new Date(e.target.value))}
            className="bg-gray0 text-purple5"
          />
          {/* <TextWinds type="content_body2" color="purple5">
                    2022.02.02
                </TextWinds> */}
        </div>
      </div>
      <ButtonGroup
        fullWidth
        variant="text"
        className="px-2 py-[10px] border border-gray4 rounded"
      >
        <Button
          className="rounded-none px-5 py-0 hover:text-gray-700"
          onClick={() => {
            onMonthChange(0);
          }}
        >
          <TextWinds type="button2" color="gray7">
            당일
          </TextWinds>
        </Button>
        <Button
          className="rounded-none px-5 py-0"
          onClick={() => {
            onMonthChange(-1);
          }}
        >
          <TextWinds type="button2" color="gray7">
            1개월
          </TextWinds>
        </Button>
        <Button
          className="rounded-none px-5 py-0"
          onClick={() => {
            onMonthChange(-3);
          }}
        >
          <TextWinds type="button2" color="gray7">
            3개월
          </TextWinds>
        </Button>
        <Button
          className="rounded-none px-5 py-0"
          onClick={() => {
            onMonthChange(-6);
          }}
        >
          <TextWinds type="button2" color="gray7">
            6개월
          </TextWinds>
        </Button>
      </ButtonGroup>
    </Fragment>
  );
};

export default Datepicker;
