import { ApiResponse } from '../../../constants/types';
import {
  studentRecitationCerificateType,
  studentRecitationLevelType,
} from '../../../constants/types/student-type';
import instance from '../../axios/utils.ts/instance';

export const getStudentRecitation = async () => {
  const response = await instance.get<
    ApiResponse<studentRecitationLevelType[]>
  >('v1/student/recitation');
  return response.data.data;
};

export const getStudentCertificate = async () => {
  const response = await instance.get('v1/student/certificates');
  return response.data.data;
};

export const postStudentCertificates = async (params: {
  name: string;
  englishName: string;
  type: string;
  subType: string;
}) => {
  const response = await instance.post<
    ApiResponse<studentRecitationCerificateType>
  >('v1/student/certificates', params);
  return response.data.data;
};
