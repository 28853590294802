import React from "react";
import Shape from "./images/shape-common-purple.png";
import ImageA from "./images/review4-a.png";
import ImageB from "./images/review4-b.png";
import Shape1 from "./images/shape1-purple.png";
import Shape2 from "./images/shape2-purple.png";
import Shape3 from "./images/shape3-purple.png";
import { Link } from "react-router-dom";

export const Review4 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-16 py-16 text-lg leading-7 text-center text-black bg-white max-md:px-5 relative overflow-hidden">
      <img
        src={Shape1}
        width={600}
        className="absolute -left-52 top-[500px] hidden md:visible"
      />
      <img
        src={Shape2}
        width={600}
        className="absolute -right-64 top-[2100px] hidden md:visible"
      />
      <img
        src={Shape3}
        width={600}
        className="absolute -left-52 top-[2400px] hidden md:visible"
      />
      <img loading="lazy" src={Shape} className="aspect-square w-[88px]" />
      <div className="mt-5 text-3xl font-bold leading-9 max-md:max-w-full">
        느리지만 말씀과 영어로 바른 길을 걸어요!
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-4 tracking-normal text-slate-900">
        저는 작은 교회에서
        <br />
        초등학교 2학년 한 명, 7세 4명 총 5명의 아이들과
        <br />
        젭스영어주일학교를 함께하고 있습니다.
        <br />
        <br />
        사실 다소 어울리지 않는 나이대의 모임이기도 했고,
        <br />
        초등학생 아이도, 7세 아이들도 영어 교육이 전무한
        <br />
        상태였기에 걱정을 안은 채로 시작을 했습니다.
        <br />
        <br />
        7세 아이들과 초등학생 사이에 나타나는
        <br />
        학습능력의 차이를 어떻게 좁히느냐가 저의 큰 과제였습니다.
        <br />
        <span className="text-purple5">그 때 하나님이 저에게 주신 마음이</span>
        <br />
        <span className="text-purple5">“느려도 괜찮다.”였습니다.</span>
        <br />
        <br />
        <span className="text-slate-900">적은 단어로 writing을 연습하고</span>
        <br />
        <span className="text-slate-900">알파벳부터 차근차근 알려주며</span>
        <br />
        <span className="text-slate-900">
          느리지만, 말씀 중심으로 영어를 차근차근 가르쳤습니다.
        </span>
        <br />
      </div>
      <img
        loading="lazy"
        src={ImageA}
        className="mt-14 max-w-full aspect-[1.43] w-[582px] max-md:mt-10 relative"
      />
      <div className="mt-8 tracking-normal text-black max-md:max-w-full">
        물론 어려움은 많았습니다.
        <br />
        교회에서 영어를 배우다보니 부모님의 학습 관리나 숙제 관리가 쉽지 않았고,
        <br />
        <br />
        죄에 대한 이야기를 배울 때엔
        <br />
        어려운 내용과 어려운 영어를 힘들어하기도 했습니다.
        <br />
        <br />이 아이들이 과연 할 수 있을까?
        <br />
        불안함이 들 때도 있었고,
        <br />
        다음부턴 그냥 한국어 프로그램을 진행하는 것이
        <br />
        낫지 않겠냐는 주일학교 내부 의견도 있었습니다.
        <br />
        <br />
        <span className="text-purple5">하지만, 저는 다른 것보다</span>
        <br />
        <span className="text-purple5">
          이 성경의 중요한 이야기를 확실히 알려주고 싶었습니다.
        </span>
        <br />
        <span className="text-purple5">
          느리지만 아이들이 말씀과 영어로 바른 길을 걸을 수 있도록
        </span>
        <br />
        <span className="text-purple5">
          더 기도하고 더 돕고싶은 마음이었습니다.
        </span>
        <br />
        <br />
        그리고, 그 바른 길을 천천히 걷다보니
        <br />
        하나님께서 도와주시고 은혜를 베푸셨습니다.
        <br />
        <br />
        하나님이 주시는 마음으로 과감하게 아이들 발표를
        <br />
        진행하기로 결정하고 발표회를 가졌는데
        <br />
        우리 아이들이 정말 멋지게 영어로 말씀을 암송해주었습니다.
        <br />
        <br />
        작은 교회, 맞지않는 연령대, 전무후무한 영어 교육 등<br />
        다소 열악한 환경이었지만
        <br />
        느리지만 말씀과 영어로 훈련받은 우리 아이들은
        <br />
        어느새 4권의 내용을 줄줄 영어로 암송하였습니다.
        <br />
        <br />그 모습을 본 교회 성도분들은 크게 감동했고
        <br />
        주일학교 총괄 선생님께서도
        <br />
        아이들의 발표를 보고 계속해서 젭스로 주일학교를 운영하자고 하셨습니다.
        <br />
        <br />
        여전히 넘어야 할 산은 높고 해결해야 할 문제도 많지만
        <br />느
        <span className="text-purple5">
          리더라도 말씀과 영어로 바른 길을 걷는다면
        </span>
        <br />
        <span className="text-purple5">아이들이 하나님을 더 알아가고</span>
        <br />
        <span className="text-purple5">
          예수님의 십자가가 얼마나 의미 있는지
        </span>
        <br />
        <span className="text-purple5">배울 수 있다는 확신을 가져 봅니다!</span>
        <br />
        <br />
        <br />
        그리고 그 무엇보다
        <br />
        바른 길을 걸을 수 있게 좋은 프로그램을 제공해준
        <br />
        젭스에게 감사한 마음을 전합니다.
        <br />
      </div>
      <img
        loading="lazy"
        src={ImageB}
        className="mt-11 max-w-full aspect-[1.72] w-[546px] max-md:mt-10 relative"
      />
      <div className="mt-32 text-3xl font-bold leading-9 text-slate-900 max-md:mt-10 max-md:max-w-full">
        - 더하트하우스 교회 남** 선생님의 후기 -
      </div>
      <div className="shrink-0 self-stretch mt-20 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-14 tracking-normal max-md:mt-10">
        하나님은 느리지만 옳고 바르게 일하십니다.
        <br />
        세상이 빠르게 움직이고 변화할 때,
        <br />
        우리는 하나님의 일하시는 방식대로
        <br />
        느리더라도, 조금은 버겁더라도
        <br />
        옳은 것으로, 바른 곳으로 걸어가야 합니다.
        <br />
        <br />
        교회는 느리더라도 아이들을 말씀과 영어로 바른 길을 걷도록
        <br />
        인도해줄 수 있어야 합니다.
        <br />
      </div>
      <div className="justify-center items-center px-16 py-10 mt-16 max-w-full font-medium tracking-normal text-black bg-[#EFE4FA] leading-[133%] w-[338px] max-md:px-5 max-md:mt-10">
        “느리지만 괜찮아”
      </div>
      <div className="mt-16 tracking-normal max-md:mt-10 max-md:max-w-full">
        교회의 아이들을 말씀과 영어로 바르게 기르는 것!
        <br />
        바로 교회의 사명입니다.
        <br />
        젭스영어주일학교는 교회의 그 사명을 최선을 다해 돕고 있습니다.
      </div>
      <Link to="/courses/churchschool">
        <div className="justify-center items-center px-2.5 py-4 mt-28 max-w-full text-base font-bold tracking-normal leading-6 text-purple5 bg-white rounded border border-purple5 border-solid w-[376px] max-md:px-5 max-md:mt-10">
          주일학교 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
