import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../components/common/Buttons2';
import TextWinds from '../../../components/common/TextWinds';
import NormalPage from '../../../components/layout/NormalPage';
import LearningCard from './component/LearningCard';
import Box from '../../../components/layout/Box';
import PageHead from '../../../components/layout/PageHead';
import { Button } from '@material-tailwind/react';
import instance from '../../../api/axios/utils.ts/instance';
import useUserHook from '../../../controllers/useUserHook';
import { useAppDispatch } from '../../../store/hook';
import {
  closeModal,
  openAlert,
  openConfirm,
  openModal,
} from '../../../store/features/modalSlice';
import { COURSE_SUMMERY_DATA } from '../../../data/statics-datas';
import { extensionLink } from './extensionLink';
import getAgreement, {
  AgreementType,
} from '../../../components/common/Agreement';
import { IconNavLink } from '../../../components/IconNavLink';
import ChannelService from '../../../api/ChannelService';

const LearningMain = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkAndPageMove } = useUserHook();
  const [pageLoaded, setPageLoaded] = useState(false); // 페이지 로드 여부
  const [data, setData] = useState([]);

  useEffect(() => {
    // API 호출 함수
    const fetchData = async () => {
      try {
        const response = await instance.get('/v1/learning');
        const res = response.data;
        setData(res.data);
        setPageLoaded(true);
      } catch (error) {}
    };

    fetchData(); // 데이터 가져오기 함수 호출
  }, []); // 컴포넌트가 처음 렌더링될 때만 실행되도록 []

  const handleStart = (learningId: string) => {
    dispatch(
      openConfirm({
        message: '학습을 시작하시겠어요?\n확인을 누르면 학습이 시작됩니다.',
        onConfirm: () => {
          openAgreeConfirm(learningId);
        },
        confirmText: '시작',
      })
    );
  };

  const handleExtend = (learningId: string) => {
    dispatch(
      openConfirm({
        message:
          '학습 기간이 종료되었어요.\n기간 연장을 결제 후 이용해 주세요.',
        onConfirm: () => {
          const courseId = data.find(
            (item) => item.myLearning.id === learningId
          ).course.id;
          const link = extensionLink(courseId);
          if ('' !== link) {
            window.open(link, '_blank');
          } else {
            dispatch(
              openConfirm({
                message: '본 코스는 연장이 불가합니다.\n고객센터로 문의주세요.',
                onConfirm: () => {
                  ChannelService.openChat();
                  dispatch(closeModal());
                },
                confirmText: '1:1 채팅',
              })
            );
          }
        },
        confirmText: '연장하기',
      })
    );
  };

  const actionOpenLearning = (learningId: string) => {
    const openId = data.find((item) => item.myLearning.id === learningId)
      .myLearning.openId;

    putOpenLearning(learningId, openId).then((res) => {
      navigate(`/learning/detail/${res.data.myLearning.id}`);
    });
  };

  const putOpenLearning = async (learningId: string, openId: string) => {
    try {
      const response = await instance.put(
        `/v1/learning/${learningId}/open/${openId}`
      );
      return response.data;
    } catch (error) {}
  };

  /**
   * 내 학습 약관 동의 컨펌창
   * @param learningId
   */
  const openAgreeConfirm = (learningId: string) => {
    const courseName = data.find((item) => item.myLearning.id === learningId)
      .course.name;
    const basicList = [
      'JEMM Basic Teacher',
      'JEMM Advanced Teacher',
      'JEMM Kids Teacher',
    ];

    const agreement = basicList.includes(courseName)
      ? AgreementType.LEARNING_HOMESCHOOL
      : AgreementType.LEARNING_TEACHING;

    const learningName = basicList.includes(courseName)
      ? '홈스쿨 코스'
      : '내 학습';
    dispatch(
      openModal({
        body: (
          <AgreeModal
            learningId={learningId}
            learningName={learningName}
            agreement={agreement}
            putOpenLearning={actionOpenLearning}
          />
        ),
      })
    );
  };

  return (
    pageLoaded && (
      <>
        <NormalPage className="pb-[30px]">
          <PageHead>
            <TextWinds type="title_h2" xlType="title_h1">
              내 학습
            </TextWinds>
          </PageHead>
          <Box>
            {data.length > 0 ? (
              data.map((item, index) => (
                <LearningCard
                  key={index}
                  data={item.myLearning}
                  linkTo={`/learning/detail/${item.myLearning.id}`}
                  openStart={(id) => handleStart(id)}
                  openExtend={handleExtend}
                  type="learning"
                />
              ))
            ) : (
              <div className="flex flex-col w-full">
                <TextWinds type="title_h2" className="w-full text-center mt-3">
                  아직 구매한 코스가 없습니다.
                </TextWinds>
                <br />
                <TextWinds type="title_h2" className="w-full text-center">
                  코스 구매 후 학습을 시작하세요.
                </TextWinds>
                <div className="grid grid-cols-2 gap-4 mt-5 lg:grid-cols-4 lg:mt-9">
                  {COURSE_SUMMERY_DATA.map((item, index) => (
                    <IconNavLink
                      key={index}
                      to={item.to}
                      src={item.src}
                      text1={item.text1}
                      text2={item.text2}
                    />
                  ))}
                </div>
              </div>
            )}
          </Box>
          <div className="xl:absolute xl:top-[18px] xl:w-[177px] xl:right-[50%] xl:mt-0 xl:translate-x-2/4 xl:mr-[-498px]">
            <Buttons
              variant="outlined"
              color="purple5"
              onClick={() => {
                window.open(
                  'https://jebs-notice.oopy.io/58e22264-d335-4b74-aff1-3099f4b01d9e',
                  '_blank'
                );
              }}
            >
              <TextWinds type="button" color="purple5">
                내 학습 이용 메뉴얼
              </TextWinds>
            </Buttons>
          </div>
        </NormalPage>
      </>
    )
  );
};

const AgreeModal = ({
  learningId,
  learningName,
  agreement,
  putOpenLearning,
}: {
  learningId: string;
  learningName: string;
  agreement: AgreementType;
  putOpenLearning: (id: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const closeBtnhandler = () => {
    dispatch(closeModal());
  };

  const confirmBtnhandler = () => {
    dispatch(closeModal());
    putOpenLearning(learningId);
  };

  return (
    <div className="bg-white w-[600px] m-auto p-4 rounded">
      <TextWinds type="content">
        {learningName} 시작 시 약관 동의가 필요해요.
      </TextWinds>
      <TextWinds type="title_h3">{agreement}</TextWinds>
      <div className="h-auto max-h-[70vh] overflow-y-auto border p-4">
        <pre className="whitespace-pre-wrap">{getAgreement(agreement)}</pre>
      </div>
      <div className="grid grid-cols-2 gap-2 pt-4">
        <Button
          onClick={() => closeBtnhandler()}
          className="border border-purple3 text-purple5 bg-white hover:bg-purple3 hover:text-white"
        >
          취소
        </Button>
        <Button
          onClick={() => confirmBtnhandler()}
          className="bg-purple5 text-white hover:bg-purple6"
        >
          동의
        </Button>
      </div>
    </div>
  );
};

export default LearningMain;
