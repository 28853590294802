import React from 'react';
import { useAppDispatch } from '../../../store/hook';
import { deleteTodoAssign } from '../../../api/utils/lms/lmsApi';
import { openAlert, openConfirm } from '../../../store/features/modalSlice';
import HFbutton from '../../common/input/HFbutton';

interface TodoStatusDeleteBtnProps {
  classId: string;
  txId: string;
  todoState: string;
  reload: () => void;
}
const TodoStatusDeleteBtn = ({
  classId,
  txId,
  todoState,
  reload,
}: TodoStatusDeleteBtnProps) => {
  const dispatch = useAppDispatch();

  const deleteAction = (classId: string, txId: string) => {
    deleteTodoAssign(classId, txId, todoState).then(() => {
      dispatch(openAlert({ message: '삭제되었습니다.' }));
      reload();
    });
  };

  const deleteHandler = () => {
    dispatch(
      openConfirm({
        message:
          '삭제한 과제는 다시 복구되지 않습니다.\n정말 삭제하시겠습니까?',
        onConfirm: () => deleteAction(classId, txId),
      })
    );
  };

  return (
    <HFbutton height={34} onClick={deleteHandler}>
      삭제
    </HFbutton>
  );
};

export default TodoStatusDeleteBtn;
