import { useEffect, useState } from 'react';
import Buttons from '../../../components/common/Buttons';
import TextWinds from '../../../components/common/TextWinds';
import NormalPage from '../../../components/layout/NormalPage';
import CustomDialog from '../../../components/layout/CustomDialog';
import LearningCard, { CardProps } from './component/LearningCard';
import FullDialog from '../../../components/layout/FullDialog';
import Box from '../../../components/layout/Box';
import PageHead from '../../../components/layout/PageHead';
import instance from '../../../api/axios/utils.ts/instance';
import { useAppDispatch } from '../../../store/hook';
import { openModal } from '../../../store/features/modalSlice';
import ModalExtendLink from '../../../components/modal/ModalExtendLink';
import { setExtendBtnLink } from '../../../api/utils/lms/ExtendSetBtnLink';

const ClassManagement = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const handleExtend = (id: string) => {
    // JEMM Basic Class ==> 연장하기 class id : Irmk2AAW2RWV-iHn JEMM Basic Class TEACHIN
    const { checkType, purchaseLink } = setExtendBtnLink(
      data.find((x) => x.myClass.id === id).course.id
    );
    dispatch(
      openModal({
        body: (
          <ModalExtendLink
            checkType={checkType}
            message="클래스 연장하기"
            purchaseLInk={purchaseLink}
          />
        ),
      })
    );
  };

  useEffect(() => {
    // API 호출 함수
    const fetchData = async () => {
      try {
        const response = await instance.get('/v1/my-class');
        const res = response.data;
        setData(res.data);
      } catch (error) {}
    };

    fetchData(); // 데이터 가져오기 함수 호출
  }, []);

  return (
    <NormalPage>
      <PageHead>
        <TextWinds type={'title_h2'}>클래스 관리</TextWinds>
      </PageHead>
      <Box>
        {data.length > 0 ? (
          data.map((item, index) => (
            <LearningCard
              key={index}
              data={item.myClass}
              linkTo={`/lms/${item.myClass.id}/dashboard`}
              openExtend={handleExtend}
              type="classmgmt"
            />
          ))
        ) : (
          <div className="flex flex-col w-full">
            <TextWinds type="title_h2" className="w-full text-center mt-3">
              아직 개설된 클래스가 없습니다.
            </TextWinds>
            <br />
            <TextWinds type="title_h2" className="w-full text-center">
              코스를 수료하신 후 클래스를 이용하세요.
            </TextWinds>
          </div>
        )}
      </Box>
      {/* <div className="mt-[64px] xl:absolute xl:top-[18px] xl:w-[177px] xl:right-[50%] xl:mt-0 xl:translate-x-2/4 xl:mr-[-498px]">
            <Buttons type="outlined" color="purple5">
                내 학습 이용 메뉴얼
            </Buttons>
        </div> */}
    </NormalPage>
  );
};

export default ClassManagement;
