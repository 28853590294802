import { Outlet } from 'react-router-dom';
import MegaMenu from './common/layouts/megamenu';
import ScrollToTop from './layout/ScrollToTop';

const OnlyHeadersLayout = () => {
  return (
    <>
      <MegaMenu />
      <main className="mt-[58px]">
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </main>
    </>
  );
};

export default OnlyHeadersLayout;
