export enum ProductType {
  COURSE = 'COURSE',
  STANDARD = 'STANDARD',
  BOOSTUP = 'BOOSTUP',
  PACKAGE = 'PACKAGE',
}

export enum CourseType {
  TEACHING = 'TEACHING',
  LEARNING = 'LEARNING',
  TRAINING = 'TRAINING',
}

export enum ProductSubType {
  HOMESCHOOL = 'HOMESCHOOL',
  WORSHIP = 'WORSHIP',
  NIV = 'NIV',
  NEW_FAMILY = 'NEW_FAMILY',
  LEARN_ON_PHONE = 'LEARN_ON_PHONE',
  PRODUCT = 'PRODUCT',
  RESOURCE = 'RESOURCE',
  TEXTBOOK = 'TEXTBOOK',
  BOOK = 'BOOK',
  GOODS = 'GOODS',
  NONE = 'NONE',
  ALL = 'ALL',
  NORMAL_BOOK = 'NORMAL_BOOK',
  SMALL_GROUP = 'SMALL_GROUP',
}

export enum AppProductListOrderBy {
  RECENT = 'RECENT',
  LOW_PRICE = 'LOW_PRICE',
  HIGH_PRICE = 'HIGH_PRICE',
  POPULARITY = 'POPULARITY',
  RECOMMENDED = 'RECOMMENDED',
}

export enum JebsplType {
  HFHJ = 'HFHJ',
  HMTW = 'WMTW',
  JS12 = 'JS12',
}

export enum GoodsType {
  DIGITAL = 'DIGITAL',
  GOOD = 'GOOD',
}

export enum PriceSubstituton {
  NONE = 'NONE',
  SOLDOUT = 'SOLDOUT',
}

export enum ProductInstallmentType {
  STANDARD = 'STANDARD',
  FIXED = 'FIXED',
}

export enum ContentsDivision {
  영상 = 'video',
  자료 = 'materials',
  기타 = 'etc',
}

export enum ContentsType {
  YOUTUBE = 'Youtube',
  VIMEO = 'Vimeo',
  HTML = 'HTML',
  FILE = 'File',
  JEBSPL = 'Jebspl',
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DEPOSIT = 'DEPOSIT',
  POINT = 'POINT',
  NOT_DETERMINED = 'NOT_DETERMINED',
  FREE = 'FREE',
}

export enum Device {
  PC = 'PC',
  MOBILE = 'MOBILE',
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  DEPOSIT_REQUESTED = 'DEPOSIT_REQUESTED',
  DEPOSIT_REFUND_REQUESTED = 'DEPOSIT_REFUND_REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR', // 환불에러일때만 표시,
  INVALIDATED = 'INVALIDATED', // 결제 요청 에러,
}

export enum StudentStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export enum ToDoType {
  RECITATION = 'RECITATION',
  EXAM = 'EXAM',
  JEBSON = 'JEBSON',
  JEBSPL = 'JEBSPL',
  SURVEY = 'SURVEY',
}

export enum TaskType {
  RECITATION = 'RECITATION',
  EXAM = 'EXAM',
  JEBSON = 'JEBSON',
  JEBSPL = 'JEBSPL',
  SURVEY = 'SURVEY',
  HOMEWORK = 'HOMEWORK',
}

export enum QuestionType {
  MULTIPLE = 'MULTIPLE',
  SHORT = 'SHORT',
}

export enum LmsType {
  CONTENTS = 'CONTENTS',
  QUIZ = 'QUIZ',
}

export enum LmsQuizType {
  DAILY = 'DAILY',
  ACTUAL = 'ACTUAL',
}

export enum LmsContentsType {
  WORD = 'WORD',
  SENTENCE = 'SENTENCE',
}

export enum QuestionComponentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
}

export enum MediaContentType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export enum ProductContentType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  URL = 'URL',
}

export enum OrderStatus {
  PENDING = 'PENDING',
  PAY_RECIEVED = 'PAY_RECIEVED',
  USER_CANCEL = 'USER_CANCEL',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUND_COMPLETE = 'REFUND_COMPLETE',
  COMPLETE = 'COMPLETE',
  INVALIDATED = 'INVALIDATED',
  ERROR = 'ERROR',
}

export enum OrderItemReferenceType {
  CART = 'CART',
  TEMPLATE = 'TEMPLATE',
  ORDER = 'ORDER',
  OPTION = 'OPTION',
}

export enum ShippingStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  PREPARING = 'PREPARING',
  SHIPPING = 'SHIPPING',
}

export enum Logistics {
  HJ = 'HJ',
}

export enum Status {
  NONE = 'NONE',
  PENDING = 'PENDING',
  CERTIFIED = 'CERTIFIED',
}

export enum ChurchScale {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
  EXTRA_LARGE = 'X',
}

export enum ClassMethod {
  PAY = 'PAY',
  FREE = 'FREE',
}

export enum LectureStatus {
  NONE = 'NONE',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum ClassLevel {
  PRESCHOOLER = '4+',
  ELEMENTARY_STUDENT = '8+',
  MIDDLE_SCHOOLER = '14+',
  UNDEFINED = '♾️',
}

export enum DayOfWeek {
  일 = '일',
  월 = '월',
  화 = '화',
  수 = '수',
  목 = '목',
  금 = '금',
  토 = '토',
}

export enum TaskStatus {
  RECOMMENDED = 'RECOMMENDED',
  ONGOING = 'ONGOING',
}

export enum UserSnsType {
  DEFAULT = 'DEFAULT',
  NAVER = 'NAVER',
  KAKAO = 'KAKAO',
  APPLE = 'APPLE',
}

export enum UserType {
  USER = 'USER',
  STUDENT = 'STUDENT',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum ChurchPosition {
  SENIOR_PASTOR = '담임목회자',
  ASSISTANT_PASTOR = '부교역자',
  PASTOR_WIFE = '사모',
  MISSIONARY = '선교사',
  SAINT = '성도',
}

export enum TeacherStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  CERTIFIED = 'CERTIFIED',
}

export enum PointTransactionType {
  PURCHASE_USE = 'PURCHASE_USE',
  PURCHASE_EARN = 'PURCHASE_EARN',
  PURCHASE_GRADE = 'PURCHASE_GRADE',
  REGISTRATION = 'REGISTRATION',
  REFUND = 'REFUND',
  OFFICE_GRANT = 'OFFICE_GRANT',
}

export enum PointTransactionSource {
  SHOP = 'SHOP',
  MANUAL = 'MANUAL',
  ETC = 'ETC',
}

export enum PointTransactionStatus {
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DEPOSIT_WAIT = 'DEPOSIT_WAIT',
}

// TODO : 표시되는 것 바꿔야함.
export enum ToDoStatusEnum {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
}
export enum ToDoStatusSearchViewBy {
  BY_TODO = 'BY_TODO',
  BY_ID = 'BY_ID',
}

export enum ToDoStatusOrderByValue {
  DESC = 'DESC',
  ASC = 'ASC',
}
export enum ToDoStatusOrderByName {
  DESC = '최신순',
  ASC = '오래된순',
}

export enum ToDoMgmtSubType {
  RECITATION = 'RECITATION',
  JEBSON = 'JEBSON',
  JEBSPL = 'JEBSPL',
}

export enum TodoMgmtSubTypeName {
  RECITATION = '인증제',
  JEBSON = '젭스온',
  JEBSPL = '젭스플',
  WMW = 'WMW',
  JS1 = 'JS1',
  JS2 = 'JS2',
  HF = 'HF',
  HJ = 'HJ',
}
