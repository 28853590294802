import TextWinds from '../../../components/common/TextWinds';
import InfoText from '../../../components/mypage/Info';
import Datepicker from '../../../components/mypage/Datepicker';
import '../../../styles/mypage/mypage.css';

import { numberWithCommas } from '../../../api/utils/format/numbers';
import { useEffect, useState } from 'react';
import { calcDateClone, toDay } from '../../../api/utils/util-func';
import { getMyPoint, selectMyPoints } from '../../../store/features/mySlice';
import { useAppDispatch, useAppSelector } from '../../../store/hook';

import PointListComp from './PointListComp';
const INFO_TEXT = [
  {
    title: '포인트 안내',
    text: [
      '포인트는 적립 일로부터 3년간 유효합니다.',
      '포인트는 타인에게 양도가 불가합니다.',
    ],
  },
];

const MypagePoint = () => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState(
    calcDateClone(toDay(), { month: -1 })
  );
  const [endDate, setEndDate] = useState(
    calcDateClone(toDay(), { day: +1, sec: -1 })
  );
  const pointList = useAppSelector(selectMyPoints);

  useEffect(() => {
    dispatch(
      getMyPoint({
        url: '/v1/mypage/points',
        params: { page: 1, pageSize: 100, startDate, endDate },
      })
    );
  }, [startDate, endDate]);

  const calcEndDate = (date: Date) => {
    setEndDate(calcDateClone(toDay(date), { day: +1, sec: -1 }));
  };

  if (pointList === undefined) return null;

  const transactions = pointList?.result?.transactions;

  const list = transactions
    ? [...transactions].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    : [];

  return (
    <>
      <section className="px-5 lg:p-0">
        <div className="mb-6 lg:mb-[22px]">
          <TextWinds type="title_h2">포인트 내역</TextWinds>
        </div>
        <div className="flex flex-col gap-y-3 mb-4 lg:gap-y-4">
          <div className="flex justify-between items-center px-5 py-[10px] bg-gray0">
            <TextWinds type="content_body1" xlType="content_body2">
              보유 포인트
            </TextWinds>
            <div className="flex items-center gap-x-1">
              <TextWinds type="title_h3" color="purple5">
                {numberWithCommas(pointList ? pointList?.result.balance : 0)}
              </TextWinds>
              <TextWinds type="content_body1" color="purple5">
                점
              </TextWinds>
            </div>
          </div>
          <Datepicker
            startDate={startDate}
            endDate={endDate}
            starDateHandle={setStartDate}
            endDateHandle={calcEndDate}
          />
        </div>
        <div className="flex flex-col gap-y-6 mb-8 lg:gap-y-8">
          <PointListComp list={list} />
          {/* <div className="lg:w-[476px] lg:mx-auto">
                        <Buttons variant="outlined" color="purple5">
                            <TextWinds type="button" color="purple5">
                                더보기
                            </TextWinds>
                        </Buttons>
                    </div> */}
        </div>
        {INFO_TEXT.map((info, key) => (
          <InfoText key={key} title={info.title} text={info.text} />
        ))}
      </section>
    </>
  );
};

export default MypagePoint;
