import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { CheckIcon } from '../homeschool/HomeschoolComp';
import wmwLecture from './wmw/img/wmw-lecture.jpg';
import siteScreenshot from './wmw/img/site-screenshot.png';

const JepsplSection = () => {
  return (
    <div className="bg-[#6730FF]">
      <div className="max-w-[520px] m-auto flex flex-col gap-5 md:gap-10 items-center text-center py-10  text-white">
        <div>
          <div className="text-3xl md:text-4xl font-bold">
            <span className="text-[#ffda18]">젭스플</span>
            이란?
          </div>

          <div className="text-lg md:text-xl">
            젭스영어주일학교 온라인 콘텐츠
          </div>
        </div>

        <div>
          <span className="text-[#ffda18] text-3xl md:text-4xl font-bold gap-3 md:gap-5">
            젭스플 (JEBS PLAY)
          </span>
          <div className="text-base md:text-lg">
            젭스플(JEBS Play)은 젭스영어주일학교 프로그램을
            <br />
            PC/모바일로 자유롭게 이용할 수 있는 콘텐츠로
            <br />
            더욱 풍성한 학습 활동을 지원해줍니다.
          </div>
        </div>

        <div>
          <div className="grid grid-cols-2 max-w-[520px] gap-5 p-5 ">
            <div className="flex flex-col bg-gray1 rounded-2xl">
              <img
                src={wmwLecture}
                alt="wmwLecture"
                className="h-full object-cover rounded-t-2xl"
              />
              <div className="w-fit text-xs font-bold rounded-full px-2 my-1 self-center text-[#6132da]">
                수업/율동 영상 시청
              </div>
            </div>
            <div className="flex flex-col bg-gray1 rounded-2xl">
              <img
                src={siteScreenshot}
                alt="site-screenshot"
                className="h-full object-cover rounded-t-2xl"
              />
              <div className="w-fit text-xs font-bold rounded-full px-2 my-1 self-center text-[#6132da]">
                한 눈에 보는 학습현황
              </div>
            </div>
          </div>

          <div className="text-sm md:text-base">
            *구매한 학생 교재 1세트 당 젭스플 이용권 1개 사용가능
          </div>
        </div>

        <div className="px-5 w-full">
          <TabView />
        </div>
      </div>
    </div>
  );
};

const TabView = () => {
  const [tab, setTab] = React.useState(0);

  const content = {
    0: [
      '복습 수업 영상 및 액티비티로 집에서도 학생들의 복습 가능',
      '학생들의 학습 현황 한 눈에 확인',
    ],
    1: [
      '주중 가정 복습용 (가정학습 가이드 제공)',
      '본 수업용 (젭스플+선생님 지도의 콜라보)',
      '진도 따라잡기용',
    ],
  };

  return (
    <div className="max-w-[520px] w-full bg-[#30009c] rounded-xl mt-5">
      <div className="grid grid-cols-2 text-center w-full">
        <div
          className={`cursor-pointer py-2 rounded-tl-xl w-full ${
            tab === 0 ? 'text-[#ffda18] font-bold' : 'bg-[#451ca2] text-white'
          }`}
          onClick={() => setTab(0)}
        >
          젭스플 주요기능
        </div>
        <div
          className={`cursor-pointer py-2 rounded-tr-xl w-full ${
            tab === 1 ? 'text-[#ffda18] font-bold' : 'bg-[#451ca2] text-white'
          }`}
          onClick={() => setTab(1)}
        >
          젭스플 활용방안
        </div>
      </div>
      <div className="p-5 w-full">
        <TextCheckListBox
          textArr={content[tab as keyof typeof content]}
          textSize="text-sm md:text-base"
        />
      </div>
    </div>
  );
};

export const TextCheckListBox = ({
  textArr,
  textSize,
}: {
  textArr: ReactNode[];
  textSize?: string;
}) => (
  <div className="w-full flex flex-col gap-3 p-5 bg-gray2 rounded-lg text-black text-left">
    {textArr.map((text, index) => (
      <div
        key={index}
        className={`w-full flex gap-2 items-start ${textSize || 'text-base'}`}
      >
        <CheckIcon width={18} color="#9c9c9c" />
        <div className="flex-1 w-full md:text-base">{text}</div>
      </div>
    ))}
  </div>
);

export default JepsplSection;
