import { createSlice } from "@reduxjs/toolkit";
import { CartProps, OptionProps, PlanProps } from "../../constants/types";
import { RootState } from "..";

export interface ProductState {
  id: number,
  name: string,
  title: string,
  desc?: string,
  price?: number,
  saleInfo?: string,
  discountPercent?: number,
  plans?: PlanProps,
  options?: OptionProps[],
  totalPrice: number,
}
interface TempType {
  cartLists: CartProps[]
}
const initialState: TempType = {
  cartLists: null
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset(state) {
      state.cartLists = null
    }
  },
})

export const { reset } = productSlice.actions
export const selectProductLists = (state: RootState) => state.product
export default productSlice.reducer