import { PurchaseRequestDto } from "../../store/features/purchaseSlice";

// 결제화면용
export const purchase_valid = (data: PurchaseRequestDto) => {
  // TODO : Validation 작업중.
  let flag: boolean = true
  let message: string = ""
  if(!data.address_1) {
    flag = false
    message = "기본주소를 입력하지 않았습니다. 기본주소를 입력해주세요."
  }
  if(!data.address_2) {
    flag = false
    message = "상세주소를 입력하지 않았습니다. 상세주소를 입력해주세요."
  }
  if(!data.contact_no) {
    flag = false
    message = "연락처를 입력하지 않았습니다. 연락처를 입력해주세요."
  }
  if(!data.message) {
    flag = false
    message = "배송메시지를 입력하지 않았습니다. 배송메시지를 입력해주세요."
  }
  if(!data.post_code) {
    flag = false
    message = "우편번호를 입력하지 않았습니다. 우편번호를 입력해주세요."
  }
  if(!data.receiver) {
    flag = false
    message = "배송지명을 입력하지 않았습니다. 배송지명을 입력해주세요."
  }
  if(!data.payment_method) {
    flag = false
    message = "결제방식을 선택하지 않았습니다. 결제방식을 선택해주세요."
  }

  return {flag, message};
}