import { Input } from '@material-tailwind/react';
import React, { useContext, useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { StudentProfileContext } from './StudentProfileContext';

import { Link, useNavigate } from 'react-router-dom';
import Buttons from '../../../components/common/Buttons';
import { useAppDispatch } from '../../../store/hook';
import instance from '../../../api/axios/utils.ts/instance';
import { useForm } from 'react-hook-form';
import { openAlert } from '../../../store/features/modalSlice';
import { toDay } from '../../../api/utils/util-func';
import CalendarInput from '../components/CalendarInput';
import axios from 'axios';
import ErrorInput from '../components/ErrorInput';

type studentProfileDTO = {
  name: string;
  englishName: string;
  birthday: string;
};
interface StudentProfileCompProps {
  editable?: boolean;
}
const StudentProfileComp = ({ editable }: StudentProfileCompProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = localStorage.getItem('userId');
  const { studentProfile, setStudentProfile } = useContext(
    StudentProfileContext
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<studentProfileDTO>({
    defaultValues: {
      name: studentProfile.name,
      englishName: studentProfile.englishName,
      birthday: studentProfile.birthday,
    },
  });

  /**
   * DatePicker 관련
   */
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(
      studentProfile.guardianInfo
        ? studentProfile.guardianInfo.birthday
        : toDay()
    )
  );

  /**
   * 학생 정보 수정 통신
   * @param data
   */
  const putStudentProfile = async (data: studentProfileDTO) => {
    try {
      const result = await instance.put('/v1/user/student/basic-info', data);
      if (result.status === 200) {
        dispatch(openAlert({ message: '학생 정보가 수정되었습니다.' }));
        navigate('/student/profile');
        setStudentProfile(result.data.data);
      }
    } catch (error) {
      //에러 타입이 AxiosError인 경우
      if (axios.isAxiosError(error)) {
        if (
          error.response?.data &&
          error.response?.data.error &&
          error.response?.data.statusCode
        ) {
          dispatch(openAlert({ message: error.response?.data.error }));
          navigate('/student/profile');
        }
      } else {
        console.error(error);
      }
    }
  };

  /**
   * 학생 정보 수정 버튼 클릭시
   * @param data
   */
  const onSubmit = (data: studentProfileDTO) => {
    putStudentProfile(data);
  };

  return (
    studentProfile && (
      <form>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <TextWinds type="title_h2">학생 정보</TextWinds>
            {!editable && (
              <Link to="/student/profile/edit/student">
                <div className="flex items-center hover:bg-purple2 px-2 border border-purple5 rounded cursor-pointer">
                  <div className="text-purple5">수정하기</div>
                  <img src="/svg/icon/write.svg" alt="modify" />
                </div>
              </Link>
            )}
          </div>
          <Input type="text" label="아이디" defaultValue={uid} disabled />
          <Input
            type="text"
            label="이름"
            name="name"
            {...register('name', {
              required: { value: true, message: '이름을 입력해주세요' },
              minLength: {
                value: 2,
                message: '이름은 2글자 이상 입력해주세요',
              },
              maxLength: {
                value: 20,
                message: '이름은 20글자 이하로 입력해주세요',
              },
              pattern: {
                value: /^[가-힣]+$/,
                message: '한글만 입력 가능합니다.',
              },
            })}
            disabled={!editable}
          />
          {errors.name && <ErrorInput text={errors.name.message} />}
          <Input
            type="text"
            label="영문명"
            name="englishName"
            placeholder="대문자만 입력해주세요"
            {...register('englishName', {
              required: { value: true, message: '영문명을 입력해주세요' },
              pattern: {
                value: /^[A-Z ]+$/,
                message: '영어 대문자만 입력 가능합니다.',
              },
            })}
            disabled={!editable}
          />
          {errors.englishName && (
            <ErrorInput text={errors.englishName.message} />
          )}
          <Input
            label="생일"
            type="date"
            {...register('birthday')}
            disabled={!editable}
          />
          {/* <Input
            type="text"
            label="생일"
            name="birthday"
            disabled={!editable}
            value={startDate.toLocaleDateString()}
            onChange={() => {}}
            onClick={() => setIsOpen(true)}
          />
          <CalendarInput
            startDate={startDate}
            setStartDate={setStartDate}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          /> */}

          {editable && (
            <>
              <Buttons
                type="filled"
                onClick={handleSubmit(onSubmit)}
                className="w-full"
                color={'purple5'}
              >
                저장
              </Buttons>
              <Buttons
                type="outlined"
                onClick={() => window.history.back()}
                className="w-full"
                color={'purple5'}
              >
                취소
              </Buttons>
            </>
          )}
        </div>
      </form>
    )
  );
};

export default StudentProfileComp;
