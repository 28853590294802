import React from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { Link, useNavigate } from 'react-router-dom';
import HFbutton from '../../../components/common/input/HFbutton';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../store/hook';
import HFinput from '../../../components/common/input/HFinput';
import instance from '../../../api/axios/utils.ts/instance';
import { openAlert } from '../../../store/features/modalSlice';
import { isAxiosError } from 'axios';

type UserPwDTO = {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
};

interface ModifyPwProps {
  isEdit?: boolean;
}
const ModifyPw = ({ isEdit = true }: ModifyPwProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserPwDTO>();
  const password = watch('newPassword');

  const onSubmit = (data: UserPwDTO) => {
    patchUserPassword(data);
  };

  const patchUserPassword = async (params: UserPwDTO) => {
    try {
      const res = await instance.patch('v1/mypage/user/password', params);
      dispatch(openAlert({ message: '비밀번호가 변경되었습니다.' }));
      navigate('/mypage/modify');
    } catch (e) {
      console.error(e);
      if (isAxiosError(e)) {
        dispatch(openAlert({ message: e.response?.data.error }));
      }
    }
  };

  return (
    <form>
      <section className="flex justify-between">
        <TextWinds type="title_h4">비밀번호</TextWinds>
        {!isEdit && (
          <Link to="/mypage/modify/pw">
            <div className="flex items-center hover:bg-purple2 px-2 border border-purple5 rounded cursor-pointer">
              <div className="text-purple5">수정하기</div>
              <img src="/svg/icon/write.svg" alt="modify" />
            </div>
          </Link>
        )}
      </section>
      {isEdit && (
        <section>
          <HFinput
            label="현재 비밀번호"
            type="password"
            name="oldPassword"
            register={register('oldPassword', {
              required: {
                value: true,
                message: '현재 비밀번호를 입력해주세요.',
              },
            })}
            error={errors.oldPassword?.message}
          />
          <HFinput
            label="새 비밀번호"
            type="password"
            name="newPassword"
            register={register('newPassword', {
              required: { value: true, message: '새 비밀번호를 입력해주세요.' },
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
                message:
                  '영문, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
              },
            })}
            error={errors.newPassword?.message}
          />
          <HFinput
            label="새 비밀번호 확인"
            type="password"
            name="newPassword2"
            register={register('newPassword2', {
              required: { value: true, message: '새 비밀번호를 입력해주세요.' },
              validate: (value) =>
                value === password || '비밀번호가 일치하지 않습니다.',
            })}
            error={errors.newPassword2?.message}
          />
          <div className="flex flex-col gap-5 mt-5 ">
            <HFbutton onClick={handleSubmit(onSubmit)}>저장</HFbutton>
            <HFbutton
              type="Outlined"
              onClick={() => navigate('/mypage/modify')}
            >
              취소
            </HFbutton>
          </div>
        </section>
      )}
    </form>
  );
};

export default ModifyPw;
