import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

export interface StorageState {
  userId: string,
  password: string,
  accessToken: string,
  expiresIn: string
  idToken: any
  refreshToken: any
  tokenType: string
}
const initialState: StorageState = {
  userId: "",
  password: "",
  accessToken: "",
  expiresIn: "",
  idToken: undefined,
  refreshToken: undefined,
  tokenType: "",
}

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    initUser: state => {
      state.userId = initialState.userId;
      state.password = initialState.password;
      state.accessToken = initialState.accessToken;
      state.expiresIn = initialState.expiresIn;
      state.idToken = initialState.idToken;
      state.refreshToken = initialState.refreshToken;
      state.tokenType = initialState.tokenType;
    },
    setUser: (state, action) => {
      state.userId = action.payload.userId;
      state.password = action.payload.password;
      state.accessToken = action.payload.accessToken;
      state.expiresIn = action.payload.expiresIn;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.tokenType = action.payload.tokenType;
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})

export const { initUser, setUser } = storageSlice.actions
export default storageSlice.reducer