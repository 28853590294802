import React from "react";
import Shape from "./images/shape-common-orange.png";
import ImageA from "./images/review5-a.png";
import ImageB from "./images/review5-b.png";
import ImageC from "./images/review5-c.png";
import ImageD from "./images/review5-d.png";
import Shape1 from "./images/shape1-orange.png";
import Shape2 from "./images/shape2-orange.png";
import Shape3 from "./images/shape3-orange.png";
import { Link } from "react-router-dom";

export const Review5 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-16 pt-12 pb-20 bg-white max-md:px-5 relative overflow-hidden">
      <img
        src={Shape1}
        width={300}
        className="absolute -left-24 top-[850px] hidden md:visible"
      />
      <img
        src={Shape2}
        width={300}
        className="absolute -right-12 top-[1900px] hidden md:visible"
      />
      <img
        src={Shape3}
        width={300}
        className="absolute -left-12 top-[2600px] hidden md:visible"
      />
      <img
        loading="lazy"
        src={Shape}
        className="max-w-full aspect-square w-[106px]"
      />
      <div className="mt-4 text-3xl font-bold leading-9 text-center text-black max-md:max-w-full">
        영어로 성경을 가르치니 선생님인 저에게도 지혜가 샘솟아요!
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-20 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10">
        <span className="text-[#ff6d2c]">
          젭스영어성경홈스쿨로 아이들에게 성경을 가르칠수록
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          저에게도 아이들을 위한 지혜가 샘솟습니다.
        </span>
        <br />
        <br />
        어떻게 하면 아이들이 더 재미있게 공부할까?
        <br />
        어떻게 복습해야 아이들이 덜 졸려 할까?
        <br />
        <br />
        제가 가르치는 아이들에게 <br />
        복음을 전해야 한다는 마음으로 고민하다 보면
        <br />
        하나님께서 생각지도 못한
        <br />
        좋은 방법과 지혜를 주시더라고요.
        <br />
        <br />
        실제로 잉스바를 공부하고 나서,
        <br />
        아이들이 즐겁고 재미있게 배운 내용을
        <br />
        복습할 수 있는 방법을 고민하다 하나님이 주신 지혜로
        <br />
        복습을 위한 좋은 자료를 만들게 되었습니다.
        <br />
        <br />
        수업 교재를 통해 배운 본문을
        <br />
        문장 단위로 잘라 순서대로 배치하며
        <br />
        배운 내용을 한 번 더 복습하는 방식입니다.
        <br />
        <br />
        <span className="text-xs leading-5">
          *잉스바: English Story Bible의 줄임말로 특허 받은 삽화기억술로
        </span>
        <br />
        <span className="text-xs leading-5">
          신·구약 48개 이야기가 들어있는 젭스영어성경홈스쿨의 암송 교재
        </span>
      </div>
      <div className="mt-16 w-full max-w-[821px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[64%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={ImageA}
              className="grow w-full aspect-[1.45] max-md:mt-5 max-md:max-w-full relative"
            />
          </div>
          <div className="flex flex-col ml-5 w-[36%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={ImageB}
              className="grow w-full aspect-[0.81] max-md:mt-5"
            />
          </div>
        </div>
      </div>
      <div className="mt-16 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10 max-md:max-w-full">
        이 복습 게임을 팀 게임 형식으로 진행하니
        <br />
        아이들도 열정적으로 게임에 임하고 팀워크까지 발휘됩니다.
        <br />
        <br />
        본문을 완성하면 큰 소리로 상대 팀의 결과물을 함께 낭송합니다.
        <br />
        자신들이 합심하여 완성한 본문을 큰 소리로 낭송할 때<br />
        아이들은 매우 기뻐합니다.
      </div>
      <img
        loading="lazy"
        src={ImageD}
        className="mt-20 max-w-full aspect-[1.45] w-[550px] max-md:mt-10 relative"
      />
      <div className="text-lg tracking-normal mt-14 leading-7 text-center text-black max-md:mt-10 max-md:max-w-full">
        아이들이 성경을 재미있어하고 즐겁게 영어로 공부하는 모습을 보면
        <br />
        선생님인 저도 참 뿌듯합니다.
        <br />
        <br />
        아이들에게 복음을 전한다는 마음 하나만 있으니
        <br />
        하나님께서 매번 지혜를 주시는 것 같습니다.
        <br />
        <br />
        <span className="text-[#ff6d2c]">
          젭스영어성경홈스쿨은 아이들 뿐 아니라
        </span>
        <br />
        <span className="text-[#ff6d2c]">
          선생님도 하나님이 주시는 은혜와 지혜 안에서 자라나게 합니다.
        </span>
        <br />
        앞으로도 저와 우리 아이들에게 부어주실 은혜와 지혜를 기대하며
        <br />
        성령 충만, 영어 능통을 위해 더욱 기도하겠습니다!
      </div>
      <img
        loading="lazy"
        src={ImageC}
        className="mt-14 max-w-full aspect-[1.49] w-[516px] max-md:mt-10 relative"
      />
      <div className="mt-24 text-3xl font-bold leading-9 text-center text-slate-900 max-md:mt-10">
        - JEMM 166기 안** 선생님의 후기 -
      </div>
      <div className="shrink-0 self-stretch mt-28 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-20 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10">
        건강한 교육은 함께 자라나는 것입니다.
        <br />
        아이들과 선생님 모두 함께 성장하고, 자라날 때<br />
        건강한 교육이 이루어 집니다.
        <br />
        <br />
        아이들을 위해 끊임없이 고민하는 선생님이 있을 때<br />
        아이들은 좋은 리더십을 따라 성장할 수 있습니다.
        <br />
      </div>
      <div className="justify-center items-center px-16 py-7 mt-14 max-w-full text-lg font-medium tracking-normal leading-6 text-center text-black bg-[#FAEEE4] w-[364px] max-md:px-5 max-md:mt-10">
        “영어로 성경을 가르치니
        <br />
        생각지도 못한 지혜가 샘솟아요”
      </div>
      <div className="mt-11 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10 max-md:max-w-full">
        젭스영어성경홈스쿨에는 성령으로 하나됨이 있고
        <br />
        말씀으로 자라남이 있습니다.
        <br />
        <br />
        젭스영어성경홈스쿨은
        <br />
        아이들뿐 아니라 선생님도, 부모님도 함께 말씀으로 자라나는 곳<br />
        영어로 말씀을 배우며 함께 성장하는 곳입니다.
      </div>
      <Link to="/courses/homeschool">
        <div className="justify-center items-center px-2.5 py-4 mt-20 max-w-full text-base font-bold tracking-normal leading-6 text-center text-purple5 bg-white rounded border border-purple5 border-solid w-[376px] max-md:px-5 max-md:mt-10">
          홈스쿨 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
