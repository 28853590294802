import React, { Fragment, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Buttons from "../../../components/common/Buttons2";
import ComInput from "../../../components/common/ComInput";
import TextWinds from "../../../components/common/TextWinds";
import ComInputSwitch from "../../../components/common/InputSwitch";
import ComInputButton from "../../../components/common/ComInputButton";
import Textarea from "../../../components/common/Textarea";
import MoLayout from "../../../components/lms/MoLayout";
import PcLayout from "../../../components/lms/PcLayout";
import "../../../styles/common/common.css";
import CheckBoxes from "../../../components/common/CheckBoxes";

const ClassSetting = () => {
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const operateList = [
        { id: "operate_1", label: "홈스쿨" },
        { id: "operate_2", label: "공부방" },
        { id: "operate_3", label: "교습소" },
        { id: "operate_4", label: "대안학교" },
        { id: "operate_5", label: "교회" },
        { id: "operate_6", label: "기타" },
    ];
    const handleCheckboxChange = (id: string) => {
        
    };

    const [selectedValue, setSelectedValue] = useState("02");

    const options = [
        { label: "02", value: "02" },
        { label: "031", value: "031" },
        { label: "032", value: "032" },
        { label: "041", value: "041" },
    ];

    const [images, setImages] = useState([
        "/images/home_moment_5.jpg",
        "/images/home_moment_5.jpg",
        "/images/home_moment_5.jpg",
    ]);

    const fileInputRef = useRef(null);
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImages((prevImages: any) => [...prevImages, e.target.result]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Fragment>
            <MoLayout title="클래스 설정" isButtonVisible={true} btnTitle="변경사항 저장하기" />
            <PcLayout
                secClassName="px-5 pt-4 pb-[120px]"
                divClassName="gap-y-0"
                topClassName="hidden xl:flex justify-between items-center mb-4"
                top={
                    <>
                        <TextWinds type="title_h3">클래스 설정</TextWinds>
                        <Buttons variant="filled" color="purple5" type="secondary">
                            <TextWinds type="button2" color="white">
                                변경사항 저장하기
                            </TextWinds>
                        </Buttons>
                    </>
                }
                bottom={
                    <>
                        <div className="mb-4 px-5 py-[14px] bg-gray1 rounded">
                            <TextWinds type="caption1" color="gray8">
                                클래스 설정 설명 영역
                            </TextWinds>
                        </div>
                        <TextWinds type="sub_title01" color="gray8" className="mb-4">
                            클래스 정보 및 설정
                        </TextWinds>
                        <div className="flex flex-col gap-y-4 mb-8 xl:flex-row gap-x-6">
                            <div className="flex flex-col gap-y-4 xl:w-full">
                                <div className="flex flex-col gap-y-2">
                                    <TextWinds type="content_body3" color="gray9">
                                        클래스 이름
                                    </TextWinds>
                                    <ComInput
                                        type="text"
                                        placeholder="클래스 이름 입력"
                                        onChange={(e) => {}}
                                    />
                                </div>
                                <div className="flex items-center gap-x-2">
                                    <TextWinds type="content_body3" color="gray9" className="min-w-[92px]">
                                        클래스 노출 여부
                                    </TextWinds>
                                    <ComInputSwitch checked={true} />
                                </div>
                                <div className="flex items-center gap-x-2">
                                    <TextWinds type="content_body3" color="gray9" className="min-w-[92px]">
                                        학생 모집 여부
                                    </TextWinds>
                                    <ComInputSwitch checked={true} />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <TextWinds type="content_body3" color="gray9" className="min-w-[92px]">
                                        클래스 소개
                                    </TextWinds>
                                    <Textarea />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <TextWinds type="content_body3" color="gray9" className="min-w-[92px]">
                                        운영 형태
                                    </TextWinds>
                                    <div className="flex w-full justify-between px-2 py-3">
                                        {operateList.map((item) => (
                                            <CheckBoxes
                                                key={item.id}
                                                id={item.id}
                                                labelValue={item.label}
                                                borderWidth={1}
                                                type="full"
                                                onChange={() => handleCheckboxChange(item.id)}
                                                className="ddd"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-4 xl:w-full">
                                <div className="flex flex-col gap-y-2">
                                    <TextWinds type="content_body3" color="gray9" className="min-w-[92px]">
                                        클래스 주소
                                    </TextWinds>
                                    <ComInputButton
                                        type="text"
                                        placeholder="우편번호 검색"
                                        onChange={(e) => {}}
                                        buttonText="우편번호 검색"
                                        variant="filled"
                                    />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <div className="flex gap-x-[6px]">
                                        <div className="border border-gray-500 rounded modify-drodown">
                                            <Dropdown
                                                value={selectedValue}
                                                options={options}
                                                onChange={(e) => setSelectedValue(e.value)}
                                                className="w-[100px] h-[50px]"
                                                placeholder="+82"
                                            />
                                        </div>
                                        <ComInput type="text" placeholder="" onChange={(e) => {}} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-y-2 mb-6">
                            <TextWinds type="sub_title01" color="gray8">
                                클래스 설정 설명 영역
                            </TextWinds>
                            <div className="">
                                <Swiper
                                    navigation={true}
                                    modules={[Navigation, Pagination]}
                                    loop={true}
                                    className="mySwiper"
                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                        type: "bullets",
                                    }}
                                >
                                    {images.map((image, idx) => (
                                        <SwiperSlide key={idx}>
                                            <img
                                                src={image}
                                                alt={`Slide ${idx}`}
                                                style={{ width: "100%", height: "300px" }}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <TextWinds type="sub_title01" color="gray8">
                                이미지 설정 (최대 5개까지 추가 가능)
                            </TextWinds>
                            <div className="flex items-center flex-col gap-y-[14px] pt-[14px] pr-[71px] pb-[23px] pl-[72px] bg-gray0 border border-gray4 rounded xl:flex-row xl:gap-x-[14px] xl:px-[18px] xl:pb-1">
                                {images.map((image, idx) => (
                                    <div key={idx} className="flex flex-col items-center gap-y-1">
                                        <div className="relative h-[124px] xl:w-[98px] xl:h-[64px]">
                                            {idx === 0 && (
                                                <div className="absolute top-[5px] left-[5px] flex items-center h-[19px] px-2.5 rounded border boder-white">
                                                    <TextWinds type="caption4" color="white">
                                                        대표
                                                    </TextWinds>
                                                </div>
                                            )}
                                            <img src={image} alt={`Image ${idx}`} />
                                        </div>
                                        <TextWinds type="content_body3" color="gray8">
                                            {idx + 1}
                                        </TextWinds>
                                    </div>
                                ))}
                                <div
                                    className="flex items-center gap-1 cursor-pointer py-1 pr-[6px]"
                                    onClick={handleUploadClick}
                                >
                                    <img src={process.env.PUBLIC_URL + "/svg/icon-plus-circle.svg"} alt="pluscircle" />
                                    <TextWinds type="button2" color="skyblue">
                                        이미지 추가하기
                                    </TextWinds>
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </>
                }
            />
            {/* <div className="bg-white">
                <div
                    style={{ width: "calc(100% - 80px)" }}
                    className="ml-[45px] mr-[35px] mt-[18px] mb-[18px] inline-flex items-center justify-between"
                >
                    <div className="text-[20px] font-bold text-gray9">클래스 설정</div>
                    <div className="inline-flex items-center gap-2">
                        <div>
                            <Buttons type="filled" color="purple5" isHeight="customize">
                                변경사항 저장하기
                            </Buttons>
                        </div>
                    </div>
                </div>
                <div className="ml-[45px] mr-[35px] h-0 shrink-0 border-[1px] border-solid border-gray4" />

                <div
                    style={{ width: "calc(100% - 80px)" }}
                    className="bg-gray1 px-5 py-3.5 ml-[45px] mr-[35px] mt-[18px] mb-[18px] inline-flex items-center justify-between"
                >
                    클래스 설명 설정 영역
                </div>

                <div className="text-lg font-semibold font-bold text-gray10 ml-[45px]">클래스 정보 및 설정</div>
                <div className="flex flex-col md:flex-row ml-[45px] mr-[35px] mt-[16px] gap-4">
                    <div className="w-full md:w-1/2 inline-flex flex-col gap-4">
                        <div>클래스 별칭 (내 주변 클래스 찾기에 표시되는 이름입니다)</div>
                        <input className="border border-gray-500 p-2.5 w-full" placeholder="클래스 이름01" />
                        <div className="inline-flex items-center justify-between w-[173px]">
                            <div>클래스 노출 여부</div>
                            <InputSwitch checked={checked1} onChange={(e) => setChecked1(e.value)} />
                        </div>
                        <div className="inline-flex items-center justify-between w-[173px]">
                            <div>학생 모집 여부</div>
                            <InputSwitch checked={checked2} onChange={(e) => setChecked2(e.value)} />
                        </div>
                        <div>운영 형태</div>
                        <div className="flex w-full justify-between">
                            {operateList.map((item) => (
                                <label key={item} className="inline-flex items-center space-x-2">
                                    <input type="checkbox" className="form-checkbox" />
                                    <span>{item}</span>
                                </label>
                            ))}
                        </div>
                        <div>클래스 소개</div>
                        <div className="border border-gray-400 h-auto p-4 gap-2.5">
                            데이터를 통해 프로덕트를 성장 시키려면 어떻게 해야 할까? 다른 실무자들은 어떤 고민을 하고
                            있을까? 어떤 시행착오를 겪었고, 이를 어떻게 해결했을까? 데이터 분석가는 아니지만, 데이터를
                            업무에 잘 활용하려면 어떤 것부터 고민해야 할까?
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 inline-flex flex-col gap-4">
                        <div>클래스 주소</div>
                        <div className="inline-flex items-center border border-gray-500 w-full">
                            <input className="p-2.5 flex-grow" placeholder="우편번호 검색" />
                            <button
                                className="bg-purple5 text-white font-bold py-1 px-4 rounded-md ml-2 whitespace-nowrap"
                                style={{ margin: "4px 8px", borderRadius: "4px" }}
                            >
                                우편번호 검색
                            </button>
                        </div>
                        <div>문의 연락처</div>
                        <div className="inline-flex items-center w-full gap-2">
                            <div className="border border-gray-500" style={{ width: "100px" }}>
                                <Dropdown
                                    value={selectedValue}
                                    options={options}
                                    onChange={(e) => setSelectedValue(e.value)}
                                    className="w-full"
                                />
                            </div>
                            <div className="border border-gray-500 flex-grow">
                                <input className="p-2.5 w-full" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{ width: "calc(100% - 80px)" }}
                    className="ml-[45px] mr-[35px] mt-[18px] mb-[18px] inline-flex flex-col"
                >
                    <div className="font-bold text-gray9">클래스 이미지</div>
                    <div style={{ width: "100%" }}>
                        <Swiper
                            navigation={true}
                            modules={[Navigation, Pagination]}
                            loop={true}
                            className="mySwiper"
                            pagination={{
                                clickable: true,
                                dynamicBullets: true,
                                type: "bullets",
                            }}
                        >
                            {images.map((image, idx) => (
                                <SwiperSlide key={idx}>
                                    <img src={image} alt={`Slide ${idx}`} style={{ width: "100%", height: "300px" }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="font-bold text-gray9 mt-[24px]">이미지 설정 (최대 5개 까지 추가 가능)</div>
                    <div className="bg-gray-100 border border-gray-300 p-4 flex md:flex-row flex-col items-center gap-[14px]">
                        {images.map((image, idx) => (
                            <div key={idx} className="flex flex-col items-center">
                                <img src={image} alt={`Image ${idx}`} style={{ width: "98px", height: "64px" }} />
                                <div>{idx + 1}</div>
                            </div>
                        ))}
                        <div
                            className="flex items-center gap-2 cursor-pointer text-blue-500"
                            onClick={handleUploadClick}
                        >
                            <i className="pi pi-plus-circle text-2xl" />
                            <span>이미지 추가하기</span>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            accept="image/*"
                        />
                    </div>
                </div>
                <div className="block md:hidden mb-[60px]" />
            </div> */}
        </Fragment>
    );
};

export default ClassSetting;
