import React from 'react';
import { Review1 } from './staticDetailPage/Review1';
import TextWinds from '../../../components/common/TextWinds';
import { useParams } from 'react-router-dom';
import { Review2 } from './staticDetailPage/Review2';
import { Review3 } from './staticDetailPage/Review3';
import { Review4 } from './staticDetailPage/Review4';
import { Review5 } from './staticDetailPage/Review5';
import { Review6 } from './staticDetailPage/Review6';
import { Review7 } from './staticDetailPage/Review7';
import { Review8 } from './staticDetailPage/Review8';

const SelectReview = (reviewId: string) => {
  switch (reviewId) {
    case '1':
      return <Review1 />;
    case '2':
      return <Review2 />;
    case '3':
      return <Review3 />;
    case '4':
      return <Review4 />;
    case '5':
      return <Review5 />;
    case '6':
      return <Review6 />;
    case '7':
      return <Review7 />;
    case '8':
      return <Review8 />;
  }
}

const ReviewDetail = () => {
  const { reviewId } = useParams();

  return (
    <div className='px-5'>
      {SelectReview(reviewId)}
    </div>
  )  
};

export default ReviewDetail;
