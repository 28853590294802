import React from 'react';
import { CircleIconBoxWithTitle, DiscountedPrice } from './WmwComp';
import monitor from '../img/circle-monitor.png';
import shield from '../img/circle-shield.png';
import notebook from '../img/circle-notebook.png';

const WmwSection13 = () => {
  return (
    <div className="bg-gradient-to-b from-20% from-[#ffffff] to-[#3700B3] flex justify-center p-3 py-10 md:p-12 md:py-20">
      <div className="w-full max-w-[520px] flex flex-col justify-center items-center gap-10 md:gap-12">
        {/* 타이틀 */}
        <div className="text-3xl md:text-4xl  text-center font-bold font-['Pretendard'] leading-tight md:leading-snug">
          교사 코스는
          <br />
          이렇게 구성되어 있어요!
        </div>

        {/* 컨텐츠 */}
        <div className="flex flex-col gap-3 md:gap-5">
          {/* 교사 메인코스 3년 */}
          <div
            className="w-full flex flex-col justify-center items-center rounded-lg overflow-hidden shadow"
            style={{ boxShadow: '0px 0px 3px 2px rgba(150,150,150,0.3)' }}
          >
            <div
              className="bg-[#31099F] w-full p-2
            text-[#FFDA19] text-center text-lg md:text-xl font-bold"
            >
              메인 교사용
            </div>

            <div
              className="bg-[#F5F4FF] w-full p-5 
              flex flex-col justify-center items-center gap-5"
            >
              <div className="flex justify-center items-center gap-5 md:gap-7">
                {/* <div className="grid grid-cols-3 w-full place-items-center gap-5 md:gap-7"> */}
                <CircleIconBoxWithTitle
                  icon={monitor}
                  circleColor="bg-[#31099F]"
                  circleSize="h-20 w-20 md:h-28 md:w-28"
                  title={
                    <div className="text-sm md:text-xl whitespace-nowrap">
                      온라인 교육과정
                    </div>
                  }
                  delay={0.1}
                />

                <CircleIconBoxWithTitle
                  icon={shield}
                  circleColor="bg-[#31099F]"
                  circleSize="h-20 w-20 md:h-28 md:w-28"
                  title={
                    <div className="text-sm md:text-xl whitespace-nowrap">
                      온라인 자료{' '}
                      <span className="font-bold text-[#31099F]">3년</span>
                    </div>
                  }
                  delay={0.3}
                />
                <CircleIconBoxWithTitle
                  icon={notebook}
                  circleColor="bg-[#31099F]"
                  circleSize="h-20 w-20 md:h-28 md:w-28"
                  title={
                    <div className="text-sm md:text-xl whitespace-nowrap">
                      교재{' '}
                      <span className="font-bold text-[#31099F]">3년치</span>
                    </div>
                  }
                  delay={0.5}
                />
              </div>

              <DiscountedPrice
                price="341,200"
                discountedPrice="289,000"
                discountColor="text-[#31099F]"
              />
            </div>
          </div>

          {/* 교사 메인코스 1년 */}
          <div
            className="w-full flex flex-col justify-center items-center rounded-lg overflow-hidden"
            style={{
              boxShadow: '0px 0px 10px 2px rgba(150,150,150,0.3 )',
            }}
          >
            <div
              className="bg-gray6 w-full p-2
          text-[#FFDA19] text-center text-lg md:text-xl font-bold"
            >
              서브 교사용
            </div>

            <div
              className="bg-[#F5F4FF] w-full p-5 
            flex flex-col justify-center items-center gap-5"
            >
              <div className="flex justify-center items-center gap-5 md:gap-7">
                <CircleIconBoxWithTitle
                  icon={monitor}
                  circleColor="bg-[#d9d9d9]"
                  circleSize="h-20 w-20 md:h-28 md:w-28"
                  title={
                    <div className="text-sm md:text-xl">온라인 교육과정</div>
                  }
                  delay={0.1}
                />

                <CircleIconBoxWithTitle
                  icon={shield}
                  circleColor="bg-[#d9d9d9]"
                  circleSize="h-20 w-20 md:h-28 md:w-28"
                  title={
                    <div className="text-sm md:text-xl">
                      온라인 자료{' '}
                      <span className="font-bold text-gray7">1년</span>
                    </div>
                  }
                  delay={0.3}
                />
                <CircleIconBoxWithTitle
                  icon={notebook}
                  circleColor="bg-[#d9d9d9]"
                  circleSize="h-20 w-20 md:h-28 md:w-28"
                  title={
                    <div className="text-sm md:text-xl">
                      교재 <span className="font-bold text-gray7">1년치</span>
                    </div>
                  }
                  delay={0.5}
                />
              </div>

              <DiscountedPrice
                price="109,000"
                discountedPrice="109,000"
                discountColor="text-gray7"
              />
            </div>
          </div>

          <div className="text-xs md:text-base text-center text-white ">
            * 본 코스의 메인교사가 최소 1명 이상인 교회만 서브교사를 추가할 수
            있습니다.
            <br />* 기간 종료 후 1년 단위 연장이 가능합니다. (연 3만원)
          </div>
        </div>

        <div className="text-xl md:text-3xl text-center text-white font-normal leading-tight md:leading-snug">
          젭스 온라인 교사대학은
          <br />
          <span className="font-bold">
            영어실력과 관계없이
            <br />
            건강한 교회에 속한 교인이라면
            <br /> 누구나 수강 가능해요!
          </span>
        </div>
      </div>
    </div>
  );
};

export default WmwSection13;
