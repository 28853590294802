import React, { useMemo, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import ClassAssignList from './classAssignment/ClassAssignList';
import {
  classGroupType,
  studentType,
} from '../../../../constants/types/student-type';
import { useAppDispatch } from '../../../../store/hook';
import { closeModal } from '../../../../store/features/modalSlice';
import {
  deleteClassForStudent,
  putClass,
} from '../../../../api/utils/lms/lmsApi';
import HFbutton from '../../../../components/common/input/HFbutton';

interface ClassAssignmentProps {
  classId: string;
  studentList?: studentType[];
  reloadTable: () => void;
}
const ClassAssignment = ({
  classId,
  studentList,
  reloadTable,
}: ClassAssignmentProps) => {
  const dispatch = useAppDispatch();

  // 학생이 1명일 경우, 해당 학생의 반 정보를 기본값으로 설정
  const defaultClassGroup: classGroupType = useMemo(() => {
    if (studentList && studentList.length === 1) {
      const classGroup = studentList[0].classInfos;
      if (classGroup.length > 0) {
        return classGroup[0].classGroup;
      } else {
        console.error('학생에게 반 정보가 없습니다.');
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [studentList]);

  const [selected, setSelected] = useState<classGroupType>(defaultClassGroup);

  const assignHandler = () => {
    if (confirm('반을 배정하시겠습니까?')) {
      putClass(classId, {
        classGroupId: selected.id,
        studentIds: studentList!.map((item) => item.id),
      }).then(() => {
        reloadTable();
        closeHandler();
      });
    }
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const deleteHandler = () => {
    if (confirm('반 배정을 해제하시겠습니까?')) {
      studentList?.map((student) => {
        student.classInfos.map((classInfo) => {
          deleteClassForStudent(
            classId,
            student.id,
            classInfo.classGroup?.id
          ).then(() => {
            reloadTable();
            closeHandler();
          });
        });
      });
    }
  };

  return (
    <div className="flex flex-col gap-2.5 w-full max-w-[360px] bg-white p-5 rounded-lg xl:mt-0 mt-[140px]">
      <section className="flex flex-row items-center justify-between px-0">
        <TextWinds type={'title_h3'}>
          {studentList.length > 1
            ? `${studentList.length}명 `
            : `'${studentList[0].name}' `}
          반 배정하기
        </TextWinds>
        <div>
          <HFbutton type="orange-Outlined" height={34} onClick={deleteHandler}>
            배정 해제
          </HFbutton>
        </div>
      </section>

      <ClassAssignList
        classId={classId}
        setSelected={setSelected}
        selected={defaultClassGroup}
      />

      <section className="flex flex-col items-start justify-start gap-[4px] p-[12px] bg-purple0 rounded-[4px]">
        <TextWinds type={'content_body4'} color="purple5">
          반 기능 안내
        </TextWinds>
        <ul className="list-outside list-disc pl-4">
          <TextWinds type={'content_body4'} color="purple5">
            <li>등록된 학생들을 특정 반에 배정할 수 있습니다.</li>
            <li>
              할 일(과제) 등록 및 관리 시 개별 학생이 아닌, 반 단위로 편하게
              관리 가능합니다.
            </li>
            <li>반 기능은 수업 운영을 위한 필수 기능은 아닙니다.</li>
          </TextWinds>
        </ul>
      </section>

      <section className="flex gap-3">
        <HFbutton type="Outlined" onClick={closeHandler} height={36}>
          취소
        </HFbutton>
        <HFbutton onClick={assignHandler} height={36}>
          완료
        </HFbutton>
      </section>
    </div>
  );
};

export default ClassAssignment;
