import React from 'react';
import { motion } from 'framer-motion';
import { openNewWindow } from '../../../../api/utils/util-func';
import {
  NeumophismAccordion,
  CircleRightArrowIcon,
  CircleCheckIcon,
  ListItem,
} from '../wmw/WmwComp';
import hfbook from './img/sec01-hfbook.png';
import HF1_1 from './img/sec08a-HF-1.png';
import HF1_2 from './img/sec08a-HF-1-2.png';
import HF2 from './img/sec08a-HF-2.png';
import HF3 from './img/sec08a-HF-3.png';
import HF3_1 from './img/sec08a-HF3-1.png';

const HF_BOOK_URL = 'https://teach.jebs2.kr/media/PPTX/ASPN/hf/';
const HF_TEACHER_MANUAL_URL =
  'https://drive.google.com/file/d/1zBzmqkk8L_77fYxzwkyVoBA9brbRD1tN/view';

const HFHJSection8a = () => {
  return (
    <div className="bg-[#F9F9F9] flex justify-center items-center p-3 py-10 md:p-12 md:pt-20">
      <div className="w-full flex flex-col place-content-center gap-5 md:gap-12">
        {/* 타이틀 */}
        <div className="text-center text-3xl md:text-4xl font-bold font-['Pretendard']">
          <span className="text-[#FE7020]">HFHJ</span> 교재 구성
        </div>

        {/* 주황박스 컨텐츠 */}
        <div className="flex justify-center rounded-2xl bg-[#FE7020] p-3">
          <div className="flex flex-col justify-center items-center gap-5 md:gap-10 max-w-[520px]">
            {/* 타이틀 */}
            <div
              className="rounded-full border border-white w-fit px-5 py-2 
            text-xl md:text-3xl font-bold text-white mt-10"
            >
              Hello, Father!
            </div>

            <div className="text-white text-xl md:text-3xl font-bold font-['Pretendard']">
              영어로 배우는{' '}
              <span className="text-[#ffda19]">구약의 6가지 사건!</span>
            </div>

            <div className="text-white text-xs sm:text-base md:text-lg font-normal font-['Pretendard'] text-center">
              창조하신 하나님, 우리를 사랑하시는 하나님,{' '}
              <br className="md:hidden" />
              죄를 미워하시는 하나님,
              <br className="hidden md:block" />
              위험에서 구해주시는 하나님,
              <br className="md:hidden" />
              유일하신 하나님, 순종하길 원하시는{' '}
              <br className="hidden md:block" />
              <span className="font-bold">
                하나님의 성품을 알아가며
                <br className="md:hidden" />
                기초적인 영어 표현까지
              </span>{' '}
              익힐 수 있습니다.
            </div>

            <section className="flex flex-col gap-5">
              {/* 그리드 영역 */}
              <section className="grid grid-cols-2 gap-3 md:gap-5">
                {/* 박스 1 */}
                <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                  <div className="relative aspect-[4/3] w-full mb-5">
                    <img
                      src={HF1_1}
                      alt="hf1_1"
                      className="absolute top-0 left-0 w-[70%] z-10"
                    />
                    <img
                      src={HF1_2}
                      alt="hf1_2"
                      className="absolute bottom-0 right-0 w-[70%] rotate-12 z-0"
                    />
                  </div>
                  <div className="flex-1 flex flex-col justify-center items-center">
                    <div className="text-center text-xs md:text-lg font-medium font-['Pretendard']">
                      <span className="text-[#FE7020] font-semibold">
                        재미있는 3박자 문장의
                        <br />
                        애니메이션과 노래
                      </span>
                      로
                      <br />
                      아이들의 시선 집중
                    </div>
                  </div>
                </div>

                {/* 박스 2 */}
                <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                  <img src={HF2} alt="hf2" className="w-[65%] m-auto" />
                  <div className="flex-1 flex flex-col justify-center items-center">
                    <div className="text-center text-xs md:text-lg font-medium font-['Pretendard']">
                      <span className="text-[#FE7020] font-semibold">
                        알파벳과 단어 쓰기
                      </span>
                      로<br />
                      기초부터 튼튼!
                    </div>
                  </div>
                </div>

                {/* 박스 3 */}
                <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                  <div className="relative aspect-[4/3] w-full mb-5">
                    <img
                      src={HF3}
                      alt="hf3"
                      className="absolute top-0 left-0 w-[70%]"
                    />
                    <img
                      src={HF3_1}
                      alt="hf3_1"
                      className="absolute -bottom-7 right-0 w-[50%]"
                    />
                  </div>
                  <div
                    className="flex-1 flex flex-col justify-center items-center
                    text-center text-xs md:text-lg font-medium font-['Pretendard'] whitespace-nowrap"
                  >
                    <div>
                      <span className="text-[#FE7020] font-semibold">
                        그림 퍼즐을 맞추기와
                        <br /> 게임과 그리기
                      </span>
                      등의
                      <br />
                      추가 활동을 통해
                      <br />
                      자연스럽게 문장 습득
                    </div>
                  </div>
                </div>

                {/* 박스 4 */}
                <div className="flex flex-col items-center rounded-xl bg-[#F9F9FF] w-full p-3 md:p-5 gap-3 md:gap-5">
                  <div className="w-full overflow-hidden">
                    <img src={hfbook} alt="hfbook" className="scale-125" />
                  </div>
                  <div className="flex flex-col justify-center gap-3 md:gap-5">
                    <div className="text-center text-xs md:text-base font-medium font-['Pretendard'] text-black">
                      <span className="text-[#FE7020] font-semibold">
                        본교재 2권 + 워크북 1권
                      </span>
                      <br />
                      + 스토리북 1권
                      <br className="md:hidden" />
                      + QR스티커
                      <br />
                      (찬양 및 워크북)
                      <br className="md:hidden" />+ 달란트 통장
                    </div>
                    <div className="text-center text-[10px] md:text-sm font-normal font-['Pretendard'] whitespace-nowrap">
                      재미있는 2권의 교재 구성과
                      <br />
                      주일학교에서 바로 활용할 수 있는
                      <br />
                      영어 찬양과 달란트 통장 제공!
                    </div>
                  </div>
                </div>
              </section>
              <div className="text-white text-center text-xs md:text-sm font-normal font-['Pretendard'] mb-5 md:mb-10">
                * 교재와 함께 선생님들을 위한 교사용 매뉴얼 함께 제공
              </div>
            </section>
          </div>
        </div>

        {/* 주황박스 하단 버튼 */}
        <section className="flex flex-col md:gap-10 gap-5 max-w-[520px] w-full m-auto">
          {/* 교사용 매뉴얼 */}
          <section>
            <NeumophismAccordion
              title={
                <div className="flex-1 text-base md:text-xl font-bold">
                  교사용 매뉴얼
                </div>
              }
            >
              <HFTeacherManual />
            </NeumophismAccordion>
          </section>

          {/* 교재 상세히 보기 */}
          <section>
            <motion.div
              className="flex gap-2 items-center justify-center p-3 bg-[#FE7020] rounded-2xl shadow-md cursor-pointer
              text-center text-white font-bold text-base md:text-xl"
              whileHover={{ opacity: 0.8 }}
              onClick={() => openNewWindow(HF_BOOK_URL)}
            >
              <div className="flex flex-col items-center">
                <span className="text-[#ffda18]">Hello, Father!</span>
                <div>교재 상세히 보기</div>
              </div>
              <CircleRightArrowIcon width={20} color="white" />
            </motion.div>
          </section>
        </section>
      </div>
    </div>
  );
};

const HFTeacherManual = () => {
  return (
    <div
      className="flex flex-col justify-center items-center bg-[#FE7020] rounded-xl p-3 md:p-5 gap-5
     text-white text-center font-normal font-['Pretendard'] text-[13px] md:text-lg "
    >
      <div
        className="rounded-full border border-[#ffda18] w-fit px-5 py-2 
        text-xl md:text-2xl text-[#ffda18] font-bold mt-10"
      >
        Hello, Father!
      </div>

      <div className="text-xl md:text-2xl font-bold">
        연간 학사 일정 & 수업 절차
      </div>

      <div>
        어떻게 가르쳐야 할까? 걱정하지 마세요.
        <br />
        선생님들을 위한 상세한 매뉴얼을 제공해 드립니다.
      </div>

      <div>
        교사용 매뉴얼에는
        <br />
        Hello, Father! 콘텐츠 안내,
        <br />
        수업 절차 안내, 수업 준비 및 운영에 필요한 자료가
        <br />
        자세히 정리되어 있습니다.
      </div>

      <div className="text-[#ffda18] font-bold">
        교사용 매뉴얼만 있으면 누구든 쉽게
        <br />
        영어로 성경을 가르칠 수 있습니다.
      </div>

      <div className="flex flex-col md:w-full">
        <div className="flex flex-col place-content-center gap-5 rounded-t-lg bg-white p-5 md:p-7 md:w-full text-black ">
          <div className="flex flex-col gap-3">
            <div className="flex justify-start items-center gap-2 font-bold">
              <CircleCheckIcon
                width="18"
                circleColor="#FE7020"
                checkColor="#FE7020"
              />
              <div className="text-xs md:text-lg">
                <span className="text-[#FE7020]">주 1회 60분씩, 40주 완성</span>{' '}
                과정 추천
              </div>
            </div>

            <div className="flex justify-start items-center gap-2 font-bold">
              <CircleCheckIcon
                width="18"
                circleColor="#FE7020"
                checkColor="#FE7020"
              />
              <div className="text-xs md:text-lg">
                <span className="text-[#FE7020]">기초 &gt; 학습 &gt; 반복</span>
                의 절차로 수업을 진행
              </div>
            </div>
          </div>

          <div className="w-full text-left text-[11px] md:text-base">
            <ul className="list-none pl-0">
              <ListItem text="Shouting & Greetings (힘찬 함성과 인사)" />
              <ListItem text="Let’s sing along (노래와 율동)" />
              <ListItem text="Vision Statement (비전 외치기)" />
              <ListItem text="The Lord’s Prayer (영어 주기도문 노래 합창)" />
              <ListItem text="Lesson Review (처음부터 배운 부분 복습)" />
              <ListItem text="Mini Sermon (짧은 설교)" />
              <ListItem text="Today’s Bible Story (본 수업 애니메이션 시청)" />
              <ListItem text="Puzzle (퍼즐을 통해 대사 습득)" />
              <ListItem text="Words & Act Out (단어를 몸으로 표현하기)" />
              <ListItem text="Play Time (노래 및 단체 활동으로 복습)" />
              <ListItem text="Dot Dot Dot & Words writing (알파벳, 단어쓰기)" />
              <ListItem text="Fun Task (그리기, 만들기, 색칠하기 등 추가 활동)" />
              <ListItem text="What’s next (다음 시간 예습)" />
              <ListItem text="Prayer & Shouting (영어로 기도, 환호성)" />
              <ListItem text="End-Class Meeting (반 별 종례)" />
              <ListItem text="Snack Time (간식 시간)" />
            </ul>
          </div>
        </div>

        <motion.div
          className="bg-[#FFDA19] rounded-b-xl w-full flex items-center justify-center gap-2 p-5 cursor-pointer"
          whileHover={{ opacity: 0.9 }}
          onClick={() => openNewWindow(HF_TEACHER_MANUAL_URL)}
        >
          <div className="text-sm md:text-lg text-[#FE7020] font-bold ">
            교사용 매뉴얼 교안 샘플 보기
          </div>
          <CircleRightArrowIcon width={18} color="#FE7020" />
        </motion.div>
      </div>
    </div>
  );
};

export default HFHJSection8a;
