import React from 'react';
import Shape from './images/shape-common-orange.png';
// import ImageA from './images/review8-a.png';
import ImageB from './images/review8-b.png';
import ImageC from './images/review8-c.png';
import ImageD from './images/review8-d.png';
import Shape1 from './images/shape1-orange.png';
import Shape2 from './images/shape2-orange.png';
import Shape3 from './images/shape3-orange.png';
import { Link } from 'react-router-dom';

export const Review8 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-16 pt-12 pb-20 text-lg leading-7 text-center text-black bg-white max-md:px-5 relative overflow-hidden">
      <img
        src={Shape1}
        width={300}
        className="absolute -left-24 top-[780px] hidden md:visible"
      />
      <img
        src={Shape2}
        width={300}
        className="absolute -right-12 top-[1900px] hidden md:visible"
      />
      <img
        src={Shape3}
        width={300}
        className="absolute -left-12 top-[2900px] hidden md:visible"
      />
      <img
        loading="lazy"
        src={Shape}
        className="max-w-full aspect-square w-[106px]"
      />
      <div className="mt-3.5 text-3xl font-bold leading-9 max-md:max-w-full">
        엄마 손 잡고 첫 수업하던 아이의 확실한 비포&애프터
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-20 tracking-normal text-black max-md:mt-10 max-md:max-w-full">
        30개월 아들을 위해 처음 시작한 젭스영어성경홈스쿨
        <br />
        공부방 수업 때문에 바빠 잘 봐주지도 못하고
        <br />늘 옆에서 엄마 수업을 듣던 아이를
        <br />
        <span className="text-[#FF6D2C]">
          어느새 하나님께서 쑥쑥 자라게 하셨어요.
        </span>
        <br />
        <br />
        처음에는 강의에서 들리는 하나님의 목소리가 무섭다며
        <br />
        엄마 손을 꼭 잡고 첫 수업을 하던 아이가
        <br />
        어느새 엄마만 들리던 발음들이 조금씩 정확해지더니
        <br />
        이젠 누구보다 자신 있게, 신나게 영어 말씀을 암송하고 있습니다.
        <br />
        <br />
        <span className="text-[#FF6D2C]">
          젭스홈스쿨에는 하나님이 함께 하시기 때문에 불가능할 것 같던 일도
        </span>
        <br />
        <span className="text-[#FF6D2C]">
          가능해지는 놀라운 은혜가 있는 것 같습니다.
        </span>
        <br />
        <br />
        이제는 젭스가 정말 재미있다고 말해주는 아이를 보며
        <br />
        학원을 보내지 않고 스스로 내 아이를 가르치는 것이 쉽지 않음에도
        <br />
        인내하고 기대하며 젭스와의 여정을 계속 이어나가고 있습니다.
        <br />
        <br />
        지금껏 가장 좋은 방향으로, 가장 선한 방법으로 인도해주신 하나님을 믿고
        <br />
        앞으로 우리 아이들이 얼마나 더 자라나고 변화할지
        <br />
        저도 함께 기도하고 노력하고 싶습니다.
        <br />
        <br />
        <span className="text-[#FF6D2C]">
          하나님의 일하심을 눈으로, 피부로 느끼게 해주는
        </span>
        <br />
        <span className="text-[#FF6D2C]">확실한 비포와 애프터가 있는</span>
        <br />
        <span className="text-[#FF6D2C]">
          젭스영어성경홈스쿨이 있어 참 다행입니다!
        </span>
        <br />
      </div>
      {/* <img
        loading="lazy"
        src={ImageA}
        className="mt-12 max-w-full aspect-[1.54] lg:w-[647px] max-md:mt-10 relative"
      />
      <div className="mt-5 text-xs leading-5">젭스 첫 수업 영상</div> */}
      <img
        loading="lazy"
        src={ImageB}
        className="mt-12 max-w-full aspect-[1.72] lg:w-[647px] max-md:mt-10 relative"
      />
      <div className="mt-5 text-xs leading-5">
        젭스홈스쿨로 영어를 배우며 조금씩 정확해지는 발음
      </div>
      <img
        loading="lazy"
        src={ImageC}
        className="mt-12 max-w-full aspect-[1.64] lg:w-[647px] max-md:mt-10 relative"
      />
      <div className="mt-5 text-xs leading-5">
        멋지게 영어로 말쓰을 암송하는 현재 모습
      </div>
      <img
        loading="lazy"
        src={ImageD}
        className="mt-32 w-full aspect-[1.39] max-w-[810px] lg:w-[647px] max-md:mt-10 max-md:max-w-full relative"
      />
      <div className="mt-32 text-3xl font-bold leading-9 text-slate-900 max-md:mt-10">
        - JEMM 169기 성**선생님의 후기 -
      </div>
      <div className="shrink-0 mt-16 max-w-full h-0.5 bg-black border-2 border-black border-solid lg:w-[795px] max-md:mt-10" />
      <div className="mt-24 tracking-normal max-md:mt-10">
        아이를 양육하는 것은 인내의 과정입니다.
        <br />
        인내를 통해 아이를 향한 믿음이 자라나고
        <br />
        아이들은 부모의 믿음만큼 더 크게, 더 높이 성장합니다.
        <br />
      </div>
      <div className="justify-center px-7 py-7 mt-14 max-w-full font-medium tracking-normal leading-6 text-black bg-[#FAEEE4] lg:w-[367px] max-md:px-5 max-md:mt-10 text-nowrap">
        “내 아이 가르치는 것이 쉽지 않지만 인내하며
        <br />
        계속해서 달려가 봅니다”
      </div>
      <div className="mt-16 tracking-normal max-md:mt-10">
        영어 한 마디 내뱉기도 어려워하던 우리 아이가
        <br />
        자신 있게 영어를 외칠 때<br />
        부모님들은 아이의 성장에 감격합니다.
        <br />
        <br />
        젭스영어성경홈스쿨에는
        <br />
        확실한 비포와 애프터가 있습니다.
        <br />
        <br />
        성경 이야기에 재미를 느끼고
        <br />
        그림으로 영어를 익히면서
        <br />
        아이들은 영어에 대한 자신감을 쌓아갑니다.
      </div>
      <Link to="/courses/homeschool">
        <div className="justify-center items-center px-2.5 py-4 mt-28 max-w-full text-base font-bold tracking-normal leading-6 text-purple5 bg-white rounded border border-purple5 border-solid lg:w-[376px] max-md:px-5 max-md:mt-10">
          홈스쿨 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
