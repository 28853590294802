import React from 'react';
import {
  LearningStatusEnum,
  myLearningChapterType,
  myLearningLessonInfoType,
} from '../../../../constants/types/learning-type';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import TextWinds from '../../../../components/common/TextWinds';
import LearningLessonItem from './LearningLessonItem';
import instance from '../../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../../constants/types';

interface LearningChapterProps {
  learningId: string;
  chapter: myLearningChapterType;
  firstOpen?: boolean;
}
const LearningChapter = ({
  learningId,
  chapter,
  firstOpen = false,
}: LearningChapterProps) => {
  const [lessonList, setLessonList] = React.useState<
    myLearningLessonInfoType[]
  >([]);
  const [openAcc, setOpenAcc] = React.useState(firstOpen);

  const handleOpenAcc = () => {
    // 처음 한번만 레슨 정보 가져오기
    if (lessonList.length === 0 && !openAcc)
      getLesson().then(() => {
        setOpenAcc((cur) => !cur);
      });
    else setOpenAcc((cur) => !cur);
  };

  const getLesson = async () => {
    try {
      const params = {
        page: 1,
        pageSize: 999,
        chapterId: chapter.id,
      };
      const response = await instance.get<
        ApiResponse<{ result: myLearningLessonInfoType[] }>
      >(`/v1/learning/${learningId}/content`, { params });
      const res = response.data;
      setLessonList(res.data.result);
    } catch (error) {}
  };

  const onStudyStart = (item: myLearningLessonInfoType) => {
    setLessonList(
      lessonList.map((lesson) => {
        if (lesson.id === item.id) {
          return {
            ...lesson,
            status: LearningStatusEnum.COMPLETED,
          };
        }
        return lesson;
      })
    );
  };

  return (
    <Accordion
      open={openAcc}
      className="border border-gray2 rounded-lg shadow-card p-4 bg-white"
    >
      <AccordionHeader onClick={handleOpenAcc} className="flex gap-4 p-0 pb-4">
        <img
          src={process.env.PUBLIC_URL + chapter.thumbnail}
          alt="arrow-down"
          className="w-20 h-20 object-cover rounded"
        />
        <div className="flex flex-col w-full">
          <TextWinds type="title_h4">{chapter.name}</TextWinds>
          <TextWinds type="content_bold" color="gray7">
            {chapter.lessonCount}개의 레슨
          </TextWinds>
        </div>
      </AccordionHeader>
      <AccordionBody>
        <div className="flex flex-col gap-4">
          {lessonList.length > 0 ? (
            lessonList.map((item) => (
              <LearningLessonItem
                learningId={learningId}
                lesson={item}
                key={item.id}
                onComplete={() => onStudyStart(item)}
              />
            ))
          ) : (
            <>강의가 없습니다.</>
          )}
        </div>
      </AccordionBody>
      <AccordionHeader
        onClick={handleOpenAcc}
        className={`p-0 pt-4 border-b-0 border-gray4 ${
          openAcc ? 'border-t' : ''
        }`}
      >
        <TextWinds type="content_bold" color="purple5" className="m-auto">
          {openAcc ? '챕터 접기' : '챕터 펼치기'}
        </TextWinds>
      </AccordionHeader>
    </Accordion>
  );
};

export default LearningChapter;
