import { motion } from 'framer-motion';
import React from 'react';
import screen01 from './img/sec06-screen01.png';
import screen02 from './img/sec06-screen02.png';
import medal from '../img/sec06-medal-dynamic-gradient.png';
import megaphone from '../img/sec06-megaphone-dynamic-gradient.png';
import puzzle from '../img/sec06-puzzle-dynamic-gradient.png';
import chat from '../img/sec06-chat-bubble-dynamic-gradient.png';
import partyPopper from '../img/sec06-partypopper.png';

const HFHJSection6 = () => {
  return (
    <>
      <div className="bg-[#FE7020] flex justify-center p-3 pt-10 md:p-12 md:pt-20">
        <div className="w-full flex flex-col gap-8 md:gap-12">
          {/* 타이틀 */}
          <section>
            <div className="text-center text-white text-3xl md:text-4xl font-light font-['Pretendard']">
              <div>정답은,</div>
              <div className="font-extrabold text-[#FFDA19] text-2xl">
                Hello, Father! Hello, Jesus!
              </div>
            </div>
          </section>

          {/* 흰색 박스 */}
          <div className="bg-white rounded-2xl p-5 md:p-10">
            <div className="max-w-[520px] m-auto">
              {/* 영역 1 */}
              <section className="flex flex-col items-center justify-center gap-5">
                <div className="text-center font-medium font-['Pretendard'] text-lg md:text-2xl">
                  아이들에게{' '}
                  <span className="text-[#FE7020] font-semibold">
                    복음과 영어는 꼭 필요
                  </span>
                  하니까!
                </div>
                <div className="flex justify-center items-center gap-2 text-base md:text-lg font-normal font-['Pretendard']">
                  <div className="rounded-md border border-[#FE7020] font-medium p-2 py-1">
                    복음
                  </div>
                  +
                  <div className="rounded-md border border-[#FE7020] font-medium p-2 py-1">
                    영어
                  </div>
                  =
                  <span className="text-[#FE7020] font-bold text-lg md:text-xl">
                    글로벌 크리스천 리더
                  </span>
                </div>
                <div className="text-sm md:text-lg text-center">
                  교회 부흥의 핵심 key는 다음 세대 아이들의 복음화! <br />
                  글로벌 크리스천 리더가 되기 위해 꼭 필요한 영어까지 <br />
                  유치부 아이들에게 꼭 필요한 것들로만 구성된 <br />
                  <span className="text-[#FE7020] font-bold">
                    Hello, Father! Hello, Jesus!
                  </span>
                </div>
              </section>

              {/* 구분선 */}
              <div className="border-t border-dashed border-[#0000001A] my-5 md:my-10"></div>

              {/* 영역 2 */}
              <section className="flex flex-col gap-3">
                <div className="text-center font-medium font-['Pretendard'] text-lg md:text-2xl">
                  아이들은{' '}
                  <span className="text-[#FE7020] font-semibold">
                    재미있어야 하니까!
                  </span>
                </div>

                <div className="grid grid-cols-2">
                  <div className="p-2 md:p-5">
                    <img src={screen01} className="w-fit m-auto p-2" />
                  </div>
                  <div className="text-left text-xs sm:text-lg font-normal font-['Pretendard'] my-auto p-1 md:p-5">
                    다양한 시청각 자료를 통해
                    <br />
                    아이들의 학습 흥미를 유발
                  </div>
                  <div className="p-2 md:p-5">
                    <img src={screen02} className="w-fit m-auto p-2" />
                  </div>
                  <div className="text-left text-[11px] sm:text-lg font-normal font-['Pretendard'] my-auto p-1 md:p-5">
                    성경 애니메이션으로
                    <br />
                    아이들의 흥미 유발
                  </div>
                </div>
              </section>

              {/* 구분선 */}
              <div className="border-t border-dashed border-[#0000001A] my-5 md:my-10"></div>

              {/* 영역 3 */}
              <section className="flex flex-col gap-5">
                <div className="text-center font-medium font-['Pretendard'] text-lg md:text-2xl">
                  영어와 성경,{' '}
                  <span className="text-[#FE7020] font-semibold">
                    확실하게 배울 수 있으니까!
                  </span>
                </div>

                <div className="flex flex-col gap-7">
                  <motion.div
                    className="flex justify-center items-center gap-3 pr-0 sm:pr-12"
                    initial={{ y: -20, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: 'spring', duration: 1 }}
                  >
                    <img
                      src={medal}
                      className="aspect-square object-contain w-24"
                    />
                    <div className="bg-gray-200 rounded-2xl shadow-md p-4 text-sm md:text-base w-full">
                      잠재의식 학습법은 유치부 아이들이 퍼즐을 맞추며 자연스럽게
                      영어문장을 잠재의식에 새길 수 있게 하는 특허받은
                      교수법입니다.
                    </div>
                  </motion.div>

                  <motion.div
                    className="flex justify-center items-center gap-3 pl-0 sm:pl-12"
                    initial={{ y: -20, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: 'spring', duration: 1 }}
                  >
                    <div className="bg-gray-200 rounded-2xl shadow-md p-4 text-sm md:text-base w-full">
                      배운 내용을 직접 신체로 표현해보며 영어 단어의 뜻과 문장을
                      자연스럽게 습득할 수 있습니다.
                    </div>
                    <img
                      src={megaphone}
                      className="aspect-square object-contain w-24"
                    />
                  </motion.div>

                  <motion.div
                    className="flex justify-center items-center gap-3 pr-0 sm:pr-12"
                    initial={{ y: -20, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: 'spring', duration: 1 }}
                  >
                    <img
                      src={puzzle}
                      className="aspect-square object-contain w-24"
                    />
                    <div className="bg-gray-200 rounded-2xl shadow-md p-4 text-sm md:text-base w-full">
                      스토리가 끝날 때마다 연극을 통해 흥미를 유발하며 배운
                      내용을 장기 기억으로 안착시킬 수 있게 합니다.
                    </div>
                  </motion.div>

                  <motion.div
                    className="flex justify-center items-center gap-3 pl-0 sm:pl-12"
                    initial={{ y: -20, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ type: 'spring', duration: 1 }}
                  >
                    <div className="bg-gray-200 rounded-2xl shadow-md p-4 text-sm md:text-base w-full">
                      유치부 아이들도 쉽게 할 수 있는 그리기, 오리기 등의 다양한
                      활동을 통해 협응력이 발달됩니다.
                    </div>
                    <img
                      src={chat}
                      className="aspect-square object-contain w-24"
                    />
                  </motion.div>
                </div>
              </section>

              {/* 구분선 */}
              <div className="border-t border-dashed border-[#0000001A] my-5 md:my-10"></div>

              {/* 영역 4 */}
              <section className="flex justify-center items-center gap-2 m-auto">
                <img src={partyPopper} className="w-12 md:w-20" />
                <div className="text-center font-medium font-['Pretendard'] text-lg md:text-2xl">
                  영어로 복음을 배우고 외치며
                  <div className="text-[#FE7020] font-semibold">
                    성령 충만! 영어 능통!
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HFHJSection6;
