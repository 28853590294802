import React, { useMemo } from 'react';
import { handleCopyClipBoard } from '../../../../api/utils/util-func';
import { useAppDispatch } from '../../../../store/hook';
import { closeModal } from '../../../../store/features/modalSlice';
import TextWinds from '../../../../components/common/TextWinds';
import HFbutton from '../../../../components/common/input/HFbutton';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

interface NewPasswordModalProps {
  type: 'REGIST' | 'RESET';
  uid: string;
  pw: string;
}
const NewPasswordModal = ({ type, uid, pw }: NewPasswordModalProps) => {
  const dispatch = useAppDispatch();

  const msgHead = useMemo(() => {
    if (type === 'REGIST') {
      return '학생등록이';
    } else {
      return '비밀번호 초기화가';
    }
  }, [type]);

  const copyHandler = (text: string) => {
    handleCopyClipBoard(text).then((result) => {
      if (result) {
        alert('복사되었습니다.');
      }
    });
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };
  return (
    <div className="flex flex-col gap-3 bg-white p-5 text-center rounded shadow-card">
      <TextWinds type="title_h2">{msgHead} 완료되었습니다.</TextWinds>
      <table className="text-left text-purple5 font-bold text-xl min-w-[230px]">
        <tr>
          <td className="text-gray7 p-1">아이디</td>
          <td
            className="flex justify-between items-center cursor-pointer rounded p-1 hover:bg-gray2"
            onClick={() => copyHandler(uid)}
          >
            {uid}
            <DocumentDuplicateIcon className="h-5 w-5 inline" />
          </td>
        </tr>
        <tr>
          <td className="text-gray7 p-1">비밀번호</td>
          <td
            className="flex justify-between items-center cursor-pointer rounded p-1 hover:bg-gray2"
            onClick={() => copyHandler(pw)}
          >
            {pw}
            <DocumentDuplicateIcon className="h-5 w-5 inline" />
          </td>
        </tr>
      </table>
      <div className="flex gap-1 mt-3">
        <HFbutton type="Outlined" height={36} onClick={() => copyHandler(pw)}>
          비밀번호 복사
        </HFbutton>
        <HFbutton height={36} onClick={closeHandler}>
          닫기
        </HFbutton>
      </div>
    </div>
  );
};

export default NewPasswordModal;
