import { useJwt } from "react-jwt"

export interface LoginFormValue {
    userId: string
    accessToken: any
    // expiresIn: string
    // idToken: any
    // refreshToken: any
    // tokenType: string
    userInfo:{
        type: string
        isActive: boolean
    }
}

export function setDatas( data: LoginFormValue ) {
    localStorage.setItem('accessToken', data.accessToken)
    localStorage.setItem('userId', data.userId)
    localStorage.setItem('type', data.userInfo.type)
    localStorage.setItem('isActive', data.userInfo.isActive? 'true' : 'false')
}

export function getDatas() {
    const userId = localStorage.getItem("userId")
    const accessToken = localStorage.getItem("accessToken")
    if( userId && accessToken ) {
        return true
    }
    return false
}

export function login(data: any) {
    setDatas(data)
}