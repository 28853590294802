import React, { useEffect, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import {
  AppProductListOrderBy,
  ProductSubType,
  ProductType,
} from '../../../../enum';
import { BoostUpTypes, LmsInfoTypes } from '../../../../constants/types';
import BoostupWidget from '../../../../components/boostup/BoostupWidget';
import { useNavigate } from 'react-router-dom';
import instance from '../../../../api/axios/utils.ts/instance';
import { IconButton } from '@material-tailwind/react';
import BoostupMenu from './boostupmenu';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const BoostUp = ({
  lmsLists,
  classId,
}: {
  lmsLists: LmsInfoTypes;
  classId: string;
}) => {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const toggleOpenNav = () => setOpenNav((cur) => !cur);
  const [openMenu, setOpenMenu] = useState(false);
  const [boostupLists, setBoostupLists] = useState<BoostUpTypes[]>([]);
  const [point, setPoint] = useState(0);
  const params = {
    product_type: ProductType.BOOSTUP,
    product_sub_type: ProductSubType.PRODUCT,
    order_by: AppProductListOrderBy.RECOMMENDED,
    courseId: lmsLists.course?.id,
  };
  useEffect(() => {
    if (lmsLists.course) {
      const fetchPoints = async () => {
        try {
          await instance.get('/v1/point').then((response) => {
            setPoint(response.data.balance);
          });
        } catch (error) {}
      };

      const fetchBoostupLists = async () => {
        try {
          await instance.get('/v1/product', { params }).then((response) => {
            setBoostupLists(response.data.result);
          });
        } catch (error) {}
      };

      fetchPoints();
      fetchBoostupLists();
    }
  }, [classId]);

  const isLoading = (): boolean => {
    if (!boostupLists) {
      return true;
    } else {
      return false;
    }
  };

  const handleNewTabs = () => {
    window.open('/cart', '_blank');
  };
  return isLoading() ? null : (
    <div className="bg-white pt-6 px-3 xl:px-8 shadow-card rounded-lg">
      <div className="relative flex items-center justify-between mb-3">
        <TextWinds type="title_h4" xlType="title_h3">
          부스트업 (코스 운영 상품)
        </TextWinds>

        <div>
          <IconButton
            size="sm"
            color="blue-gray"
            variant="text"
            className="mr-2 xl:hidden"
            onClick={toggleOpenNav}
          >
            {openNav ? (
              <XMarkIcon className="w-6 h-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="w-6 h-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
        <BoostupMenu
          openNav={openNav}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          point={point}
        />
      </div>

      <div className="p-4 rounded bg-gray1 xl:px-5 xl:py-[14px] xl:mb-[34px] mb-5">
        <TextWinds type="caption1" color="gray8">
          본 코스를 수료 후 운영 기간 중에 계신 선생님만 구매할 수 있는
          상품입니다.
        </TextWinds>
      </div>
      <div className="grid grid-cols-2 gap-x-[15px] gap-y-[23px] xl:grid-cols-5 xl:gap-x-6 xl:gap-y-6 h-auto pb-8">
        {boostupLists !== null && (
          <BoostupWidget classId={classId} datas={boostupLists} />
        )}
      </div>
    </div>
  );
};

export default BoostUp;
