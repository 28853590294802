import React, { ReactNode } from 'react';
import {
  AccordionColor,
  AsteriskLi,
  BASIC,
  GrayNumList,
  HeadBox,
  NumberingText,
  PurchaseBox,
  PurchaseButton,
  SnowFlower,
} from './HomeschoolComp';
import Img1 from './img/section4b1-a.png';
import Img2 from './img/section4b1-b.png';
import Img3 from './img/section4b1-c.png';
import Img4 from './img/section4b1-z.png';
import Img5 from './img/section4b1-d.png';
import esb from './img/basic-esb.png';
import ef from './img/basic-ef.png';
import ec from './img/basic-ec.png';
import book from './img/basic-book.png';
import online1 from './img/section4b4-a.png';
import online2 from './img/section4b4-b.png';
import online3 from './img/section4b4-c.png';
import online4 from './img/section4b4-d.png';
import online5 from './img/section4b4-e.png';
import TextWinds from '../../common/TextWinds';
import { motion } from 'framer-motion';

const HomeschoolBASIC = () => {
  return (
    <div>
      <div className="w-full bg-[#fff8dd]">
        <div className="flex flex-col gap-10 items-center text-black py-20 px-5 max-w-[520px] m-auto">
          <SnowFlower color="#ff9b00" width={24} opacity={1} />
          <div className="text-3xl md:text-4xl text-center mt-3">
            정철의 A.D. 영어학습법
            <div className="font-bold">젭스홈스쿨 대표 코스</div>
          </div>
          <div className="bg-[#ff9b00] text-white py-3 w-full rounded-lg text-center font-bold text-xl">
            BASIC | 4년
          </div>
          {/* 1. 바이블 스토리 암송 */}
          <div className="flex flex-col gap-5 w-full rounded-lg bg-white p-3 py-5">
            <NumberingText number={1} text="바이블 스토리 암송" />
            <img
              src={Img1}
              alt="kids-bible"
              className="w-full px-3 h-[150px] object-contain"
            />
            <div>
              <TextWinds type="title_h2">
                잉스바 (English Story Bible)
              </TextWinds>
              <div className="text-gray6">
                특허받은 ‘삽화기억술’로 자연스러운 연상 기억법이 가능, 정철의
                A.D.학습법 적용으로 영어문장을 청크 단위로 손쉽게 암송!
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-white text-base font-semibold">
              <div className="bg-[#ff9b00] p-1 rounded">
                총 48권 ( 구약 24권 + 신약 24권 )
              </div>
              <div className="bg-gray6 p-1 rounded">젭스펜 적용</div>
            </div>
            <div className="flex text-[4.5px] text-gray8 bg-gray2 p-2 rounded">
              <ol className="list-decimal list-inside flex-1">
                <li>In the Beginning</li>
                <li>Heaven or Hell</li>
                <li>Noah's Ark</li>
                <li>Language</li>
                <li>Abraham and Isaac</li>
                <li>Joseph Sold as a Slave</li>
                <li>Brothers Bowed Down to Joseph</li>
                <li>Moses in the Desert</li>
                <li>Ten Plagues and the Passover</li>
                <li>The Red Sea</li>
                <li>Food from Heaven</li>
                <li>Ten Commandments</li>
                <li>Twelve Spies</li>
                <li>The Jordan and Jericho</li>
                <li>Stolen Treasure</li>
                <li>Judges</li>
              </ol>
              <ol
                className="list-decimal list-inside border-l border-gray4 pl-2 flex-1"
                start={17}
              >
                <li>Gideon and Samson</li>
                <li>Saul and David</li>
                <li>David and Goliath</li>
                <li>King David and His Songs</li>
                <li>Solomon’s Wisdom</li>
                <li>Fire from Heaven</li>
                <li>Jonah in the Big Fish</li>
                <li>Daniel and the Hungry Lions</li>
                <li>An Angel Visits Mary</li>
                <li>Jesus Is Born</li>
                <li>Jesus and John</li>
                <li>Jesus,Devil,and Twelve Apostles</li>
                <li>Jesus Teaches and Heals</li>
                <li>Water Turns Into Wine</li>
                <li>The Lord's Prayer</li>
                <li>Take Your Mat and Walk! Go Wash Your Eyes!</li>
              </ol>
              <ol
                className="list-decimal list-inside border-l border-gray4 pl-2 flex-1"
                start={33}
              >
                <li>Teaching on a Mo untainside (1) </li>
                <li>Teaching on a Mountainside (2)</li>
                <li>Jesus Heals the Sick and Calms the Storm</li>
                <li>Jesus Feeds 5,000</li>
                <li>The Good Samaritan</li>
                <li>Jesus' Appearance Is Changed</li>
                <li>The Story of the Lost Son</li>
                <li>Jesus Raises Lazarus</li>
                <li>Jesus Enters Jerusalem</li>
                <li>
                  Jesus Cleans the Temple · Mary Pours Perfume · Judas Betrays
                  Jesus
                </li>
                <li>The Last Supper 1</li>
                <li>The Last Supper 2</li>
                <li>Jesus Is Arrested</li>
                <li>Jesus Dies</li>
                <li>Jesus Rises Again</li>
                <li>Jesus Goes Up to Heaven</li>
              </ol>
            </div>
          </div>
          {/* 2. 회화 */}
          <div className="flex flex-col gap-5 w-full rounded-lg bg-white p-3 py-5">
            <NumberingText number={2} text="회화" />
            <img
              src={Img2}
              alt="kids-bible"
              className="w-full px-3 h-[150px] object-contain"
            />
            <div>
              <TextWinds type="title_h2">잉포 (English Formula 198)</TextWinds>
              <div className="text-gray6 text-sm">
                일상에서 꼭 필요한 관용적 회화 표현과 초등과정의 필수 생활회화
                198개 구문을 스토리로 습득
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-white text-base font-semibold">
              <div className="bg-[#ff9b00] p-1 px-3 rounded">총 24권</div>
              <div className="bg-gray6 p-1 px-3 rounded">젭스펜 적용</div>
            </div>

            <div className="flex text-[6px] text-gray8 bg-gray2 p-2 rounded">
              <ol className="list-decimal  list-inside flex-1">
                <li>Greetings and Meeting</li>
                <li>Going to a Birthday Party</li>
                <li>Scheduling an Appointment</li>
                <li>Seeing Animals at the Zoo</li>
                <li>Going to an Ice Cream Shop</li>
                <li>Going for a Bike Ride</li>
                <li>Shopping at the Mall</li>
                <li>Preparing Dinner at Home</li>
                <li>Making New Friends</li>
                <li>Watching a Movie at Home</li>
                <li>Going to the Hospital</li>
                <li>Talking About Teeth</li>
              </ol>
              <ol
                className="list-decimal list-inside border-l border-gray4 pl-2 flex-1"
                start={13}
              >
                <li>Talking About Family</li>
                <li>Going to a Restaurant</li>
                <li>Playing Baseball</li>
                <li>Going to an Art Museum</li>
                <li>Vegetable Gardening</li>
                <li>Going Downtown</li>
                <li>Going to an Amusement Park</li>
                <li>Going to an Aquarium</li>
                <li>Making up with a Friend</li>
                <li>Doing Household Chores</li>
                <li>Talking About Eyesight</li>
                <li>Planning a Family Trip Abroad</li>
              </ol>
            </div>
            <div className="w-full border-t border-dotted"></div>
            <img
              src={Img3}
              alt="kids-bible"
              className="w-full px-3 h-[150px] object-contain"
            />
            <div>
              <TextWinds type="title_h2">
                잉포플 (English Formula Plus)
              </TextWinds>
              <div className="text-gray6 text-sm">
                스토리로 연결된 만화로 즐거운 회화학습, 기능별/상황별 응용 표현
                및 숙어 192개 습득
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-white text-base font-semibold">
              <div className="bg-[#ff9b00] p-1 px-3 rounded">총 24권</div>
              <div className="bg-gray6 p-1 px-3 rounded">젭스펜 적용</div>
            </div>
            <div className="flex text-[6px] text-gray8 bg-gray2 p-2 rounded">
              <ol className="list-decimal  list-inside flex-1">
                <li>Getting to Know Each Other</li>
                <li>Inviting a Friend Over</li>
                <li>Hanging Out in the Park</li>
                <li>Telephone Conversation: Making an Appointment</li>
                <li>Talking About the Weekend</li>
                <li>Ordering Fast Food</li>
                <li>Going Bike Riding</li>
                <li>Seeing a Doctor</li>
                <li>Shopping for Clothes</li>
                <li>Appreciating Art Pieces</li>
                <li>Going on a Field Trip to the Mud Flats</li>
                <li>Experiencing Real-life Jobs</li>
              </ol>
              <ol
                className="list-decimal list-inside border-l border-gray4 pl-2 flex-1"
                start={13}
              >
                <li>Reporting an Emergency</li>
                <li>Describing a Bad Day</li>
                <li>Going Grocery Shopping</li>
                <li>Going to the Library</li>
                <li>Going to the Pharmacy</li>
                <li>Going Camping</li>
                <li>Getting a Haircut</li>
                <li>Giving Tips for a School Play Tryout</li>
                <li>Talking About Winter Break Plans</li>
                <li>Taking Passport Photos</li>
                <li>Checking In at the Airport</li>
                <li>On the Plane</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-5 w-full rounded-lg bg-white p-3 py-5">
            <NumberingText number={3} text="문법" />
            <img
              src={Img4}
              alt="kids-bible"
              className="w-full px-3 h-[150px] object-contain"
            />
            <div>
              <TextWinds type="title_h2">잉크 (English Creative)</TextWinds>
              <div className="text-gray6 text-sm">
                어순감각에 맞춰 문장을 만들어 말하는 Grammar 청크 기본기 완성
                프로그램
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-white text-base font-semibold">
              <div className="bg-[#ff9b00] p-1 px-3 rounded">총 24권</div>
            </div>
            <div className="flex flex-col text-[10px] text-gray8 bg-gray2 p-2 rounded">
              <div className="font-bold">*ENGLISH CREATIVE 창조적 언어학습</div>
              <div className="flex flex-col gap-0.5 mt-2">
                <div>
                  <span className="font-bold mr-1">Level 1</span> 기본적인 영어
                  문장(주어+동사)의 감각을 습득하는 단계
                </div>
                <div>
                  <span className="font-bold mr-1">Level 2</span> 기본문을
                  의문문,부정문,진행형으로 바꿔 말하는 단계
                </div>
                <div>
                  <span className="font-bold mr-1">Level 3</span> 일상에서
                  자주하게 되는 부탁,제안,명령문을 말하는 단계
                </div>
                <div>
                  <span className="font-bold mr-1">Level 4</span> 기본문에
                  다양한 표현을 붙여 문장을 확장하는 단계
                </div>
                <div>
                  <span className="font-bold mr-1">Level 5</span> 영어식
                  어순감각을 익혀 문장을 자유롭게 말하는 단계
                </div>
                <div>
                  <span className="font-bold mr-1">Level 6</span> 문장과 문장을
                  연결하여 말하는 단계
                </div>
              </div>
            </div>
            <div className="w-full border-t border-dotted"></div>
            <img
              src={Img5}
              alt="kids-bible"
              className="w-full px-3 h-[150px] object-contain"
            />
            <div>
              <TextWinds type="title_h2">잉코 (English Correct)</TextWinds>
              <div className="text-gray6 text-sm">
                정확한 영어 말하기를 위한 작은 단위의 문법부터 시작하는
                중고등학교 시험대비 프로그램
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-white text-base font-semibold">
              <div className="bg-[#ff9b00] p-1 px-3 rounded">총 24권</div>
            </div>
            <div className="flex flex-col text-[10px] text-gray8 bg-gray2 p-2 rounded">
              <div className="font-bold">*ENGLISH CORRECT 정확한 언어학습</div>
              <div className="flex flex-col gap-0.5 mt-2">
                <div>
                  <span className="font-bold mr-1">Level 1</span> 문장의 기본을
                  이루는 명사와 동사
                </div>
                <div>
                  <span className="font-bold mr-1">Level 2</span> 문장을
                  풍부하게 해 주는 대명사,관사,형용사
                </div>
                <div>
                  <span className="font-bold mr-1">Level 3</span> 본격적인 청크
                  연습! 부사,전치사,to부정사,ing
                </div>
                <div>
                  <span className="font-bold mr-1">Level 4</span>{' '}
                  과거분사,문장의 형식,시제,조동사
                </div>
                <div>
                  <span className="font-bold mr-1">Level 5</span>{' '}
                  비교급,관계사와 접속사
                </div>
                <div>
                  <span className="font-bold mr-1">Level 6</span> 가정법과 기타
                  구문
                </div>
              </div>
            </div>
          </div>

          {/* KIDS 권장 수업 시간 */}
          <div className="w-full">
            <div className="font-bold w-fit m-auto text-lg">
              <BASIC /> 권장 수업 시간
            </div>
            <div className="w-full mt-3 drop-shadow-lg">
              <div className="w-full">
                <div className="flex text-white text-center w-full font-bold text-sm">
                  <div className="w-[180px] bg-[#ff9b00] py-2 rounded-tl-lg border-r border-dotted border-[#fff1dc]">
                    교재
                  </div>
                  <div className="flex-1 bg-[#ff9b00] py-2 rounded-tr-lg">
                    권장 수업 시간
                  </div>
                </div>
                <div className="flex text-xs text-[#583b3b] text-center font-semibold">
                  <div className="w-[180px] bg-[#fff1dc] px-2  rounded-bl-lg">
                    <div className="py-3 border-b border-[#f9bf64]">
                      잉스바
                      <br /> (English Story Bible)
                    </div>
                    <div className="py-3 border-b border-[#f9bf64]">
                      잉포
                      <br />
                      (English Formula)
                    </div>
                    <div className="py-3">
                      잉크
                      <br />
                      (English Creative/Correct)
                    </div>
                  </div>
                  <div className="flex-1 bg-white px-3 rounded-br-lg">
                    <div className="py-3 border-b border-gray3 leading-2">
                      레슨 당{' '}
                      <span className="text-[#ff9b00] font-bold">50분</span>
                      <br />주 2회 권장
                    </div>
                    <div className="py-3 border-b border-gray3 leading-2">
                      레슨 당{' '}
                      <span className="text-[#ff9b00] font-bold">15분</span>{' '}
                      <br />주 2회 권장
                    </div>
                    <div className="py-3">
                      레슨 당{' '}
                      <span className="text-[#ff9b00] font-bold">35분</span>{' '}
                      <br />주 2회 권장
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5 w-full max-w-[520px] py-10 px-5">
        <div className="text-2xl text-center font-semibold">
          <BASIC />
          코스가
          <div className="font-extrabold">좋은 이유 5가지</div>
        </div>
        <GrayNumList
          textArr={[
            '그림만 보고도 암송이 가능한 특허받은 삽화기억술 적용',
            'Rhythm&Stress, 어순감각 등 영어의 기본원리 체득',
            '초등 교과 과정 & 일상 회화 구문 포함',
            '국내 최초 Creative Speech 기반의 A.D. 학습법 적용',
            '완벽한 3박자 BASIC 트라이앵글 교재 구성',
          ]}
        />
        <div className="grid grid-cols-3 gap-3 text-center">
          <div>
            <div className="bg-[#ff7d7d] text-white font-bold rounded-t-lg p-2">
              잉스바
            </div>
            <div className="bg-[#fff1f1] text-[#464009] rounded-b-lg py-2 sm:py-5">
              바이블 스토리
              <br />
              낭송/암송
            </div>
          </div>
          <div>
            <div className="bg-[#946bf1] text-white font-bold rounded-t-lg p-2">
              잉포
            </div>
            <div className="bg-[#f6f2ff] text-[#464009] rounded-b-lg py-2 sm:py-5">
              관용적,일상
              <br />
              회화 연습
            </div>
          </div>
          <div>
            <div className="bg-[#53b1f5] text-white font-bold rounded-t-lg p-2">
              잉크
            </div>
            <div className="bg-[#e4faff] text-[#464009] rounded-b-lg py-2 sm:py-5">
              청크 중심의
              <br />
              문법 훈련
            </div>
          </div>
        </div>

        <AccordionColor
          textArea={
            <div className="font-bold text-center">[BASIC 교재] 미리보기</div>
          }
          color={'#ff9b00'}
        >
          <div className="flex flex-col gap-3 mt-3">
            <a
              href="https://teach.jebs2.kr/media/PPTX/home_school/book1/ESB_Lesson_05/?code=157"
              target="_blank"
            >
              <img src={esb} />
            </a>
            <a
              href="https://teach.jebs2.kr/media/PPTX/home_school/book2/EF_Lesson_05/?code=157"
              target="_blank"
            >
              <img src={ef} />
            </a>
            <a
              href="https://teach.jebs2.kr/media/PPTX/home_school/book2/EC_Lesson_09/?code=157"
              target="_blank"
            >
              <img src={ec} />
            </a>
            <a
              href="https://teach.jebs2.kr/media/PPTX/home_seminar/"
              target="_blank"
            >
              <img src={book} />
            </a>
          </div>
        </AccordionColor>
      </div>
      <div className="flex flex-col gap-5 bg-[#f4f4f4] py-10 px-5">
        <div className="text-2xl text-center font-semibold">
          <BASIC /> <span className="font-extrabold">멤버십 비용</span>
        </div>

        <PurchaseBox
          head={'1년'}
          textArr={[
            <>
              온라인 교사 교육
              <div className="text-xs text-gray6 font-normal">
                (최초 수료 강의 + 연 2회 강화 교육)
              </div>
            </>,
            'BASIC 교재 총 12세트',
            '온라인 서비스 1년 & 교사용 메뉴얼',
            '젭스펜 1개',
            <>
              암송인증제 스타터팩{' '}
              <div className="text-xs text-gray6 font-normal">
                (스티커보드/패스포트/응시권)
              </div>
            </>,
          ]}
          button={
            <>
              <div className="text-[#d6d0ff]">멤버십 1년 </div>
              1,390,000원 <span className="text-base">(월 115,833원)</span>
            </>
          }
          link="/courses/homeschool/details/F3j1H7AOUmL6Rxpb?palnItems=true"
        />
        <PurchaseBox
          head={'2년'}
          textArr={[
            <>
              온라인 교사 교육
              <div className="text-xs text-gray6 font-normal">
                (최초 수료 강의 + 연 2회 강화 교육)
              </div>
            </>,
            'BASIC 교재 총 24세트',
            '온라인 서비스 2년 & 교사용 메뉴얼',
            '젭스펜 1개',
            <>
              암송인증제 스타터팩{' '}
              <div className="text-xs text-gray6 font-normal">
                (스티커보드/패스포트/응시권)
              </div>
            </>,
          ]}
          button={
            <>
              <div className="text-[#d6d0ff]">멤버십 2년 </div>
              1,970,000원 <span className="text-base">(월 82,000원)</span>
            </>
          }
          link="/courses/homeschool/details/DAvFDd_N0nbFgISL?palnItems=true"
        />
        <HeadBox
          head={
            <div className="w-fit px-9 py-1 font-bold bg-[#ff373f] text-white rounded-full">
              연장
            </div>
          }
        >
          <div className="p-5">
            <div className="grid grid-cols-2 text-center font-semibold text-xl">
              <div className="pr-5">
                <div className="text-base h-24 leading-[6rem]">
                  교재 연장 패키지
                </div>
                <PurchaseButton>
                  <div className="relative w-full text-center py-2 text-white font-extrabold leading-4 pt-3">
                    <div className="w-fit bg-[#ff6363] absolute top-[-4px] px-2 py-1 left-1/2 transform -translate-y-full -translate-x-1/2 text-xs rounded text-nowrap">
                      교재 12세트
                      <div className="absolute bottom-0 left-1/2 transform translate-y-full -translate-x-1/2 w-0 h-0 border-t-[6px] border-r-transparent border-r-[6px] border-l-transparent border-l-[6px] border-t-[#ff6363]"></div>
                    </div>
                    648,000원
                    <br />
                    <span className="text-[#d6d0ff] text-base font-semibold">
                      (월 54,000원)
                    </span>
                  </div>
                </PurchaseButton>
              </div>
              <div className="flex flex-col justify-between border-l pl-5">
                <div className="mt-6 text-base">
                  온라인 서비스
                  <br />
                  연장(1년)
                </div>
                <PurchaseButton>
                  <div className="w-full text-center py-2 text-white font-extrabold leading-4 pt-3">
                    360,000원
                    <br />
                    <span className="text-[#d6d0ff] text-base font-semibold">
                      (월 30,000원)
                    </span>
                  </div>
                </PurchaseButton>
              </div>
            </div>
            <div className="pt-5 text-gray6 text-center text-xs md:text-base">
              * 교재 12세트 구매 시, 온라인 서비스는 무료 연장됩니다.{' '}
            </div>
          </div>
        </HeadBox>
        <HeadBox head={<></>}>
          <ul className="p-5 list-none text-gray6 text-sm space-y-1 text-[#868686] font-medium">
            <AsteriskLi>
              해당 멤버십은 BASIC 과정만 이용할 수 있습니다.
            </AsteriskLi>
            <AsteriskLi>
              목사님, 사모님, 선교사님은 교역자 할인이 적용되오니,
              <br /> 젭스 본사로 별도 문의를 부탁 드립니다.
            </AsteriskLi>
          </ul>
        </HeadBox>
      </div>
      <div className="relative flex flex-col items-center overflow-hidden bg-[#ececec]">
        <div className="w-full max-w-[480px]">
          <section className="flex flex-col items-center p-5 pt-[80px]">
            <SnowFlower color="black" opacity={1} width={24} />
            <div className="text-3xl md:text-4xl text-center mt-3 text-white text-[#3f3a3a]">
              <span className="text-[#8f8f8f]">BASIC </span>
              <span className="">코스 </span>
              <span className="font-bold">온라인 서비스</span>
            </div>
            <div className="mt-3 text-center">
              <span className="font-bold">BASIC 코스</span>는 교재와 함께 다양한
              온라인 서비스를 활용하여
              <br /> 더욱 <span className="font-bold">풍성한 수업을 진행</span>
              할 수 있어요!
            </div>
          </section>
          <section className="flex w-full py-20 pr-10">
            <div className="relative bg-[#808080] w-[1px] mx-5">
              <div className="absolute bottom-[-2px] left-1 w-[1px] bg-[#808080] h-3 rotate-45"></div>
            </div>
            <div className="flex-1 mt-[-12px] text-white text-sm">
              <Card
                head="교사용 자료"
                title={
                  <>
                    수업진행을 위한 필수자료로 구성되어 있어 절차대로만
                    <br /> 진행하면 수업이 완성되는 탄탄한 교사용 웹자료
                  </>
                }
                url={online1}
              />
              <Dash />
              <Card
                head="수업동영상"
                title={
                  <>
                    교재 별 젭스 강사의 각 과목 수업 영상 <br />
                    (*잉스바는 정철 직강 제공)
                  </>
                }
                url={online2}
              />
              <Dash />
              <Card
                head="젭스온"
                title={
                  <>
                    <div className="font-bold">
                      듣기/말하기/쓰기 영역별 온라인 복습 시스템
                    </div>
                    <ol className="list-disc ml-7">
                      <li>교재 연동 온라인 복습 과제와 시험 배포 가능</li>
                      <li>클래스 및 학생 관리 LMS 시스템 제공</li>
                    </ol>
                  </>
                }
                url={online3}
              />
              <Dash />
              <Card
                head="홈이"
                title="베이직 코스의 3과목을 온라인 학습 가이드에 따라 학생 스스로 학습할 수 있도록 만든 콘텐츠 (3년 차까지만 제공)"
                url={online4}
              />
              <Dash />
              <Card
                head="젭스암송인증제"
                title={
                  <ol className="list-disc ml-7">
                    <li>국내 최초 영어암송인증제</li>
                    <li>AI 음성인식을 통해 학생의 암송 실력 인증</li>
                    <li>단계별 뱃지,인증서를 통한 동기부여 역할</li>
                  </ol>
                }
                url={online5}
              />
              <div className="h-20"></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
const Card = ({
  head,
  title,
  url,
}: {
  head: string;
  title: ReactNode;
  url: string;
}) => (
  <div className="relative text-base md:text-lg font-bold">
    {/* historyLine의 점 */}
    <div className="absolute left-[-24px] top-3 h-2 w-2 bg-[#808080] rounded-full"></div>

    {/* case */}
    <motion.div
      className="bg-[#808080] rounded-full w-fit px-3 py-0.5"
      initial={{ x: -20 }}
      whileInView={{ x: 0 }}
    >
      {head}
    </motion.div>
    {/* title */}
    <motion.div
      className="mt-2 text-[#1f1f1f] font-normal text-sm"
      initial={{ y: -20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
    >
      {title}
    </motion.div>

    <div className="mt-5 rounded-xl overflow-hidden w-full aspect-video">
      <img src={url} className="w-full h-full object-cover" />
    </div>
  </div>
);
const Dash = () => (
  <div className="my-8 border-t border-[#808080] border-dashed"></div>
);
export default HomeschoolBASIC;
