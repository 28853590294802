import React from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import HFbutton from '../common/input/HFbutton';
import PurchaseSummary, { PurchaseSummaryType } from './PurchaseSummary';
import TextWinds from '../common/TextWinds';
interface PurchaseFinalModalProps {
  purchaseInfo: PurchaseSummaryType;
  pgUrl: string;
  closeAction: () => void;
  purchaseAction: (pgUrl: string) => void;
}
const PurchaseFinalModal = ({
  purchaseInfo,
  pgUrl,
  closeAction,
  purchaseAction,
}: PurchaseFinalModalProps) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
    closeAction();
  };

  const onPurchase = () => {
    dispatch(closeModal());
    purchaseAction(pgUrl);
  };

  return (
    <div className="flex flex-col gap-3 p-5 bg-white w-full max-w-[320px] rounded">
      <TextWinds type="title_h3">구매 확인</TextWinds>
      <PurchaseSummary
        productAmount={purchaseInfo.productAmount}
        deliveryCharge={purchaseInfo.deliveryCharge}
        discountAmount={purchaseInfo.discountAmount}
        pointAmount={purchaseInfo.pointAmount}
      />
      <HFbutton onClick={onPurchase}>구매하기</HFbutton>
      <HFbutton type="Outlined" onClick={onClose}>
        닫기
      </HFbutton>
    </div>
  );
};

export default PurchaseFinalModal;
