import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit"
import { CommonResProps, OptionProps } from "../../constants/types"
import { RootState } from "..";
import { AxiosParamType } from ".";
import instance from "../../api/axios/utils.ts/instance";

interface ItemType {
    status: string,
    optionLists: CommonResProps<OptionProps[]>,
    selectOptions: [],
}
const initialState: ItemType = {
    status: '',
    optionLists: {
        page: null,
        result: null,
    },
    selectOptions: []
}

export const getShopOptionInfo = createAsyncThunk<CommonResProps<OptionProps[]>, AxiosParamType>(
    'asyncThunk/getShopOptionInfo',
    async ({ url }: AxiosParamType) => {
    try {
        const response = await instance.get(url);
        return response.data;
    } catch(error) {
        console.error('[axios] getShopLists : ', error)
        return isRejectedWithValue(error)
    }
});

export const optionSlice = createSlice({
    name: 'option',
    initialState,
    reducers: {
        // setInitOption: (state, action) => {
        //     state.itemLists = action.payload
        // },
        setSelectedOptions: (state, action) => {
            state.selectOptions = action.payload
        },
    },
    extraReducers(builder) {
        builder
            // Option 조회
            .addCase(getShopOptionInfo.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getShopOptionInfo.fulfilled, (state, action: PayloadAction<CommonResProps<OptionProps[]>>) => {
                state.status = 'fulfilled';
                state.optionLists = action.payload;
            })
            .addCase(getShopOptionInfo.rejected, (state) => {
                state.status = 'rejected';
            })
    }
})
export const { setSelectedOptions } = optionSlice.actions
export const selectOptionLists = (state: RootState) => state.option
export default optionSlice.reducer