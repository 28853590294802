import React, { useState, Fragment, useContext, useMemo } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { Switch } from '@material-tailwind/react';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse, User } from '../../../constants/types';
import { MypageContext } from '../membership/MypageContext';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';

enum checkListEnum {
  agreementMarketing = 'agreementMarketing',
}

type CheckItem = {
  id: checkListEnum;
  checked: boolean;
};

const MypageSetting = () => {
  const dispatch = useAppDispatch();
  const { userInfo, setMemberInfo } = useContext(MypageContext);

  const [checkList, setCheckList] = useState<CheckItem[]>([]);

  useMemo(() => {
    setCheckList([
      {
        id: checkListEnum.agreementMarketing,
        checked: userInfo?.agreementMarketing,
      },
    ]);
  }, [userInfo]);

  const checkHandler = (id: checkListEnum, value: boolean) => {
    putUserInfo(id, value).then((data) => {
      if (data) {
        setMemberInfo(data);
        dispatch(openAlert({ message: '수신 설정이 변경되었습니다.' }));
      }
    });
  };

  const putUserInfo = async (id: checkListEnum, checked: boolean) => {
    try {
      const res = await instance.put<ApiResponse<User>>('v1/mypage/user', {
        [id]: checked,
      });
      return res.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <section className="px-5 pb-[65px] lg:p-0">
        <div className="mb-[30px] lg:mb-6">
          <TextWinds type="title_h2">설정</TextWinds>
        </div>
        <div className="flex flex-col gap-y-8">
          <Fragment>
            <div>
              <div className="mb-3">
                <TextWinds type="sub_title01">권한 설정</TextWinds>
              </div>
              {/* <div className="flex justify-between items-center py-4 border-t border-b">
                                <TextWinds type="content_body2">푸시 알림</TextWinds>
                                <InputSwitch
                                    checked={checked1}
                                    onChange={(e) => setChecked1(e.value)}
                                    className="switch_sm"
                                />
                            </div> */}
            </div>
          </Fragment>
          <Fragment>
            <div>
              {/* <div className="mb-3">
                                <TextWinds type="sub_title01">활동 알림</TextWinds>
                            </div> */}
              {/* <div className="flex justify-between items-center py-4 border-t border-b">
                                <div>
                                    <TextWinds type="content_body2">푸시 알림</TextWinds>
                                    <TextWinds type="caption1">댓글, 공감 알림</TextWinds>
                                </div>
                                <InputSwitch
                                    checked={checked2}
                                    onChange={(e) => setChecked2(e.value)}
                                    className="switch_sm"
                                />
                            </div> */}
              {/* <div className="flex justify-between items-center py-4 border-b">
                                <div>
                                    <TextWinds type="content_body2">학습 알림</TextWinds>
                                    <TextWinds type="caption1">수업 일정 알림</TextWinds>
                                </div>
                                <InputSwitch
                                    checked={checked3}
                                    onChange={(e) => setChecked3(e.value)}
                                    className="switch_sm"
                                />
                            </div> */}
              {/* <div className="flex justify-between items-center py-4 border-b">
                                <div>
                                    <TextWinds type="content_body2">중요 알림</TextWinds>
                                    <TextWinds type="caption1">공지 알림</TextWinds>
                                </div>
                                <InputSwitch
                                    checked={checked4}
                                    onChange={(e) => setChecked4(e.value)}
                                    className="switch_sm"
                                />
                            </div> */}
              {checkList.map((item) => (
                <div
                  key={item.id}
                  className="flex justify-between items-center py-4 border-b"
                >
                  <div>
                    <TextWinds type="content_body2">마케팅 정보 수신</TextWinds>
                    {/* <TextWinds type="caption1">
                      마케팅 정보 수신해제 2021-7-13
                    </TextWinds> */}
                  </div>
                  <Switch
                    checked={item.checked ?? false}
                    onChange={() => checkHandler(item.id, !item.checked)}
                    className="h-full w-full checked:bg-purple5 cursor-pointer"
                    containerProps={{
                      className: 'w-11 h-6',
                    }}
                    circleProps={{
                      className: 'before:hidden left-0.5 border-none',
                    }}
                  />
                </div>
              ))}
            </div>
          </Fragment>
        </div>
      </section>
    </>
  );
};

export default MypageSetting;
