import React from 'react';
import StudentClassItem from './StudentClassItem';
import instance from '../../../api/axios/utils.ts/instance';
import { myLearningInfoType } from '../../../constants/types/student-type';

const StudentClassList = () => {
  const [classList, setClassList] = React.useState<myLearningInfoType[]>([]);

  const getClasses = async () => {
    try{
      const result = await instance.get('/v1/student/class');
      if (result.data.statusCode === 200) {
        const data = result.data.data;
        setClassList(data.myLearningInfo);
      }
    }catch(e){
      console.error(e);
    }
  };

  React.useEffect(() => {
    getClasses();
  }, []);

  return (
    <div className="grid grid-cols-1 gap-8 m-auto px-4 md:grid-cols-2 xl:grid-cols-3 justify-items-center">
      {classList?.map((item) => (
        <StudentClassItem key={item.myLearning.id} data={item} />
      ))}
    </div>
  );
};

export default StudentClassList;
