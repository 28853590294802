export function numberWithCommas(number: number) {
  return number.toLocaleString();
}

export function dateResize(number: number) {
  if (number % 365 === 0) return number / 365 + '년';

  return number + '일';
}

export function phoneFormat(phone?: string) {
  if (!phone) return '';
  return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

export const regPhone = /^0(?:2|[0-9]{2})(?:[0-9]{3,4})[0-9]{4}$/;

export const dateToString = (date: Date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return `${year}. ${month}. ${day}`;
};
