import { createAsyncThunk, createSlice, isRejectedWithValue } from "@reduxjs/toolkit";
import instance from "../../api/axios/utils.ts/instance";
import { AxiosParamType } from ".";
import { RootState } from "..";

interface ItemType {
  status: string,
  points: { balance: number },
}
const initialState: ItemType = {
  status: '',
  points: { balance: 0 }
}
export const pointSlice = createSlice({
  name: 'point',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPoint.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getPoint.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.points = action.payload
      })
      .addCase(getPoint.rejected, (state) => {
        state.status = 'rejected'
      })
  }
})
export const getPoint = createAsyncThunk(
  'asyncThunk/getPoint',
  async ({ url, params }: AxiosParamType) => {
    try {
      const response = await instance.get(url, { params });
      return response.data;
    } catch (error) {
      console.error('[axios] getPoint : ', error)
      return isRejectedWithValue(error)
    }
  }
)

export const selectPoint = (state: RootState) => state.point.points
export default pointSlice.reducer