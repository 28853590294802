import React, { useMemo } from 'react';
import { ProductSubType } from '../../enum';
import FullWidthPage from '../layout/FullWidthPage';
import TextWinds from '../common/TextWinds';
import CourseList from './CourseList';
import CourseNaviBar from './CourseNaviBar';

interface CourseWidgetProps {
  product_sub_type: ProductSubType;
}
const CourseWidget = ({ product_sub_type }: CourseWidgetProps) => {
  const { title, description } = useMemo(() => {
    switch (product_sub_type) {
      case ProductSubType.HOMESCHOOL:
        return {
          title: '홈스쿨 코스',
          description: '말씀으로 끝장보는 우리 아이 영어교육 솔루션!',
        };
      case ProductSubType.WORSHIP:
        return {
          title: '주일학교 코스',
          description: '땅끝복음 준비하는 우리 교회 부흥 솔루션!',
        };
      case ProductSubType.SMALL_GROUP:
        return {
          title: '소그룹 코스',
          description: '전도를 위한 복음 준비부터 영어성경 일독에서 암송까지!',
        };
      case ProductSubType.LEARN_ON_PHONE:
        return {
          title: '화상영어 코스',
          description: '바이블 중심 1:1 원어민 화상영어',
        };
      default:
        return {
          title: '젭스 코스',
          description: '지금 바로 시작할 수 있는 코스',
        };
    }
  }, [product_sub_type]);

  return (
    <FullWidthPage>
      <section className="px-[20px] mt-12 flex flex-col gap-2 pt-10">
        <TextWinds type="title_h2" xlType="title_h1">
          {title}
        </TextWinds>
        <TextWinds type="title_h4" color="purple5">
          {description}
        </TextWinds>
      </section>

      <section className="pt-[25px] xl:pt-[48px]">
        <CourseList productSubType={product_sub_type} />
      </section>

      <CourseNaviBar />
    </FullWidthPage>
  );
};

export default CourseWidget;
