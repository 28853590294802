import { useState } from 'react';
import { numberWithCommas } from '../../api/utils/format/numbers';
import { openAlert } from '../../store/features/modalSlice';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import Inputs from '../common/Inputs';
import TextWinds from '../common/TextWinds';
import Buttons from '../common/Buttons2';
import { selectPoint } from '../../store/features/pointSlice';

interface MemberShopInfoProps {
  originPrice: number;
  setPointAmount: (value: number) => void;
}
/**
 *
 * @param originPrice 상품금액
 * @param setPointAmount 포인트 적용
 * @returns
 */
const MemberShipInfo = ({
  originPrice,
  setPointAmount,
}: MemberShopInfoProps) => {
  const dispatch = useAppDispatch();
  // 단위 금액
  const STANDARD_POINT = 1000;
  // 사용하려는 포인트
  const [redeemPoints, setRedeemPoints] = useState(0);
  const userPoints = useAppSelector(selectPoint);
  const points = userPoints?.balance || 0;

  // 포인트 계산 (단위 버림 처리)
  const floor = (value: number) => {
    return Math.floor(value / STANDARD_POINT) * STANDARD_POINT;
  };

  // 알림창
  const Alert = (msg: string) => {
    dispatch(
      openAlert({
        message: msg,
      })
    );
  };

  // 포인트 입력
  const handleInputChange = (value: string) => {
    const point: number = Number(value.replace(/[^0-9]/g, '') || 0);
    setRedeemPoints(point);
  };

  // 포인트 적용 [ 아래 함수와 합쳐서 로직 단순화 필요 ]
  const handleApplyEvent = (redeemPoints: number) => {
    const point = redeemPoints > points ? points : redeemPoints;
    if (usedPointCheck(point)) {
      setRedeemPoints(floor(point));
      setPointAmount(floor(point));
      if (point > 0) {
        Alert(`${numberWithCommas(floor(point))} 포인트가 적용 되었습니다.`);
      } else {
        Alert(`포인트가 해제 되었습니다.`);
      }
    }
  };

  // 사용 포인트 체크 [ 위 함수와 합쳐서 로직 단순화 필요 ]
  const usedPointCheck = (redeemPoints: number) => {
    if (redeemPoints === 0) {
      return true;
    } else if (STANDARD_POINT > redeemPoints) {
      Alert(
        `포인트는 ${numberWithCommas(
          STANDARD_POINT
        )} 포인트 이상부터 사용할 수 있습니다.`
      );
      setRedeemPoints(0);
      setPointAmount(0);
      return false;
    } else if (originPrice < redeemPoints) {
      Alert(`포인트는 상품금액보다 적게 사용할 수 있습니다.`);
      setRedeemPoints(floor(originPrice));
      setPointAmount(floor(originPrice));
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="mt-[16px] py-[14px] px-[20px] bg-white xl:rounded-[8px]">
      <div className="flex flex-row items-end">
        <TextWinds type="title_h4"> 포인트</TextWinds>
        <TextWinds type="caption1">
          ( {numberWithCommas(STANDARD_POINT)}원 단위로 사용가능하며, 반드시
          적용버튼을 눌러주세요.)
        </TextWinds>
      </div>
      <div className=" mt-[16px]">
        <Inputs
          // title="포인트"
          type="text-button"
          buttonText="적용"
          placeholder=""
          value={numberWithCommas(redeemPoints)}
          idDbCheck={redeemPoints >= 0}
          onChange={handleInputChange}
          onBlur={() => handleApplyEvent(redeemPoints)}
          onClick={() => handleApplyEvent(redeemPoints)}
          maxValue={points}
        />
        <div className="flex flex-row items-center justify-start gap-[8px]">
          <div className="pt-2 flex gap-1">
            <TextWinds type="content_rel">보유 포인트</TextWinds>
            <TextWinds type="content_body4">
              {points ? numberWithCommas(points) : 0}점
            </TextWinds>
          </div>

          <div className="pt-[8px]">
            <Buttons
              variant="filled"
              type="text"
              color="purple5"
              disabled={points > 0 ? false : true}
              onClick={() => handleApplyEvent(points)}
              className="rounded-md px-[6px] py-1"
            >
              <TextWinds type="content_rel" color="white">
                최대사용
              </TextWinds>
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberShipInfo;
