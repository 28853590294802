import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import HFbutton from '../common/input/HFbutton';

export interface ModalConfirmProps {
  message: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}
const ModalConfirm = ({
  message,
  cancelText = '취소',
  confirmText = '확인',
  onConfirm: onclick,
  onCancel,
}: ModalConfirmProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler);
    return () => {
      document.removeEventListener('keydown', keyboardHandler);
    };
  }, []);

  const keyboardHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeBtnhandler();
    } else if (event.key === 'Enter') {
      confirmBtnhandler();
    }
  };

  const closeBtnhandler = () => {
    dispatch(closeModal());
    onCancel && onCancel();
  };
  const confirmBtnhandler = () => {
    dispatch(closeModal());
    onclick();
  };
  return (
    <div className="flex flex-col gap-4 w-[335px] p-4 bg-white shadow-card mb-40 rounded-md mountAnimation">
      <div className="flex flex-col">
        {message.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-2">
        <HFbutton type="Outlined" onClick={closeBtnhandler} height={40}>
          {cancelText}
        </HFbutton>
        <HFbutton onClick={confirmBtnhandler} height={40} isFocus>
          {confirmText}
        </HFbutton>
      </div>
    </div>
  );
};

export default ModalConfirm;
