import React from 'react';
import HFinput from './HFinput';
import { useForm } from 'react-hook-form';
import HFbutton from './HFbutton';
import HFinputPhone from './HFinputPhone';
import HFselect from './HFselect';

type HFsampleProps = {
  name: string;
  alias: string;
  phone: string;
  gender: string;
};

const HFsample = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HFsampleProps>();
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  let doc = document.documentElement;
  // 전체화면 설정
  function openFullScreenMode() {
    if (doc.requestFullscreen) doc.requestFullscreen();
    // else if (doc.webkitRequestFullscreen)
    //   // Chrome, Safari (webkit)
    //   doc.webkitRequestFullscreen();
    // else if (doc.mozRequestFullScreen)
    //   // Firefox
    //   doc.mozRequestFullScreen();
    // else if (doc.msRequestFullscreen)
    //   // IE or Edge
    //   doc.msRequestFullscreen();
  }

  // 전체화면 해제
  function closeFullScreenMode() {
    if (document.exitFullscreen) document.exitFullscreen();
    // else if (document.webkitExitFullscreen)
    //   // Chrome, Safari (webkit)
    //   document.webkitExitFullscreen();
    // else if (document.mozCancelFullScreen)
    //   // Firefox
    //   document.mozCancelFullScreen();
    // else if (document.msExitFullscreen)
    //   // IE or Edge
    //   document.msExitFullscreen();
  }

  const fullScreenHandler = () => {
    if (isFullScreen) {
      closeFullScreenMode();
    } else {
      openFullScreenMode();
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <>
      <form className="p-5">
        <HFinput />
        {/* <HFcheckbox labelId='check' label='체크박스' {...register('name')}/> */}
        <HFinput
          label="라벨명"
          register={register('name', {
            required: { value: true, message: '필수 입력 항목입니다' },
            minLength: { value: 3, message: '3자 이상으로 입력해주세요' },
          })}
          error={errors['name']?.message}
          className="h-[40px] px-0 py-0"
          placeholder="플레이스 홀더2"
        />
        <HFinput
          label="별칭"
          height={40}
          register={register('alias', {
            required: { value: true, message: '필수 입력 항목입니다' },
            minLength: { value: 3, message: '3자 이상으로 입력해주세요' },
          })}
          error={errors['alias']?.message}
          className="h-[40px] px-0 py-0"
          placeholder="플레이스 홀더"
        />
        <HFinputPhone
          label="전화번호"
          name="phone"
          placeholder="숫자만 입력"
          register={register('phone', {
            required: { value: true, message: '필수 입력 항목입니다' },
            minLength: { value: 11, message: '11자 이상으로 입력해주세요' },
          })}
          error={errors['phone']?.message}
        />
        <HFselect
          register={register('gender')}
          placeholder="성별 선택"
          className="mb-5"
          data={[
            { value: 'MALE', label: '남자' },
            { value: 'FEMALE', label: '여자' },
          ]}
        />

        <HFbutton onClick={handleSubmit((data) => {})}>로그인</HFbutton>
        <div className="grid grid-cols-2 gap-x-4">
          <HFbutton onClick={() => {}}>회원가입</HFbutton>
          <HFbutton onClick={() => {}} type="Filled" disabled>
            회원가입
          </HFbutton>
          <HFbutton type="Outlined">회원가입</HFbutton>
          <HFbutton onClick={() => {}} type="Outlined" disabled>
            회원가입
          </HFbutton>
          <HFbutton type="orange-Filled">회원가입</HFbutton>
          <HFbutton type="orange-Filled" disabled>
            회원가입
          </HFbutton>
          <HFbutton type="orange-Outlined">회원가입</HFbutton>
          <HFbutton type="orange-Outlined" disabled>
            회원가입
          </HFbutton>
          <HFbutton onClick={fullScreenHandler}>fullscreen</HFbutton>
        </div>
      </form>
    </>
  );
};

export default HFsample;
