import React from 'react';
import StudentClassList from './StudentClassList';


const StudentClassPage = () => {
  return (
    <div className='bg-[#f4f9ff] py-16'>
        <div className='max-w-[1176px] m-auto'>
            <StudentClassList/>
        </div>
    </div>
  );
};

export default StudentClassPage;
