import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LmsStudentType } from '../../../constants/types/lms-type';
import { getStudentDetail, putStudentDetail } from './func';
import TextWinds from '../../../components/common/TextWinds';
import HFbutton from '../../../components/common/input/HFbutton';
import { useFieldArray, useForm } from 'react-hook-form';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import FormRow from '../../../components/common/input/FormRow';
import ErrorInput from '../../student/components/ErrorInput';

const StudentDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [oldStudnetData, setOldStudentData] = useState<LmsStudentType>();
  const { classId, studentId } = useParams<{
    classId: string;
    studentId: string;
  }>();
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<LmsStudentType>({
    defaultValues: {
      name: '',
      uid: '',
      parents: [
        {
          name: '',
          phone: '',
          parentType: '',
        },
      ],
    },
  });
  const uid = watch('uid');
  const phone = watch('phone');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parents',
  });

  const init = () => {
    getStudentDetail(classId, Number(studentId)).then((data) => {
      setOldStudentData(data);
      reset(data);
      setIsEdit(false);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const daumPostHook = useDaumPostcodePopup();
  const daumPostHandleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValue('address', fullAddress);
    setValue('zipCode', data.zonecode);
  };
  const daumPostHandleClick = () => {
    if (!isEdit) return;
    daumPostHook({ onComplete: daumPostHandleComplete });
  };

  const submitHandler = (data: LmsStudentType) => {
    putStudentDetail(classId, Number(studentId), data).then((res) => {
      if (res.statusCode === 200) {
        init();
        dispatch(openAlert({ message: '수정이 완료되었습니다.' }));
      } else {
        console.error(res);
      }
    });
  };

  const cancelHandler = () => {
    reset(oldStudnetData);
    setIsEdit(false);
  };

  return (
    uid && (
      <div>
        <form className="flex flex-col gap-5 p-5 bg-white shadow-card rounded-0 xl:rounded-lg m-0 xl:mx-5 max-w-[500px] mb-20">
          <section className="flex flex-col gap-5 border-b border-gray2 py-5">
            <TextWinds type="title_h3" xlType="title_h3">
              학생 상세 보기
            </TextWinds>
          </section>
          <section className="border-b border-gray2">
            <div className="flex justify-between">
              <TextWinds type="title_h3" xlType="title_h3">
                상세정보
              </TextWinds>
              <div className="flex gap-2">
                {isEdit ? (
                  <>
                    <HFbutton
                      type="Outlined"
                      onClick={() => cancelHandler()}
                      height={36}
                    >
                      취소
                    </HFbutton>
                    <HFbutton
                      onClick={() => {
                        handleSubmit(submitHandler)();
                      }}
                      height={36}
                    >
                      저장
                    </HFbutton>
                  </>
                ) : (
                  <HFbutton onClick={() => setIsEdit(true)} height={36}>
                    수정하기
                  </HFbutton>
                )}
              </div>
            </div>
            <div className="mt-4">
              <div className="flex gap-x-2">
                <div className="flex flex-col w-full">
                  <div className={`text-sm mb-2 leading-snug`}>학생 연락처</div>
                  <FormRow
                    type="phone"
                    name="phone"
                    control={control}
                    placeholder="- 없이 번호만 입력"
                    rules={{
                      pattern: {
                        value: /^01[016789]-?[0-9]{3,4}-?[0-9]{4}$/,
                        message: '올바른 휴대폰 번호를 입력하세요',
                      },
                    }}
                    disabled={!isEdit}
                    className={isEdit ? '' : 'text-gray6'}
                    autoComplete="off"
                  />
                  <div className="h-2 mb-4">
                    {errors['phone'] && (
                      <ErrorInput text={errors['phone'].message} />
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className={`text-sm mb-2 leading-snug`}>이메일</div>
                  <FormRow
                    type="text"
                    name="email"
                    control={control}
                    placeholder="이메일 주소 입력"
                    rules={{
                      pattern: {
                        value:
                          /^[a-zA-Z0-9]+[!#$%&'*+-/=?^_`(){|}~]*[a-zA-Z0-9]*@[\w]+\.[a-zA-Z0-9-]+[.]*[a-zA-Z0-9]+$/,
                        message: '올바른 형식의 메일을 입력하세요',
                      },
                    }}
                    disabled={!isEdit}
                    className={isEdit ? '' : 'text-gray6'}
                    autoComplete="off"
                  />
                  <div className="h-2 mb-4">
                    {errors['email'] && (
                      <ErrorInput text={errors['email'].message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2 w-full">
                {/* <TextWinds type="content_body3" color="gray9">
                  주소
                </TextWinds> */}
                <div>
                  <div className={`text-sm mb-2 leading-snug`}>주소</div>
                  <div className="relative flex-col mb-6 w-full">
                    <FormRow
                      type="text-button"
                      name="address"
                      control={control}
                      placeholder="주소 입력"
                      textButtonProp={{
                        text: '주소 검색',
                        action: daumPostHandleClick,
                        disabled: !isEdit,
                      }}
                      disabled
                      className={isEdit ? '' : 'text-gray6'}
                    />
                  </div>
                </div>
                <div>
                  <div className={`text-sm mb-2 leading-snug`}>우편번호</div>
                  <div className="relative flex-col mb-6 w-full">
                    <FormRow
                      type="text"
                      name="zipCode"
                      control={control}
                      placeholder="우편번호"
                      disabled
                      className={isEdit ? '' : 'text-gray6'}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-x-2">
                <div className="flex flex-col w-full">
                  <div className={`text-sm mb-2 leading-snug`}>학교명</div>
                  <div className="relative flex-col mb-6 w-full">
                    <FormRow
                      type="text"
                      name="schoolName"
                      control={control}
                      placeholder="학교명 입력"
                      disabled={!isEdit}
                      className={isEdit ? '' : 'text-gray6'}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className={`text-sm mb-2 leading-snug`}>반</div>
                  <div className="relative flex-col mb-6 w-full">
                    <FormRow
                      type="text"
                      name="schoolGroup"
                      control={control}
                      placeholder="반 입력"
                      disabled={!isEdit}
                      className={isEdit ? '' : 'text-gray6'}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <TextWinds type="title_h3" xlType="title_h3">
              보호자 정보
            </TextWinds>
            <div className="flex flex-col gap-y-2.5 mt-5">
              {fields.length === 0 && !isEdit ? (
                <TextWinds
                  type="content_body2"
                  className="m-auto text-gray6 mb-4"
                >
                  등록된 보호자가 없습니다
                </TextWinds>
              ) : (
                fields.map((item, index) => (
                  <div key={index} className="bg-gray1 p-4 rounded">
                    <div className="text-right h-5">
                      {isEdit && (
                        <span
                          className="p-1 text-sm font-bold text-purple5 hover:text-purple6 cursor-pointer"
                          onClick={() => remove(index)}
                        >
                          삭제하기
                        </span>
                      )}
                    </div>
                    <div className="grid grid-cols-2 gap-x-2 mb-6">
                      <div>
                        <TextWinds type="content_body3" className="mb-2">
                          보호자명
                        </TextWinds>
                        <FormRow
                          type="text"
                          name={`parents.${index}.name`}
                          control={control}
                          placeholder="보호자 성함 입력"
                          disabled={!isEdit}
                          className={isEdit ? '' : 'text-gray6'}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <TextWinds type="content_body3" className="mb-2">
                          보호자 연락처
                        </TextWinds>
                        <FormRow
                          type="phone"
                          name={`parents.${index}.phone`}
                          control={control}
                          placeholder="- 없이 번호만 입력"
                          disabled={!isEdit}
                          className={isEdit ? '' : 'text-gray6'}
                          // rules={{
                          //   pattern: {
                          //     value: /^01[016789]-?[0-9]{3,4}-?[0-9]{4}$/,
                          //     message: '올바른 휴대폰 번호를 입력하세요',
                          //   },
                          // }}
                          autoComplete="off"
                        />
                        {/* <div className="h-2">
                          {errors['parents'] && (
                            <ErrorInput text={errors['parents'].message} />
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div>
                      <TextWinds type="content_body3" className="mb-2">
                        성별
                      </TextWinds>
                      <div className="relative flex-col mb-3 w-full">
                        <FormRow
                          type="gender"
                          name={`parents.${index}.parentType`}
                          control={control}
                          disabled={!isEdit}
                        ></FormRow>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {isEdit && (
                <div
                  className="flex items-center gap-2 p-2.5 cursor-pointer justify-center"
                  onClick={() =>
                    append({
                      name: undefined,
                      phone: undefined,
                      parentType: undefined,
                    })
                  }
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/svg/icon-plus-circle-purple.svg'
                    }
                    alt="pluscircle"
                  />
                  <TextWinds type="button2" color="purple5">
                    보호자 추가하기
                  </TextWinds>
                </div>
              )}
            </div>
          </section>
        </form>
      </div>
    )
  );
};

export default StudentDetail;
