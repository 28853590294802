import React from "react";
import Shape from "./images/shape-common-orange.png";
import ImageA from "./images/review7-a.png";
import ImageB from "./images/review7-b.png";
import ImageC from "./images/review7-c.png";
import ImageD from "./images/review7-d.png";
import Shape1 from "./images/shape1-orange.png";
import Shape2 from "./images/shape2-orange.png";
import Shape3 from "./images/shape3-orange.png";
import { Link } from "react-router-dom";

export const Review7 = () => {
  return (
    <div className="flex flex-col items-center px-0 md:px-16 pt-12 pb-20 bg-white max-md:px-5">
      <img
        src={Shape1}
        width={300}
        className="absolute -left-24 top-[780px] hidden md:visible"
      />
      <img
        src={Shape2}
        width={300}
        className="absolute -right-12 top-[1900px] hidden md:visible"
      />
      <img
        src={Shape3}
        width={300}
        className="absolute -left-12 top-[2900px] hidden md:visible"
      />
      <img
        loading="lazy"
        src={Shape}
        className="max-w-full aspect-square w-[106px]"
      />
      <div className="mt-3.5 text-3xl font-bold leading-9 text-center text-black max-md:max-w-full">
        젭스홈스쿨을 통해 영어로 말씀을 공부하며 비전을 품는 대안학교
      </div>
      <div className="shrink-0 self-stretch mt-10 h-0.5 bg-black border-2 border-black border-solid max-md:mt-10 max-md:max-w-full" />
      <div className="mt-16 text-lg tracking-normal leading-7 text-center text-slate-900 max-md:mt-10 max-md:max-w-full">
        저는 초등학생, 중학생, 고등학생 다양한 나이대의
        <br />
        9명의 소수의 아이들로 구성된 대안학교를 운영중인 선생님입니다.
        <br />
        저희 대안학교는 공통적으로 말씀과 언어 중심으로 교육하는 학교입니다.
        <br />
        <br />
        이미 대안학교를 졸업하고 대학생이 된 아이들도 있습니다.
        <br />이 아이들이 초등학생일 때 저는 대안학교 학생들을 위한
        <br />
        영어 교육을 위해 여러 방법을 시도했었습니다.
        <br />
        <br />
        여러 도서로 아이들을 교육했었지만,
        <br />
        어린 아이들이 흥미를 가지고 오래 공부하기는 쉽지 않았습니다.
        <br />
        그러던 와중 우연히 젭스를 알게 되었고 설명회에 신청하여 참여하게
        되었습니다.
        <br />
        영어 학습의 목적이 영어로 복음을 전하기 위한 것이라는 점, <br />또
        말씀을 영어로 암송하는 방법이 저희 학교의 방향과 똑같다는 점에 놀라{" "}
        <br />그 자리에서 신청을 하게 되었습니다.
        <br />
        <br />
        이때까지 제가 찾았던 영어 교육 방식이였기에 주저함이 없었습니다.
        <br />
        그리고 그 주저함 없던 결단은 확신으로 돌아왔습니다.
        <br />
        당시 유치원생, 초등학생이였던 학생들은 키즈 코스를, <br />
        중학생 고등학생이였던 학생들은 베이직 코스을 진행하였습니다.
        <br />
        시간이 흘러 당시 중학생이였던 학생들은 고등학생이 되었고, <br />
        어느새 베이직 코스를 수료하게 되었습니다!
        <br />
        <br />
        일단 결론부터 말씀드리면, <br />
        젭스홈스쿨을 통한 아이들의 영어실력은 <br />
        고등학교 검정고시 영어과목을 별다른 공부 없이 통과할 정도의 실력이
        되었습니다.
        <br />
        기초 영어도 되어있지 않던 아이들이 다른 공부 없이 젭스의 내용대로
        충실하게 말씀을 외우고, <br />
        회화를 연습하고, 청크로 끊어서 잉크를 연습하다 보니 정말 자연스럽게
        영어를 하게 되었습니다.
        <br />
        <br />
        아이들이 수료하며 남긴 후기들을 보면, 그 은혜가 더욱 크게 다가옵니다.
        <br />
        <br />
      </div>
      <div className="self-stretch mt-32 max-md:pr-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={ImageA}
              className="grow w-full aspect-[1.43] max-md:mt-6"
            />
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={ImageB}
              className="grow w-full aspect-[1.67] max-md:mt-6"
            />
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={ImageC}
              className="grow w-full aspect-[1.33] max-md:mt-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-16 text-lg tracking-normal leading-7 text-center text-slate-900 max-md:mt-10 max-md:max-w-full">
        아이들에게도 그런 은혜가 있었지만 <br />
        <span className="text-[#FF6D2C]">
          가르치는 선생님인 저에게도 젭스를 가르치며 말씀과 가까워지는 시간이
          되었습니다.{" "}
        </span>
        <br />
        성경을 쭉 배우며 하나님이 어떠한 분이신지 아이들도 배우고 저도 배우는
        시간이 되었습니다. <br />
        아이들과 함께 그 말씀들을 통하여 삶을 돌아보기도 하고, <br />
        도전을 얻기도 하며 은혜를 나누는 시간도 젭스홈스쿨만의 특별한
        은혜였습니다.
        <br />
        <br />
        돌이켜 보면 젭스를 만나게 하신 하나님께 <br />
        감사드리는 일 밖에 할 수 있는 일이 없는 것 같습니다. <br />
        <span className="text-[#FF6D2C]">
          그저 좋은 프로그램을 만나 저는 있는 내용을 전달만 해주었고,{" "}
        </span>
        <br />
        <span className="text-[#FF6D2C]">
          아이들도 성실히 영어말씀을 외우고 연습한 일 밖에 없었습니다.
        </span>
        <br />
        <br />
        수업 전에 날마다 <br />
        <span className="text-slate-900">
          &quot;성경을 영어로 암송하면 지혜가 열리고 영어가 터진다!&quot;
        </span>{" "}
        라는 구호를 믿음으로 선포했더니
        <br />
        어느새, 아이들의 실력이 일취월장해 있는 모습을 보게 되었습니다.
        <br />
        <br />
        <span className="text-[#FF6D2C]">
          저는 젭스홈스쿨로 대안학교를 운영하며
        </span>
        <br />
        <span className="text-[#FF6D2C]">
          세상 방법이 아닌, 하나님의 말씀으로 아이들을 교육하는 것이{" "}
        </span>
        <br />
        <span className="text-[#FF6D2C]">
          얼마나 옳은 방법인지 보게 되었습니다.
        </span>
        <br />
        <br />
        무엇보다 하나님께 너무 감사드리고, <br />
        좋은 프로그램을 연구하시고 만들어주신 정철 선생님과 데이빗 정 대표님,{" "}
        <br />또 많은 직원분들께 감사합니다!
        <br />
      </div>
      <img
        loading="lazy"
        src={ImageD}
        className="mt-24 w-full aspect-[1.72] max-md:mt-10 max-md:max-w-full"
      />
      <div className="mt-32 text-3xl font-bold leading-9 text-center text-slate-900 max-md:mt-10">
        - JEMM 184기 김** 선생님의 후기 -
      </div>
      <div className="shrink-0 mt-16 max-w-full h-0.5 bg-black border-2 border-black border-solid w-full max-md:mt-10" />
      <div className="mt-20 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10 max-md:max-w-full">
        대안학교가 가지고 있는 장점을 살려
        <br />
        대안학교 아이들만이 할 수 있는 공부 방법으로 공부를 하면
        <br />
        기대하지 못했던 아이들의 더욱 빛나는 모습을 볼 수 있습니다.
        <br />
      </div>
      <div className="justify-center px-10 py-4 mt-24 max-w-full text-lg font-medium tracking-normal leading-6 text-center text-black bg-[#FAEEE4] max-md:px-5 max-md:mt-10">
        “세상 방법이 아닌, 하나님의 말씀으로 아이들을 교육하는 것이 얼마나 옳은
        방법인지 <br />
        보게 되었습니다.”
      </div>
      <div className="mt-20 text-lg tracking-normal leading-7 text-center text-black max-md:mt-10">
        영어로 말씀을 가르치며
        <br />
        진짜 아이들에게 필요한 교육,
        <br />
        진짜 아이들에게 가르쳐야 할 것을
        <br />
        분별할 수 있습니다.
        <br />
        <br />
        지금도 많은 대안학교들이 <br />
        젭스영어성경홈스쿨을 통해영어로 말씀을 가르치며
        <br />
        하나님과 동행하는 글로벌 리더의 아이들을
        <br />
        양육하는 데에 힘쓰고 있습니다!
      </div>
      <Link to="/courses/homeschool">
        <div className="justify-center items-center px-2.5 py-4 mt-32 max-w-full text-base font-bold tracking-normal leading-6 text-center text-purple5 bg-white rounded border border-purple5 border-solid w-[376px] max-md:px-5 max-md:mt-10">
          홈스쿨 코스 자세히 보기 →
        </div>
      </Link>
    </div>
  );
};
