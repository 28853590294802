import React, { Fragment, useState } from 'react';
import TextWinds from '../common/TextWinds';
import { ChevronDownIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { numberWithCommas } from '../../api/utils/format/numbers';
import { Collapse, Typography } from '@material-tailwind/react';
import { OrderItem, OrderTemplate } from '../../constants/types';
import { calcPrice } from '../../api/utils/common-purchase';

interface OrderProductProps {
  purchaseInfo: OrderTemplate;
}
const OrderProductInfo = ({ purchaseInfo }: OrderProductProps) => {
  const [isProdInfoOpen, setIsProdInfoOpen] = useState(false);
  const orderItem: OrderItem[] = purchaseInfo.order_items;

  const ItemThumbnail = (item: OrderItem) => {
    try {
      return (
        <img
          src={item!.product.thumbnail.access_url}
          className="w-full h-full object-contain"
        />
      );
    } catch (e) {
      return <PhotoIcon className="w-full h-full" />;
    }
  };

  return (
    <div className="mt-[16px] py-[14px] px-[20px] bg-white xl:rounded-[8px]">
      <TextWinds type="title_h4">주문 상품 정보</TextWinds>

      <div className="flex justify-between pt-[20px]">
        <div className="flex flex-col gap-[8px]">
          <TextWinds type="content">
            {orderItem[0].product.name} 외 {orderItem.length - 1}개 / 총{' '}
            {orderItem.length}개
          </TextWinds>
          <div className="flex">
            <TextWinds
              type="content_body4"
              className="flex items-center pt-[8px] gap-[4px]"
            >
              총
            </TextWinds>
            <TextWinds type="title_h3" color="purple5" className="ml-1">
              {purchaseInfo ? numberWithCommas(purchaseInfo.total_price) : 0}
            </TextWinds>
            <TextWinds
              type="content_body4"
              className="flex items-center pt-[8px] gap-[4px] ml-1"
            >
              원
            </TextWinds>
          </div>
        </div>
        <div className="flex self-center">
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`w-5 h-5 transition-transform ${
              isProdInfoOpen ? 'rotate-180' : ''
            }`}
            onClick={() => setIsProdInfoOpen((cur) => !cur)}
          />
        </div>
      </div>
      <div className="pt-[20px]">
        <Collapse open={isProdInfoOpen}>
          <div className="flex flex-col gap-[12px]">
            <div className="flex flex-row items-center gap-4 px-2">
              <TextWinds type="title_h6" className="mr-auto">
                주문 상품
              </TextWinds>
              {/* <Button variant="text" color="purple">
                  <TextWinds type='button2' color='purple5'>변경하기</TextWinds>
              </Button> */}
            </div>
            {orderItem.map((item, index) => (
              <Fragment key={`0-${item.id}`}>
                <div className="flex flex-col px-2">
                  <div className="flex flex-row px-4">
                    <div className="flex flex-col mr-auto gap-[8px]">
                      <Typography variant="h6" className="font-medium">
                        {item.product.name} / {item.product.base_quantity} 개
                      </Typography>
                      {item.chosen_variants &&
                        item.chosen_variants.map((variants, index) => (
                          <TextWinds
                            key={`variants-${index}`}
                            type="content_body3"
                            className="px-2"
                          >
                            [옵션] : {variants.variant.name} /{' '}
                            {variants.quantity} 개
                          </TextWinds>
                        ))}
                      {item.enroll_options &&
                        item.enroll_options.map((enrolls, index) => (
                          <TextWinds
                            key={`enrolls-${index}`}
                            type="content_body3"
                            className="px-2"
                          >
                            [플랜] :{' '}
                            {enrolls.enroll_option.product_course.course.name}
                          </TextWinds>
                        ))}
                      {item.chosen_options &&
                        item.chosen_options.map((options) =>
                          options.chosen_variants.map((x, index) => (
                            <TextWinds
                              key={`options-${index}`}
                              type="content_body3"
                              className="px-2"
                            >
                              [옵션] : {x.variant.name} / {x.quantity} 개
                            </TextWinds>
                          ))
                        )}
                    </div>
                    <div className="w-24 h-24">{ItemThumbnail(item)}</div>
                  </div>
                  <TextWinds
                    type="content_body1"
                    className="px-2 font-bold pt-[12px]"
                  >
                    {numberWithCommas(calcPrice(item))} 원
                  </TextWinds>
                  <div className="w-full border-dashed my-[1%] border-x border-y px-2 border-gray-500" />
                </div>
              </Fragment>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default OrderProductInfo;
