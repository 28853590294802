import React, { useState } from 'react';
import TextWinds from '../common/TextWinds';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Collapse } from '@material-tailwind/react';
import { numberWithCommas } from '../../api/utils/format/numbers';
import Buttons from '../common/Buttons';
import PurchaseSummary from './PurchaseSummary';

interface AmountPaymentProps {
  productAmount: number;
  deliveryAmount: number;
  discountAmount: number;
  pointAmount: number;
  paymentMethod: string;
  handleOpenPayment: () => void;
  selectAll: boolean;
}
const AmountPayment = ({
  productAmount,
  deliveryAmount,
  discountAmount,
  pointAmount,
  paymentMethod,
  handleOpenPayment,
  selectAll,
}: AmountPaymentProps) => {
  const [isPurchaseInfoOpen, setIsPurchaseInfoOpen] = useState(true);

  const salePrice = discountAmount + pointAmount;
  const orderPrice = productAmount + deliveryAmount;
  const totalPrice = orderPrice - salePrice;
  return (
    <div className="relative w-full py-[14px] px-[20px] bg-gray0 mt-[30px] rounded-md shadow-none h-fit xl:mt-0 xl:bg-white xl:rounded-[8px]">
      <TextWinds type="title_h4">결제 금액</TextWinds>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center justify-between pt-[10px]">
          <TextWinds
            type="title_h3"
            color="purple5"
            className="flex items-center gap-[5px]"
          >
            {/* {numberWithCommas(totalAmount)} */}
            {numberWithCommas(totalPrice)}
          </TextWinds>
          <TextWinds type="content_body4">원</TextWinds>
        </div>
        <div className="flex items-center">
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`w-5 h-5 transition-transform ${
              isPurchaseInfoOpen ? 'rotate-180' : ''
            }`}
            onClick={() => setIsPurchaseInfoOpen((cur) => !cur)}
          />
        </div>
      </div>
      <div>
        <div className="my-[16px] border border-t-[1px] border-gray4" />
        <Collapse open={isPurchaseInfoOpen}>
          <PurchaseSummary
            productAmount={productAmount}
            deliveryCharge={deliveryAmount}
            discountAmount={discountAmount}
            pointAmount={pointAmount}
          />
        </Collapse>
      </div>
      <div className="p-[16px] bg-white xl:px-0">
        <Buttons
          type="filled"
          color="purple5"
          disabled={
            (paymentMethod === '' ? true : false) || !selectAll ? true : false
          }
          onClick={handleOpenPayment}
        >
          결제하기
        </Buttons>
        {/* 
          TODO 1 : 벨리데이션 안되면, 최종결제하기 버튼 비활성화 시켜야함
               2 : 로그인 여부 체크 해야함. (로그인 안되면 팝업 띄우기만 하면 됨)
        */}
      </div>
    </div>
  );
};

export default AmountPayment;
