import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit"
import { ChosenVariant, CommonResProps, OptionProduct, OptionVariant, ProductContent, ProductCourse, ShopInfoProps } from "../../constants/types"
import instance from "../../api/axios/utils.ts/instance"
import { RootState } from ".."
import { AxiosParamType } from "."
import { LegacyShopListType, LegacyShopType } from "../../constants/legacyTypes"

export type BuyerType = 'join' | 'buy'

export interface ChosenOptionsDto {
  optionProduct: OptionProduct
  chosenVariant: OptionVariant
  quantity: number
}

interface stateObj {
  status: string,
  shopLists: CommonResProps<ShopInfoProps | ShopInfoProps[]>,
  shopInfo: ShopInfoProps,
}
const initialState: stateObj = {
  status: '',
  shopLists: {
    page: null,
    result: null,
  },
  shopInfo: null,
}

const DataParser = (data: LegacyShopType): ShopInfoProps => {
  const contents: ProductContent[] = data.contents.map((v) => ({
    id: v.id,
    product_id: v.product_id,
    order_idx: v.order_idx,
    type: v.type,
    file_id: v.file_id,
    file: v.file,
    video_url: v.video_url,
    url_link: v.url_link,
    url_label: v.url_label,
  }))

  const courses: ProductCourse[] = data.courses.map((v) => ({
    id: v.id,
    product_id: v.product_id,
    enroll_options: v.enroll_options.map((v1) => ({
      id: v1.id,
      product_course_id: v1.product_course_id,
      period: v1.period,
      price: v1.price,
      is_active: v1.is_active,
    })),
    course: {
      id: v.course.id,
      name: v.course.name,
      type: v.course.type,
      required_completion_percent: v.course.required_completion_percent,
    },
    course_model_id: v.course_model_id,
    training_course_id: v.training_course_id,
  }))

  const option_variants: ChosenVariant[] =
    data.option_variants.length > 0 &&
    data.option_variants.map((v) => ({
      id: v.id,
      name: v.name,
      product_id: v.product_id,
      additional_price: v.additional_price,
      inventory_code: v.inventory_code,
      is_active: v.is_active,
      jebspl_subscription_period: v.jebspl_subscription_period
    }))

  // const option_plans: CoursePlanInfoType[] = 
  //     data.courses.length > 0 && [
  //     {
  //         id: data.courses[1].course.id,
  //         name: data.courses[1].course.name,
  //         description: data.description,
  //         price: data.current_price,
  //         period:data.courses.filter((v)=>v.course.type==="TEACHING")[0].enroll_options[0].period,
  //     }
  // ]
  // const enroll_options = []

  const rtn: ShopInfoProps = {
    id: data.id,
    name: data.name,
    description: data.description,
    thumbnail_url: data.thumbnail.access_url,
    current_price: data.current_price,
    origin_price: data.origin_price,
    base_quantity: data.base_quantity,
    contents: contents,
    option: option_variants,
    courses: courses,
  };
  return rtn
}

const DataParserList = (list: LegacyShopListType[]): ShopInfoProps[] => {
  return list.map((data) => {
    const rtn: ShopInfoProps = {
      id: data.id,
      name: data.name,
      description: data.description,
      thumbnail_url: data.thumbnail.access_url,
      current_price: data.current_price,
      origin_price: data.origin_price,
      base_quantity: data.base_quantity,
      contents: [],
      option: [],
      courses: []
    }
    return rtn
  })
}
export const getShopLists = createAsyncThunk<CommonResProps<ShopInfoProps[]>, AxiosParamType>(
  'asyncThunk/getShopLists',
  async ({ url, params }) => {
    try {
      const response = await instance.get(url, { params });
      response.data.result = DataParserList(response.data.result)
      return response.data;
    } catch (error) {
      console.error('[axios] getShopLists : ', error)
      return isRejectedWithValue(error)
    }
  });
export const getShopInfo = createAsyncThunk<ShopInfoProps, AxiosParamType>(
  'asyncThunk/getShopInfo',
  async ({ url }: AxiosParamType) => {
    try {
      const response = await instance.get(url);
      response.data = DataParser(response.data)
      return response.data;
    } catch (error) {
      console.error('[axios] getShopInfo : ', error)
      return isRejectedWithValue(error)
    }
  });

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    resetShopLists(state) {
      state.shopLists = {
        page: null,
        result: null
      }
    },
    resetShopInfo(state) {
      state.shopInfo = null
    }
  },
  extraReducers(builder) {
    builder
      // Shop 목록 조회
      .addCase(getShopLists.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getShopLists.fulfilled, (state, action: PayloadAction<CommonResProps<ShopInfoProps[]>>) => {
        state.status = 'fullfilled';
        state.shopLists = action.payload;
      })
      .addCase(getShopLists.rejected, (state) => {
        state.status = 'rejected';
      })

      // Shop 상세 조회
      .addCase(getShopInfo.pending, (state) => {
        state.status = 'pending';
      })
      // .addCase(getShopInfo.fulfilled, (state, action: PayloadAction<CommonResProps<ShopProps>>) => {
      .addCase(getShopInfo.fulfilled, (state, action: PayloadAction<ShopInfoProps>) => {
        state.status = 'fullfilled';
        state.shopInfo = action.payload;
      })
      .addCase(getShopInfo.rejected, (state) => {
        state.status = 'rejected';
      })
  },
})
export const { resetShopLists, resetShopInfo } = shopSlice.actions
export const selectShopLists = (state: RootState) => state.shop.shopLists
export const selectShopInfo = (state: RootState) => state.shop.shopInfo
export default shopSlice.reducer