import React, { Fragment } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import InfoText from '../../../components/mypage/Info';

const INFO_TEXT = [
  {
    title: '뱃지 안내',
    text: ['뱃지 획득 조건을 충족하면 뱃지가 부여됩니다. '],
  },
];

const MypageBadge = () => {
  return (
    <Fragment>
      <section className="hidden lg:block mb-[34px]">
        <TextWinds type="title_h2">나의 뱃지</TextWinds>
      </section>
      {/* <section className="p-5 pt-3 lg:pl-[45px] lg:pr-8 lg:pb-[69px]">
                <div className="mb-8 lg:mb-[53px]">
                    <div className="mb-4 lg:mb-[14px]">
                        <TextWinds type="title_h4">교사대학 코스 수료</TextWinds>
                    </div>
                    <div className="grid grid-cols-3 gap-x-[37px] gap-y-4 lg:grid-cols-5 lg:gap-x-[50px] lg:gap-y-[26px]">
                        {BADGE_ARRAY_LIST.map((props, key) => (
                            <div key={key} className="flex flex-col gap-0.5 lg:gap-0">
                                <div className="max-w-[50px] mx-auto lg:max-w-[70px]">
                                    <img src={process.env.PUBLIC_URL + props.img} alt={props.alt} />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="text-center">
                                        <TextWinds type="content_body4">{props.text1}</TextWinds>
                                    </div>
                                    <div className="flex justify-center">
                                        <TextWinds type="caption2" color="gray12">
                                            {props.text2}
                                        </TextWinds>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <div className="mb-4">
                        <TextWinds type="title_h4">인증제 레벨</TextWinds>
                    </div>
                    <div className="grid grid-cols-3 gap-x-[37px] gap-y-4 lg:grid-cols-5 lg:gap-x-[50px] lg:gap-y-[26px]">
                        {BADGE_ARRAY_LIST.map((props, key) => (
                            <div key={key} className="flex flex-col gap-0.5 lg:gap-0">
                                <div className="max-w-[50px] mx-auto lg:max-w-[70px]">
                                    <img src={process.env.PUBLIC_URL + props.img} alt={props.alt} />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="text-center">
                                        <TextWinds type="content_body4">{props.text1}</TextWinds>
                                    </div>
                                    <div className="flex justify-center">
                                        <TextWinds type="caption2" color="gray2">
                                            {props.text2}
                                        </TextWinds>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
      <section className="hidden lg:block">
        {/* <div className="w-[476px] mx-auto mb-8">
                    <Buttons type="outlined" color="purple5">
                        <TextWinds type="button" color="purple5">
                            더보기
                        </TextWinds>
                    </Buttons>
                </div> */}

        {INFO_TEXT.map((info, key) => (
          <InfoText key={key} title={info.title} text={info.text} />
        ))}
      </section>

      <section className="mt-4 bg-gray1 rounded text-center p-5">
        준비 중 입니다.
      </section>
    </Fragment>
  );
};

export default MypageBadge;
