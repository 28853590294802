import { Fragment, useEffect } from 'react';
import { Button, Typography } from '@material-tailwind/react';
import { Cards } from '../../components/card/cards';
import CarouselNavigation from '../../components/carousel/carouselnavigation';
import IconArrayList from '../../components/iconarraylist';

import '../../styles/home/home.css';
import { Link, useNavigate } from 'react-router-dom';
import { ProductSubType } from '../../enum';
import CourseList from '../../components/course/CourseList';
import { HOME_REVIEW_DATA } from '../../data/statics-datas';
import { useAppDispatch } from '../../store/hook';
import { openModal } from '../../store/features/modalSlice';
import ModalPopup from '../../components/modal/ModalPopup';
import { popupList, PopupType } from '../../constants/popupData';
import dayjs from 'dayjs';

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isStudent = localStorage.getItem('type') === 'STUDENT' ? true : false;

  const popupOpen = () => {
    if (!popupList) return;

    dispatch(
      openModal({
        body: <ModalPopup list={popupList} />,
      })
    );
  };

  useEffect(() => {
    if (isStudent) {
      navigate('/student');
    }

    popupOpen();
  }, []);

  return (
    <Fragment>
      <section className="grid place-items-center max-w-[1920px] m-auto">
        <CarouselNavigation />
      </section>

      <section className="grid px-5 lg:px-14 m-auto max-w-[1440px] mt-4">
        <Typography variant="h4" className="text-xl md:text-2xl my-4">
          젭스에 오신 걸 환영합니다!
        </Typography>

        <IconArrayList />
      </section>

      <section className="grid px-5 lg:px-14 mt-10 md:mt-20 m-auto max-w-[1440px]">
        <Typography variant="h4" className="text-xl md:text-2xl">
          성경적 영어교육은 이렇게 다릅니다!
        </Typography>
        <Typography variant="h6" className="font-normal">
          실제 젭스를 하고 계신 분들의 생생한 후기
        </Typography>
        <Cards items={HOME_REVIEW_DATA} />
        <Link to="/review">
          <Button
            variant="text"
            className="block mx-auto border border-purple-00 mt-6"
          >
            <Typography
              variant="h6"
              className="text-center font-semibold text-purple-05"
            >
              후기 더보기
            </Typography>
          </Button>
        </Link>
      </section>

      <section className="px-5 lg:px-14 m-auto max-w-[1440px] mt-[80px]">
        <Typography variant="h4" className="text-xl md:text-2xl">
          클래스를 개설하여 자녀/학생들과 수업을 시작하세요.
        </Typography>
        {/* <TagCards gubun="homeschool" /> */}
        <div className="mt-6">
          <CourseList
            productSubType={ProductSubType.HOMESCHOOL}
            max={4}
            isFromMainPage={true}
          />
        </div>
        <Link to="/courses/homeschool">
          <Button
            variant="text"
            className="block mx-auto border border-purple-00 mt-6"
          >
            <Typography
              variant="h6"
              className="text-center font-semibold text-purple-05"
            >
              홈스쿨 코스 전체보기
            </Typography>
          </Button>
        </Link>
      </section>

      <section className="px-5 lg:px-14 m-auto max-w-[1440px] mt-[80px]">
        <Typography variant="h4" className="text-xl md:text-2xl">
          우리 교회에 영어주일학교를 도입하세요.
        </Typography>
        {/* <TagCards gubun="churchschool" /> */}
        <div className="mt-6">
          <CourseList
            productSubType={ProductSubType.WORSHIP}
            max={4}
            isFromMainPage={true}
          />
        </div>
        <Link to="/courses/churchschool">
          <Button
            variant="text"
            className="block mx-auto border border-purple5"
          >
            <Typography
              variant="h6"
              className="text-center font-semibold text-purple-05"
            >
              주일학교 코스 전체보기
            </Typography>
          </Button>
        </Link>
      </section>
      <section className="m-auto bg-[#6d10fe] mt-[80px]">
        <div className="p-7 pt-0 items-center max-w-[375px] m-auto">
          <div className="flex flex-col items-center">
            <img
              className="object-cover"
              alt="Image"
              src={process.env.PUBLIC_URL + 'images/home/main_jebstory.png'}
            />
          </div>
          <Link to="/story">
            <div className="p-4 text-center bg-white hover:bg-gray2">
              <Typography
                variant="h5"
                color="deep-purple"
                className="font-bold leading-6 tracking-tight text-center whitespace-nowrap"
              >
                젭스 자세히 알아보기
              </Typography>
            </div>
          </Link>
        </div>
      </section>
    </Fragment>
  );
};

export default Home;
