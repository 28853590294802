interface HalfLayoutProps {
  left: React.ReactNode;
  right: React.ReactNode;
  className?: string;
}

export const HalfLayout = ({ left, right, className }: HalfLayoutProps) => {
  return (
    <div className={"flex flex-col lg:flex-row gap-8 " + className}>
      <div className="w-full lg:w-1/2">{left}</div>
      <div className="w-full lg:w-1/2">{right}</div>
    </div>
  );
};
