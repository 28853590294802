import React, { useContext, useState } from 'react';
import { StudentProfileContext } from './StudentProfileContext';
import TextWinds from '../../../components/common/TextWinds';
import { Link, useNavigate } from 'react-router-dom';
import {
  studentGuardianType,
  studentProfileType,
} from '../../../constants/types/student-type';
import { useForm } from 'react-hook-form';
import { Input, Select, Option } from '@material-tailwind/react';
import Buttons from '../../../components/common/Buttons';
import { toDay } from '../../../api/utils/util-func';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import CalendarInput from '../components/CalendarInput';
import { AgreementBox } from '../../../components/common/AgreementBox';
import { AgreementType } from '../../../components/common/Agreement';

interface StudentGuardianCompProps {
  editable?: boolean;
}
const StudentGuardianComp = ({ editable }: StudentGuardianCompProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { studentProfile, setStudentProfile } = useContext(
    StudentProfileContext
  );
  const [gender, setGender] = useState<string>(
    studentProfile.guardianInfo?.gender
  );
  const [checkBox, setCheckBox] = useState<boolean>(false);

  // useForm을 사용하여 form을 관리
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<studentGuardianType, 'id'>>({
    defaultValues: { ...studentProfile.guardianInfo },
  });

  /**
   * DatePicker 관련
   */
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(
      studentProfile.guardianInfo
        ? studentProfile.guardianInfo.birthday
        : toDay()
    )
  );

  /**
   * 보호자 정보 업데이트
   * @param data 업데이트 될 보호자 정보
   */
  const putGuardianInfo = async (data: Omit<studentGuardianType, 'id'>) => {
    try {
      const result = await instance.put<ApiResponse<studentProfileType>>(
        '/v1/user/student/parent-info',
        { guardianInfo: data }
      );
      if (result.status === 200) {
        dispatch(openAlert({ message: '보호자 정보가 수정되었습니다.' }));
        navigate('/student/profile');
        setStudentProfile(result.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * 저장 버튼 클릭시
   * @param data 업되이트 될 보호자 정보
   */
  const onSubmit = (data: Omit<studentGuardianType, 'id'>) => {
    if (checkBox === false) {
      dispatch(openAlert({ message: '개인정보 동의를 해주세요.' }));
    }

    const param: Omit<studentGuardianType, 'id'> = {
      birthday: startDate,
      gender: gender as 'MALE' | 'FEMALE',
      countryCode: '+82',
      agreementPersonalInfo: checkBox,
      name: data.name,
      phone: data.phone,
    };
    putGuardianInfo(param);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <TextWinds type="title_h2">보호자 정보</TextWinds>
        {!editable && (
          <Link to="/student/profile/edit/guardian">
            <div className="flex items-center hover:bg-purple2 px-2 border border-purple5 rounded cursor-pointer">
              <div className="text-purple5">수정하기</div>
              <img src="/svg/icon/write.svg" alt="modify" />
            </div>
          </Link>
        )}
      </div>
      {studentProfile.guardianInfo || editable ? (
        <form>
          <div className="flex flex-col gap-4">
            <Input
              label="이름"
              type="text"
              {...register('name')}
              disabled={!editable}
            />
            <div className="relative">
              <Input
                type="text"
                label="생일"
                name="birthday"
                disabled={!editable}
                value={startDate.toLocaleDateString()}
                onChange={() => {}}
                onClick={() => setIsOpen(true)}
              />
              <CalendarInput
                startDate={startDate}
                setStartDate={setStartDate}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
            <Select
              name="gender"
              label="성별"
              onChange={(e) => {
                setGender(e);
              }}
              value={studentProfile.guardianInfo?.gender}
              disabled={!editable}
            >
              <Option value="MALE">남</Option>
              <Option value="FEMALE">여</Option>
            </Select>
            <Input
              label="연락처"
              type="tel"
              {...register('phone', {
                required: { value: true, message: '연락처를 입력해주세요.' },
                // pattern: /^01(?:0|1|[6-9])-(\d{3}|\d{4})-(\d{4})$/,
              })}
              disabled={!editable}
            />
            {errors.phone && errors.phone.message}
            {editable && (
              <>
                <div className="flex flex-col gap-2 text-right">
                  <AgreementBox
                    agreementList={[
                      {
                        agree: AgreementType.PERSONAL_COLLECTION,
                        essential: true,
                      },
                    ]}
                    essentialChecked={(flag) => setCheckBox(flag)}
                  />
                  {/* <CheckBoxes
                    type="border"
                    labelValue={'개인정보동의'}
                    checked={checkBox}
                    onChange={()=>setCheckBox(!checkBox)}
                    color='purple5'
                  /> */}
                </div>
                <Buttons
                  type="filled"
                  onClick={handleSubmit(onSubmit)}
                  className="w-full"
                  color={'purple5'}
                >
                  저장
                </Buttons>
                <Buttons
                  type="outlined"
                  onClick={() => window.history.back()}
                  className="w-full"
                  color={'purple5'}
                >
                  취소
                </Buttons>
              </>
            )}
          </div>
        </form>
      ) : (
        <div className="py-3">
          <TextWinds type="content_body1">보호자 정보가 없습니다.</TextWinds>
        </div>
      )}
    </div>
  );
};

export default StudentGuardianComp;
