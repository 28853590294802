import React from 'react';
import { CartItem, OrderItem } from '../../constants/types';
import ProductAllSelect from './productallselect';
import CheckBoxes from '../common/CheckBoxes';
import Buttons from '../common/Buttons';
import TextWinds from '../common/TextWinds';
import Purchase_Conditions from './list/purchase_conditions';
import { cartCheckedProps } from '../../store/features/cartSlice';
import { updateTarget } from '../../api/utils/util-func';

const CartLeft = ({
  selectAll,
  cartLists,
  selectedProducts,
  handleSelectAllChange,
  handleCheckboxChange,
  onDecrease,
  onIncrease,
  handleBuySingleItem,
  onSingleRemoveItem,
  // onRemoveAllItems,
  onRemoveSelectedItems,
}: {
  selectAll: boolean;
  cartLists: CartItem[];
  selectedProducts: cartCheckedProps[];
  handleSelectAllChange: () => void;
  handleCheckboxChange: (el: cartCheckedProps[]) => void;
  onDecrease: (
    type: string,
    variantId: string,
    orderItemId: string,
    value: number
  ) => void;
  onIncrease: (
    type: string,
    variantId: string,
    orderItemId: string,
    value: number
  ) => void;
  handleBuySingleItem: (orderItem: OrderItem) => void;
  onSingleRemoveItem: (id: string) => void;
  // onRemoveAllItems: (ids: string[]) => void;
  onRemoveSelectedItems: () => void;
}) => {
  // 체크박스
  const eventHandle = (productId: string, idx: number, toggled: boolean) => {
    const target = { ...selectedProducts[idx], isChecked: toggled };
    const result = updateTarget(selectedProducts, productId, target);
    handleCheckboxChange(result);
  };

  const handleIncrementCount = (
    type: string,
    variantId: string,
    orderItemId: string,
    value: number
  ) => {
    onIncrease(type, variantId, orderItemId, value);
  };
  const handleDecrementCount = (
    type: string,
    variantId: string,
    orderItemId: string,
    value: number
  ) => {
    onDecrease(type, variantId, orderItemId, value);
  };

  const handleSingleRemoveItem = (id: string) => {
    onSingleRemoveItem(id);
  };
  // const handleRemoveAllItem = () => {
  //   const allItemIds: string[] = [...cartLists.map((x) => x.id)];
  //   onRemoveAllItems(allItemIds);
  // };

  const handleRemoveSelectedItems = () => {
    onRemoveSelectedItems();
  };

  return (
    <>
      <div className="relative w-full h-full">
        <section>
          <ProductAllSelect
            selectAll={selectAll}
            productState={cartLists}
            selectedProducts={selectedProducts}
            handleSelectAllChange={handleSelectAllChange}
            // handleRemoveAllItem={handleRemoveAllItem}
            handleRemoveSelectedItems={handleRemoveSelectedItems}
          />
        </section>
        <section>
          <div className="flex flex-col first:mt-[5px] border-y-[1px] border-solid border-gray5">
            {cartLists.length > 0 ? (
              cartLists.map(({ id, order_item }: CartItem, idx) => (
                <div
                  key={id}
                  className="py-[20px] bg-white xl:px-[32px] border first:border-y-0 p-5"
                >
                  <div className="flex flex-row items-center justify-between">
                    <CheckBoxes
                      type="full"
                      checked={selectedProducts[idx].isChecked}
                      onChange={(e) => eventHandle(id, idx, e.target.checked)}
                      labelValue={``}
                    />
                    <Buttons
                      type="text"
                      color="purple5"
                      className="justify-end bg-transparent text-purple5"
                      onClick={() => handleSingleRemoveItem(id)}
                    >
                      삭제하기
                    </Buttons>
                  </div>
                  <div className="p-0 flex flex-col">
                    <div className="w-full">
                      <Purchase_Conditions
                        order_item={order_item}
                        enroll_options={order_item.enroll_options}
                        chosen_options={order_item.chosen_options}
                        chosen_variants={order_item.chosen_variants}
                        handleDecrementCount={handleDecrementCount}
                        handleIncrementCount={handleIncrementCount}
                        handleBuySingleItem={handleBuySingleItem}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="py-[20px] bg-white rounded-md xl:px-[32px]">
                <TextWinds type="content">장바구니가 비어있습니다.</TextWinds>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default CartLeft;
