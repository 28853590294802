import { Avatar, Button, Typography } from '@material-tailwind/react';
import React, { useEffect } from 'react';
import { ReviewCard } from '../../../components/card/ReviewCard';
import { HalfLayout } from '../../../components/layout/HalfLayout';
import ResponseTypo from '../../../components/ResponseTypo';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
interface dummyType {
  hash: string;
  name: string;
  img: string;
  video: string;
}

const dummy: dummyType[] = [
  {
    hash: '#교회 #주중',
    name: '이정은 선생님',
    img: '/images/home/story/videoReview1.png',
    video: 'https://youtu.be/UD1P3GtEnVo?si=SQBGeoqKBSgYLzhb',
  },
  {
    hash: '#자녀 #대안학교',
    name: '이수경 선생님',
    img: '/images/home/story/videoReview2.png',
    video: 'https://youtu.be/75jgHQDdwp8?si=xSfqgjm9cE0Wa7k5',
  },
  {
    hash: '#공부방',
    name: '박리나 선생님',
    img: '/images/home/story/videoReview3.png',
    video: 'https://youtu.be/0AOmbOiGkVQ?si=neFBynfBwUuohQEM',
  },
  {
    hash: '#자녀 #교습소',
    name: '성소영 선생님',
    img: '/images/home/story/videoReview4.png',
    video: 'https://youtu.be/YyKOUcq8BGQ?si=n6w8AsEIqcSlbWkY',
  },
  {
    hash: '#교회 #주중',
    name: '만남의교회',
    img: '/images/home/story/videoReview5.png',
    video: 'https://youtu.be/GW2c9S7xYiA?si=LSnYO23W_0X1cpBD',
  },
];

export const StoryReview = () => {
  const [review, setReview] = React.useState(0);
  const [autoPlay, setAutoPlay] = React.useState(false);

  // 리뷰 변경시 자동재생 & 영상 종료시 다음 영상 재생
  useEffect(() => {
    setAutoPlay(review !== 0);
  }, [review]);
  return (
    <section className="p-5 lg:pt-14 lg:pb-14 bg-black/[0.05]">
      <div className="lg:m-auto lg:max-w-[1024px]">
        <HalfLayout
          left={
            <div className="flex flex-col text-center items-center md:text-left md:items-start">
              <img src="./svg/story/icon-book.svg" />
              <ResponseTypo variant_sm="h4" variant_lg="h4" className="mt-3">
                가정, 교회, 공부방
                <br />
                내가 있는 곳 어디서든지 클래스를 오픈하여 다음 세대를 양육할 수
                있습니다.
              </ResponseTypo>
            </div>
          }
          right={
            <ReactPlayer
              controls
              style={{ aspectRatio: '16/9' }}
              width={'100%'}
              height={'auto'}
              onEnded={() => setReview((review + 1) % dummy.length)}
              playing={autoPlay}
              url={dummy[review].video}
            />
          }
        />
      </div>
      <div className="lg:mx-auto lg:max-w-[1024px] grid grid-cols-2 gap-5 lg:grid-cols-5 mt-8">
        {dummy.map((item, i) => (
          <div className={`flex justify-center`} onClick={() => setReview(i)}>
            <div className="flex flex-col items-center w-fit group cursor-pointer">
              <div className="w-16 h-16 relative">
                {review === i && (
                  <div className="w-2 h-2 bg-red5 absolute top-0 right-0 rounded-full animate-pulse"></div>
                )}
                <img
                  src={process.env.PUBLIC_URL + item.img}
                  className={`group-hover:scale-110 transition-transform duration-100 ease-in-out absolute top-0 left-0
                 border-purple5 rounded-full`}
                />
              </div>
              <div className="w-full text-center mt-2 text-lg">{item.hash}</div>
              <div className="w-full text-center text-sm text-gray9">
                {item.name}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Link to="/review">
        <Button
          variant="text"
          className="block border border-purple-00 mt-6 w-full lg:w-fit lg:mx-auto"
        >
          <Typography
            variant="h6"
            className="text-center font-semibold text-purple-05"
          >
            회원 후기 더보기 →
          </Typography>
        </Button>
      </Link>
    </section>
  );
};
