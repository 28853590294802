import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StudentClassComp from './StudentClassComp';
import StudentGuardianComp from './StudentGuardianComp';
import StudentProfileComp from './StudentProfileComp';
import { StudentProfileContext } from './StudentProfileContext';
import StudentPwComp from './StudentPwComp';

const StudentProfileEditPage = () => {
  const { studentProfile } = useContext(StudentProfileContext);
  const { type } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (studentProfile === undefined) {
      navigate('/student/profile');
    }
  }, []);

  return (
    studentProfile && (
      <div className="w-full bg-gray0 py-10">
        <div className="max-w-[600px] m-auto p-10 shadow-card bg-white rounded-lg flex flex-col gap-10">
          {type === 'student' && <StudentProfileComp editable />}
          {type === 'pw' && <StudentPwComp editable />}
          {type === 'class' && <StudentClassComp editable />}
          {type === 'guardian' && <StudentGuardianComp editable />}
        </div>
      </div>
    )
  );
};

export default StudentProfileEditPage;
