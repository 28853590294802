interface StudentColProps {
    width?: string;
    shrink?: string;
    children: React.ReactNode;
  }
  export const StudentCol = ({ width, shrink, children }: StudentColProps) => {
    return (
      <div
        className={`w-${width ??'full'} text-center ${shrink ? 'shrink-' + shrink : ''}`}
      >
        {children}
      </div>
    );
  };