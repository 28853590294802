import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import TextWinds from '../common/TextWinds';
import Icon from '../common/Icon';
import Buttons from '../common/Buttons';
import { Link, redirect } from 'react-router-dom';

interface SimpleDialogProps {
    open: boolean;
    handleClose: () => void;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    onConfirm: () => void;
    children: React.ReactNode;
}
const SimpleDialog: React.FC<SimpleDialogProps> = ({
    open,
    handleClose,
    title,
    confirmText,
    cancelText,
    onConfirm,
    children,
}) => {
    return (
        <Dialog
            open={open}
            size="xs"
            handler={() => handleClose}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
        >
            <DialogHeader className="relative border-b-2 border-black-400">
                <div className={`w-full text-left flex item-center justify-between`}>
                    <TextWinds type="title_h4">{title}</TextWinds>
                    <Button onClick={handleClose} className="bg-white p-0 border-none rounded-none shadow-none">
                        <Icon width={30} height={30} fileName={'icon_close'} />
                    </Button>
                </div>
            </DialogHeader>
            <DialogBody className="text-left text-contentText text-gray9">
                {children}
                {/* <div
                    className="text-content p-[10px]"
                    dangerouslySetInnerHTML={{ __html: `<Textwinds type="content">${content}</Textwinds>` }}
                /> */}
            </DialogBody>
            {confirmText && cancelText && (
                <DialogFooter className="flex flex-col items-center gap-[10px] w-full">
                    <div className="flex flex-row items-center justify-between gap-[16px] w-full">
                        {confirmText && (
                            <Buttons type={'filled'} color={'purple5'} onClick={onConfirm}>
                                <span>{confirmText}</span>
                            </Buttons>
                        )}
                        {cancelText && (
                            <Buttons type={'outlined'} color={'purple5'} onClick={handleClose}>
                                <span>{cancelText}</span>
                            </Buttons>
                        )}
                    </div>
                </DialogFooter>
            )}
        </Dialog>
    );
};

export default SimpleDialog;
