import React from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import HFbutton from '../../../../components/common/input/HFbutton';
import instance from '../../../../api/axios/utils.ts/instance';
import { useParams } from 'react-router-dom';
import { ToDoStatusEnum } from '../../../../enum';
import { useAppDispatch } from '../../../../store/hook';
import dayjs from 'dayjs';
import {
  getSubTypeName,
  openContentsView,
} from '../../../../api/utils/util-func';

interface StudentHomeworkItemProps {
  data: myLearningTodoType;
  setTodoView: (value: React.SetStateAction<Window>) => void;
  lastItemRef?: (node: HTMLDivElement) => void;
}
const StudentHomeworkItem = ({
  data,
  setTodoView,
  lastItemRef,
}: StudentHomeworkItemProps) => {
  const dispatch = useAppDispatch();
  const { classId } = useParams();
  const [todo, setTodo] = React.useState<myLearningTodoType>(data);
  const checkStartDay = dayjs(todo.fromDate).isAfter(dayjs());

  const patchTodoStatus = async (status: string) => {
    try {
      const result = await instance.patch(
        `/v1/student/class/${classId}/todo/${todo.id}`,
        { status: status }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const startAction = () => {
    // 오늘 날짜와 시작일을 비교해서, 시작일 이전이면 경고창 띄우기
    if (checkStartDay) {
      alert('아직 시작일이 되지 않았습니다.');
      return;
    }

    if (todo.name.includes('Actual Test') || todo.name.includes('Daily Test')) {
      window.open(todo.link, 'TestView', 'location=no, menubar=no,');
    } else {
      const dataToSend = {
        type: 'todoView',
        contents: {
          url: todo.link,
        },
      };
      openContentsView(dataToSend, setTodoView);
    }

    // 인증제만 마감처리 하던 코드
    // if (todo.type === 'RECITATION') {
    //   patchTodoStatus('CLOSED');
    //   setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.CLOSED }));
    // } else {
    // patchTodoStatus('IN_PROGRESS');
    // setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
    // }

    if (todo.status === 'COMPLETED') {
      return;
    }

    patchTodoStatus('IN_PROGRESS');
    setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
  };

  const getButtonLabel = (status: ToDoStatusEnum) => {
    if (checkStartDay) {
      return '할 일 대기';
    }
    switch (status) {
      case 'IN_PROGRESS':
        return '진행 중';
      case 'COMPLETED':
        return data.name.includes('Test') ? '결과 보기' : '완료';
      case 'CLOSED':
        return '마감';
      default:
        return '할 일 시작';
    }
  };

  /**
   *
   * WMW 파랑
   * JS1 빨강
   * JS2 초록
   * HF 파랑
   * HJ 빨강
   * JEBSON 파랑
   * RECITATION 빨강
   * @param type
   * @returns
   */
  const getSubTypeColor = (type: string) => {
    switch (type) {
      case 'RECITATION':
      case 'JS1':
      case 'HJ':
        return 'red-500';
      case 'JS2':
        return 'green-500';
      default:
        return 'skyblue';
    }
  };

  return (
    <div
      className="w-full bg-white rounded-lg shadow-card p-5 flex justify-between items-center"
      ref={lastItemRef}
    >
      <div>
        <Chip
          color={getSubTypeColor(todo.type)}
          text={getSubTypeName(todo.type)}
        />
        <div>
          <TextWinds type={'title_h3'} children={todo.name} className="mt-2" />
        </div>
        <div className="">
          <TextWinds type={'caption1'} className="mt-2">
            {`${dayjs(todo.fromDate).format('YY-MM-DD')} 부터 
            ${dayjs(todo.toDate).format('YY-MM-DD')}까지 (${
              todo.remainDays
            }일 남음)`}
          </TextWinds>
        </div>
      </div>
      <div>
        <HFbutton
          onClick={startAction}
          height={40}
          disabled={
            todo.status === 'CLOSED' ||
            (todo.status === 'COMPLETED' && !todo.name.includes('Test')) ||
            checkStartDay
          }
        >
          {getButtonLabel(todo.status)}
        </HFbutton>
      </div>
    </div>
  );
};

interface ChipProps {
  color: 'tangerine5' | 'skyblue' | string;
  text: string;
}
const Chip = ({ color, text }: ChipProps) => {
  return (
    <div
      className={`text-${color} border border-${color} rounded-md text-center w-fit p-1 text-sm `}
    >
      {text}
    </div>
  );
};

export default StudentHomeworkItem;
