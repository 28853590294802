import React, { Fragment } from 'react';
import Buttons from '../../../../components/common/Buttons2';
import TextWinds from '../../../../components/common/TextWinds';
import {
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';

interface BoostupMenuProps {
  openNav: boolean;
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  point: number;
}
const BoostupMenu = ({
  openNav,
  openMenu,
  setOpenMenu,
  point,
}: BoostupMenuProps) => {
  const navigate = useNavigate();
  const handleNewTabs = () => {
    window.open('/cart', '_blank');
  };
  return (
    <div
      className={`absolute bg-white xl:top-0 top-10 right-0 flex-col xl:flex-row justify-end shadow-card xl:shadow-none p-1 z-10 ${
        openNav ? 'flex' : 'xl:flex hidden'
      }`}
    >
      <div className="flex justify-end">
        <Buttons
          variant="text"
          color="gray9"
          type="text"
          onClick={() => handleNewTabs()}
          className="rounded-lg hover:bg-blue-gray-50 px-[6px] py-1"
        >
          <TextWinds type="button2" color="gray8">
            장바구니
          </TextWinds>
        </Buttons>
      </div>
      <div className="flex justify-end">
        <Buttons
          variant="text"
          color="gray9"
          type="text"
          onClick={() => navigate('/mypage/purchase')}
          className="rounded-lg hover:bg-blue-gray-50 px-[6px] py-1"
        >
          <TextWinds type="button2" color="gray8">
            구매내역
          </TextWinds>
        </Buttons>
      </div>
      <div className="flex justify-end">
        <Menu
          placement="bottom-end"
          open={openMenu}
          handler={setOpenMenu}
          offset={1}
        >
          <MenuHandler>
            <ListItem className="flex items-center gap-2 p-0 w-fit font-medium text-gray-900">
              <TextWinds
                type="content_bold"
                color="gray8"
                className="px-[6px] py-1"
              >
                보유 포인트
              </TextWinds>
            </ListItem>
          </MenuHandler>
          <MenuList className="p-0 min-w-[120px]">
            <Link to={'/mypage/point'}>
              <MenuItem className="flex">
                <TextWinds type="content_body4" color="purple5">
                  {point}
                </TextWinds>
                <TextWinds type="content_body4" color="gray9">
                  점
                </TextWinds>
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default BoostupMenu;
