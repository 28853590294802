import TextWinds from '../../../components/common/TextWinds';
import InfoText from '../../../components/mypage/Info';
import '../../../styles/mypage/mypage.css';
import CertificateImg from '../../../components/mypage/CertificateImg';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { TeacherLicenseType } from '../../../constants/types/mypage-type';
import { useEffect, useState } from 'react';

const INFO_TEXT = [
  {
    title: '수료증 내 영문명 안내',
    text: [
      '내 정보 > 회원 정보 수정에 입력된 영문명으로 기재됩니다. (대문자, 20자 이내)',
    ],
  },
];

const MypageCertification = () => {
  const [teacherLicense, setTeacherLicense] = useState<TeacherLicenseType[]>();
  useEffect(() => {
    getTeacherLicense().then((data) => {
      setTeacherLicense(data);
    });
  }, []);

  const getTeacherLicense = async () => {
    try {
      const result = await instance.get<ApiResponse<TeacherLicenseType[]>>(
        '/v1/mypage/teacher-license'
      );
      return result.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="px-5 pb-[65px] lg:p-0">
        <div className="mb-4 lg:mb-6">
          <TextWinds type="title_h2">수료증</TextWinds>
        </div>
        <div>
          {INFO_TEXT.map((info, key) => (
            <InfoText
              key={key}
              title={info.title}
              text={info.text}
              className="mb-5"
            />
          ))}
        </div>

        {teacherLicense?.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {teacherLicense?.map((data, key) => (
              <CertificateImg
                key={data.id}
                name={data.userEnglishName}
                type={data.licenseType}
                date={new Date(data.graduationAt).toDateString()}
              />
            ))}
          </div>
        ) : (
          <div className="w-full text-left bg-gray1 p-3">
            아직 수료한 코스가 없습니다.
          </div>
        )}
      </section>
    </>
  );
};

export default MypageCertification;
