import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { closeModal, selectIsOpen } from '../../store/features/modalSlice';

const Modal = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector((state) => state.modal);
  const blurCSS = modalState.option?.removeBlur ? '' : 'backdrop-blur-sm';
  const hiddenCSS = modalState.open ? '' : 'hidden';
  const isOpen = useAppSelector(selectIsOpen);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // 새로고침시 모달창 닫기
  useEffect(() => {
    dispatch(closeModal());
  }, []);

  return (
    // 열린 상태일 때만 모달창 렌더링
    <div
      className={`bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-[100] flex items-center justify-center
        ${blurCSS} ${hiddenCSS}`}
      style={{ zIndex: 10000 }}
    >
      {/* JSX 렌더링 가능한 경우 체크 ( 명확하지 않음 ) */}
      {modalState.body.type ? modalState.body : <></>}
    </div>
  );
};

export default Modal;
