import { LmsStudentProps } from '../types';
import { classInfo, parentType } from './student-type';

/**
 * @description LMS 학생 정보. 학생페이지의 studentType과는 다름
 */
export type LmsStudentType = {
  id: number;
  name: string;
  uid: string;
  birthday?: Date;
  email?: string;
  phone?: string;
  address?: string;
  detailAddress?: string;
  zipCode?: string;
  schoolName?: string;
  schoolGroup?: string;
  parents?: parentType[];
};

export type LmsMigrationStudent = {
  id: number;
  isSuccess: boolean;
  classId: string;
  point: string;
  teacherId: string;
  u_birth: string;
  u_church: number;
  u_email: string;
  u_id: string;
  u_lv: number;
  u_name: string;
  u_name_en: string;
  u_phone: string;
  u_school_code: string;
  u_seq: number;
};

export enum LmsMigrationStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export type LmsClassGroupType = {
  id: number;
  classId: string;
  name: string;
  description: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
};

export type LmsTodoStatusByTodoType = {
  txId: string;
  txInfo: {
    id: number;
    createdAt: Date;
    updatedAt: Date | null;
    deletedAt: Date | null;
    book: string;
    lesson: string;
    code: string;
    title: string;
    type: string;
    fromDate: Date;
    toDate: Date;
    link: string;
  };
};

export type assignedLmsTodosByTodo = {
  student: LmsStudentType;
  progress: {
    id: number;
    link: string;
    progress: number | null;
    progressState: string;
    fromDate: Date;
    toDate: Date;
    completedDate: Date | null;
    passDate: Date | null;
    failDate: Date | null;
    testDate: Date | null;
    type: string;
  };
};

export type LmsTodoStatusByIdType = {
  name: string;
  uid: string;
  accumulatedTodoCount: number;
  accumulatedBadgeCount: number;
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type assignedLmsTodosById = {
  book: string;
  lesson: string;
  viewId: string;
  code: string;
  title: string;
  result: string | null;
  type: string;
  progress: string | null;
  progressState: string;
  completedDate: Date | null;
  fromDate: Date;
  toDate: Date;
  passDate: Date | null;
  failDate: Date | null;
  testDate: Date | null;
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export type recitationResultType = {
  user: {
    id: number;
    name: string;
    uid: string;
    classInfo: classInfo;
  };
  recitationResultSummary: {
    completedLevels: number[];
    passCount: number;
    failCount: number;
    testCount: number;
  };
};

export type recitationBookType = {
  level: number;
  lesson: number;
  title: string;
  passDate: Date;
  failDate: Date;
  testDate: Date;
  lmsTodoId: number;
};

export type recitationLevelType = {
  level: number;
  results: recitationBookType[];
};
