import React, { useEffect, useRef, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Option = {
  value: string;
  label: string;
};

type HFselectProps = {
  register: UseFormRegisterReturn;
  className?: string;
  placeholder?: string;
  data: Option[];
  height?: number;
  selected?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

const HFselect = ({
  register,
  className,
  placeholder,
  data,
  height = 50,
  selected,
  disabled,
  onChange,
}: HFselectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option>({
    value: '',
    label: '',
  });

  // Ref to store the reference of the select box
  const selectRef = useRef<HTMLDivElement>(null);

  // Function to handle click outside the select box
  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // Effect to add click event listener when component mounts
  useEffect(() => {
    if (selected) {
      const selectedOption = data.find((option) => option.value === selected);
      if (selectedOption) {
        setSelectedValue(selectedOption);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOptions = () => {
    if (disabled) return;

    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: Option) => {
    setSelectedValue(value);
    onChange && onChange(value.value);
    setIsOpen(false);
  };

  const py = (height - 26) / 2;

  return (
    <div ref={selectRef} className={`relative ${className}`}>
      <div
        className={`h-[${height}px] border border-gray5 bg-white px-2.5 py-[${py}px] ${
          isOpen ? 'rounded-t' : 'rounded'
        } shadow-sm cursor-pointer flex justify-between items-center`}
        onClick={toggleOptions}
      >
        {selectedValue.value ? (
          <span className={disabled ? 'text-gray6' : ''} >{selectedValue.label}</span>
        ) : (
          <span className="text-gray-400">{placeholder}</span>
        )}
        <svg
          className={`w-4 h-4 ml-2 transition-transform duration-300 transform  ${
            isOpen ? 'rotate-180' : ''
          }`}
          viewBox="0 0 20 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10 12l-6-6 1.5-1.5L10 9.086l4.5-4.5L16 6l-6 6z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {isOpen && (
        <div
          className={`absolute top-[${
            height - 1
          }px] w-full bg-white border border-t-0 border-gray-400 rounded-b shadow-lg z-10`}
        >
          {data.map((option) => (
            <div
              key={option.value}
              className={`h-auto cursor-pointer px-2.5 py-[${py}px] hover:bg-gray3 odd:bg-gray1`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      <input type="hidden" {...register} value={selectedValue.value}/>
    </div>
  );
};

export default HFselect;
