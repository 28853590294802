import { createContext } from "react";
import { User } from "../../../constants/types"

type MypageContextType = {
    userInfo: User;
    setMemberInfo: (memberInfo: User) => void;
}

export const MypageContext = createContext<MypageContextType>({
    userInfo: undefined,
    setMemberInfo: () => {},
});