import React from 'react';
import HomeschoolSection1 from './HomeschoolSection1';
import HomeschoolSection2 from './HomeschoolSection2';
import HomeschoolSection3 from './HomeschoolSection3';
import HomeschoolSection4 from './HomeschoolSection4';
import HomeschoolSection5 from './HomeschoolSection5';
import HomeschoolSection6 from './HomeschoolSection6';
import HomeschoolSection7 from './HomeschoolSection7';
import HomeschoolSection8 from './HomeschoolSection8';
import HomeschoolSection9 from './HomeschoolSection9';
import HomeschoolSection10 from './HomeschoolSection10';
import HomeschoolSection11 from './HomeschoolSection11';
import HomeschoolEvent from './HomeschoolEvent';

const HomeschoolMain = () => {
  return (
    <div>
      <HomeschoolEvent />
      <HomeschoolSection2 />
      <HomeschoolSection3 />
      <HomeschoolSection4 />
      <HomeschoolSection5 />
      <HomeschoolSection6 />
      <HomeschoolSection7 />
      <HomeschoolSection8 />
      <HomeschoolSection9 />
      <HomeschoolSection10 />
      <HomeschoolSection11 />
    </div>
  );
};

export default HomeschoolMain;
