import React from 'react';
import TextWinds from '../common/TextWinds';
import { Link } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react';
import { CourseInfoType } from '../../constants/types';

interface CourseCardProps {
  data: CourseInfoType;
  isFromMainPage?: boolean;
}
const CourseCard = ({ data, isFromMainPage }: CourseCardProps) => {
  const path = isFromMainPage
    ? `./courses/${data.product_sub_type.toLowerCase()}/details/${data.id}`
    : `./details/${data.id}`;
  return (
    <Link to={path}>
      <Card className="w-full h-full shrink-0 xl:w-[270px] m-auto">
        <Button className="bg-white h-full p-0 flex flex-col">
          <CardHeader className="flex items-center justify-center w-full p-0 mx-0 my-0 bg-gray-100 rounded-sm shadow-none aspect-[210/120]">
            {data.thumbnail_url ? (
              <img
                className="w-full h-full rounded-t-lg object-contain"
                src={data.thumbnail_url}
                alt={data.name}
              />
            ) : (
              <PhotoIcon className="w-[32px] h-[32px]" />
            )}
          </CardHeader>
          <CardBody className="px-[10px] py-[16px] w-full">
            <TextWinds type="content_body4" className="font-semibold">
              {data.name}
            </TextWinds>
            <TextWinds type="content_body4" color="gray7" className="pt-[8px]">
              {data.description}
            </TextWinds>
          </CardBody>
        </Button>
      </Card>
    </Link>
  );
};

export default CourseCard;
