import React from 'react';
import StudentBadgeList from './StudentBadgeList';
import TextWinds from '../../../components/common/TextWinds';
import WhatIsCertification from '../certification/WhatIsCertification';

const StudentBadgePage = () => {
  return (
    <div className="bg-[#f3f0e7] py-16">
      <div className="w-full max-w-[1176px] m-auto px-5">
        <TextWinds type={'title_h1'} children={'암송인증제 레벨 뱃지'} />
        {/* <WhatIsCertification /> */}
        <StudentBadgeList />
      </div>
    </div>
  );
};

export default StudentBadgePage;
