import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";

interface CustomInputSwitchProps {
    checked?: boolean;
}

const ComInputSwitch: React.FC<CustomInputSwitchProps> = ({ checked }) => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <div className="flex p-1">
            <InputSwitch checked={isChecked} onChange={(e) => setIsChecked(e.value)} className="custom-switch" />
        </div>
    );
};

export default ComInputSwitch;
