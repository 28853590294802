import React, { useState } from 'react';
import {
  Tabs,
  TabsBody,
  TabsHeader,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import NormalPage from '../../../components/layout/NormalPage';
import LoginPasswordPhone from './LoginPasswordPhone';
import LoginPasswordEmail from './LoginPasswordEmail';

const LoginPassword = () => {
  const [activeTab, setActiveTab] = useState('phone');

  const TABS_MENU = [
    {
      label: '비밀번호 초기화',
      value: 'phone',
    },
    // {
    //     label: "이메일 인증",
    //     value: "email",
    // },
  ];

  const tabClickHandler = (value: string) => {
    setActiveTab(value);
  };

  return (
    <NormalPage paddingX={0} paddingY={0}>
      <div className="w-full shadow-none xl:w-[580px] xl:mx-auto xl:mt-[45px] xl:px-8 xl:py-10 xl:shadow-card xl:rounded-lg">
        <Tabs value={activeTab} className="w-full">
          <TabsHeader
            className="rounded-none border-b border-gray6 bg-transparent p-0"
            indicatorProps={{
              className:
                'bg-transparent border-b-2 border-purple5 shadow-none rounded-none',
            }}
          >
            {TABS_MENU.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => tabClickHandler(value)}
                className={`pt-[12px] pb-[13px] leading-[22px] ${
                  activeTab === value
                    ? 'font-semibold text-purple5'
                    : 'text-gray7'
                }`}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            <TabPanel value={activeTab} className="px-5 py-2 xl:px-0">
              {activeTab === 'phone' && <LoginPasswordPhone />}
              {activeTab === 'email' && <LoginPasswordEmail />}
            </TabPanel>
          </TabsBody>
        </Tabs>
      </div>
    </NormalPage>
  );
};

export default LoginPassword;
