import calendar from '../img/sec07-calender-dynamic-gradient.png';
import { motion } from 'framer-motion';
import React from 'react';
import { NeumophismAccordion, CircleIcon } from '../wmw/WmwComp';

const HFHJSection7 = () => {
  return (
    <>
      {/* 흰배경 영역 */}
      <div className="bg-[#F9F9F9] p-3 py-10 md:p-10">
        <div className="max-w-[520px] m-auto">
          <NeumophismAccordion
            title={
              <div className="flex-1 text-base md:text-xl font-['Pretendard']">
                <span className="font-bold">HFHJ</span>는<br />
                이렇게 구성되었어요!
              </div>
            }
          >
            <div
              className="flex flex-col gap-5
             bg-white rounded-lg p-5"
            >
              <div className="border border-[#FE7020] rounded-[46.50px] font-bold text-lg md:text-xl p-1 px-5 m-auto">
                <span className="text-[#FE7020]">HFHJ</span> 구성
              </div>
              <div className="text-center text-sm sm:text-base font-medium">
                HFHJ는 유치부 주일학교 프로그램으로
                <br />
                1년 과정의{' '}
                <span className="text-[#FE7020] font-semibold">
                  [Hello, Father!]
                </span>
                과
                <br />
                1년 과정의{' '}
                <span className="text-[#FE7020] font-semibold">
                  [Hello, Jesus!]
                </span>
                <br />총 2년의 학습 코스로 구성되어 있습니다.
              </div>
            </div>
          </NeumophismAccordion>
        </div>
      </div>

      {/* 회색배경 영역 */}
      <div className="bg-[#d5d5d5] flex justify-center items-center p-3 pt-10 pb-28">
        <div className="w-full max-w-[520px] flex flex-col justify-center gap-6 md:gap-8">
          {/*타이틀 */}
          <section className="flex flex-col gap-1">
            <img
              src={calendar}
              alt="calendar"
              className="aspect-square object-contain w-24 h-24 m-auto"
            />
            <div className="text-center font-bold font-['Pretendard'] text-2xl sm:text-3xl ">
              <span className="text-[#FE7020]">HFHJ</span> 커리큘럼 과정
            </div>
          </section>

          {/* 컨텐츠 */}
          <section className="flex gap-5 md:gap-7 m-auto justify-around items-baseline">
            <motion.div
              className="relative"
              initial={{ y: -20, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <div
                className="bg-white rounded-2xl p-4 w-fit m-auto
                    text-lg font-bold text-[#FE7020] text-center font-['Pretendard']
                    relative z-10"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                1년
              </div>
              <div
                className="bg-gray-200 rounded-2xl w-[100px] h-20 p-4 m-auto
                    text-center font-['Pretendard'] text-sm
                    flex items-end justify-center
                    absolute top-10 left-1/2 -translate-x-1/2
                    z-0"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                Hello,
                <br />
                Father!
              </div>
            </motion.div>

            <CircleIcon
              width={22}
              height={22}
              fontSize={50}
              text="+"
              color="#FE7020"
            />

            <motion.div
              className="relative"
              initial={{ y: -20, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <div
                className="bg-white rounded-2xl p-4 w-fit m-auto
                    text-lg font-bold text-[#FE7020] text-center font-['Pretendard']
                    relative z-10"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                1년
              </div>
              <div
                className="bg-gray-200 rounded-2xl w-[100px] h-20 p-4 m-auto 
                text-center font-['Pretendard'] text-sm
                flex items-end justify-center
                absolute top-10 left-1/2 -translate-x-1/2
                z-0"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                Hello,
                <br />
                Jesus!
              </div>
            </motion.div>

            <CircleIcon
              width={22}
              height={22}
              fontSize={50}
              text="="
              color="#FE7020"
            />

            <motion.div
              className="relative"
              initial={{ y: -20, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <div
                className="bg-[#FE7020] rounded-2xl p-4 w-fit m-auto
                    text-lg font-bold text-white text-center font-['Pretendard']
                    relative z-10"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                2년
              </div>
              <div
                className="bg-gray-200 rounded-2xl w-[100px] p-4 h-20 m-auto 
                    text-center font-['Pretendard'] text-sm
                    flex items-center justify-center
                    absolute top-10 left-1/2 -translate-x-1/2 
                    z-0"
                style={{ boxShadow: '0px 0px 3px 1px rgba(200, 200, 200)' }}
              >
                총 학습 기간
              </div>
            </motion.div>
          </section>
        </div>
      </div>
    </>
  );
};

export default HFHJSection7;
