import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import TextWinds from "../../../components/common/TextWinds";
import { MYPAGE_NOTICE_DATA } from "../../../data/statics-datas";

const MypageNotice = () => {
    return (
        <>
            <section className="px-5 pt-[18px] lg:p-0">
                <div className="hidden lg:block lg:mb-6">
                    <TextWinds type="title_h2">공지사항</TextWinds>
                </div>
                <iframe
                    src='https://jebs-notice.oopy.io/55e90fc4-9893-4189-9c9d-759c138d4e98'
                    className="w-full h-[600px] lg:h-[800px] border-0"
                    title="notice"/>
            </section>
        </>
    );
};

export default MypageNotice;
