import { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';

interface CalendarInputProps {
  startDate: Date;
  setStartDate: (data: Date) => void;
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

const CalendarInput = ({
  startDate,
  setStartDate,
  isOpen,
  setIsOpen,
}: CalendarInputProps) => {
  
    // DatePicker Ref  
  const datePickerRef = useRef<HTMLDivElement>();

  // DatePicker 변경시
  const handleChange = (date: Date) => {
    setIsOpen(!isOpen);
    setStartDate(date);
  };

  // DatePicker 외부 클릭시
  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // DatePicker 외부 클릭시 이벤트 등록
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="absolute left-0 top-0 z-10" ref={datePickerRef}>
        {isOpen && (
          <DatePicker
            dropdownMode="select"
            showYearDropdown
            // scrollableYearDropdown
            // yearDropdownItemNumber={15}
            showMonthDropdown
            selected={startDate}
            onChange={handleChange}
            inline
          />
        )}
      </div>
    </div>
  );
};

export default CalendarInput;
