import { Step, Stepper } from '@material-tailwind/react';
import React, { useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import LoginCheck from './LoginCheck';
import { SignupContext } from './SignupContext';
import AgreementCheck from './AgreementCheck';
import InfoForm from './InfoForm';
import Step4 from './step4';

const SignupPage = () => {
  const menuList = ['가입 확인', '약관 동의', '정보 입력', '가입 완료'];
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState('');
  return (
    <SignupContext.Provider
      value={{
        step: step,
        setStep: setStep,
        phone: phone,
        setPhone: setPhone,
      }}
    >
      <div className="w-full bg-gray0 py-10">
        <div className="max-w-[600px] m-auto p-10 shadow-card bg-white rounded-lg flex flex-col gap-10">
          <section>
            <Stepper activeStep={step} activeLineClassName="bg-purple5">
              {menuList.map((menu, index) => (
                <CustomStepper
                  index={index}
                  activeStep={step}
                  label={menu}
                  key={index}
                />
              ))}
            </Stepper>
          </section>
          <section>
            {step === 0 && <LoginCheck />}
            {step === 1 && <AgreementCheck />}
            {step === 2 && <InfoForm />}
            {step === 3 && <Step4 />}
          </section>
        </div>
      </div>
    </SignupContext.Provider>
  );
};

const CustomStepper = ({
  index,
  activeStep,
  label,
}: {
  index: number;
  activeStep: number;
  label: string;
}) => {
  return (
    <Step
      key={index}
      className={`w-[${
        index === 3 ? '20%' : '50px'
      }] h-[20px] bg-gray0 flex whitespace-nowrap gap-[6px] items-center ${
        index > 0 ? 'pl-[5px]' : ''
      }`}
      activeClassName="ring-0 !bg-gray0 text-white"
      completedClassName="!bg-gray0 text-white"
    >
      <p
        className={`text-[10px] flex shrink-0 items-center justify-center font-medium w-[14px] h-[14px] border border-solid border-purple3 rounded-[14px] xl:w-[18px] xl:h-[18px] ${
          activeStep === index ? 'text-purple5' : 'text-purple3'
        }`}
      >
        {index + 1}
      </p>
      <div className="flex bg-gray0 pr-[5px]">
        <TextWinds
          type="caption2"
          xlType="content"
          color={activeStep === index ? 'purple5' : 'purple3'}
        >
          {label}
        </TextWinds>
      </div>
    </Step>
  );
};

export default SignupPage;
