import React from 'react'
import TextWinds from '../common/TextWinds'
import { Spinner } from '@material-tailwind/react'

interface SpinnerProps {
  message?: string
}

export const LoadingSpinner = ({message}: SpinnerProps) => {
  return (
    <div>
      <Spinner />
      {/* <Text fontSize="12px">{message}</Text> */}
      <TextWinds type='title_h6'>{message}</TextWinds>
    </div>
  )
}
