import React, { Fragment, SetStateAction, useState } from 'react';
import TextWinds from '../common/TextWinds';
import Buttons from '../common/Buttons';
import CheckBoxes from '../common/CheckBoxes';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Collapse } from '@material-tailwind/react';
import CustomRadios from '../common/CustomRadios';
import { PaymentMethod } from '../../enum';
import { useAppDispatch } from '../../store/hook';
import { openModal } from '../../store/features/modalSlice';
import { AgreementModal } from '../common/Agreement';

interface PaymentsProps {
  paymentTermAgree: any[];
  selectAll: boolean;
  paymentMethod: string;
  handleCheckboxChange: (e: any) => void;
  handleSelectAllChange: (e: any) => void;
  handleOpenFull: (id: string, toggled: boolean) => void;
  handlePaymentMethod: (id: string) => void;
}
const Payments = ({
  paymentTermAgree,
  selectAll,
  paymentMethod,
  handleCheckboxChange,
  handleSelectAllChange,
  handlePaymentMethod,
}: PaymentsProps) => {
  const dispatch = useAppDispatch();
  const [isTermsInfoOpen, setIsTermsInfoOpen] = useState(false);

  const eventCheckedHandle = (id: string, toggled: boolean) => {
    const changeItem = {
      ...paymentTermAgree.find((el) => el.id === id),
      isChecked: toggled,
    };
    handleCheckboxChange(changeItem);
  };

  const eventAllCheckedHandle = () => {
    const changeItem = paymentTermAgree.map((item) => ({
      ...item,
      isChecked: !selectAll,
    }));
    handleSelectAllChange(changeItem);
  };

  const eventChangedPayments = (id: string) => {
    handlePaymentMethod(id.toUpperCase());
  };

  return (
    <Fragment>
      <div className="mt-[16px] py-[14px] px-[20px] bg-white xl:rounded-[8px]">
        <TextWinds type="title_h4">결제방식</TextWinds>
        <ul className="grid md:grid-cols-2 gap-[16px] mt-[16px]">
          <CustomRadios
            id="credit_card"
            color="purple5"
            target={paymentMethod}
            onChange={eventChangedPayments}
          >
            <TextWinds
              type="content_bold"
              color={`${
                PaymentMethod.CREDIT_CARD === paymentMethod
                  ? 'white'
                  : 'purple5'
              }`}
            >
              신용카드
            </TextWinds>
          </CustomRadios>
          <CustomRadios
            id="bank_transfer"
            color="purple5"
            target={paymentMethod}
            onChange={eventChangedPayments}
          >
            <TextWinds
              type="content_bold"
              color={`${
                PaymentMethod.BANK_TRANSFER === paymentMethod
                  ? 'white'
                  : 'purple5'
              }`}
            >
              실시간 계좌이체
            </TextWinds>
          </CustomRadios>
          {/* <CustomRadios id="naver_pay" color="purple5" onChange={eventChangedPayments}>
            <TextWinds type="content_bold" color="purple5">
              네이버페이
            </TextWinds>
          </CustomRadios>
          <CustomRadios id="kakao_pay" color="purple5" onChange={eventChangedPayments}>
            <TextWinds type="content_bold" color="purple5">
              카카오페이
            </TextWinds>
          </CustomRadios> */}
          <CustomRadios
            id="deposit"
            color="purple5"
            target={paymentMethod}
            onChange={eventChangedPayments}
          >
            <TextWinds
              type="content_bold"
              color={`${
                PaymentMethod.DEPOSIT === paymentMethod ? 'white' : 'purple5'
              }`}
            >
              무통장 입금
            </TextWinds>
          </CustomRadios>
        </ul>
      </div>

      <div className="mt-[16px] py-[14px] px-[20px] bg-white xl:rounded-[8px] ">
        <TextWinds type="title_h4">결제 약관 동의</TextWinds>
        <form className="flex flex-col gap-[8px] mt-[16px]">
          <div className="flex flex-row items-center justify-between">
            <CheckBoxes
              type="rounded"
              color="purple5"
              labelValue={
                '(필수)위 주문의 상품 및 결제, 주문 정보 등을 확인하였으며,구매 진행에 동의합니다.'
              }
              checked={selectAll}
              onChange={eventAllCheckedHandle}
            />

            <div className="flex items-start justify-center">
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`w-5 h-5 transition-transform ${
                  isTermsInfoOpen ? 'rotate-180' : ''
                }`}
                onClick={() => setIsTermsInfoOpen((cur) => !cur)}
              />
            </div>
          </div>
          <div>
            <Collapse open={isTermsInfoOpen}>
              <div className="flex flex-col gap-[8px]">
                {paymentTermAgree.map((item) => (
                  <div
                    className="flex flex-row items-center gap-[8px]"
                    key={item.id}
                  >
                    <CheckBoxes
                      id={item.id}
                      labelValue={item.label}
                      type="full"
                      checked={item.isChecked}
                      onChange={(e) =>
                        eventCheckedHandle(item.id, e.target.checked)
                      }
                    />
                    <div>
                      <Buttons
                        type="text"
                        color="purple5"
                        // onClick={() => handleOpenFull(item.id, !item.views)}
                        onClick={() =>
                          dispatch(
                            openModal({
                              body: <AgreementModal agreement={item.label} />,
                            })
                          )
                        }
                      >
                        자세히 보기
                      </Buttons>
                    </div>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Payments;
